//CSS & MaterialUI
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  large: {
    width: "9rem",
    height: "9rem",
    overflow: "visible"
  },
  grid: {
    marginLeft: "5%",
    marginRight: "5%",
    width: "80%"
  },
  field: {
    marginLeft: "5%",
    marginRight: "5%",
    width: "80%",
    marginBottom: "1em",
    "& div": {
      color: "white"
    }
  },
  whiteBackgroudColor: {
    backgroundColor: "white"
  },
  prompt1: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    marginBottom: "0.5em",
    color: "#1277B8",
    display: "inline",
    fontSize: 16
  },
  prompt2: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    marginBottom: "2rem",
    color: "#1277B8",
    display: "inline",
    fontSize: 14,
    marginLeft: "0.5em"
  },
  prompt3: {
    // fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    color: "#1277B8",
    fontSize: 14,
    marginBottom: "1rem"
    // marginTop: "2rem",
  },
  nextButton: {
    marginTop: "3em",
    width: "9em",
    height: "2.5em",
    borderRadius: 100,
    textAlign: "center",
    marginRight: "1em"
  },
  buttonLabel: {
    color: "white",
    fontWeight: "normal",
    fontFamily: "PT Sans Narrow"
  },
  iconbutton: {
    float: "right",
    marginRight: "2em",
    color: "black"
  },
  popUpTitle: {
    marginTop: "1em"
  },
  browseLabel: {
    color: "#1277B8",
    fontWeight: "bold",
    fontFamily: "PT Sans Narrow"
  },
  browseRoot: {
    background: "#FFFFFF",
    /* Title & Lable Text */

    border: "2px solid #1277B8",
    boxSizing: "border-box",
    borderRadius: "100px"
  },
  uploadPhoto: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    marginLeft: "3em",
    marginTop: "5px",
    fontWeight: "normal",
    fontSize: "26px",
    lineHeight: "140.62%",
    /* or 37px */

    display: "flex",
    alignItems: "center",

    color: "#000000"
  },
  uploadButtonRoot: {
    background: "#1277B8"
  },
  buttonRoot: {
    backgroundColor: "#FF6161"
  },
  backButton: {
    width: "60%",
    marginTop: "3em",
    marginBottom: "1em",
    marginLeft: "20%",
    marginRight: "2em",
    fontFamily: "PT Sans",
    border: "white"
  },
  backButtonLabel: {
    color: "#FF6161",
    fontSize: 16,
    fontWeight: "normal",
    fontFamily: "PT Sans Narrow"
  },
  uploadCard: {
    width: "431px",
    height: "186px",
    background: "#FFFFFF"
  },
  card: {
    backgroundColor: "#EFEFEF",
    height: "170px",
    width: "170px",
    boxShadow: "none",
    marginTop: "1em",
    marginBottom: "2em"
  },
  input: {
    display: "none"
  },
  upload: {
    display: "inline"
    // marginLeft: "4em"
  },
  uploadButton: {
    boxsizing: "border-box",
    borderRadius: "100px",
    boxShadow: "none",
    backgroundColor: "white"
  },
  uploadLabel: {
    color: "rgba(18, 119, 184, 0.6)",
    textTransform: "uppercase"
  },
  uploadRoot: {
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    boxShadow: "none"
  },
  labels: {
    fontWeight: "bold",
    fontFamily: "PT Sans Narrow",
    marginBottom: "0.5em",
    color: "#1277B8",
    textTransform: "uppercase"
  },
  certificatesLabel: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "PT Sans Narrow",
    marginBottom: "0.5em",
    color: "#1277B8"
  },
  checkboxes: {
    display: "inline",
    maxWidth: "200px"
  },
  fileUploadCheck: {
    display: "inline"
  },
  iconRoot: {
    fill: "#1277B8",
    marginLeft: "1em"
  },
  center: {
    textAlign: "center"
  },
  specialisations: {
    marginBottom: "2em",
    marginTop: "6em"
  },
  column: {
    marginTop: "-1em"
  },
  skillDiv: {
    textAlign: "center",
    margin: "0 auto"
  },
  skillInfo: {
    textAlign: "center",
    margin: "0 auto",
    marginBottom: "1rem"
  },
  allergyandotherinformation: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "25.31px",
    display: "flex",
    alignItems: "center",
    color: "#000000"
  },
  introduction: {
    fontWeight: "bold",
    fontFamily: "PT Sans Narrow",
    color: "#1277B8"
  }
});

export default useStyles;
