import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  addTimeslot,
  updateTimeslot,
  deleteTimeslot
} from "../../redux/actions/virtInstructorDataActions";
import { connect } from "react-redux";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { TimePicker } from "@material-ui/pickers";
import moment from "moment";

const useStyles = makeStyles({
  container: {
    marginTop: ".5em",
    width: "100%"
  },
  dateText: { fontSize: 11, marginTop: ".25rem" },
  highlight: { background: "#C5C5C5", padding: "0.25rem" },
  dropdown: { marginLeft: "0rem", fontSize: "14px" },
  arrow: { margin: "0 0em 0 0em" },
  saveButton: {
    // marginLeft: "0.2rem",
    backgroundColor: "#FF6161",
    padding: "2px 12px 2px 12px",
    borderRadius: "50px",
    fontFamily: "PT Sans Narrow",
    fontSize: "10px",
    border: "none",
    display: "inline-block",
    color: "#FFFFFF"
    // width:"50%"
  },
  saveButtonLabel: {
    padding: "0px 3px 0px 3px",
    color: "white",
    fontWeight: 500,
    fontSize: "12px"
  },
  pricingLabel: {
    fontSize: "14px",
    position: "absolute",
    top: 25
  },
  cancelButton: {
    // marginLeft: "0.2rem",
    backgroundColor: "#FFFFFF",
    borderRadius: "50px",
    fontFamily: "PT Sans Narrow",
    fontSize: 10,
    color: "#FF6161",
    borderColor: "#FF6161",
    padding: "1px 8px 1px 8px"
  },
  removeButton: {
    // marginLeft: "0.2rem",
    backgroundColor: "#FFFFFF",
    borderRadius: "50px",
    fontFamily: "PT Sans Narrow",
    fontSize: "10px",
    border: "none",
    color: "#000000",
    display: "inline-block",
    padding: "1px 12px 1px 1px"
  },
  label: {
    color: "rgba(0, 0, 0, 0.54)",
    padding: "6px 0",
    fontSize: 16,
    fontFamily: "Roboto",
    fontWeight: 400,
    lineHeight: 0,
    letterSpacing: "0.00938em",
    transform: "translate(0, 1.5px) scale(0.75)"
  },
  priceContainer: {
    border: 0,
    margin: 0,
    display: "inline-flex",
    padding: 0,
    position: "relative",
    minWidth: 0,
    flexDirection: "column",
    verticalAlign: "top"
  }
});

//HELPER FUNCTIONS
//displays date as nicely formatted MaterialUI-Typography as Day Month Date
//props:
//date - Date object to be converted
const FormatDateAsMaterialUI = date => {
  const classes = useStyles();
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const day = daysOfWeek[date.getDay()];
  const dateNum = date.getDate();
  const month = date.toDateString().substring(4, 7);
  return (
    <div>
      <Typography className={classes.dateText}>
        {day} {month} <span className={classes.highlight}>{dateNum}</span>
      </Typography>
    </div>
  );
};

//singular timeslot
const SelectedTimeslot = ({
  datefrom,
  dateto,
  id,
  timeslots,
  buttons,
  pricing,
  _addTimeslot,
  _updateTimeslot,
  _deleteTimeslot,
  price,
  error,
  currentProfile
}) => {
  const classes = useStyles();
  //constants

  const roundMinutes = date => {
    date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
    date.setMinutes(0, 0, 0); // Resets also seconds and milliseconds
    return date;
  };

  let INITIAL_DATE = new Date(); // 4:55
  INITIAL_DATE.setDate(
    INITIAL_DATE.getDate() + currentProfile.minAdvanceNoticeDays
  ); // add advance notice days to intial date of new timeslot
  roundMinutes(INITIAL_DATE); // 5:00

  let instructor_rates = 0;
  pricing = [
    {
      hours: 1,
      rate: 10,
      discount: 0
    },
    {
      hours: 2,
      rate: 10,
      discount: 0.1
    },
    {
      hours: 3,
      rate: 10,
      discount: 0.2
    }
  ];
  if (pricing) instructor_rates = pricing;

  const add = () => {
    _addTimeslot({
      datefrom: INITIAL_DATE,
      dateto: INITIAL_DATE,
      id: timeslots.length,
      price: 0,
      error: false
    });
  };

  const updateTimeslots = (dateFrom, dateTo) => {
    const timeA = dateFrom instanceof Date ? dateFrom : dateFrom.toDate();
    const timeB =
      dateTo instanceof Date
        ? new Date(
            timeA.getFullYear(),
            timeA.getMonth(),
            timeA.getDate(),
            dateTo.getHours(),
            dateTo.getMinutes()
          )
        : new Date(
            timeA.getFullYear(),
            timeA.getMonth(),
            timeA.getDate(),
            dateTo.toDate().getHours(),
            dateTo.toDate().getMinutes()
          );
    _updateTimeslot(id, {
      datefrom: timeA,
      dateto: timeB,
      price: getPricingInfo(moment(timeA), moment(timeB))
    });
  };

  const renderButton = () => {
    // if (status === "saved") {
    if (buttons === "undefined") {
      return <div />;
    } else if (id === timeslots.length - 1) {
      let newTimeslots = [...timeslots];
      newTimeslots.splice(id, 1);

      return (
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <button
              variant="contained"
              onClick={() => {
                add();
              }}
              className={classes.saveButton}
              classes={{
                label: classes.saveButtonLabel
              }}
            >
              Add
            </button>
          </Grid>
          {timeslots.length === 1 ? null : (
            <Grid item>
              <button
                variant="contained"
                onClick={() => {
                  _deleteTimeslot(id);
                }}
                className={classes.cancelButton}
                classes={{
                  label: classes.removeButtonLabel
                }}
              >
                Remove
              </button>
            </Grid>
          )}
        </Grid>
      );
    } else {
      let newTimeslots = [...timeslots];
      newTimeslots.splice(id, 1);
      return (
        <button
          variant="contained"
          onClick={() => {
            _deleteTimeslot(id);
          }}
          className={classes.cancelButton}
          classes={{
            label: classes.removeButtonLabel
          }}
        >
          Remove
        </button>
      );
    }
  };

  const getPricingInfo = (timeA, timeB) => {
    const hourA = timeA.toDate().getHours();
    const hourB = timeB.toDate().getHours();
    const minuteA = timeA.toDate().getMinutes();
    const minuteB = timeB.toDate().getMinutes();
    const hours = hourB - hourA;

    const minutes = minuteB - minuteA;
    let price = 0;
    pricing.forEach(rate => {
      // hours should not be smaller than 0 to avoid negative price
      if (hours >= 0) {
        // convert potential negative minutes e.g. 1:45 to 2:00
        if (minutes < 0) {
          const modifiedMinuteA = minuteA;
          const modifiedMinuteB = minuteB + 60;
          const modifiedMinutes = modifiedMinuteB - modifiedMinuteA;
          const modifiedHours = hours - 1;
          // apply discounts if available
          if (modifiedHours >= rate.hours) {
            const priceForHours =
              modifiedHours * rate.rate * (1 - rate.discount);
            const priceForMinutes =
              (modifiedMinutes / 15) * (rate.rate / 4) * (1 - rate.discount);
            price = priceForHours + priceForMinutes;
          }
          // apply no discount if modifiedHours < 1
          if (modifiedHours === 0) {
            const priceForHours = modifiedHours * rate.rate;
            const priceForMinutes = (modifiedMinutes / 15) * (rate.rate / 4);
            price = priceForHours + priceForMinutes;
          }
        } else {
          // apply discounts if available
          if (hours >= rate.hours) {
            const priceForHours = hours * rate.rate * (1 - rate.discount);
            const priceForMinutes =
              (minutes / 15) * (rate.rate / 4) * (1 - rate.discount);
            price = priceForHours + priceForMinutes;
          }
          // only calculate price for minutes if hours = 0
          if (hours === 0) {
            price = (minutes / 15) * (rate.rate / 4);
          }
        }
      }
      // price will be -1 if the "from time" is after the "to time"
      else {
        price = -1;
      }
    });
    return price;
  };
  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="baseline"
      className={classes.container}
      spacing={1}
      style={{ border: error ? "1px solid red" : null, marginLeft: 0 }}
    >
      {/* Date Picker */}
      <Grid item lg={2} xs={12}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            label="Date"
            // LATER: should display latest available date of instructor, considering currentProfile:
            // minAdvanceNoticeDays, unavailableDays, resort_bookings.unavailableDays+start_time+end_time
            value={datefrom}
            minDate={new Date().setDate(
              new Date().getDate() + currentProfile.minAdvanceNoticeDays
            )}
            maxDate={new Date().setDate(
              new Date().getDate() + currentProfile.maxBookingAvalDays
            )}
            onChange={e => {
              // setSelectedDay(e.toDate());
              // setUpdateState(new Date());
              updateTimeslots(e, dateto);
            }}
            format="DD/MM"
            disablePast="true"
          />
        </MuiPickersUtilsProvider>
      </Grid>

      {/* Start Time */}
      <Grid item lg={3} xs={12}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <TimePicker
            label="Start Time"
            value={datefrom}
            minutesStep={15}
            onChange={e => {
              // setTimeslotValueFrom(e);
              // setUpdateState(new Date());
              updateTimeslots(e, dateto);
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>

      {/* End Date */}
      <Grid item lg={3} xs={12}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <TimePicker
            label="End Time"
            value={dateto}
            minutesStep={15}
            onChange={e => {
              // setTimeslotValueTo(e);
              // setUpdateState(new Date());
              updateTimeslots(datefrom, e);
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>

      {/* Pricing */}
      <Grid item lg={2} xs={12}>
        <div className={classes.priceContainer}>
          <div className={classes.label}>Price</div>
          <div>
            <Typography className={classes.pricingLabel}>
              {"$" + price.toFixed(2)}
            </Typography>
          </div>
        </div>
      </Grid>

      {/* Action Buttons */}
      <Grid item lg={2} xs={12}>
        {renderButton()}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    timeslots: state.virtualInstructorReducer.timeslots,
    currentProfile: state.virtualInstructorReducer.currentProfile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    _addTimeslot: timeslot => dispatch(addTimeslot(timeslot)),
    _updateTimeslot: (id, timeslot) => dispatch(updateTimeslot(id, timeslot)),
    _deleteTimeslot: id => dispatch(deleteTimeslot(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectedTimeslot);
