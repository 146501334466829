import { GET_PROFILE_DATA, UPDATE_PROFILE_DATA } from "../actions/action-types";

const initialState = {
  profileData: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE_DATA:
      return {
        ...state,
        profileData: action.payload
      };

    default:
      return state;
  }
};
