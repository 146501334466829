import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Flag from "../../virtualLessons/Flag";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  typograhyFormat: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontSize: 16,
    lineHeight: "22.50px",
    textAlign: "left",
    color: "#1277B8",
    marginTop: "2rem",
    paddingBottom: "1rem",
    fontWeight: "bolder",
    width: "100%"
  },
  lableFormat: {
    display: "block",
    color: "rgba(18, 119, 184, 0.6)"
  },
  mainDiv: {
    marginLeft: "14rem",
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "3rem"
    }
  },
  flagContainer: {
    display: "inline-block"
  },
  // sliderbarFormat: {
  //   paddingLeft: 25,
  //   width: "100%"
  // },
  sliderPositionFormat: {
    maxWidth: "33%"
  }
}));

const VisitorIntroSection = props => {
  const classes = useStyles();
  const { age, gender, country, languages, abilities } = props;

  const [sliderValue, setSliderValue] = React.useState(1);
  const [sliderValue2, setSliderValue2] = React.useState(1);
  const handleSliderChange = event => {
    setSliderValue(event.target.value);
  };
  const handleSliderChange2 = event => {
    console.log("trigger", event.target.value);
    setSliderValue2(event.target.value);
  };
  const Language = languages.map(value => (
    <div className={classes.flagContainer}>
      <Flag language={value} key={value} />
    </div>
  ));

  return (
    // this is the main Grid Container work as  (DIV)
    <div className={classes.mainDiv}>
      <Grid container xs={12} md={12} lg={12} spacing={2}>
        {/* this is the 1st row which has "Age"+ "Gender"+ "Phisical Diability" */}

        <Grid item xs={12} lg={4}>
          <Typography className={classes.typograhyFormat}> AGE </Typography>
          <span> {`${age} Years`} </span>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Typography className={classes.typograhyFormat}> GENDER </Typography>
          <span>{gender}</span>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Typography className={classes.typograhyFormat}>COUNTRY</Typography>
          {country.map(countries => (
            <span>
              {countries}
              <br />
            </span>
          ))}
        </Grid>

        {/* this is the 2nd row which has "Langange"+ "Years of Experince"+ "Ability" */}
        <Grid item xs={12} lg={4} className={classes.sliderPositionFormat}>
          <Typography className={classes.typograhyFormat}>LANGUAGES</Typography>
          {Language}
        </Grid>

        <Grid container item xs={12} lg={8}>
          <Typography className={classes.typograhyFormat}> ABILITY </Typography>

          {abilities.map(ability => (
            <Grid item container xs={12} spacing={1} lg={12}>
              <Grid item xs={4} lg={2}>
                <label className={classes.lableFormat} for="ski">
                  {ability.name}
                </label>
              </Grid>
              <Grid item xs={7} lg={4}>
                <div className={classes.sliderbarFormat}>
                  <input
                    type="range"
                    className="color-slider-bar"
                    min={1}
                    max={7}
                    step={1}
                    id="ski"
                    value={ability.skill}
                    onChange={handleSliderChange}
                    disabled={false}
                  />
                </div>
              </Grid>

              <Grid item lg={2} md={1} sm={1} xs={1}>
                <div>{ability.skill}</div>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default VisitorIntroSection;
