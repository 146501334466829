import React, { Component, Fragment, useState, useEffect, useCallback, useMemo } from "react";

// material ui
import { Grid, Typography, InputBase } from "@material-ui/core";

import useStyles from "./ServicesStyles";

// debounce function
const debounce = (fn, delay) => {
  let timeout = -1;
  return (...args) => {
    if (timeout !== -1) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(fn, delay, ...args);
  };
};

export const MaxGroupSize = props => {
  const { onTextChange, groupSize } = props;
  const [inputValue, setValue] = useState(0);
  const classes = useStyles();

  // prefill service price if it is offered
  useEffect(() => {
    if (groupSize !== undefined && groupSize) {
      setValue(groupSize);
    }
  }, [groupSize]);

  // Debounce changes
  const onDebounceText = useMemo((value) => {
    return debounce(value => {
      onTextChange("max_group_size", value);
    }, 1000);
  }, []);

  const handleTextChange = useCallback((value) => {
    onDebounceText(value);
  }, []);

  // handle group size change event
  const handleInput = event => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setValue(event.target.value);
      handleTextChange(event.target.value);
    }
  };

  return (
    <Fragment>
      <Grid item xs={12} md={12} lg={12} align="left" className={classes.title}>
        <Typography>MAX GROUP SIZE</Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        className={classes.cardContainer}
        spacing={2}
      >
        <Grid item xs={3} md={2} lg={2}>
          <InputBase
            className={classes.input}
            onChange={handleInput}
            value={ inputValue }
            disabled={props.isDisabled || false}
          />
        </Grid>
        <Grid item xs={9} md={10} lg={10} align="left">
          <Typography className={classes.prompt}>per group</Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export const TextContent = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <Grid item xs={12} md={12} lg={12} align="left" className={classes.title}>
        <p>
          <span>&#8226;</span> TRIP PLANNING ADVICE
          <br />
          <br />
          <span>&#8226;</span> RENTAL SHOP SUPPORT IN STORE & GETTING STARTED ON
          THE SLOPES
        </p>
      </Grid>
    </Fragment>
  );
};


export const ListContent = () => {
  const classes = useStyles();
  return (
    
            <ul style={{marin:0, padding:10}}>
                <li>Trip Planning Advice</li>
                <li>Rental Shop Support in store & getting started on
          the slopes</li>
            </ul>          
           
  );
};

export const PricePerHead = props => {
  const { onTextChange, pricePerHead } = props;
  const [inputValue, setValue] = useState(0);
  const classes = useStyles();

  // prefill service price if it is offered
  useEffect(() => {
    if (pricePerHead !== undefined && pricePerHead) {
      setValue(pricePerHead);
    }
  }, [pricePerHead]);

  // Debounce changes
  const onDebounceText = useMemo((value) => {
    return debounce(value => {
      onTextChange("price_per_head", value);
    }, 1000);
  }, []);

  const handleTextChange = useCallback((value) => {
    onDebounceText(value);
  }, []);

  // handle price per head change event
  const handleInput = event => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setValue(event.target.value);
      handleTextChange(event.target.value);
    }
  };

  return (
    <Fragment>
      <Grid item xs={12} md={12} lg={12} align="left" className={classes.title}>
        <Typography>PRICE PER HEAD</Typography>
      </Grid>

      <Grid
        container
        item
        xs={12}
        className={classes.cardContainer}
        spacing={2}
      >
        <Grid item xs={1} md={1} lg={1}>
          <Typography className={classes.prompt}>$</Typography>
        </Grid>
        <Grid item xs={3} md={2} lg={2}>
          <InputBase
            className={classes.input}
            onChange={handleInput}
            value={ inputValue }
            disabled={props.isDisabled || false}
          />
        </Grid>
        <Grid item xs={8} md={9} lg={9} align="left">
          <Typography className={classes.prompt}>per guest</Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export const AdditionalGuests = props => {
  const { onTextChange, guestPrice } = props;
  const [inputValue, setValue] = useState(0);
  const classes = useStyles();

  // prefill service price if it is offered
  useEffect(() => {
    if (guestPrice !== undefined && guestPrice) {
      setValue(guestPrice);
    }
  }, [guestPrice]);

  // Debounce changes
  const onDebounceText = useMemo((value) => {
    return debounce(value => {
      onTextChange("additional_guests_price", value);
    }, 1000);
  }, []);

  const handleTextChange = useCallback((value) => {
    onDebounceText(value);
  }, []);

  // handle additional guests price change event
  const handleInput = event => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setValue(event.target.value);
      handleTextChange(event.target.value);
    }
  };

  return (
    <Fragment>
      <Grid item xs={12} md={12} lg={12} align="left" className={classes.title}>
        <Typography>ADDITIONAL GUESTS</Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        className={classes.cardContainer}
        spacing={2}
      >
        <Grid item xs={1} md={1} lg={1}>
          <Typography className={classes.prompt}>$</Typography>
        </Grid>
        <Grid item xs={3} md={2} lg={2}>
          <InputBase
            className={classes.input}
            value={ inputValue }
            onChange={handleInput}
            disabled={props.isDisabled || false}
          />
        </Grid>
        <Grid item xs={8} md={9} lg={9} align="left">
          <Typography className={classes.prompt}>per guest</Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export const AdditionalChild = props => {
  const { onTextChange, childPrice } = props;
  const [inputValue, setValue] = useState(0);
  const classes = useStyles();

  // prefill service price if it is offered
  useEffect(() => {
    if (childPrice !== undefined && childPrice) {
      setValue(childPrice);
    }
  }, [childPrice]);

  // Debounce changes
  const onDebounceText = useMemo((value) => {
    return debounce(value => {
      onTextChange("additional_children_price", value);
    }, 100);
  }, []);

  const handleTextChange = useCallback((value) => {
    onDebounceText(value);
  }, []);

  const handleInput = event => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setValue(event.target.value);
      handleTextChange(event.target.value);
    }
  };

  return (
    <Fragment>
      <Grid item xs={12} md={12} lg={12} align="left" className={classes.title}>
        <Typography>ADDITIONAL CHILD</Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        className={classes.cardContainer}
        spacing={2}
      >
        <Grid item xs={1} md={1} lg={1}>
          <Typography className={classes.prompt}>$</Typography>
        </Grid>
        <Grid item xs={3} md={2} lg={2}>
          <InputBase
            className={classes.input}
            onChange={handleInput}
            value={inputValue}
            disabled={props.isDisabled || false}
          />
        </Grid>
        <Grid item xs={8} md={9} lg={9} align="left">
          <Typography className={classes.prompt}>per guest</Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
};


export const PricePerHour = props => {
  const { defaultPrice, isDisabled } = props;

  const { onTextChange } = props;
  const [inputValue, setValue] = useState(0);
  const classes = useStyles();

  // prefill service price if it is offered
  useEffect(() => {
    if (defaultPrice !== undefined && defaultPrice) {
      setValue(defaultPrice);
    }
  }, [defaultPrice]);

  // Debounce changes
  const onDebounceText = useMemo((value) => {
    return debounce(value => {
      onTextChange(value);
    }, 1000);
  }, []);

  const handleTextChange = useCallback((value) => {
    onDebounceText(value);
  }, []);

  // handle price change event
  const handleInput = event => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setValue(event.target.value);
      handleTextChange(event.target.value);
    }
  };

  return (
    <Fragment>
      <Grid
        container
        item
        xs={12}
        className={classes.cardContainer}
        spacing={2}
      >
        <Grid item xs={1} md={1} lg={1} align="left">
          <Typography className={classes.prompt}>$</Typography>
        </Grid>

        <Grid item xs={3} md={2} lg={2}>
          <InputBase
            onChange={handleInput}
            value = {inputValue}
            className={classes.input}
            disabled={isDisabled || false}
          />
        </Grid>

        <Grid item xs={8} md={9} lg={9} align="left">
          <Typography className={classes.prompt}>per hour</Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
};

// <CardContent>
//   <Grid item container justify="center" alignItems="center" xs={8} md={8} lg={8} >
//     <Grid item container xs={12} className={classes.cardContainer1} spacing={2} >
//
//     </Grid>
//   </Grid>
// </CardContent>
