import React from "react";
//materialUI
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

const LoadingWheel = props => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={!props.isLoaded}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default LoadingWheel;
