import React, { useState } from "react";

// material ui
import {
  Grid,
  Typography,
  CardContent,
  InputBase,
  Button
} from "@material-ui/core";

import useStyles from "../ServicesStyles";

import CollapsibleCheckBox from "../../../../CollapsibleCheckBox";

// import SkiTourSlopes from "./SkiTourSlopes";
import SnowTourSlopes from "./SnowTourSlopes";
import TourVillage from "./TourVillage";
import Backcountry from "./Backcountry";
import Hiking from "./Hiking";
import { PROVIDER_SERVICES } from "../../../../../../constants/common";
import { MaxGroupSize, PricePerHead, AdditionalGuests } from "../InputFields";
import SkiBackCountryTouring from "./SkiBackCountryTouring";
import SnowBackCountryTouring from "./SnowBackCountryTouring";
import Snowshoeing from "./Snowshoeing";
import SnowMobiling from "./SnowMobiling";
import { useEffect } from "react";

const Tours = props => {
  const {
    selectService,
    addSkiToursSlopeValues,
    addSnowBoardToursSlopeValues,
    addTourVillageValues,
    addBackCountryValues,
    addHikingValues,
    addSkiBackCountryTouring,
    addSnowBackCountryTouring,
    addSnowShoeingTouring,
    addSnowMobilingTouring,
    addBikeRiding
  } = props;

  const {
    services,
    isDisabled,
    setSkiTourSlopes,
    setSnowBoardTourSlopes,
    setTourVillage,
    setBackCountry,
    setHiking,
    setSkiBackCountryTouringState,
    setSnowBackCountryTouringState,
    setSnowshoeing,
    setSnowMobilingTouring,
    setBikeRiding,
  } = props;
  const classes = useStyles();
  // set the top level "TOURING HOSTS" to expand or not
  const [checkboxSelected, setCheckboxSelected] = useState(false);
  const [
    checkboxSelectedSkiTourSlopes,
    setCheckboxSelectedSkiTourSlopes
  ] = useState(false);
  const [
    checkboxSelectedSnowTourSlopes,
    setCheckboxSelectedSnowTourSlopes
  ] = useState(false);
  const [
    checkboxSelectedTourVillage,
    setCheckboxSelectedTourVillage
  ] = useState(false);
  const [
    checkboxSelectedBackCountry,
    setCheckboxSelectedBackCountry
  ] = useState(false);
  const [checkboxSelectedHiking, setCheckboxSelectedHiking] = useState(false);
  const [
    checkboxSelectedSkiBackCountryTouring,
    setSkiBackCountryTouring
  ] = useState(false);
  const [
    checkboxSelectedSnowBackCountryTouring,
    setSnowBackCountryTouring
  ] = useState(false);
  const [checkboxSelectedSnowShoeing, setSnowShoeing] = useState(false);
  const [checkboxSelectedSnowMobiling, setSnowMobiling] = useState(false);
  const [bikeRidingSelected, setCheckboxSelectedBikeRiding] = useState(false);

  //OnClick Methods

  const onCheckboxSelected = event => {
    setCheckboxSelected(event);
  };
  const onSkiTourSlopesSelected = event => {
    setCheckboxSelectedSkiTourSlopes(event);
    selectService(event, 2);
  };

  const onSnowTourSlopesSelected = event => {
    setCheckboxSelectedSnowTourSlopes(event);
    selectService(event, 3);
  };

  const onTourVillageSelected = event => {
    setCheckboxSelectedTourVillage(event);
    selectService(event, 4);
  };

  const onBackCountrySelected = event => {
    setCheckboxSelectedBackCountry(event);
    selectService(event, 5);
  };

  const onHikingSelected = event => {
    setCheckboxSelectedHiking(event);
    selectService(event, 6);
  };
  const onSkiBackCountryTouringSelected = event => {
    setSkiBackCountryTouring(event);
    selectService(event, 7);
  };
  const onSnowBackCountryTouringSelected = event => {
    setSnowBackCountryTouring(event);
    selectService(event, 8);
  };

  const onSnowShoeingSelected = event => {
    setSnowShoeing(event);
    selectService(event, 9);
  };
  const onSnowMobilingSelected = event => {
    setSnowMobiling(event);
    selectService(event, 10);
  };
  const onBikeRidingSelected = event => {
    setCheckboxSelectedBikeRiding(event);
    selectService(event, 11);
  };

  // Declare service state variables.
  let [skiTourSlopesDetail, setSkiTourSlopesDetail] = useState({});
  let [snowboardTourSlopesDetail, setSnowboardTourSlopesDetail] = useState({});
  let [tourVillageDetail, setTourVillageDetail] = useState({});
  let [backCountryDetail, setBackCountryDetail] = useState({});
  let [hikingDetail, setHikingDetail] = useState({});
  let [skiBackCountryTouringDetail, setSkiBackCountryTouringDetail] = useState({});
  let [snowBackCountryTouringDetail, setSnowBackCountryTouringDetail] = useState({});
  let [snowshoeingDetail, setSnowshoeingDetail] = useState({});
  let [snowMobilingDetail, setSnowMobilingDetail] = useState({});
  let [bikeRidingDetail, setBikeRidingDetail] = useState({});

  // Set provided service details
  useEffect(()=> {
    if (services.length > 0 && services !== undefined) {
      let isExpanded = false;
      services.forEach(service => {
        if (service.catagory_name === "TOURING HOSTS"){
          if (service.service_id === 2){
            setSkiTourSlopesDetail(service);
            setCheckboxSelectedSkiTourSlopes(service.is_provided);
            setSkiTourSlopes(service);
            isExpanded = true;
          }
          if (service.service_id === 3){
            setSnowboardTourSlopesDetail(service);
            setCheckboxSelectedSnowTourSlopes(service.is_provided);
            setSnowBoardTourSlopes(service);
            isExpanded = true;
          }
          if (service.service_id === 4){
            setTourVillageDetail(service);
            setCheckboxSelectedTourVillage(service.is_provided);
            setTourVillage(service);
            isExpanded = true;
          }
          if (service.service_id === 5){
            setBackCountryDetail(service);
            setCheckboxSelectedBackCountry(service.is_provided);
            setBackCountry(service);
            isExpanded = true;
          }
          if (service.service_id === 6){
            setHikingDetail(service);
            setCheckboxSelectedHiking(service.is_provided);
            setHiking(service);
            isExpanded = true;
          }
          if (service.service_id === 7){
            setSkiBackCountryTouringDetail(service);
            setSkiBackCountryTouring(service.is_provided);
            setSkiBackCountryTouring(service);
            isExpanded = true;
          }
          if (service.service_id === 8){
            setSnowBackCountryTouringDetail(service);
            setSnowBackCountryTouring(service.is_provided);
            setSnowBackCountryTouring(service);
            isExpanded = true;
          }
          if (service.service_id === 9){
            setSnowshoeingDetail(service);
            setSnowShoeing(service.is_provided);
            setSnowShoeing(service);
            isExpanded = true;
          }
          if (service.service_id === 10){
            setSnowMobilingDetail(service);
            setSnowMobiling(service.is_provided);
            setSnowMobiling(service);
            isExpanded = true;
          }
          if (service.service_id === 11){
            setBikeRidingDetail(service);
            setCheckboxSelectedBikeRiding(service.is_provided);
            setBikeRiding(service);
            isExpanded = true;
          }
        }
      });
      isExpanded ? setCheckboxSelected(true) : setCheckboxSelected(false);
    }
  }, [services]);

  // Set top level collapsebox state
  // useEffect(() => {
  //   if (services.length > 0 && services !== undefined) {
  //     for (let service of services) {

  //     }
  //     services.forEach(service => {
  //       if (service.catagory_name === "TOURING HOSTS"){
  //         if (service.is_provided){
  //           setCheckboxSelected(true);
  //         }

  //       }
  //     });
  //   }
 
  // }, [services])
  
  // Generate UI Components
  const SkiTourSlopes = (parent, nestingLevel) => {
    const inputFields = () => {
      return (
        <CardContent>
          <Grid
            container
            justify="center"
            alignItems="center"
            xs={8}
            md={8}
            lg={8}
          >
            <MaxGroupSize onTextChange={addSkiToursSlopeValues} groupSize={skiTourSlopesDetail.max_group_size} isDisabled={isDisabled}/>
            <PricePerHead onTextChange={addSkiToursSlopeValues} pricePerHead={skiTourSlopesDetail.price_per_head} isDisabled={isDisabled}/>
            <AdditionalGuests onTextChange={addSkiToursSlopeValues} guestPrice={skiTourSlopesDetail.additional_guests_price} isDisabled={isDisabled}/>
          </Grid>
        </CardContent>
      );
    };

    const children = [inputFields];

    return (
      <CollapsibleCheckBox
        name={"SkiTourSlopes"}
        text="SKI TOUR OF THE SLOPES"
        parent={parent}
        children={children}
        onCheckboxSelected={onSkiTourSlopesSelected}
        nestingLevel={nestingLevel + 1}
        isDisabled={isDisabled}
        isSelected={checkboxSelectedSkiTourSlopes}
      />
    );
  };

  const SnowTourSlopes = (parent, nestingLevel) => {
    const inputFields = () => {
      return (
        <CardContent>
          <Grid
            container
            justify="center"
            alignItems="center"
            xs={8}
            md={8}
            lg={8}
          >
            <MaxGroupSize onTextChange={addSnowBoardToursSlopeValues} groupSize={snowboardTourSlopesDetail.max_group_size} isDisabled={isDisabled}/>
            <PricePerHead onTextChange={addSnowBoardToursSlopeValues} pricePerHead={snowboardTourSlopesDetail.price_per_head} isDisabled={isDisabled}/>
            <AdditionalGuests onTextChange={addSnowBoardToursSlopeValues} guestPrice={snowboardTourSlopesDetail.additional_guests_price} isDisabled={isDisabled}/>
          </Grid>
        </CardContent>
      );
    };

    const children = [inputFields];

    return (
      <CollapsibleCheckBox
        name={"SnowTourSlopes"}
        text="SNOWBOARD TOUR OF THE SLOPES"
        parent={parent}
        children={children}
        nestingLevel={nestingLevel + 1}
        onCheckboxSelected={onSnowTourSlopesSelected}
        isDisabled={isDisabled}
        isSelected={checkboxSelectedSnowTourSlopes}
      />
    );
  };

  const TourVillage = (parent, nestingLevel) => {
    const inputFields = () => {
      return (
        <CardContent>
          <Grid
            container
            justify="center"
            alignItems="center"
            xs={8}
            md={8}
            lg={8}
          >
            <MaxGroupSize onTextChange={addTourVillageValues} groupSize={tourVillageDetail.max_group_size} isDisabled={isDisabled}/>
            <PricePerHead onTextChange={addTourVillageValues} pricePerHead={tourVillageDetail.price_per_head} isDisabled={isDisabled}/>
            <AdditionalGuests onTextChange={addTourVillageValues} guestPrice={tourVillageDetail.additional_guests_price} isDisabled={isDisabled}/>
          </Grid>
        </CardContent>
      );
    };

    const children = [inputFields];

    return (
      <CollapsibleCheckBox
        name={"TourVillage"}
        text="WALKING TOUR OF THE VILLAGE"
        parent={parent}
        children={children}
        nestingLevel={nestingLevel + 1}
        onCheckboxSelected={onTourVillageSelected}
        isDisabled={isDisabled}
        isSelected={checkboxSelectedTourVillage}
      />
    );
  };

  const BackCountry = (parent, nestingLevel) => {
    const inputFields = () => {
      return (
        <CardContent>
          <Grid
            container
            justify="center"
            alignItems="center"
            xs={8}
            md={8}
            lg={8}
          >
            <MaxGroupSize onTextChange={addBackCountryValues} groupSize={backCountryDetail.max_group_size} isDisabled={isDisabled}/>
            <PricePerHead onTextChange={addBackCountryValues} pricePerHead={backCountryDetail.price_per_head} isDisabled={isDisabled}/>
            <AdditionalGuests onTextChange={addBackCountryValues} guestPrice={backCountryDetail.additional_guests_price} isDisabled={isDisabled}/>
          </Grid>
        </CardContent>
      );
    };

    const children = [inputFields];

    return (
      <CollapsibleCheckBox
        name={"Backcountry"}
        text="EBIKE BACKCOUNTRY TOURING (summer only)"
        parent={parent}
        children={children}
        nestingLevel={nestingLevel + 1}
        onCheckboxSelected={onBackCountrySelected}
        isDisabled={isDisabled}
        isSelected={checkboxSelectedBackCountry}
      />
    );
  };

  const Hiking = (parent, nestingLevel) => {
    const classes = useStyles();

    const inputFields = () => {
      return (
        <CardContent>
          <Grid
            container
            justify="center"
            alignItems="center"
            xs={8}
            md={8}
            lg={8}
          >
            <MaxGroupSize onTextChange={addHikingValues} groupSize={hikingDetail.max_group_size} isDisabled={isDisabled}/>
            <PricePerHead onTextChange={addHikingValues} pricePerHead={hikingDetail.price_per_head} isDisabled={isDisabled}/>
            <AdditionalGuests onTextChange={addHikingValues} guestPrice={hikingDetail.additional_guests_price} isDisabled={isDisabled}/>
          </Grid>
        </CardContent>
      );
    };

    const children = [inputFields];

    return (
      <CollapsibleCheckBox
        name={"Hiking"}
        text="HIKING"
        parent={parent}
        children={children}
        nestingLevel={nestingLevel + 1}
        onCheckboxSelected={onHikingSelected}
        isDisabled={isDisabled}
        isSelected={checkboxSelectedHiking}
      />
    );
  };

  const SkiBackCountryTouring = (parent, nestingLevel) => {
    const inputFields = () => {
      return (
        <CardContent>
          <Grid
            container
            justify="center"
            alignItems="center"
            xs={8}
            md={8}
            lg={8}
          >
            <MaxGroupSize onTextChange={addSkiBackCountryTouring} groupSize={skiBackCountryTouringDetail.max_group_size} isDisabled={isDisabled}/>
            <PricePerHead onTextChange={addSkiBackCountryTouring} pricePerHead={skiBackCountryTouringDetail.price_per_head} isDisabled={isDisabled}/>
            <AdditionalGuests onTextChange={addSkiBackCountryTouring} guestPrice={skiBackCountryTouringDetail.additional_guests_price} isDisabled={isDisabled}/>
          </Grid>
        </CardContent>
      );
    };

    const children = [inputFields];

    return (
      <CollapsibleCheckBox
        name={"SkiBackCountryTouring"}
        text="SKI BACKCOUNTRY TOURING (min 4 hours)"
        parent={parent}
        children={children}
        nestingLevel={nestingLevel + 1}
        onCheckboxSelected={onSkiBackCountryTouringSelected}
        isDisabled={isDisabled}
        isSelected={checkboxSelectedSkiBackCountryTouring}
      />
    );
  };

  const SnowBackCountryTouring = (parent, nestingLevel) => {
    const inputFields = () => {
      return (
        <CardContent>
          <Grid
            container
            justify="center"
            alignItems="center"
            xs={8}
            md={8}
            lg={8}
          >
            <MaxGroupSize onTextChange={addSnowBackCountryTouring} groupSize={snowBackCountryTouringDetail.max_group_size} isDisabled={isDisabled}/>
            <PricePerHead onTextChange={addSnowBackCountryTouring} pricePerHead={snowBackCountryTouringDetail.price_per_head} isDisabled={isDisabled}/>
            <AdditionalGuests onTextChange={addSnowBackCountryTouring} guestPrice={snowBackCountryTouringDetail.additional_guests_price} isDisabled={isDisabled}/>
          </Grid>
        </CardContent>
      );
    };

    const children = [inputFields];

    return (
      <CollapsibleCheckBox
        name={"SnowBackCountryTouring"}
        text="SNOWBOARD BACKCOUNTRY TOURING (min 4 hours)"
        parent={parent}
        children={children}
        nestingLevel={nestingLevel + 1}
        onCheckboxSelected={onSnowBackCountryTouringSelected}
        isDisabled={isDisabled}
        isSelected={checkboxSelectedSnowBackCountryTouring}
      />
    );
  };

  const Snowshoeing = (parent, nestingLevel) => {
    const classes = useStyles();

    const inputFields = () => {
      return (
        <CardContent>
          <Grid
            container
            justify="center"
            alignItems="center"
            xs={8}
            md={8}
            lg={8}
          >
            <MaxGroupSize onTextChange={addSnowShoeingTouring} groupSize={snowshoeingDetail.max_group_size} isDisabled={isDisabled}/>
            <PricePerHead onTextChange={addSnowShoeingTouring} pricePerHead={snowshoeingDetail.price_per_head} isDisabled={isDisabled}/>
            <AdditionalGuests onTextChange={addSnowShoeingTouring} guestPrice={snowshoeingDetail.additional_guests_price} isDisabled={isDisabled}/>
          </Grid>
        </CardContent>
      );
    };

    const children = [inputFields];

    return (
      <CollapsibleCheckBox
        name={"Snowshoeing"}
        text="SNOWSHOEING (min 2 hours)"
        parent={parent}
        children={children}
        nestingLevel={nestingLevel + 1}
        onCheckboxSelected={onSnowShoeingSelected}
        isDisabled={isDisabled}
        isSelected={checkboxSelectedSnowShoeing}
      />
    );
  };

  const SnowMobiling = (parent, nestingLevel) => {
    const inputFields = () => {
      return (
        <CardContent>
          <Grid
            container
            justify="center"
            alignItems="center"
            xs={8}
            md={8}
            lg={8}
          >
            <MaxGroupSize onTextChange={addSnowMobilingTouring} groupSize={snowMobilingDetail.max_group_size} isDisabled={isDisabled}/>
            <PricePerHead onTextChange={addSnowMobilingTouring} pricePerHead={snowMobilingDetail.price_per_head} isDisabled={isDisabled}/>
            <AdditionalGuests onTextChange={addSnowMobilingTouring} guestPrice={snowMobilingDetail.additional_guests_price} isDisabled={isDisabled}/>
          </Grid>
        </CardContent>
      );
    };

    const children = [inputFields];

    return (
      <CollapsibleCheckBox
        name={"SnowMobiling"}
        text="SNOWMOBILING (min 2 hours)"
        parent={parent}
        children={children}
        nestingLevel={nestingLevel + 1}
        onCheckboxSelected={onSnowMobilingSelected}
        isDisabled={isDisabled}
        isSelected={checkboxSelectedSnowMobiling}
      />
    );
  };

  const BikeRiding = (parent, nestingLevel) => {
    const inputFields = () => {
      return (
        <CardContent>
          <Grid
            container
            justify="center"
            alignItems="center"
            xs={8}
            md={8}
            lg={8}
          >
            <MaxGroupSize onTextChange={addBikeRiding} groupSize={bikeRidingDetail.max_group_size} isDisabled={isDisabled}/>
            <PricePerHead onTextChange={addBikeRiding} pricePerHead={bikeRidingDetail.price_per_head} isDisabled={isDisabled}/>
            <AdditionalGuests onTextChange={addBikeRiding} guestPrice={bikeRidingDetail.additional_guests_price} isDisabled={isDisabled}/>
          </Grid>
        </CardContent>
      );
    };

    const children = [inputFields];

    return (
      <CollapsibleCheckBox
        name={"BikeRiding"}
        text="BIKE RIDING (summer only) (min 2 hours duration)"
        parent={parent}
        children={children}
        nestingLevel={nestingLevel + 1}
        onCheckboxSelected={onBikeRidingSelected}
        isDisabled={isDisabled}
        isSelected={bikeRidingSelected}
      />
    );
  };

  const children = [
    SkiTourSlopes,
    SnowTourSlopes,
    TourVillage,
    BackCountry,
    Hiking,
    SkiBackCountryTouring,
    SnowBackCountryTouring,
    Snowshoeing,
    SnowMobiling,
    BikeRiding
  ];

  return (
    <CollapsibleCheckBox
      name={"Tours"}
      text={PROVIDER_SERVICES.TOURS}
      parent={null}
      children={children}
      nestingLevel={0}
      onCheckboxSelected={onCheckboxSelected}
      isDisabled={isDisabled}
      isSelected={checkboxSelected}
    />
  );
};

export default Tours;
