import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Store from "../../../redux/store";
import "react-calendar-timeline/lib/Timeline.css";
//import {translateServiceData_new} from "../../../redux/actions/stepperActions.js"
// Components
import ItemsCarousel from "react-items-carousel";
//mport Rating from 'material-ui-rating';
//data
import { getProviderProfile } from "../../../redux/actions/stepperActions";
import agent from "../../../agent.js";

//import Constants
import {
  FIRST_TIMER_API_VALUE,
  FIRST_TIMER_VALUE,
  SERVICES_NAMES,
  SKI,
  SNOWBOARD
} from "../../../constants/common.js";

//
import clsx from "clsx";

// Style
import useStyles from "../../Common/SignupForm/ClientInfoStyles";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import { getCountryList } from "../../../redux/actions/homePageAction.js";
import {TextContent, ListContent} from "../../Common/SignupForm/ProviderInfo/Services/InputFields";
import {translateServiceData_new} from "../../../redux/actions/stepperActions.js"
import {
  Typography,
  Grid,
  Avatar,
  Box,
  Paper,
  ImageList,
  ImageListItem,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  ListSubheader,
  FormLabel,
  Checkbox,
  ListItemText,
  Hidden
} from "@material-ui/core";
import CountryResortSelection from "../../../pages/serviceMarket/profile/CountryResortSelection";


const useClasses = makeStyles(theme => ({
  arrowIcon: {
    transform: "rotate(180deg)"
  },
  root: {
    margin: "0 auto",
    width: "100%",
    marginTop: "2rem",
    alignItems: "center"
  },
  backButton: {
    margin: "3em",
    borderColor: "#FF6161",
    color: "#FF6161"
  },
  avatarSize: {
    width: "10rem",
    height: "10rem",
    marginBottom: "15%",
    [theme.breakpoints.down("sm")]: {
      width: "10rem",
      height: "10rem"
    }
  },
  editProfileBtn: {
    borderRadius: 100,
    background: "#FF6161",
    width: "70%",
    fontSize: 14,
    lineHeight: "140.62%",
    backgroundColor: "#FF6161",
    color: "white",
    fontWeight: "normal",
    letterSpacing: "0.01em",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "40%"
    }
  },
  textName: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#1277B8",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      marginTop: "2rem",
      textAlign: "center"
    }
  },
  viewUpcommingBtn: {
    background: "#FF6161",
    width: "70%",
    fontSize: 14,
    lineHeight: "140.62%",
    backgroundColor: "#FF6161",
    color: "white",
    fontWeight: "normal",
    letterSpacing: "0.01em",
    borderRadius: 100,
    [theme.breakpoints.down("sm")]: {
      width: "40%",
      marginTop: "1rem",
      marginBottom: "1rem"
    }
  },
  textFieldFormat: {
    color: "#000000",
    width: "100%",
    height: "9%",
    fontSize: 14,
    resize: "none",
    border: "0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "1rem",
      marginBottom: "2rem",
      color: "#000000"
    }
  },
  divAvatar: {
    textAlign: "center",
    margin: "0 auto"
  },
  ratingFormat: {
    textAlign: "center",
    margin: "0 auto",
    width: "fit-content"
  },
  commonFormat: {
    fontStyle: "normal",
    fontFamily: "PT Sans Narrow",
    fontSize: "16px",
    color: "#000000"
  },
  nameLabel: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "30px",
    color: "#000000"
  },
  label: {
    marginLeft: "7.5%",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    marginBottom: "0.5em",
    textTransform: "uppercase",
    color: "#000000"
  },
  galleryLabel: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "26px",
    color: "#000000"
  },
  headlineText: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: '36.56px',
    display: "flex",
    alignItems: "center",
    color: "#000000",
  },
  option:{
    backgroundColor: 'white',
    fontFamily: "PT Sans",
    fontStyle: "regular",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "25.31px",
    color: "black"
  },
  listSubheader:{
    fontFamily: "PT Sans",
    fontStyle: "regular",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "25.31px",
    color: "grey",
  },

  formControl:{
    "&:hover": {
      border: 'none',
      outline:'none'
    },

  },
  selectDropdown:{
    backgroundColor: 'white',
    margin: '0',
    width: '100%',
    outline: 'none',
    border: '1.4px solid black',
    borderRadius: '10px',
    minWidth: '100px',
    "&:before":{
      border: 'none'
    },
    "&:after": {
      border: 'none'
    },
    "&:active": {
      border: 'none',
      outline:'none',
      backgroundColor: 'white'
    },
    "&:focus": {
      backgroundColor: 'white'
    },
    "&:hover": {
      border: 'none',
      outline:'none',
      backgroundColor: 'white'
    },
  },

  inputLabel: {
    color: "#000000",
    padding:'0px 0px 6px 7px',
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "25.5px",
  },

  heightServices:
  {    
    [theme.breakpoints.up("lg")]: {
      height: "200px"
    }  
  },

  activityHeight:
  {   
     
    [theme.breakpoints.down("xs")]: {
      height: "120px"
    },
    [theme.breakpoints.only("sm")]: {
      height: "150px"
    },
    [theme.breakpoints.only("lg")]: {
      height: "200px"
    },
  },

  alignInXS:
  {
    [theme.breakpoints.down("xs")]: {
      marginLeft: "20px"
    }
  }


}));

function ClientView(props) {
  const store = Store.getState();

  const styles = useStyles();
  const classes = useClasses();
  const { user_id } =  useParams();
  const { avatar, profileData, userProfile } = props;

 
  let [activeItem, setActiveItem] = useState(1); //Image Gallery
  let [isLoading, setIsLoading] = useState(true);
  let [profilePicture, setProfilePicture] = useState("");
  let [name, setName] = useState("");
  let [bio, setBio] = useState("");
  let [nationality, setNationality] = useState("");
  let [languages, setLanguages] = useState([]);
  let [skiLevel, setSkiLevel] = useState("");
  let [snowboardLevel, setSnowboardLevel] = useState("");
  
  let [gallery, setGallery] = useState([]);
 

useEffect(() => {
    setIsLoading(false)
    agent.Users.getClientProfile(user_id)
    .then((res)=>{
        const client=res.data
        console.log(client)
        setName(client.first_name + " " + client.last_name);
        setBio(client.bio);
        setProfilePicture(client.profile_picture);
        setLanguages(client.languages);
        setNationality(client.country.country_name);


        if (client.skiing_level == 1) {
            setSkiLevel("Ski - Level 1 - First Timer");
        } else if (client.skiing_level == 2) {
            setSkiLevel("Ski - Level 2 - Low Beginner");
        } else if (client.skiing_level == 3) {
            setSkiLevel("Ski - Level 3 - High Beginner");
        } else if (client.skiing_level == 4) {
            setSkiLevel("Ski - Level 4 - Low Intermediate");
        } else if (client.skiing_level == 5) {
            setSkiLevel("Ski - Level 5 - High Intermediate");
        } else if (client.skiing_level == 6) {
            setSkiLevel("Ski - Level 6 - Low Advanced");
        } else if (client.skiing_level == 7) {
            setSkiLevel("Ski - Level 7 - High Advanced");
        }
            
                  
        if (client.snowboarding_level == 1) {
            setSnowboardLevel("Snowboard - Level 1 - First Timer");
        } else if (client.snowboarding_level == 2) {
            setSnowboardLevel("Snowboard - Level 2 - Low Beginner");
        } else if (client.snowboarding_level == 3) {
            setSnowboardLevel("Snowboard - Level 3 - High Beginner");
        } else if (client.snowboarding_level == 4) {
            setSnowboardLevel("Snowboard - Level 4 - Low Intermediate");
        } else if (client.snowboarding_level == 5) {
            setSnowboardLevel("Snowboard - Level 5 - High Intermediate");
        } else if (client.snowboarding_level == 6) {
            setSnowboardLevel("Snowboard - Level 6 - Low Advanced");
        } else if (client.snowboarding_level == 7) {
            setSnowboardLevel("Snowboard - Level 7 - High Advanced");
        }
        

        
        
    })


  //gallery
  agent.Users.getGallery(user_id).then((res)=>
  {
    //onsole.log(res.data)
    setGallery(res.data)
  }).catch(error => {
    console.log("error", error);
  })


  
}, []);


  const handleSetActiveItem = value => {
    setActiveItem(value);
  };



  return (
      <div>
      {isLoading ? (
        <div className="loader-container">
          <div className="loader" />
        </div>
      ) : (
        <div>
          <Grid
            container
            justify="center"
            alignItems="center"
            spacing={0}
            direction="column"
            style={{ padding: "2rem 15px" }}
          >
            {/* Profile Picture, Bio */}
            <Grid
              container
              justify="center"
              alignItems="center"
              xs={12}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              direction="row"
            >
              <Grid
                item
                container
                xs={12}
                sm={4}
                direction="column"
                justify="center"
                alignItems="center"
              >
                {profilePicture == undefined ? (
                  <Avatar className={classes.avatarSize} src={avatar} />
                ) : (
                  <Avatar className={classes.avatarSize}>
                    <img src={profilePicture} />
                  </Avatar>
                )}
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={4}
                md={12}
                lg={8}
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
                
              >
                <Grid
                item
                container   
                xs={12}
                sm={12}
                lg={12}    
                direction="row"
                justify="space-between"
                alignItems="center"
                display="flex"
                flexWrap="wrap"
              >
                
                  <Typography className={classes.nameLabel}>{name}</Typography>
                  
                </Grid>

                <Grid item lg={12}>
                <Typography className={classes.commonFormat}>
                    {bio}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* Nationality, Languages, Abilities */}
            <Grid
              container
              justify="center"
              alignItems="flex-start"
              xs={12}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              direction="row"
              style={{ padding: "2rem 0px" }}
            >
              {/* Nationality */}
              <Grid
                item
                container
                xs={12}
                sm={4}
                direction="column"
                justify="center"
                alignItems="left"
                style={{ marginBottom: "1rem" }}
                className={classes.alignInXS}
              >
                <Grid item style={{ padding: "0.25rem 0" }}>
                  <Typography className={styles.allergyandotherinformation}>
                    NATIONALITY
                  </Typography>
                </Grid>
                <Grid item container justify="left" alignItems="left">
                  <Typography className={classes.commonFormat}>
                    {nationality}
                  </Typography>
                </Grid>
              </Grid>

              {/* Languages */}
              <Grid
                item
                container
                xs={12}
                sm={4}
                direction="column"
                justify="center"
                alignItems="left"
                style={{ marginBottom: "1rem" }}
                className={classes.alignInXS}
              >
                <Grid item style={{ padding: "0.25rem 0" }}>
                  <Typography className={styles.allergyandotherinformation}>
                    LANGUAGES
                  </Typography>
                </Grid>
                <Grid item container justify="left" alignItems="left">
                  <Typography className={classes.commonFormat}>
                    {languages != undefined
                      ? languages.map(language => (
                          <div>{language.language}</div>
                        ))
                      : null}
                  </Typography>
                </Grid>
              </Grid>

              {/* Abilities */}
              <Grid
                item
                container
                xs={12}
                sm={4}
                direction="column"
                justify="center"
                alignItems="left"
                style={{ marginBottom: "1rem" }}
                className={classes.alignInXS}
              >
                <Grid item style={{ padding: "0.25rem 0" }}>
                  <Typography className={styles.allergyandotherinformation}>
                    ABILITY LEVEL
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  justify="left"
                  alignItems="left"
                >
                  <Typography className={classes.commonFormat} align="left">
                    {skiLevel}
                  </Typography>
                  <Typography className={classes.commonFormat} align="left">
                    {snowboardLevel}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>


          {/* Image Gallery Header */}
            <Grid
              container
              justify="center"
              alignItems="flex-start"
              xs={12}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              direction="row"
            >
              <Grid item xs={12} sm={12}>
                <Typography align="center" className={classes.galleryLabel}>
                  Gallery
                </Typography>
              </Grid>
            </Grid>

            {/* Image Gallery Carousel */}
            <Grid
              container
              justify="center"
              alignItems="flex-start"
              xs={10}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              direction="row"
            >
              <Grid
                item
                justify="center"
                alignItems="center"
                xs={10}
                sm={10}
                style={{ paddingTop: "1.5rem" }}
              >
                <Hidden xsDown>
                  <ItemsCarousel
                    enablePlaceholder={true}
                    numberOfPlaceholderItems={3}
                    gutter={12}
                    activePosition={"center"}
                    chevronWidth={100}
                    disableSwipe={false}
                    alwaysShowChevrons={false}
                    numberOfCards={3}
                    slidesToScroll={1}
                    outsideChevron={true}
                    showSlither={false}
                    firstAndLastGutter={false}
                    activeItemIndex={activeItem}
                    requestToChangeActive={value => handleSetActiveItem(value)}
                    rightChevron={<ArrowForwardIosOutlinedIcon />}
                    leftChevron={<ArrowBackIosOutlinedIcon />}
                  >
                    {gallery!==undefined&&gallery.map(item =>
                      activeItem == item.id ? (
                        <img
                          src={`${item.image_link}&fit=crop&auto=format`}
                          alt={item.title}
                          loading="lazy"
                          style={{
                            width: "154px",
                            height: "154px",
                            margin: "1.5rem",
                            transform: "scale( 1.3 )",
                            transition: "transform .1s"
                          }}
                        />
                      ) : (
                        <img
                          src={`${item.image_link}&fit=crop&auto=format`}
                          alt={item.title}
                          loading="lazy"
                          style={{
                            width: "154px",
                            height: "154px",
                            margin: "1.5rem",
                            transform: "scale( 0.75 )"
                          }}
                        />
                      )
                    )}
                  </ItemsCarousel>
                </Hidden>
                <Hidden smUp>
                  <ItemsCarousel
                    enablePlaceholder={true}
                    numberOfPlaceholderItems={3}
                    gutter={12}
                    activePosition={"center"}
                    chevronWidth={100}
                    disableSwipe={false}
                    alwaysShowChevrons={false}
                    numberOfCards={1}
                    slidesToScroll={1}
                    outsideChevron={true}
                    showSlither={false}
                    firstAndLastGutter={false}
                    activeItemIndex={activeItem}
                    requestToChangeActive={value => handleSetActiveItem(value)}
                    rightChevron={<ArrowForwardIosOutlinedIcon />}
                    leftChevron={<ArrowBackIosOutlinedIcon />}
                  >
                    {gallery.map(item => (
                      <img
                        src={`${item.image_link}&fit=crop&auto=format`}
                        alt={item.title}
                        loading="lazy"
                        style={{
                          width: "200px",
                          height: "200px"
                        }}
                      />
                    ))}
                  </ItemsCarousel>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}



// Reducer part
function mapStateToProps(state, ownProps) {
  
  return {
    profileData: state.profileDataReducer.profileData,   //client data
    userProfile: state.stepperReducer.userProfile,       //Provider data
    country_list: state.homePageReducer.country_list
  };
}

// Adding Dispatch to update the existing values in Redux
const mapDispatchToProps = dispatch => {
  return {
    getCountryList: () => dispatch(getCountryList())
  };
};

// Connect part
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientView);