import React from "react";
import useStyles from "./MyAccountStyles";
import { Grid, TextField } from "@material-ui/core";

const Resorts = ({
  studentData,
  filterData,
  _editCountry,
  select,
  disabled
}) => {
  const classes = useStyles();

  return (
    <Grid item container md={12} direction="row">
      <Grid item lg={2} md={2} sm={2} xs={2}>
        <div align="right" className={classes.textFieldStyle}>
          Resort/s:
        </div>
      </Grid>

      <Grid item lg={1} md={1} sm={1} xs={1} />

      <Grid item container lg={9} md={9} sm={9} xs={9}>
        <Grid item lg={5} md={5} sm={5} xs={12}>
          <div align="left">
            <TextField
              id="standard-select-language"
              disabled={disabled}
              select={select}
              value={studentData.country}
              onChange={event => _editCountry(event.target.value)}
              variant="outlined"
              SelectProps={{
                native: true
              }}
              className={classes.textFieldLong}
            >
              {filterData.country.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>
        </Grid>
        <Grid item lg={1} md={1} sm={1} />
        <Grid item lg={5} md={5} sm={5} xs={12}>
          <div align="left">
            <TextField
              id="standard-select-language"
              disabled={disabled}
              select={select}
              value={studentData.country}
              onChange={event => _editCountry(event.target.value)}
              variant="outlined"
              SelectProps={{
                native: true
              }}
              className={classes.textFieldLong}
            >
              {filterData.country.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>
        </Grid>
        <Grid item lg={1} md={1} sm={1} />
        <Grid item container lg={5} md={5} sm={5} xs={12} direction="row">
          <Grid item align="center" lg={6} md={6} sm={6} xs={6}>
            <button
              color="primary"
              variant="contained"
              className={classes.btnResortAdd}
            >
              Add
            </button>
          </Grid>
          <Grid item align="center" lg={6} md={6} sm={6} xs={6}>
            <button
              color="primary"
              variant="contained"
              className={classes.btnResortRemove}
            >
              Remove
            </button>
          </Grid>
        </Grid>
        <Grid item lg={7} md={7} sm={7} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <div className={classes.emptyCard3} />
      </Grid>
    </Grid>
  );
};

export default Resorts;
