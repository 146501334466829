import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import "../../../styling/css/template/SliderBar.css";

export default function SkillRangeInputForExploreInstructors(props) {
  const { min, max, label, input } = props;

  const useStyles = makeStyles(theme => ({
    label: {
      fontWeight: "bold",
      fontSize: "15px",
      color: "black",
      textAlign: "left"
    },
    bar: {
      width: "70%"
    },
    value: {
      width: "5%",
      fontWeight: "bold",
      paddingLeft: "10px"
    }
  }));

  const classes = useStyles();

  return (
    <Grid container alignItems="center" className="form-row ">
      <Grid item className={classes.label} md={3} sm={3} xs={3}>
        {label}
        {/* <label style={{ fontWeight: "bold", fontSize: "15px", color: "black" }}>
          {label}
        </label> */}
      </Grid>
      <Grid item className={classes.bar} md={8} sm={8} xs={8}>
        <input
          type="range"
          className="color-slider-bar"
          min={min}
          max={max}
          step={1}
          value={input.value}
          onChange={input.onChange}
        />
      </Grid>
      <Grid item className={classes.value} md={1} sm={1} xs={1}>
        {input.value}
      </Grid>
    </Grid>
  );
}
