import {
  EDIT_NAME,
  EDIT_BIO,
  EDIT_COUNTRYCODE,
  EDIT_PHONENUMBER,
  EDIT_EMAIL,
  EDIT_PASSWORD,
  EDIT_GENDER,
  EDIT_EXPERIENCE,
  EDIT_AGE,
  EDIT_EXPERIENCE_INS,
  EDIT_EXPERIENCE_LOCAL,
  EDIT_LANGUAGES,
  EDIT_ABILITIES,
  EDIT_DISABILITIES,
  EDIT_SPORT_SPECIAL,
  EDIT_PRE_LANGUAGE,
  EDIT_PRE_CURRENCY,
  EDIT_TIMEZONE,
  EDIT_QUALIFICATIONS,
  EDIT_RESORTS,
  EDIT_COUNTRY,
  EDIT_SPECIALIATIES,
  EDIT_TARGET_AUDIENCE
} from "./action-types";

export const editName = name => {
  return {
    type: EDIT_NAME,
    payload: name
  };
};

export const editBio = bio => {
  return {
    type: EDIT_BIO,
    payload: bio
  };
};

export const editCountrycode = countrycode => {
  return {
    type: EDIT_COUNTRYCODE,
    payload: countrycode
  };
};

export const editPhonenumber = phonenumber => {
  return {
    type: EDIT_PHONENUMBER,
    payload: phonenumber
  };
};

export const editEmail = email => {
  return {
    type: EDIT_EMAIL,
    payload: email
  };
};

export const editPassword = password => {
  return {
    type: EDIT_PASSWORD,
    payload: password
  };
};

export const editGender = gender => {
  return {
    type: EDIT_GENDER,
    payload: gender
  };
};

export const editExperience = experience => {
  return {
    type: EDIT_EXPERIENCE,
    payload: experience
  };
};

export const editAge = age => {
  return {
    type: EDIT_AGE,
    payload: age
  };
};
export const editExperienceIns = experienceIns => {
  return {
    type: EDIT_EXPERIENCE_INS,
    payload: experienceIns
  };
};
export const editExperienceLocal = experienceLocal => {
  return {
    type: EDIT_EXPERIENCE_LOCAL,
    payload: experienceLocal
  };
};
export const editLanguages = languages => {
  return {
    type: EDIT_LANGUAGES,
    payload: languages
  };
};
export const editAbilities = abilities => {
  return {
    type: EDIT_ABILITIES,
    payload: abilities
  };
};
export const editDisabilities = disabilities => {
  return {
    type: EDIT_DISABILITIES,
    payload: disabilities
  };
};
export const editSportSpecial = sportAndSpecialisation => {
  return {
    type: EDIT_SPORT_SPECIAL,
    payload: sportAndSpecialisation
  };
};
export const editPreferlanguage = preferLanguage => {
  return {
    type: EDIT_PRE_LANGUAGE,
    payload: preferLanguage
  };
};
export const editPrefercurrency = preferCurrency => {
  return {
    type: EDIT_PRE_CURRENCY,
    payload: preferCurrency
  };
};
export const editTimezone = timeZone => {
  return {
    type: EDIT_TIMEZONE,
    payload: timeZone
  };
};
export const editQualifications = qualifications => {
  return {
    type: EDIT_QUALIFICATIONS,
    payload: qualifications
  };
};
export const editResorts = resorts => {
  return {
    type: EDIT_RESORTS,
    payload: resorts
  };
};
export const editCountry = country => {
  return {
    type: EDIT_COUNTRY,
    payload: country
  };
};
export const editSpecialiaties = specialiaties => {
  return {
    type: EDIT_SPECIALIATIES,
    payload: specialiaties
  };
};
export const editTargetaudience = targetAudience => {
  return {
    type: EDIT_TARGET_AUDIENCE,
    payload: targetAudience
  };
};
