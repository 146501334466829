import agent from "../../agent";
import {
UPDATE_PROVIDER_PROFILE_SUCCESS_REQUEST,
UPDATE_PROVIDER_PROFILE_SUCCESS,
UPDATE_PROVIDER_PROFILE_FAILURE
} from "./action-types";

export const updateProviderProfile = provider => async dispatch => {
  dispatch({ type: UPDATE_PROVIDER_PROFILE_SUCCESS_REQUEST});
  try {
    console.log("sending data to DB");
    const response = await agent.Users.updateProvider(
      provider.providerId,
      provider.user,
      provider.provider_bio,
      provider.resorts,
      provider.suitable_fors
    );
    dispatch({ type: UPDATE_PROVIDER_PROFILE_SUCCESS, payload: true});
  } catch (error) {
    const message =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    dispatch({ type: UPDATE_PROVIDER_PROFILE_FAILURE, payload: message });
  }
};