//this is the card for the instructor to set their availability
import React from "react";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import "react-day-picker/lib/style.css";
import UnavailabilitySettings from "./UnavailabilitySettings";
import AvailabilitySettings from "./AvailabilitySettings";
import {
  toggleVirtInstructorLike,
  createBooking,
  adjustUnavailability
} from "../../../redux/actions/virtInstructorDataActions";

const useStyles = makeStyles({
  root: {
    width: "100%",
    padding: "20px 10% 20px 10%"
  }
});

//TO BE CLEAR
//Unavailability refers to specfic days that the instructor is unavailable- perhaps on a holiday
//Availability refers to REPEATED SCHEDULED days that the instructor is available - every monday-friday 9-5 for example
//This card deals with both
const AvailabilityCard = props => {
  const classes = useStyles();
  //1st screen is Month but uneditable
  //2nd screen is Weekly schedule
  //3rd screen is Month but editable
  const [screenNumber, setScreenNumber] = React.useState(1);
  const updateTime = props.updateTime;

  //render

  const unavailabilitySettings = (
    <UnavailabilitySettings
      updateTime={updateTime}
      screenNumber={screenNumber}
      setScreenNumber={setScreenNumber}
    />
  );

  const availabilitySettings = (
    <AvailabilitySettings
      screenNumber={screenNumber}
      setScreenNumber={setScreenNumber}
    />
  );

  const renderCardScreen = () => {
    switch (screenNumber) {
      case 1:
        return availabilitySettings;
      case 2:
        return unavailabilitySettings;
      default:
        break;
    }
  };

  return (
    <Card className={classes.root} variant="outlined">
      {renderCardScreen()}
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    instructorData: state.virtualInstructorReducer.currentProfile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleVirtInstructorLike: virtInstructorId =>
      dispatch(toggleVirtInstructorLike(virtInstructorId)),
    createBooking: (id, resort, chosenday, timeslot) =>
      dispatch(createBooking(id, resort, chosenday, timeslot)),
    adjustUnavailabilityReduxState: (resort_info_id, dates) =>
      dispatch(adjustUnavailability(resort_info_id, dates))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AvailabilityCard);
