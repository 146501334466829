import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Gallery from "../../../components/virtualLessons/InstructorGalleryCarousel.js";
import Reviews from "../../../components/virtualLessons/InstructorReviewCarousel.js";
import Grid from "@material-ui/core/Grid";
import InstructorIntroSection from "./InstructorIntroSection.js";
import InstructorMyProfile from "./InstructorMyProfile.js";
import Button from "@material-ui/core/Button";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { useEffect, useState } from "react";
import agent from "../../../agent";

import { updateInsProfile } from "../../../redux/actions/instructorNewProfileAction";
import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
  main: {
    margin: "2em"
  },
  typograhyFormat: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontSize: 20,
    lineHeight: "22.50px",
    textAlign: "left",
    color: "#1277B8",
    marginTop: "2rem",
    marginBottom: "2rem",
    marginLeft: 50,
    fontWeight: "bolder",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18
    }
  },
  arrowIcon: {
    transform: "rotate(180deg)"
  },
  backButton: {
    margin: "3em",
    borderColor: "#FF6161",
    color: "#FF6161"
  }
}));

const InstructorNewProfile = props => {
  const {
    instructorIntroSection,
    instructorMyProfile,
    isStudent,
    _updateInsProfile
  } = props;

  //styling
  const classes = useStyles();

  useEffect(() => {
    //getting Instructor profiles from database
    agent.Instructors.getInstructor(6)
      .then(response => {
        const responseData = response.data;
        console.log("responseData", responseData);
        _updateInsProfile(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <div className={classes.main}>
      <Grid container item sm={12} xs={12}>
        <Grid container item sm={6} xs={12} spacing={2} lg={12}>
          <Button
            className={classes.backButton}
            variant="outlined"
            startIcon={<ArrowRightAltIcon className={classes.arrowIcon} />}
          >
            Back
          </Button>
        </Grid>
      </Grid>
      {/* This is the child component called InstructorMyProfile  */}
      <InstructorMyProfile {...instructorMyProfile} isStudent={isStudent} />

      {/* This is the child component called InstructorIntroSection  */}

      <InstructorIntroSection {...instructorIntroSection} />

      <Grid container direction="Row" alignContent="center" justify="center">
        <Grid item xs={12} md={12}>
          <Typography className={classes.typograhyFormat}>REVIEWS</Typography>
          {/* <WeeklySchedule week={week} setSchedule={} id={} /> */}
          {/* This is the Reviews component  */}
          <Reviews instructorId={1} />
        </Grid>

        <Grid item xs={12} md={12}>
          <Typography className={classes.typograhyFormat}>GALLERY</Typography>
          {/* This is the Gallery component  */}
          <Gallery instructorId={1} />
        </Grid>
      </Grid>
    </div>
  );
};

// Reducer part
function mapStateToProps(state) {
  return {
    instructorIntroSection: {
      ...state.instructorNewProfileReducer.InstructorIntroSection
    },
    instructorMyProfile: {
      ...state.instructorNewProfileReducer.InstructorMyProfile
    }
  };
}

const mapDispatchToProps = dispatch => {
  return {
    _updateInsProfile: data => dispatch(updateInsProfile(data))
  };
};

// Connect part
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstructorNewProfile);
