import {
  UPDATE_RESORTS,
  UPDATE_SPORT,
  UPDATE_SPECIALISATIONS,
  UPDATE_LANGUAGES,
  UPDATE_YEARS_OF_EXPERIENCE,
  UPDATE_QUALIFICATIONS,
  UPDATE_AVATAR,
  UPDATE_NAME,
  UPDATE_RATING,
  UPDATE_STARTING_PRICE,
  UPDATE_BIO,
  UPDATE_INS_PROFILE
} from "../actions/action-types";
import profilePic from "../../materials/HomePage/host.jpg";

const initialState = {
  InstructorIntroSection: {
    resorters: ["Mount Hotham", "Falls Creek"],
    sports: ["Snowboarding", "Freeride Snowboarding"],
    specialisations: ["Kids", "Adults"],
    languages: ["English", "spanish"],
    experience: 4,
    qulification: ["Level 4", "Carte Professionnelle License"]
  },
  InstructorMyProfile: {
    avatar: profilePic,
    name: {
      firstName: "Casey",
      lastName: "Flemmings"
    },
    rating: 5,
    intialprice: 80,
    bio:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
  }
};

const instructorNewProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_AVATAR:
      return { ...state, avatar: action.payload };
    case UPDATE_NAME:
      return { ...state, name: action.payload };
    case UPDATE_RATING:
      return { ...state, rating: action.payload };
    case UPDATE_STARTING_PRICE:
      return { ...state, intialprice: action.payload };
    case UPDATE_BIO:
      return { ...state, bio: action.payload };
    case UPDATE_RESORTS:
      return { ...state, resorters: [...state.resorters, action.payload] };
    case UPDATE_SPORT:
      return { ...state, sports: [...state.sports, action.payload] };
    case UPDATE_SPECIALISATIONS:
      return {
        ...state,
        specialisations: [...state.specialisations, action.payload]
      };
    case UPDATE_LANGUAGES:
      return { ...state, languages: [...state.languages, action.payload] };
    case UPDATE_YEARS_OF_EXPERIENCE:
      return { ...state, experience: action.payload };
    case UPDATE_QUALIFICATIONS:
      // return {...state, qulification: action.payload };
      return {
        ...state,
        qulification: [...state.qulification, action.payload]
      };
    case UPDATE_INS_PROFILE:
      return {
        ...state,
        InstructorIntroSection: {
          ...state.InstructorIntroSection,
          // resorters: action.payload.resorters,
          // sports: action.payload.sports,
          // specialisations: action.payload.specialisations,
          experience: action.payload.instructor.ExperienceYears,
          qulification: action.payload.instructor.Qualifications.split(","),
          languages: action.payload.languages.map(item => item.description)
        },
        InstructorMyProfile: {
          ...state.InstructorMyProfile,
          // avatar: action.payload.avatar,
          name: {
            firstName: action.payload.instructor.FirstName,
            lastName: action.payload.instructor.LastName
          },
          bio: action.payload.instructor.Bio
        }
      };
    default:
      return state;
  }
};

export default instructorNewProfileReducer;
