import {
  TOGGLE_VIRTUAL_INSTRUCTOR_LIKE,
  SAVE_INSTRUCTOR_STATE,
  CREATE_VIRT_BOOKING,
  SEND_INSTRUCTOR_SPECIALITY_VALUES,
  MODIFY_VIRT_BOOKING,
  ADJUST_UNAVAILABILITY,
  ADD_TIMESLOT,
  UPDATE_TIMESLOT,
  DELETE_TIMESLOT,
  UPDATE_ERRORS_TIMESLOT,
  ADD_RATE,
  REMOVE_RATE
} from "../actions/action-types";
import sampleImg from "../../materials/HomePage/campfire_img.jpg";
import moment from "moment";

// TODO: retreive data from instructor table in database
let MIN_ADVANCE_NOTICE_DAYS = 5;
let MAX_BOOKING_AVAL_DAYS = 30;

const roundMinutes = date => {
  date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
  date.setMinutes(0, 0, 0); // Resets also seconds and milliseconds
  return date;
};
let INITIAL_DATE = new Date(); // 4:55
INITIAL_DATE.setDate(INITIAL_DATE.getDate() + MIN_ADVANCE_NOTICE_DAYS);
roundMinutes(INITIAL_DATE); // 5:00

const initialState = {
  //The below data structure is out-dated
  //It still exists as it used in a few dummy data scenarios
  //Review carousel for example in the instructor profile page
  data: [
    {
      id: 1,
      fname: "Aaron",
      lname: "Hussain",
      avatarSrc: sampleImg,
      rating: 4,
      minimumPrice: 40,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Necessitatibus sunt illo quia dignissimos obcaecati expedita amet provident nam quo aliquid, saepe et repudiandae libero sed neque consequatur, modi. Magnam sunt laborum, similique earum suscipit! Veritatis quod vero recusandae voluptas. Culpa doloremque rerum explicabo quas laborum nulla possimus repellat provident unde.",
      resorts: ["Mount Hotham", "Fall Creek"],
      languages: ["german", "french"],
      isLiked: true,
      sport: ["Snowboarding", "Freeride Snowboarding"],
      specialisations: ["Kids"],
      experienceYears: 4,
      qualifications: ["Level 4", "Carte Professionnelle License"],
      priceList: [
        { key: 1, displayText: "Full day (up to 2 people)", amount: "$504.00" },
        { key: 2, displayText: "Full day (up to 3 people)", amount: "$505.00" },
        { key: 3, displayText: "Full day (up to 4 people)", amount: "$506.00" },
        { key: 4, displayText: "Full day (up to 5 people)", amount: "$507.00" }
      ],
      resort_bookings: [
        {
          group: 1,
          resort_id: 1,
          resortName: "Mount Hotham",
          unavailableDays: [new Date(2020, 6, 5)],
          bookings: [
            {
              start: new Date(2020, 6, 5, 11, 0),
              end: new Date(2020, 6, 5, 12, 0)
            }
          ]
        },
        {
          group: 1,
          resort_id: 2,
          resortName: "Fall Creek",
          unavailableDays: [new Date(2020, 6, 5)],
          bookings: [
            {
              start: new Date(2020, 6, 5, 10, 0),
              end: new Date(2020, 6, 5, 11, 0)
            }
          ]
        }
      ],
      reviewsItem: [
        {
          id: "1",
          comment: "“Amazing instructor! SO fun to learn from.”",
          author: "- Jane Doe, Sep 2019"
        },
        {
          id: "2",
          comment:
            "“TODO: This and gallery using dummy data as there is currently no way to upload reviews or gallery”",
          author: "- John Smith, Aug 2019"
        },
        {
          id: "3",
          comment:
            "“Had an absolute ball with Casey. Mad respect for her techniques.” ",
          author: "- Jane Peter, Mar 2019"
        },
        {
          id: "4",
          comment: "“Amazing instructor! SO fun to learn from.”",
          author: "- Jane Doe, Sep 2019"
        }
      ],
      galleryItem: [
        {
          id: "1",
          name: "John",
          src:
            "https://www.newzealand.com/assets/Operator-Database/1e3a62a78b/img-1574395061-8070-17949-p-4D10865C-AA2B-61EE-8F0574F3F17ED5C8-2544003__aWxvdmVrZWxseQo_CropResizeWzk0MCw1MzAsNzUsImpwZyJd.jpg"
          // src:"https://img.redbull.com/images/c_crop,x_969,y_0,h_1824,w_1459/c_fill,w_860,h_1075/q_auto,f_auto/redbullcom/2019/12/04/8e89fbee-f686-4ff3-b680-2b54178fd1b6/switzerland-skiing"
        },
        {
          id: "2",
          name: "Peter",
          src:
            "https://www.newzealand.com/assets/Operator-Database/25e6389408/img-1574395172-9299-12937-p-4511D717-04E3-499C-70D8B36673C80D0A-2544003__aWxvdmVrZWxseQo_CropResizeWzk0MCw1MzAsNzUsImpwZyJd.jpg"
          // src:"https://www.skimag.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTY3MTQ5MTQ5NDkyMzU2MjEy/20190305-skitest-allmtn-068.jpg"
        },
        {
          id: "3",
          name: "Taylor",
          src:
            "https://www.larosiere.net/wp-content/uploads/2018/03/ski_famille-800x797.jpg"
        },
        {
          id: "4",
          name: "Ellie",
          src:
            "https://www.skimag.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTY3MTQ5MTQ5NDkyMzU2MjEy/20190305-skitest-allmtn-068.jpg"
        }
      ],
      rates: [
        {
          hours: 10,
          price: 100
        }
      ]
    }
  ],
  //The below data structure is the currently loaded profile
  //By default this is an instructor profile
  currentProfile: {
    id: 0,
    FirstName: "",
    LastName: "",
    AvatarSrc: sampleImg,
    rating: 4,
    minimumPrice: 40,
    description: "",
    minAdvanceNoticeDays: MIN_ADVANCE_NOTICE_DAYS,
    maxBookingAvalDays: MAX_BOOKING_AVAL_DAYS,
    resorts: [
      { id: 1, fullname: "Mount Hotham" },
      { id: 2, fullname: "Fall Creek" }
    ],
    languages: ["german", "french"],
    isLiked: true,
    sport: ["Snowboarding", "Freeride Snowboarding"],
    specialisations: [""],
    experienceYears: 4,
    qualifications: ["Level 4", "Carte Professionnelle License"],
    priceList: [
      { key: 1, displayText: "Full day (up to 2 people)", amount: "$504.00" },
      { key: 2, displayText: "Full day (up to 3 people)", amount: "$505.00" },
      { key: 3, displayText: "Full day (up to 4 people)", amount: "$506.00" },
      { key: 4, displayText: "Full day (up to 5 people)", amount: "$507.00" }
    ],
    unavailableDays: [new Date(2021, 3, 21)],
    resort_bookings: [
      {
        id: 1,
        group: 1,
        resort_id: 1,
        uesr_id: 1,
        resortName: "Mount Hotham",
        unavailableDays: [new Date(2020, 6, 5)],
        start_time: new Date(2020, 7, 20, 2, 0),
        end_time: new Date(2020, 7, 20, 3, 0)
      },
      {
        id: 2,
        group: 1,
        resort_id: 2,
        uesr_id: 1,
        resortName: "Fall Creek",
        unavailableDays: [new Date(2020, 6, 5)],
        start_time: new Date(2020, 6, 5, 10, 0),
        end_time: new Date(2020, 6, 5, 13, 0)
      }
    ]
  },
  timeslots: [
    {
      datefrom: INITIAL_DATE,
      dateto: INITIAL_DATE,
      id: 0,
      price: 0,
      error: false
    }
  ],
  other: null,
  specialties: [],

  rates: [
    {
      hours: 10,
      price: 100
    }
  ],

  vlInstructorPricing: [
    {
      index: 1,
      errorMessage: ""
    }
  ]
};

const virtualInstructorReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_VIRTUAL_INSTRUCTOR_LIKE:
      const toggledData = state.data.map(instructorData => {
        if (instructorData.id === action.payload.VirtInstructorId) {
          instructorData.isLiked = !instructorData.isLiked;
          return instructorData;
        }
        return instructorData;
      });
      return {
        ...state,
        data: toggledData
      };
    case SAVE_INSTRUCTOR_STATE:
      if (action.payload.id !== state.currentProfile.id) {
        return {
          ...state,
          currentProfile: { ...state.currentProfile, ...action.payload }
        };
      } else {
        return state;
      }
    case CREATE_VIRT_BOOKING:
      return {
        ...state,
        currentProfile: {
          ...state.currentProfile,
          resort_bookings: [
            ...state.currentProfile.resort_bookings,
            {
              id: state.currentProfile.resort_bookings.length + 1,
              group: 1,
              resort_id: action.payload.resort_id,
              unavailableDays: [new Date(2020, 9, 1)],
              start_time: action.payload.timeslot.datefrom,
              end_time: action.payload.timeslot.dateto
            }
          ]
        }
      };

    case ADJUST_UNAVAILABILITY:
      const { resort_info_id, dates } = action.payload;
      console.log(state.currentProfile.unavailability);
      let unq_id = 0;
      let dates_new = dates.map(date => {
        unq_id += 1;
        return { ...date, id: unq_id };
      });
      return {
        ...state,
        currentProfile: {
          ...state.currentProfile,
          unavailability: dates_new
        }
      };

    case MODIFY_VIRT_BOOKING:
      const items = action.payload.items;
      return {
        ...state,
        currentProfile: {
          ...state.currentProfile,
          resort_bookings: items
        }
      };

    case SEND_INSTRUCTOR_SPECIALITY_VALUES:
      return {
        ...state,
        specialties: action.payload
      };

    case ADD_TIMESLOT:
      return {
        ...state,
        timeslots: [...state.timeslots, action.payload]
      };

    case UPDATE_TIMESLOT:
      let copyOfTimeslots1 = [...state.timeslots];
      copyOfTimeslots1[action.payload.id] = action.payload.timeslot;
      return {
        ...state,
        timeslots: copyOfTimeslots1
      };

    case DELETE_TIMESLOT:
      let copyOfTimeslots2 = [...state.timeslots];
      copyOfTimeslots2.splice(action.payload, 1);
      return {
        ...state,
        timeslots: copyOfTimeslots2
      };

    case UPDATE_ERRORS_TIMESLOT:
      return {
        ...state,
        timeslots: action.payload
      };

    // case ADD_RATE_OR_DISCOUNT:
    //   return {
    //     ...state,
    //     rates: action.payload
    //   };

    // case REMOVE_RATE_OR_DISCOUNT:
    //   return {
    //     ...state,
    //     rates: action.payload
    //   };

    case ADD_RATE:
      return {
        ...state,
        vlInstructorPricing: [...state.vlInstructorPricing, action.payload]
      };

    case REMOVE_RATE: {
      let copyOfPricing = [...state.vlInstructorPricing];
      copyOfPricing.pop();
      return {
        ...state,
        vlInstructorPricing: [...copyOfPricing]
      };
    }

    default:
      return state;
  }
};

/**
 * Simulates data loaded into this reducer from database
 */

export default virtualInstructorReducer;
