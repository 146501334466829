import React from "react";

//MaterialUI
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

//Styling
const useStyles = makeStyles({
  smallSectionHeading: {
    fontSize: "16"
  },
  root: {
    width: "100%",
    padding: "20px 10% 20px 10%"
  },
  textHeading: {
    fontSize: "16",
    color: "#1277B8",
    fontWeight: "bold",
    fontFamily: "PT Sans Narrow",
    lineHeight: "140.62%",
    padding: "6px"
  },
  dateText: { fontSize: 11, marginTop: ".25rem" },
  highlight: { background: "#C5C5C5", padding: "0.25rem" },
  dropdown: { marginLeft: ".2rem" },
  arrow: { margin: "0 .5em 0 .5em" }
});

//--------Class for rendering and changing checkboxes
//--------
const WeeklyCheckboxes = (daysOfWeek, setDaysOfWeek) => {
  //Sunday is index 0, Saturday is index 6

  const indexOfDay = day => {
    const days = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday"
    ];
    return days.indexOf(day);
  };

  const handleChangeState = newDay => {
    let index = indexOfDay(newDay.day);
    const newWeek = [...daysOfWeek];
    newWeek[index].active = !newWeek[index].active;
    setDaysOfWeek(newWeek);
  };

  const renderDays = daysOfWeek.map(day => (
    <FormControlLabel
      control={
        <Checkbox
          checked={day.active}
          onClick={() => handleChangeState(day)}
          name={day.day}
          color="primary"
        />
      }
      label={day.day}
    />
  ));
  return <FormGroup row>{renderDays}</FormGroup>;
};

//--------Class for rendering and changing available hours
//--------
const WeeklyHours = (
  week,
  timeslotValueFrom,
  setTimeslotValueFrom,
  timeslotValueTo,
  setTimeslotValueTo
) => {
  //styling
  const classes = useStyles();

  //constants
  const allTimesHour = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23"
  ];
  const allTimesMinute = ["00", "30"];

  //states

  //helper function
  //returns true if timeBefore is before timeAfter, false otherwise
  const isAfterTime = (timeBefore, timeAfter) => {
    const beforeSplit = timeBefore.split(":");
    const afterSplit = timeAfter.split(":");
    const beforeHour = parseInt(beforeSplit[0]);
    const beforeMinute = parseInt(beforeSplit[1]);
    const afterHour = parseInt(afterSplit[0]);
    const afterMinute = parseInt(afterSplit[1]);
    if (beforeSplit.length !== 2 || afterSplit.length !== 2) {
      throw "Error! isAfterTime() is not being passed the correct parameters";
    }

    if (beforeHour < afterHour) return true;

    if (beforeHour == afterHour && beforeMinute < afterMinute) return true;

    return false;
  };

  //times to render
  let allTimesFrom = [];
  allTimesHour.map(hour => {
    allTimesMinute.map(minute => {
      allTimesFrom.push(hour + ":" + minute);
    });
  });
  allTimesFrom.push("23:59");
  console.log(timeslotValueFrom);
  let allTimesTo = allTimesFrom.filter(time => {
    if (typeof timeslotValueFrom !== "undefined") {
      return isAfterTime(timeslotValueFrom, time);
    } else return true;
  });
  console.log(allTimesTo);

  //render
  return (
    <Grid container justify="center">
      <Grid item>
        <select
          className={classes.dropdown}
          // id="timeslots"
          onChange={event => {
            setTimeslotValueFrom(event.target.value);
          }}
          value={timeslotValueFrom}
          // disabled={disabled}
        >
          {allTimesFrom.map(time => {
            return (
              <option key={time} value={time}>
                {time}
              </option>
            );
          })}
        </select>
      </Grid>
      <ArrowRightAltIcon className={classes.arrow} />
      <Grid item>
        <select
          className={classes.dropdown}
          id="timeslots"
          onChange={event => {
            setTimeslotValueTo(event.target.value);
          }}
          value={timeslotValueTo}
          // disabled={disabled}
        >
          {allTimesTo.map(time => {
            return (
              <option key={time} value={time}>
                {time}
              </option>
            );
          })}
        </select>
      </Grid>
    </Grid>
  );
};

//--------Default Class: Rendering and changing a weekly schedule
//--------
const WeeklySchedule = props => {
  //default properties
  const { week, setSchedule, id } = props;
  const DEFAULT_STARTTIME = "09:00";
  const DEFAULT_ENDTIME = "17:00";
  const [timeslotValueFrom, setTimeslotValueFrom] = React.useState(
    DEFAULT_STARTTIME
  );
  const [timeslotValueTo, setTimeslotValueTo] = React.useState(DEFAULT_ENDTIME);
  const [days, setDays] = React.useState([
    { day: "sunday", active: false },
    { day: "monday", active: false },
    { day: "tuesday", active: false },
    { day: "wednesday", active: false },
    { day: "thursday", active: false },
    { day: "friday", active: false },
    { day: "saturday", active: false }
  ]);

  //Listens for a change in set days or hours
  React.useEffect(() => {
    let newSchedule = [...week];
    console.log(days);

    //filters out the non active days
    let filteredDays = days.filter(day => day.active);

    //convert timeslotValueFrom and timeslotValueTo to Date Objects
    let today = new Date();
    // let hourTo = timeslotValueTo.split()[0];
    // let minuteTo = timeslotValueTo.split()[1];
    // let secondTo = '00';
    //let timeslotFrom = new Date();
    let timeslotFrom = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      timeslotValueFrom.split(":")[0],
      timeslotValueFrom.split(":")[1]
    );

    let timeslotTo = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      timeslotValueTo.split(":")[0],
      timeslotValueTo.split(":")[1]
    );

    console.log(timeslotFrom);
    console.log(timeslotTo);

    //formats the days in the data structure the backend needs
    filteredDays = filteredDays.map(day => ({
      day: day.day,
      //start: timeslotValueFrom.toString(),
      //end: timeslotValueTo.toString()
      //replace these with selected times
      start: timeslotFrom,
      end: timeslotTo
      //start: new Date(),
      //end: new Date()
    }));

    //modifies the "group" of availability were currently modifying
    newSchedule[id] = filteredDays;

    //updates the schedule
    setSchedule(newSchedule);
  }, [days, timeslotValueFrom, timeslotValueTo]);

  //styling
  const classes = useStyles();

  //renderWeeklyHours
  const weeklyHours = WeeklyHours();

  //render
  return (
    <Grid item container justify="center" spacing={2}>
      <Grid item container xs={12}>
        <Grid item xs={3}>
          <Typography className={classes.textHeading} align="left" gutterBottom>
            Available Hours:
          </Typography>
        </Grid>
        <Grid item xs={9}>
          {WeeklyHours(
            week,
            timeslotValueFrom,
            setTimeslotValueFrom,
            timeslotValueTo,
            setTimeslotValueTo
          )}
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={3}>
          <Typography className={classes.textHeading} align="left" gutterBottom>
            Apply to:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          {WeeklyCheckboxes(days, setDays)}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default WeeklySchedule;
