import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Flag from "../../../components/virtualLessons/Flag";
import { Typography } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles({
  typograhyFormat: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontSize: 16,
    lineHeight: "22.50px",
    textAlign: "left",
    color: "#1277B8",
    marginTop: "2rem",
    fontWeight: "bolder",
    width: "100%",
    marginBottom: "0.5rem"
  },

  lableFormat: {
    color: "rgba(18, 119, 184, 0.6)",
    fontSize: 16,
    fontStyle: "bold"
  },
  mainDiv: {
    margin: "0 auto",
    width: "65%",
    marginTop: "2rem"
  },
  flagContainer: {
    display: "inline-block"
  },
  spanFormat: {
    fontsize: 14
  },
  commonFormat: {
    fontStyle: "normal",
    fontFamily: "PT Sans Narrow"
  }
});

const StudentIntroSection = props => {
  const classes = useStyles();
  const {
    age,
    gender,
    physicalDisability,
    languages,
    experience,
    snowboardAbility,
    skiAbility,
    abilities
  } = props;

  const Language = languages.map(value => (
    <div className={classes.flagContainer}>
      <Flag language={value} key={value} />
    </div>
  ));

  return (
    // this is the main Grid Container work as  (DIV)
    <div className={classes.mainDiv}>
      <Grid container xs={12} md={12} lg={12}>
        {/* this is the 1st row which has "Age"+ "Gender"+ "Phisical Diability" */}

        <Grid item xs={12} lg={3}>
          <Typography className={classes.typograhyFormat}> AGE </Typography>
          <span
            className={classNames(classes.spanFormat, classes.commonFormat)}
          >
            {`${age} Years`}
          </span>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Typography className={classes.typograhyFormat}> GENDER </Typography>
          <span
            className={classNames(classes.spanFormat, classes.commonFormat)}
          >
            {gender}
          </span>
        </Grid>

        <Grid item xs={12} lg={5}>
          <Typography className={classes.typograhyFormat}>
            PHYSICAL DISABILITIES
          </Typography>
          {/* {physicalDisability.map(physicalDisability => ( */}
          <span
            className={classNames(classes.spanFormat, classes.commonFormat)}
          >
            {physicalDisability} <br />
          </span>
          {/* ))} */}
        </Grid>

        {/* this is the 2nd row which has "Langange"+ "Years of Experince"+ "Ability" */}
        <Grid item xs={12} lg={3}>
          <Typography className={classes.typograhyFormat}>LANGUAGES</Typography>
          {Language}
        </Grid>

        <Grid item xs={12} lg={4}>
          <Typography className={classes.typograhyFormat}>
            YEARS OF EXPERIENCE
          </Typography>
          <span
            className={classNames(classes.spanFormat, classes.commonFormat)}
          >
            {experience}
          </span>
        </Grid>

        <Grid container item xs={12} lg={5}>
          <Grid item xs={12}>
            <Typography className={classes.typograhyFormat}>ABILITY</Typography>
          </Grid>

          {abilities.map(ability => (
            <Grid
              item
              container
              xs={12}
              spacing={3}
              lg={12}
              justify="flex-start"
            >
              <Grid item xs={4} lg={4}>
                <label className={classes.lableFormat} for="SKI">
                  {ability.name}
                </label>
              </Grid>

              <Grid item xs={6} lg={7}>
                <div className={classes.sliderbarFormat}>
                  <input
                    type="range"
                    className="color-slider-bar"
                    min={1}
                    max={7}
                    step={1}
                    id="ski"
                    value={ability.skill}
                    // onChange={handleSnowboardAbilitySlider}
                    disabled={true}
                  />
                </div>
              </Grid>

              <Grid item xs={2} lg={1}>
                <span>{ability.skill}</span>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default StudentIntroSection;
