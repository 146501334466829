/*
 *Theme colors
 */

export const OrangeColor1 = "#FF6261";
export const DangerColor = "#A50E0D";

export const GrayColor0 = "#F6F6F6";
export const GrayColor1 = "#E5E6E7";
export const GrayColor2 = "#CECFCF";
export const GrayColor3 = "#767277";
export const GrayColor4 = "#4E4E52";

export const BlueColor1 = "#ecf4f9";
export const BlueColor2 = "#B2CFEE";
export const BlueColor3 = "#4682B4";
export const BlueColor4 = "#31638C";

/*
 * Ability Level Colors
 */
export const Level1Color = "#F0FD4E";
export const Level2Color = "#91F85A";
export const Level3Color = "#2AB01F";
export const Level4Color = "#54BFD9";
export const Level5Color = "#1D80C0";
export const Level6Color = "#748E9F";
export const Level7Color = "#363636";

export const Level1TextColor = "#7A8121";
export const Level2TextColor = "#4C9923";
export const Level3TextColor = "#106A08";
export const Level4TextColor = "#1B7990";
export const Level5TextColor = "#BBD6E8";
export const Level6TextColor = "#BFCCD4";
export const Level7TextColor = "#D3D2D2";

/*
 * Lesson Colors
 */
export const LessonMandatoryColor1 = "#752027";
export const LessonMandatoryColor2 = "#f8d7da";
export const LessonMandatoryColor3 = "#EEE4E5";
export const LessonMandatoryColor4 = "#B04952";
export const LessonMandatoryColor5 = "#FAF0F1";

export const LessonGroupColor1 = "#02237B";
export const LessonGroupColor2 = "#CDE5FE";
export const LessonGroupColor3 = "#E0EBF4";
export const LessonGroupColor4 = "#3757AD";
export const LessonGroupColor5 = "#F5F9FD";

export const LessonPrivateColor1 = "#826110";
export const LessonPrivateColor2 = "#FFF1CF";
export const LessonPrivateColor3 = "#F0EFD5";
export const LessonPrivateColor4 = "#B28D31";
export const LessonPrivateColor5 = "#FCF7EB";

export const LessonAdaptiveColor1 = "#023F0C";
export const LessonAdaptiveColor2 = "#D4EBDA";
export const LessonAdaptiveColor3 = "#daf1d7";
export const LessonAdaptiveColor4 = "#239435";
export const LessonAdaptiveColor5 = "#F0F8F2";

export const mandatory_theme = {
  color1: LessonMandatoryColor1,
  color2: LessonMandatoryColor2,
  color3: LessonMandatoryColor3,
  color4: LessonMandatoryColor4,
  color5: LessonMandatoryColor5
};

export const group_theme = {
  color1: LessonGroupColor1,
  color2: LessonGroupColor2,
  color3: LessonGroupColor3,
  color4: LessonGroupColor4,
  color5: LessonGroupColor5
};

export const private_theme = {
  color1: LessonPrivateColor1,
  color2: LessonPrivateColor2,
  color3: LessonPrivateColor3,
  color4: LessonPrivateColor4,
  color5: LessonPrivateColor5
};

export const adaptive_theme = {
  color1: LessonAdaptiveColor1,
  color2: LessonAdaptiveColor2,
  color3: LessonAdaptiveColor3,
  color4: LessonAdaptiveColor4,
  color5: LessonAdaptiveColor5
};

export const FilterColor = "rgba(7, 7, 7, 0.3)";

export const LightFilterColor = "rgba(7, 7, 7, 0.15)";
