import React from "react";
// Redux
import { connect } from "react-redux";
import Store from "../../../redux/store";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Typography
} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: 0
  },

  stepperBox: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "auto"
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "55%",
      margin: "auto"
    }
  },

  instructions: {
    textAlign: "center",
    paddingTop: theme.spacing(1)
  },

  prompt: {
    paddingTop: "1em",
    paddingBottom: "1em",
    textAlign: "center",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    textTransform: "uppercase",
    fontSize: "2.3em",
    color: "#1277B8"
  },

  iconRoot: {
    backgroundColor: "white",
    border: "2px solid #AAAAAA",
    // borderWidth: 4,
    zIndex: 1,
    color: "#FF6161",
    width: 35,
    height: 35,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "0em"
  },

  stepper: {
    padding: ".2em"
  },

  active: {
    border: "2px solid #FF6161"
  },

  completed: {
    backgroundColor: "#FF6161",
    border: "1px solid #FF6161"
  },

  backButton: {},

  steps: {
    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      fontFamily: "PT Sans Narrow",
      // fontWeight: "bold",
      color: "black"
    }
  }
}));

function getStepsForProvider() {
  return ["Account", "Details", "Profile", "Service", "Confirm"];
}

function getStepsForClient() {
  return ["Account", "Details", "Profile", "Confirm"];
}

//#region Steps Content - Sets the content under stepper
function getStepsContentForProvider(store) {
  switch (store) {
    case 0:
      return "";
    case 1:
      return "";
    case 2:
      return "";
    case 3:
      return "";
    case 4:
      return "";
    default:
      return "";
  }
}

function getStepContentForClient(store) {
  switch (store) {
    case 0:
      return "";
    case 1:
      return "";
    case 2:
      return "";
    case 3:
      return "";
    default:
      return "";
  }
}
//#endregion

function StepperIcons(props) {
  const classes = useStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.iconRoot, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    />
  );
}

let SignupStepper = ({ stepperReducer, userData }) => {
  const classes = useStyles();
  const store = Store.getState();
  let steps = getStepsForProvider();

  if (stepperReducer == 5) {
    if (userData.data.is_provider == false) {
      stepperReducer = stepperReducer;
    } else {
      stepperReducer = stepperReducer - 1;
    }
  } else if (stepperReducer > 1) {
    stepperReducer = stepperReducer - 1;
  }

  //Why is this hard-coded?
  // const TypeOfBuild = "VL";
  const TypeOfBuild = "Host";

  if (userData && userData.data.is_provider) {
    steps = getStepsForProvider();
  } else if (userData && !userData.data.is_provider) {
    steps = getStepsForClient();
  } else if (store.form.signup.values.userType === 2) {
    steps = getStepsForClient();
  } else {
    if (TypeOfBuild === "Host") {
      steps = getStepsForProvider();
    }
  }
  const StepperConnector = withStyles({
    alternativeLabel: {
      top: 16,
      marginLeft: "-1rem",
      marginRight: "-1rem"
    },
    active: {
      "& $line": {
        backgroundColor: "#FF6161"
      }
    },
    completed: {
      "& $line": {
        backgroundColor: "#FF6161"
      }
    },
    line: {
      height: 2,
      border: 0,
      backgroundColor: "#AAAAAA",
      borderRadius: 1
    }
  })(StepConnector);

  //Render for client or provider
  if ((userData && !userData.data.is_provider) || store.form) {
    // render for clients
    // && store.form.signup.values.userType === 1
    return (
      <div className={classes.root}>
        <Box width="100%" display="flex">
          <Box width="40%" m="auto" className={classes.stepperBox}>
            <Stepper
              className={classes.stepper}
              activeStep={stepperReducer - 1}
              connector={() => <div />}
              alternativeLabel
              m="auto"
            >
              {steps.map(label => (
                <Step key={label} className={classes.steps}>
                  <StepLabel StepIconComponent={() => <div />}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <Stepper
              className={classes.stepper}
              activeStep={stepperReducer - 1}
              connector={<StepperConnector />}
              alternativeLabel
              m="auto"
            >
              {steps.map(label => (
                <Step key={label} className={classes.steps}>
                  <StepLabel StepIconComponent={StepperIcons}>{}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Box>

        <div>
          {stepperReducer === stepperReducer.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed
              </Typography>
            </div>
          ) : (
            <div>
              <Typography className={classes.prompt}>
                {getStepContentForClient(stepperReducer)}
              </Typography>
              <div />
            </div>
          )}
        </div>
      </div>
    );
  } else {
    // render for providers
    return (
      <div className={classes.root}>
        <Box width="100%" display="flex">
          <Box width="50%" m="auto">
            <Stepper
              activeStep={stepperReducer - 1}
              connector={<StepperConnector />}
              alternativeLabel
              m="auto"
            >
              {steps.map(label => (
                <Step key={label} className={classes.steps}>
                  <StepLabel StepIconComponent={StepperIcons}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Box>

        <div>
          {stepperReducer === stepperReducer.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed
              </Typography>
            </div>
          ) : (
            <div>
              <Typography className={classes.prompt}>
                {getStepsContentForProvider(stepperReducer)}
              </Typography>
              <div />
            </div>
          )}
        </div>
      </div>
    );
  }
};

function mapStateToProps(state) {
  return {
    stepperReducer: state.stepperReducer.activeStep,
    userData: state.loginReducer.userData
  };
}

export default connect(mapStateToProps)(SignupStepper);
