/// TODO: Uncomment and Check the entire file for issues once the frontend server is started. Commenting this now!

import React, { useState } from "react";
import { connect, Provider } from "react-redux";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import {
  sendHostName,
  getServiceData
} from "../../../redux/actions/exploreHostsActions";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  ListSubheader,
  Checkbox,
  ListItemText,
  Hidden
} from "@material-ui/core/";
import clsx from "clsx";
import { useMediaQuery } from "@material-ui/core";
import InstructorCard from "../../../components/virtualLessons/InstructorCard/InstructorCard.js";
import Header from "../../../components/Common/Home/HeaderSection.js";
import SignUpSection from "../../../components/exploreHosts/home/SignUpSection.jsx";
import HostCard from "../../../components/exploreHosts/HostCard/HostCard.js";
// import SavedInstructors from "../ExploreHosts/SavedHosts";
import CardPagination from "../../../components/Common/CardPagination";
import ContentFilter from "../../../components/exploreHosts/home/ContentFilter";

import SearchCardForExploreInstructors from "../../../components/virtualLessons/home/SearchCardForExploreInstructors";
import BackgroundImg from "../../../materials/HomePage/background_landing.jpg";
import croppedBackgroundImg from "../../../materials/HomePage/cropped_background_landing.jpg";
import {
  NAV_BAR_HEIGHT,
  TAB_BAR_HEIGHT
} from "../../../styling/styled-components/dimension-themes";
import { Button, Collapse, Container, IconButton } from "@material-ui/core";
import downArrow from "../../../materials/HomePage/down_arrow.png";
import ProviderResultCard from "../../../components/Common/Home/ProviderResultCard";
import Countries from "../../../components/Common/SignupForm/ProviderInfo/Countries";
import { renderSelectField } from "../../../components/Common/FormField/FormField";
import CountryAndResortFields from "../../../components/Common/CountryAndResortFields";
import {
  getResortList,
  getCountryList
} from "../../../redux/actions/homePageAction";
import { COLOR, FONTFAMILY } from "../../../styling/theme/Theme";
import Store from "../../../redux/store"

const BACKGROUND_SIZE = "60vh";

const useStyles = makeStyles(theme => ({
  cards: {
    paddingBottom: "4em"
  },

  heading: {
    fontFamily: FONTFAMILY.FONT_SECONDARY,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "29px",
    lineHeight: "45px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.01em",
    color: "#1277B8"
  },
  // backgroundContainer: {
  //   [theme.breakpoints.down("sm")]: {
  //     height: "130vh",
  //     display: "flex",
  //     flexDirection: "column",
  //     justifyContent: "flex-start"
  //   },
  //   [theme.breakpoints.down("xs")]: {
  //     height: "130vh",
  //     display: "flex",
  //     flexDirection: "column",
  //     justifyContent: "flex-start"
  //   },
  //   "@media (min-width: 370px) and @media (max-width: 810px) and (max-height: 810px)": {
  //     // max-width: 400px - Galaxy s3, iphone X, iphone 6/7/8
  //     // border: "1px solid red",
  //     height: "150vh",
  //     display: "flex",
  //     flexDirection: "column",
  //     justifyContent: "flex-start"
  //   },
  //   "@media (max-width: 360px)": {
  //     // max-width: 400px - Galaxy s3, iphone X, iphone 6/7/8
  //     // border: "1px solid red",
  //     height: "160vh",
  //     display: "flex",
  //     flexDirection: "column",
  //     justifyContent: "flex-start"
  //   },
  //   "@media (max-width: 320px)": {
  //     // max-width: 400px - Galaxy s3, iphone X, iphone 6/7/8
  //     // border: "1px solid red",
  //     height: "200vh",
  //     display: "flex",
  //     flexDirection: "column",
  //     justifyContent: "flex-start"
  //   }
  // },
  background: {
    minHeight: BACKGROUND_SIZE,
    backgroundImage: `url(${BackgroundImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100vh",
    width: "100%",
    backgroundPosition: "fixed",
    alignContent: "center",
    [theme.breakpoints.down("sm")]: {
      backgroundPosition: "80%"
    },
    [theme.breakpoints.down("xs")]: {
      // change mobile background image here "width(px) height(px)"
      // for e.g. Pixel 2, Pixel 2
      backgroundImage: `url(${croppedBackgroundImg})`,
      backgroundPosition: "35%"
    }
  },
  searchCardPush: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "27rem"
    },
    [theme.breakpoints.down("xs")]: {
      //600px
      marginBottom: "27rem"
    },
    "@media (max-width: 375px)": {
      //iphone 6/7/8
      marginBottom: "28em"
    },
    "@media (max-width: 360px)": {
      //galaxy s3
      marginBottom: "28rem"
    },
    "@media (max-width: 320px)": {
      //iphone 4
      marginBottom: "32rem"
    }
  },

  searchCardPushXS: {
    // border: '1px solid red',
    marginBottom: "27rem",
    "@media (max-width: 415px)": {
      //iphone 6/7/8 plus & Nexus 6
      // border: "1px solid blue",
      marginBottom: "25em"
    },
    "@media (max-width: 384px)": {
      //Nexus 4
      // border: "1px solid blue",
      marginBottom: "28rem"
    }
  },
  backgroundLayer: {
    backgroundPosition: "center",
    background: "rgba(0,0,0,0.4)",
    backgroundSize: "cover",
    height: "100vh",
    width: "100%",
    backgroundPosition: "center",
    alignContent: "center"
  },
  filter: {
    margin: "1rem 0rem"
  },

  hideSearchCard: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      "& .MuiCard-root": {
        margin: "4% 0%"
      }
    }
  },
  iconbutton: {
    backgroundColor: COLOR.PRIMARY,
    height: "2rem",
    width: "2rem",

    "&:focus": {
      // outline: "none"
    },
    "&:hover": {
      background: COLOR.PRIMARY,
      borderRadius: "1rem",
      zIndex: 10000
    }
  },
  logo: {
    height: "2rem",
    width: "2rem"
  },
  downIconButtonGrid: {
    position: "absolute",
    paddingBottom: "1rem",
    textAlign: "center",
    bottom: 0,
    left: 0,
    right: 0,
    [theme.breakpoints.down("xs")]: {
      // 600px
      bottom: "-400px"
    },
    "@media (max-width: 420px)": {
      //iphone 6/7/8 plus
      bottom: "-31rem"
    },
    "@media (max-width: 375px)": {
      //iphone 6/7/8
      bottom: "-30.5rem"
    },
    "@media (max-width: 360px)": {
      //galaxy s3
      bottom: "-30.5rem"
    },
    "@media (max-width: 320px)": {
      //iphone 4
      bottom: "-35.5rem"
    }
  },
  collapseView: {
    overflow: "hidden",
    width: "inherit",
    "& .MuiCollapse-wrapper": {
      display: "flex",
      margin: "1rem 0rem"
    }
  },
  noResultMessage: {
    fontFamily: FONTFAMILY.FONT_SANS_NARROW,
    textAlign: "center",
    fontStyle: "normal",
    fontSize: "26px"
  },
  border: {
    border: "1px solid green",
    padding: 0
  },
  noProviders: {
    textAlign: "center",
    margin: "2rem 0rem",
    backgroundColor: COLOR.BACKGROUND_SECONDARY,
    padding: "1rem 0rem"
  },
  createAccount: {
    border: "1px solid green",
    // height: 400,
    marginTop: 80
  },
  formContainer: {
    flexGrow: 1,
    // border: '1px solid green',
    // display: 'flex',
    width: "50%"
    // flex: 'wrap',
    // flexDirection: 'column'
  },
  InputField: {
    height: "40px",
    // width: '221px',
    border: "1px solid black",
    color: COLOR.PRIMARY,
    borderWidth: 1,
    borderRadius: 10
  },
  inputLabel: {
    color: "black",
    border: "1px solid red"
  },
  selectRoot: {
    color: "black",
    paddingRight: "30px",
    // display: "inline",
    "& .svg.MuiSvgIcon-root .MuiStepIcon-completed, path": {
      color: "#4A443F"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black  ",
      borderWidth: "1px  "
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black  ",
      borderWidth: "1px  "
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black  ",
      borderWidth: "1px  "
    }
  },
  navAnchorNormal: {
    color: "#ffffff",
    fontFamily: "PT Sans Narrow",
    fontWeight: "700",
    fontSize: 20,
    padding: "0rem 2rem",
    lineHeight: "128.91%",
    minWidth: "10%",
    letterSpacing: "0.02em",
    "&:hover": {
      // color: COLOR.PRIMARY,
      // background: "#ffffff",
      cursor: "pointer",
      borderRadius: "10px",
      color: "#fff",
      decoration: "none",
      textDecoration: "none"
    }
  },
  selectRoot2: {
    display: "inline",
    paddingBottom: "0em",
    paddingTop: "0em",
    marginBottom: "0em",
    height: "2.3em",
    lineHeight: 2.5
  },
  labels: {
    display: "inline",
    fontFamily: FONTFAMILY.FONT_SANS_NARROW,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    color: "#000000",
    lineHeight: "23px",
    letterSpacing: "0em",
    textAlign: "left",
    paddingBottom: "5px",
    marginLeft: 30
  },
  labels2: {
    display: "inline",
    fontFamily: FONTFAMILY.FONT_SANS_NARROW,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    color: "#000000",
    lineHeight: "23px",
    letterSpacing: "0em",
    textAlign: "left",
    paddingBottom: "5px",
    marginLeft: 30
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5"
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)"
    }
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3"
    }
  },
  select1: {
    width: "210px",
    height: "2.1em",
    marginLeft: 30,
    fontFamily: FONTFAMILY.FONT_SANS_NARROW
  },
  select2: {
    width: "210px",
    height: "40px",
    marginLeft: 30,
    fontFamily: FONTFAMILY.FONT_SANS_NARROW
  },
  textField: {
    width: "10em",
    height: "1em",
    border: "1px solid red",
    searchCardContainer: {
      [theme.breakpoints.down("xs")]: {
        border: "1px solid red",
        margin: "0rem 1rem"
      },
      [theme.breakpoints.down("sm")]: {
        margin: "0rem 1rem"
      }
    }
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3"
    }
  }
}));

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    window.addEventListener("resize", handleResize);

    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}

const ExploreHosts = props => {
  const { hosts, providerList, country_list, serviceData } = props;
  console.log(Store.getState())

  const classes = useStyles();

  // for future use
  /*  //build criteria
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [selectedTravellers, setSelectedTravellers] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState("");
  const [selectedSuitability, setSelectedSuitability] = useState(-1);
  const [selectedInterest, setSelectedInterest] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedResort, setSelectedResorts] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedActivityType, setSelectedActivityType] = useState("");
  const [selectedAbilityLevel, setSelectedAbilityLevel] = useState(-1);
  const [selectedCriteria, setSelectedCriteria] = useState([]);
  const [selectedPricing, setSelectedPricing] = useState("");

  const [Hosts, setHosts] = useState([]);
  const [search, setSearch] = useState(""); */

  // for future use
  /*   const API_PATH = "../api/";
  let responseData;

  useEffect(() => {
    //getting instructor profiles from database
    axios
      .get(API_PATH + "/host")
      .then(response => {
        responseData = response.data;
        responseData.map(item => {
          item.name = item.FirstName + " " + item.LastName;

          console.log(item.name);
        });
        console.log("with name", responseData);
        //props.getName(responseData);
        setHosts(responseData);
      })
      .catch(error => {
        console.log(error);
      });
  }, [props.name, props.getName]); */

  const slogan = "Browse and book Experienced Hosts local to the resort";
  const underSlogan =
    "...just for YOURSELF, your FAMILY, the KIDS, or for a group of FRIENDS";
  const lookingFor = false;

  // for future use
  /*   const containsAll = (arrA, arrB) => {
    const lowerA = arrA.map(
      Function.prototype.call,
      String.prototype.toLowerCase
    );

    return true;
  }; */

  // for future use
  /*  const filteredCards = hosts.filter(host => {
    return (
      host.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 
      /* containsAll(host.criteria, selectedCriteria) &&
      instructor.pricing
        .toLowerCase()
        .indexOf(selectedPricing.toLowerCase()) !== -1 &&
      containsAll(instructor.dates, selectedDates) &&
      (instructor.abilities.indexOf(selectedAbilityLevel) !== -1 ||
        selectedAbilityLevel === -1) &&
      (instructor.activities.indexOf(selectedActivityType) !== -1 ||
        selectedActivityType === "") &&
      containsAll(instructor.resorts, selectedResort) */
  /*     );
  });  */

  const [favHosts, setFavHosts] = React.useState([]);
  const [checked, setChecked] = React.useState(false);
  const [selectedCountry, selectCountry] = React.useState();
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [selectedResort, selectResort] = React.useState();
  const [selectedStartDate, selectStartDate] = React.useState();
  const [selectedEndDate, selectEndDate] = React.useState();
  const [bottom, setBottom] = useState(false);
  const [visitorOrHost, setVisitorOrHost] = useState("");
  const [country, setCountry] = useState([]);
  const [resort, setResort] = useState([]);
  const [disable, setDisable] = useState(true);
  const [servicesData, setServicesData] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const isTablet = useWindowSize();

  useEffect(() => {
    if (providerList.length >= 0 && selectedCountry) {
      // console.log("==providerList==>", providerList);
      // handleChange();
    }
  }, [providerList]);

  useEffect(() => {
    var categoryNameArray = [];
    var objectData = {};
    var objectArray = [];
    var index = 0;

    serviceData &&
      serviceData.forEach(service => {
        if (!categoryNameArray.includes(service.catagory_name)) {
          categoryNameArray.push(service.catagory_name);
          objectData.category_name = service.catagory_name;
          objectData.id = "";
          objectData.header = true;
          index = objectArray.length;
          objectArray[index] = { ...objectData };
        }
        objectData.category_name = service.catagory_name;
        objectData.id = service.id;
        objectData.service_name = service.service_name;
        objectData.header = false;
        index = objectArray.length;
        objectArray[index] = { ...objectData };
      });
    setServicesData(objectArray);
  }, [serviceData]);

  useEffect(() => {
    props.getCountryList();
    props.getServiceData();
  }, []);

  // const handleChange = () => {
  //   setChecked(prev => !prev);
  // };

  const handleVisitorOrHost = event => {
    setVisitorOrHost(event.target.value);
    // console.log(visitorOrHost)
  };

  // const alhpabetCountryList = () => {
  //   return country_list.sort((a, b) => {
  //     return b.country_name > a.country_name
  //       ? -1
  //       : b.country_name < a.country_name
  //       ? 1
  //       : 0;
  //   });
  // };

  const handleInterests = event => {
    // console.log("Handle Interest", event.target.value, selectedInterests);
    setSelectedInterests(event.target.value);
    // console.log("After Handle Interest", event.target.value, selectedInterests);
  };

  // const checkOptions = alhpabetCountryList().map(data => (
  const handleCountry = countryValue => {
    selectCountry(countryValue);
    // console.log("Selected Country", selectedCountry, countryValue);
  };
  const handleResort = resortValue => {
    selectResort(resortValue);
    // console.log("Selected Country", selectedCountry, resortValue);
  };
  const handleStartDate = startDateValue => {
    selectStartDate(startDateValue);
    // console.log("Selected Country", selectedCountry, startDateValue);
  };
  const handleEndDate = endDateValue => {
    selectEndDate(endDateValue);
    // console.log("Selected Country", selectedCountry, endDateValue);
  };

  const handleChange = val => {
    setChecked(!checked);
  };

  const handleOpen = val => {
    setChecked(val);
  };

  // const countryOptions = country_list.map(data => (
  //   <MenuItem key={data.id} value={data.id}>
  //     {data.country_name}
  //   </MenuItem>
  // ));

  const formData = () => {
    const data = {
      firstName,
      lastName,
      visitorOrHost,
      selectedInterests,
      country,
      resort
    };
    // console.log(data);
  };

  const handleFirstName = event => {
    setFirstName(event.target.value);
  };

  const handleLastName = event => {
    setLastName(event.target.value);
  };

  const checkOptions = country_list.map(data => (
    <MenuItem key={data.id} value={data}>
      {data.country_name}
    </MenuItem>
  ));

  const onCountryHandleChange = event => {
    // console.log(event.target.value);
    setCountry(event.target.value.id);
    props.getResortList(event.target.value.id);
    setDisable(false);
  };

  const onResortHandleChange = event => {
    setResort([...resort, event.target.value]);
    // console.log(event.target.value)
  };

  const handleSubmit = event => {
    event.preventDefault();
    // console.log(formData());
  };

  const resortOptions = props.resortList.map(data => (
    <MenuItem key={data.id} value={data.id}>
      {data.resort_name}
    </MenuItem>
  ));

  return (
    <>
      {/* <div className={classes.container}></div> */}
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.backgroundContainer}
      >
        <Grid
          container
          item
          className={
            isTablet.height <= 825 && isTablet.width <= 420
              ? classes.background + " " + classes.searchCardPushXS
              : classes.background + " " + classes.searchCardPush
          }
        >
          {/* <div className={classes.backgroundLayer}> */}
          <Grid
            item
            // sm={12}
            xs={12}
            className={classes.searchCardContainer}
          >
            <Header
              slogan={slogan}
              underSlogan={underSlogan}
              lookingFor={lookingFor}
              center={true}
              searchCardBlue={true}
              onSelectCountry={handleCountry}
              onSelectResort={handleResort}
              onSelectStartDate={handleStartDate}
              onSelectEndDate={handleEndDate}
              onSearchClick={handleOpen}
            />
          </Grid>

          {/* <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.hideSearchCard}
        >
          <SearchCardForExploreInstructors />
        </Grid> */}
          {providerList.length >= 0 && selectedCountry && selectedResort && (
            <Hidden smDown>
              <Grid
                item
                xs={12}
                className={classes.downIconButtonGrid}
                id="result"
              >
                <IconButton
                  onClick={handleChange}
                  style={{
                    transform: checked ? ["rotate(90deg)"] : ["rotate(270deg)"]
                  }}
                  className={classes.iconbutton}
                >
                  <img
                    src={downArrow}
                    className={classes.logo}
                    alt="down arrow"
                  />
                </IconButton>
              </Grid>
            </Hidden>
          )}

          {/* </div> */}
        </Grid>
        {/* filter */}
        {/* providerList.length > 0 */}
        {providerList.length >= 0 && selectedCountry && selectedResort ? (
          <Collapse
            in={checked}
            className={classes.collapseView}
            style={{ overflow: "hidden" }}
          >
            <Grid
              container
              // justify="center"
              // alignItems="center"
              item
              // spacing={4}
              // sm={12}
              // xs={12}
              className={classes.filter}
            >
              <ContentFilter
                selectedCountry={selectedCountry}
                selectedResort={selectedResort}
                selectedStartDate={selectedStartDate}
                selectedEndDate={selectedEndDate}
                // handleLanguage={setSelectedLanguage}
                // handleTravellers={setSelectedTravellers}
                // handleSuitableFor={setSelectedSuitability}
                // handleActivities={setSelectedActivity}
                // handleInterestedIn={setSelectedInterest}
              />
            </Grid>
            <Grid
              container
              direction="column"
              justify="center"
              className={classes.noProviders}
            >
              {isTablet.width < 960 ? (
                <>
                  <Typography variant="h4" className={classes.noResultMessage}>
                    <b>No providers yet at</b>
                  </Typography>
                  <br />
                  <Typography variant="h5" className={classes.noResultMessage}>
                    {selectedResort}
                  </Typography>
                  <br />
                  <Typography variant="body2">
                    Sign up available soon
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="h4" className={classes.noResultMessage}>
                    <b>No providers yet at</b> {selectedResort}
                  </Typography>
                  <br />
                  <Typography variant="body2">
                    Sign up available soon
                  </Typography>
                </>
              )}
            </Grid>

            {/* Create an account section */}
            {/* <Container maxWidth="sm" style={{ marginTop: 30 }}>
        <FormControl>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} style={{ marginBottom: 30 }}>
              <Typography
                variant="h4"
                getterBottom
                style={{ fontFamily: "PT Sans Narrow", marginRight: "30px" }}
              >
                <b>Create An Account</b>
              </Typography> */}
            {/* Provider Cards section */}
            {/* <Container maxWidth="md" className={classes.border}>
            <Grid container justify="center">
              {providerList.map(provider => (
                <ProviderResultCard providerDetails={provider} />
              ))}

              {providerList > 6 && (
                <Grid container item justify="center">
                  <Button>see more results</Button>
                </Grid>
              )}
            </Grid>
          </Container> */}
            {/* </Grid>
           </Grid>
          </FormControl>
          </Container> */}
          </Collapse>
        ) : (
          <Collapse in={checked} itemclasses={classes.collapseView}>
            <Grid justify="center">
              <Grid
                // justify="center"
                // alignItems="center"
                item
                // spacing={4}
                // sm={12}
                // xs={12}
                className={classes.filter}
              >
                <ContentFilter
                  selectedCountry={selectedCountry}
                  selectedResort={selectedResort}
                  selectedStartDate={selectedStartDate}
                  selectedEndDate={selectedEndDate}
                  // handleLanguage={setSelectedLanguage}
                  // handleTravellers={setSelectedTravellers}
                  // handleSuitableFor={setSelectedSuitability}
                  // handleActivities={setSelectedActivity}
                  // handleInterestedIn={setSelectedInterest}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="column"
              justify="center"
              className={classes.noProviders}
            >
              {/* <Typography variant="h4" classes={classes.noResultMessage}>
              No providers yet at {selectedResort}
            </Typography>
            <Typography variant="subtitle" classes={classes.noResultMessage}>
              To get more results, try adjusting your search by changing your
              dates
            </Typography> */}
            </Grid>
          </Collapse>
        )}
        {/* CREATE AN ACCOUNT SECTION */}

        {/* <Grid container alignItems="center">
        <Container maxWidth="sm" style={{ marginTop: 30 }}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} style={{ marginBottom: 30 }}>
                <Typography
                  variant="h4"
                  getterBottom
                  // align="center"
                  style={{ fontFamily: "PT Sans Narrow" }}
                >
                  <b>Create An Account</b>
                </Typography>
              </Grid>

              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={6} style={{ marginBottom: 10 }}>
                  <Grid container direction="column" alignItems="center">
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <label
                        htmlFor="firstName"
                        style={{
                          fontSize: "18px",
                          fontFamily: "PT Sans Narrow"
                        }}
                      >
                        <b>First Name</b>
                      </label>
                      <input
                        required
                        style={{
                          borderRadius: "5px",
                          border: "1px solid black",
                          height: "40px",
                          width: "210px",
                          paddingLeft: 10,
                          fontFamily: "PT Sans Narrow"
                        }}
                        onChange={handleFirstName}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6} style={{ marginBottom: 10 }}>
                  <Grid container direction="column" alignItems="center">
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <label
                        htmlFor="lastName"
                        style={{
                          fontSize: "18px",
                          fontFamily: "PT Sans Narrow"
                        }}
                      >
                        <b>Last Name</b>
                      </label>
                      <input
                        required
                        style={{
                          borderRadius: "5px",
                          border: "1px solid black",
                          height: "40px",
                          width: "210px",
                          paddingLeft: 10,
                          fontFamily: "PT Sans Narrow"
                        }}
                        onChange={handleLastName}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6} style={{ marginBottom: 10 }}>
                  <Grid container direction="column" alignItems="center">
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <FormControl
                        variant="outlined"
                        color="primary"
                        classes={{ root: classes.selectRoot }}
                      >
                        <Typography className={classes.labels2}>
                          Are You A Visitor or Host?{" "}
                        </Typography>
                        <Select
                          required
                          className={classes.select2}
                          classes={{
                            root: classes.selectRoot2
                          }}
                          onChange={event => handleVisitorOrHost(event)}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null
                          }}
                        >
                          <MenuItem
                            value="visitor"
                            containerElement={
                              <NavLink activeStyle={{ color: "blue" }} />
                            }
                          >
                            Visitor
                          </MenuItem>
                          <MenuItem value="host">
                            Host / Service Provider
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6} style={{ marginBottom: 10 }}>
                  <Grid container direction="column" alignItems="center">
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <FormControl
                        variant="outlined"
                        color="primary"
                        classes={{ root: classes.selectRoot }}
                      >
                        <Typography className={classes.labels2}>
                          Interested in{" "}
                        </Typography>
                        <Select
                          multiple
                          value={selectedInterests}
                          onChange={handleInterests}
                          renderValue={selectedInterests =>
                            selectedInterests.join(",")
                          }
                          className={classes.select2}
                          classes={{
                            selectMenu: classes.selectMenu,
                            root: classes.selectRoot,
                            icon: classes.selectIcon,
                            selected: classes.selected
                          }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null
                          }}
                        >
                          {servicesData &&
                            servicesData.map(service =>
                              service.header ? (
                                <ListSubheader
                                  classes={{ root: classes.subHeaderRoot }}
                                  disableSticky
                                >
                                  {service.category_name}
                                </ListSubheader>
                              ) : (
                                <MenuItem
                                  key={service.id}
                                  value={service.service_name}
                                  classes={{ root: classes.listRoot }}
                                >
                                  <Checkbox
                                    // onChange={event => handleInterests(event)}
                                    checked={
                                      selectedInterests.indexOf(
                                        service.service_name
                                      ) > -1
                                    }
                                    className={classes.root}
                                    disableRipple
                                    color="default"
                                    checkedIcon={
                                      <span
                                        className={clsx(
                                          classes.icon,
                                          classes.checkedIcon
                                        )}
                                      />
                                    }
                                    icon={<span className={classes.icon} />}
                                    inputProps={{
                                      "aria-label": "decorative checkbox"
                                    }}
                                  />
                                  <ListItemText
                                    primary={service.service_name}
                                    className={classes.listitem}
                                    classes={{ primary: classes.primary }}
                                  />
                                </MenuItem>
                              )
                            )}

                          <ListSubheader
                            classes={{ root: classes.subHeaderRoot2 }}
                            disableSticky
                          >
                            *requires qualification check
                          </ListSubheader>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                {visitorOrHost === "host" ? (
                  <>
                    <CountryAndResortFields
                      checkOptions={checkOptions}
                      onCountryHandleChange={onCountryHandleChange}
                      resortOptions={resortOptions}
                      onResortHandleChange={onResortHandleChange}
                      disable={disable}
                    />
                  </>
                ) : (
                  <>{""}</>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ marginBottom: 30 }}
            >
              <Grid item style={{ marginTop: 30 }}>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  style={{
                    background: "#FF6161",
                    color: "white",
                    marginRight: "30px",
                    borderRadius: "100px",
                    width: "100%"
                  }}
                >
                  <a className={classes.navAnchorNormal} href="/register">
                    Register
                  </a>
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Grid> */}
      </Grid>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    getName: name => dispatch(sendHostName(name)),
    getResortList: id => dispatch(getResortList(id)),
    getCountryList: () => dispatch(getCountryList()),
    getServiceData: () => dispatch(getServiceData())
  };
}

function mapStateToProps(state) {
  return {
    hosts: state.exploreHostsReducer.fakeHostData,
    providerList: state.homePageReducer.providerList,
    country_list: state.homePageReducer.country_list,
    resortList: state.homePageReducer.resortList,
    serviceData: state.exploreHostsReducer.serviceData
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExploreHosts);
