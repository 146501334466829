// Actions
const hostReducerDefaultState = {
  firstName: "Homer",
  lastName: "Simpson",
  description:
    "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
  imageURL:
    "https://www.resorter.app/client/build/static/media/background.72d7a155.jpg",
  price: 61.5,
  rating: 3.2,

  // dummy values for languages and specialties
  // TODO: These values should be passed from DB in the future
  // fetching data from DB has been implemented in the SignupFormMoreInfoHost.js
  // dummy values can be removed once data is saved in DB

  // languages
  languages: ["English", "German", "Russian", "Spanish", "Mandarin"],

  // gender
  gender: ["Male", "Female", "Not specified"],

  // target audience
  targetAudience: ["Kids", "Adults", "Both Kids and Adults"],

  // specialties
  generalConcierge: [
    "Accommodation/equipment rental/lessons",
    "Transfers/resort entry"
  ],
  generalAssistance: ["Equipment rental"],
  activities: [
    "Ski/snb backcountry touring",
    "Snowshoeing",
    "Snowmobiling",
    "Hiking"
  ],
  tours: ["General tour of the slopes", "General tour of Village"],
  childrenCertificate: [
    "On slope - skiing",
    "On slope - snowboarding",
    "Off slope",
    "Special needs care experience",
    "Can drive a car",
    "Can drive own car"
  ],
  interests: [
    "Culture",
    "Food",
    "Astronomy",
    "Comedy",
    "History",
    "Art",
    "Music"
  ]
};

const hostReducer = (state = hostReducerDefaultState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default hostReducer;
