import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import classNames from "classnames";

import Gallery from "../../../components/virtualLessons/InstructorGalleryCarousel.js";
import Reviews from "../../../components/virtualLessons/InstructorReviewCarousel.js";
import VisitorIntroSection from "../../../components/exploreHosts/profile/VisitorIntroSection.js";
import VisitorMyProfile from "../../../components/exploreHosts/profile/VisitorMyProfile.js";
import FooterBar from "../../../components/Common/Footer/FooterBar.js";

import {
  UPDATE_AGE,
  UPDATE_GENDER,
  UPDATE_COUNTRY,
  UPDATE_LANGUAGES,
  UPDATE_ABILITY
} from "../../../redux/actions/action-types";
import { connect } from "react-redux";

const useStyles = makeStyles({
  main: {
    margin: "2em"
  },
  typograhyFormat: {
    fontStyle: "normal",
    fontFamily: "PT Sans Narrow",
    fontSize: 16,
    lineHeight: "22.50px",
    textAlign: "left",
    color: "#1277B8",
    marginTop: "2rem",
    marginBottom: "2rem",
    marginLeft: 50,
    fontWeight: "bolder",
    width: "100%"
  },
  arrowIcon: {
    transform: "rotate(180deg)"
  },
  backButton: {
    margin: "3em",
    borderColor: "#FF6161",
    color: "#FF6161"
  }
});
const VisitorProfile = props => {
  const { visitorMyProfile, visitorIntroSection, isHost } = props;

  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Grid container item sm={12} xs={12}>
        <Grid container item sm={6} xs={12} spacing={2} lg={12}>
          <Button
            className={classes.backButton}
            variant="outlined"
            startIcon={<ArrowRightAltIcon className={classes.arrowIcon} />}
          >
            Back
          </Button>
        </Grid>
      </Grid>

      <VisitorMyProfile {...visitorMyProfile} isHost={isHost} />

      <VisitorIntroSection {...visitorIntroSection} />

      <Grid container direction="Row" alignContent="center" justify="center">
        <Grid item xs={12} md={12}>
          <Typography className={classes.typograhyFormat}>
            {isHost ? "WHAT HOST SAY" : "REVIEWS"}
          </Typography>
          <Reviews instructorId={1} />
        </Grid>

        <Grid item xs={12} md={12}>
          <Typography className={classes.typograhyFormat}>GALLERY</Typography>
          <Gallery instructorId={1} />
        </Grid>
      </Grid>

      <Grid container direction="Row" alignContent="center" justify="center">
        <Grid item xs={12}>
          <FooterBar />
        </Grid>
      </Grid>
    </div>
  );
};

// Reducer part
function mapStateToProps(state) {
  return {
    visitorIntroSection: {
      ...state.visitorProfileReducer.visitorIntroSection
    },
    visitorMyProfile: {
      ...state.visitorProfileReducer.visitorMyProfile
    }
  };
}

// Connect part
export default connect(
  mapStateToProps,
  {
    UPDATE_AGE,
    UPDATE_GENDER,
    UPDATE_COUNTRY,
    UPDATE_LANGUAGES,
    // UPDATE_YEARS_OF_EXPERIENCE,
    UPDATE_ABILITY
  },
  null,
  { forwardRef: true }
)(VisitorProfile);
