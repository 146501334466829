import React, { forwardRef } from "react";
import FooterBar from "../../../components/Common/Footer/FooterBar";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Mastercard from "../../../materials/payment/Mastercard.jpg";
import VISA from "../../../materials/payment/visa.svg";
import Paypal from "../../../materials/payment/Paypal.png";
import Button from "@material-ui/core/Button";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import Rating from "@material-ui/lab/Rating";
import Avatar from "@material-ui/core/Avatar";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Payouttab from "./Payouttab";
import { addNewPaymentMethod } from "../../../redux/actions/paymentActions.js";
import { connect } from "react-redux";
import { Switch, Route, withRouter } from "react-router-dom";
import Link from "@material-ui/core/Link";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  divStyle: {
    fontFamily: "PT Sans Narrow",
    textAlign: "left"
  },
  marginTopStyle: {
    marginTop: 15
  },
  textFieldStyleTitle: {
    fontFamily: "PT Sans Narrow",
    fontWeight: "bold",
    color: "#1277B8",
    fontSize: 24,
    lineHeight: "140.62%",
    marginTop: 20
  },
  textFieldStyleSubtitle: {
    fontFamily: "PT Sans Narrow",
    fontWeight: "bold",
    color: "#000000",
    fontSize: 18,
    lineHeight: "140.62%",
    marginTop: 15
  },
  textField: {
    height: 10,
    fontSize: 5,
    size: "small",
    padding: "0em",
    paddingLeft: "1rem",
    border: 10,
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    borderRadius: 0,
    marginBottom: 10
  },
  textFieldAccount: {
    height: 45,
    fontSize: 5,
    size: "small",
    width: "100%",
    padding: "0em",
    paddingLeft: "1rem",
    border: 10,
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    borderRadius: 0,
    marginTop: 15,
    marginBottom: 20,
    textAlign: "left"
  },
  text: {
    fontFamily: "PT Sans Narrow"
  },
  tab: {
    fontFamily: "PT Sans Narrow",
    fontWeight: "bolder",
    fontSize: 18,
    textTransform: "none",
    color: "#1277B8",
    "&:focus": {
      outline: "none"
    },
    "& span": {
      alignItems: "normal"
    },
    textAlign: "left"
  },

  root: {
    background: "#FFFFFF"
  },
  paper: {
    padding: theme.spacing(5),
    border: "1px solid",
    borderColor: "red"
  },
  paper2: {
    padding: theme.spacing(5),
    background: "#F6F6F6"
  },
  btn: {
    color: "red",
    height: "3em",
    width: "7em",
    margin: "2em",
    border: "1px solid #FF6161"
  },
  btnArrow: {
    position: "absolute",
    left: "0",
    transform: "rotate(180deg)"
  },
  bluebtn: {
    borderRadius: 20,
    width: 311,
    height: 49,
    bottom: -25,
    background: "#1277B8",
    right: "30%"
  },
  btmbtn: {
    borderRadius: 25,
    width: 220,
    height: 35,
    right: "10%",
    background: "#FF6161"
  },
  emptyCard: {
    position: "relative",
    width: 1600,
    height: 100,
    background: "#F6F6F6"
  },

  textField: {
    height: 45,
    fontSize: 5,
    size: "small",
    width: "100%",
    padding: "0em",
    paddingLeft: "4.5rem",
    border: 10,
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    borderRadius: 0,
    marginBottom: 10
  },
  textFieldPopup: {
    height: 45,
    width: "100%",
    fontSize: 15,
    size: "small",
    padding: "0em",
    paddingLeft: "0.5rem",
    border: 10,
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    borderRadius: 0,
    marginBottom: 10,
    "&::placeholder": {
      color: "#1277B8"
    }
  },
  textField1: {
    height: 45,
    width: "100%",
    fontSize: 5,
    size: "small",
    padding: "0em",
    border: 10,
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    borderRadius: 0,
    marginBottom: 10
  },
  mainGrid: {
    background: "#F6F6F6",
    position: "center"
  },
  preMainGrid: {
    background: "#F6F6F6",
    position: "center"
  },
  cardpaygrid: {},
  imgsizevisa: {
    width: 61,
    height: 32
  },
  imgsizemaster: {
    width: 61,
    height: 32
  },
  imgsizepaypal: {
    width: 70,
    height: 52
  },
  headingStyle: {
    color: "#1277B8",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 30,
    lineHeight: "140.62%",
    alignItems: "center",
    textAlign: "center"
  },
  roundimg: {
    width: 150,
    height: 150,
    top: "10%"
  },
  text: {
    fontFamily: "PT Sans Narrow"
  },
  btnpay: {
    borderRadius: 100,
    background: "#FF6161",
    height: 35,
    width: 70,
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontSize: 17,
    fontWeight: "normal",
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
    border: "none",
    marginTop: 20
  },
  btnpayLarge: {
    borderRadius: 100,
    background: "#FF6161",
    height: 35,
    width: 200,
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontSize: 18,
    fontWeight: "normal",
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
    border: "none",
    marginTop: 20,
    marginBottom: 80
  },
  btnpayLarge2: {
    borderRadius: 100,
    background: "#FF6161",
    height: 35,
    width: 253,
    marginTop: 15
  },

  emptyCard: {
    height: 40
  },

  textFieldStyle: {
    fontFamily: "PT Sans Narrow",
    fontWeight: "bold",
    color: "#1277B8",
    fontSize: 20,
    lineHeight: "140.62%",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14
    }
  },
  paymentInputLabel: {
    color: "black",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 16,
    lineHeight: "140.62%"
  },
  paymentBtnContainer: {
    textAlign: "left",
    width: "55%"
  },
  payoutContainer: {
    textAlign: "left",
    marginLeft: "1%"
  },
  payoutSection: {
    margin: "2em 0",
    textAlign: "left",
    width: "75%"
  },
  paymentMethodImgContainer: {
    marginLeft: "1em"
  },
  paymentDialog: {
    margin: "0 auto",
    width: "90%"
  },
  popupSection: {
    marginBottom: "1rem"
  },
  closeBtnContainer: {
    marginLeft: "2.3rem"
  },
  cardInfoContainer: {
    width: "75%",
    margin: "0 auto"
  },
  cardInfo: {
    height: 44,
    backgroundColor: "rgba(18, 119, 184, 0.15)"
  },
  cardInfoGrid: {
    margin: "1rem 0"
  },
  cardInfoText: {
    lineHeight: 2.5
  }
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function AutoGrid(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { cardInfo } = props;

  const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles(theme => ({
    root: {
      padding: theme.spacing(2)
    }
  }))(MuiDialogContent);

  const DialogActions = withStyles(theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(1)
    }
  }))(MuiDialogActions);

  return (
    <div className={classes.root} align="center">
      <Grid
        container
        lg={8}
        md={9}
        sm={10}
        xs={12}
        direction="column"
        justify="center"
      >
        <div align="left">
          <Button
            variant="outlined"
            className={classes.btn}
            classes={{ root: classes.btnRoot, label: classes.btnLabel }}
          >
            <ArrowRightAltIcon
              className={classes.btnArrow}
              variant="outlined"
            />
            Back
          </Button>
        </div>

        <div>
          <p className={classes.headingStyle}>Payment and Payouts</p>
        </div>

        <div className={classes.cardInfoContainer}>
          <AppBar
            elevation={0} //clear the shadow
            className={classes.AppBar}
            position="static"
            color={"transparent"}
          >
            <Tabs
              className={classes.Tabs}
              // change the color of indicator to grey
              TabIndicatorProps={{
                style: { backgroundColor: "#1277B8" }
              }}
              variant="fullWidth"
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="Payments" className={classes.tab} />
              <Tab label="Payouts" className={classes.tab} {...a11yProps(1)} />
              {/*<Tab label="Item Three" {...a11yProps(2)} />*/}
            </Tabs>
          </AppBar>

          <TabPanel value={value} index={0}>
            <div className={classes.root} align="center">
              <div className={classes.emptyCard} />
              <Grid>
                {cardInfo.map(element => {
                  return (
                    <Grid
                      md={9}
                      sm={10}
                      xs={12}
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      className={classes.cardInfoGrid}
                    >
                      <Grid item md={5} sm={6} xs={12}>
                        <div className={classes.paymentInputLabel}>
                          Your Credit/Debit cards
                        </div>
                      </Grid>
                      <Grid item md={7} sm={6} xs={12}>
                        <div className={classes.cardInfo}>
                          <p className={classes.cardInfoText}>
                            {"xxxxxxxxxx" +
                              element.substring(element.length - 4)}
                          </p>
                        </div>
                      </Grid>
                    </Grid>
                  );
                })}
                <div className={classes.emptyCard} />
              </Grid>
              <Grid container item md={12} sm={12} xs={12}>
                <Grid item md={3} sm={1} />
                <Grid item md={6} sm={10} xs={12} align="center">
                  <button
                    onClick={handleClickOpen}
                    color="primary"
                    variant="contained"
                    className={classes.btnpayLarge}
                  >
                    Add New Payment Method
                  </button>
                </Grid>
                <Grid item md={3} sm={1} />
              </Grid>

              <Dialog
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
                open={open}
                fullWidth="true"
              >
                <DialogContent dividers>
                  <div className={classes.paymentDialog}>
                    {/* accepted payments */}
                    <Grid xs={12} container direction="row" spacing={1}>
                      <Grid item md={11} sm={11} xs={10}>
                        <div align="left" className={classes.textFieldStyle}>
                          ACCEPTED PAYMENTS
                          <div className={classes.paymentMethodImgContainer}>
                            <img
                              src={VISA}
                              position="absolute"
                              align="center"
                              top="20px"
                              alt="Visa card logo"
                              className={classes.imgsizevisa}
                            />
                            <img
                              src={Mastercard}
                              align="center"
                              position="absolute"
                              top="20px"
                              alt="Master card logo"
                              className={classes.imgsizemaster}
                            />
                            <img
                              src={Paypal}
                              align="center"
                              position="absolute"
                              top="20px"
                              alt="Paypal logo"
                              className={classes.imgsizepaypal}
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={1} sm={1} xs={2}>
                        <div>
                          <IconButton
                            aria-label="close"
                            onClick={() => handleClose()}
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                      </Grid>
                    </Grid>
                    {/* name part */}
                    <Grid
                      xs={12}
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={6}>
                        <div align="left" className={classes.textFieldStyle}>
                          FIRST NAME
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div align="left" className={classes.textFieldStyle}>
                          SURNAME
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      xs={12}
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={6}>
                        <div align="left" className={classes.textFieldStyle}>
                          <input
                            fullWidth
                            id="cardname"
                            variant="filled"
                            className={classes.textFieldPopup}
                            size="small"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div align="left" className={classes.textFieldStyle}>
                          <input
                            fullWidth
                            id="cardname"
                            variant="filled"
                            className={classes.textFieldPopup}
                            size="small"
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid
                      xs={12}
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={6}>
                        <div align="left" className={classes.textFieldStyle}>
                          CARD INFO
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div />
                      </Grid>
                    </Grid>

                    <Grid
                      xs={12}
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item md={12} xs={12}>
                        <div align="left">
                          <input
                            id="cardname"
                            variant="filled"
                            className={classes.textField1}
                            size="small"
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid
                      xs={12}
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={6}>
                        <div align="left" className={classes.textFieldStyle}>
                          <input
                            fullWidth
                            id="cardname"
                            variant="filled"
                            className={classes.textFieldPopup}
                            size="small"
                            placeholder="Expiry"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div align="left" className={classes.textFieldStyle}>
                          <input
                            fullWidth
                            id="cardname"
                            variant="filled"
                            className={classes.textFieldPopup}
                            size="small"
                            placeholder="CVV"
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid
                      xs={12}
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={6}>
                        <div align="left" className={classes.textFieldStyle}>
                          BILLING INFO
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div align="left" className={classes.textFieldStyle}>
                          COUNTRY/REGION
                        </div>
                      </Grid>
                    </Grid>

                    <Grid
                      xs={12}
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={6}>
                        <div align="left" className={classes.textFieldStyle}>
                          <input
                            fullWidth
                            id="cardname"
                            variant="filled"
                            className={classes.textFieldPopup}
                            size="small"
                            placeholder="Postcode"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div align="left" className={classes.textFieldStyle}>
                          <input
                            fullWidth
                            id="cardname"
                            variant="filled"
                            className={classes.textFieldPopup}
                            size="small"
                            placeholder="Please select an option "
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid
                      xs={12}
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={6} align="center">
                        <button
                          color="primary"
                          variant="contained"
                          className={classes.btnpay}
                        >
                          Add
                        </button>
                      </Grid>
                      <Grid item xs={6} align="center">
                        <button
                          color="primary"
                          variant="contained"
                          className={classes.btnpay}
                          onClick={() => handleClose()}
                        >
                          Cancel
                        </button>
                      </Grid>
                    </Grid>
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <div className={classes.root} align="center">
              <Grid
                md={9}
                sm={10}
                xs={12}
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                {/* title 1 */}
                <Grid item xs={12}>
                  <div align="left" className={classes.textFieldStyleTitle}>
                    Payout Method
                  </div>
                </Grid>
                {/* content 1 */}
                <Grid xs={12}>
                  <div className={classes.divStyle}>
                    When you receive a payment for a reservation, we call that
                    payment to you a 'payout'. Our secure payment system
                    supports several payment methods , which can be set up
                    below.
                  </div>
                </Grid>
                {/* title 2 */}
                <Grid item xs={12}>
                  <div align="left" className={classes.textFieldStyleSubtitle}>
                    You've successfully added a payout method
                  </div>
                </Grid>
                {/* content 2 */}
                <Grid xs={12}>
                  <div textAlign="justify" className={classes.divStyle}>
                    It'll take up to 5 business days for us to verify it. Once
                    its status is set to 'Ready', we'll pay you through this
                    payout method.
                  </div>
                </Grid>
                {/*Bank Account*/}
                <Grid
                  xs={12}
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item xs={3}>
                    <div align="left" className={classes.paymentInputLabel}>
                      Bank Account
                    </div>
                  </Grid>
                  <Grid item lg={6} xs={9}>
                    <div align="left">
                      <input
                        fullWidth
                        id="cardname"
                        variant="filled"
                        disabled
                        className={classes.textFieldAccount}
                        size="small"
                      />
                    </div>
                  </Grid>
                  <Grid item lg={3} xs={9} />
                </Grid>
                {/* button */}
                <Grid container item xs={12}>
                  <Grid item md={3} sm={1} />
                  <Grid item md={6} sm={10} xs={12} align="center">
                    <Link href="/payoutTab">
                      <button
                        color="primary"
                        variant="contained"
                        className={classes.btnpayLarge}
                      >
                        Add New Payout Method
                      </button>
                    </Link>
                  </Grid>
                  <Grid item md={3} sm={1} />
                </Grid>
              </Grid>
            </div>
          </TabPanel>
        </div>
      </Grid>
    </div>
  );
}

function Payment(props) {
  const { cardInfo } = props;
  return (
    <div>
      <AutoGrid cardInfo={cardInfo} />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    cardInfo: state.paymentReducer.cardInfo
  };
}

export default connect(
  mapStateToProps,
  { addNewPaymentMethod },
  null,
  { forwardRef: true }
)(Payment);
