import React from "react";
import useStyles from "./MyAccountStyles";
import { Button, Grid, Dialog, DialogContent } from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const Disabilities = ({
  studentData,
  handleCloseDis,
  handleClickOpenDis,
  opendis,
  disabled
}) => {
  const classes = useStyles();

  return (
    <Grid item container lg={6} md={6} sm={6} xs={12}>
      <Grid item lg={4} md={4} sm={4} xs={2}>
        <div align="right" className={classes.textFieldStyle}>
          Disabilities:
        </div>
      </Grid>
      <Grid item lg={2} md={2} sm={2} xs={1} />
      <Grid item lg={5} md={5} sm={5} xs={9}>
        <div align="left">
          <input
            fullWidth
            id="cardname"
            variant="filled"
            className={classes.textField}
            size="small"
            disabled={disabled}
            onClick={handleClickOpenDis}
            value={studentData.disabilities.details}
          />
        </div>
      </Grid>
      <Dialog
        fullWidth="true"
        open={opendis}
        onClose={handleCloseDis}
        aria-labelledby="form-dialog-title"
      >
        <Button
          variant="outlined"
          className={classes.btn}
          classes={{ root: classes.btnRoot, label: classes.btnLabel }}
          onClick={handleCloseDis}
        >
          <ArrowRightAltIcon className={classes.btnArrow} variant="outlined" />
          Back
        </Button>
        <DialogContent style={{ marginLeft: "40px", marginTop: "-20px" }}>
          <div className={classes.textFieldStyle}>PLEASE PROVIDE DETAILS</div>
          <input
            id="cardname"
            variant="filled"
            className={classes.textFieldProDetail}
            size="small"
            value={studentData.disabilities.details}
          />
          <div style={{ marginBottom: "15px" }}>
            Please enter your adaptive sports membership information if you are
            associated
          </div>
          <Grid container>
            <Grid item xs={6}>
              <div className={classes.textFieldStyle}> MEMBERSHIP TYPE</div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.textFieldStyle}> MEMBERSHIP ID#</div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <input
                id="cardname"
                variant="filled"
                className={classes.textFieldDis}
                size="small"
                value={studentData.disabilities.membertype}
              />
            </Grid>
            <Grid item xs={6}>
              <input
                id="cardname"
                variant="filled"
                className={classes.textFieldDis}
                size="small"
                value={studentData.disabilities.memberid}
              />
            </Grid>
          </Grid>
          <button onClick={handleCloseDis} className={classes.btnSave}>
            Save
          </button>
        </DialogContent>
      </Dialog>
      <Grid item lg={1} md={1} sm={1} />
    </Grid>
  );
};

export default Disabilities;
