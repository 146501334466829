import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { getCountryList } from "../../../redux/actions/homePageAction.js";
import { connect } from "react-redux";
import {
  Box, 
  MenuItem,
  FormControl,
  Select,
  Chip,
  ListItemText,
  Typography,
  FormHelperText
} from "@material-ui/core";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  errorText:{
    color: "#f44336", marginLeft: "14px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "22px",
    },
  },
  selectBox: {
    fontColor: "1277B8",
    marginLeft: "7.5%",
    width: "85%",
    fontFamily: "PT Sans Narrow",
    borderRadius: "10px",
    "& .MuiOutlinedInput-input": {
      padding: "0.5rem 0.5rem",
      height: "auto"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black  ",
      borderWidth: "1px  "
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black  ",
      borderWidth: "1px  "
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black  ",
      borderWidth: "1px  "
    },
    "& .MuiListItemText-root": {
      marginTop: "0px"
    },
    "& .MuiTypography-body1": {
      lineHeight: "inherit"
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "2.5%",
      width: "95%"
    },
  }, 
  selectRoot: {
    borderColor: "black",
    width: "100%",
    "& div.MuiOutlinedInput-root": {
      height: "auto",
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      borderColor: "black !important"
    },
    "& .MuiOutlinedInput-root .MuiSelect-select:focus": {
      backgroundColor: "transparent"
    }
  }, 
  label: {
    marginLeft: "7.5%",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    marginBottom: "0.5em",
    textTransform: "uppercase",
    color: "#000000",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "2.5%",
      width: "95%"
    },
  }, 
}));

const Nationality = props => {
  const classes = useStyles();
  const { country_list } = props;
  const { nationality,  setNationality, isDisabled } = props;
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOptions([value.country_name]);
    setNationality(value);
  };

  // get ALL countries
  useEffect(() => {
    props.getCountryList();
  }, []);

   // prefill user selected option
  useEffect(() => {
    if (nationality != undefined) {
      if (nationality.length == 0) {
        setError(true);
      } else {
        setError(false);
      }
      setSelectedOptions([nationality.country_name]);
    }
  }, [nationality]);

  const handleClose = event => {
    if (selectedOptions == "" || selectedOptions == null) {
      setError(true);
    }
  };

  return (
    <div>
      <FormControl
        variant="outlined"
        color="primary"
        className={classes.selectRoot}
      >
        <Typography align="left" className={classes.label}>
          NATIONALITY
        </Typography>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          className={classes.selectBox}
          disabled={isDisabled}
          value={selectedOptions}
          onChange={handleChange}
          onClose={handleClose}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {country_list.map((country, index) => (
            <MenuItem
              key={index}
              value={country}
            >
              <ListItemText primary={country.country_name} />
            </MenuItem>
          ))}
        </Select>
        {error ? <FormHelperText className={classes.errorText}>*Required</FormHelperText> : (
          <FormHelperText className={classes.errorText}>&nbsp;</FormHelperText>)}
      </FormControl>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    country_list: state.homePageReducer.country_list
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getCountryList: () => dispatch(getCountryList())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Nationality);