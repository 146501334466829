import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import StarRating from "../StarRating/StarRating";
import PeopleAltIcon from "@material-ui/icons/PeopleAltOutlined";
import Avatar from "@material-ui/core/Avatar";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import classNames from "classnames";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  bookingContainer: {
    textAlign: "left",
    padding: "0 1em"
  },
  avatarSize: {
    width: "10rem",
    height: "10rem"
  },
  totalSection: {
    fontWeight: "bold",
    color: "black"
  },
  pricingSection: {
    padding: "1rem",
    borderBottom: "1px solid grey",
    textAlign: "left"
  },
  primaryColor: {
    color: "#1277B8"
  },
  secondryColor: {
    color: "#606060"
  }
}));

export default function PaymentConfirmation({
  username,
  rating,
  typeOfBooking,
  avatar,
  slots,
  lessonDate,
  // cancellationDeadline,
  serviceCharge,
  moreDetailsLink,
  bookingDate
}) {
  const classes = useStyles();

  const getTotalPrice = () => {
    let totalPrice = 0 + serviceCharge;
    slots.forEach(element => {
      totalPrice += element.price * element.hours;
    });
    return totalPrice;
  };

  return (
    <Grid container direction="column" justify="center" alignItems="flex-start">
      <Grid
        container
        item
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item xs={6} className={classes.bookingContainer}>
          <div
            className={classNames(classes.primaryColor, classes.totalSection)}
          >
            <span>{username}</span>
          </div>
          <div>
            <StarRating currentRating={rating} />
          </div>
          <div className={classes.secondryColor}>
            <PeopleAltIcon /> {typeOfBooking}
          </div>
          {/* <div className={classes.secondryColor}>
            <CalendarTodayOutlinedIcon /> {bookingDate.toDateString()}
          </div> */}
        </Grid>

        <Grid item xs={6}>
          <Avatar src={avatar} className={classes.avatarSize} />
        </Grid>
      </Grid>

      <Grid
        container
        item
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        className={classNames(classes.pricingSection, classes.secondryColor)}
      >
        {slots.map(slot => (
          <>
            <Grid item xs={8}>
              {`${slot.lessonDate}: $${slot.price} X ${slot.hours} Hours`}
            </Grid>
            <Grid item xs={4}>
              {`$${slot.price * slot.hours}`}
            </Grid>
          </>
        ))}
        <Grid item xs={8}>
          Service Charge
        </Grid>
        <Grid item xs={4}>
          {`$${serviceCharge}`}
        </Grid>
        <Grid item xs={8} className={classes.totalSection}>
          Total (AUD)
        </Grid>
        <Grid item xs={4} className={classes.totalSection}>
          {`$${getTotalPrice()}`}
        </Grid>
      </Grid>

      <Grid item className={classes.bookingContainer}>
        {/* <p>
          Free cancellation untill {`${cancellationDeadline.toDateString()}`}
        </p> */}
        <p>
          Cancel up to 2 days before session and avail full refund.
          <br />
          <a href={moreDetailsLink}>More Details</a>
        </p>
      </Grid>
    </Grid>
  );
}
