import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toggleInstructorLike } from "../../../redux/actions/instructorDataActions";
import Hidden from "@material-ui/core/Hidden";
import withWidth from "@material-ui/core/withWidth";
import { makeStyles } from "@material-ui/core/styles";
import FavoriteIcon from "@material-ui/icons/Favorite";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Button,
  Typography,
  IconButton
} from "@material-ui/core";

// Todo: should be replaced by img variables
import sampleImg from "../../../materials/HomePage/campfire_img.jpg";

const colorCode = "#1976d2";
const colorWhite = "white";
const colorRed = "Red";

const useStyles = makeStyles(theme => {
  const breaksize = {
    md: 12,
    xs: 11
  };

  return {
    root: {
      maxWidth: 423
    },

    buttonStyle: {
      marginTop: 10,
      marginBottom: 30,
      borderRadius: 50
    },

    buttonLabel: {
      padding: 8,
      color: "white",
      fontWeight: 500
    },

    contained: {
      backgroundColor: colorCode
    },

    colorPrimary: {
      color: colorCode
    },

    bodyTextStyle: {
      marginTop: 0,
      padding: "0px 5px 0px 0px",
      fontSize: breaksize.xs,

      [theme.breakpoints.up("sm")]: {
        marginTop: 10,
        fontSize: 14
      }
    },

    textContext: {
      marginTop: 5,
      padding: "0px",
      [theme.breakpoints.up("sm")]: {
        marginTop: 10,
        padding: "16px 25px 16px 25px"
      }
    },

    media: {
      [theme.breakpoints.up("sm")]: {
        height: 267,
        width: 423
      },
      width: 130,
      height: 124
    },

    grey: {
      color: "grey",
      fontSize: 14,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12
      }
    },

    bold: {
      [theme.breakpoints.up("sm")]: {
        fontSize: 30,
        fontWeight: 500
      },
      fontSize: 14,
      fontWeight: 500
    },

    iconLabel: {
      color: isLiked => (isLiked ? "red" : "grey")
    },

    iconRoot: {
      margin: 10,
      [theme.breakpoints.down("sm")]: {
        margin: 0
      },
      //TODO: change hover style
      backgroundColor: "rgba(128, 128, 128, 0.3)"
    },

    gutterBottom: {
      marginBottom: "0.15em"
    },

    iconstyle: {
      [theme.breakpoints.up("sm")]: {
        fontSize: 16
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 12
      }
    },

    textsizing: {
      fontSize: breaksize.md,
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        fontSize: breaksize.xs
      }
    }
  };
});

const InstructorCard = props => {
  //TODO: varianles from props fake now
  const {
    instructorType,
    sepcialisation,
    pricing,
    instructorName,
    desc,
    isLiked,
    id,
    toggleInstructorLike,
    width,
    theme
  } = props;

  const classes = useStyles(isLiked);

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <Grid container justify="space-around">
          <Grid item sm={12} xs={6}>
            <CardMedia
              className={classes.media}
              image={sampleImg}
              title="Instructor Image"
            />
          </Grid>

          <Grid item container sm={12} xs={6}>
            <CardContent className={classes.textContext}>
              <Grid container alignItems="center">
                <Grid item sm={10} xs={8}>
                  <Typography
                    color="primary"
                    className={classes.bold}
                    classes={{
                      colorPrimary: classes.colorPrimary
                    }}
                  >
                    {instructorName}
                  </Typography>
                </Grid>

                {/*  <Grid item sm={2} xs={4}>
                  <IconButton
                    aria-label="add to favorites"
                    onClick={() => {
                      toggleInstructorLike(id);
                    }}
                    classes={{
                      label: classes.iconLabel,
                      root: classes.iconRoot
                    }}
                  >
                    <FavoriteIcon className={classes.iconstyle} />
                  </IconButton>
                </Grid> */}
              </Grid>

              <Grid item container>
                <Grid item sm={8} xs={12}>
                  <Hidden xsDown>
                    <Typography gutterbottom className={classes.grey}>
                      {instructorType} instructor.
                    </Typography>
                  </Hidden>

                  <Hidden xsDown>
                    <Typography gutterbottom className={classes.grey}>
                      Sepcialisation: {sepcialisation}.
                    </Typography>
                  </Hidden>
                </Grid>

                <Grid
                  item
                  container
                  sm={4}
                  xs={12}
                  alignItems="center"
                  justify="flex-end"
                >
                  <Grid item sm={12} xs={8}>
                    <Typography
                      color="primary"
                      className={classes.textsizing}
                      classes={{
                        colorPrimary: classes.colorPrimary
                      }}
                    >
                      Price From
                    </Typography>
                  </Grid>

                  <Grid item sm={12} xs={4}>
                    <Typography
                      color="primary"
                      classes={{
                        colorPrimary: classes.colorPrimary
                      }}
                      className={classes.bold}
                    >
                      ${pricing}/hr
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sm={12} xs={12}>
                <Typography className={classes.bodyTextStyle} gutterBottom>
                  {desc}
                </Typography>
              </Grid>
            </CardContent>
          </Grid>
        </Grid>
      </CardActionArea>
      <Grid container direction="row" justify="center" alignItems="center">
        <Button
          // variant="containedPrimary"
          variant="contained"
          size="large"
          className={classes.buttonStyle}
          classes={{
            contained: classes.contained,
            label: classes.buttonLabel
          }}
        >
          View Full Profile
        </Button>
      </Grid>
    </Card>
  );
};

InstructorCard.propTypes = {
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    toggleInstructorLike: id => dispatch(toggleInstructorLike(id))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withWidth()(InstructorCard));
