import React from "react";

//Redux
import { Field } from "redux-form";

// Material UI
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { TextField, NoSsr } from "@material-ui/core";
import { styled, createMuiTheme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";

// Tree Dropdown
const theme = createMuiTheme();

const FieldSpacing = {
  width: "85%",
  marginLeft: "7.5%"
};

const TextFieldCustomLogin = styled(TextField)({
  width: "100%",
  fontFamily: "PT Sans Narrow",
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px"
  },
  "& .MuiOutlinedInput-input": {
    padding: "0.5rem 0.5rem",
    fontFamily: "PT Sans Narrow"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "black  ",
    borderWidth: "1px  "
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "black  ",
    borderWidth: "1px  "
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "black  ",
    borderWidth: "1px  "
  },
  "& ::placeholder": {
    color: "#1277B8"
  }
});

const TextFieldCustom = styled(TextField)({
  ...FieldSpacing,
  fontFamily: "PT Sans Narrow",
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px"
  },
  "& .MuiOutlinedInput-input": {
    padding: "0.5rem 0.5rem",
    fontFamily: "PT Sans Narrow"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "black  ",
    borderWidth: "1px  "
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "black  ",
    borderWidth: "1px  "
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "black  ",
    borderWidth: "1px  "
  },
  "& ::placeholder": {
    color: "#1277B8"
  }
});

const TextFieldZoomCustom = styled(TextField)({
  fontFamily: "PT Sans Narrow",
  width: "80%",
  padding: "0em",
  backgroundColor: "rgba(18, 119, 184, 0.15)",
  borderRadius: "0",

  "& .MuiOutlinedInput-notchedOutline": {
    borderWidth: "0"
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderWidth: "0"
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderWidth: "0"
  }
});

const TextFieldCustomNumber = styled(TextField)({
  fontFamily: "PT Sans Narrow",
  padding: "0em",
  width: "35",
  height: "37",
  backgroundColor: "#FFFFFF",
  borderRadius: "0",

  "& .MuiOutlinedInput-input": {
    padding: "0",
    height: "2em",
    fontSize: "18px",
    textAlign: "center"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderWidth: "0"
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderWidth: "0"
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderWidth: "0"
  }
});

const TextFieldAreaCustom = styled(TextField)({
  border: "1px solid #000000",
  boxSizing: "border-box",
  borderRadius: "10px",
  width: "100%",

  "& .MuiOutlinedInput-inputMultiline": {
    maxWidth: "520px"
  },
  ".MuiOutlinedInput-root": {
    margin: "0"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderWidth: "0"
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderWidth: "0"
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderWidth: "0"
  },
  "& .MuiInputBase-input": {
    fontFamily: "PT Sans Narrow",
    fontSize: "16px"
  }
});

const MobileTextFieldCustom = styled(TextField)({
  fontFamily: "PT Sans Narrow",
  padding: "0em",
  borderRadius: "0px",
  "& .MuiOutlinedInput-root ": {
    borderTopLeftRadius: "0px",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    borderBottomLeftRadius: "0px"
  },
  "& .MuiOutlinedInput-input": {
    padding: "0.5rem 0.5rem"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
    borderWidth: "1px 1px 1px 0px"
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "black  ",
    borderWidth: "1px 1px 1px 0px"
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "black  ",
    borderWidth: "1px  "
  }
});

const CheckboxCustom = styled(Checkbox)({
  minWidth: "0%"
});

export const LabelCustom = styled(Typography)({
  fontFamily: "PT Sans Narrow",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "18px",
  //marginTop added by mamta as
  //marginTop: "10px",
  marginBottom: "0.5em",
  textTransform: "uppercase",
  color: "#000000"
});

const LabelError = styled(Typography)({
  color: "#ff0033",
  fontSize: "0.75rem"
});

const LabelCustomCheckbox = styled(Typography)({
  fontSize: "14px",
  fontFamily: "PT Sans Narrow",
  fontStyle: "normal",
  marginBottom: "0.5em",
  color: "#1277B8",
  display: "inline"
});

const SelectCustom = styled(Select)({
  fontColor: "1277B8",
  marginLeft: "7.5%",
  width: "85%",
  fontFamily: "PT Sans Narrow",
  borderRadius: "10px",
  "& .MuiOutlinedInput-input": {
    padding: "0.5rem 0.5rem",
    height: "0px"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "black  ",
    borderWidth: "1px  "
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "black  ",
    borderWidth: "1px  "
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "black  ",
    borderWidth: "1px  "
  },
  "& .MuiListItemText-root": {
    marginTop: "0px"
  },
  "& .MuiTypography-body1": {
    lineHeight: "inherit"
  }
});

const MobileSelectCustom = styled(Select)({
  fontFamily: "PT Sans Narrow",
  borderRadius: "0px",
  borderTopLeftRadius: "10px",
  borderBottomLeftRadius: "10px",
  width: "6rem",
  [theme.breakpoints.down("sm")]: {
    width: "4.3rem"
  },

  "& .MuiOutlinedInput-root ": {
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    borderBottomLeftRadius: "10px"
  },
  "& .MuiOutlinedInput-input": {
    padding: "0.5rem 0.5rem"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
    borderRightColor: "grey",
    borderWidth: "1px 1px 1px 1px"
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
    borderWidth: "1px 1px 1px 1px"
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
    borderWidth: "1px 1px 1px 1px"
  },
  "& .MuiListItemText-root": {
    height: "0px",
    margin: "0px 0px",
    lineHeight: "normal"
  },
  "& .MuiTypography-body1": {
    lineHeight: "inherit"
  }
});

export const renderNumberField = ({
  input,
  label,
  type,
  renderError,
  index,
  meta: { touched, error }
}) => {
  index = index ? index : 0;
  if (touched && error && renderError) {
    renderError(error, index);
  } else if (index === 0) {
    renderError("", index);
  } else {
    console.log("");
  }
  return (
    <div>
      <NoSsr>
        <TextFieldCustomNumber
          {...input}
          type={type}
          variant="outlined"
          autoComplete={label}
          inputProps={{ maxLength: 3 }}
        />
      </NoSsr>
    </div>
  );
};

export const renderFieldForLogin = ({
  input,
  label,
  placeholder,
  secondaryPlaceholder,
  type,
  meta: { touched, error }
}) => {
  return (
    <div>
      <NoSsr>
        <LabelCustom style={{}}> {label} </LabelCustom>
        <TextFieldCustomLogin
          {...input}
          placeholder={placeholder}
          type={type}
          variant="outlined"
        />
        {/* <p>{secondaryPlaceholder}</p> */}
        {touched && error && <LabelError style={{}}> {error} </LabelError>}
      </NoSsr>
    </div>
  );
};

// Input field
export const renderField = ({
  input,
  label,
  placeholder,
  secondaryPlaceholder,
  type,
  meta: { touched, error }
}) => {
  return (
    <div>
      <NoSsr>
        <LabelCustom style={{ marginLeft: "7.5%" }}> {label} </LabelCustom>
        <TextFieldCustom
          {...input}
          placeholder={placeholder}
          type={type}
          variant="outlined"
          style={{
            fontFamily: "PT Sans Narrow"
          }}
        />
        {/* <p>{secondaryPlaceholder}</p> */}
        {touched && error && (
          <LabelError style={{ marginLeft: "7.5%" }}> {error} </LabelError>
        )}
      </NoSsr>
    </div>
  );
};

// Zoom field
export const renderZoomField = ({
  input,
  label,
  placeholder,
  invLink,
  secondaryPlaceholder,
  type,
  meta: { touched, error }
}) => {
  return (
    <div>
      <NoSsr>
        <LabelCustom> {label} </LabelCustom>
        <TextFieldZoomCustom
          {...input}
          value={invLink}
          placeholder={placeholder}
          type={type}
          variant="outlined"
          autoComplete={label}
          // autoFocus={autoFocus}
        />
        {/* <p>{secondaryPlaceholder}</p> */}
        {touched && error && <LabelError> {error} </LabelError>}
      </NoSsr>
    </div>
  );
};

// Select field
export const renderSelectField = props => {
  const {
    input,
    options,
    label,
    placeholder,
    type,
    spacing,
    value,
    marginL,
    onChange,
    // onChange,
    meta: { touched, error },
    // added by Shubin
    disabled,
    native,
    renderValue
  } = props;
  console.log(props);
  // const [option, setOption] = useState({id: 67, country_name: 'Algeria'});

  return (
    <div>
      <NoSsr>
        <LabelCustom style={{ marginLeft: "7.5%" }}> {label} </LabelCustom>
        <SelectCustom
          variant="outlined"
          {...input}
          placeholder={placeholder || label}
          type={type}
          name={input.name}
          value={value}
          onChange={onChange}
          //added by Shubin
          disabled={disabled}
          native={native}
          renderValue={renderValue}
        >
          {options.map(option => (
            <MenuItem key={option.id || option} value={option || option.value}>
              <ListItemText
                primary={option.value || option.country_name || option}
              />
            </MenuItem>
          ))}
        </SelectCustom>
        {touched && error && (
          <LabelError style={{ marginLeft: marginL }}> {error} </LabelError>
        )}
      </NoSsr>
    </div>
  );
};

// Select field
// TODO doesnt work with field
export const RenderSelectFieldWithHandleChange = props => {
  const {
    name,
    input,
    options,
    label,
    placeholder,
    type,
    spacing,
    marginL,
    meta: { touched, error },
    handleChange
  } = props;
  const [value, setValue] = React.useState("");

  let realHandleChange = handleChange;
  if (!handleChange) {
    realHandleChange = event => {
      setValue(event.target.value);
    };
  } else {
    realHandleChange = event => {
      setValue(event.target.value);
      handleChange(event);
    };
  }

  return (
    <div>
      <NoSsr>
        <LabelCustom style={{ marginLeft: marginL }}> {label} </LabelCustom>
        <FormControl variant="outlined" style={{ ...spacing }} name={name}>
          <SelectCustom
            variant="outlined"
            type={type}
            onChange={realHandleChange}
            value={value}
            displayEmpty
          >
            {placeholder && (
              <MenuItem key={placeholder} value={""} disabled>
                {placeholder}
              </MenuItem>
            )}
            {options &&
              options.map(option => (
                <MenuItem key={option} value={option}>
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
          </SelectCustom>
        </FormControl>
        {touched && error && (
          <LabelError style={{ marginLeft: marginL }}> {error} </LabelError>
        )}
      </NoSsr>
    </div>
  );
};

export const renderMobileTextField = ({
  input,
  label,
  placeholder,
  secondaryPlaceholder,
  type,
  meta: { touched, error }
}) => {
  return (
    <div style={{ width: "100%" }}>
      <NoSsr>
        <MobileTextFieldCustom
          {...input}
          type={type}
          variant="outlined"
          autoComplete={label}
          style={{ width: "100%" }}
          inputProps={{ maxLength: 20 }}
        />
        <p>{secondaryPlaceholder}</p>
        {touched && error && <LabelError> {error} </LabelError>}
      </NoSsr>
    </div>
  );
};

export const renderMobileSelectField = props => {
  const {
    input,
    options,
    label,
    placeholder,
    type,
    meta: { touched, error }
  } = props;
  console.log(error);
  return (
    <div>
      <NoSsr>
        <MobileSelectCustom
          variant="outlined"
          {...input}
          placeholder={placeholder || label}
          type={type}
          name={placeholder}
        >
          {options.map(option => (
            <MenuItem key={option} value={option}>
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </MobileSelectCustom>
        {/* </FormControl> */}
        {touched && error && <LabelError> {error} </LabelError>}
      </NoSsr>
    </div>
  );
};

export const renderCheckboxField = ({
  input,
  label,
  handleChange,
  value,
  meta: { touched, error }
}) => {
  return (
    <div>
      <NoSsr>
        <CheckboxCustom
          color="primary"
          {...input}
          checked={value}
          onChange={handleChange}
        />
        <LabelCustomCheckbox> {label} </LabelCustomCheckbox>
        {touched && error && <LabelError> {error} </LabelError>}
      </NoSsr>
    </div>
  );
};

// File upload field
export const renderFileUploadField = props => {
  const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);
  const {
    input: { onChange, onBlur },
    meta: { touched, error }
  } = props;

  return (
    <div>
      <input
        onChange={adaptFileEventToValue(onChange)}
        onBlur={adaptFileEventToValue(onBlur)}
        type="file"
      />
      {touched && error && <LabelError> {error} </LabelError>}
    </div>
  );
};

// Textarea field
export const renderTextAreaField = ({
  input,
  label,
  placeholder,
  type,
  value,
  disabled,
  meta: { touched, error }
}) => {
  return (
    <div>
      <div>
        <TextFieldAreaCustom
          {...input}
          // value={input.value || defaultValue}
          placeholder={placeholder}
          type={type}
          multiline
          rows={6}
          variant="outlined"
          value={value}
          disabled={disabled}
        />
        {touched && error && <LabelError> {error} </LabelError>}
      </div>
    </div>
  );
};

export const renderCheckbox = ({
  checked,
  handleChange,
  label,
  sublabel,
  meta: { touched, error }
}) => {
  return (
    <div>
      <LabelCustom> {label} </LabelCustom>
      {sublabel}
      <Checkbox
        checked={checked}
        onChange={handleChange}
        color="primary"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
    </div>
  );
};

export const OptionalForm = ({ input, label, show }) => {
  return (
    <div>
      {true ? (
        <div>
          <FormLabel component="legend">
            Please provide further details about the food allergy
          </FormLabel>
          <Field
            name="allergyform"
            component={renderField}
            type="textfield"
            placeholder="Please write about any allergies you may have"
          />
        </div>
      ) : null}
    </div>
  );
};

// Textarea field
export const renderYesOrNo = ({
  input,
  title,
  check,
  show,
  hide,
  meta: { touched, error }
}) => {
  return (
    <div>
      <div>
        <FormControl component="fieldset" {...input}>
          <LabelCustom> {title} </LabelCustom>
          <RadioGroup
            row
            aria-label="position"
            name="position"
            defaultValue="top"
          >
            <FormControlLabel
              checked={input.value !== "yes"}
              value="No"
              control={<Radio color="primary" />}
              label="No"
              labelPlacement="start"
              onChange={hide}
            />
            <FormControlLabel
              checked={input.value === "yes"}
              value="Yes"
              control={<Radio color="primary" />}
              label="Yes"
              labelPlacement="start"
              onChange={show}
            />
          </RadioGroup>
        </FormControl>
        {touched && error && <LabelError> {error} </LabelError>}
      </div>
    </div>
  );
};

const CustomSlider = withStyles({
  root: {
    minWidth: "400%",
    color: "#52af77",
    height: 0
  },
  thumb: {
    height: 24,
    width: 1,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit"
    }
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)"
  },

  rail: {
    height: 8,
    borderRadius: 4
  }
})(Slider);

// Slider
export const skillRangeInput = props => {
  const {
    min,
    max,
    label,
    handleChange,
    level,
    meta: { touched, error }
  } = props;
  return (
    <div>
      <div>
        <div>
          <label>{label}</label>
        </div>
        <div>
          <CustomSlider
            value={level}
            onChange={handleChange}
            valueLabelDisplay="auto"
            aria-label="ski slider"
            defaultValue={1}
            min={min}
            max={max}
            track={false}
            step={1}
            classes={{ rail: "color-slider-bar" }}
          />
        </div>
      </div>
      {touched && error && <LabelError> {error} </LabelError>}
    </div>
  );
};

// MultiSelect - Helped with Material UI
export const multiSelect = props => {
  const emptyArray = [];
  const { label, placeholder, options, data, input } = props;

  return (
    <div>
      <div>
        <label>{label}</label>
      </div>
      <Select
        {...input}
        multiple
        label={label}
        value={emptyArray}
        input={<Input />}
        placeholder={placeholder}
        onChange={input.onChange}
        renderValue={selected => selected.join(", ")}
      >
        {options.map(option => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={data.indexOf(option) > -1} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
