import styled from "styled-components";
import { GrayColor2 } from "./color-themes_old";

export const DateDisplay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  width: 100%;
  height: 37px;
  border: 1px solid ${GrayColor2};
  border-radius: 4px;
`;
