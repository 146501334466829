import React from "react";

export default class DatePickerButton extends React.Component {
  render() {
    return (
      <button className=" custom-select" onClick={this.props.onClick}>
        {this.props.value || this.props.placeholderText}
      </button>
    );
  }
}
