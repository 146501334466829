// TODO: Uncomment and Check the entire file for issues once the frontend server is started. Commenting this now!

import React, { useState } from "react";
import { connect } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { sendHostName } from "../../../redux/actions/exploreHostsActions";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import InstructorCard from "../../../components/virtualLessons/InstructorCard/InstructorCard.js";
import Header from "../../../components/Common/Home/HeaderSection.js";
import SignUpSection from "../../../components/exploreHosts/home/SignUpSection.jsx";
import HostCard from "../../../components/exploreHosts/HostCard/HostCard.js";
// import SavedInstructors from "../ExploreHosts/SavedHosts";
import CardPagination from "../../../components/Common/CardPagination";
import ContentFilter from "../../../components/exploreHosts/home/ContentFilter";
import BackgroundImg from "../../../materials/HomePage/background_landing.png";

import SearchCardForExploreInstructors from "../../../components/virtualLessons/home/SearchCardForExploreInstructors";
import {
  NAV_BAR_HEIGHT,
  TAB_BAR_HEIGHT
} from "../../../styling/styled-components/dimension-themes";
import { Helmet } from "react-helmet";
import SEO from "../../Common/seo/SEO";

const BACKGROUND_SIZE = "60vh";

const useStyles = makeStyles(theme => ({
  cards: {
    paddingBottom: "4em"
  },

  searchCardContainer: {
    height: "inherit",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      "& .MuiCard-root": {
        margin: "4% 0%"
      }
    }
  },

  background: {
    minHeight: BACKGROUND_SIZE,
    backgroundImage: `url(${BackgroundImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100vh",
    width: "100%",
    backgroundPosition: "center"
  },
  backgroundLayer: {
    height: "100vh",
    width: "100%",
    backgroundPosition: "center",
    background: "rgba(0,0,0,0.4)"
  },

  heading: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "29px",
    lineHeight: "45px",

    display: "flex",
    alignItems: "center",
    letterSpacing: "0.01em",

    color: "#1277B8"
  },

  hideSearchCard: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      "& .MuiCard-root": {
        margin: "4% 0%"
      }
    }
  }
}));

const ExploreHosts = props => {
  const hosts = props.hosts;
  console.log(hosts);

  const classes = useStyles();

  // for future use
  /*  //build criteria
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [selectedTravellers, setSelectedTravellers] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState("");
  const [selectedSuitability, setSelectedSuitability] = useState(-1);
  const [selectedInterest, setSelectedInterest] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedResort, setSelectedResorts] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedActivityType, setSelectedActivityType] = useState("");
  const [selectedAbilityLevel, setSelectedAbilityLevel] = useState(-1);
  const [selectedCriteria, setSelectedCriteria] = useState([]);
  const [selectedPricing, setSelectedPricing] = useState("");

  const [Hosts, setHosts] = useState([]);
  const [search, setSearch] = useState(""); */

  // for future use
  /*   const API_PATH = "../api/";
  let responseData;

  useEffect(() => {
    //getting instructor profiles from database
    axios
      .get(API_PATH + "/host")
      .then(response => {
        responseData = response.data;
        responseData.map(item => {
          item.name = item.FirstName + " " + item.LastName;

          console.log(item.name);
        });
        console.log("with name", responseData);
        //props.getName(responseData);
        setHosts(responseData);
      })
      .catch(error => {
        console.log(error);
      });
  }, [props.name, props.getName]); */

  const slogan = "Browse and book experienced hosts local to the resort";
  const underSlogan =
    "...just for YOURSELF, your FAMILY, the KIDS, or for a group of FRIENDS";
  const lookingFor = false;

  // for future use
  /*   const containsAll = (arrA, arrB) => {
    const lowerA = arrA.map(
      Function.prototype.call,
      String.prototype.toLowerCase
    );

    return true;
  }; */

  // for future use
  /*  const filteredCards = hosts.filter(host => {
    return (
      host.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 
      /* containsAll(host.criteria, selectedCriteria) &&
      instructor.pricing
        .toLowerCase()
        .indexOf(selectedPricing.toLowerCase()) !== -1 &&
      containsAll(instructor.dates, selectedDates) &&
      (instructor.abilities.indexOf(selectedAbilityLevel) !== -1 ||
        selectedAbilityLevel === -1) &&
      (instructor.activities.indexOf(selectedActivityType) !== -1 ||
        selectedActivityType === "") &&
      containsAll(instructor.resorts, selectedResort) */
  /*     );
  });  */

  const [favHosts, setFavHosts] = React.useState([]);

  return (
    <div key={favHosts.length}>
      <SEO
        title="HomePage"
        description="Home Page for user to search resort on resorter"
      />
      <Grid container justify="center" alignItems="center">
        <div className={classes.background}>
          <div className={classes.backgroundLayer}>
            {/* header */}
            <Grid
              container
              item
              // sm={12}
              xs={12}
              className={classes.searchCardContainer}
            >
              <Header
                slogan={slogan}
                underSlogan={underSlogan}
                lookingFor={lookingFor}
                center={true}
                searchCardBlue={true}
              />
            </Grid>

            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.hideSearchCard}
            >
              <SearchCardForExploreInstructors />
            </Grid>
          </div>
        </div>

        {/* filter */}
        {/* <Grid
          container
          justify="center"
          alignItems="center"
          item
          spacing={4}
          sm={12}
          xs={12}
          className={classes.filter}
        >
          <ContentFilter
          // handleLanguage={setSelectedLanguage}
          // handleTravellers={setSelectedTravellers}
          // handleSuitableFor={setSelectedSuitability}
          // handleActivities={setSelectedActivity}
          // handleInterestedIn={setSelectedInterest}
          />
        </Grid>

        <SignUpSection /> */}
      </Grid>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    getName: name => dispatch(sendHostName(name))
  };
}

function mapStateToProps(state) {
  return {
    hosts: state.exploreHostsReducer.fakeHostData
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExploreHosts);
