import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import InstructorProfileTile from "../../virtualLessons/Instructors-redundant/InstructorProfileTile.js";
import SectionHeading from "./SectionHeading";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  link: {
    color: "#4a443f",
    marginTop: "20px",
    "&:hover": {
      color: "#736b65",
      textDecoration: "none",
      cursor: "pointer"
    }
  }
}));

const ReusableSection = props => {
  const classes = useStyles();

  return (
    <div>
      <Grid container>
        <Grid item>
          <SectionHeading children={props.heading} />
        </Grid>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          flexWrap="nowrap"
          style={{ width: "100%", overflow: "auto" }}
        >
          {props.data.map(data => (
            <Box m={2} key={data.id}>
              <InstructorProfileTile {...data} />
            </Box>
          ))}
        </Box>

        <Grid container item justify="flex-end">
          {props.type == "instructor" ? (
            <a href="/vl/explore" className={classes.link}>
              {props.more}
              <i
                className="fas fa-arrow-right"
                style={{ marginLeft: "10px" }}
              />
            </a>
          ) : (
            <a href="/host/explore" className={classes.link}>
              {props.more}
              <i
                className="fas fa-arrow-right"
                style={{ marginLeft: "10px" }}
              />
            </a>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ReusableSection;
