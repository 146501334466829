import React, { useState } from "react";
import useStyles from "../ServicesStyles";
import { Grid, CardContent } from "@material-ui/core";
import CollapsibleCheckBox from "../../../../CollapsibleCheckBox";
import { PricePerHour, TextContent } from "../InputFields";
import { SERVICES_NAMES } from "../../../../../../constants/common";
import { useEffect } from "react";

const FirstTimerAssistance = props => {
  const { title, services, selectService, addFirstTimerAssistance, isDisabled, setFirstTimerAssistance } = props;
  const classes = useStyles();
  const [inputValue, setValue] = useState(0);
  const [checkboxSelected, setCheckboxSelected] = useState(false);

  const onCheckboxSelected = event => {
    setCheckboxSelected(event);
    selectService(event, 1);
  };

  // If this service is provided by a provider, displaying the service detail.
  useEffect(() => {
    if (services.length > 0 && services !== undefined) {
      services.forEach(service => {
        if (service.service_id === 1){
          setFirstTimerAssistance(service);
          setValue(service.price_per_hour);
          setCheckboxSelected(service.is_provided);
          selectService(service.is_provided, 1);
        }
      });
    }
  }, [services]);

  const inputFields = () => {
    return (
      <div>
        <p className={classes.subtext}>{TextContent()}</p>
        <CardContent>
          <Grid
            container
            justify="center"
            alignItems="center"
            xs={8}
            md={8}
            lg={8}
          >
            <PricePerHour onTextChange={addFirstTimerAssistance} defaultPrice={inputValue} isDisabled={isDisabled}/>
          </Grid>
        </CardContent>
      </div>
    );
  };

  const children = [inputFields];

  return (
    <CollapsibleCheckBox
      name={"FirstTimerAssistance"}
      text={SERVICES_NAMES.FIRST_TIMER}
      parent={null}
      children={children}
      nestingLevel={0}
      onCheckboxSelected={onCheckboxSelected}
      isDisabled={isDisabled}
      isSelected={checkboxSelected}
    />
  );
};

export default FirstTimerAssistance;
