import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

// Database
import agent from "../../../agent";

// Redux
import { getProviderProfile } from "../../../redux/actions/stepperActions";
import { getClientProfile } from "../../../redux/actions/stepperActions";

// Components
// ---Editable Mode
import MyInfoForm from "../../../components/serviceMarket/profile/MyInfoForm";
import MyInfoView from "../../../components/serviceMarket/profile/MyInfoView";

const useStyles = makeStyles(theme => ({
  contentContainer: {
    paddingTop: "1em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    marginTop: "1rem"
  },
  main: {
    paddingBottom: "5em"
  },
  titleStyle: {
    marginTop: 190,
    paddingLeft: "5em",
    paddingRight: "5em",
    fontFamily: "PT Sans Narrow",
    color: "#000000",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "140.62%",
    paddingTop: "1em",
    paddingBottom: "1em",
    fontSize: "26px",
    [theme.breakpoints.up("xs")]: {
      marginTop: 140,
      paddingLeft: "0em",
      paddingRight: "0em"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "190px"
    }
  },
  typograhyFormat: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontSize: 16,
    lineHeight: "22.50px",
    textAlign: "left",
    color: "#1277B8",
    marginTop: "2rem",
    marginBottom: "2rem",
    marginLeft: 50,
    fontWeight: "bolder",
    width: "100%"
  },
  arrowIcon: {
    transform: "rotate(180deg)"
  },
  buttonRoot: {
    backgroundColor: "#FF6161"
  },
  buttonLabel: {
    color: "white",
    fontWeight: 700,
    letterSpacing: "1%",
    fontFamily: "PT Sans"
  },
  nextButton: {
    width: "102px",
    height: "2.5em",
    borderRadius: 100,
    textAlign: "center"
  },
  backButton: {
    width: "60%",
    marginRight: "1em",
    fontFamily: "PT Sans",
    border: "white"
  },
  backButtonLabel: {
    color: "#FF6161",
    fontWeight: 700,
    letterSpacing: "1%",
    fontFamily: "PT Sans"
  },
  headerLabel: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "26px",
    color: "#000000"
  }
}));

const Profile = props => {
  const classes = useStyles();
  //const { client_id } = useParams();
  const client_id = 2;
  const { userProfile } = props;
//   console.log(userProfile);

  const [viewMode, setViewMode] = useState("provider"); // client or provider

  // Check View Mode
  useEffect(() => {
    if (client_id != undefined) {
      if (viewMode == "client") {
        props.getClientProfile(client_id);
      } else {
        props.getProviderProfile(client_id);
      }
    }
  }, [client_id]);

  return (
    <div className={classes.main}>
      <MyInfoView />
    </div>
  );
};

// Reducer part
function mapStateToProps(state, ownProps) {
  return {
    userProfile: state.stepperReducer.userProfile
  };
}

// Adding Dispatch to update the existing values in Redux
const mapDispatchToProps = dispatch => {
  return {
    getClientProfile: client_id => {
      dispatch(getClientProfile(client_id));
    },
    getProviderProfile: client_id => {
      dispatch(getProviderProfile(client_id));
    }
  };
};

// Connect part
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);