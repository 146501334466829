//Modified from Filter/ContentFilter.js
// Author: Brody Taylor
// Should probably be redesigned in a way that its reuseable
// (and uses a reuseable dropdown)
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Typography } from "@material-ui/core";
import ListSubheader from "@material-ui/core/ListSubheader";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { sendHostName } from "../../../redux/actions/exploreHostsActions.js";

const useStyles = makeStyles({
  main: {
    textAlign: "center",
    marginTop: "2em"
  },
  filter: {
    marginBottom: "2em",
    marginRight: "2em",
    marginTop: "2em"
  },
  prompt: {
    fontWeight: "bold",
    fontStyle: "normal",
    color: "#505050",
    display: "inline-block"
  },
  bodytext: {
    fontWeight: "bold",
    fontStyle: "normal",
    color: "#505050",
    display: "inline-block",
    color: "rgb(255,97,97)",
    marginTop: "0.5em",
    marginRight: "1em",
    textAlign: "right"
  },
  select1: {
    minWidth: "8em",
    maxWidth: "8em",
    marginRight: "1.5em"
  },
  select2: {
    minWidth: "12em",
    maxWidth: "12em",
    marginRight: "1.5em"
  },
  select3: {
    minWidth: "9em",
    maxWidth: "9em",
    marginRight: "1.5em"
  },
  select4: {
    minWidth: "11em",
    maxWidth: "11em",
    marginRight: "1.5em"
  },
  select5: {
    minWidth: "8em",
    maxWidth: "8em"
  },
  label: {
    color: "rgb(255,97,97)"
  },
  inputSelect: {
    marginTop: "0.2em",
    textAlign: "center",
    flexWrap: "wrap",
    display: "flex"
  },
  selectMenu: {
    height: "0.8em"
  },
  selectRoot: {
    textAlign: "left",
    color: "rgb(255,97,97)"
  },
  selectIcon: {
    color: "rgb(255,97,97)"
  },
  search: {
    width: "17em"
  },
  searchRoot: {
    borderRadius: 6,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgb(255,97,97)",
        borderWidth: 1,
        borderRadius: 6
      },
      "&:hover fieldset": {
        borderColor: "rgb(255,97,97)",
        borderWidth: 1,
        borderRadius: 6
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgb(255,97,97)",
        borderWidth: 1,
        borderRadius: 6
      }
    }
  },
  searchInput: {
    color: "rgb(255,97,97)",
    height: "3em",
    marginTop: "0.3em",
    textAlign: "center",
    flexWrap: "wrap",
    display: "flex"
  },
  searchLabel: {
    color: "rgb(255,97,97)"
  },
  listRoot: {
    whiteSpace: "normal",
    width: "18em",
    height: "2.1em"
  },
  subHeaderRoot: {
    color: "#1277B8",
    lineHeight: 1.3,
    paddingLeft: "2em",
    fontWeight: 400
  },
  subHeaderRoot2: {
    color: "#1277B8",
    lineHeight: 1.3,
    paddingLeft: "2.8em",
    fontWeight: 400,
    fontSize: 10,
    whiteSpace: "normal"
  },
  primary: {
    textAlign: "left",
    fontSize: 14,
    color: "#505050",
    fontWeight: 300
  },
  primaryOfSuitable: {
    textAlign: "left"
  }
});

const theme = createMuiTheme({
  overrides: {
    MuiMenu: {
      list: {
        maxHeight: "40em"
      }
    },
    MuiIconButton: {
      label: {
        justifyContent: "flex-start"
      }
    },
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "rgb(255,97,97)",
          borderWidth: 1
        },
        "&$focused $notchedOutline": {
          borderColor: "rgb(255,97,97)",
          borderWidth: 1
        },
        "&:hover $notchedOutline": {
          borderColor: "rgb(255,97,97)",
          borderWidth: 1
        }
      },
      notchedOutline: {}
    }
  },
  palette: {
    primary: {
      main: "#FFFFFF"
    },
    secondary: {
      main: "#FF6161"
    }
  }
});

const ContentFilter = props => {
  const classes = useStyles();

  const [search, setSearch] = useState("");

  const [selectedResort, setSelectedResorts] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedActivityType, setSelectedActivityType] = useState([]);
  const [selectedAbilityLevel, setSelectedAbilityLevel] = useState([]);
  const [selectedCriteria, setSelectedCriteria] = useState([]);
  const [selectedPricing, setSelectedPricing] = useState([]);

  const resorts = ["Test Resort 1", "Test Resort 2", "Test Resort 3"];
  const dates = [];
  const activityTypes = ["Ski", "Snowboard"];
  const abilityLevels = [1, 2, 3, 4, 5, 6, 7];
  //subcatergories
  const criteria = ["Required Specialties", "Languages", "Nationality"];
  const specialties = [
    "Teenagers",
    "3-5 Year Olds",
    "Kids",
    "Moguls",
    "Terrain Park",
    "Race",
    "Telemark",
    "Ski/Splitboard Touring"
  ];
  const languages = ["French", "German", "Russian", "Spanish", "Mandarin"];
  const nationalities = [
    "Austrian",
    "British",
    "Australian",
    "Japanese",
    "New Zealander"
  ];

  const pricing = ["High to low", "Low to high"];

  const handleResorts = event => {
    //remove undefined or null values
    if (!event.target.value) return;
    const value = event.target.value.filter(x => x);

    props.handleResorts(value);
    setSelectedResorts(value);
  };

  const handleDates = event => {
    //remove undefined or null values
    if (!event.target.value) return;
    const value = event.target.value.filter(x => x);

    props.handleDates(value);
    setSelectedDates(value);
  };

  const handleActivities = event => {
    console.log(event.target.value);
    let value = event.target.value;
    if (!event.target.value) {
      value = "";
    }

    props.handleActivities(value);
    setSelectedActivityType(event.target.value);
  };

  const handleAbilities = event => {
    let value = event.target.value;
    if (!event.target.value) {
      value = -1;
    }

    props.handleAbilities(value);
    setSelectedAbilityLevel(event.target.value);
  };

  const handleCriteria = event => {
    //remove undefined or null values
    if (!event.target.value) return;
    const value = event.target.value.filter(x => x);

    props.handleCriteria(value);
    setSelectedCriteria(value);
  };

  const handleSearch = event => {
    props.sendHostName(event.target.value);
    setSearch(event.target.value);
  };

  const handlePricing = event => {
    props.handlePricing(event.target.value);
    setSelectedPricing(event.target.value);
  };

  return (
    <div className={classes.main}>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.filter}
        direction="row"
      >
        <ThemeProvider theme={theme}>
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            item
            sm={12}
            xs={12}
          >
            <Grid
              container
              item
              justify="center"
              alignItems="center"
              md={1}
              sm={12}
              xs={12}
            >
              <Typography variant="body1" className={classes.prompt}>
                Filter:
              </Typography>
            </Grid>

            <FormControl
              variant="outlined"
              color="secondary"
              className={classes.select1}
            >
              <InputLabel className={classes.label}>Resorts</InputLabel>
              <Select
                multiple
                value={selectedResort}
                onChange={handleResorts}
                className={classes.inputSelect}
                renderValue={selected => selected.join(", ")}
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot,
                  icon: classes.selectIcon,
                  selected: classes.selected
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              >
                {resorts.map(rest => (
                  <MenuItem key={rest} value={rest}>
                    <Checkbox checked={selectedResort.indexOf(rest) > -1} />
                    <ListItemText primary={rest} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              color="secondary"
              className={classes.select2}
            >
              <InputLabel className={classes.label}>Dates</InputLabel>
              <Select
                value={selectedDates}
                onClick={handleDates}
                className={classes.inputSelect}
                renderValue={selected => selected}
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot,
                  icon: classes.selectIcon
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              >
                {dates.map(date => (
                  <MenuItem key={date} value={date}>
                    <ListItemText primary={date} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              color="secondary"
              className={classes.select3}
            >
              <InputLabel className={classes.label}>Activity</InputLabel>
              <Select
                value={selectedActivityType}
                onClick={handleActivities}
                className={classes.inputSelect}
                renderValue={selected => selected}
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot,
                  icon: classes.selectIcon
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              >
                {activityTypes.map(activity => (
                  <MenuItem key={activity} value={activity}>
                    <ListItemText primary={activity} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              color="secondary"
              className={classes.select2}
            >
              <InputLabel className={classes.label}>Ability</InputLabel>
              <Select
                value={selectedAbilityLevel}
                onClick={handleAbilities}
                className={classes.inputSelect}
                renderValue={selected => selected}
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot,
                  icon: classes.selectIcon
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              >
                {abilityLevels.map(ability => (
                  <MenuItem key={ability} value={ability}>
                    <ListItemText primary={ability} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              color="secondary"
              className={classes.select4}
            >
              <InputLabel className={classes.label}>Criteria</InputLabel>
              <Select
                multiple
                value={selectedCriteria}
                onChange={handleCriteria}
                renderValue={selected => selected.join(", ")}
                className={classes.inputSelect}
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot,
                  icon: classes.selectIcon
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  root: {
                    maxHeight: "30em"
                  },
                  getContentAnchorEl: null
                }}
              >
                <ListSubheader
                  classes={{ root: classes.subHeaderRoot }}
                  disableSticky
                >
                  Specialties
                </ListSubheader>
                {specialties.map(specialty => (
                  <MenuItem
                    key={specialty}
                    value={specialty}
                    classes={{ root: classes.listRoot }}
                  >
                    <Checkbox
                      checked={selectedCriteria.indexOf(specialty) > -1}
                      size="small"
                    />
                    <ListItemText
                      primary={specialty}
                      className={classes.listitem}
                      classes={{ primary: classes.primary }}
                    />
                  </MenuItem>
                ))}
                <ListSubheader
                  classes={{ root: classes.subHeaderRoot }}
                  disableSticky
                >
                  Languages
                </ListSubheader>
                {languages.map(language => (
                  <MenuItem
                    key={language}
                    value={language}
                    classes={{ root: classes.listRoot }}
                  >
                    <Checkbox
                      checked={selectedCriteria.indexOf(language) > -1}
                      size="small"
                    />
                    <ListItemText
                      primary={language}
                      className={classes.listitem}
                      classes={{ primary: classes.primary }}
                    />
                  </MenuItem>
                ))}
                <ListSubheader
                  classes={{ root: classes.subHeaderRoot }}
                  disableSticky
                >
                  Nationality
                </ListSubheader>
                {nationalities.map(nationality => (
                  <MenuItem
                    key={nationality}
                    value={nationality}
                    classes={{ root: classes.listRoot }}
                  >
                    <Checkbox
                      checked={selectedCriteria.indexOf(nationality) > -1}
                      size="small"
                    />
                    <ListItemText
                      primary={nationality}
                      className={classes.listitem}
                      classes={{ primary: classes.primary }}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              variant="outlined"
              color="secondary"
              className={classes.select5}
            >
              <InputLabel className={classes.label}>Pricing</InputLabel>
              <Select
                // TODO fix select crashing app when clicking the subheader section of the menu
                value={selectedPricing}
                onChange={handlePricing}
                renderValue={selected => selected}
                className={classes.inputSelect}
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot,
                  icon: classes.selectIcon
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  root: {
                    maxHeight: "30em"
                  },
                  getContentAnchorEl: null
                }}
              >
                <ListSubheader classes={{ root: classes.subHeaderRoot }}>
                  PRICING
                </ListSubheader>
                {pricing.map(price => (
                  <MenuItem key={price} value={price}>
                    <Checkbox checked={selectedPricing.indexOf(price) > -1} />
                    <ListItemText primary={price} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Grid
              container
              item
              justify="center"
              alignItems="center"
              md={1}
              sm={1}
              xs={1}
            >
              <Typography variant="body1" className={classes.bodytext}>
                Or:
              </Typography>
            </Grid>

            <TextField
              id="search"
              label="Search by host/guide name"
              type="search"
              color="secondary"
              onChange={handleSearch}
              value={search}
              className={classes.search}
              classes={{
                root: classes.searchRoot
              }}
              InputProps={{
                className: classes.searchInput
              }}
              InputLabelProps={{
                className: classes.searchLabel
              }}
              margin="normal"
              variant="outlined"
            />
            <TextField style={{ visibility: "hidden" }} />
          </Grid>
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            item
            sm={9}
            xs={9}
          />
        </ThemeProvider>
      </Grid>
    </div>
  );
};

export default connect(
  null,
  { sendHostName }
)(ContentFilter);
