export const RESORT = "resort";
export const PROFILE = "profile";
export const WHEN_WHO = "whenWho";
export const SLEEP = "sleep";
export const DOING = "doing";
export const LIFT_PASS = "liftPass";
export const EQUIPMENT = "equipment";
export const LESSONS = "lessons";
export const CHECKOUT = "checkout";
export const PAYMENT = "payment";
