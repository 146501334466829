import React from "react";
import { SvgIcon } from "@material-ui/core";
import { SERVICE_NAMES } from "../../../constants/common";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../../../materials/vector.svg";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    height: 50,
    margin: 5,
    [theme.breakpoints.down("sm")]: {
      height: 40
    }
  },
  root: {
    // "& .MuiSvgIcon-root": {
    //   height: "2em"
    // }
  },
  logo: {
    [theme.breakpoints.down("sm")]: {
      height: 25
    }
  },
  logoImg: {
    height: "75px",
    verticalAlign: "bottom",

    [theme.breakpoints.down("sm")]: {
      height: "2.5rem",
      paddingLeft: 0
    }
  },
  button: {
    zIndex: 1000,
    paddingLeft: "1rem",
    "&:focus": {
      outline: "none"
    },
    "&:hover": {
      background: "none"
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1rem"
    }
  },
  popperPaper: {
    width: "15rem"
  },
  iconbutton: {
    float: "right",
    margin: "0.5rem 0.1rem"
  },
  colwhite: {
    color: "white"
  },
  colblack: {
    color: "black"
  }
}));

const NavbarLogoDropdown = props => {
  const classes = useStyles();
  const { book, about, how, contact } = SERVICE_NAMES;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const pathname = props.location.pathname;
  const LogoWithJustify = () => {
    return (
      <div>
        <SvgIcon component="object">
          {/* <IconWhite /> */}
          <img src={Logo} className={classes.logoImg} />
        </SvgIcon>
      </div>
    );
  };

  return (
    <>
      <div style={{ paddingLeft: "1rem" }}>
        <LogoWithJustify />
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return { currentUser: state.auth.currentUser };
}

export default connect(mapStateToProps)(NavbarLogoDropdown);
