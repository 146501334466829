import {
  ADD_RESORT,
  REMOVE_RESORT,
  ADD_PROFILE_IMAGE,
  ADD_QUALIFICATION
} from "../actions/action-types";

const initialState = {
  resortID: [],
  profileImageURL: "",
  qualificationURL: ""
};

function signupReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_RESORT: {
      let newIDs = [...state.resortID];
      newIDs[action.payload.resortIndex] = action.payload.resortID;
      return { ...state, resortID: newIDs };
    }
    case REMOVE_RESORT: {
      let newIDs = [...state.resortID];
      newIDs.pop();
      return { ...state, resortID: newIDs };
    }
    case ADD_PROFILE_IMAGE: {
      console.log("payload is ", action.payload);
      return { ...state, profileImageURL: action.payload };
    }
    case ADD_QUALIFICATION: {
      console.log("payload is ", action.payload);
      return { ...state, qualificationURL: action.payload };
    }

    default:
      return state;
  }

  // if (action.type === ADD_RESORT) {
  //   let newIDs = [...state.resortID];
  //   newIDs[action.payload.resortIndex] = action.payload.resortID;
  //   return { ...state, resortID: newIDs };
  // } else if (action.type === REMOVE_RESORT) {
  //   let newIDs = [...state.resortID];
  //   newIDs.pop();
  //   return { ...state, resortID: newIDs };
  // } else {
  //   return state;
  // }
}

export default signupReducer;
