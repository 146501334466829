import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

// Store
import { reduxForm, blur } from "redux-form";
import { USER_TYPE } from "../../../constants/common";

function validate(values) {
  let errors = {};
  return errors;
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#F6F6F6"
  },
  backButton: {
    width: "60%",
    marginTop: "1em",
    marginBottom: "1em",
    marginLeft: "20%",
    marginRight: "auto",
    padding: "0",
    fontFamily: "PT Sans Narrow"
  },

  buttonRoot: {
    "&.MuiButton-outlined": {
      color: "#FF6161"
      // borderColor: "#FF6161"
    }
  },

  arrowIcon: {
    transform: "rotate(180deg)"
  },

  blankBox1: {
    height: 190,
    [theme.breakpoints.down("sm")]: {
      height: "20%"
    }
  },

  blankBox2: {
    height: 280,
    [theme.breakpoints.down("sm")]: {
      height: "20%"
    }
  },

  titleStyle: {
    paddingLeft: "5em",
    paddingRight: "5em",
    fontFamily: "PT Sans Narrow",
    color: "#000000",
    textAlign: 'center',
    fontWeight: "400",
    fontStyle: "normal",
    // textTransform: "uppercase",
    lineHeight: "140.62%",
    paddingTop: "1em",
    paddingBottom: "1em",
    fontSize: "26px",
    [theme.breakpoints.down("xs")]: {
      marginTop: 140,
      fontSize: "18px",
      paddingLeft: "1.5em",
      paddingRight: "1.5em"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "20vh"
    }
  },

  buttons: {
    textAlign: "center",
    display: "inline",
    width: "16em",
    // height: "3.1em",
    borderRadius: 100,
    backgroundColor: "rgb(255,97,97)",
    [theme.breakpoints.down("xs")]: {
      width: "12em"
    },
    "&:hover": {
      backgroundColor: "rgb(255,97,97)"
    },
    boxShadow:
      "rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px",
    marginTop: "2em",
    marginBottom: "1em",
    marginRight: "0.5em",
    marginLeft: "0.5em"
  },
  buttonGrid: {
    marginBottom: 160,
    display:'flex',
    flexDirection:'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonsLabel: {
    color: "white",
    fontFamily: "PT Sans Narrow",
    fontWeight: "bold",
    fontSize: "16px",
    textTransform: "none"
  },
  form: {
    display: "inline",
    textAlign: "center",
    backgroundColor: "#FFFFFF"
  },
  root1: {
    backgroundColor: "#FFFFFF"
  }
}));

let SignupProviderOrClient = props => {
  const classes = useStyles();
  const history = useHistory();

  const handleSelectionClient = event => {
    event.preventDefault();
    // The below sets the new value in the redux form with the value of 1
    props.dispatch(blur("signup", "userType", USER_TYPE.CLIENT));

    // The below handles the stepper and moves the user onto the next stage when button is clicked.
    return props.handleSubmit();
  };

  const handleSelectionProvider = event => {
    event.preventDefault();
    // The below sets the new value in the redux form with the value of 2
    props.dispatch(blur("signup", "userType", USER_TYPE.PROVIDER));

    // The below handles the stepper and moves the user onto the next stage when button is clicked.
    return props.handleSubmit();
  };

  return (
    <div className={classes.root1}>
      <Grid container justify="center">
        <form className={classes.form}>
          <div>
            <Grid item sm={12} xs={12}>
              <Typography className={classes.titleStyle}>
                What type of account would you like to create?
              </Typography>
            </Grid>

            <Grid item sm={12} xs={12} lg={12} className={classes.buttonGrid}>
              <Button
                variant="contained"
                onClick={handleSelectionProvider}
                className={classes.buttons}
                classes={{ label: classes.buttonsLabel }}
              >
                Provider
              </Button>

              <Button
                variant="contained"
                onClick={handleSelectionClient}
                className={classes.buttons}
                classes={{ label: classes.buttonsLabel }}
              >
                Client
              </Button>
            </Grid>
          </div>
        </form>
      </Grid>
    </div>
  );
};

// destroyOnUnmount - saves it in state
SignupProviderOrClient = reduxForm({
  form: "signup",
  destroyOnUnmount: false,
  validate
})(SignupProviderOrClient);

export default SignupProviderOrClient;
