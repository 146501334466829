import React from "react";
import useStyles from "./MyAccountStyles";

import { Grid, TextField } from "@material-ui/core";
import SpecialisationFilter from "./SpecialisationFilter";
import FileUpload from "./FileUpload";

const SpecialHost = ({
  studentData,
  filterData,
  _editTargetaudience,
  select,
  disabled
}) => {
  const classes = useStyles();

  return (
    <Grid item container lg={6} md={6} sm={6} xs={12}>
      <Grid item lg={4} md={4} sm={4} xs={2}>
        <div align="right" className={classes.textFieldStyle}>
          Specialities:
        </div>
      </Grid>
      <Grid item lg={2} md={2} sm={2} xs={1} />
      <Grid item lg={5} md={5} sm={5} xs={9}>
        <div align="left">
          <SpecialisationFilter />
        </div>
      </Grid>
      <Grid item lg={1} md={1} sm={1} />
      <Grid item lg={4} md={4} sm={4} xs={2}>
        <div align="right" className={classes.textFieldStyle}>
          Target Audience:
        </div>
      </Grid>
      <Grid item lg={2} md={2} sm={2} xs={1} />
      <Grid item lg={5} md={5} sm={5} xs={9}>
        <div align="left">
          <TextField
            id="select-target-audience"
            variant="outlined"
            disabled={disabled}
            select={select}
            value={studentData.targetAudience}
            onChange={event => {
              _editTargetaudience(event.target.value);
            }}
            SelectProps={{
              native: true
            }}
            className={classes.textFieldGender}
          >
            {filterData.targetAudience.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </div>
      </Grid>
      <Grid item lg={1} md={1} sm={1} />
      <Grid container item lg={4} md={4} sm={4} xs={2} direction="column">
        <Grid item>
          <div align="right" className={classes.textFieldStyle}>
            Proof of Identity:
          </div>
        </Grid>
        <Grid item>
          <div align="right" className={classes.textSmall}>
            (Upload Drivers License Working with children)
          </div>
        </Grid>
      </Grid>
      <Grid item lg={2} md={2} sm={2} xs={1} />
      <Grid item lg={5} md={5} sm={5} xs={9}>
        <div align="left">
          <FileUpload />
        </div>
      </Grid>
      <Grid item lg={1} md={1} sm={1} />
    </Grid>
  );
};

export default SpecialHost;
