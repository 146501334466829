import Timeline from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import GanttCalendar from "./ganttCalendar";
import axios from "axios";

const API_PATH = "../api/";

const InstructorCalendar = props => {
  const {
    bookings,
    unavailability,
    groups,
    startdate,
    handleItemMove,
    handleItemResize
  } = props;

  //styling
  const [items, setItems] = useState([]);
  useEffect(() => {
    if (unavailability) {
      let unavailability_colored = unavailability.map(item => ({
        ...item,
        id: -item.id,
        start_time: item.start,
        end_time: item.end,
        color: "#C4C4C4",
        selectedBgColor: "#C4C4C4",
        bgColor: "#C4C4C4",
        border: "1px solid #C4C4C4",
        group: 1
      }));
      let bookings_colored = bookings.map(item => ({
        ...item,
        color: "rgba(49, 151, 243, 0.9)",
        selectedBgColor: "rgba(49, 151, 243, 0.9)",
        bgColor: "rgba(49, 151, 243, 0.9)",
        group: 2
      }));
      setItems([...unavailability_colored, ...bookings_colored]);
    }
  }, [unavailability, bookings]);

  //This allows the items to be colored
  const itemRenderer = ({
    item,
    itemContext,
    getItemProps,
    getResizeProps
  }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    const backgroundColor = item.bgColor;
    const borderColor = item.color;
    return (
      <div
        {...getItemProps({
          style: {
            background: item.color,
            backgroundColor: item.color,
            color: item.color,
            borderColor: item.color,
            borderStyle: "solid",
            borderWidth: 1,
            borderRadius: 4,
            borderLeftWidth: itemContext.selected ? 3 : 1,
            borderRightWidth: itemContext.selected ? 3 : 1
          }
        })}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ""}

        <div
          className="rct-item-content"
          style={{ maxHeight: `${itemContext.dimensions.height}` }}
        >
          {itemContext.title}
        </div>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ""}
      </div>
    );
  };

  return (
    <GanttCalendar
      items={items}
      itemRenderer={itemRenderer}
      groups={groups}
      startdate={startdate}
      handleItemMove={handleItemMove}
      handleItemResize={handleItemResize}
    />
  );
};

export default InstructorCalendar;
