import React, { Component } from "react";
import { connect } from "react-redux";
import { baseURL } from "../../../../redux/actions/action-types";

import {
  OrangeButton,
  NextButton,
  VerticalSpaceMD,
  VerticalSpaceSM,
  FormInputCheckbox,
  SectionTitle
} from "../../../../styling/styled-components/index";

import {
  MemberDisplayArea,
  MemberCard,
  TickBoxColumn,
  NameColumn,
  DOBColumn,
  ViewEditColumn,
  CardName,
  CardDOB,
  CardEditButton
} from "../../../../styling/styled-components/whoSection";
import { SectionBodyWrapper } from "../../../../styling/styled-components/sectionWrapper";

import {
  addTripMember,
  removeTripMember,
  retrieveGroupMemberInfo
} from "../../../../redux/actions/tripWhoActions";

import TripMemberModal from "./TripMemberModal";
import ConfirmModal from "./ConfirmModal";
import $ from "jquery";
import { scroller } from "react-scroll/modules";
import { PROFILE } from "../section/section-types";
import { openSection } from "../../../../redux/actions/formActions";
import { editTripLesson } from "../../../../redux/actions/tripLessonActions";

class MemberCardComponent extends Component {
  state = {
    isModalShown: false
  };

  modifyTripMembers = e => {
    const { tripMembers, addTripMember, removeTripMember } = this.props;
    const checked = e.target.checked;
    const memberID = e.target.id;
    if (!checked) {
      // want to remove member
      if (tripMembers.length > 1) {
        removeTripMember(memberID);
      }
    } else {
      // want to add member
      addTripMember(memberID);
    }
  };

  handleClickViewEdit = () => {
    const { uuid, isMaster } = this.props;
    if (isMaster) {
      scroller.scrollTo(PROFILE, {
        activeClass: "active",
        onSetActive: this.handleSetActive,
        duration: 0,
        delay: 0,
        smooth: true,
        isDynamic: true
      });
      this.props.openSection({ section: PROFILE });
    } else {
      this.setState({ isModalShown: true }, () => {
        $("#uuid-".concat(uuid))
          .modal({
            backdrop: "static",
            keyboard: false
          })
          .modal("show");
      });
    }
  };

  handleClickCancelButton = () => {
    this.setState({ isModalShown: false });
  };

  render() {
    const { uuid, firstName, lastName, DOB, enrolled, isMaster } = this.props;
    const { isModalShown } = this.state;

    return (
      <MemberCard isMaster={isMaster}>
        <TickBoxColumn>
          <FormInputCheckbox
            type="checkbox"
            checked={enrolled}
            id={uuid}
            onChange={this.modifyTripMembers}
          />
          <label htmlFor={uuid} />
        </TickBoxColumn>

        <NameColumn>
          <CardName>
            {firstName} {lastName}
          </CardName>
        </NameColumn>

        <DOBColumn>
          <CardDOB>{DOB.toLocaleDateString("en-au")}</CardDOB>
        </DOBColumn>

        <ViewEditColumn>
          <CardEditButton onClick={this.handleClickViewEdit}>
            View/Edit
          </CardEditButton>
        </ViewEditColumn>

        {isModalShown ? (
          <TripMemberModal
            modalID={"uuid-".concat(uuid)}
            type="edit"
            onClickCancelButton={this.handleClickCancelButton}
          />
        ) : null}

        <ConfirmModal
          member_uuid={uuid}
          firstName={firstName}
          lastName={lastName}
        />
      </MemberCard>
    );
  }
}

class AddTripMember extends Component {
  render() {
    const { familyMembers, tripMembers } = this.props;
    const familyMemberKeys = Object.keys(familyMembers);

    // sort the remaining keys according to firstName
    familyMemberKeys.sort((a, b) => {
      const a_firstName = familyMembers[a]["FirstName"];
      const b_firstName = familyMembers[b]["FirstName"];
      const a_lastName = familyMembers[a]["LastName"];
      const b_lastName = familyMembers[b]["LastName"];
      if (a_firstName < b_firstName) {
        return -1;
      } else if (a_firstName > b_firstName) {
        return 1;
      } else {
        if (a_lastName < b_lastName) {
          return -1;
        } else if (a_lastName > b_lastName) {
          return 1;
        } else {
          return 0;
        }
      }
    });

    // find the master member
    const master_uuid = familyMemberKeys.find(member => {
      return familyMembers[member]["isMaster"];
    });

    // remove the master member from key array
    const master_index = familyMemberKeys.indexOf(master_uuid);
    if (master_index >= 0) {
      familyMemberKeys.splice(master_index, 1);
      familyMemberKeys.unshift(master_uuid);
    }

    return (
      <React.Fragment>
        <VerticalSpaceSM />
        <div>
          <SectionTitle>Who is going on this trip?</SectionTitle>
          <OrangeButton
            style={{ margin: "0 0 0 2vw" }}
            data-toggle="modal"
            data-target="#createMember"
            onClick={() => {
              $("#createMember").modal("show");
            }}
          >
            Create New Member
          </OrangeButton>
        </div>

        <TripMemberModal modalID="createMember" type="create" />

        <VerticalSpaceMD />

        <MemberDisplayArea>
          {familyMemberKeys.map(memberKey => {
            const enrolled = tripMembers.indexOf(memberKey) !== -1;
            return (
              <MemberCardComponent
                key={memberKey}
                firstName={familyMembers[memberKey].FirstName}
                lastName={familyMembers[memberKey].LastName}
                DOB={familyMembers[memberKey].DOB}
                level_ski={familyMembers[memberKey].SkiAbility}
                level_snowboard={familyMembers[memberKey].SnowboardAbility}
                level_telemark={familyMembers[memberKey].TelemarkAbility}
                level_snowbiking={familyMembers[memberKey].SnowbikeAbility}
                level_snowshoeing={familyMembers[memberKey].SnowshoeAbility}
                level_snowmobiling={familyMembers[memberKey].SnowmobileAbility}
                enrolled={enrolled}
                uuid={memberKey}
                isMaster={familyMembers[memberKey].isMaster}
              />
            );
          })}
        </MemberDisplayArea>
      </React.Fragment>
    );
  }
}

class BookTripPage extends Component {
  handleNext = () => {
    this.props.onNext();
  };

  componentDidMount() {
    const userID = localStorage.getItem("userID");

    if (userID != null) {
      // console.log("retrieve members");
      /*
        clear trip lesson data when logged in just for dev as trip lessons
        will be set to empty when in production mode
      */
      this.props.editTripLesson([]);
      // axios
      //   .get(baseURL + `/members/getMembers`, {
      //     headers: {
      //       Authorization: "Bearer " + localStorage.getItem("accessToken")
      //     }
      //   })
      //   .then(response => {
      //     const members = response.data.groupMembers;
      //     console.log(members);
      //     this.props.retrieveGroupMemberInfo(members);
      //   });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.masterUuid !== "" && (
          <SectionBodyWrapper>
            <AddTripMember />
            <div style={{ textAlign: "right" }}>
              <NextButton onClick={this.handleNext}>Next</NextButton>
            </div>
          </SectionBodyWrapper>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps_AddTripMember = state => {
  return {
    familyMembers: state.tripWhoReducer.familyMembers,
    tripMembers: state.tripWhoReducer.tripMembers
  };
};

const mapStateToProps_MemberCardComponent = state => {
  return {
    tripMembers: state.tripWhoReducer.tripMembers
  };
};

const mapStateToProps_BookTripPage = state => {
  return {
    masterUuid: state.masterUuid
  };
};

const mapDispatchToProps_MemberCardComponent = dispatch => {
  return {
    addTripMember: id => dispatch(addTripMember(id)),
    removeTripMember: index => dispatch(removeTripMember(index)),
    openSection: section => dispatch(openSection(section))
  };
};

const mapDispatchToProps_BookTripPage = dispatch => {
  return {
    retrieveGroupMemberInfo: memberInfos =>
      dispatch(retrieveGroupMemberInfo(memberInfos)),
    editTripLesson: lessons => dispatch(editTripLesson(lessons))
  };
};

AddTripMember = connect(mapStateToProps_AddTripMember)(AddTripMember);
MemberCardComponent = connect(
  mapStateToProps_MemberCardComponent,
  mapDispatchToProps_MemberCardComponent
)(MemberCardComponent);
BookTripPage = connect(
  mapStateToProps_BookTripPage,
  mapDispatchToProps_BookTripPage
)(BookTripPage);

export default BookTripPage;
