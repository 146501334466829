import {
  SELECT_COUNTRY,
  SELECT_RESORT,
  SELECT_RESORT_ID,
  SHOW_RESORT_ERRORS,
  UPDATE_RESORT_ERRORS,
  SET_COUNTRIES_AND_RESORTS,
  UPDATE_COUNTRIES
} from "../actions/action-types";

const initialState =
  process.env.REACT_APP_NODE_ENV === "production"
    ? {
        resortValidated: false,
        resortErrors: {},
        resortId: "",
        countries: [],
        countryResortInfoDic: {},
        resortsWithContinent: [],
        countriesAndOpeningStatus: [],
        countryResorts: []
      }
    : {
        resortValidated: false,
        resortErrors: {},
        resortId: 1,
        country: "Algeria",
        resortName: "Chréa",
        countryResortInfoDic: {},
        resortsWithContinent: [],
        countryResorts: [],
        countriesAndOpeningStatus: [],
        countries: []
      };

function tripWhereReducer(state = initialState, action) {
  if (action.type === SELECT_RESORT) {
    return Object.assign({}, state, {
      resortName: action.payload.resortName
    });
  } else if (action.type === SELECT_RESORT_ID) {
    return {
      ...state,
      resortId: action.payload.resortId
    };
  } else if (action.type === SELECT_COUNTRY) {
    return Object.assign({}, state, {
      country: action.payload.country
    });
  } else if (action.type === SHOW_RESORT_ERRORS) {
    return { ...state, resortValidated: action.payload.show };
  } else if (action.type === UPDATE_RESORT_ERRORS) {
    return {
      ...state,
      resortErrors: { ...state.resortErrors, ...action.payload.errors }
    };
  } else if (action.type === SET_COUNTRIES_AND_RESORTS) {
    return {
      ...state,
      ...action.payload
    };
  } else {
    return state;
  }
}

export default tripWhereReducer;
