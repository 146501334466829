import React, { Component } from "react";
import { connect } from "react-redux";
import {
  createMember,
  updateMember,
  deleteMember
} from "../../../../redux/actions/tripWhoActions";
import {
  OrangeButton,
  GrayButton,
  DangerButton,
  FormLabel,
  FormLabelWrapper,
  FormLabelLight,
  FormInputText,
  FormInputRadioWrapper,
  FormExplanation,
  //FormSelect,
  RadioLabel,
  FormTextarea,
  VerticalSpaceSM,
  VerticalSpaceMD
} from "../../../../styling/styled-components/index";

import {
  StyledAlert,
  AlertCloseButton
} from "../../../../styling/styled-components/whoSection";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import WhoSectionCustomDateInput from "./WhoSectionCustomDateInput";
import PureSliderBar from "../Common/PureSliderBar";
import $ from "jquery";
import uuid from "uuid";
import StyledModal from "../../StyledModal";
import YesOrNo from "../Common/YesOrNo";

const today = new Date();

class ModalBodyFooter extends Component {
  handleClickDelete = () => {
    const { modalID } = this.props;
    const member_uuid = modalID.substring(5);

    $("#uuid-".concat(member_uuid)).modal("hide");
    $("#confirmDelete-".concat(member_uuid)).modal("show");
  };

  handleClickCancel = () => {
    const { onClickCancel } = this.props;
    onClickCancel();
  };

  handleClickSave = () => {
    const { onClickSave } = this.props;
    onClickSave();
  };

  handleClickCreate = () => {
    const { onClickCreate } = this.props;
    onClickCreate();
  };

  render() {
    const { type, isMaster } = this.props;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-5" style={{ textAlign: "left" }}>
            {type === "edit" && !isMaster ? (
              <React.Fragment>
                <DangerButton
                  type="button"
                  style={{
                    margin: "0",
                    padding: "2px 10px"
                  }}
                  onClick={this.handleClickDelete}
                >
                  Delete
                </DangerButton>
              </React.Fragment>
            ) : null}
          </div>
          <div className="col-7" style={{ textAlign: "right" }}>
            <GrayButton
              type="button"
              style={{
                margin: "0",
                padding: "2px 10px"
              }}
              onClick={this.handleClickCancel}
            >
              Cancel
            </GrayButton>
            <OrangeButton
              type="button"
              style={{
                margin: "0 0 0 10px",
                padding: "2px 10px"
              }}
              onClick={
                type === "create"
                  ? this.handleClickCreate
                  : this.handleClickSave
              }
            >
              {type === "create" ? "Create" : "Save"}
            </OrangeButton>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

class ModalBodyContent extends Component {
  handleInfoChange = e => {
    const { onInfoChange } = this.props;
    onInfoChange(e);
  };

  handleDateChange = date => {
    // Call the handleDateChange method in the props
    const { onDateChange } = this.props;
    onDateChange(date);
  };

  handleAlertDismiss = () => {
    const { onAlertDismiss } = this.props;
    onAlertDismiss();
  };

  render() {
    const {
      resortName,
      firstName,
      lastName,
      DOB,
      gender,
      ski,
      snowboard,
      //telemark,
      isDisabled,
      disabilityMembership,
      disabilityMembershipID,
      disabilityDetail,
      isChild,
      hasAllergy,
      allergyDetails,
      hasSnowPass,
      alertStatus,
      alertContent
    } = this.props;

    const abilities = {
      ski,
      snowboard
    };
    const ability_keys = Object.keys(abilities);
    const inlineBlockStyle = { display: "inline-block", marginLeft: "10px" };

    return (
      <React.Fragment>
        {alertStatus ? (
          <StyledAlert>
            Please Fill in: <b>{alertContent}</b>
            <AlertCloseButton type="button" onClick={this.handleAlertDismiss}>
              <span>&times;</span>
            </AlertCloseButton>
          </StyledAlert>
        ) : null}

        <form>
          {/* First Name */}
          <div style={{ textAlign: "left" }}>
            <FormLabelWrapper>
              <FormLabel htmlFor="firstName">First Name</FormLabel>
            </FormLabelWrapper>

            <FormInputText
              type="text"
              id="firstName"
              name="firstName"
              value={firstName}
              onChange={this.handleInfoChange}
            />
          </div>
          {/* Last Name */}
          <div style={{ textAlign: "left" }}>
            <FormLabelWrapper>
              <FormLabel htmlFor="lastName">Last Name</FormLabel>
            </FormLabelWrapper>

            <FormInputText
              type="text"
              id="lastName"
              name="lastName"
              value={lastName}
              onChange={this.handleInfoChange}
            />
          </div>
          {/* Gender */}
          <div style={{ textAlign: "left" }}>
            <FormLabelWrapper>
              <FormLabel>Gender</FormLabel>
            </FormLabelWrapper>

            <div style={{ display: "inline-block" }}>
              <FormInputRadioWrapper>
                <input
                  id="male"
                  type="radio"
                  name="gender"
                  value="male"
                  checked={gender === "male"}
                  onChange={this.handleInfoChange}
                />
                <RadioLabel htmlFor="male" className="radio-inline">
                  Male
                </RadioLabel>
              </FormInputRadioWrapper>
              <FormInputRadioWrapper>
                <input
                  id="female"
                  type="radio"
                  name="gender"
                  value="female"
                  checked={gender === "female"}
                  onChange={this.handleInfoChange}
                />
                <RadioLabel htmlFor="female" className="radio-inline">
                  Female
                </RadioLabel>
              </FormInputRadioWrapper>
              <FormInputRadioWrapper>
                <input
                  id="not specified"
                  type="radio"
                  name="gender"
                  value="not specified"
                  checked={gender === "not specified"}
                  onChange={this.handleInfoChange}
                />
                <RadioLabel htmlFor="not specified" className="radio-inline">
                  Not Specified
                </RadioLabel>
              </FormInputRadioWrapper>
            </div>
          </div>
          {/* DOB */}
          <div style={{ textAlign: "left" }}>
            <FormLabelWrapper>
              <FormLabel>Date of Birth</FormLabel>
            </FormLabelWrapper>

            <div style={{ display: "inline-block" }}>
              <DatePicker
                customInput={<WhoSectionCustomDateInput />}
                dateFormat="dd/MM/yyyy"
                selected={DOB}
                maxDate={today}
                dropdownMode="select"
                showYearDropdown
                showMonthDropdown
                onChange={this.handleDateChange}
              />
            </div>
          </div>
          <VerticalSpaceMD />
          {/* Ability */}
          {ability_keys.map(act => (
            <div style={{ textAlign: "left", marginBottom: "5px" }} key={act}>
              <FormLabelWrapper>
                <FormLabel htmlFor={act}>
                  {act[0].toUpperCase() + act.substring(1)}
                </FormLabel>
              </FormLabelWrapper>

              <div style={{ display: "inline-block", width: "40%" }}>
                <PureSliderBar
                  min={1}
                  max={7}
                  id={act}
                  value={abilities[act]}
                  onChange={this.handleInfoChange}
                />
              </div>

              <div
                style={{
                  display: "inline-block",
                  marginLeft: "10px"
                }}
              >
                {abilities[act]}
              </div>
            </div>
          ))}
          <VerticalSpaceMD />
          {/* SnowPass */}
          <div style={{ textAlign: "left" }}>
            <FormLabel>
              Do you already have a reloadable snowpass for {resortName}?
            </FormLabel>
            <div style={inlineBlockStyle}>
              <YesOrNo
                input={{
                  onChange: e => this.handleInfoChange(e),
                  value: hasSnowPass,
                  name: "hasSnowPass"
                }}
              />
            </div>
          </div>

          <VerticalSpaceMD />
          {/* Disability */}
          <div style={{ textAlign: "left" }}>
            <FormLabel>Any physical or learning disabilities?</FormLabel>
            <div style={inlineBlockStyle}>
              <YesOrNo
                input={{
                  onChange: e => this.handleInfoChange(e),
                  value: isDisabled,
                  name: "isDisabled"
                }}
              />
            </div>
          </div>
          {/* Disability Details */}
          {isDisabled ? (
            <div className="row" align="left">
              <div className="col-12 col-lg-6">
                <div>
                  <FormLabelLight htmlFor="disabilityDetail">
                    <FormExplanation>
                      An Adaptive Private lesson is recommended
                    </FormExplanation>
                  </FormLabelLight>
                </div>
                <div style={{ textAlign: "center" }}>
                  <FormTextarea
                    id="disabilityDetail"
                    name="disabilityDetail"
                    value={disabilityDetail}
                    onChange={this.handleInfoChange}
                    placeholder="Disability Details"
                  />
                </div>
              </div>

              <div className="col-12 col-lg-6">
                <div>
                  <FormExplanation>
                    Disability Sports Membership?
                  </FormExplanation>
                </div>

                <div>
                  <FormLabelLight htmlFor="disabilityMembership">
                    <span
                      className="fas fa-caret-right"
                      style={{ margin: "0 4px" }}
                    />
                    Membership Type
                  </FormLabelLight>

                  <FormInputText
                    style={{ width: "150px" }}
                    type="text"
                    id="disabilityMembership"
                    name="disabilityMembership"
                    value={disabilityMembership}
                    onChange={this.handleInfoChange}
                  />
                </div>

                <VerticalSpaceSM />

                <div>
                  <FormLabelLight htmlFor="disabilityMembershipID">
                    <span
                      className="fas fa-caret-right"
                      style={{ margin: "0 4px" }}
                    />
                    Membership ID #
                  </FormLabelLight>

                  <FormInputText
                    style={{ width: "150px", transform: "translate(2px,0)" }}
                    type="text"
                    id="disabilityMembershipID"
                    name="disabilityMembershipID"
                    value={disabilityMembershipID}
                    onChange={this.handleInfoChange}
                  />
                </div>
              </div>
            </div>
          ) : null}
          <VerticalSpaceSM />
          {/* If the member is a child then display radio buttons to ask for food allergies */}
          <div style={{ textAlign: "left" }}>
            <FormLabel>Any food allergies? </FormLabel>
            <div style={inlineBlockStyle}>
              <YesOrNo
                input={{
                  onChange: e => this.handleInfoChange(e),
                  value: hasAllergy,
                  name: "hasAllergy"
                }}
              />
            </div>
          </div>

          {/* If "Yes" is selected in hasAllergy readio button then display text area for details */}
          {hasAllergy ? (
            <div className="row" align="left">
              <div className="col-12 col-lg-6">
                <div>
                  <FormLabelLight htmlFor="allergDetails">
                    <FormExplanation>
                      Please provide further details about the food allergy
                    </FormExplanation>
                  </FormLabelLight>
                </div>
                <div style={{ textAlign: "center" }}>
                  <FormTextarea
                    id="allergyDetails"
                    name="allergyDetails"
                    value={allergyDetails}
                    onChange={this.handleInfoChange}
                    placeholder="Allergy Details"
                    maxLength={20}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </form>
      </React.Fragment>
    );
  }
}

class TripMemberModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      DOB: today,
      gender: "",
      ski: 1,
      snowboard: 1,
      telemark: 1,
      snowbike: 1,
      snowshoe: 1,
      snowmobile: 1,
      isDisabled: false,
      disabilityMembership: "",
      disabilityMembershipID: "",
      disabilityDetail: "",
      isChild: false,
      hasAllergy: false,
      allergyDetails: "",
      isMaster: null,
      alert_status: false,
      alert_content: ""
    };
  }

  componentDidMount() {
    const { modalID, type, resortName, familyMembers } = this.props;
    if (type === "edit") {
      const uuid = modalID.substring(5); // modalID prefix is uuid-

      const memberInfo = familyMembers[uuid]; // Get this info for displaying during editing a family member

      const {
        FirstName,
        LastName,
        DOB,
        Gender,
        SkiAbility,
        SnowboardAbility,
        TelemarkAbility,
        SnowbikeAbility,
        SnowmobileAbility,
        SnowshoeAbility,
        IsDisabled,
        DisabilityDetail,
        DisabilityMembership,
        DisabilityMembershipID,
        isChild,
        hasAllergy,
        allergyDetails,
        hasSnowPass,
        isMaster
      } = memberInfo;

      this.setState({
        firstName: FirstName,
        lastName: LastName,
        DOB: DOB,
        gender: Gender,
        ski: SkiAbility,
        snowboard: SnowboardAbility,
        telemark: TelemarkAbility,
        snowbike: SnowbikeAbility,
        snowshoe: SnowshoeAbility,
        snowmobile: SnowmobileAbility,
        isDisabled: IsDisabled,
        disabilityMembership: DisabilityMembership,
        disabilityMembershipID: DisabilityMembershipID,
        disabilityDetail: DisabilityDetail,
        isChild: isChild,
        hasAllergy: hasAllergy,
        allergyDetails: allergyDetails,
        hasSnowPass: hasSnowPass,
        resortName: resortName,
        isMaster: isMaster
      });
    }
  }

  // Handles all info (input element value) changes except Date change
  handleInfoChange = e => {
    const name = e.target.name;
    let value = e.target.value;
    if (value === "true") {
      value = true;
    }
    if (value === "false") {
      value = false;
    }
    this.setState({
      [name]: value
    });
  };

  handleDateChange = date => {
    const isChild = this.getIsChild(date);
    this.setState({ DOB: date, isChild: isChild });
  };

  /**
   * Calculates the age of the member based on the given dob (date of birth).
   *
   * @returns If the age falls between 3 and 14 (both inclusive), then true, false otherwise.
   */
  getIsChild = dob => {
    const millisPerDay = 1000 * 60 * 60 * 24; // Number of milliseconds in a day
    const daysPerYear = 365; // Number of days per year

    const differenceInDays =
      (new Date().getTime() - dob.getTime()) / millisPerDay;

    const differenceInYears = Math.round(differenceInDays) / daysPerYear;

    return differenceInYears >= 3 && differenceInYears <= 14;
  };

  handleAlertDismiss = () => {
    this.setState({
      alert_status: false
    });
  };

  handleClickCancel = () => {
    const { modalID, type } = this.props;
    $("#".concat(modalID)).modal("hide");
    if (type === "edit") {
      const { onClickCancelButton } = this.props;
      onClickCancelButton();
    }
  };

  handleClickSave = () => {
    const {
      firstName,
      lastName,
      DOB,
      gender,
      ski,
      snowboard,
      telemark,
      snowbike,
      snowshoe,
      snowmobile,
      isDisabled,
      disabilityMembership,
      disabilityMembershipID,
      disabilityDetail,
      isChild,
      hasAllergy,
      allergyDetails,
      hasSnowPass
    } = this.state;

    const { updateMember, modalID } = this.props;

    if (firstName === "") {
      this.setState({
        alert_status: true,
        alert_content: "First Name"
      });
      return;
    }

    if (lastName === "") {
      this.setState({
        alert_status: true,
        alert_content: "Last Name"
      });
      return;
    }

    const memberInfo = {
      uuid: modalID.substring(5),
      info: {
        FirstName: firstName[0].toUpperCase() + firstName.substring(1),
        LastName: lastName[0].toUpperCase() + lastName.substring(1),
        DOB: DOB,
        Gender: gender,
        SkiAbility: parseInt(ski),
        SnowboardAbility: parseInt(snowboard),
        TelemarkAbility: parseInt(telemark),
        SnowbikeAbility: parseInt(snowbike),
        SnowmobileAbility: parseInt(snowmobile),
        SnowshoeAbility: parseInt(snowshoe),
        isMaster: false,
        IsDisabled: isDisabled,
        DisabilityDetail: disabilityDetail,
        DisabilityMembership: disabilityMembership,
        DisabilityMembershipID: disabilityMembershipID,
        isChild: isChild,
        hasAllergy: hasAllergy,
        allergyDetails: allergyDetails,
        hasSnowPass: hasSnowPass
      }
    };

    updateMember(memberInfo);

    $("#".concat(modalID)).modal("hide");
  };

  handleClickCreate = () => {
    const {
      firstName,
      lastName,
      DOB,
      gender,
      ski,
      snowboard,
      telemark,
      snowbike,
      snowshoe,
      snowmobile,
      isDisabled,
      disabilityMembership,
      disabilityMembershipID,
      disabilityDetail,
      isChild,
      hasAllergy,
      allergyDetails,
      hasSnowPass
    } = this.state;

    const { createMember, modalID } = this.props;

    if (firstName === "") {
      this.setState({
        alert_status: true,
        alert_content: "First Name"
      });
      return;
    }

    if (lastName === "") {
      this.setState({
        alert_status: true,
        alert_content: "Last Name"
      });
      return;
    }

    if (gender === "") {
      this.setState({
        alert_status: true,
        alert_content: "Gender"
      });
      return;
    }

    if (DOB === null) {
      this.setState({
        alert_status: true,
        alert_content: "Date of Birth"
      });
      return;
    }

    const memberUUID = uuid.v4();

    const memberInfo = {
      uuid: memberUUID,
      info: {
        FirstName: firstName[0].toUpperCase() + firstName.substring(1),
        LastName: lastName[0].toUpperCase() + lastName.substring(1),
        DOB: DOB,
        Gender: gender,
        SkiAbility: ski,
        SnowboardAbility: snowboard,
        TelemarkAbility: telemark,
        SnowbikeAbility: snowbike,
        SnowmobileAbility: snowmobile,
        SnowshoeAbility: snowshoe,
        isMaster: false,
        IsDisabled: isDisabled,
        DisabilityDetail: disabilityDetail,
        DisabilityMembership: disabilityMembership,
        DisabilityMembershipID: disabilityMembershipID,
        isChild: isChild,
        hasAllergy: hasAllergy,
        allergyDetails: allergyDetails,
        hasSnowPass: hasSnowPass
      }
    };

    // save new member to Redux store
    createMember(memberInfo);

    $("#".concat(modalID)).modal("hide");

    // reset the state
    this.setState({
      firstName: "",
      lastName: "",
      DOB: today,
      gender: "",
      ski: 1,
      snowboard: 1,
      telemark: 1,
      snowbike: 1,
      snowshoe: 1,
      snowmobile: 1,
      isDisabled: false,
      disabilityMembership: "",
      disabilityMembershipID: "",
      disabilityDetail: "",
      isChild: false,
      hasAllergy: false,
      allergyDetails: "",
      hasSnowPass: false,
      isMaster: null,
      alert_status: false,
      alert_content: ""
    });
  };

  render() {
    // modalID: createMember / uuid-xxx
    // type: edit / create
    const { modalID, type, resortName } = this.props;
    const {
      firstName,
      lastName,
      DOB,
      gender,
      ski,
      snowboard,
      telemark,
      snowbike,
      snowshoe,
      snowmobile,
      isDisabled,
      disabilityMembership,
      disabilityMembershipID,
      disabilityDetail,
      isChild,
      hasAllergy,
      allergyDetails,
      hasSnowPass,
      isMaster,
      alert_status,
      alert_content
    } = this.state;

    const body = (
      <ModalBodyContent
        resortName={resortName}
        firstName={firstName}
        lastName={lastName}
        DOB={DOB}
        gender={gender}
        ski={ski}
        snowboard={snowboard}
        telemark={telemark}
        snowbike={snowbike}
        snowshoe={snowshoe}
        snowmobile={snowmobile}
        isDisabled={isDisabled}
        disabilityMembership={disabilityMembership}
        disabilityMembershipID={disabilityMembershipID}
        disabilityDetail={disabilityDetail}
        isChild={isChild}
        hasAllergy={hasAllergy}
        allergyDetails={allergyDetails}
        hasSnowPass={hasSnowPass}
        alertStatus={alert_status}
        alertContent={alert_content}
        onInfoChange={this.handleInfoChange}
        onDateChange={this.handleDateChange}
        onAlertDismiss={this.handleAlertDismiss}
      />
    );

    const footer = (
      <ModalBodyFooter
        type={type}
        isMaster={isMaster}
        modalID={modalID}
        firstName={firstName}
        lastName={lastName}
        onClickCancel={this.handleClickCancel}
        onClickSave={this.handleClickSave}
        onClickCreate={this.handleClickCreate}
      />
    );

    return <StyledModal id={modalID} body={body} footer={footer} />;
  }
}

function mapStateToProps_TripMemberModal(state) {
  return {
    familyMembers: state.tripWhoReducer.familyMembers,
    resortName: state.tripWhereReducer.resortName
  };
}

function mapDispatchToProps_TripMemberModal(dispatch) {
  return {
    createMember: memberInfo => dispatch(createMember(memberInfo)),
    updateMember: memberInfo => dispatch(updateMember(memberInfo)),
    deleteMember: memberID => dispatch(deleteMember(memberID))
  };
}

function mapStateToProps_ModalBodyFooter(state) {
  return {
    familyMembers: state.tripWhoReducer.familyMembers
  };
}

TripMemberModal = connect(
  mapStateToProps_TripMemberModal,
  mapDispatchToProps_TripMemberModal
)(TripMemberModal);
ModalBodyFooter = connect(mapStateToProps_ModalBodyFooter)(ModalBodyFooter);

export default TripMemberModal;
