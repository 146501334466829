import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import TabContainer from "react-bootstrap/TabContainer";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

import { FilterColor } from "../../../styling/styled-components/color-themes_old";
import { OrangeButton } from "../../../styling/styled-components";
import { SERVICE_NAMES } from "../../../constants/common";
import { TAB_BAR_HEIGHT } from "../../../styling/styled-components/dimension-themes";

import { PROFILE, RESORT } from "../Book/section/section-types";
import ResortSection from "../Book/where/ResortSection";

import ConnectInstructors from "./ConnectInstructors";
import ConnectOthers from "./ConnectOthers";

import {
  closeSection,
  openSection,
  saveSection
} from "../../../redux/actions/formActions";

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: "justify"
  },
  tabBar: {
    height: TAB_BAR_HEIGHT,
    backgroundColor: FilterColor
  },
  tab: {
    color: "white",
    fontFamily: "PT Sans Narrow",
    fontSize: "1.1rem",
    textTransform: "initial",
    opacity: 1,
    "&:focus": {
      outline: "none"
    },
    "&:active": {
      outline: "none"
    },
    "&:disabled": {
      opacity: 1
    }
  },
  tabContents: {
    marginTop: 32
  },
  selectedTab: {
    color: "black",

    backgroundColor: "white"
  },
  scrollButtons: {
    color: "white"
  },
  indicator: {
    display: "none"
  }
}));

const ServiceTabs = props => {
  const resortSectionRef = React.createRef();
  const { book, connect, instructor } = SERVICE_NAMES;
  const [page, setPage] = useState(0);
  const [redirectToSAF, setRedirectToSAF] = useState(false);
  const classes = useStyles();

  const handleTabChange = (event, page) => {
    setPage(page);
  };

  const handleEnquiry = () => {
    if (props.resortName === "" || props.resortName === undefined) {
      resortSectionRef.current.validateResorts();
    } else {
      props.openSection({ section: PROFILE });
      props.saveSection({ section: RESORT });
      props.closeSection({ section: RESORT });
      setRedirectToSAF(true);
    }
  };

  if (redirectToSAF) {
    return <Redirect push to="/lessons-enquiry" />;
  }
  return (
    <div className={classes.root}>
      <Tabs
        variant="scrollable"
        scrollButtons="off"
        value={page}
        onChange={handleTabChange}
        className={classes.tabBar}
        classes={{
          indicator: classes.indicator,
          scrollButtons: classes.scrollButtons
        }}
      >
        <Tab
          disableRipple
          classes={{ selected: classes.selectedTab }}
          className={classes.tab}
          label={book}
        />
        <Tab
          disableRipple
          classes={{ selected: classes.selectedTab }}
          className={classes.tab}
          label={instructor}
        />

        <Tab
          disableRipple
          classes={{ selected: classes.selectedTab }}
          className={classes.tab}
          label={connect}
        />
        {/*<Tab*/}
        {/*  disableRipple*/}
        {/*  classes={{ selected: classes.selectedTab }}*/}
        {/*  className={classes.tab}*/}
        {/*  label={host}*/}
        {/*/>*/}
        {/*<Tab*/}
        {/*  disableRipple*/}
        {/*  classes={{ selected: classes.selectedTab }}*/}
        {/*  className={classes.tab}*/}
        {/*  label={invite}*/}
        {/*/>*/}
      </Tabs>
      <div className={classes.tabContents}>
        {page === 0 && (
          <TabContainer>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <ResortSection ref={resortSectionRef} homePage />
                </div>
                <div className="row col" style={{ margin: "auto" }}>
                  <OrangeButton
                    style={{ margin: "auto", borderRadius: 4, height: 38 }}
                    onClick={handleEnquiry}
                    className="col-12 col-sm-4"
                  >
                    Find your lessons
                  </OrangeButton>
                </div>
              </div>
            </div>
          </TabContainer>
        )}
        {page === 1 && (
          <TabContainer>
            <ConnectInstructors />
          </TabContainer>
        )}
        {page === 2 && (
          <TabContainer>
            <ConnectOthers />
          </TabContainer>
        )}
        {/*{page === 3 && (*/}
        {/*  <TabContainer>*/}
        {/*    <ComingSoon*/}
        {/*      message={*/}
        {/*        <h5>*/}
        {/*          We think you might want to Invite your friends to become a*/}
        {/*          RESORTer member so you can invite them to a group you*/}
        {/*          create.*/}
        {/*        </h5>*/}
        {/*      }*/}
        {/*    />*/}
        {/*  </TabContainer>*/}
        {/*)}*/}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    resortName: state.tripWhereReducer.resortName
  };
}

export default connect(
  mapStateToProps,
  { saveSection, openSection, closeSection }
)(ServiceTabs);
