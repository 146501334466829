// Dependencies
import React, { useState } from "react";
import { connect } from "react-redux";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from '@material-ui/icons/Check';
import { useHistory } from "react-router-dom";
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  withWidth,
  ListItem,
  ListItemText,
  ListItemIcon,
  Avatar,
} from "@material-ui/core";

// Components
// TODO: future feature - rating
import StarRating from "../../../../components/Common/StarRating/StarRating";

const useStyles = makeStyles(theme =>({
  root: {
    position: "relative",
    maxWidth: 340,
    width: "40%",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    }
  },
  buttonLabel:{
      color: "#1277B8",
      width: "100%",
      display: "inherit",
      alignItems: "inherit",
      justifyContent: "inherit",
  },
  large: {
    width: 75,
    height: 75,
  },
  buttonRoot: {
    marginBottom: "2em",
    textTransform: "none",
    fontWeight: "normal",
    fontFamily: "PT Sans Narrow",
    fontSize: 18,
    borderRadius: 20,
    backgroundColor: "#1277b8",
    "&:focus": {
      outline: "transparent"
    }
  },
  name: {
    color: "#1277b8",
    fontFamily: "PT Sans Narrow",
    fontWeight: "bold",
    fontSize: 17,
    textAlign: "left",
    paddingLeft: "0.3em"
  },
  service:{
    fontFamily: "PT Sans",
    fontWeight: "400",
    float:"left",
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    }
  },
  price: {
    fontFamily: "PT Sans",
    fontSize: 16,
    fontWeight: "700",
    float:"right"
  },
  priceFrom: {
    fontFamily: "PT Sans",
    fontWeight: "400",
    float:"right",
    color:"#AAAAAA"
  },
  description: {
    textAlign: "left",
    paddingLeft: "0.5em",
    paddingRight: "0.5em",
    fontSize: 15,
    color: "#505050"
  },
  media: {
    position: "relative",
    height: 260
  },
}));

function ProviderCard({ provider }) {
  const [msg, setMsg] = useState("View Full Profile");
  let history = useHistory();
  /** provider e.g
   * {
        "id": 62,
        "user": {
            "first_name": "Victor",
            "last_name": "Duncan",
            "gender": "male",
            "dob": "1978-09-14",
            "mobile_number": "+4364337223",
            "bio": "Friend staff leave everybody investment even.",
            "country": {
                "id": 30,
                "country_name": "Serbia"
            },
            "languages": [
                {
                    "id": 6,
                    "language": "Chinese (simplified)"
                },
                {
                    "id": 19,
                    "language": "Hebrew"
                },
                {
                    "id": 40,
                    "language": "Romanian"
                },
                {
                    "id": 42,
                    "language": "Scottish Gaelic"
                }
            ],
            "skiing_level": 4,
            "snowboarding_level": 0,
            "signup_step": 2
        },
        "provider_bio": "Ahead age product throw dinner wear force.",
        "services": [
            {
                "id": 3,
                "catagory_name": "TOURING HOSTS",
                "service_name": "Snowboard tour of the slopes"
            },
            {
                "id": 5,
                "catagory_name": "TOURING HOSTS",
                "service_name": "eBike backcountry tour (summer only)"
            },
            {
                "id": 6,
                "catagory_name": "TOURING HOSTS",
                "service_name": "Hiking"
            },
            {
                "id": 13,
                "catagory_name": "ACTIVITY HOSTS",
                "service_name": "Yoga/Pilates"
            },
            {
                "id": 16,
                "catagory_name": "CHILD MINDING HOSTS",
                "service_name": "On slope - snowboarding"
            }
        ],
        "suitable_fors": [
            {
                "id": 1,
                "suitable_for": "Toddlers / 3-5yo"
            },
            {
                "id": 4,
                "suitable_for": "Adult / 18-64yo"
            }
        ],
        "resorts": [
            {
                "id": 1,
                "resort_name": "Chréa",
                "country": 67
            }
        ]
    },
   */

  const providerContent = {
    id: provider.id,
    avatar: provider.user.profile_picture,
    name: provider.user.first_name.toUpperCase() + " " + provider.user.last_name.toUpperCase(),
    // rating: 5,
    services: provider.services,
  };

  const classes = useStyles();

  const handleClick = event => {
    //setMsg('Coming Soon ... View Full Profile');
    history.push("/profile/"+providerContent.id)
  }

  return (
    <Card className={classes.root}>
      <CardHeader 
        avatar={
          <Avatar className={classes.large} aria-label="provider image" alt={providerContent.name} src={providerContent.avatar} />
        }
        title={providerContent.name}
        /** TODO: future feature - rating stars
         *subheader={
            <StarRating aria-label="provider rating" currentRating={providerContent.rating} />
          }
         */
      />
      <CardContent>
          {providerContent.services.map((service, index)=>
            (<ListItem style={{ padding:0 }}>
              <ListItemIcon style={{ width: "10%", minWidth: 30 }}>
                <CheckIcon style={{ color: "#1277B8" }}/>
              </ListItemIcon>
              <ListItemText
                primary={
                  <div style={{ display: "block", clear: "both" }}>
                    <span className={classes.service}>
                      {service.id == 1 ? "First Timer Assistance" : service.service_name}
                    </span>
                    {/* 
                    TODO: future feature - minimum price
                      <span className={classes.price}>
                        {`$${service.price}`}
                      </span>
                      <span className={classes.priceFrom}>
                        from &nbsp;
                      </span> 
                    */}
                  </div>
                }
              />
            </ListItem>)
          )}
      </CardContent>
      <CardActions style={{flexDirection: "row-reverse"}}>
        <Button
          size="small"
          classes={{
            label: classes.buttonLabel
          }}
          onClick={handleClick}
        >
          {msg}
        </Button> 
       </CardActions>
    </Card>
  );
}

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withWidth()(ProviderCard));
