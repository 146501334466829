import React, { useState, useRef } from "react";
import { connect } from "react-redux";

import SnackbarContent from "@material-ui/core/SnackbarContent";
import LessonContainer from "./LessonContainer";
import {
  ADAPTIVE_LESSON_COLOR,
  NON_FIRST_TIMERS_COLOR,
  FIRST_TIMER_LESSON_COLOR
} from "./constant/color";
import {
  ADAPTIVE_LESSON_HEADING,
  FIRST_TIMERS_LESSON_HEADING,
  NON_FIRST_TIMERS_LESSON_HEADING
} from "./constant/lessonHeading";
import {
  ADAPTIVE_SECTION,
  FIRST_TIMER_SECTION,
  NON_FIRST_TIMER_SECTION
} from "./constant/lessonSection";
import { lessonObjs } from "./constant/lessonType";
import EditPopup from "./EditPopup";
import { calculateAgeGroups } from "./reusable/OptionFilter";
import {
  createTripLessons,
  showLessonErrors
} from "../../../../redux/actions/tripLessonActions";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";

import _ from "lodash";
import { SNOWBOARD } from "../../../../constants/common";

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: theme.spacing(4, 2)
  },
  buttonWrapper: {
    [theme.breakpoints.down("xs")]: {
      flexBasis: "100%",
      textAlign: "center"
    },
    flexBasis: "auto"
  },
  buttonSection: {
    margin: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end"
    }
  },
  buttonSectionLeft: {
    margin: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end"
    }
  },
  actionBtns: {
    backgroundColor: "#FF6261",
    color: "white",
    borderRadius: 15,
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "black"
    }
  }
}));

// TODO: currently static data for private age categories
const privateAgeCategories = [
  {
    minimumAge: 3,
    maximumAge: 5,
    ageGroup: "3-5 yrs"
  },
  {
    minimumAge: 6,
    maximumAge: 120,
    ageGroup: "Above 5"
  }
];

const LessonSection = props => {
  const {
    tripLessons,
    familyMembers,
    main,
    createTripLessons, // action
    skiFirstTimersWithoutLesson,
    snowboardFirstTimersWithoutLesson,
    lessonValidated,
    dismissLessonErrors
  } = props;

  const classes = useStyles(props);
  const [isAdaptive, setIsAdaptive] = useState(false);
  const [isFirstTimer, setIsFirstTimer] = useState(false);
  const [popupOpen, setPopupOpen] = useState([
    {
      id: 0,
      open: false
    },
    {
      id: 1,
      open: false
    },
    {
      id: 2,
      open: false
    }
  ]);

  /** get all activity members to check if have potential disabilities/first timers */
  let initialParticipants = [];
  const allActivityMembers = _.union(main["ski"], main["snowboard"]);
  _.map(allActivityMembers, id => {
    const memberInfo = familyMembers[id];
    initialParticipants.push({ ...memberInfo });
  });

  const hasPotentialDisabilities =
    _.find(initialParticipants, { IsDisabled: true }) === undefined
      ? false
      : true;
  const hasPotentialFirstTimers =
    _.find(initialParticipants, participant => {
      return participant.SkiAbility === 1 || participant.SnowboardAbility === 1;
    }) === undefined
      ? false
      : true;

  /** group lessons into three sections from tripLessons */
  const adaptives = _.filter(tripLessons, { adaptive: true });
  const firstTimers = _.filter(tripLessons, {
    adaptive: false,
    firstTimer: true
  });
  const nonFirstTimers = _.filter(tripLessons, {
    adaptive: false,
    firstTimer: false
  });

  let lessonSections = [];
  lessonSections[0] = {
    ...lessonObjs[0],
    hasPotentialPariticipants: hasPotentialDisabilities,
    lessons: adaptives
  };
  lessonSections[1] = {
    ...lessonObjs[1],
    hasPotentialPariticipants: hasPotentialFirstTimers,
    lessons: firstTimers
  };
  lessonSections[2] = {
    ...lessonObjs[2],
    hasPotentialPariticipants: true,
    lessons: nonFirstTimers
  };

  const handleAddLessonButton = (event, lessonSection) => {
    const selected = _.find(popupOpen, { id: lessonSection.id });
    selected.open = true;
    setPopupOpen([...popupOpen]);

    if (lessonSection.id === 0) {
      setIsAdaptive(true);
      setIsFirstTimer(false);
    } else if (lessonSection.id === 1) {
      setIsAdaptive(false);
      setIsFirstTimer(true);
    } else {
      setIsAdaptive(false);
      setIsFirstTimer(false);
    }
  };

  //scrolling effect for new lessons
  const myRef = useRef(null);
  const scrollToRef = ref => ref.current.scrollIntoView(false);
  const handlePopupClose = lessonSection => {
    const selected = _.find(popupOpen, { id: lessonSection.id });
    selected.open = false;
    setPopupOpen([...popupOpen]);
    scrollToRef(myRef);
  };

  const renderMembersWithoutLessons = (members, type) => {
    let member_string = "Please register " + type + " lessons for: ";
    members.map(
      member =>
        (member_string += '"' + member.FirstName + " " + member.LastName + '" ')
    );
    return <p>{member_string}</p>;
  };

  const errorMessage = () => {
    return (
      <div style={{ color: "white" }}>
        {skiFirstTimersWithoutLesson.length > 0
          ? renderMembersWithoutLessons(skiFirstTimersWithoutLesson, "ski")
          : null}
        {snowboardFirstTimersWithoutLesson.length > 0
          ? renderMembersWithoutLessons(
              snowboardFirstTimersWithoutLesson,
              "snowboard"
            )
          : null}
      </div>
    );
  };

  return (
    <div>
      {lessonValidated && (
        <SnackbarContent
          open={lessonValidated}
          message={errorMessage()}
          action={
            <div>
              <Button
                color="secondary"
                size="small"
                onClick={dismissLessonErrors}
              >
                Dismiss
              </Button>
            </div>
          }
        />
      )}
      {/* render lesson containers */}
      {_.map(lessonSections, lessonSection => {
        const {
          id,
          hasPotentialPariticipants,
          lessons,
          lessonHeading,
          lessonBackgroundColor
        } = lessonSection;
        // do not render lesson container if there is no potential participants
        if (hasPotentialPariticipants === false) return;

        const open = _.find(popupOpen, { id: id }).open;

        return (
          <Box className={classes.wrapper} key={id}>
            <div ref={id == 2 ? myRef : null}>
              <LessonContainer
                id={id} // pass id to the container: 0-adaptive, 1-first timers, 2-experienced
                lessonArray={lessons}
                lessonHeading={lessonHeading}
                lessonBackgroundColor={lessonBackgroundColor}
              />
            </div>
            <Grid container className={classes.buttonSectionLeft}>
              <Grid className={classes.buttonWrapper} item sm={6} xs={12}>
                <Button
                  variant="contained"
                  className={classes.actionBtns}
                  onClick={event => handleAddLessonButton(event, lessonSection)}
                >
                  {lessons.length === 0
                    ? "Build Your Lesson/s"
                    : "Add New Lesson"}
                </Button>
              </Grid>

              {open ? (
                <EditPopup
                  isAdaptive={isAdaptive}
                  isFirstTimer={isFirstTimer}
                  open={open}
                  onClose={() => handlePopupClose(lessonSection)}
                  needsHighlight={true}
                />
              ) : null}
            </Grid>
          </Box>
        );
      })}

      {/* render buttons */}
      <Grid container className={classes.buttonSection}>
        <Grid className={classes.buttonWrapper} item sm={6} xs={12}>
          <Button
            variant="contained"
            className={classes.actionBtns}
            onClick={props.onNext}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    familyMembers: state.tripWhoReducer.familyMembers,
    groupLessons: state.tripLessonReducer.groupLessons,
    privateLessons: state.tripLessonReducer.privateLessons,
    tripLessons: state.tripLessonReducer.tripLessons,
    main: state.activityReducer.main,
    lessonValidated: state.tripLessonReducer.lessonValidated,
    skiFirstTimersWithoutLesson:
      state.tripLessonReducer.firstTimersWithoutLessons
        .skiFirstTimersWithoutLesson,
    snowboardFirstTimersWithoutLesson:
      state.tripLessonReducer.firstTimersWithoutLessons
        .snowboardFirstTimersWithoutLesson
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createTripLessons: tripLessons => dispatch(createTripLessons(tripLessons)),
    dismissLessonErrors: () => dispatch(showLessonErrors(false))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LessonSection);
