import React from "react";
import axios from "axios";
import { baseURL } from "../../../redux/actions/action-types";
import FooterBar from "../../../components/Common/Footer/FooterBar";
import Button from "@material-ui/core/Button";
import {
  makeStyles,
  Checkbox,
  createMuiTheme,
  ThemeProvider
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";

const checkboxTheme = createMuiTheme({
  overrides: {
    PrivateSwitchBase: {
      root: {
        padding: 0
      }
    },

    MuiIconButton: {
      label: {
        width: 14.69,
        height: 15.85
      }
    },

    MuiSvgIcon: {
      root: {
        fill: "#137cbd",
        width: 20,
        height: 20
      }
    }
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    background: "#FFFFFF",
    textAlign: "center"
  },

  btn: {
    borderRadius: 100,
    background: "#FF6161",
    align: "right",
    fontFamily: "PT Sans Narrow"
  },

  checkBoxStyle: {
    width: 14.69,
    height: 15.86,
    border: "1px solid #505050",
    borderRadius: 3,
    boxSizing: "border-box"
  },

  checkTextStyle: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14,
    lineHeight: "140.62%",
    textAlign: "left",
    color: "#606060",
    marginTop: 5
  },

  moreDetailFormat: {
    textDecoration: "underline",
    fontFamily: "PT Sans Narrow"
  },

  btnbot: {
    borderRadius: 100,
    background: "#FF6161",
    marginTop: 63.84,
    fontFamily: "PT Sans Narrow",
    fontSize: 14,
    width: 87,
    height: 27.23,
    textTransform: "none"
  },

  headingStyle: {
    color: "#1277B8",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 30,
    lineHeight: "140.62%",
    alignItems: "center",
    textAlign: "center"
  },

  textField: {
    width: "100%",
    fontFamily: "PT Sans Narrow",
    padding: 0,
    border: 10,
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    borderRadius: 0,
    marginBottom: 10,
    "&::placeholder": {
      color: "#000000"
    }
  },

  textFieldStyle: {
    fontFamily: "PT Sans Narrow",
    fontWeight: "bold",
    color: "#1277B8",
    fontSize: 20,
    lineHeight: "140.62%"
  },

  emptyCardgrey: {
    position: "relative",
    height: 100,
    background: "#F6F6F6"
  },
  emptyCard: {
    position: "relative",
    height: 80,
    background: "#FFFFFF"
  },

  textField1: {
    height: 120,
    width: "100%",
    padding: "0em",
    border: 10,
    backgroundColor: "rgba(18, 119, 184, 0.15)"
  },

  textField2: {
    height: 40,
    width: "100%",
    fontSize: 5,
    size: "small",
    padding: 0,
    border: "1px solid #1277B8",
    borderRadius: 5,
    boxSizing: "border-box",
    marginBottom: 10
  },

  [theme.breakpoints.only("xs")]: {
    textField1: {
      fontSize: 16
    },
    textField2: {
      fontSize: 16
    },
    emptyCard: {
      height: 40
    },
    headingStyle: {
      fontSize: 24
    },
    textFieldStyle: {
      fontSize: 18
    }
  },

  emptyCard2: {
    position: "relative",
    height: 20,
    background: "#FFFFFF"
  },

  emptyCard3: {
    position: "relative",
    height: 5.66,
    background: "#FFFFFF"
  }
}));

//the state for the video information
const VideoUpload = () => {
  //TODO assume the booking id is known
  const booking_id = "1";

  const [form, setForm] = React.useState({
    title: "",
    description: "",
    file: null
  });

  function handleChange(event) {
    const inputValue =
      event.target.name === "file" ? event.target.files[0] : event.target.value;
    setForm({
      ...form,
      [event.target.name]: inputValue
    });
  }

  const classes = useStyles();

  return (
    <div className={classes.root} align="center">
      <div align="center">
        <Grid container xs={9} justify-content="center" align-content="center">
          <Grid item xs={12}>
            <div className={classes.emptyCard} />
          </Grid>
          <Grid item xs={12}>
            <p className={classes.headingStyle}>UPLOAD VIDEO TO YOUTUBE</p>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.emptyCard2} />
          </Grid>
        </Grid>

        <Grid>
          <form
            form
            method="POST"
            // {/* TODO: value should be booking id associated with the given lesson
            // Should probably not be a hidden input either */}
            action={"api/uploadVideoToYouTube/" + booking_id}
            enctype="multipart/form-data"
          >
            {/* // title */}
            <Grid
              md={6}
              xs={9}
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
            >
              <Grid item md={2} xs={12}>
                <div align="left" className={classes.textFieldStyle}>
                  TITLE&nbsp;:
                </div>
              </Grid>
              <Grid item md={10} xs={12}>
                <div>
                  <input
                    onChange={handleChange}
                    type={"text"}
                    name={"title"}
                    autoComplete={"off"}
                    placeholder={
                      "RESORTer - {student name} - {lesson type} - {scheduled date}"
                    }
                    fullWidth
                    id="cardname"
                    variant="filled"
                    className={classes.textField}
                    size="small"
                  />
                </div>
              </Grid>
            </Grid>
            <div className={classes.emptyCard2} />

            {/* // description */}
            <Grid
              md={6}
              xs={9}
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
            >
              <Grid item md={2} xs={12}>
                <div align="left" className={classes.textFieldStyle}>
                  DESCRIPTION&nbsp;:
                </div>
              </Grid>

              <Grid item md={10} xs={12}>
                <div>
                  <input
                    onChange={handleChange}
                    type={"text"}
                    name={"description"}
                    autoComplete={"off"}
                    fullWidth
                    id="cardname"
                    variant="filled"
                    className={classes.textField1}
                    size="small"
                  />
                </div>
              </Grid>
            </Grid>

            <div className={classes.emptyCard2} />

            {/* // video */}
            <Grid md={6} xs={9} container direction="row" justify="center">
              <Grid item md={2} xs={12} backgroundcolor="red">
                <div align="left" className={classes.textFieldStyle}>
                  VIDEO&nbsp;:
                </div>
              </Grid>

              <Grid item md={8} xs={12} backgroundcolor="blue">
                <div>
                  <input
                    textAlign="left"
                    id="cardname"
                    variant="filled"
                    className={classes.textField2}
                  />
                </div>
              </Grid>
              <Grid item md={2} xs={12} />
              <Grid
                item
                md={2}
                xs={12}
                justify="center"
                backgroundcolor="green"
              >
                <input
                  onChange={handleChange}
                  accept={"video/*"}
                  type={"file"}
                  name={"file"}
                  placeholder={"add video"}
                />
              </Grid>
            </Grid>

            <div className={classes.emptyCard2} />

            {/* // agreement */}
            <Grid md={6} xs={9} container direction="row" justify="left">
              <Grid item md={2} xs={12} />
              <Grid item md={1} xs={2}>
                <ThemeProvider theme={checkboxTheme}>
                  <Checkbox
                    required="required"
                    icon={<span className={classes.checkBoxStyle} />}
                    inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                  />
                </ThemeProvider>
              </Grid>

              <Grid item md={9} xs={8}>
                <div align="left" className={classes.checkTextStyle}>
                  I am happy to make this link available to RESORTer's library
                  of footage for other instructors to use as a resource to teach
                  other students. I am aware the file will be tagged with the
                  appropriate skii defect or feature for easy reference.
                </div>
              </Grid>

              <div className={classes.emptyCard3} />

              <Grid item md={2} xs={12} />

              <Grid item md={1} xs={2}>
                <ThemeProvider theme={checkboxTheme}>
                  <Checkbox
                    required="required"
                    icon={<span className={classes.checkBoxStyle} />}
                    inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                  />
                </ThemeProvider>
              </Grid>

              <Grid item md={9} xs={8}>
                <div align="left" className={classes.checkTextStyle}>
                  I am aware that my video will be accessible via shared link
                  which RESORTer is storing for above mentioned usage.
                </div>
              </Grid>
            </Grid>
            <div className={classes.emptyCard2} />

            {/* // more details */}
            <Grid md={6} xs={6} container direction="row" justify="center">
              <Grid item md={2} xs={2} />

              <Grid
                item
                md={2}
                xs={12}
                className={classes.moreDetailItemFormat}
              >
                <div align="left">
                  <a
                    href="link"
                    target="_blank"
                    className={classes.moreDetailFormat}
                  >
                    More Details
                  </a>
                </div>
              </Grid>
              <Grid item md={8} xs={8} />
            </Grid>
            <div className={classes.emptyCard2} />

            {/* // upload */}
            <Grid md={6} xs={6} container direction="row" justify="center">
              <Grid item md={12} xs={12} />

              <Grid item md={10} xs={10} />
              <Grid item md={2} xs={2}>
                <Button
                  type={"submit"}
                  color="primary"
                  variant="contained"
                  className={classes.btnbot}
                >
                  Upload
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </div>
    </div>
  );
};

export default VideoUpload;
