import React, { useState, useRef } from "react";
import { connect } from "react-redux";

import _ from "lodash";
import {
  calculateEndDate,
  listAllDates,
  formatAMPM,
  calculateSession
} from "./reusable/LessonTimeCalculator";
import { ADAPTIVE_LESSON_HEADING } from "./constant/lessonHeading";
import { monthNames } from "./constant/month";
import { FIRST_TIMER_LESSON_COLOR } from "./constant/color";
import { GROUP, PRIVATE } from "./constant/lessonType";
import EditPopup from "./EditPopup";
import { ADAPTIVE_LESSON_COLOR } from "./constant/color";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";

import {
  deleteTripLesson,
  changeNewLessonEffect
} from "../../../../redux/actions/tripLessonActions";
import { marshallAgeGroup } from "./reusable/DataMapper";
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 300,
    borderRadius: "10px",
    padding: "5px",
    backgroundColor: theme.palette.background.paper,
    border: props => (props.tripLesson.newlesson ? "2px solid blue" : "none")
  },
  lessonHeading: {
    fontSize: "1rem"
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  ageColor: {
    backgroundColor: "#e5dfe6",
    fontWeight: "bold"
  },
  chipColor: {
    backgroundColor: props => props.chipColor,
    fontWeight: "bold"
  },
  firstTimerChip: {
    fontWeight: "bold"
  },
  info: {
    color: "#9b9b9b"
  },
  divider: {
    height: 3,
    marginRight: 0,
    marginLeft: 0
  },
  participantSection: {
    // margin: theme.spacing(0, 1.2)
  },
  participantName: {
    fontWeight: "normal",
    fontSize: 12
  },
  participantHeading: {
    fontSize: "0.8rem",
    fontWeight: 500
  },
  buttonSection: {
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    textAlign: "right"
  },
  button: {
    borderRadius: 16,
    margin: theme.spacing(0.5),
    backgroundColor: props => props.buttonColor
  },
  buttonText: {
    fontSize: 11,
    fontWeight: "bold"
  },
  popover: {
    pointerEvents: "none"
  },
  warning: {
    width: 200,
    height: 130,
    padding: theme.spacing(1)
  }
}));

const LessonCard = props => {
  const classes = useStyles(props, tripLesson);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);

  let isAdaptive;
  let dates = "";
  let session = "";
  let instructor = "Assigned ";

  const {
    tripLesson, // a single lesson object passed from parent
    lessonHeading,
    activityType,
    chipType,
    familyMembers // from redux
  } = props;

  const {
    durationHours,
    durationDays, // null/0 if private
    flexibleDays, // null/0 if private
    firstTimer,
    tripLessonId,
    participants,
    startDate,
    startTime,
    ageGroup,
    instructorDetails,
    selectedInstructorOption,
    lessonType,
    newlesson
  } = tripLesson;

  const date = startDate.getDate();
  const month = monthNames[startDate.getMonth()];
  const { endDate, endDay, endMonth } = calculateEndDate(
    startDate,
    durationDays
  );

  if (selectedInstructorOption === "2") {
    instructor = instructorDetails;
  } else {
    if (instructorDetails) {
      instructor = instructor + instructorDetails;
    }
  }

  // if flexibleDays is not null or 0, then render (xx of xx days)
  if (flexibleDays) {
    dates = `${date} ${month} - ${endDay} ${endMonth} (${durationDays} of ${flexibleDays} days)`;
  } else {
    // else list each single day
    const allDates = listAllDates(startDate, endDate);

    if (allDates.length === 1) {
      const day = allDates[0].getDate();
      const month = monthNames[allDates[0].getMonth()];
      dates = `${day} ${month}`;
    } else {
      allDates.reduce((previousDate, currentDate) => {
        const previousMonth = monthNames[previousDate.getMonth()];
        const currentMonth = monthNames[currentDate.getMonth()];
        const previousDay = previousDate.getDate();

        if (currentMonth === previousMonth) {
          dates += `${previousDay}, `;
        } else {
          dates += `${previousDay} ${previousMonth}, `;
        }

        return currentDate;
      });

      const lastDay = allDates[allDates.length - 1].getDate();
      const lastMonth = monthNames[allDates[allDates.length - 1].getMonth()];
      dates = dates.slice(0, -2) + `, ${lastDay} ${lastMonth}`;
    }
  }
  session = calculateSession(startTime, durationHours);
  const handleEditClick = () => {
    setPopupOpen(true);
  };

  const handleEditClose = () => {
    setPopupOpen(false);
  };

  const handleDeleteClick = () => {
    props.deleteTripLesson(tripLessonId);
  };

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // when hover on the delete button, anchorEl will not be null -> true
  // when the card is a first timer lesson -> true
  const open = Boolean(anchorEl && firstTimer);

  let chip = null;
  if (lessonHeading === ADAPTIVE_LESSON_HEADING) {
    isAdaptive = true;
    if (firstTimer) {
      chip = (
        <Chip
          label="First Timer"
          size="small"
          style={{ backgroundColor: FIRST_TIMER_LESSON_COLOR }}
          className={classes.firstTimerChip}
        />
      );
    }
  } else {
    isAdaptive = false;
    chip = <Chip label={chipType} size="small" className={classes.chipColor} />;
  }

  let ageDisplay;
  if (lessonType !== "private") {
    ageDisplay = marshallAgeGroup[ageGroup];
  } else {
    ageDisplay = ageGroup;
  }

  const clickListener = () => {
    props.changeNewLessonEffect();
  };

  return (
    <div className={classes.root}>
      {newlesson ? document.addEventListener("click", clickListener) : null}
      <div className={classes.infoSection}>
        <Grid container alignItems="center" style={{ marginBottom: "5px" }}>
          <Grid item xs>
            <Typography classes={{ h6: classes.lessonHeading }} variant="h6">
              {activityType}
            </Typography>
          </Grid>
          <Grid item style={{ marginRight: "8px" }}>
            <Chip
              label={ageDisplay}
              size="small"
              className={classes.ageColor}
            />
          </Grid>
          <Grid item>{chip}</Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.info} variant="caption">
            Dates: {dates}
          </Typography>
        </Grid>
        <Grid item x={12}>
          <Typography className={classes.info} variant="caption">
            Session: {session}
          </Typography>
        </Grid>
        {lessonType === "private" ? (
          <Grid item x={12}>
            <Typography className={classes.info} variant="caption">
              Instructor: {instructor}
            </Typography>
          </Grid>
        ) : null}
      </div>

      <Divider classes={{ middle: classes.divider }} variant="middle" />

      <div className={classes.participantSection}>
        <Typography
          classes={{ body2: classes.participantHeading }}
          gutterBottom
          variant="body2"
        >
          Participants
        </Typography>
        <div>
          {participants.map(id => {
            // TODO: get participants' names through their ids
            const name = familyMembers[id].FirstName;

            return (
              <Chip
                className={classes.chip}
                classes={{ label: classes.participantName }}
                label={name}
                size="small"
                key={id}
              />
            );
          })}
        </div>
      </div>

      <div className={classes.buttonSection}>
        {popupOpen ? (
          <EditPopup
            isAdaptive={isAdaptive}
            tripLessonId={tripLessonId}
            isFirstTimer={firstTimer}
            groupLessonSessionDuration={session}
            open={popupOpen}
            onClose={handleEditClose}
          />
        ) : null}
        <Button
          variant="contained"
          size="small"
          classes={{ label: classes.buttonText }}
          className={classes.button}
          onClick={handleEditClick}
        >
          Edit
        </Button>
        <div
          style={{
            display: "inline-block",
            cursor: open ? "not-allowed" : "auto"
          }}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <Button
            variant="contained"
            size="small"
            classes={{ label: classes.buttonText }}
            className={classes.button}
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            disabled={open}
            onClick={handleDeleteClick}
          >
            Delete
          </Button>
        </div>

        <Popover
          className={classes.popover}
          id="mouse-over-popover"
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography className={classes.warning}>
            This is a mandatory lesson, we recommend you attending the lesson
            since you are a first timer on {activityType}
          </Typography>
        </Popover>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    familyMembers: state.tripWhoReducer.familyMembers,
    tripLessons: state.tripLessonReducer.tripLessons
  };
}

export default connect(
  mapStateToProps,
  { deleteTripLesson, changeNewLessonEffect }
)(LessonCard);
