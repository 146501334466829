import React, { Component } from "react";
import styled from "styled-components";

import Fade from "react-reveal/Fade";
import {
  media_lg_min,
  media_md_min,
  media_sm_min
} from "../../../styling/styled-components/dimension-themes";

const BASE_FONT_SIZE = 1;
const FONT_INCREMENT = 0.2;
const FONT_RATIO = 1.5;

export const StyledMedText = styled.div`
  color: white;
  text-align: center;
  font-size: ${FONT_RATIO * BASE_FONT_SIZE}rem;
  @media (min-width: ${media_sm_min}) {
    font-size: ${FONT_RATIO * BASE_FONT_SIZE}rem;
  }
  @media (min-width: ${media_sm_min}) {
    font-size: ${FONT_RATIO * BASE_FONT_SIZE + FONT_INCREMENT}rem;
  }
  @media (min-width: ${media_md_min}) {
    font-size: ${FONT_RATIO * BASE_FONT_SIZE + 2 * FONT_INCREMENT}rem;
  }
  @media (min-width: ${media_lg_min}) {
    font-size: ${FONT_RATIO * BASE_FONT_SIZE + 3 * FONT_INCREMENT}rem;
  }
`;

export const StyledSmallText = styled.span`
  color: white;
  text-align: center;
  margin-bottom: 19px;
  @media (min-width: ${media_sm_min}) {
    font-size: ${BASE_FONT_SIZE + FONT_INCREMENT}rem;
  }
  @media (min-width: ${media_md_min}) {
    font-size: ${BASE_FONT_SIZE + 2 * FONT_INCREMENT}rem;
  }
  @media (min-width: ${media_lg_min}) {
    font-size: ${BASE_FONT_SIZE + 3 * FONT_INCREMENT}rem;
  }
`;

const DELAY = 1000;

class Slider extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="text-center">
          <Fade duration={DELAY}>
            <StyledMedText>
              The easiest way to book ski and snowboard lessons
            </StyledMedText>
          </Fade>
          <Fade delay={DELAY} duration={DELAY}>
            <StyledSmallText>
              Make new ski and snowboard buddies in a group lesson
            </StyledSmallText>
          </Fade>
          <Fade delay={DELAY * 2} duration={DELAY}>
            <StyledSmallText>
              The hassle-free way to book group AND private lessons
            </StyledSmallText>
          </Fade>
        </div>
      </React.Fragment>
    );
  }
}

export default Slider;
