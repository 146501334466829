import { Avatar } from "@material-ui/core";
import {
  UPDATE_AGE,
  UPDATE_GENDER,
  UPDATE_PHYSICAL_DISABILITY,
  UPDATE_LANGUAGES,
  UPDATE_YEARS_OF_EXPERIENCE,
  UPDATE_ABILITY,
  UPDATE_AVATAR,
  UPDATE_NAME,
  UPDATE_RATING,
  UPDATE_BIO,
  UPDATE_STU_PROFILE
} from "./action-types";

// Update Profil Page
export const updateStuProfile = data => {
  return {
    type: UPDATE_STU_PROFILE,
    payload: data
  };
};

// Age action
export const updateAge = age => {
  return {
    type: UPDATE_AGE,
    payload: age
  };
};

// Gender action
export const updateGender = gender => {
  return {
    type: UPDATE_GENDER,
    payload: gender
  };
};

// PhysicalDisability action
export const updatePhysicalDisability = physicalDisability => {
  return {
    type: UPDATE_PHYSICAL_DISABILITY,
    payload: physicalDisability
  };
};

// Languages action
export const updateLanguages = languages => {
  return {
    type: UPDATE_LANGUAGES,
    payload: languages
  };
};

//Years of Experience  action
export const updateYearsOfExperience = years => {
  return {
    type: UPDATE_YEARS_OF_EXPERIENCE,
    payload: years
  };
};

// Ability action
export const updateAbility = ability => {
  return {
    type: UPDATE_ABILITY,
    payload: ability
  };
};
// Avatar action
export const updateAvatar = avatar => {
  return {
    type: UPDATE_AVATAR,
    payload: avatar
  };
};
// Name action
export const updateName = name => {
  return {
    type: UPDATE_NAME,
    payload: name
  };
};
// Rating action
export const updateRating = rating => {
  return {
    type: UPDATE_RATING,
    payload: rating
  };
};

// Bio action
export const updateBio = bio => {
  return {
    type: UPDATE_BIO,
    payload: bio
  };
};
