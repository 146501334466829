import React, { useRef } from "react";

import {
  GROUP,
  PRIVATE,
  FIRST_TIMER_GROUP,
  FIRST_TIMER_PRIVATE
} from "./constant/lessonType";
import { GROUP_LESSON_COLOR, PRIVATE_LESSON_COLOR } from "./constant/color";
import LessonCard from "./LessonCard";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ExplanationCard from "./ExplanationCard";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles(theme => ({
  lessonRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap"
  },
  lessonWrapper: {
    width: "100%",
    overflow: "auto"
  },
  activityChip: {
    fontWeight: "bold"
  },
  sectionHeading: {
    display: "inline-flex"
  },
  infoBtn: {
    minWidth: "auto",
    paddingTop: 0
  },
  container: {
    backgroundColor: props => props.lessonBackgroundColor
  },
  cardWrapper: {
    margin: theme.spacing(1),
    minWidth: 245,
    maxWidth: 240
  },
  nowrap: {
    flexWrap: "nowrap"
  },
  space: {
    padding: "1px"
  }
}));

const LessonContainer = props => {
  const classes = useStyles(props);
  const childRef = useRef();
  const { id, lessonArray, lessonHeading, lessonBackgroundColor } = props;

  const sortedLessonArray = (lessonArray, activity) => {
    const temp = lessonArray.filter(lesson => lesson.activity === activity);

    //  make sure first timer card comes first
    return temp.sort((lesson1, lesson2) => {
      return lesson1.firstTimer === lesson2.firstTimer
        ? 0
        : lesson1.firstTimer
        ? -1
        : 1;
    });
  };

  const skiLessonArray = sortedLessonArray(lessonArray, "ski");
  const snowboardLessonArray = sortedLessonArray(lessonArray, "snowboard");

  const handleInfoOpen = () => {
    childRef.current.handleOpen();
  };

  const getChipTypeColor = lessonType => {
    let chipType, chipColor;

    if (lessonType.toLowerCase() === GROUP.toLowerCase()) {
      chipType = "Group";
      chipColor = GROUP_LESSON_COLOR;
    } else if (lessonType.toLowerCase() === PRIVATE.toLowerCase()) {
      chipType = "Private";
      chipColor = PRIVATE_LESSON_COLOR;
    }

    return [chipType, chipColor];
  };

  return (
    <div>
      <Typography gutterBottom variant="h6" className={classes.sectionHeading}>
        {lessonHeading}
      </Typography>
      <ExplanationCard
        ref={childRef}
        lessonHeading={lessonHeading}
        lessonBackgroundColor={lessonBackgroundColor}
      />
      <Button size="small" onClick={handleInfoOpen} className={classes.infoBtn}>
        <i className="fas fa-info-circle" />
      </Button>

      {/** only have the wordings when it is first timer section */}
      {id === 1 && (
        <Typography gutterBottom variant="subtitle2">
          You must do at least either a group or private lesson - edit your
          preference
        </Typography>
      )}

      <Grid container className={classes.container}>
        <Grid container>
          {/* ski lesson row and snowboard lesson row*/}
          {[
            { activity: "SKI", array: skiLessonArray },
            { activity: "SNB", array: snowboardLessonArray }
          ].map(lessonRow => (
            <Box
              className={classes.lessonRow}
              key={lessonRow.activity}
              className={classes.lessonWrapper}
            >
              <Grid container>
                <Grid
                  container
                  item
                  md={1}
                  xs={2}
                  justify="center"
                  alignItems="center"
                  className={classes.space}
                >
                  {lessonRow.array.length > 0 ? (
                    <Chip
                      size="small"
                      label={lessonRow.activity}
                      variant="outlined"
                      className={classes.activityChip}
                    />
                  ) : null}
                </Grid>
                <Grid item container md={11} xs={10} className={classes.nowrap}>
                  {lessonRow.array.map(lesson => {
                    const [chipType, chipColor] = getChipTypeColor(
                      lesson.lessonType
                    );

                    return (
                      <Box
                        className={classes.cardWrapper}
                        key={lesson.tripLessonId}
                      >
                        <LessonCard
                          tripLesson={lesson}
                          lessonHeading={lessonHeading}
                          activityType={lessonRow.activity}
                          buttonColor={lessonBackgroundColor}
                          chipType={chipType}
                          chipColor={chipColor}
                        />
                      </Box>
                    );
                  })}
                </Grid>
              </Grid>
            </Box>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default LessonContainer;
