import React, { useState, useRef, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, TextField, Button } from "@material-ui/core";
import { FONTFAMILY } from "../../../styling/theme/Theme";
import { instance } from "../../../agent";
// import { Field, reduxForm } from "redux-form";

const useStyles = makeStyles(theme => ({
  title: {
    margin: "2rem 0rem",
    fontSize: 26,
    fontWeight: "400",
    fontFamily: FONTFAMILY.FONT_SANS_NARROW,
    color: "#000000"
  },
  textFields: {
    width: "15em",
    "& .MuiOutlinedInput-root": {
      // - The Input-root, inside the TextField-root
      "& fieldset": {
        // - The <fieldset> inside the Input-root
        borderColor: "black" // - Set the Input border
      },
      "&:hover fieldset": {
        borderColor: "black" // - Set the Input border when parent has :hover
      },
      "&.Mui-focused fieldset": {
        // - Set the Input border when parent is focused
        borderColor: "black"
      }
    }
    // height: "4em",
    // size:"small",
    // borderRadius: "40px",
    // background: "blue",

    // [theme.breakpoints.down("md")]: {
    //   width: "25em",
    //   marginLeft: "3em"
    // },
    // [theme.breakpoints.down("xs")]: {
    //   width: "15em",
    //   marginLeft: "3em",
    //   marginRight: "3em"
    // },
  },
  loginBtn: {
    borderRadius: 100,
    width: "14em",
    height: "3em",
    margin: "2em",
    [theme.breakpoints.down("sm")]: {
      width: "2em",
      height: "3em",
      margin: "1rem 0rem"
    },
    backgroundColor: "rgb(255,97,97)",
    "&:hover": {
      backgroundColor: "rgb(255,97,97)"
    },
    boxShadow:
      "rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px",
    [theme.breakpoints.down("lg")]: {
      width: "15em"
    }
  },
  btnLabel: {
    color: "white",
    fontFamily: "PT Sans Narrow",
    fontWeight: "bold",
    textTransform: "none"
  },
  errorMsg: {
    textAlign: "center",
    padding: "5em",
    marginBottom: "5em",
    color: "#ff0033",
    padding: "15px 10px",
    borderRadius: "10px"
  }
}));
function PasswordReset() {
  const classes = useStyles();
  const ref = useRef(null);
  const [email, setEmail] = useState("");
  const [displayMessage, setdisplayMessage] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    if (!email) {
      setErrors({ requiredEmail: "*Required" });
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setErrors({ emailFormat: "Please enter valid email!" });
    } else {
      setLoading(true);
      await instance({
        url: "/auth/reset-password-email-send/" + email,
        method: "GET"
      })
        .then(res => {
          setErrors({});
          setLoading(false);
          setdisplayMessage(true);
        })
        .catch(e => {
          setLoading(false);
          setErrors({ requiredEmail: "This email has not registered." });
        });
    }
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current) {
        // setvisible(false)
        if (!email) {
          setErrors({ requiredEmail: "*Required" });
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
          setErrors({ emailFormat: "Please enter valid email!" });
        } else {
          setErrors({});
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [email]);

  return (
    <div ref={ref}>
      <Grid container justify="center" alignItems="center" align="center">
        <Grid item sm={12} xs={12}>
          <Typography align="center" className={classes.title}>
            Reset your password
          </Typography>
        </Grid>
        {loading && (
          <Grid
            container
            justify="center"
            alignItems="center"
            spacing={0}
            direction="column"
          >
            <Grid
              container
              justify="center"
              alignItems="flex-start"
              xs={12}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              direction="row"
              style={{ backgroundColor: "white", padding: "2rem 0px" }}
            >
              <div className="loader-container">
                <div className="loader" />
              </div>
            </Grid>
          </Grid>
        )}

        {!loading && displayMessage && (
          <div>
            <Typography className={classes.errorMsg}>
              Please check you Email. <br />
              Click link on your inbox.
            </Typography>
          </div>
        )}

        {!loading && !displayMessage && (
          <div>
            <Grid>
              <Grid align="center">
                <Typography> Enter your sign in email:</Typography>
              </Grid>
              <Grid
                item
                md={12}
                style={{ margin: "1rem 0rem", alignItems: "center" }}
              >
                <TextField
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value);
                  }}
                  variant="outlined"
                  size="small"
                  className={classes.textFields}
                />
                <div className={classes.errorMsg}>
                  {errors.requiredEmail || errors.emailFormat}
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                className={classes.loginBtn}
                classes={{ label: classes.btnLabel }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Grid>
          </div>
        )}
      </Grid>
    </div>
  );
}

// PasswordReset = reduxForm({
//     form: "password-reset",
//     destroyOnUnmount: false,
//     // validate
//   })(PasswordReset);
export default PasswordReset;
