import React from "react";

import Avatar from "@material-ui/core/Avatar";

//icons
import English from "../../materials/flags/english.png";
import Japan from "../../materials/flags/japanese.png";
import French from "../../materials/flags/french.png";
import Germany from "../../materials/flags/german.png";
import Russia from "../../materials/flags/russia.png";
import China from "../../materials/flags/chinese.png";
import Spain from "../../materials/flags/spain.png";

const Flags = props => {
  const { language } = props;
  if (typeof language === "undefined") {
    return <div />;
  }
  let languageIcon = "";
  try {
    language.toLowerCase();
  } catch (e) {
    console.log(language, e);
  }
  switch (language.toLowerCase()) {
    case "english":
      languageIcon = English;
      break;
    case "japanese":
      languageIcon = Japan;
      break;
    case "french":
      languageIcon = French;
      break;
    case "german":
      languageIcon = Germany;
      break;
    case "russian":
      languageIcon = Russia;
      break;
    case "mandarin":
      languageIcon = China;
      break;
    case "spanish":
      languageIcon = Spain;
      break;
    default:
      languageIcon = English;
  }

  return <Avatar src={languageIcon} />;
};

export default Flags;
