import React, { useImperativeHandle, forwardRef, theme } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  content: {
    backgroundColor: props => props.lessonBackgroundColor
  }
}));

const ExplanationCard = forwardRef((props, ref) => {
  const theme = useTheme();
  const classes = useStyles(props);
  const { lessonHeading, lessonBackgroundColor } = props;
  const [open, setOpen] = React.useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  let hasDisabledMember = false;
  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
    }
  }));

  const handleClose = () => {
    setOpen(false);
  };

  if (lessonHeading === "Group Lesson") {
    // for (let entry of Object.entries(familyMembers)) {
    //   const value = entry[1];
    //   if (value.IsDisabled) {
    //     hasDisabledMember = true;
    //     break;
    //   }
    // }
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent className={classes.content}>
        {lessonHeading === "Group Lesson" ? (
          <React.Fragment>
            <span className="fa-dot-circle">
              {" "}
              AM lessons usually start around 10am; PM lessons usually start
              around 1.30pm.
            </span>
            <br />
            <span style={{ fontSize: "small" }}>* Enquire with resort</span>
            <br />
            <span className="fa-dot-circle">
              {" "}
              Resorts aim to make times for group lessons compatible with adult
              group lessons times to allow parents enough time to drop off and
              pick up children.
            </span>
            {hasDisabledMember && (
              <React.Fragment>
                <br />
                <span className=" fa-dot-circle">
                  NB. Those with special adaptive needs will not show in the
                  participants' column because it is recommended to do an
                  especially tailored adaptive private which is usually
                  subsidised.
                </span>
              </React.Fragment>
            )}
          </React.Fragment>
        ) : lessonHeading === "Private Lesson" ? (
          <React.Fragment>
            <span className="fa-dot-circle">
              Everyone in the private lesson must be roughly the same ability
              level.
            </span>
            <br />
            <span className="fa-dot-circle">
              We recommend children 5 years old and under do not attend a
              private lesson with parents and other children 6 years old and
              over. Enquire with resort when they contact you if they will make
              exceptions.
            </span>
            <br />
            <span style={{ fontSize: "small" }}>
              * Activities & time are subject to availability. Confirm with
              resort when they make contact.
            </span>
          </React.Fragment>
        ) : (
          //Explanation card for private adaptive
          <React.Fragment>
            <span className="fa-dot-circle">
              Private adaptive lessons are tailored to members with disabilities
              only.
            </span>
            <br />
            <span style={{ fontSize: "small" }}>
              * Activities & time are subject to availability. Confirm with
              resort when they make contact.
            </span>
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions className={classes.content}>
        <Button autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
});
export default ExplanationCard;
