import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Collapse from "@material-ui/core/Collapse";
import ReactPlayer from "react-player";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import { useHistory } from "react-router-dom";
import agent from "../../../agent";

const useStyles = makeStyles({
  root: {
    minWidth: 200,
    borderBottomStyle: "groove",
    paddingBottom: "6px"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  name: {
    fontFamily: "PT Sans Narrow",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "22.5px",
    textAlign: "center"
  },
  manageChip: {
    backgroundColor: "white",
    textAlign: "left",
    width: "155px",
    height: "40px",

    color: "#1277B8",
    fontFamily: "PT Sans Narrow",
    fontSize: "16px",
    fontWeight: "bold",
    fontStyle: "normal",
    textTransform: "capitalize",
    lineHeight: 0,

    borderStyle: "solid",
    borderColor: "#1277B8",
    borderWidth: "1px",
    borderRadius: "100px"
    //padding: "23px",
  },

  messageChip: {
    backgroundColor: "white",
    textAlign: "left",
    width: "155px",
    height: "40px",

    color: "#1277B8",
    fontFamily: "PT Sans Narrow",
    fontSize: "16px",
    fontWeight: "bold",
    fontStyle: "normal",
    textTransform: "capitalize",
    lineHeight: 0,
    marginLeft: 10,

    borderStyle: "solid",
    borderColor: "#1277B8",
    borderWidth: "1px",
    borderRadius: "100px"
    //padding: "23px",
  },

  closeChip: {
    marginBottom: 5,
    backgroundColor: "#ff6161",
    color: "white",
    borderRadius: "25px",
    float: "right"
  },
  goMeetingChip: {
    marginBottom: 5,
    backgroundColor: "#FF6161",
    float: "right"
  },
  cancelChip: {
    backgroundColor: "#FF6161",
    marginTop: 5
  },
  price: {
    // fontWeight: "bold",
    alignItems: "center",
    //    paddingTop: "0.4rem",
    fontFamily: "PT Sans Narrow",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "22.5px",
    textAlign: "center"
  },
  rescheduleChip: {
    float: "right",
    backgroundColor: "#FF6161",
    marginTop: 5
  },
  schedule: {
    fontWeight: "bold",
    fontFamily: "PT Sans Narrow",
    fontSize: "16px",
    fontStyle: "normal",
    lineHeight: "22.5px",
    textAlign: "left",
    color: "#606060",
    paddingBottom: "6px",
    display: "inline-flex"
  },
  videoPlayer: {
    maxHeight: 500
  },
  cardContent: {
    padding: "16px 0 0 4px"
  },
  instructorSpan: {
    fontWeight: "bold",
    color: "black"
  }
});

// const [status, setStatus] = React.useState("");
// React.useEffect(() => {
//   updateBookings();
// }, [props.status]);

// const updateBookings = props => {
//   let booking_data = [];
//   if (props.data) booking_data = [...props.data];
//   booking_data = booking_data.filter(booking => booking.user_id === user_id);
//   //const resortObject = [];
//   if (booking_data) {
//     setStatus(booking_data.status);
//   } else {
//     setStatus([]);
//   }
// };

export const reformatDate = (start, end) => {
  const year = start.getFullYear();
  const month = start.toLocaleString("default", { month: "long" });
  const day = start.getDate();
  const hourStart = start.getHours();
  const minuteStart = start.getMinutes();
  const hourStartFormatted = hourStart % 12 || 12; // hour returned in 24 hour format
  const minuteStartFormatted =
    minuteStart < 10 ? "0" + minuteStart : minuteStart;
  const morningStart = hourStart < 12 ? " AM" : " PM";

  // end time logic:
  const hourEnd = end.getHours();
  const minuteEnd = end.getMinutes();
  const hourEndFormatted = hourEnd % 12 || 12; // hour returned in 24 hour format
  const minuteEndFormatted = minuteEnd < 10 ? "0" + minuteEnd : minuteEnd;
  const morningEnd = hourEnd < 12 ? " AM" : " PM";

  return (
    hourStartFormatted +
    ":" +
    minuteStartFormatted +
    morningStart +
    " to " +
    hourEndFormatted +
    ":" +
    minuteEndFormatted +
    morningEnd +
    "," +
    " " +
    day +
    " " +
    month +
    " " +
    year
  );
};

export default function UpcomingVideoLesson(props) {
  const classes = useStyles();
  let history = useHistory();

  const virtualbookingID = props.virtualbookingID;
  const name = props.name;
  const firstName = name.split(" ")[0];
  const price = props.price;
  const start = props.start;
  const end = props.end;
  const videoLink = props.videoLink;
  const meetingLink = props.meetingLink;
  const theOtherGuyUserID = props.theOtherGuyUserID;
  const userType = props.userType;
  // re-format the time and date function
  const schedule = reformatDate(start, end);
  const [status, setStatus] = React.useState(props.status);
  const [expanded, setExpanded] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const instructorUserID = props.instructorUserID;
  const VirtualID = props.VirtualID;

  const handleExpandClick = () => {
    setExpanded(!expanded);
    setAnchorEl(null);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleZoomlink = () => {
    setAnchorEl(null);
    window.open(meetingLink);
  };

  const handleReschedule = () => {
    setAnchorEl(null);
    //TODO the reschedule button is not functional
    //Put the redirect url when the reschedule function is done
    history.push("/");
  };

  const handleCancelBooking = () => {
    if (status !== "Cancelled") {
      agent.VirtualLesson.cancelVirtualBooking("Cancelled", virtualbookingID)
        .then(res => {
          setStatus("Cancelled");
          if (res.status === 200) {
            alert("you have successfully cancelled your booking !");
          }
        })
        .catch(e => console.log(e));
    } else if (status === "Cancelled") {
      alert("The booking is already cancelled!");
    }
  };

  const handleMessageButton = () => {
    if (userType == "student") {
      // make the api request to backend, data format: (studentID,instructorID)
      agent.Message.getOrCreateMessageRoom(
        props.currentUser.id,
        theOtherGuyUserID
      )
        .then(res => {
          const roomID = res.data.room_id;
          history.push("/chatpage/" + roomID);
        })
        .catch(e => console.log(e));
    } else if (userType == "instructor") {
      // when the current user is the instructor
      // make the api request to backend, data format: (studentID,instructorID)
      agent.Message.getOrCreateMessageRoom(
        theOtherGuyUserID,
        props.currentUser.id
      )
        .then(res => {
          const roomID = res.data.room_id;
          history.push("/chatpage/" + roomID);
        })
        .catch(e => console.log(e));
    }
  };

  return (
    <Card elevation={0} className={classes.root}>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={2}>
          {/* Instructor name */}
          <Grid item xs={6} sm={3}>
            <Typography display={"inline"} className={classes.name}>
              <span className={classes.instructorSpan}> INSTRUCTOR </span>
              <br />
              {name}
            </Typography>
          </Grid>

          {/* Lesson price */}
          <Grid item xs={6} sm={2}>
            <Typography display={"inline"} className={classes.price}>
              <span style={{ fontWeight: "bold", color: "black" }}>
                {" "}
                PRICE{" "}
              </span>
              <br />${price}
            </Typography>
          </Grid>

          {/* Lesson information */}
          <Grid
            item
            xs={12}
            sm={9}
            className={classes.schedule}
            container
            spacing={1}
          >
            {/* Lesson schedule */}
            <Grid item xs={12} sm={12}>
              <Typography display={"inline"} className={classes.schedule}>
                Scheduled:{" "}
                <span style={{ fontWeight: "normal" }}>{schedule}</span>
              </Typography>
            </Grid>

            {/* Manage booking section */}
            <Grid item xs={6} sm={5}>
              {/* Manage booking - button */}
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                className={classes.manageChip}
                onClick={handleClick}
              >
                Manage Booking
              </Button>

              {/* Manage booking - select menu*/}
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleReschedule}> Reschedule </MenuItem>
                <MenuItem onClick={handleCancelBooking}>
                  {" "}
                  Cancel Booking{" "}
                </MenuItem>
                <MenuItem onClick={handleZoomlink}> Start Meeting </MenuItem>
                <MenuItem onClick={handleExpandClick}> View Video </MenuItem>
              </Menu>
            </Grid>

            {/* Send message section - only button */}
            <Grid item xs={6} sm={5}>
              <Button
                className={classes.messageChip}
                onClick={handleMessageButton}
              >
                Send Message
              </Button>
            </Grid>
          </Grid>

          <Grid item sm={12}>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <Button
                  className={classes.closeChip}
                  onClick={handleExpandClick}
                >
                  Close
                </Button>
                <ReactPlayer
                  className={classes.videoPlayer}
                  url={videoLink}
                  width="100%"
                  controls
                />
              </CardContent>
            </Collapse>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser
  };
}
