import withStyles from "@material-ui/core/es/styles/withStyles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  mainWrapper: {
    position: "absolute",
    left: 0,
    top: 0
  },
  wrapper: {
    padding: "63px 84px 78px 84px"
  },
  [theme.breakpoints.down("sm")]: {
    wrapper: {
      padding: "30px 35px"
    }
  },
  [theme.breakpoints.down("xs")]: {
    wrapper: {
      padding: "15px 20px"
    }
  },
  hideSearchCard: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      "& .MuiCard-root": {
        margin: "4% 0%"
      }
    }
  }
}));

export default useStyles;
