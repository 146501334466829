// Actions
import {
  INCREMENT_STEPPER,
  DECREMENT_STEPPER,
  INCREMENT_STEPPER_HOST,
  DECREMENT_STEPPER_HOST,
  GET_ACTIVATION_STATUS,
  GET_ERROR_MESSAGE,
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILURE,
  LOAD_USER_DETAILS,
  LOAD_USER_DETAILS_FAILURE,
  GET_PROVIDER_PROFILE_REQUEST,
  GET_PROVIDER_PROFILE_SUCCESS,
  GET_PROVIDER_PROFILE_FAILURE,
  UPDATE_USER_DETAILS_SUCCESS_REQUEST,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAILURE,
  UPDATE_USER_PROFILE_SUCCESS_REQUEST,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILURE,
  INCREMENT_STEPPER_MANUL,
  CREATE_PROVIDER_SERVICES,
  GET_PROVIDER_SERVICES
} from "./../actions/action-types.js";

const stepperReducerDefaultState = {
  activeStep: 0,
  activeStepHost: 0,
  activationStatus: null,
  activationError: null,
  loading: false,
  updateProfileSuccess: false,
  updateProfileError: null,
  updateDetailsSuccess: false,
  updateDetailsError: null,
  createProviderServices: null
};

const stepperReducer = (state = stepperReducerDefaultState, action) => {
  switch (action.type) {
    case INCREMENT_STEPPER:
      return {
        activeStep: state.activeStep + 1
      };
    case INCREMENT_STEPPER_MANUL:
      return {
        activeStep: action.payload
      };
    case DECREMENT_STEPPER:
      return {
        activeStep: state.activeStep - 1
      };
    case INCREMENT_STEPPER_HOST:
      return {
        activeStep: state.activeStepHost + 1
      };
    case DECREMENT_STEPPER_HOST:
      return {
        activeStep: state.activeStepHost - 1
      };
    case GET_ACTIVATION_STATUS: {
      return { ...state, activationStatus: action.payload };
    }
    case GET_ERROR_MESSAGE: {
      return { ...state, activationError: action.payload };
    }
    case UPDATE_USER_PROFILE_SUCCESS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case GET_USER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        userProfile: action.payload,
        getUserProfileError: null
      };
    case GET_USER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        userProfile: null,
        getUserProfileError: action.payload
      };
    case LOAD_USER_DETAILS:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    case LOAD_USER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload
      };
    // case GET_PROVIDER_PROFILE_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // case GET_PROVIDER_PROFILE_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     providerProfile: action.payload,
    //     getProviderProfileError: null
    //   };
    // case GET_PROVIDER_PROFILE_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     providerProfile: null,
    //     getProviderProfileError: action.payload
    //   };
    case UPDATE_USER_PROFILE_SUCCESS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        updateProfileSuccess: action.payload
      };
    case UPDATE_USER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        updateProfileError: action.payload
      };
    case UPDATE_USER_DETAILS_SUCCESS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case UPDATE_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        updateDetailsSuccess: true
      };
    case UPDATE_USER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        updateDetailsError: action.payload
      };
    case CREATE_PROVIDER_SERVICES:
      console.log(action.payload);
      return { ...state, createProviderServices: action.payload };
    default:
      return state;
  }
};

export const load = data => ({ type: LOAD_USER_DETAILS, data });

export default stepperReducer;
