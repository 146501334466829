import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers/rootReducer";
import { composeWithDevTools } from "redux-devtools-extension";
// import {
//   removeTripMember,
//   changeFamilyMemberDOB_Ability_Disability
// } from "./middleware/tripWhoMiddlewares";
// import { changeTripDates } from "./middleware/tripWhenMiddlewares";
// import {
//   changeFirstTimerTimeSlot,
//   checkFirstTimerLessons,
//   mergeParticipantsMiddleware
// } from "./middleware/tripLessonMiddlewares";
// import { changeMasterDOB_Ability_Disability } from "./middleware/tripProfileMiddlewares";
// import { activityMemberChange } from "./middleware/tripActivityMiddlewares";
import thunk from "redux-thunk";

const noDevTool = applyMiddleware(
  thunk,
  // changeTripDates,
  // removeTripMember,
  // changeFirstTimerTimeSlot,
  // checkFirstTimerLessons,
  // changeMasterDOB_Ability_Disability,
  // changeFamilyMemberDOB_Ability_Disability,
  // activityMemberChange,
  // mergeParticipantsMiddleware
);

const composeEnhancers =
  process.env.REACT_APP_NODE_ENV === "production"
    ? noDevTool
    : composeWithDevTools({ trace: true, traceLimit: 25 })(noDevTool);

const store = createStore(rootReducer, composeEnhancers);

export default store;
