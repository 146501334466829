import axios from "axios";

import { baseURL } from "./redux/actions/action-types";

//changes the api path slightly depending on amount of extra path names
let api_backtrack = window.location.pathname.split("/").length - 2;
let api_relative_path = "";
while (api_backtrack > 0) {
  api_relative_path += "../";
  api_backtrack--;
}
api_relative_path = api_relative_path + baseURL;

//instantiate axios instance
export const instance = axios.create({ baseURL: api_relative_path });

const setAccess = access => {
  if (access != null) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + access;
    instance.defaults.headers.common["Authorization"] = "Bearer " + access;
  } else {
    delete axios.defaults.headers.common["Authorization"];
    delete instance.defaults.headers.common["Authorization"];
  }
};

const Users = {
  currentUser: () => {
    return instance
      .get("/users/current-user")
      .catch(error => console.log(error));
  },
  login: (email, password) => {
    return instance.post("/auth/user-login", {
      email: email,
      password: password
    });
  },
  logout: () => {
    delete axios.defaults.headers.common["Authorization"];
    delete instance.defaults.headers.common["Authorization"];
    return instance.put("/auth/user-logout", {});
  },
  register: (email, password, is_provider = false, signup_step) => {
    return instance.post("/auth/user-create", {
      email,
      password,
      is_provider,
      signup_step
    });
  },
  getClientProfile: async userId => {
    return await instance.get(`/auth/client-get/${userId}`);
  },
  getClientProfileImage: async userId => {
    return await instance.get(`/auth/profileimage-get/${userId}`);
  },
  getProviderProfile: userId => {
    return instance.get(`/auth/provider-get/${userId}`);
  },
  getProviderProfileImage: userId => {
    return instance.get(`/auth/profileimage-get/${userId}`);
  },
  getProviderServices: provider_id => {
    return instance.get(`/auth/providerservice-get/${provider_id}`);
  },
  getProviderReviewRating: provider_id=>
  {
    return instance.get(`/booking/review-rating-list/${provider_id}`);
  },
  getProviderRating: user_id=>
  {
    return instance.get(`/booking/provider-rating-get/${user_id}`);
  },
  getGallery: userId => {
    return instance.get(`/gallery-list/${userId}`);
  },
  uploadProfileImage: (userId, profile_picture) => {
    return instance.put(
      `/auth/profileimage-upload/${userId}`,
      profile_picture,
      {
        headers: {
          content_type:
            "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
        }
      }
    );
  },
  listProviders: resortID => {
    return instance.get(`/auth/provider-list/${resortID}`);
  },
  listGallery: userId => {
    return instance.get(`/auth/gallery-list/${userId}`);
  },
  uploadGalleryImage: image => {
    return instance.post(`/auth/gallery-create`, image, {
      headers: {
        content_type:
          "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
      }
    });
  },
  deleteGalleryImage: imageId => {
    return instance.delete(`/auth/gallery-update/${imageId}`);
  },
  updateGalleryImage: (imageId, image) => {
    return instance.put(`/auth/gallery-update/${imageId}`, image, {
      headers: {
        content_type:
          "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
      }
    });
  },
  updateUser: (
    userId,
    bio,
    country,
    languages,
    skiing_level,
    snowboarding_level,
    signup_step
  ) => {
    return instance.patch(`/auth/user-update/${userId}`, {
      bio,
      country,
      languages,
      skiing_level,
      snowboarding_level,
      signup_step
    });
  },
  updateSignupStep: (
    userId,
    signup_step
  ) => {
    return instance.patch(`/auth/user-update/${userId}`, {
      signup_step
    });
  },
  updateUserInfo: (
    userId,
    firstName,
    lastName,
    gender,
    dob,
    mobileNumber,
    signupStep
  ) => {
    return instance.put(`/auth/user-info-update/${userId}`, {
      first_name: firstName,
      last_name: lastName,
      gender: gender,
      dob: dob,
      mobile_number: mobileNumber,
      signup_step: signupStep
    });
  },
  updateProvider: (
    userId,
    user,
    provider_bio,
    resorts,
    suitable_fors,
    ) => {
      return instance.put(`/auth/provider-update/${userId}`, {
        user,
        provider_bio,
        resorts,
        suitable_fors
      });
    }
};

const Members = {
  addMembers: profile => {
    return instance.get("members/addMembers", profile);
  }
};

const Instructors = {
  getAll: () => {
    return instance.get("/instructor/");
  },
  instructor: instructorId => {
    console.log(instance.defaults.headers.common["Authorization"]);
    return instance.get("/instructor/" + instructorId);
  },
  create: profile => {
    return instance.post("/instructor/create", profile);
  },
  getInstructor: id => {
    return instance.get(`/instructor/${id}`);
  }
};

const Students = {
  getStudent: id => {
    return instance.get(`/student/getStudent/${id}`);
  }
};

const Lessons = {
  privateLessons: resortId => {
    return instance.get("/lessons/private-lessons/" + resortId);
  },

  groupLessons: resortId => {
    return instance.get("/lessons/group-lessons/" + resortId);
  },

  allPrivateLessons: () => {
    return instance.get("/lessons/private-lessons");
  },

  allGroupLessons: () => {
    return instance.get("/lessons/group-lessons");
  },

  charge: () => {
    return instance.get("/lessons/charge");
  }
};

const Resorts = {
  getCountriesAndResorts: () => {
    return instance.get("/getCountriesAndResorts");
  }
};

const VirtualLesson = {
  getVirtualLesson: userID => {
    return instance.get("getVirtualLesson/" + userID);
  },
  cancelVirtualBooking: (status, virtualbookingID) => {
    return instance.post("virtualBooking/cancel", {
      status: status,
      id: virtualbookingID
    });
  },
  sendFeedbackEmail: () => {
    return instance.get("/booking/RateEmail/virtualLesson");
  }
};

const Message = {
  getOrCreateMessageRoom: (student_user_id, instructor_user_id) => {
    return instance.post("messagingRoom/getID", {
      student_user_id: student_user_id,
      instructor_user_id: instructor_user_id
    });
  },
  addMessage: (room_id, messageData, sender_user_id) => {
    return instance.post("messagingRoom/createMessage/" + room_id, {
      messageData: messageData,
      sender_user_id: sender_user_id
    });
  },
  getMessageRoomInfo: roomID => {
    return instance.get("messagingRoom/getRoomInfo/" + roomID);
  },
  getInboxDataset: userID => {
    return instance.get("messagingRoom/getInboxDataset/" + userID);
  }
};

const Trips = {
  postTrip: trip => {
    return instance.post("/addNewTrip", trip);
  },
  pastTrip: params => {
    return instance.get("/getPreviousTrip", params);
  },
  getTripByTripId: (member_id, trip_id) => {
    return instance.get(
      `/members/${member_id}/trips/${trip_id}/submissionEmail`
    );
  },
  putTripByTripId: (member_id, trip_id, request) => {
    return instance.put(
      `/members/${member_id}/trips/${trip_id}/submissionEmail`,
      request
    );
  }
};

const Votes = {
  getVotes: () => {
    return instance.get("/votes");
  },
  putVotes: (id, req) => {
    return instance.put(`/votes/${id}`, req);
  }
};

const Constants = {
  getAllLanguages: () => {
    return instance.get("/language-list");
  },
  getAllSpecialisations: () => {
    return instance.get("/specialisations/getAll");
  },
  getSuitableforList: () => {
    return instance.get("/suitablefor-list");
  },
  getCountry: () => {
    return instance.get("/country-list");
  },
  getResort: countryId => {
    return instance.get(`/resort-list/${countryId}`);
  },
  getServices: () => {
    return instance.get(`/service-list`);
  }
};

const ProviderData = {
  getProviderData: resortId => {
    return instance.get(`provider-list/${resortId}`);
  }
};

/**
 * @todo implement contactUs api in the new django backend
 */
const Helpers = {
  contactUs: req => {
    return instance.post("auth/contact-us-email-send", req);
  }
};

const Zoom = {
  getInvLink: code => {
    return instance.post("/zoomAuth", { code: code });
  }
};

const VirtualLessonPayment = {
  sendPaymentEmail: () => {
    return instance.get("/booking/RateEmail/virtualLessonPayment");
  }
};

const PasswordChange = {
  sendPasswordChange: () => {
    return instance.get("/booking/RateEmail/passwordChange");
  }
};

const VerifyEmail = {
  sendVerifyEmail: () => {
    return instance.get("/booking/RateEmail/verifyEmail");
  }
};

const ActivatedUser = {
  getActivatedUserDetail: (token, userID, signup_step) => {
    return instance.get(`/auth/user-active/${token}/${userID}/${signup_step}`);
  }
};

const updateProviderServices = {
  setProviderServices: (provider_id, services) => {
    return instance.post(`/auth/providerservice-update/${provider_id}`, services, {
      headers: {
        content_type:
          "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
      }
    });
  }
};
export default {
  Votes,
  Resorts,
  Constants,
  Helpers,
  Members,
  Users,
  Students,
  Instructors,
  Lessons,
  setToken: setAccess,
  Trips,
  VirtualLesson,
  VirtualLessonPayment,
  PasswordChange,
  VerifyEmail,
  Zoom,
  Message,
  ProviderData,
  ActivatedUser,
  updateProviderServices
};
