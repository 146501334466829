import React from "react";
import FooterBar from "../../../components/Common/Footer/FooterBar";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Link from "@material-ui/core/Link";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    background: "#FFFFFF"
  },
  btn: {
    color: "red",
    height: "3em",
    width: "7em",
    margin: "2em",
    border: "1px solid #FF6161"
  },
  btnArrow: {
    position: "absolute",
    left: "0",
    transform: "rotate(180deg)"
  },
  headingStyle: {
    color: "#1277B8",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "30px",
    lineHeight: "140.62%",
    alignItems: "center",
    textAlign: "center"
  },
  textFieldStyle: {
    fontFamily: "PT Sans Narrow",
    fontWeight: "bold",
    color: "#1277B8",
    fontSize: "15px",
    lineHeight: "140.62%"
  },
  textFieldStyleBilling: {
    fontFamily: "PT Sans Narrow",
    fontWeight: "bold",
    color: "#1277B8",
    fontSize: 15,
    lineHeight: "140.62%",
    marginTop: 20
  },
  tab: {
    fontFamily: "PT Sans Narrow",
    fontWeight: "bolder",
    fontSize: 18,
    textTransform: "none",
    color: "#1277B8",
    "&:focus": {
      outline: "none"
    },
    "& span": {
      alignItems: "normal"
    },
    textAlign: "left"
  },
  textField: {
    height: "45px",
    fontSize: "5",
    size: "small",
    width: "100%",
    padding: "0em",
    paddingLeft: "1rem",
    border: "10px",
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    borderRadius: "0px",
    marginBottom: "10px"
  },
  textFieldStyle1: {
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    width: "100%",
    height: "45px",
    borderStyle: "none",
    "& .PrivateNotchedOutline-root-28": {
      border: "none"
    },
    "& .MuiInputBase-input": {
      color: "#1277B8",
      fontFamily: "PT Sans Narrow",
      marginTop: -5
    },
    "& .MuiSvgIcon-root": {
      color: "#1277B8",
      marginTop: -3
    },
    marginBottom: "20px"
  },
  btnpay: {
    borderRadius: 100,
    background: "#FF6161",
    height: 35,
    width: 100,
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontSize: 18,
    fontWeight: "normal",
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
    border: "none",
    marginTop: 20,
    marginBottom: 80
  },
  cardInfoContainer: {
    width: "75%",
    margin: "0 auto"
  },
  tabindicator: {
    backgroundColor: "#1277B8"
  }
}));

const country = [
  {
    value: "Australia",
    label: "Australia"
  },
  {
    value: "New Zealand",
    label: "New Zealand"
  },
  {
    value: "China",
    label: "China"
  }
];

const accounttype = [
  {
    value: "Savings",
    label: "Savings"
  },
  {
    value: "Transaction",
    label: "Transaction"
  }
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function AutoGrid() {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);
  const [billingcountry, setBillingcountry] = React.useState("");
  const [bankaccounttype, setBankaccounttype] = React.useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeBillingCountry = event => {
    setBillingcountry(event.target.value);
  };

  const handleChangeBankAccountType = event => {
    setBankaccounttype(event.target.value);
  };

  return (
    <div className={classes.root} align="center">
      <Grid
        container
        lg={8}
        md={9}
        sm={10}
        xs={12}
        direction="column"
        justify="center"
      >
        <div align="left">
          <Link href="/PaymentandPayout">
            <Button
              variant="outlined"
              className={classes.btn}
              classes={{ root: classes.btnRoot, label: classes.btnLabel }}
            >
              <ArrowRightAltIcon
                className={classes.btnArrow}
                variant="outlined"
              />
              Back
            </Button>
          </Link>
        </div>

        <div>
          <p className={classes.headingStyle}>Payment and Payouts</p>
        </div>

        <div className={classes.cardInfoContainer}>
          <AppBar
            elevation={0} //clear the shadow
            className={classes.AppBar}
            position="static"
            color={"transparent"}
          >
            <Tabs
              className={classes.Tabs}
              // change the color of indicator to grey
              TabIndicatorProps={{
                style: { backgroundColor: "#1277B8" }
              }}
              variant="fullWidth"
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="Payments" className={classes.tab} disabled />
              <Tab label="Payouts" className={classes.tab} {...a11yProps(1)} />
            </Tabs>
          </AppBar>
        </div>

        <TabPanel value={value} index={1}>
          <div align="center">
            <Grid
              lg={5}
              md={6}
              sm={7}
              xs={8}
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              align="center"
            >
              <Grid item xs={12}>
                <div align="left" className={classes.textFieldStyleBilling}>
                  BILLING COUNTRY
                </div>
              </Grid>

              <Grid item lg={11} md={12} xs={11}>
                <div align="left" className={classes.textFieldStyle}>
                  <FormControl className={classes.textFieldStyle1}>
                    <Select
                      value={billingcountry}
                      onChange={handleChangeBillingCountry}
                      displayEmpty
                      variant="outlined"
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="" disabled>
                        Please slect an option
                      </MenuItem>
                      {country.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div align="left" className={classes.textFieldStyle}>
                  BANK ACCOUNT TYPE
                </div>
              </Grid>

              <Grid item lg={11} md={12} xs={11}>
                <div align="left" className={classes.textFieldStyle}>
                  <FormControl className={classes.textFieldStyle1}>
                    <Select
                      value={bankaccounttype}
                      onChange={handleChangeBankAccountType}
                      displayEmpty
                      variant="outlined"
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="" disabled>
                        Please slect an option
                      </MenuItem>
                      {accounttype.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div align="left" className={classes.textFieldStyle}>
                  ACCOUNT HOLDER NAME
                </div>
              </Grid>

              <Grid item lg={11} md={12} xs={11}>
                <div align="left" className={classes.textFieldStyle}>
                  <input
                    fullWidth
                    id="cardname"
                    variant="filled"
                    className={classes.textField}
                    size="small"
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div align="left" className={classes.textFieldStyle}>
                  BANK NAME
                </div>
              </Grid>

              <Grid item lg={11} md={12} xs={11}>
                <div align="left" className={classes.textFieldStyle}>
                  <input
                    fullWidth
                    id="cardname"
                    variant="filled"
                    className={classes.textField}
                    size="small"
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div align="left" className={classes.textFieldStyle}>
                  BSB
                </div>
              </Grid>

              <Grid item lg={11} md={12} xs={11}>
                <div align="left" className={classes.textFieldStyle}>
                  <input
                    textAlign="left"
                    fullWidth
                    id="cardname"
                    variant="filled"
                    className={classes.textField}
                    size="small"
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div align="left" className={classes.textFieldStyle}>
                  ACCOUNT NUMBER
                </div>
              </Grid>

              <Grid item lg={11} md={12} xs={11}>
                <div align="left" className={classes.textFieldStyle}>
                  <input
                    fullWidth
                    id="cardname"
                    variant="filled"
                    className={classes.textField}
                    size="small"
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div align="left" className={classes.textFieldStyle}>
                  CONFIRM ACCOUNT NUMBER
                </div>
              </Grid>

              <Grid item lg={11} md={12} xs={11}>
                <div align="center" className={classes.textFieldStyle}>
                  <input
                    fullWidth
                    id="cardname"
                    variant="filled"
                    className={classes.textField}
                    size="small"
                  />
                </div>
              </Grid>

              <Grid container item xs={12}>
                <Grid item lg={2} md={3} sm={1} />
                <Grid item lg={7} md={6} sm={9} xs={12} align="center">
                  <Link href="/payoutAddress">
                    <button
                      color="primary"
                      variant="contained"
                      className={classes.btnpay}
                    >
                      Next
                    </button>
                  </Link>
                </Grid>
                <Grid item lg={3} md={3} sm={2} />
              </Grid>
            </Grid>
          </div>
        </TabPanel>
      </Grid>
    </div>
  );
}

export default function Payouttab() {
  return (
    <div>
      <AutoGrid />
    </div>
  );
}
