import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GoogleLogin from 'react-google-login';
import  Button  from "@material-ui/core/Button";
import Google from "../../../materials/google.svg";
import { SvgIcon } from "@material-ui/core";
import {gapi} from 'gapi-script';
import { useHistory } from "react-router-dom";
import agent from "../../../agent";

const useStyles = makeStyles(theme => ({
  button: {
    width: "16em",
    height: "4em",
    "& span": {
      color: "black",
      fontFamily: "PT Sans Narrow",
      fontWeight: "bold" + "!important",
      marginLeft: 10,
      fontSize: "16px"
    },
    "& div": {
      marginLeft: 30,
      marginRight: 30
    },
    [theme.breakpoints.down("md")]: {
      width: "13em",
      // marginLeft: "2em",
      "& span": {
        marginLeft: 5
      },
      "& div": {
        marginLeft: 15,
        marginRight: 15
      }
    },
    [theme.breakpoints.down("lg")]: {
      width: "15em",
      // marginLeft: "2.8em",
      marginRight: 0,
      "& div": {
        marginLeft: "0",
        marginRight: "0" + "!important"
      }
    }
  },
  logoImg: {
    height: "20px",
    verticalAlign: "super",

    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0
    }
  },
  loginBtn: {
    borderRadius: "10px",
    width: "16em",
    height: "3em",
    border: "1px solid",
    [theme.breakpoints.down("sm")]: {
      width: "2em",
      height: "3em",
      margin: "1rem 0rem"
    },
    
    [theme.breakpoints.down("lg")]: {
      width: "15em"
    }
  },
 
  btnLabel: {
    color: "black",
    fontFamily: "PT Sans Narrow",
    fontWeight: "bold",
    textTransform: "none"
  }
}));

const GoogleSignup = props => {
  const classes = useStyles();
  let history = useHistory();
  
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [isLoading, setLoading] = useState();

  const clientId =
    "747047264880-p5t7h6i8bp5ldfmeh0g68fcvgd5ldgis.apps.googleusercontent.com";

  useEffect(()=>{
    function start(){
      gapi.auth2.init({
        client_id: clientId,
        scope: ""
      })
    };
    gapi.load('client:auth2', start);
  });

  const signup = (response) => {
    console.log("Successfully signed up in",response);
    setIsLoggedIn(true);
    // accessToken = setAccessToken(response.accessToken);
    const email=response.profileObj.email
    const password="google@res"

    agent.Users.register(email, password, props.isProvider, 3)
      .then(response => {
        setLoading(false);
        setIsLoggedIn(true);
        history.push("/activate-user")
      })
      .catch(error => {
        setLoading(false);
        alert(error.response.data.email);
      });
  };

  const handleLoginFailure = (response) => {
    alert("Failed to log in", response);
  };

  return (
    <GoogleLogin
      onSuccess={signup}
      onFailure={handleLoginFailure}
      cookiePolicy={"single_host_origin"}
      responseType="code,token"
      className={classes.button}
      render={renderProps => (
        <Button
          className={classes.loginBtn}
          classes={{ label: classes.btnLabel }}
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          startIcon={
            <SvgIcon component="object">
              <img src={Google} className={classes.logoImg} />
            </SvgIcon>
          }
        >
          Continue with Google
        </Button>
      )}
      style={{ "border-radius": "3rem" }}
    />
  );
};

export default GoogleSignup;
