import React, { Fragment, useCallback, useMemo, useEffect } from "react";
import { Field } from "redux-form";
// Component Input
import { renderTextAreaField } from "../../FormField/FormField.js";
// Styles
import { Typography, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// import useStyles from "../ClientInfoStyles";
// Import Style
import useClasses from "../ClientInfoStyles";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const debounce = (fn, delay) => {
  let timeout = -1;

  return (...args) => {
    if (timeout !== -1) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(fn, delay, ...args);
  };
};

const useStyles = makeStyles(theme => ({
  titleSpacing: {
    [theme.breakpoints.up("sm")]: {
      padding: "0.5rem 1rem 0.5rem 0rem"
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "2.5%",
      width: "95%",
      paddingBottom: "0.5rem"
    }
  },
  boxSpacing: {
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      padding: "0rem 1rem 0rem 0rem"
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "2.5%",
      width: "95%"
    }
  }
}));

export default function Bio(props) {
  const classes = useClasses();
  const styles = useStyles();

  const {bio,setBio, isDisabled} = props
  
  // Debounce changes to bio
  const onDebounceBio = useMemo(() => {
    return debounce(value => {
      props.setBio(value);
    }, 500);
  }, []);

  const handleBioChange = useCallback(value => {
    onDebounceBio(value);
  }, []);

  return (
    <Fragment>
      <Grid
        container
        item
        xs={12}
        sm={8}
        direction="column"
        alignItems="stretch"
        justifyContent="flex-start"
      >
        <Grid
          container
          item
          direction="row"
          // style={{ padding: "0.5rem 2rem" }}
          className={styles.titleSpacing}
        >
          <Typography className={classes.allergyandotherinformation}>
            <text>BIO</text>
            <text style={{ fontWeight: "normal" }}>(max 500 words)</text>
          </Typography>
        </Grid>

        <Grid
          item
          // style={{ width: "100%", padding: "0rem 1rem 0rem 2rem" }}
          className={styles.boxSpacing}
        >
          <Field
            // onChange={event => handleBioChange(event.target.value)}
            onChange={event => props.setBio(event.target.value)}
            name="bio"
            component={renderTextAreaField}
            type="textarea"
            label="Bio"
            placeholder="Introduce yourself -  Where you're from, what your hobbies are, etc..."
            props={{
              disabled: isDisabled,
              value: bio
            }}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
