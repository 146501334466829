// React
import React, { useEffect, useState } from "react";

// Redux
import { getCountryList } from "../../../redux/actions/homePageAction.js";
import { connect } from "react-redux";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
} from "@material-ui/core";

// Project Components
import CountryResortSelection from "./CountryResortSelection.js";

const useStyles = makeStyles((theme) => ({
  label: {
    marginLeft: "7.5%",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    marginBottom: "0.5em",
    textTransform: "uppercase",
    color: "#000000"
  },
}));

const Countries = props => {
  const classes = useStyles();
  const { isDisabled, countryResorts, setCountryResorts } = props;

  const [resortsGroupByCountry, setResortsGroupByCountry] = useState([[]]);
  const [boxWidth, setBoxWidth] = useState(6);

  /**
   * The funtion groups resorts by country ID.
   * @param countryResorts the data of resorts from database.
   * @param countryId the key for grouping
   * @example countryResorts = [{id: 1094, resort_name: 'Batea Mahuida', country: 41}, {id: 1088, resort_name: 'Catedral Alta Patagonia', country: 41}, {id: 930, resort_name: 'Akhtamar – Sevan', country: 46}]
   */
  function groupByCountry(countryResorts, countryId) {
    return countryResorts.reduce(function (acc, obj) {
      let key = obj[countryId]
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(obj)
      return acc
    }, {})
  }

  // get ALL countries
  useEffect(() => {
    props.getCountryList();
  }, []);
  
  // First render from props (redux.userProfile)
  useEffect(() => {
    if (props.userProfile) {
      if (props.userProfile.resorts.length != 0){
        let resortsGroupedByCountry = groupByCountry(props.userProfile.resorts, "country"); // e.g. 41: (2) [{…}, {…}] 46: [{…}]
        let size = Object.keys(resortsGroupedByCountry).length;
        let temp = [];
        for (let i = 0; i < size; i++) {
          let item = resortsGroupedByCountry[Object.keys(resortsGroupedByCountry)[i]];
          temp.push(item);
        }
        setResortsGroupByCountry(temp);  // [Array(2), Array(2), Array(1)]
        /**
         * (3) [Array(2), Array(2), Array(1)]
              0: Array(2)
                0: {id: 3254, resort_name: 'Arcalís – Ordino (Vallnord)', country: 25}
                1: {id: 3255, resort_name: 'Arinsal – La Massana (Vallnord)', country: 25}
              1: Array(2)
                0: {id: 2518, resort_name: 'Babin Do – Bjelašnica', country: 29}
                1: {id: 1033, resort_name: 'Blidinje – Risovac', country: 29}
              2: Array(1)
                0: {id: 1, resort_name: 'Chréa', country: 67}
        */
      }
    }
     
  }, []); // empty array: only run once

  // reset the Grid's xs value depending on the mode
  useEffect(() => {
    if (isDisabled !== undefined) {
      if(!isDisabled){
        setBoxWidth(5);
      }
    }
  }, [isDisabled]);

  return (
    <div>
      <Grid 
        container
        justify="center"
        alignItems="flex-start"
        direction="row"
      >
        <Grid container item xs={12}>
          <Grid container item xs={boxWidth}>
            <Typography align="left" className={classes.label}>
              MY RESORTS
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid style={{ marginBottom: "1.5em" }}>
      </Grid>
      {
      resortsGroupByCountry.map((item, index) => (
        <Grid style={{ marginBottom: "1.5em" }}>
          <CountryResortSelection 
            resorts={item}
            resortsGroupByCountry={resortsGroupByCountry}
            setResortsGroupByCountry={setResortsGroupByCountry}
            rowIndex={index}
            setCountryResorts={setCountryResorts}
            countryResorts={countryResorts}
            isDisabled={isDisabled}
            boxWidth={boxWidth}
          />
        </Grid>))
      }
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userProfile: state.stepperReducer.userProfile,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getCountryList: () => dispatch(getCountryList()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Countries);