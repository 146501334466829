import React from "react";
import useStyles from "./MyAccountStyles";
import { Button, Grid } from "@material-ui/core";

import { ArrowRightAlt as ArrowRightAltIcon } from "@material-ui/icons";

import Ability from "./Ability";
// import "../../css/template/SliderBar.css";

import Bio from "./Bio";
import Contact from "./Contact";

import Disabilities from "./Disabilities";
import Email from "./Email";

import ExperLang from "./ExperLang";
import LangCountry from "./LangCountry";

import Gallery from "./Gallery";
// import InstructorReviewCarousel from "../Instructor/InstructorReviewCarousel";
// import InstructorGalleryCarousel from "../Instructor/InstructorGalleryCarousel";
import GalleryVis from "./GalleryVis";
import GenderAge from "./GenderAge";

import Name from "./Name";
import Password from "./Password";
// import EditPassword from "../Home/EditPassword";

import Phone from "./Phone";

import PreCurrency from "./PreCurrency";
import PreLanguage from "./PreLanguage";

import Qualifications from "./Qualifications";
// import InsQualification from "../Home/InsQualification";

import Resorts from "./Resorts";

import SpecialHost from "./SpecialHost";
import SportSpecial from "./SportSpecial";

import TimeZone from "./TimeZone";
import Title from "./Title";

export default function Account(props) {
  const classes = useStyles();

  let {
    filterData,
    studentData,
    _editCountrycode,
    _editName,
    _editBio,
    _editPhonenumber,
    _editEmail,
    _editPassword,
    _editGender,
    _editExperience,
    _editAge,
    _editExperienceIns,
    _editExperienceLocal,
    _editLanguages,
    _editAbilities,
    _editDisabilities,
    _editSportSpecial,
    _editPreferlanguage,
    _editPrefercurrency,
    _editTimezone,
    _editQualifications,
    _editResorts,
    _editCountry,
    _editSpecialiaties,
    _editTargetAudience
  } = props;

  //#region Handles

  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("sm");

  const [disabled, setDisabled] = React.useState(true);
  const [select, setSelect] = React.useState(false);

  //Password Dialog Open
  const [open, setOpen] = React.useState(false);

  const handleClickOpenPassword = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //Disabilities Dialog Open
  const [opendis, setOpendis] = React.useState(false);

  const handleClickOpenDis = () => {
    setOpendis(true);
  };

  const handleCloseDis = () => {
    setOpendis(false);
  };

  const handleDisabled = () => {
    setDisabled(false);
    setSelect(true);
  };

  //#endregion

  return (
    <div className={classes.root} align="center">
      <div align="left">
        <Button
          variant="outlined"
          className={classes.btn}
          classes={{ root: classes.btnRoot, label: classes.btnLabel }}
        >
          <ArrowRightAltIcon className={classes.btnArrow} variant="outlined" />
          Back
        </Button>
      </div>

      <Grid md={12} sm={12} xs={12} container direction="column" align="center">
        <Title {...props} handleDisabled={handleDisabled} />

        <Grid item md={12}>
          <div className={classes.emptyCard2} />
        </Grid>

        <Name {...props} />

        <Bio {...props} />

        <Contact {...props} select={select} disabled={disabled} />

        <Phone {...props} />

        <Email {...props} />

        <Password
          {...props}
          open={open}
          handleClose={handleClose}
          handleClickOpenPassword={handleClickOpenPassword}
        />

        <GenderAge {...props} select={select} disabled={disabled} />

        {props.journey === "h-vis" ? (
          <LangCountry {...props} select={select} disabled={disabled} />
        ) : (
          <ExperLang {...props} select={select} disabled={disabled} />
        )}

        <Ability {...props} />

        <SportSpecial {...props} />
        <GalleryVis {...props} />

        {/* STU - Disabilities & Gallery  INS - Qualifations & Gallery*/}
        <Grid item container md={12} direction="row">
          {props.journey === "h-vis" ? null : props.journey === "vl-stu" ? (
            <Disabilities
              {...props}
              opendis={opendis}
              disabled={disabled}
              handleCloseDis={handleCloseDis}
              handleClickOpenDis={handleClickOpenDis}
            />
          ) : props.journey === "vl-ins" ? (
            <Qualifications {...props} />
          ) : (
            <SpecialHost {...props} select={select} disabled={disabled} />
          )}
          {props.journey === "h-vis" ? null : <Gallery />}
        </Grid>

        <Grid item container md={12} direction="row">
          <div className={classes.emptyCard3} />
        </Grid>

        {/* Resort/s */}
        {props.journey === "vl-stu" || props.journey === "h-vis" ? null : (
          <Resorts {...props} select={select} disabled={disabled} />
        )}

        <PreLanguage {...props} select={select} disabled={disabled} />
        <PreCurrency {...props} select={select} disabled={disabled} />

        <TimeZone {...props} select={select} disabled={disabled} />

        <Grid item container md={12} direction="row">
          <div className={classes.emptyCard2} />
        </Grid>

        <Grid
          item
          container
          md={12}
          direction="row"
          justify="center"
          align="center"
        >
          <button
            color="primary"
            variant="contained"
            className={classes.btnbot1}
          >
            Save
          </button>
        </Grid>
      </Grid>
    </div>
  );
}

// export default Account;
