import React, { Fragment, useEffect, useState } from "react";
import { Field } from "redux-form";
import { styled, createMuiTheme } from "@material-ui/core/styles";
// Component Input
import { renderTextAreaField } from "../../FormField/FormField.js";

import { Typography, Grid, Select, MenuItem } from "@material-ui/core";

// import useStyles from "../ClientInfoStyles";
const SelectGender = styled(Select)({
  fontColor: "1277B8",
  // width: "100%",
  // border: "1px solid #000000",
  // boxSizing: "border-box",
  marginLeft: "7.5%",
  width: "85%",
  fontFamily: "PT Sans Narrow",
  borderRadius: "10px",
  // marginLeft: "10%",
  // marginRight: "10%",
  // borderRadius: "10px",
  "& .MuiOutlinedInput-input": {
    padding: "0.5rem 0.5rem",
    height: "0px"
    // paddingTop: "5px",
    // paddingBottom: "5px"
    // paddingLeft: "5px"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    // borderWidth: "0"
    borderColor: "black  ",
    borderWidth: "1px  "
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    // borderWidth: "0"
    borderColor: "black  ",
    borderWidth: "1px  "
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    // borderWidth: "0"
    borderColor: "black  ",
    borderWidth: "1px  "
  },
  "& .MuiListItemText-root": {
    marginTop: "0px"
  },
  "& .MuiTypography-body1": {
    lineHeight: "inherit"
  }
});

export const LabelCustom = styled(Typography)({
  fontFamily: "PT Sans Narrow",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "18px",
  marginBottom: "0.5em",
  textTransform: "uppercase",
  color: "#000000"
});

export default function Gender(props) {
  const { renderSelectField, gender, handleGenderChange } = props;
  // const classes = useStyles();

  return (
    <>
      <LabelCustom style={{ marginLeft: "7.5%" }}> Gender</LabelCustom>
      <SelectGender
        value={gender}
        label="Gender"
        variant="outlined"
        onChange={handleGenderChange}
      >
        <MenuItem value={"male"}>Male</MenuItem>
        <MenuItem value={"female"}>Female</MenuItem>
        <MenuItem value={"not_specified"}>Not specified</MenuItem>
      </SelectGender>
    </>
    // <Field
    //   name="gender"
    //   component={renderSelectField}
    //   type="select"
    //   label="Gender"
    //   value={gender.value}
    //   placeholder="Please select an option"
    //   onChange={handleGenderChange}
    //   multiple={false}
    //   // spacing={{ width: "80%" }}
    //   options={[
    //     { id: 1, value: "Male" },
    //     { id: 2, value: "Female" },
    //     { id: 3, value: "Not specified" }
    //   ]}
    // />
  );
}
