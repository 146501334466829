import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { connect } from "react-redux";

const useStyles = {
  card: {
    height: 200,
    weight: 100
  }
};

class InstructorGalleryCarousel extends React.Component {
  state = {
    currentIndex: 0,
    responsive: {
      500: { items: 2 },
      800: { items: 3 },
      1024: { items: 4 }
    },
    stagePadding: {
      paddingLeft: 5,
      paddingRight: 5
    },
    galleryItems: this.galleryItems()
  };

  slideTo = i => this.setState({ currentIndex: i });
  onSlideChanged = e => this.setState({ currentIndex: e.item });
  slideNext = () =>
    this.setState({ currentIndex: this.state.currentIndex + 1 });
  slidePrev = () =>
    this.setState({ currentIndex: this.state.currentIndex - 1 });
  thumbItem = (item, i) => <span onClick={() => this.slideTo(i)}>* </span>;

  galleryItems() {
    const { classes } = this.props;
    const { galleryItem } = this.props.instructorData.filter(
      instructor => instructor.id == this.props.instructorId
    )[0];

    return galleryItem.map(galleryItem => (
      <img
        src={galleryItem.src}
        alt={galleryItem.name}
        height={200}
        style={{ width: "100%", objectFit: "cover", padding: 10 }}
      />
    ));
  }

  render() {
    const { galleryItems, responsive, currentIndex } = this.state;
    return (
      <div>
        <AliceCarousel
          dotsDisabled={true}
          buttonsDisabled={true}
          items={galleryItems}
          responsive={responsive}
          slideToIndex={currentIndex}
          onSlideChanged={this.onSlideChanged}
          autoHeight
        />
        <div align={"center"}>
          <IconButton aria-label="ChevronLeft" onClick={() => this.slidePrev()}>
            <ChevronLeft />
          </IconButton>
          <IconButton
            aria-label="ChevronRight"
            onClick={() => this.slideNext()}
          >
            <ChevronRight />
          </IconButton>
        </div>
        {/*<ul>{this.items.map(this.thumbItem)}</ul>*/}
        {/*used to make a new button to control prev and next slides*/}
        {/*<button onClick={() => this.slidePrev()} ></button>*/}
        {/*<button onClick={() => this.slideNext()}>Next button</button>*/}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    instructorData: state.virtualInstructorReducer.data
  };
}

export default connect(mapStateToProps)(InstructorGalleryCarousel);
