import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import image from "../../../materials/HomePage/ski-lessons-at-cardrona-alpine-resort.png";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import Button from "@material-ui/core/Button";
import IntroSection from "../../../components/virtualLessons/profile/IntroSection";
import MoreInfo from "../../../components/virtualLessons/moreInfo";
import sampleImg from "../../../materials/HomePage/campfire_img.jpg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { baseURL } from "../../../redux/actions/action-types";
import agent from "../../../agent";
import { saveInstructorState } from "../../../redux/actions/virtInstructorDataActions";

import Header from "../../../components/Common/Home/HeaderSection.js";

import Timeline from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import InstructorCalendar from "../../../components/virtualLessons/instructorProfileCalendar";
import LoadingPopup from "../../../components/virtualLessons/LoadingPopup";

import InstructorReviewCarousel from "../../../components/virtualLessons/InstructorReviewCarousel";
import InstructorGalleryCarousel from "../../../components/virtualLessons/InstructorGalleryCarousel";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

//redux
import {
  modifyBooking,
  adjustUnavailability
} from "../../../redux/actions/virtInstructorDataActions";

import { useEffect } from "react";

const API_PATH = "../api/";

const useStyles = makeStyles(theme => ({
  arrowIcon: {
    transform: "rotate(180deg)"
  },
  backButton: {
    margin: "3em",
    borderColor: "#FF6161",
    color: "#FF6161"
  },
  smallSectionHeading: {
    fontSize: "16",
    color: "#1277B8",
    fontWeight: "bold",
    fontFamily: "PT Sans Narrow",
    lineHeight: "140.62%",
    // height: "46.07px",
    // width: "78px",
    padding: "6px"
  },
  carousel: {
    marginTop: 50,
    padding: "6px"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

const InstructorProfile = props => {
  const classes = useStyles();
  const searchCard = false;
  const slogan = "Browse & Book Private Instructors";
  const version = "instructor";
  const theme = useTheme();
  //this is the date the calendar starts at
  const [startdate, setStartdate] = React.useState(new Date());
  const [isOwnProfile, setIsOwnProfile] = React.useState(false);
  const [loadedProfile, setLoadedProfile] = React.useState(false);
  const breakpoints = {
    matchesSm: useMediaQuery(theme.breakpoints.down("sm")),
    matchesXs: useMediaQuery(theme.breakpoints.down("xs")),
    matchesMd: useMediaQuery(theme.breakpoints.down("md")),
    matchesLg: useMediaQuery(theme.breakpoints.down("lg"))
  };

  const instructorData = props.instructorData;
  let rootSize = classes.root;
  if (breakpoints.matchesXs) {
    rootSize = classes.rootXs;
  } else if (breakpoints.matchesMd) {
    rootSize = classes.rootMd;
  }

  const groups = [
    { id: 1, title: "Unavailble", label: "test" },
    { id: 2, title: "Booked Slots", label: "test" },
    { id: 3, title: "", label: "test" },
    { id: 4, title: "", label: "test" }
  ];

  //Loads in the instructor data from the backend and then saves it to the redux state
  const loadInstructorData = () => {
    const id = props.match.params.virtInstructorId;
    let responseData;
    axios
      .get(API_PATH + "instructor/" + id, {})
      .then(response => {
        //format response data
        responseData = response.data;
        let resorts = responseData.resorts.map(x => {
          return { ...x, fullname: x.Name + ", " + x.Country };
        });
        let specialisations = responseData.specialisations.map(x => ({
          desc: x.Description,
          id: x.id
        }));
        let sport = specialisations.filter(x => x.desc.indexOf("-") === -1);
        specialisations = specialisations.filter(
          x => x.desc.indexOf("-") !== -1
        );
        let languages = responseData.languages.map(x => x.description);
        let unavailability = responseData.unavailability.map(unavailability => {
          return {
            ...unavailability,
            start: new Date(unavailability.start),
            end: new Date(unavailability.end)
          };
        });
        let bookings = responseData.bookings.map(booking => {
          return {
            ...booking,
            start_time: new Date(booking.start),
            end_time: new Date(booking.end),
            group: 1,
            unavailableDays: []
          };
        });
        let rates = responseData.rates;
        let instructor = {
          ...responseData.instructor,
          specialisations: specialisations,
          resorts: resorts,
          sport: sport,
          languages: languages,
          resort_bookings: bookings,
          unavailability: unavailability,
          rates: rates
        };

        //save to redux
        props.saveInstructorState(instructor);
        setLoadedProfile(true);
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadInstructorData();
  });

  //Handles changes the starttime of a calendar item
  const handleCalendarItemMove = (itemId, dragTime, newGroupOrder) => {
    //only instructor can edit own profile
    if (isOwnProfile) {
      //if in bookings group apply update
      //unavailability has been given negative id to differenciate the two
      if (itemId > 0) {
        handleBookingMoved(itemId, dragTime, newGroupOrder);
      }

      //else apply update on unavailability
      if (itemId < 0) {
        handleUnavailabilityMoved(-itemId, dragTime, newGroupOrder);
      }
    }
  };

  //Handles changes the length of a booking in the bottom gnatt calendar
  const handleCalendarItemResize = (itemId, time, edge) => {
    //only instructor can edit own profile
    if (isOwnProfile) {
      //if in bookings group apply update
      //unavailability has been given negative id to differenciate the two
      if (itemId > 0) {
        handleBookingResize(itemId, time, edge);
      }

      //else apply update on unavailability
      if (itemId < 0) {
        handleUnavailabilityResize(-itemId, time, edge);
      }
    }
  };

  //BOOKING calendar functions
  //handles changing the starttime of a booking item
  const handleBookingMoved = (itemId, dragTime, newGroupOrder) => {
    //makes new items state
    let group = 1;
    const new_start = moment(dragTime);
    let items_new = [...instructorData.resort_bookings];
    let new_end;
    items_new.map(item => {
      if (item.id === itemId) {
        group = item.group;
        new_end = new Date(
          moment(new_start + (item.end_time - item.start_time))
        );
        item.start_time = new_start.toDate();
        item.end_time = new_end;
      }
    });

    //front-end redux state
    props.modifyBooking(items_new);
    //back-end database values
    axios
      .put(API_PATH + "/virtualBooking/" + itemId, {
        start: new_start.toString(),
        end: new_end.toString(),
        group: group.id
      })
      .then(response => {
        loadInstructorData();
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleBookingResize = (itemId, time, edge) => {
    const new_time = moment(time).toDate();
    let items_new = [...instructorData.resort_bookings];
    let start_time;
    let end_time;
    items_new.map(item => {
      if (item.id === itemId) {
        start_time = edge === "left" ? new_time : item.start_time;
        end_time = edge === "right" ? new_time : item.end_time;
        item.start_time = start_time;
        item.end_time = end_time;
      }
    });
    modifyBookingState(items_new, start_time, end_time, itemId);
  };

  const modifyBookingState = (newState, start, end, itemId) => {
    //modifies booking on front end
    props.modifyBooking(newState);
    //modifies booking on back end
    axios
      .put(API_PATH + "/virtualBooking/" + itemId, {
        start: start.toString(),
        end: end.toString()
      })
      .then(response => {})
      .catch(error => {
        console.log(error);
      });
  };

  //UNAVAILABILITY calendar functions
  //handles changing the starttime of unavailability
  const handleUnavailabilityMoved = (itemId, dragTime, newGroupOrder) => {
    //makes new items state
    let group = 0;
    //conver id back to positive
    const new_start = moment(dragTime);
    let items_new = [...instructorData.unavailability];
    let resort_info_id = 1;
    let new_end;
    items_new.map(item => {
      if (item.id === itemId) {
        group = item.group;
        resort_info_id = item.resort_info_id;
        new_end = new Date(moment(new_start + (item.end - item.start)));
        item.start = new_start.toDate();
        item.end = new_end;
      }
    });

    modifyUnavailabilityState(items_new, resort_info_id);
  };

  const handleUnavailabilityResize = (itemId, time, edge) => {
    const new_time = moment(time).toDate();
    let items_new = [...instructorData.unavailability];
    let start;
    let end;
    let resort_info_id = 1;
    items_new.map(item => {
      if (item.id === itemId) {
        start = edge === "left" ? new_time : item.start;
        end = edge === "right" ? new_time : item.end;
        item.start = start;
        item.end = end;
        resort_info_id = item.resort_info_id;
      }
    });

    modifyUnavailabilityState(items_new, resort_info_id);
  };

  const modifyUnavailabilityState = (newState, resort_info_id) => {
    //modifies unavailability on front end
    props.adjustUnavailabilityReduxState(resort_info_id, newState);

    //modifies unavailability on back end
    let stateToString = newState.map(date => {
      return {
        ...date,
        start: date.start.toString(),
        end: date.end.toString()
      };
    });
    axios
      .put(API_PATH + "instructor/unavailability/" + props.instructorData.id, {
        newUnavailability: newState,
        resort_id: resort_info_id
      })
      .then(response => {})
      .catch(e => {
        console.log(e);
      });
  };

  //Instructor Profile page will change depending on whether student or instructor or viewing own profile
  //Make these changes below
  const instructorCalendar = (
    <InstructorCalendar
      groups={groups}
      bookings={instructorData.resort_bookings}
      unavailability={instructorData.unavailability}
      startdate={startdate}
      handleItemMove={handleCalendarItemMove}
      handleItemResize={handleCalendarItemResize}
    />
  );
  let calendar = instructorCalendar;
  return (
    <div>
      {/* Displays a loading wheel while loading profile */}
      <LoadingPopup isLoaded={loadedProfile} />
      <Grid container justify="center" alignItems="center" spacing={2}>
        <Header
          searchCard={searchCard}
          slogan={slogan}
          version={version}
          image={image}
        />
        <Grid
          container
          justify="flex-start"
          alignItems="flex-start"
          item
          sm={12}
          xs={12}
        >
          <Button
            className={classes.backButton}
            variant="outlined"
            startIcon={<ArrowRightAltIcon className={classes.arrowIcon} />}
          >
            Back
          </Button>
        </Grid>
        <Grid
          container
          justify="center"
          alignItems="center"
          item
          sm={10}
          xs={10}
        >
          <Grid container item spacing={2}>
            <IntroSection
              instructorId={instructorData.id}
              breakpoints={breakpoints}
            />
            <Grid xs={12} />
            <MoreInfo
              isOwnProfile={isOwnProfile}
              instructorId={instructorData.id}
              updateTime={setStartdate}
            />
            <div className={classes.smallSectionHeading}>Booked Slots : </div>
            <div style={{ overflowX: "scroll", border: "1px solid red" }}>
              {calendar}
            </div>
          </Grid>
        </Grid>
      </Grid>
      {/*Below is two carousel made by Yuyang*/}
      <Grid className={classes.carousel}>
        <Container maxWidth={"lg"}>
          <Typography className={classes.smallSectionHeading}>
            {" "}
            Reviews
          </Typography>
          {/* TODO: This is using dummy data as there is currently no way to upload reviews */}
          <InstructorReviewCarousel instructorId={1} />
          <Typography className={classes.smallSectionHeading}>
            {" "}
            Gallery
          </Typography>
          {/* TODO: This is using dummy data as there is currently no way to upload a gallery */}
          <InstructorGalleryCarousel instructorId={1} />
        </Container>
      </Grid>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    saveInstructorState: account => dispatch(saveInstructorState(account)),
    modifyBooking: booking => dispatch(modifyBooking(booking)),
    adjustUnavailabilityReduxState: (resort_info_id, dates) =>
      dispatch(adjustUnavailability(resort_info_id, dates))
  };
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth.currentUser,
    instructorID: state.virtualInstructorReducer.data.filter(
      instructor => instructor.id == ownProps.match.params.virtInstructorId
    )[0],
    instructorData: state.virtualInstructorReducer.currentProfile
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InstructorProfile));
