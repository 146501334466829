import { withStyles, makeStyles } from "@material-ui/core/styles";

import BackgroundImg from "../../../../materials/HomePage/campfire_img.jpg";
import { FilterColor } from "../../../styled-components/color-themes_old";

import {
  NAV_BAR_HEIGHT,
  TAB_BAR_HEIGHT
} from "../../../styled-components/dimension-themes";

const BACKGROUND_SIZE = "60vh";

const useStyles = makeStyles(theme => ({
  root: {},

  background: {
    minHeight: BACKGROUND_SIZE,
    backgroundImage: `url(${BackgroundImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "50% 50%",
    paddingBottom: TAB_BAR_HEIGHT
  },

  gradient: {
    //minHeight: `calc(${BACKGROUND_SIZE} - ${TAB_BAR_HEIGHT})`,
    backgroundColor: FilterColor,
    paddingBottom: 20
  },

  spacer: {
    height: NAV_BAR_HEIGHT
  },

  serviceTabs: {
    position: "relative",
    top: `calc(0px - ${TAB_BAR_HEIGHT})`
  }
}));

export default useStyles;
