import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  MenuItem,
  Select,
  ListSubheader,
  Checkbox,
  ListItemText
} from "@material-ui/core";
import { connect } from "react-redux";
import { sendHostName } from "../../../redux/actions/exploreHostsActions.js";

const useStyles = makeStyles({
  listCheckboxes: {
    margin: 0
  },

  subHeaderRoot: {
    color: "#1F1F1F",
    fontSize: 16,
    lineHeight: 1.3,
    fontFamily: "PT Sans Narrow"
  },
  listitem: {
    color: "#1F1F1F",
    "& .MuiTypography-body1": {
      fontFamily: "PT Sans Narrow"
    }
  },
  subCheckbox: {
    paddingLeft: "1.5em",
    backgroundColor: "transparent"
  },
  field: {
    width: "100%",
    height: 45,
    fontSize: "0.5em",
    fontSize: 15,
    fontFamily: "PT Sans Narrow",
    border: "10px",
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    borderRadius: "0px",
    marginBottom: "20px",
    textAlign: "left",
    "& .PrivateNotchedOutline-root-43": {
      border: "none"
    }
  }
});

//Multiple
//props: title, headings(array), subheadings(array), subheadingsList(array of arrays)
//selectedValues (array of arrays)
export const SportDropdownMenu = props => {
  const classes = useStyles();

  const headings = props.headings;
  const subheadingLists = props.subheadingLists;

  const [selectedValues, setSelectedValues] = useState(["SKI-Kids", "SKI"]);

  //Automatically selects parent heading if subheading is selected
  //Automatically unselects child subheadings if heading is selected
  const handleHeadingValues = (heading, subheading) => {
    const newValue = heading + "-" + subheading;
    if (heading !== subheading) {
      const index = selectedValues.indexOf(newValue);
      const headingIndex = selectedValues.indexOf(heading);
      let newState = [];
      if (index > -1) {
        newState = selectedValues.filter(i => i !== newValue);
      } else {
        newState = selectedValues.concat(newValue);
        if (headingIndex === -1) {
          newState = newState.concat(heading);
        }
      }
      setSelectedValues(newState);
      props.sendSelectedValues(newState);
    } else {
      const subheadingIndex = headings.indexOf(heading);
      const index = selectedValues.indexOf(heading);
      const subheadings = props.subheadingLists[subheadingIndex];
      let newState = [...selectedValues];
      if (index > -1) {
        newState = selectedValues.filter(i => i !== subheading);
        for (let i in subheadings) {
          newState = newState.filter(j => j !== heading + "-" + subheadings[i]);
        }
      } else {
        subheadings.forEach(element => {
          newState.push(heading + "-" + element);
        });
        newState.push(subheading);
      }
      setSelectedValues(newState);
      props.sendSelectedValues(newState);
    }
  };

  return (
    <Select
      multiple
      variant="outlined"
      value={selectedValues}
      renderValue={selected => selected.join(", ")}
      className={classes.field}
    >
      {headings.map(heading => {
        const index = headings.indexOf(heading);
        const subheadingList = subheadingLists[index];
        return (
          <div>
            <MenuItem
              key={heading}
              onClick={() => handleHeadingValues(heading, heading)}
              disableRipple
            >
              <Checkbox
                checked={selectedValues.indexOf(heading) > -1}
                disableRipple
              />
              <ListSubheader className={classes.subHeaderRoot} disableSticky>
                {heading}
              </ListSubheader>
            </MenuItem>
            {subheadingList.map(interest => (
              <div key={interest}>
                <MenuItem
                  key={interest}
                  onClick={() => handleHeadingValues(heading, interest)}
                >
                  <Checkbox
                    checked={
                      selectedValues.indexOf(heading + "-" + interest) > -1
                    }
                    style={{
                      paddingLeft: "1.5em"
                    }}
                    disableRipple
                  />
                  <ListItemText
                    primary={interest}
                    className={classes.listitem}
                  />
                </MenuItem>
              </div>
            ))}
          </div>
        );
      })}
    </Select>
  );
};

export default connect(
  null,
  { sendHostName }
)(SportDropdownMenu);
