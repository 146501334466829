import React, { useState, useEffect } from "react";

// material ui
import {
  Grid,
  Typography,
  CardContent,
  InputBase,
  Button
} from "@material-ui/core";

import useStyles from "../ServicesStyles";

import CollapsibleCheckBox from "../../../../CollapsibleCheckBox";

import BikeRiding from "./BikeRiding";
import { AdditionalGuests, MaxGroupSize, PricePerHead } from "../InputFields";

const Activities = props => {
  const {
    parent,
    nestingLevel,
    selectService,
    addYogaPilates,
    addFlyFishing,
    services,
    isDisabled,
    setYogaPilates,
    setFlyFishing
  } = props;
  const classes = useStyles();
  const [checkboxSelected, setCheckboxSelected] = useState(false);

  const [flyFishingSelected, setCheckboxSelectedFlyFishing] = useState(false);
  const [yogaPilatesSelected, setCheckboxSelectedyogaPilates] = useState(false);

  const onCheckboxSelected = event => {
    setCheckboxSelected(event);
  };

  const onFlyFishingSelected = event => {
    setCheckboxSelectedFlyFishing(event);
    selectService(event, 12);
  };

  const onYogaPilatesSelected = event => {
    setCheckboxSelectedyogaPilates(event);
    selectService(event, 13);
  };

  // Display provided services detail.
  let [yogaPilatesDetail, setYogaPilatesDetail] = useState({});
  let [flyFishingDetail, setFlyFishingDetail] = useState({});

  useEffect(() => {
    if (services.length > 0 && services !== undefined){
      services.forEach(service => {
        if (service.service_id === 12){
          setFlyFishingDetail(service);
          setCheckboxSelectedFlyFishing(service.is_provided);
          setFlyFishing(service);
        }
        if (service.service_id === 13){
          setYogaPilatesDetail(service);
          setCheckboxSelectedyogaPilates(service.is_provided);
          setYogaPilates(service);
        }
      });
    }
  }, [services]);

  useEffect(() => {
    if (flyFishingSelected || yogaPilatesSelected) {
      setCheckboxSelected(true);
    }
  }, [flyFishingSelected, yogaPilatesSelected]);
 

  const FlyFishing = (parent, nestingLevel) => {
    const inputFields = () => {
      return (
        <CardContent>
          <Grid
            container
            justify="center"
            alignItems="center"
            xs={8}
            md={8}
            lg={8}
          >
            <MaxGroupSize onTextChange={addFlyFishing} groupSize={flyFishingDetail.max_group_size} isDisabled={isDisabled}/>
            <PricePerHead onTextChange={addFlyFishing} pricePerHead={flyFishingDetail.price_per_head} isDisabled={isDisabled}/>
            <AdditionalGuests onTextChange={addFlyFishing} guestPrice={flyFishingDetail.additional_guests_price} isDisabled={isDisabled}/>
          </Grid>
        </CardContent>
      );
    };

    const children = [inputFields];

    return (
      <CollapsibleCheckBox
        name={"FLYFISHING"}
        text="FLY-FISHING (summer only) (min 2 hours duration)"
        parent={parent}
        children={children}
        nestingLevel={nestingLevel + 1}
        onCheckboxSelected={onFlyFishingSelected}
        isDisabled={isDisabled}
        isSelected={flyFishingSelected}
      />
    );
  };
  const YogaPilates = (parent, nestingLevel) => {
    const inputFields = () => {
      return (
        <CardContent>
          <Grid
            container
            justify="center"
            alignItems="center"
            xs={8}
            md={8}
            lg={8}
          >
            <MaxGroupSize onTextChange={addYogaPilates} groupSize={yogaPilatesDetail.max_group_size} isDisabled={isDisabled}/>
            <PricePerHead onTextChange={addYogaPilates} pricePerHead={yogaPilatesDetail.price_per_head} isDisabled={isDisabled}/>
            <AdditionalGuests onTextChange={addYogaPilates} guestPrice={yogaPilatesDetail.additional_guests_price} isDisabled={isDisabled}/>
          </Grid>
        </CardContent>
      );
    };

    const children = [inputFields];

    return (
      <CollapsibleCheckBox
        name={"Yoga/Pilates"}
        text="YOGA/PILATES"
        parent={parent}
        children={children}
        nestingLevel={nestingLevel + 1}
        onCheckboxSelected={onYogaPilatesSelected}
        isDisabled={isDisabled}
        isSelected={yogaPilatesSelected}
      />
    );
  };

  const children = [FlyFishing, YogaPilates];

  return (
    <CollapsibleCheckBox
      name={"Activites"}
      text="ACTIVITY HOSTS"
      parent={null}
      children={children}
      nestingLevel={0}
      onCheckboxSelected={onCheckboxSelected}
      isDisabled={isDisabled}
      isSelected={checkboxSelected}
    />
  );
};

export default Activities;
