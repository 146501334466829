import {
  ADD_TRIP_MEMBER,
  REMOVE_TRIP_MEMBER,
  CREATE_MEMBER,
  UPDATE_MEMBER,
  DELETE_MEMBER,
  CREATE_MASTER,
  UPDATE_MASTER,
  LOAD_GROUP_MEMBERS,
  GROUP_MEMBER_INFO_RETRIEVED
} from "./action-types";

export const addTripMember = memberID => {
  return {
    type: ADD_TRIP_MEMBER,
    payload: { memberID }
  };
};

export const removeTripMember = memberID => {
  return {
    type: REMOVE_TRIP_MEMBER,
    payload: { memberID }
  };
};

export const createMember = memberInfo => {
  // memberInfo should be in {} format
  return {
    type: CREATE_MEMBER,
    payload: { memberInfo }
  };
};

export const updateMember = memberInfo => {
  // memberInfo should be in {} format
  return {
    type: UPDATE_MEMBER,
    payload: { memberInfo }
  };
};

export const deleteMember = memberID => {
  // memberID is uuid
  return {
    type: DELETE_MEMBER,
    payload: { memberID }
  };
};

export const createMaster = memberInfo => {
  return {
    type: CREATE_MASTER,
    payload: { memberInfo }
  };
};

export const updateMaster = memberInfo => {
  // memberInfo should be in {} format
  return {
    type: UPDATE_MASTER,
    payload: { memberInfo }
  };
};

export const loadGroupMembers = members => {
  return {
    type: LOAD_GROUP_MEMBERS,
    payload: { members }
  };
};

export const retrieveGroupMemberInfo = memberInfos => {
  return {
    type: GROUP_MEMBER_INFO_RETRIEVED,
    payload: { memberInfos }
  };
};
