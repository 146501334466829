import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  heading: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "35px",
    lineHeight: "128.91%",
    letterSpacing: "0.02em",
    marginBottom: "18px",
    color: "#1277b8"
  },
  [theme.breakpoints.down("xs")]: {
    heading: {
      fontSize: "20px"
    }
  }
}));

const SectionHeading = props => {
  const styles = useStyles();

  return (
    <div>
      <h1 className={styles.heading}>{props.children}</h1>
    </div>
  );
};

export default SectionHeading;
