// React
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// APIs
import agent from "../../../../agent";

// Redux
import { connect } from "react-redux";

// material ui
import { 
  List, 
  Grid, 
  Typography, 
  ListItemText,
  Checkbox,
  ListItem,
  ListItemIcon
} from "@material-ui/core";
import {
  DatePicker,
  TimePicker,
} from '@material-ui/pickers';
import { makeStyles } from "@material-ui/core/styles";
import {
  ArrowBack as ArrowBackIcon,
  ExpandLess,
  ExpandMore,
} from "@material-ui/icons";

import _ from "lodash";

// Styling - MAKE SURE IN alphabetical order
const useClasses = makeStyles( theme => ({
  categoryName :{
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#000000",
  },
  datePicker:{
    border: 'none'
  },
  headlineText: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "30px",
    lineHeight: '140.62%',
    display: "flex",
    alignItems: "center",
    color: "#000000",
  },
  listSubheader:{
    fontFamily: "PT Sans",
    fontStyle: "regular",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "25.31px",
    color: "grey",
  },
  miniComponentStyling:{
    fontFamily: "PT Sans",
    fontStyle: "regular",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "22.5px",
    color: "#000000",
    padding:'1rem'
  },
  subheadline: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: '140.62%',
    color: "#000000",
  },
  selectDropdown:{
    backgroundColor: 'none',
    margin: '0',
    outline: 'none',
    border: '1px solid #BFBFBF',
    borderRadius: '4px',
    padding: '0 0 0 2px', 
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    "&:active": {
      background: '#FFFFFF'
    },
    "&:focus": {
      background: '#FFFFFF'
    },
    "&:hover": {
      background: '#FFFFFF',
      backgroundColor: 'none'
    },
  },
  serviceName:{
    fontFamily: "PT Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#000000",
  },
}));

const MiniComponent = props => {
  const classes = useClasses();
  const [selectedDate, handleDateChange] = useState(new Date());

  return (
      <Grid
        container item spacing={1} className={classes.listSubheader} 
        direction="row" 
        alignItems="center" 
        justifyContent="flex-start"
      >
        {/* date */}
        <Grid item container spacing={1} className={classes.miniComponentStyling} direction="row" alignItems="center" justifyContent="flex-start">
          <Grid item xs={6}>
            Date
          </Grid>
          <Grid item xs={6} style={{border: '1px solid black',textAlign:'center', fontSize: '1rem'}}>
              <DatePicker  value={selectedDate} onChange={handleDateChange} />
          </Grid>
        </Grid>

        {/* start time */}
        <Grid item container spacing={1} className={classes.miniComponentStyling} direction="row" alignItems="center" justifyContent="flex-start">
          <Grid item xs={6}>
            Start
          </Grid>
          <Grid item xs={6}style={{border: '1px solid black',textAlign:'center', fontSize: '1rem'}} >
            <TimePicker value={selectedDate} onChange={handleDateChange} />
          </Grid>
        </Grid>

        {/* end time */}
        <Grid item container spacing={1} className={classes.miniComponentStyling} direction="row" alignItems="center" justifyContent="flex-start">
          <Grid item xs={6}>
            End
          </Grid>
          <Grid item xs={6} style={{border: '1px solid black',textAlign:'center', fontSize: '1rem'}}>
            <TimePicker value={selectedDate} onChange={handleDateChange} />
          </Grid>
        </Grid>

        {/* price  */}
        <Grid item container spacing={1} className={classes.miniComponentStyling} direction="row" alignItems="center" justifyContent="flex-start">
          <Grid item xs={6}>
            Price
          </Grid>
          <Grid item xs={6} style={{border: '1px solid black',textAlign:'center', fontSize: '1rem'}}>
            ${props.price_per_hour !== null ? props.price_per_hour : null}
          </Grid>
        </Grid>          
        
     </Grid>
  )
}

const OrderFormCard = props => {
  const classes = useClasses();
  const data = props.data
  const setTotalPrice = props.setTotalPrice
  const selectItems = props.selectItems
  const setSelectItems = props.setSelectItems

  function handleCheckbox (index,price) {
    const {click_items,price_array} = selectItems
    let sum =0

    if (click_items.includes(index) === false ) {
      click_items.push(index)
      price_array.push(price)
      setSelectItems({
        click_items:  [...click_items],    
        isdisplayed: true,
        checked: true,
        price_array: price_array
      })

      for (const element of price_array) {
        sum +=element
      }
    } else {
      const currentIndex = click_items.indexOf(index);
      click_items.splice(currentIndex,1) 
      const price_Index = price_array.indexOf(price);
        price_array.splice(price_Index,1) 

      setSelectItems({
        click_items:  [...click_items],    
        isdisplayed: false,
        checked: false,
        price_array: price_array
      })

      for (const element of price_array) {
        sum +=element
      }
    }

    setTotalPrice(sum)
  }

  const handleServiceName = (string) => {
    const regex =/<[^>]+>/g
    const split_string = string
    const new_str = string.replace(regex,'/')
    const split_array = new_str.split('/')
    const new_array = split_array.filter((element)=> element !== '')
    const service = new_array.map((service_name) => {
      return (
        <ul>
          <li>{service_name}</li>
        </ul>
      )
    })
    return service
  }

  return (
    <Grid 
      container 
      direction="column" 
      alignItems="flex-start" 
      justifyContent="center" 
      style={{width: '100%', border: ' 1px solid #BFBFBF', padding: '4px 0 10px 1.02rem'}}
      xs={12}
      sm={12}
      md={12}
      lg={12}
    >
      { data !== null && data.map((service,index) => {
        if (props.category_name === service.service_info.catagory_name) {
          return (
            <div>
              <Grid container item xs={12}   style={{fontFamily: "PT Sans Narrow", fontWeight: '700'}}>
                <List dense>
                  <ListItem 
                    key={service.service_info.service_name} 
                    alignItems="center" 
                    button style={{paddingLeft: '0'}}
                    onClick = {
                      () => service.service_detail.price_per_hour ? 
                      handleCheckbox(index,service.service_detail.price_per_hour) : 
                      handleCheckbox(index,service.service_detail.price_per_head)
                    }
                  > 
                    <ListItemText  
                      primary={
                        service.service_info.service_name.includes('ul') ?
                        handleServiceName(service.service_info.service_name) : 
                        service.service_info.service_name
                      }
                      classes={classes.serviceName} 
                    />
                    <ListItemIcon>
                      <Checkbox edge="end" />
                    </ListItemIcon>
                  </ListItem>
                </List>
              </Grid>

              { selectItems.click_items.includes(index) ?  
                <MiniComponent
                  price_per_hour={
                    service.service_detail.price_per_hour ? 
                    service.service_detail.price_per_hour : 
                    service.service_detail.price_per_head
                  }
                /> : null }
            </div>
          )
        }
      })}  
    </Grid>
  )
}

/**This is the page for orderform
 * @todo Some funtions are not well working now, which need to be fix: 
 *       datepicker of start time and end time, service fee, checkbox in form selection
 *       number of guest, styling as figma
 */
const OrderForm = props => {
  const classes = useClasses();
  const { provider_id } = useParams();

  const [data, setData] = useState([]) 
  const [object, setObject] = useState({index: [], isClicked: false})
  const [selectItems, setSelectItems] = useState({ click_items: [], isdisplayed: false, checked: false, price_array: []})
  const [total_price, setTotalPrice]= useState(0)
  const counter = 0
  const category_object = {}

  //api call for fetching provider services data
  useEffect(() => {
    agent.Users.getProviderServices(provider_id)
      .then((res)=>{
        setData(res.data.services_pricing)
      }).catch((err)=>{
        console.log(err)
      })
  }, []);

  // function to handle category names display
  const  handleCategoryName = () => {
    data && data.map((service)=>{
      category_object[service.service_info.catagory_name] === undefined ? category_object[service.service_info.catagory_name]  = counter + 1: 
      category_object[service.service_info.catagory_name] = category_object[service.service_info.catagory_name] + 1
    })
    return category_object
  }

  function handleDropdown (index) {
    if (object.index.includes(index) === false) {
      object.index.push(index);
      setObject({
        index: object.index,    
        isClicked: true
      })
    } else {
      const currentIndex = object.index.indexOf(index);
      object.index.splice(currentIndex,1);
      setObject({
        index: object.index,    
        isClicked: false,
      })
    }  
  }

  return (
    // MAIN GRID CONTAINER
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      xs={10}
      sm={8}
      md={6}
      lg={6}
      spacing={1}
      direction="column"
      style={{ padding: "2rem 2rem" ,border: '1px solid #BFBFBF', borderRadius: '10px', margin: '2rem auto'}}
    >
      {/* Back Button 
            TODO: make it function later when we finish chat box
      */}
      <Grid item xs={12} sm={12} style={{padding: "0 0 2rem 0"}} >
        <Typography align="left"  >
          <ArrowBackIcon/> Back
        </Typography>
      </Grid>

      {/* Headline */}
      <Grid item xs={12} sm={12}>
        <Typography align="left" className={classes.headlineText}>
          CHOOSE EXPERIENCES
        </Typography>
      </Grid>

      {/* Form Select components*/}
      {/* e.g category_object = {touring hosts:2, activity host:1, child minding : 1} */}
      {handleCategoryName() && Object.keys(category_object).map((category_name,index) => {
        return ( 
          <Grid 
            item
            style={{ padding: "1rem 0 0rem", width: '100%', '&:hover': { border:'none', outline: 'none'}}}
          >
            <List > 
              <ListItem 
                key={category_name} 
                button 
                onClick={(e)=>handleDropdown(index,e)} 
                alignItems="center"
                className={classes.selectDropdown}
                style={{borderBottomLeftRadius: object.isClicked  ? 0: '4px', borderBottomRightRadius: object.isClicked  ? 0: '4px'}}
              >
                <ListItemText primary={category_name} style={{paddingLeft: '1.02rem'}}   className={classes.categoryName} />
                {object.isClicked === true && object.index.includes(index) ?  
                  <ExpandMore /> :
                  <ExpandLess /> 
                }
              </ListItem>
              
              {object.index.includes(index) ? 
                <OrderFormCard  
                  data={data} 
                  category_name={category_name}  
                  setTotalPrice={setTotalPrice}
                  selectItems={selectItems}
                  setSelectItems={setSelectItems}
                /> : null }  
          
            </List>
          </Grid>  
        )   
      })}   

      {/* Price, Service Charge, Total */}
      <Grid item xs={12} sm={12}>
        <Typography align="left" className={classes.headlineText}>
          Pricing
        </Typography>
      </Grid>

      {/* TODO: Service Charge should be 15%? (confirm with Jody) of total  */}
      <Grid item container xs={12} sm={12} className={classes.subheadline}>
        <Grid xs={4}>
          Service Charge
        </Grid>
        <Grid item xs={6} align="right">
          $5
        </Grid>
      </Grid>
          
      <Grid item container xs={12} sm={12} className={classes.subheadline}>
        <Grid xs={4}>
          Total
        </Grid>
        <Grid item xs={6} align="right"  >
          ${total_price}
        </Grid>
      </Grid>
    </Grid>
  );
};

function mapDispatchToProps(dispatch) {
  return {};
}

function mapStateToProps(state) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderForm);
