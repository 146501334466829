import Ws from "@adonisjs/websocket-client";

// import { getSocketProtocol } from "../utils/data";

export class SocketConnection {
  connect() {
    //placeholder
    this.ws = Ws("ws://localhost:3333")
      // .withApiToken(token)
      .connect();

    //change this back and add environment variable
    //see tutorial if confused https://itnext.io/setting-up-adonisjs-websockets-with-react-app-demo-27bb0bb7500f
    // this.ws = Ws(`${getSocketProtocol()}${process.env.REACT_APP_API_URL}`)
    //   // .withApiToken(token)
    //   .connect();

    this.ws.on("open", () => {
      //console.log("Connection initialized");
    });

    this.ws.on("close", () => {
      //console.log("Connection closed");
    });

    return this;
  }

  subscribe(channel, handler) {
    if (!this.ws) {
      setTimeout(() => this.subscribe(channel), 1000);
    } else {
      const result = this.ws.subscribe(channel);

      result.on("message", message => {
        //console.log("Incoming", message);
        handler(message);
      });

      result.on("error", error => {
        console.error(error);
      });

      return result;
    }
  }
}

export default new SocketConnection();
