import React from "react";
import { connect } from "react-redux";
import { deleteMember } from "../../../../redux/actions/tripWhoActions";
import $ from "jquery";
import {
  OrangeButton,
  GrayButton,
  FormLabel
} from "../../../../styling/styled-components/index";
import StyledModal from "../../StyledModal";

function ConfirmModal(props) {
  const { firstName, lastName, member_uuid, deleteMember } = props;
  const id = "confirmDelete-".concat(member_uuid);
  const body = (
    <FormLabel>
      Are you sure to delete{" "}
      <b>
        {firstName} {lastName}
      </b>
      ?
    </FormLabel>
  );

  const footer = (
    <div>
      <GrayButton
        style={{ margin: "0" }}
        onClick={() => {
          $("#confirmDelete-".concat(member_uuid)).modal("hide");
        }}
      >
        No
      </GrayButton>
      <div style={{ display: "inline-block", width: "10px" }} />
      <OrangeButton
        style={{ margin: "0" }}
        onClick={() => {
          $("#confirmDelete-".concat(member_uuid)).modal("hide");
          deleteMember(member_uuid);
        }}
      >
        Yes
      </OrangeButton>
    </div>
  );

  return <StyledModal id={id} body={body} footer={footer} />;
}

function mapDispatchToProps_ConfirmModal(dispatch) {
  return {
    deleteMember: memberID => dispatch(deleteMember(memberID))
  };
}

export default connect(
  null,
  mapDispatchToProps_ConfirmModal
)(ConfirmModal);
