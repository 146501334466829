import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// Material-UI 
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  FormControl,
  TextField,
  FormHelperText
} from "@material-ui/core";

// Styles
import useStyles from "../../../Common/SignupForm/ClientInfoStyles";

const MAX_INPUT_LENGTH = 500;
const useClasses = makeStyles(theme => ({
  label: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    textTransform: "uppercase",
    color: "#000000"
  },
  errorText:{
    color: "#f44336", marginLeft: "14px"
  },
  helperText:{
    marginLeft: "14px"
  },
  textFieldBio: {
    border: "1px solid #000000",
    boxSizing: "border-box",
    borderRadius: "10px",
    width: "100%",
    "& .MuiOutlinedInput-inputMultiline": {
      maxWidth: "520px"
    },
    ".MuiOutlinedInput-root": {
      margin: "0"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0"
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0"
    },
    "& .MuiInputBase-input": {
      fontFamily: "PT Sans Narrow",
      fontSize: "16px"
    }
  },
  providerOnlyStyle: props => ({
    marginLeft: props.marginLeft,
    width: props.width
  })
}));

let BioForm = props => {
  let { bio, setBio, isDisabled, providerOnlyStyle } = props;

  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState(" ");

  const styles = useStyles();
  const classes = useClasses(providerOnlyStyle);

  const handleBioChange = event => {
    setHelperText(`${event.target.value.length} of ${MAX_INPUT_LENGTH} max characters. `);
    setError(false);
    setBio(event.target.value);
  };

  const handleBlur = event => {
    if (event.target.value == "" || event.target.value == null) {
      setError(true);
    }
  };

  const handleFocus = event => {
    if (bio != "" && bio != null){
      setHelperText(`${bio.length} of ${MAX_INPUT_LENGTH} max characters. `);
    } else {
      setHelperText(`0 of ${MAX_INPUT_LENGTH} max characters. `);
    }
  };

  return (
    <Grid
      item
      container
      xs={12}
      sm={8}
      direction="column"
      justify="center"
      alignItems="left"
    >
      {/* Bio Header */}
      <Grid item className={classes.providerOnlyStyle} style={{ padding: "0.5rem 0"}}>
        <Typography className={styles.allergyandotherinformation}>
          BIO
          <text style={{ fontWeight: "normal" }}>{`(max ${MAX_INPUT_LENGTH} words)`}</text>
        </Typography>
      </Grid>

      {/* Bio form */}
      <Grid item className={classes.providerOnlyStyle}>
        <FormControl
          variant="outlined"
          color="primary"
          classes={{ root: classes.selectRoot }}
          className={classes.textFieldBio}
          disabled={isDisabled}
        >
          <TextField
            id="outlined-textarea"
            placeholder="Introduce yourself -  Where you're from, what your hobbies are, etc..."
            multiline
            rows="6"
            variant="outlined"
            value={bio}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onChange={handleBioChange}
            disabled={isDisabled}
            inputProps={{ maxLength: MAX_INPUT_LENGTH }} // The input limits to 500 characters
          />
        </FormControl>
      </Grid>

      <Grid item>
        {error ? (
          <FormHelperText className={classes.errorText}>
            *Required
          </FormHelperText>
        ) : (
          <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>
        )}
      </Grid>
    </Grid>
  );
};

// Reducer part
function mapStateToProps(state, ownProps) {
  return {};
}

// Adding Dispatch to update the existing values in Redux
const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BioForm);
