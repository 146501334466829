import React from "react";
import { Form } from "react-bootstrap";

export default function CustomFormLabel(props) {
  return (
    <Form.Label
      style={{
        color: "#686369",
        fontSize: "23px"
      }}
    >
      {props.children}
    </Form.Label>
  );
}
