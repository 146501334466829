import React, { useState } from "react";
import { reduxForm, Field } from "redux-form";
import connect from "react-redux/es/connect/connect";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";

import {
  PROFILE_FIELD_NAMES,
  scrollerOptions
} from "../../../constants/common";
import SkillRangeInputForExploreInstructors from "./SkillRangeInputForExploreInstructors";
import SavedInstructors from "./SavedInstructors";
import InstructorCard from "../InstructorCard/InstructorCard.js";
import CardPagination from "../../Common/CardPagination";
import { toggleInstructorLike } from "../../../redux/actions/instructorDataActions";

const useStyles = makeStyles(theme => ({
  main: {
    textAlign: "center"
  },
  gridContainer: {
    marginBottom: "2em",
    marginRight: "2em",
    // marginLeft: "2em",
    justifyContent: "space-around",
    alignItems: "center"
  },
  gridItem: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "2em"
    }
  },
  prompt: {
    fontWeight: "bold",
    fontStyle: "normal",
    fontFamily: "PT Sans Narrow",
    color: "#505050",
    display: "inline-block"
  },
  select4: {
    maxWidth: "210px",
    minWidth: "146px",
    marginRight: "1.5em",
    backgroundColor: "rgb(255,97,97)",
    borderBottomColor: "rgb(255,97,97)",
    borderRadius: 6,
    width: "97%"
  },
  label: {
    color: "rgb(255,251,251,1)",
    fontFamily: "PT Sans Narrow",
    "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -20px) scale(0.75);",
      color: "#505050"
    }
  },
  inputSelect: {
    // marginTop: "0.2em",
    textAlign: "center",
    flexWrap: "wrap",
    display: "flex",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline-notchedOutline": {
      borderWidth: 0
    }
  },
  selectRoot: {
    textAlign: "left"
  },
  selectIcon: {
    color: "rgb(255,251,251,1)"
  },
  subHeaderRoot: {
    color: "#1277B8",
    lineHeight: 1.3,
    paddingLeft: "2em",
    fontWeight: 400
  },
  listRoot: {
    whiteSpace: "normal",
    width: "18em",
    height: "2.1em"
  },
  listRootForAbility: {
    whiteSpace: "normal",
    width: "30em",
    height: "2.1em",
    display: "flex"
  },
  field: {
    width: "25em",
    marginTop: "0.5em"
  },

  primary: {
    textAlign: "left",
    fontSize: 14,
    color: "#505050",
    fontWeight: 300
  },
  or: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "2em"
    }
  },
  searchBox: {
    justifyContent: "flex-start",
    paddingRight: 83,
    [theme.breakpoints.down("sm")]: {
      marginBottom: "2em"
    }
  },
  search: {
    backgroundColor: "rgb(255,251,251,1)",
    maxWidth: "210px",
    minWidth: "186px"
  },

  searchInput: {
    color: "rgb(255,97,97)"
  },
  searchLabel: {
    fontFamily: "PT Sans Narrow",
    color: "rgb(255,97,97)"
  },
  cards: {
    paddingTop: "4em"
  }
}));

const theme = createMuiTheme({
  overrides: {
    MuiMenu: {
      list: {
        maxHeight: "40em"
      }
    },
    MuiIconButton: {
      label: {
        justifyContent: "flex-start"
      }
    },
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "rgb(255,97,97)",
          borderWidth: 1
        },
        "&$focused $notchedOutline": {
          borderColor: "rgb(255,97,97)",
          borderWidth: 1
        },
        "&:hover $notchedOutline": {
          borderColor: "rgb(255,97,97)",
          borderWidth: 1
        }
      },
      notchedOutline: {}
    },
    palette: {
      primary: {
        main: "#FFFFFF"
      },
      secondary: {
        main: "#FF6161"
      }
    }
  }
});

const FiltersForExploreInstructors = props => {
  const classes = useStyles();
  const [selectedCriteria, setSelectedCriteria] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedAbility, setSelectedAbility] = useState([]);

  const handleCriteria = event => {
    if (!event.target.value) return;
    const value = event.target.value.filter(x => x);
    setSelectedCriteria(value);
  };

  const handleSearch = event => {
    setSearch(event.target.value);
  };

  const handleAbility = event => {
    setSelectedAbility(event.target.value);
  };

  const { specialties, languages, nationalities } = props;
  const instructors = props.instructorData;
  const toggleInstructorLike = props.toggleInstructorLike;

  //filters instructors based on selected filters
  const filteredCards = instructors.filter(instructor => {
    return (
      instructor.instructorName.toLowerCase().indexOf(search.toLowerCase()) !==
      -1
    );
  });

  const savedInstructors = instructors.filter(instructor => instructor.isLiked);
  const savedInstructorsList = savedInstructors.map(
    instructor => instructor.instructorName
  );

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const cardsOnPage = filteredCards.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage
  );

  const removeFromFavourites = name => {
    let chosenInstructor = savedInstructors.filter(
      instructor => instructor.instructorName === name
    );
    toggleInstructorLike(chosenInstructor[0].id);
  };

  const { SKI_ABILITY, SNOWBOARD_ABILITY } = PROFILE_FIELD_NAMES;

  return (
    <div className={classes.main}>
      <Grid container className={classes.gridContainer}>
        <ThemeProvider theme={theme}>
          {/* filter search */}
          <Grid item md={1} sm={1} xs={12} className={classes.gridItem}>
            <Typography className={classes.prompt}>Filter search</Typography>
          </Grid>

          {/* Activity and ability */}
          <Grid item md={3} sm={3} xs={12} className={classes.gridItem}>
            <FormControl
              variant="outlined"
              color="secondary"
              className={classes.select4}
            >
              <InputLabel className={classes.label}>
                Activity and Ability
              </InputLabel>
              <Select
                multiple
                value={selectedAbility}
                onChange={handleAbility}
                renderValue={selected => selected.join(", ")}
                className={classes.inputSelect}
                classes={{
                  root: classes.selectRoot,
                  icon: classes.selectIcon
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  root: {
                    maxHeight: "30em"
                  },
                  getContentAnchorEl: null
                }}
              >
                <MenuItem
                  key={SKI_ABILITY}
                  value={"Ski"}
                  classes={{ root: classes.listRootForAbility }}
                >
                  <Checkbox
                    checked={selectedAbility.indexOf("Ski") > -1}
                    size="small"
                  />
                  <ListItemText
                    className={classes.field}
                    classes={{ primary: classes.primary }}
                  >
                    <Field
                      name={SKI_ABILITY}
                      component={SkillRangeInputForExploreInstructors}
                      label={"Ski"}
                      min={1}
                      max={7}
                      parse={value => parseInt(value, 10)}
                    />
                  </ListItemText>
                </MenuItem>

                <MenuItem
                  key={SNOWBOARD_ABILITY}
                  value={"Snowboard"}
                  classes={{ root: classes.listRootForAbility }}
                >
                  <Checkbox
                    checked={selectedAbility.indexOf("Snowboard") > -1}
                    size="small"
                  />
                  <ListItemText
                    className={classes.field}
                    classes={{ primary: classes.primary }}
                  >
                    <Field
                      name={SNOWBOARD_ABILITY}
                      component={SkillRangeInputForExploreInstructors}
                      label={"Snowboard"}
                      min={1}
                      max={7}
                      parse={value => parseInt(value, 10)}
                    />
                  </ListItemText>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* criteria */}
          <Grid item md={3} sm={3} xs={12} className={classes.gridItem}>
            <FormControl
              variant="outlined"
              color="secondary"
              className={classes.select4}
            >
              <InputLabel className={classes.label}>Criteria</InputLabel>
              <Select
                multiple
                value={selectedCriteria}
                onChange={handleCriteria}
                renderValue={selected => selected.join(", ")}
                className={classes.inputSelect}
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot,
                  icon: classes.selectIcon
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  root: {
                    maxHeight: "30em"
                  },
                  getContentAnchorEl: null
                }}
              >
                <ListSubheader
                  classes={{ root: classes.subHeaderRoot }}
                  disableSticky
                >
                  Specialties
                </ListSubheader>
                {specialties.map(specialty => (
                  <MenuItem
                    key={specialty}
                    value={specialty}
                    classes={{ root: classes.listRoot }}
                  >
                    <Checkbox
                      checked={selectedCriteria.indexOf(specialty) > -1}
                      size="small"
                    />
                    <ListItemText
                      primary={specialty}
                      className={classes.listitem}
                      classes={{ primary: classes.primary }}
                    />
                  </MenuItem>
                ))}
                <ListSubheader
                  classes={{ root: classes.subHeaderRoot }}
                  disableSticky
                >
                  Languages
                </ListSubheader>
                {languages.map(language => (
                  <MenuItem
                    key={language}
                    value={language}
                    classes={{ root: classes.listRoot }}
                  >
                    <Checkbox
                      checked={selectedCriteria.indexOf(language) > -1}
                      size="small"
                    />
                    <ListItemText
                      primary={language}
                      className={classes.listitem}
                      classes={{ primary: classes.primary }}
                    />
                  </MenuItem>
                ))}
                <ListSubheader
                  classes={{ root: classes.subHeaderRoot }}
                  disableSticky
                >
                  Nationality
                </ListSubheader>
                {nationalities.map(nationality => (
                  <MenuItem
                    key={nationality}
                    value={nationality}
                    classes={{ root: classes.listRoot }}
                  >
                    <Checkbox
                      checked={selectedCriteria.indexOf(nationality) > -1}
                      size="small"
                    />
                    <ListItemText
                      primary={nationality}
                      className={classes.listitem}
                      classes={{ primary: classes.primary }}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid
            container
            justify="flex-start"
            alignItems="center"
            item
            md={3}
            sm={3}
            xs={12}
          >
            {/* or */}
            <Grid item md={1} sm={1} xs={2} className={classes.or}>
              <Typography variant="body1" className={classes.prompt}>
                Or:
              </Typography>
            </Grid>

            {/* search card input box */}
            <Grid item md={4} sm={4} xs={10} className={classes.searchBox}>
              <TextField
                id="search"
                label="Search by instructor name"
                type="search"
                color="secondary"
                justify="flex-start"
                onChange={handleSearch}
                value={search}
                className={classes.search}
                InputProps={{
                  className: classes.searchInput
                }}
                InputLabelProps={{
                  className: classes.searchLabel
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>

          {/*instructor cards whole section */}
          <Grid container justify="space-around" className={classes.cards}>
            {/* favorate instructors */}
            <Grid item xs={12} md={3}>
              <SavedInstructors
                instructors={savedInstructorsList}
                onClick={removeFromFavourites}
              />
            </Grid>

            {/* listed cards */}
            <Grid container item xs={10} md={8} spacing={4}>
              {cardsOnPage.map(card => (
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  item
                  key={card}
                  lg={4}
                  md={6}
                  sm={6}
                  xs={12}
                >
                  {console.log(card)}

                  <InstructorCard
                    // favourites={favInstructors}
                    onClick={toggleInstructorLike}
                    {...card}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>

          {/* card pagination */}
          <Grid
            container
            justify="center"
            alignItems="flex-start"
            item
            sm={12}
            xs={12}
          >
            <Grid item xs={6}>
              <CardPagination
                count={filteredCards.length}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
              />
            </Grid>
          </Grid>
        </ThemeProvider>
      </Grid>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    instructorData: state.instructorReducer.data.fakeData,
    favInstructors: state.instructorReducer.data.favInstructors,
    specialties: state.instructorReducer.data.specialties,
    languages: state.instructorReducer.data.languages,
    nationalities: state.instructorReducer.data.nationalities
  };
}

const mapDispatchToProps = dispatch => {
  return {
    toggleInstructorLike: instructorId =>
      dispatch(toggleInstructorLike(instructorId))
  };
};

/* export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FiltersForExploreInstructors); */

export default reduxForm({
  form: "myForm"
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FiltersForExploreInstructors)
);
