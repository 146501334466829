import React from "react";
import useStyles from "./MyAccountStyles";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  ListItemText
} from "@material-ui/core";

const ExperLang = ({
  studentData,
  filterData,
  _editExperience,
  _editLanguages,
  journey,
  select
}) => {
  const classes = useStyles();

  return (
    <Grid item container md={12} direction="row">
      <Grid item lg={2} md={2} sm={2} xs={2}>
        {journey === "vl-stu" || journey === "vl-ins" ? (
          journey === "vl-stu" ? (
            <div align="right" className={classes.textFieldStyle}>
              Experience:
            </div>
          ) : (
            <div align="right" className={classes.textFieldStyle}>
              Experience as an Instructor:
            </div>
          )
        ) : (
          <div align="right" className={classes.textFieldStyle}>
            Experience as Local:
          </div>
        )}
      </Grid>
      <Grid item lg={1} md={1} sm={1} xs={1} />
      <Grid item lg={2} md={2} sm={2} xs={9}>
        <div align="left">
          <input
            fullWidth
            id="cardname"
            variant="filled"
            className={classes.textField}
            size="small"
            onChange={event => _editExperience(event.target.value)}
            value={studentData.experience}
          />
        </div>
      </Grid>
      <Grid item lg={1} md={1} sm={1} />
      <Grid item lg={1} md={1} sm={1} xs={2}>
        <div className={classes.textFieldStyle} align="right">
          Languages:
        </div>
      </Grid>
      <Grid item lg={1} md={1} sm={1} xs={1} />
      <Grid item lg={3} md={3} sm={3} xs={9}>
        <div align="left">
          <Select
            multiple
            id="standard-select-language"
            variant="outlined"
            // disabled={disabled}
            select={select}
            onChange={event => _editLanguages(event.target.value)}
            value={studentData.languages}
            renderValue={selected => selected.join(", ")}
            className={classes.textFieldLanguage}
          >
            {filterData.languages.map(lang => (
              <MenuItem key={lang} value={lang}>
                <Checkbox checked={studentData.languages.indexOf(lang) > -1} />
                <ListItemText primary={lang} />
              </MenuItem>
            ))}
          </Select>
        </div>
      </Grid>
      <Grid item lg={1} md={1} sm={1} />
    </Grid>
  );
};

export default ExperLang;
