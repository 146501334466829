// Actions
import { LOGIN,LOGOUT } from "./../actions/action-types.js";

const loginReducerDefaultState = {
  userData: null
};

const loginReducer = (state = loginReducerDefaultState, action) => {
  switch (action.type) {
    case LOGIN: {
      return { ...state, userData: action.payload };
    }
    case LOGOUT:
      return {};
      
    default:
      return state;
  }
};

export default loginReducer;
