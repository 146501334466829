import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

// Styles
import {
  Grid,
  MenuItem,
  FormControl,
  Select,
  ListSubheader,
  ListItemText,
  Radio,
  Typography,
  Box,
  Chip,
  FormHelperText
} from "@material-ui/core";
import useStyles from "../../../Common/SignupForm/ClientInfoStyles";
import { makeStyles } from "@material-ui/core/styles";
import { COLOR } from "../../../../styling/theme/Theme";

const useClasses = makeStyles(theme => ({
  errorText:{
    color: "#f44336", 
    marginLeft: "0px"
  },
  clientRoot:{
    marginBottom: "1rem",
 },
  providerRoot:{
    marginLeft: "7.5%",
    maxWidth: "85%",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "2.5%",
      maxWidth: "95%"
    },
  },
  clientTitle:{
    padding: "0.25rem 0"
  },
  providerTitle:{
    marginBottom: "10px"
  },
  select2: {
    fontColor: "1277B8",
    fontFamily: "PT Sans Narrow",
    borderRadius: "10px",
    "& .MuiOutlinedInput-input": {
      padding: "0.5rem 0.5rem",
      height: "Auto"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black  ",
      borderWidth: "1px  "
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black  ",
      borderWidth: "1px  "
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black  ",
      borderWidth: "1px  "
    },
    "& .MuiListItemText-root": {
      fontFamily: "PT Sans Narrow",
      marginTop: "0px"
    },
    "& .MuiTypography-body1": {
      lineHeight: "inherit"
    }
  },
  label: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    marginBottom: "0.25em",
    textTransform: "uppercase",
    color: "#000000"
  },
  inputSelect: {
    marginTop: "0.2em",
    textAlign: "center",
    flexWrap: "wrap",
    display: "flex"
  },
  selectMenu: {
    width: "90%"
  },
  selectRootText: {
    textAlign: "center",
    color: COLOR.PRIMARY,
    width: "90%",
    "& div.MuiOutlinedInput-root": {
      height: "2.1rem",
      fontFamily: "PT Sans"
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      borderColor: "black"
    },
    "& .MuiOutlinedInput-root .MuiSelect-select:focus": {
      backgroundColor: "#EFEFEF"
    }
  },
  selectRoot: {
    textAlign: "center",
    borderColor: "black",
    width: "100%",
    "& div.MuiOutlinedInput-root": {
      height: "Auto",
      fontFamily: "PT SANS"
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      borderColor: "black !important"
    },
    "& .MuiOutlinedInput-root .MuiSelect-select:focus": {
      backgroundColor: "transparent"
    }
  },
  selectIcon: {
    color: COLOR.PRIMARY
  },
  search: {
    width: "17em"
  },
  checkBoxStyle: {
    "& .MuiCheckbox-root": {
      color: "#1277B8"
    }
  },
  searchRoot: {
    borderRadius: 5,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: COLOR.PRIMARY,
        borderWidth: 1,
        borderRadius: 5
      },
      "&:hover fieldset": {
        borderColor: COLOR.PRIMARY,
        borderWidth: 1,
        borderRadius: 5
      },
      "&.Mui-focused fieldset": {
        borderColor: "#ff0000",
        borderWidth: 1,
        borderRadius: 5
      }
    }
  },
  listRoot: {
    whiteSpace: "normal",
    alignContent: "center"
  },
  subHeaderRoot: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "140.62%",
    display: "flex",
    alignItems: "center",
    color: COLOR.PRIMARY
  },
  primary: {
    fontFamily: "PT Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "140.62%",
    color: COLOR.PRIMARY
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  }
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function AbilityLevel(props) {
  const styles = useStyles();
  const classes = useClasses();

  const { snowBoardAbilityLevel_list, skiAbilityLevel_list } = props;
  const {
    skiLevel,
    setSkiLevel,
    snowboardLevel,
    setSnowboardLevel,
    isDisabled,
    providerOnlyStyle
  } = props;

  let [error, setError] = useState(false);
  let [selectedAbilityLevel, setAbilityLevel] = useState([]);

  const handleAbilityLevelChange = event => {
    let skiValue = 0;
    let snowboardValue = 0;
    const selectedLevel = event.target.value;

    if ( selectedLevel != [] ) {
      selectedLevel.map(item => {
        if (item.includes("Skiing")) {
          skiValue = item.substring(item.length - 1);
        } else if (item.includes("Snowboard")) {
          snowboardValue = item.substring(item.length - 1);
        }
      });
    };

    setSkiLevel(skiValue); // eg. 0 orginally
    setSnowboardLevel(snowboardValue); // eg. 0 orginally
    setAbilityLevel(selectedLevel); // ['Skiing Ability Level 1', 'Snowboard Ability Level 4']
  };

  const handleClose = event => {
    (selectedAbilityLevel.length == 2) ? setError(false) : setError(true);
  };

  useEffect(() => {
    if (skiLevel != undefined && snowboardLevel != undefined) {
      let skiLevelStr = "Skiing Ability Level " + skiLevel;
      let snowboardLevelStr = "Snowboard Ability Level " + snowboardLevel;

      if (skiLevel == 0 || snowboardLevel == 0 ) {
        (skiLevel == 0 && snowboardLevel == 0) ? setAbilityLevel([]) :
        (skiLevel == 0) ? setAbilityLevel([snowboardLevelStr]) :
        setAbilityLevel([skiLevelStr]) 
      } else {
        setAbilityLevel([skiLevelStr, snowboardLevelStr]);
      }
    }
  }, [skiLevel, snowboardLevel]);

  return (
    <Grid
      item
      container
      xs={12}
      sm={providerOnlyStyle ? 6: 4}
      direction="column"
      justify="center"
      alignItems="left"
      className={providerOnlyStyle ? classes.providerRoot : classes.clientRoot}
    >
      <Grid item className={providerOnlyStyle ? classes.providerTitle : classes.clientTitle}>
        <Typography className={styles.allergyandotherinformation}>
          ABILITY LEVEL
        </Typography>
        <Typography style={{fontSize:"10px"}}>
        <i>Instructors must indicate ability level you are qualified to teach for virtual lessons</i>
        </Typography>
      </Grid>
      <Grid item container justify="center" alignItems="left">
        <FormControl
          variant="outlined"
          color="primary"
          classes={{ root: classes.selectRoot }}
          disabled={isDisabled}
          error={error}
        >
          <Select
            multiple
            onClose={handleClose}
            value={selectedAbilityLevel}
            onChange={handleAbilityLevelChange}
            renderValue={selected => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {selected.map(value => (
                  <Chip
                    style={{ fontFamily: "PT Sans Narrow" }}
                    key={value}
                    label={value}
                  />
                ))}
              </Box>
            )}
            className={classes.select2}
            classes={{
              selectMenu: classes.selectMenu,
              root: classes.selectRoot,
              icon: classes.selectIcon,
              selected: classes.selected
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
          >
            {/* Ski */}
            <ListSubheader
              classes={{ root: classes.subHeaderRoot }}
              disableSticky
              value="value"
            >
              SKIING
            </ListSubheader>
            {skiAbilityLevel_list.map(skiingAbilityLevel => (
              <MenuItem
                key={skiingAbilityLevel.id}
                value={skiingAbilityLevel.key}
                classes={{ root: classes.listRoot }}
              >
                <Radio
                  checked={selectedAbilityLevel.includes(
                    skiingAbilityLevel.key
                  )}
                  value={skiingAbilityLevel.key}
                  disableRipple
                  color="primary"
                  className={classes.root}
                />
                <ListItemText
                  primary={skiingAbilityLevel.level}
                  className={classes.listItem}
                  classes={{ primary: classes.primary }}
                />
              </MenuItem>
            ))}

            {/* Snowboard */}
            <ListSubheader
              classes={{ root: classes.subHeaderRoot }}
              disableSticky
              value="value"
            >
              SNOWBOARDING
            </ListSubheader>
            {snowBoardAbilityLevel_list.map(snowBoardingAbilityLevel => (
              <MenuItem
                // disabled={visibilitySnowBoarding}
                key={snowBoardingAbilityLevel.id}
                value={snowBoardingAbilityLevel.key}
                classes={{ root: classes.listRoot }}
              >
                <Radio
                  checked={selectedAbilityLevel.includes(
                    snowBoardingAbilityLevel.key
                  )}
                  value={snowBoardingAbilityLevel.key}
                  // inputProps={{ "aria-label": "C" }}
                  disableRipple
                  color="primary"
                  className={classes.root}
                />
                <ListItemText
                  primary={snowBoardingAbilityLevel.level}
                  className={classes.listitem}
                  classes={{ primary: classes.primary }}
                />
              </MenuItem>
            ))}
          </Select>
          {error ? <FormHelperText className={providerOnlyStyle ? classes.errorText : null}>*Required</FormHelperText> : <FormHelperText>&nbsp;</FormHelperText>}
        </FormControl>
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    snowBoardAbilityLevel_list: state.exploreHostsReducer.snowBoardAbilityLevel,
    skiAbilityLevel_list: state.exploreHostsReducer.skiAbilityLevel
  };
}

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AbilityLevel);
