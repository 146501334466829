// Redux, React
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { useHistory } from "react-router-dom";
import { getProviderProfile } from "../../../redux/actions/stepperActions";
import { getCountryList, getResortList } from "../../../redux/actions/homePageAction.js";


// APIs
import agent from "../../../agent";
// import Store from "../../../redux/store";

//Material UI
import Grid from "@material-ui/core/Grid";
import { Button, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

//Components
import Services from "../../../components/Common/SignupForm/ProviderInfo/Services/Services";
import Bio from "../../../components/Common/SignupForm/UserInfo/Bio";
import Nationality from "./NationalityDropdown";
import Languages from "./LanguageDropdown";
import AbilityLevel from "./AbilityLevelDropdown";
import Countries from "./CountryDropdown";
import SuitableFor from "./SuitableForDropdown";
import ProfileAvatar from "./ProfileAvatar";
import ImageGalleryForm from "../../../components/serviceMarket/profile/ImageGalleryForm";

const useStyles = makeStyles(theme => ({
  root: {
    width: "auto",
    marginTop: "2rem",
    display: "flex",
    padding: "0 10rem"
  },
  headerLabel: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "26px",
    color: "#000000"
  },
  avatarSize: {
    width: "10rem",
    height: "10rem",
    marginBottom: "15%",
    [theme.breakpoints.down("sm")]: {
      width: "10rem",
      height: "10rem"
    }
  },
  divAvatar: {
    textAlign: "center",
    margin: "0 auto"
  },
  myProfileLabel: {
    fontSize: "x-large",
    width: "50%",
    margin: "3rem 0",
    fontFamily: "PT Sans Narrow",
    paddingLeft: "1rem"
  },
  editProfileLabel: {
    width: "50%",
    margin: "3rem 0",
    textAlign: "right",
    paddingRight: "1rem",
    fontFamily: "PT Sans Narrow",
    color: "royalblue"
  },
  sectionMargin: {
    margin: "0.5rem 0",
    width: "100%"
  },
  btnDisabled: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 16,
    marginTop: 8,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14
    },
    color: "#BDBDBD",
    border: "none",
    backgroundColor: "transparent",
    padding: 0
  },
  saveButton: {
    width: "102px",
    height: "2.5em",
    borderRadius: 100,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      marginRight: "20px"
    }
  },
  buttonLabel: {
    color: "white",
    fontWeight: 700,
    letterSpacing: "1%",
    fontFamily: "PT Sans"
  },
  centerBtns: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-around",
      alignItems: "center"
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
      alignItems: "center"
    }
  }
}));

function ProviderProfile(props) {
  const classes = useStyles();
  const { userData, userProfile } = props;
  const history = useHistory();
  let [profileImage, setProfileImage] = useState({});
  let [bio, setBio] = useState("");
  let [nationality, setNationality] = useState({});
  let [languages, setLanguages] = useState([{}]);
  let [skiLevel, setSkiLevel] = useState(0);
  let [snowboardLevel, setSnowboardLevel] = useState(0);
  let [countryResorts, setCountryResorts] = useState([]);
  let [suitableFor, setSuitableFor] = useState([{}]);
  let [providedServices, setProvidedServices] = useState([{}]);
  let [gallery, setGallery] = useState([]);
  let [updatedServices, setUpdatedServices] = useState({});

  let [isUploadPhotos, setUploadPhotos] = useState(false);

  // update userProfile for testing purpose - user_id 197 or 250
  useEffect(() => {
    if (userData){props.getProviderProfile(userData.data.user_id);} 
  }, []);

  useEffect(() => {
      if (props.currentUser != undefined || props.currentUser != null) {
        console.log(props.currentUser);
      } else {
        props.history.replace("/login");
      }
  }, [ props.currentUser]);

  // Update UI
  useEffect(() => {
    if (userProfile && userProfile !== undefined) {
      setSkiLevel(userProfile.user.skiing_level);
      setSnowboardLevel(userProfile.user.snowboarding_level);
      setBio(userProfile.providerBio);
      setNationality(userProfile.user.country);
      setCountryResorts(userProfile.resorts);
      setSuitableFor(userProfile.suitableFors);
      setLanguages(userProfile.user.languages);
      setProfileImage(userProfile.profileImage);
      setProvidedServices(userProfile.providerServices);
      
      let imageArray = [];
      if (userProfile.gallery != undefined) {
        if (userProfile.gallery.length > 0) {
          Array.from(new Array(6)).map((_, i) => {
            if (userProfile.gallery[i] != undefined) {
              imageArray.push({
                id: i,
                image_id: userProfile.gallery[i].id,
                image_link: userProfile.gallery[i].image_link,
                image_file: ""
              });
            } else {
              imageArray.push({
                id: i,
                image_id: 0,
                image_link: "",
                image_file: ""
              });
            }
          });
          setGallery(imageArray);
        }

        if (userProfile.gallery.length == 6) {
          setUploadPhotos(false);
        } else {
          setUploadPhotos(true);
        }
      }
    }
  }, [userProfile]);

  useEffect(() => {
    for (const obj of gallery) {
      if (obj.image_link === "") {
        setUploadPhotos(true);
      } else {
        setUploadPhotos(false);
      }
    }
  }, [gallery]);

  return (
    <form>
      <Grid 
        container
        justify="center"
        alignItems="center"
        spacing={0}
        direction="column"
        style={{ marginTop: "3rem" }}
      >
        <Grid
          container
          xs={12}
          sm={10}
          md={8}
          lg={6}
          direction="row"
          justifyContent="flex-start"
          alignItems="baseline"
        >
          <div className={classes.myProfileLabel}>My Profile</div>
          <div className={classes.editProfileLabel}>
            <Button
              color="primary"
              onClick={() => history.push("/pro/edit-my-profile")}
            >
              <span>edit profile</span>
            </Button>
          </div>
        </Grid>

        {/* Avatar and Bio section */}
        <Grid
          container
          xs={12}
          sm={10}
          md={8}
          lg={6}
          spacing={2}
          direction="row"
          justifyContent="space-evenly"
          alignItems="flex-start"
        >
          {/* Avatar section */}
          <ProfileAvatar
            setProfileImage={setProfileImage}
            profileImage={profileImage}
            isEditable={false}
          />
          {/* BIO section */}
          <Bio setBio={setBio} bio={bio} isDisabled={true} />
        </Grid>

        {/* Nationality and Language */}
        <Grid
          item
          container
          xs={12}
          sm={10}
          md={8}
          lg={6}
          spacing={2}
          justify="center"
          alignItems="flex-start"
          direction="row"
          className={classes.sectionMargin}
        >
          <Grid item xs={12} sm={6}>
            <Nationality
              setNationality={setNationality}
              nationality={nationality}
              isDisabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Languages
              setLanguages={setLanguages}
              languages={languages}
              isDisabled={true}
            />
          </Grid>
        </Grid>

        {/* Ability level and Suitable for selections */}
        <Grid
          item
          container
          xs={12}
          sm={10}
          md={8}
          lg={6}
          spacing={2}
          justify="center"
          alignItems="flex-start"
          direction="row"
          className={classes.sectionMargin}
        >
          <Grid item xs={12} sm={6}>
            <AbilityLevel
              skiLevel={skiLevel}
              snowboardLevel={snowboardLevel}
              setSkiLevel={setSkiLevel}
              setSnowboardLevel={setSnowboardLevel}
              isDisabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SuitableFor
              setSuitableFor={setSuitableFor}
              selectedLevels={suitableFor}
              isDisabled={true}
              isEditable={false}
            />
          </Grid>
        </Grid>

        {/* Countries and  Resorts selections */}
        <Grid
          item
          container
          xs={12}
          sm={10}
          md={8}
          lg={6}
          spacing={2}
          justify="center"
          alignItems="flex-start"
          direction="row"
          className={classes.sectionMargin}
        >
          <Grid item xs={12} sm={12}>
            <Countries
              setCountryResorts={setCountryResorts}
              countryResorts={countryResorts}
              isDisabled={true}
            />
          </Grid>
        </Grid>

        {/* Offered Services and Pricing section */}
        <Grid
          container
          item
          xs={12}
          sm={10}
          md={8}
          lg={6}
          justify="center"
          alignItems="flex-start"
          direction="column"
        >
          <div className={classes.sectionMargin}>
            <hr />
          </div>
          <div className={classes.sectionMargin}>
            <Services
              fromHostIntroSection={true}
              providedServices={providedServices}
              setProvidedServices={setProvidedServices}
              setUpdatedServices={setUpdatedServices}
              isDisabled={true}
            />
          </div>
        </Grid>
        {/* Image Gallery Header & Upload photos button */}
        <Grid
                container
                justify="center"
                alignItems="center"
                xs={12}
                sm={10}
                md={8}
                lg={6}
                spacing={2}
                direction="row"
                style={{ padding: "2rem 0" }}
              >
                <Grid item xs={8} sm={9}>
                  <Typography className={classes.headerLabel}>
                    Image Gallery
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  xs={4}
                  sm={3}
                >

                    <Button
                      className={classes.btnDisabled}
                      variant="text"
                      component="span"
                    >
                      Upload photos
                    </Button>
                  
                </Grid>
              </Grid>

        {/* Image Gallery List */}
        <Grid
                container
                justify="center"
                alignItems="flex-start"
                xs={12}
                sm={10}
                md={8}
                lg={6}
                spacing={2}
                direction="row"
                style={{ padding: "2rem 0px" }}
              >
                <ImageGalleryForm
                  item_id={0}
                  gallery={gallery}
                  setGallery={setGallery}
                  isDisabled={true}
                />
                <ImageGalleryForm
                  item_id={1}
                  gallery={gallery}
                  setGallery={setGallery}
                  isDisabled={true}
                />
                <ImageGalleryForm
                  item_id={2}
                  gallery={gallery}
                  setGallery={setGallery}
                  isDisabled={true}
                />
                <ImageGalleryForm
                  item_id={3}
                  gallery={gallery}
                  setGallery={setGallery}
                  isDisabled={true}
                />
                <ImageGalleryForm
                  item_id={4}
                  gallery={gallery}
                  setGallery={setGallery}
                  isDisabled={true}
                />
                <ImageGalleryForm
                  item_id={5}
                  gallery={gallery}
                  setGallery={setGallery}
                  isDisabled={true}
                />
        </Grid>
        {/* Cancel and Save Button */}
        <Grid
          container
          item
          xs={12}
          sm={10}
          md={8}
          lg={6}
          direction="row"
          style={{ margin: "2rem 0", width: "100%" }}
          className={classes.centerBtns}
        >
          <Grid item>
            <Button style={{ marginRight: "20px" }} disabled>
              Cancel
            </Button>
          </Grid>

          <Grid item>
            <Button
              disabled
              type="submit"
              className={classes.saveButton}
              classes={{
                label: classes.buttonLabel
              }}
              variant="contained"
              // style={{ background: "dimgrey"}}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

// get current user data from store
//specific which props we want to sync on this page
const mapStateToProps = state => {
  return {
    userData: state.loginReducer.userData,
    currentUser: state.auth.currentUser,
    userProfile: state.stepperReducer.userProfile
  };
};

// event or function are used to update existing values in Store
const mapDispatchToProps = dispatch => ({
  getProviderProfile: userId => dispatch(getProviderProfile(userId)),
  getCountryList: () => dispatch(getCountryList()),
});

const handleSubmit = async event => {
  event.preventDefault();
};

ProviderProfile = reduxForm({
  form: "profile",
  destroyOnUnmount: false
})(ProviderProfile);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderProfile);
