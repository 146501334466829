import React, { useEffect, useState } from "react";
import "react-chatbox-component/dist/style.css";
import { ChatBox } from "react-chatbox-component";
import axios from "axios";
import connection from "./Helpers/socket";
import { connect } from "react-redux";
import agent from "../../../agent";
import Typography from "@material-ui/core/Typography";

const Messenger = props => {
  const [test, setTest] = useState([]);
  const [room_id, setRoomId] = useState();
  const [profileRefresh, setProfileRefresh] = useState(0);
  const [myProfile, setMyProfile] = React.useState();
  const [otherProfile, setOtherProfile] = React.useState();
  const [messages, setMessages] = React.useState([]);
  const [newMessage, setNewMessage] = React.useState();

  const API_PATH = "../api/";

  const ROOM_ID = props.roomID;
  const USER_ID = props.userID.id;
  const userType = props.userType;
  //user profile - dummy data
  // const [profile1, setProfile1] = useState();
  // const [profile2, setProfile2] = useState();
  // setProfile1({
  //   id: 11,
  //   // name: "Ironman",
  //   name: props.instructorData.FirstName,
  //   uid: "user2",
  //   avatar: "https://data.cometchat.com/assets/images/avatars/ironman.png"
  // })
  // setProfile2({
  //   id: 12,
  //   // name: "Spiderman",
  //   name: props.instructorData.FirstName,
  //   uid: "user2",
  //   avatar: "https://data.cometchat.com/assets/images/avatars/spiderman.png"
  // })

  let profile1, profile2;

  // The profiles should be come from redux by using props.xxxx
  profile1 = {
    id: 11,
    name: "",
    // name: props.instructorData.FirstName,
    uid: "user2",
    avatar: "https://data.cometchat.com/assets/images/avatars/ironman.png"
  };
  profile2 = {
    id: 12,
    name: "",
    // name: props.instructorData.FirstName,
    uid: "user2",
    avatar: "https://data.cometchat.com/assets/images/avatars/spiderman.png"
  };

  let subscription;

  //subscribe/listen to chatroom
  useEffect(() => {
    setRoomId(ROOM_ID);

    //TODO: replace with function loadProfiles()
    console.log("PROFILE LOADING");
    console.log(props.instructorData.FirstName);
    let name = "test";
    if (typeof props.instructorData.FirstName !== "undefined") {
      // differentiate the current login user is student or instructor
      if (userType === "student") {
        setMyProfile({
          ...profile1,
          uid: "user1",
          name: props.studentData.FirstName,
          avatar: props.studentData.AvatarSrc
        });
        setOtherProfile({
          ...profile2,
          id: props.instructorData.id,
          uid: "user2",
          name: props.instructorData.FirstName,
          avatar: props.instructorData.AvatarSrc
        });
        setProfileRefresh(profileRefresh + 1);
      } else {
        if (userType === "instructor") {
          setMyProfile({
            ...profile1,
            uid: "user1",
            name: props.instructorData.FirstName,
            avatar: props.instructorData.AvatarSrc
          });
          setOtherProfile({
            ...profile2,
            id: props.studentData.id,
            uid: "user2",
            name: props.studentData.FirstName,
            avatar: props.studentData.AvatarSrc
          });
          setProfileRefresh(profileRefresh + 1);
        }
      }
    }

    //specify how to cleanup
    return function cleanup() {
      subscription.close();
    };
  }, [props.instructorData.FirstName]);

  //fetch messages after the profiles are loaded
  useEffect(() => {
    connection.connect();

    subscription = connection.subscribe(
      `room:${room_id}`,
      handleMessageReceived
    );

    if (typeof myProfile !== "undefined" && typeof otherProfile !== "undefined")
      fetchMessages();
  }, [profileRefresh]);

  //Adds new message to stack when newMessage is found
  useEffect(() => {
    if (typeof newMessage !== "undefined") {
      const convertedMessage = convertMessageData(newMessage);
      if (convertedMessage.sender.uid === "user2")
        handleMessageAdd(convertedMessage);
    }
  }, [newMessage]);

  //convert message to format that works with ChatBox
  //user1 if matches the logged in user
  //user2 otherwise
  const convertMessageData = message => {
    let uid = "user2";
    let profile = otherProfile;
    if (message.sender_user_id == myProfile.id) {
      profile = myProfile;
      uid = "user1";
    }
    return { text: message.message, sender: { ...profile, uid: uid } };
  };

  //what happens when a new message is recieved
  const handleMessageReceived = message => {
    const messageData = message.data;
    setNewMessage(messageData);
  };

  //fetch messages (from the backend database) - this updates the messages state
  const fetchMessages = async () => {
    try {
      const room = await axios
        .get(API_PATH + "messagingRoom/latestFew/" + room_id, {})
        .then(response => {
          const newMessages = response.data.messageData.map(message => {
            return convertMessageData(message);
          });
          setMessages(newMessages.reverse());
        })
        .catch(e => console.log(e));
      //put new message
    } catch (e) {
      console.log(e);
    }
  };

  const handleMessageAdd = newMessage => {
    setMessages([...messages, newMessage]);
  };

  //what happens when a message is submitted
  const handleSubmit = message => {
    // if there is no virtual booking between the student and instructor
    // not allow to send an email, a Link, or a number
    // if (
    //   props.virtualBookingData == null &&
    //   (/[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}/.test(message) ||
    //     /^.*zoom.us.*/.test(message) ||
    //     /(http\:\/\/|https\:\/\/)?([a-z0-9][a-z0-9\-]*\.)+[a-z0-9][a-z0-9\-]*/.test(message) ||
    //     /[\s\d]{8,15}/.test(message))
    // ) {
    //   alert(
    //     "sending email, link or phone number is not allowed, " +
    //     "\n" +
    //     "if there is no virtual booking with him/her"
    //   );
    // } else {
    let filteredMessage;
    let newMessage;
    let emailPattern = /[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}/g;
    let urlPattern = /(http\:\/\/|https\:\/\/)?([\w\d][\w\d\-]*\.)+[\w\d][\w\d\-\/\?\=\&\%\+\.]*/g;
    let phoneNumberPattern = /[\s\d]{8,15}/g;
    if (props.virtualBookingData == null) {
      // filter by different pattern
      if (emailPattern.test(message)) {
        message = message.replace(
          emailPattern,
          " (Email hidden by RESORTer.app)"
        );
        //
        // } else if (zoomLinkPattern.test(message)) {
        //   console.log("the message contains a Zoom link");
        //   message = message.replace(zoomLinkPattern, "(Zoom Link hidden by Resorter.app)");
      } else if (urlPattern.test(message)) {
        message = message.replace(urlPattern, "(Url hidden by RESORTer.app)");
      } else if (phoneNumberPattern.test(message)) {
        message = message.replace(
          phoneNumberPattern,
          "(Phone number hidden by RESORTer.app)"
        );
      }
    } else {
    }

    newMessage = {
      text: message,
      id: messages.length,
      sender: myProfile
    };

    handleMessageAdd(newMessage);
    // axios
    //   .post(API_PATH + "messagingRoom/createMessage/" + room_id, {
    //     messageData: message,
    //     sender_user_id: myProfile.id
    //   })
    agent.Message.addMessage(room_id, message, myProfile.id)
      .then(response => {
        //console.log(response);
      })
      .catch(e => console.log(e));
  };

  return (
    <div>
      {userType === "none" ? (
        <Typography>You don't belong to this message room!</Typography>
      ) : (
        <ChatBox messages={messages} onSubmit={handleSubmit} />
      )}
    </div>
  );
};

// get the current user form redux
function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser,
    messageRoomData: state.chatPageReducer.messageRoomData,
    instructorData: state.chatPageReducer.instructorData,
    studentData: state.chatPageReducer.studentData,
    virtualBookingData: state.chatPageReducer.virtualBookingData
  };
}

export default connect(mapStateToProps)(Messenger);
