import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Mastercard from "./Mastercard.jpg";
import VISA from "./visa.svg";
import Paypal from "./Paypal.png";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(theme => ({
  textFieldStyle: {
    //
    fontFamily: "PT Sans Narrow",
    fontWeight: "bold",
    color: "#1277B8",
    fontSize: 18,
    lineHeight: "140.62%",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14
    }
  },
  textFieldPopup: {
    height: 45,
    width: "100%",
    fontSize: "5",
    size: "small",
    padding: "0em",
    paddingLeft: "0.5rem",
    border: 10,
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    borderRadius: 0,
    "&::placeholder": {
      color: "#1277B8"
    }
  },
  textField1: {
    height: 45,
    width: "100%",
    fontSize: "5",
    size: "small",
    padding: "0em",
    border: 10,
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    borderRadius: 0,
    marginBottom: 10
  },
  imgsizeVisaAndMaster: {
    //
    width: 61,
    height: 32
  },
  imgsizepaypal: {
    width: 70,
    height: 52
  },
  paymentInputLabel: {
    color: "black",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "140.62%"
  },
  paymentBtnContainer: {
    textAlign: "left",
    width: "55%"
  },
  payoutContainer: {
    textAlign: "left"
  },
  // payoutSection: {
  //   margin: "2em 0",
  //   textAlign: "left",
  //   width: "75%"
  // },
  paymentMethodImgContainer: {
    //
    marginLeft: "1em"
  },
  paymentDialog: {
    padding: "0 2rem"
  },
  // cardInfoContainer: {
  //   width: "75%",
  //   margin: "0 auto"
  // },
  cardInfo: {
    height: "44px",
    backgroundColor: "rgba(18, 119, 184, 0.15)"
  },
  cardInfoGrid: {
    margin: "1rem 0"
  },
  cardInfoText: {
    lineHeight: 2.5
  },
  bankaccountstyle: {
    fontFamily: "PT Sans Narrow",
    fontSize: "0.8rem"
  },
  contentstyle: {
    fontFamily: "PT Sans Narrow",
    textAlign: "left"
  },
  fontfamily: {
    fontFamily: "PT Sans Narrow"
  }
}));

export default function PaymentPopup({ handleClose, hideActionButtons }) {
  const classes = useStyles();

  return (
    <div className={classes.paymentDialog}>
      <Grid md={12} container direction="row">
        <Grid item md={10} xs={10}>
          <div align="left" className={classes.textFieldStyle}>
            ACCEPTED PAYMENTS
            <div className={classes.paymentMethodImgContainer}>
              <img
                src={VISA}
                position="absolute"
                align="center"
                top="20px"
                alt="Visa card logo"
                className={classes.imgsizeVisaAndMaster}
              />
              <img
                src={Mastercard}
                align="center"
                position="absolute"
                top="20px"
                alt="Master card logo"
                className={classes.imgsizeVisaAndMaster}
              />
              <img
                src={Paypal}
                align="center"
                position="absolute"
                top="20px"
                alt="Paypal logo"
                className={classes.imgsizepaypal}
              />
            </div>
          </div>
        </Grid>

        {hideActionButtons ? null : (
          <Grid item md={2} xs={2}>
            <div>
              <IconButton aria-label="close" onClick={() => handleClose()}>
                <CloseIcon />
              </IconButton>
            </div>
          </Grid>
        )}
      </Grid>

      <Grid
        md={12}
        xs={12}
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={3}
      >
        <Grid item md={6} xs={6}>
          <div align="left" className={classes.textFieldStyle}>
            FIRST NAME
          </div>
        </Grid>

        <Grid item md={6} xs={6}>
          <div align="left" className={classes.textFieldStyle}>
            SURNAME
          </div>
        </Grid>
      </Grid>

      <Grid
        md={12}
        xs={12}
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={6}>
          <div align="left" className={classes.textFieldStyle}>
            <input
              fullWidth
              id="cardname"
              variant="filled"
              className={classes.textFieldPopup}
              size="small"
            />
          </div>
        </Grid>

        <Grid item xs={6}>
          <div align="left" className={classes.textFieldStyle}>
            <input
              fullWidth
              id="cardname"
              variant="filled"
              className={classes.textFieldPopup}
              size="small"
            />
          </div>
        </Grid>
      </Grid>

      <Grid
        xs={12}
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12}>
          <div align="left" className={classes.textFieldStyle}>
            CARD INFO
          </div>
        </Grid>
      </Grid>

      <Grid
        md={12}
        xs={12}
        container
        direction="row"
        spacing={3}
        justify="space-between"
        alignItems="center"
      >
        <Grid item md={12} xs={12}>
          <div align="left">
            <input
              id="cardname"
              variant="filled"
              className={classes.textField1}
              size="small"
            />
          </div>
        </Grid>
      </Grid>

      <Grid
        md={12}
        xs={12}
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={3}
      >
        <Grid item md={6} xs={6}>
          <div align="left" className={classes.textFieldStyle}>
            <input
              fullWidth
              id="cardname"
              variant="filled"
              className={classes.textFieldPopup}
              size="small"
              placeholder="Expiry"
            />
          </div>
        </Grid>
        <Grid item md={6} xs={6}>
          <div align="left" className={classes.textFieldStyle}>
            <input
              fullWidth
              id="cardname"
              variant="filled"
              className={classes.textFieldPopup}
              size="small"
              placeholder="CVV"
            />
          </div>
        </Grid>
      </Grid>

      <Grid
        md={12}
        xs={12}
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={3}
      >
        <Grid item md={6} xs={6}>
          <div align="left" className={classes.textFieldStyle}>
            BILLING INFO
          </div>
        </Grid>
        <Grid item md={6} xs={6}>
          <div align="left" className={classes.textFieldStyle}>
            COUNTRY/REGION
          </div>
        </Grid>
      </Grid>

      <Grid
        md={12}
        xs={12}
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={3}
      >
        <Grid item md={6} xs={6}>
          <div align="left" className={classes.textFieldStyle}>
            <input
              fullWidth
              id="cardname"
              variant="filled"
              className={classes.textFieldPopup}
              size="small"
              placeholder="Postcode"
            />
          </div>
        </Grid>
        <Grid item md={6} xs={6}>
          <div align="left" className={classes.textFieldStyle}>
            <input
              fullWidth
              id="cardname"
              variant="filled"
              className={classes.textFieldPopup}
              size="small"
              placeholder="Please select an option "
            />
          </div>
        </Grid>
      </Grid>

      {hideActionButtons ? null : (
        <Grid
          md={12}
          xs={12}
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid item md={6} xs={6}>
            <div align="left" className={classes.textFieldStyle}>
              <Button
                color="primary"
                variant="contained"
                className={classes.btnpay}
              >
                Add
              </Button>
            </div>
          </Grid>
          <Grid item md={6} xs={6}>
            <div align="left" className={classes.textFieldStyle}>
              <Button
                color="primary"
                variant="contained"
                className={classes.btnpay}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
