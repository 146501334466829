import React, { useEffect, useState, useRef } from "react";
import styles from "./EditAccount.module.css";
import { Input } from "@material-ui/core";
import Button from "@mui/material/Button";
import MuiPhoneNumber from "material-ui-phone-number";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";

import { instance } from "../../../agent";

function EditAccount(props) {
  let id = localStorage.getItem("user_id");
  let [editMode, setEditMode] = useState(false);
  let [resetMode, setResetMode] = useState(false);
  let [phone, setPhone] = useState("");
  let [gender, setGender] = useState("");
  let [firstName, setFirstName] = useState("");
  let [lastName, setLastName] = useState("");
  let [password, setPassword] = useState("");
  let [repassword, setRepassword] = useState("");
  let [date, setDate] = useState("");
  let [dob, setDOB] = useState();
  let [email, setEmail] = useState("");

  let ref = useRef(null);
  let [error1, setError1] = useState("");
  let [error2, setError2] = useState("");
  let [success1, setSuccess1] = useState("");
  let [success2, setSuccess2] = useState("");

  useEffect(() => {

    const getAccountInfo = async () => {
      if (id === null || props.currentUser === null || props.currentUser === undefined) {
        props.history.replace("/login");
      } else {
        instance({
          url: `/auth/client-get/${id}`,
          method: "GET"
        })
          .then(res => {
            setFirstName(res.data.first_name);
            setEmail(res.data.email);
            setLastName(res.data.last_name);
            setGender(res.data.gender);
            setDOB(res.data.dob);
            setDate(Date.parse(res.data.dob));
            setPhone(res.data.mobile_number);
          })
          .catch(e => {
            console.log(e.toJSON());
            props.history.replace("/login");
          });
      }
    };
    getAccountInfo();
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current) {
        if (!password) {
          setError1("*Password is required.");
        } else if (
          !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/.test(
            password
          )
        ) {
          setError1(
            "*Password must be 8 characters long, has at least a number, and at least a special character!"
          );
        } else if (!repassword) {
          setError1("*Confirm password is required.");
        } else if (password !== repassword) {
          setError1("*Passwords don't match.");
        } else {
          setError1("");
        }

        if (!firstName) {
          setError2("*First name cannot be empty!");
        } else if (!lastName) {
          setError2("*Last name cannot be empty!");
        } else if (!date) {
          setError2("*Please select a valid date for DOB!");
        } else {
          setError2("");
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [password, repassword, firstName, lastName, date, gender, phone]);

  let resetPassword = async () => {
    if (!password) {
      setError1("*Password is required.");
    } else if (
      !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/.test(
        password
      )
    ) {
      setError1(
        "*Password must be 8 characters long, has at least a number, and at least a special character!"
      );
    } else if (!repassword) {
      setError1("*Confirm password is required.");
    } else if (password !== repassword) {
      setError1("*Passwords don't match.");
    } else {
      setError1("");
      instance
      .post("/auth/password-reset", {
        email,
        password
      })
      .then(res => {
        setPassword("");
        setRepassword("");
        setResetMode(false);
        setSuccess1("Your password has been successfully reset!");
      })
      .catch(error => {
        console.log(error.toJSON());
      });
    }
  };

  let updateAccount = async () => {
    if (!firstName) {
      setError2("*First name cannot be empty!");
    } else if (!lastName) {
      setError2("*Last name cannot be empty!");
    } else if (!date) {
      setError2("*Please select a valid date for DOB!");
    } else {
      setError2("");
      let data = {
        first_name: firstName,
        last_name: lastName,
        gender: gender,
        dob:
          date === Date.parse(dob)
            ? dob
            : date.getFullYear() +
              "-" +
              (date.getMonth() + 1) +
              "-" +
              date.getDate(),
        mobile_number: phone
      };
      instance
        .put(`/auth/user-info-update/${id}`, data)
        .then(res => {
          setEditMode(false);
          setError2("");
          setSuccess2("Your profile has been successfully updated!");
        })
        .catch(error => {
          setError2("*Something is wrong, your profile cannot be updated!");
          console.log(error.toJSON());
        });
    }
  };

  let handleCancel = () => {
    instance({
      url: `/auth/client-get/${id}`,
      method: "GET"
    })
      .then(res => {
        setFirstName(res.data.first_name);
        setLastName(res.data.last_name);
        setGender(res.data.gender);
        setDOB(res.data.dob);
        setDate(Date.parse(res.data.dob));
        setPhone(res.data.mobile_number);
        setError2("");
        setEditMode(false);
      })
      .catch(e => {
        console.log(e.toJSON());
      });
  }

  return (
    <div className={styles.myAccountWrap} ref={ref}>
      <p className={styles.title}>My Account</p>
      <div className={styles.label}>EMAIL</div>
      <Input
        className={styles.textField}
        disableUnderline={true}
        autoComplete="Email"
        InputLabelProps={{ shrink: false }}
        value={email}
        disabled={true}
      />
      {resetMode && (
        <div>
          <div className={styles.label}>NEW PASSWORD</div>
          <Input
            className={styles.textField}
            disableUnderline={true}
            type="password"
            autoComplete="password"
            InputLabelProps={{ shrink: false }}
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <div className={styles.label}>CONFIRM PASSWORD</div>
          <Input
            className={styles.textField}
            disableUnderline={true}
            type="password"
            autoComplete="confirm password"
            InputLabelProps={{ shrink: false }}
            value={repassword}
            onChange={e => setRepassword(e.target.value)}
          />
          {error1 !== "" && <div className={styles.error}>{error1}</div>}
        </div>
      )}
      {success1 !== "" && <div className={styles.success}>{success1}</div>}
      <hr />
      {resetMode ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "10px 0 0 300px"
          }}
        >
          <Button
            variant="text"
            style={{
              fontFamily: "PT SANS NARROW",
              fontSize: "16px",
              textTransform: "none",
              color: "#FF6161"
            }}
            onClick={() => {
              setError1("");
              setResetMode(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{
              fontFamily: "PT SANS NARROW",
              fontSize: "16px",
              textTransform: "none",
              background: "#FF6161",
              borderRadius: "100px",
              width: "100px",
              heith: "40px",
              marginLeft: "20px"
            }}
            onClick={resetPassword}
          >
            Save
          </Button>
        </div>
      ) : (
        <Button
          style={{
            fontFamily: "PT SANS NARROW",
            fontSize: "16px",
            textTransform: "none",
            marginLeft: "350px",
            width: "110px"
          }}
          variant="text"
          onClick={() => {
            setSuccess1("");
            setResetMode(true);
          }}
        >
          Reset Password
        </Button>
      )}
      <div className={styles.part2}>
        <div className={styles.label}>FIRST NAME</div>
        <Input
          className={styles.textField}
          disableUnderline={true}
          autoComplete="firstname"
          InputLabelProps={{ shrink: false }}
          disabled={!editMode}
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
        />
        <div className={styles.label}>LAST NAME</div>
        <Input
          className={styles.textField}
          disableUnderline={true}
          autoComplete="lastname"
          InputLabelProps={{ shrink: false }}
          disabled={!editMode}
          value={lastName}
          onChange={e => setLastName(e.target.value)}
        />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <div className={styles.label}>GENDER</div>
            <Select
              className={styles.dob}
              variant="standard"
              disableUnderline={true}
              InputLabelProps={{ shrink: false }}
              disabled={!editMode}
              value={gender}
              onChange={e => setGender(e.target.value)}
            >
              <MenuItem value={"female"}>Female</MenuItem>
              <MenuItem value={"male"}>Male</MenuItem>
              <MenuItem value={"not_specified"}>Not specified</MenuItem>
            </Select>
          </div>
          <div style={{ marginLeft: "15px" }}>
            <div className={styles.label}>DOB</div>
            <DatePicker
              className={styles.dob}
              selected={date}
              onChange={date => setDate(date)}
              disabled={!editMode}
            />
          </div>
        </div>
        <div className={styles.label}>MOBILE</div>
        <MuiPhoneNumber
          className={styles.phone}
          defaultCountry={"au"}
          variant="standard"
          enableLongNumbers={true}
          disabled={!editMode}
          InputProps={{
            disableUnderline: true,
            style: {
              border: "1px black solid",
              borderRadius: "10px",
              width: "222px",
              height: "40px",
              fontFamily: "PT SANS NARROW",
              paddingLeft: "10px"
            }
          }}
          disableAreaCodes={true}
          value={phone}
          onChange={value => setPhone(value)}
        />
        {editMode && error2 !== "" && (
          <div className={styles.error}>{error2}</div>
        )}
        {success2 !== "" && <div className={styles.success}>{success2}</div>}
        <hr />
        {editMode ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "10px 0 0 300px"
            }}
          >
            <Button
              variant="text"
              style={{
                fontFamily: "PT SANS NARROW",
                fontSize: "16px",
                textTransform: "none",
                color: "#FF6161"
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{
                fontFamily: "PT SANS NARROW",
                fontSize: "16px",
                textTransform: "none",
                background: "#FF6161",
                borderRadius: "100px",
                width: "100px",
                heith: "40px",
                marginLeft: "20px"
              }}
              onClick={updateAccount}
            >
              Save
            </Button>
          </div>
        ) : (
          <Button
            style={{
              fontFamily: "PT SANS NARROW",
              fontSize: "16px",
              textTransform: "none",
              marginLeft: "370px",
              width: "90px"
            }}
            variant="text"
            onClick={() => {
              setSuccess2("");
              setEditMode(true);
            }}
          >
            Edit Account
          </Button>
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser
  };
}

export default connect(mapStateToProps)(EditAccount);