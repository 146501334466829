import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import {
  incrementActiveStep,
  decrementActiveStep,
  incrementActiveStepHost,
  decrementActiveStepHost
} from "../../../redux/actions/stepperActions.js";

const useStyles = makeStyles(theme => ({
  confirmationPrompt: {
    fontFamily: "PT Sans Narrow",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "23px",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "18px"
    }
  },
  container: {
    margin: "200px auto 200px auto",
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      margin: "100px auto 100px auto",
      width: "90%"
    }
  },
  headPromptDiv: {
    borderBottomStyle: "groove",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBlockEnd: "0.3rem",
    [theme.breakpoints.down("sm")]: {
      marginBlockEnd: "0.1rem"
    }
  },

  headPrompt: {
    fontFamily: "PT Sans Narrow",
    fontSize: "26px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "37px",
    letterSpacing: "0.01em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px"
    }
  },
  confirmationPromptDiv: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "3.5rem",
    [theme.breakpoints.between("sm", "md")]: {
      marginRight: "8rem"
    }
  },
  backToHomeButton: {
    backgroundColor: "#FF6161",
    borderRadius: 100,
    textAlign: "center",
    color: "#FFFFFF",
    width: "12em",
    height: "3em",
    marginBottom: "18em",
    marginTop: "1em"
  },

  verifyEmialAddressButton: {
    backgroundColor: "#FF6161",
    borderRadius: 100,
    color: "#FFFFFF",
    width: "12em",
    height: "3em"
  },

  verifyBtnLabel: {
    fontWeight: "bold",
    letterSpacing: "0.01em",
    fontFamily: "PT Sans Narrow",
    textTransform: "none",
    paddingTop: "0.4em",
    paddingBottom: "0.4em"
  },

  tips: {
    fontFamily: "PT Sans Narrow",
    fontSize: 16,
    color: "#1277B8",
    paddingLeft: "5em",
    paddingRight: "5em"
  },
  buttonRoot: {
    backgroundColor: "#FF6161"
  },
  buttonLabel: {
    color: "white",
    fontWeight: "normal",
    fontFamily: "PT Sans Narrow"
  },
  backButton: {
    width: "60%",
    marginTop: "3em",
    marginBottom: "1em",
    marginLeft: "20%",
    marginRight: "2em",
    fontFamily: "PT Sans",
    border: "white"
  },
  backButtonLabel: {
    color: "#FF6161",
    fontSize: 16,
    fontWeight: "normal",
    fontFamily: "PT Sans Narrow"
  }
}));

const SignupComplete = props => {
  let history = useHistory();
  const classes = useStyles();
  const userType = props.isProvider ? "Provider" : "Client";

  const handleBackToHomeButton = event => {
    history.push({ pathname: "/" });
  };

  return (
    <div>
      <form onSubmit={handleBackToHomeButton}>
        <Grid
          container
          justify="center"
          alignContent="center"
          className={classes.container}
        >
          <Grid item sm={12} xs={8} className={classes.headPromptDiv}>
            <div>
              <Typography className={classes.headPrompt}>
                Thanks for creating a{" "}
                <b style={{ fontWeight: "700" }}>{userType}</b> account!
              </Typography>
            </div>
          </Grid>

          <Grid item sm={12} xs={12} className={classes.confirmationPromptDiv}>
            <Button
              type="submit"
              variant="contained"
              className={classes.backToHomeButton}
              classes={{ label: classes.verifyBtnLabel }}
            >
              BACK TO HOME
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    incrementActiveStep: () => dispatch(incrementActiveStep()),
    decrementActiveStep: () => dispatch(decrementActiveStep()),
    incrementActiveStepHost: () => dispatch(incrementActiveStepHost()),
    decrementActiveStepHost: () => dispatch(decrementActiveStepHost())
  };
}

function mapStateToProps(state, ownProps) {
  return {
    activeStep: state.stepperReducer.activeStep,
    activeStepHost: state.stepperReducer.activeStepHost
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupComplete);
