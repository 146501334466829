import React, { useEffect, useRef, useState } from "react";

// Redux
import { Field, reduxForm } from "redux-form";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles({
  input: {
    display: "none"
  },
  upload: {
    display: "inline",
    marginLeft: "4em"
  },
  uploadButton: {
    width: "117px",
    height: "46px",
    borderRadius: "0px",
    textAlign: "center",
    fontFamily: "PT Sans Narrow"
  },
  uploadLabel: {
    color: "rgba(18, 119, 184, 0.6)",
    textTransform: "uppercase"
  },
  uploadRoot: {
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    boxShadow: "none"
  },
  fileUploadCheck: {
    display: "inline"
  },
  iconRoot: {
    fill: "#24B80B",
    marginLeft: "1em"
  }
});

let FileUpload = props => {
  const classes = useStyles();

  let [file, setFile] = React.useState("");

  const handleFileUpload = event => {
    let uploaded = URL.createObjectURL(event.target.files[0]);
    setFile(uploaded);
  };

  return (
    <div>
      <input
        accept=".pdf, .doc, .docx, .png, .jpeg, .jpg"
        className={classes.input}
        id="file button"
        multiple
        type="file"
        onChange={handleFileUpload}
      />
      <label htmlFor="file button">
        <Button
          variant="contained"
          component="span"
          className={classes.uploadButton}
          classes={{
            label: classes.uploadLabel,
            root: classes.uploadRoot
          }}
        >
          Upload
        </Button>
      </label>
      <div className={classes.fileUploadCheck}>
        {file !== "" ? (
          <CheckIcon classes={{ root: classes.iconRoot }} />
        ) : (
          <p />
        )}
      </div>
    </div>
  );
};

export default reduxForm({
  destroyOnUnmount: false
})(FileUpload);
