import styled, { css } from "styled-components";
import {
  GrayColor3,
  BlueColor3,
  BlueColor1,
  BlueColor2,
  BlueColor4
} from "./color-themes_old";
import { media_md_max, media_sm_max } from "./dimension-themes";

export const MemberDisplayArea = styled.div`
  border: 0;
  position: relative;
  margin: auto;
  width: 100%;
  background-color: ${BlueColor1};
  border-radius: 10px;
  padding: 10px;
  text-align: center;
`;

export const MemberCard = styled.div`
  box-sizing: content-box;
  position: relative;
  border: 0;
  background-color: white;
  border-radius: 8px;

  margin: 5px 5px;
  padding: 3px 0;

  display: inline-block;
  width: 46%;
  @media (max-width: ${media_md_max}) {
    margin: 2px auto;
    width: 75%;
  }
  @media (max-width: ${media_sm_max}) {
    margin: 1px auto;
    width: 98%;
  }

  ${props =>
    props.isMaster &&
    css`
      background-color: ${BlueColor2};
      border: 2px solid white;
    `}
`;

export const MemberCardColumn = styled.div`
  display: inline-block;
  margin: 0 5px;
  float: left;
  height: 24px;
  overflow: hidden;

  @media (max-width: ${media_sm_max}) {
    margin: 0 3px;
  }
`;

export const TickBoxColumn = styled(MemberCardColumn)`
  width: 7%;
`;
export const NameColumn = styled(MemberCardColumn)`
  text-align: left;
  max-width: 50%;
`;
export const DOBColumn = styled(MemberCardColumn)``;
export const ViewEditColumn = styled(MemberCardColumn)`
  float: right;
`;

export const CardName = styled.span`
  color: ${BlueColor4};
  font-size: 0.8rem;
  font-family: "Open Sans Condensed", sans-serif;
  font-weight: 700;
  @media (max-width: ${media_sm_max}) {
    font-size: 0.73rem;
  }
`;

export const CardDOB = styled.span`
  font-size: 0.7rem;
  color: ${GrayColor3};
  @media (max-width: ${media_sm_max}) {
    font-size: 0.66rem;
  }
`;

export const CardEditButton = styled.button`
  font-size: 0.7rem;
  color: floralwhite;
  background-color: ${BlueColor3};
  transform: translate(0, -1px);

  border: 0;
  border-radius: 5px;

  width: 55px;
  height: 18px;

  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: black;
  }

  &:focus {
    outline: none;
  }
`;

export const DateDisplay = styled.div`
  text-align: center;
  background: white;
  color: ${BlueColor4};
  font-size: 0.95rem;
  width: 150px;
  height: 25px;
  border: 2px solid ${BlueColor2};
  border-radius: 4px;
`;

// alert
export const StyledAlert = styled.div`
  background-color: ${BlueColor2};
  color: ${BlueColor3};
  padding: 7px 10px;
  width: 82%;
  border-radius: 5px;
  margin-bottom: 15px;
  position: relative;
`;

export const AlertCloseButton = styled.button`
  background-color: transparent;
  //color: #5294aa;
  color: ${BlueColor2};
  border: none;
  font-size: 2rem;
  font-weight: bold;
  position: absolute;
  right: 8px;
  top: -15%;

  &:hover {
    cursor: pointer;
    color: black;
  }
`;
