export const FIRST_TIMER_LESSON_COLOR = "#F8D7DA";
export const GROUP_LESSON_COLOR = "#CDE5FE";
export const PRIVATE_LESSON_COLOR = "#FFF1CF";
export const ADAPTIVE_LESSON_COLOR = "#D4EBDA";
export const NON_FIRST_TIMERS_COLOR = "#CDD2FE";
export const DEFAULT_TABLE_COLOR = "#ecf4f9";
export const SNOWPASS_TABLE_COLOR = "#f1d181";

export const ADAPTIVE_LESSON_COLOR_SECONDARY = "#e5f3e9";
export const FIRST_TIMER_LESSON_COLOR_SECONDARY = "#FAE3E5";
export const NON_FIRST_TIMERS_COLOR_SECONDARY = "#E6E8FC";
export const DEFAULT_TABLE_COLOR_SECONDARY = "#f5f8fa";
export const DEFAULT_TABLE_COLOR_BORDER = "#0d0d0d";

export const SNOWPASS_TABLE_COLOR_SECONDARY = "#f6e2af";
export const BLUECOLOR4 = "#31638C";
export const GRAYCOLOR3 = "#767277";
export const GRAYCOLOR4 = "#4E4E52";
export const WHITECOLOR = "FFFFFF";
