import React, { useEffect, useState } from "react";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Messenger from "./Messenger";
import VirtualLessonCard from "./VirtualLessonCard";
import { Container, Paper } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InstructorCard from "../../virtualLessons/InstructorCard/InstructorCard.js";
import { connect } from "react-redux";
import axios from "axios";
import agent from "../../../agent";
import {
  loadInstructorInfo,
  loadMessageRoomInfo,
  loadStudentInfo,
  loadVirtualBookingInfo
} from "../../../redux/actions/chatPageAction";
import Grid from "@material-ui/core/Grid";

const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexGrow: 1
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  accordionSummary: {
    alignItems: "center"
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(1),
    // borderStyle:"solid",
    borderRadius: "25px"
  },
  drawerTitle: {
    color: "#1277B8",
    fontWeight: "bold",
    margin: 15,
    textAlign: "center"
  },
  desktopPaper: {
    height: "100%"
  },
  messenger: {
    // height: document.body.clientHeight
  },
  accordion: {
    padding: 3
  },
  heading: {
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {},
  instructorCard: {}
}));

function ChatPage(props) {
  const classes = useStyles();

  //the room ID comes from the url
  const roomID = props.match.params.id;

  const API_PATH = "../api/";

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [userType, setUserType] = React.useState();
  const [favInstructors, setFavInstructors] = React.useState([]);

  //When not login, doesn't display the messenger; if yes, return the Messenger
  const returnMessenger = (userID, roomID, userType) => {
    if (userID != null && userType != undefined) {
      return <Messenger userID={userID} roomID={roomID} userType={userType} />;
    } else {
      return (
        <Typography>
          Loading Messenger now, or you haven't log in yet!
        </Typography>
      );
    }
  };

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (props.currentUser !== null) {
      const currentUserID = props.currentUser.id;

      agent.Message.getMessageRoomInfo(roomID)
        .then(res => {
          // it will return the message room data, instructor data,
          // student data and virtual booking data.
          let responseData = res.data;
          if (responseData == "no such room") {
            alert("no such message room");
          }
          console.log("responseData", responseData);
          // differentiate user type
          if (currentUserID === responseData.instructor.user_id) {
            console.log("the user is a ins");
            setUserType("instructor");
          } else if (currentUserID === responseData.student.UserID) {
            console.log("the user is a stu");
            setUserType("student");
          } else {
            setUserType("none");
            alert("you don't belong to this message room!");
          }
          props.loadMessageRoomInfo(responseData.messageRoom);
          props.loadInstructorInfo(responseData.instructor);
          props.loadStudentInfo(responseData.student);
          props.loadVirtualBookingInfo(responseData.virtualBooking);
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        });
    } else console.log("undefined / waiting for loading ");
  }, [props.currentUser]);

  const reduxMessageRoomData = props.messageRoomData;
  const reduxInstructorData = props.instructorData;
  const reduxStudentData = props.studentData;
  const reduxVirtualBookingData = props.virtualBookingData;

  const displayInsNameDesktopView = dataset => {
    let fullName = dataset.FirstName + " " + dataset.LastName;
    if (dataset.FirstName !== undefined) {
      return fullName;
    } else {
      return <Typography>Loading</Typography>;
    }
  };

  const drawer = (
    messageRoomData,
    instructorData,
    studentData,
    virtualBookingData
  ) => {
    if (virtualBookingData !== null) {
      const dataset = {
        id: virtualBookingData.id,
        FirstName: instructorData.FirstName,
        LastName: instructorData.LastName,
        price: instructorData.MinPrice,
        schedule: virtualBookingData.start,
        status: virtualBookingData.status,
        videoLink: virtualBookingData.video_url,
        //dummy data for these buttons
        messageLink: "https://google.com",
        calenderLink: "https://www.youtube.com/",
        meetingLink: "https://www.unimelb.edu.au/"
      };
      let fullName = dataset.FirstName + " " + dataset.LastName;
      return (
        <div>
          <div />
          <Divider />
          <Typography className={classes.drawerTitle}>
            VIRTUAL LESSON BOOKING {dataset.status}
          </Typography>
          <Divider />
          <VirtualLessonCard
            //This component could be come from /videolesson folder
            key={dataset.id}
            name={fullName}
            price={dataset.price}
            schedule={dataset.schedule}
            videoLink={dataset.videoLink}
            messageLink={dataset.messageLink}
            calenderLink={dataset.calenderLink}
            meetingLink={dataset.meetingLink}
          />
          <Divider />
          <Grid
            className={classes.instructorCard}
            justify="center"
            alignItems="center"
          >
            <InstructorCard
              user_id={props.instructorData.id}
              name={props.instructorData.FirstName}
              favourites={favInstructors}
              Bio={props.instructorData.Bio}
              MinPrice={props.instructorData.MinPrice}
              AvatarSrc={props.instructorData.AvatarSrc}
              // onClick={handleFavourites}
              // {...card}
            />
          </Grid>
        </div>
      );
    } else {
      return (
        <div>
          <div />
          <Divider />
          <Typography className={classes.drawerTitle}>
            No bookings with him/her
          </Typography>
          <Divider />
          <Divider />
          <Grid
            className={classes.instructorCard}
            justify="center"
            alignItems="center"
          >
            <InstructorCard
              user_id={props.instructorData.user_id}
              name={props.instructorData.FirstName}
              favourites={favInstructors}
              Bio={props.instructorData.Bio}
              MinPrice={props.instructorData.MinPrice}
              AvatarSrc={props.instructorData.AvatarSrc}
              // onClick={handleFavourites}
              // {...card}
            />
          </Grid>
        </div>
      );
    }
  };

  return (
    <Container className={classes.root}>
      <main className={classes.content}>
        {/* this component will show in Mobile view (smUp), but hide in desktop*/}
        <Hidden smUp implementation="css">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className={classes.accordion}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              className={classes.accordionSummary}
            >
              <Typography className={classes.heading}>
                {//display the other guy's name
                userType == "student"
                  ? reduxInstructorData.FirstName +
                    " " +
                    reduxInstructorData.LastName
                  : reduxStudentData.FirstName +
                    " " +
                    reduxStudentData.LastName}
              </Typography>

              <Typography className={classes.secondaryHeading}>
                {reduxVirtualBookingData !== null
                  ? reduxVirtualBookingData.start
                  : "No bookings with him/her"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {drawer(
                props.messageRoomData,
                props.instructorData,
                props.studentData,
                props.virtualBookingData
              )}
            </AccordionDetails>
          </Accordion>
        </Hidden>

        {/* this component will show in desktop view (xsDown), but hide in mobile view*/}
        <div className={classes.messenger}>
          <Hidden xsDown implementation={"css"}>
            <Typography variant={"h5"} className={classes.drawerTitle}>
              {userType == "student"
                ? displayInsNameDesktopView(reduxInstructorData)
                : displayInsNameDesktopView(reduxStudentData)}
            </Typography>
          </Hidden>
          {/*Display the messenger component */}
          {returnMessenger(props.currentUser, roomID, userType)}
        </div>
      </main>
      {/* this component will show in desktop view (xsDown), but hide in mobile view*/}
      <Hidden xsDown implementation={"css"}>
        <Paper
          // elevation={0}
          className={classes.desktopPaper}
        >
          <div>
            {drawer(
              props.messageRoomData,
              props.instructorData,
              props.studentData,
              props.virtualBookingData
            )}
          </div>
        </Paper>
      </Hidden>
    </Container>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    loadMessageRoomInfo: messageRoomData =>
      dispatch(loadMessageRoomInfo(messageRoomData)),
    loadInstructorInfo: instructorData =>
      dispatch(loadInstructorInfo(instructorData)),
    loadStudentInfo: studentData => dispatch(loadStudentInfo(studentData)),
    loadVirtualBookingInfo: virtualBookingData =>
      dispatch(loadVirtualBookingInfo(virtualBookingData))
  };
}

// get the current user form redux
function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser,
    messageRoomData: state.chatPageReducer.messageRoomData,
    instructorData: state.chatPageReducer.instructorData,
    studentData: state.chatPageReducer.studentData,
    virtualBookingData: state.chatPageReducer.virtualBookingData
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatPage);
