import { combineReducers } from "redux";
import { reducer as dataReducer } from "redux-form";

import equipmentReducer from "./equipmentReducer";

import activityReducer from "./activityReducer";
import sectionReducer from "./sectionReducer";

import tripCoreReducer from "./tripCoreReducer";
import tripWhenReducer from "./tripWhenReducer";
import tripWhereReducer from "./tripWhereReducer";
import tripWhoReducer from "./tripWhoReducer";
import tripLessonReducer from "./tripLessonReducer";
import resortReducer from "./resortReducer";
import contactUsReducer from "./contactUsReducer";
import profileReducer from "./profileReducer";
import stepperReducer from "./stepperReducer";
import instructorReducer from "./instructorReducer";
import auth from "./auth";
import hostReducer from "./hostReducer";
import awsS3Reducer from "./awsS3Reducer";
import homePageReducer from "./homePageReducer";
import exploreHostsReducer from "./exploreHostsReducer";
import virtualInstructorReducer from "./virtualInstructorReducer";
import signupReducer from "./signupReducer";
import paymentReducer from "./paymentReducer";
import chatPageReducer from "./chatPageReducer";
import displayVideoLessonReducer from "./displayVideoLessonReducer";
import visitorProfileReducer from "./visitorProfileReducer";
import HostProfileVisitorViewReducer from "./HostProfileVisitorViewReducer";
// import instructorProfileReducer from "./instructorProfileReducer";
import instructorNewProfileReducer from "./instructorNewProfileReducer.js";
import studentProfileReducer from "./studentProfileReducer";
import hostNewProfileReducer from "./hostNewProfileReducer";
import cancelBookingReducer from "./cancelBookingReducer";
import myAccountReducer from "./myAccountReducer";
import loginReducer from "./loginReducer";
import profileDataReducer from "./profileDataReducer";

//Used to provide the store with all reducers.
const allReducers = {
  // equipmentReducer,
  // sectionReducer,
  form: dataReducer,
  // resortReducer,
  // tripWhereReducer,
  // tripCoreReducer,
  // tripLessonReducer,
  // activityReducer,
  // tripWhoReducer,
  // tripWhenReducer,
  contactUsReducer,
  profileReducer,
  stepperReducer,
  auth,
  hostReducer,
  // instructorReducer,
  awsS3Reducer,
  homePageReducer,
  exploreHostsReducer,
  // virtualInstructorReducer,
  signupReducer,
  // paymentReducer,
  // chatPageReducer,
  // displayVideoLessonReducer,
  // visitorProfileReducer,
  // HostProfileVisitorViewReducer,
  // instructorProfileReducer,
  // instructorNewProfileReducer,
  // studentProfileReducer,
  // hostNewProfileReducer,
  // cancelBookingReducer,
  myAccountReducer,
  loginReducer,
  profileDataReducer
};

//instructorFakeReducer will be moved to database later.
const rootReducer = combineReducers(allReducers);
export default rootReducer;
