import React from "react";
import styles from "../../../styling/css/Homepage/instructorCard.module.css";

const InstructorProfileTile = props => {
  const { imagePath, instructorName, location, title } = props;

  return (
    <div className={styles.instructorContainer}>
      <img src={imagePath} alt="instructor" className={styles.instructorImg} />
      <div className={styles.instructorInfoContainer}>
        <h5 className={styles.instructorName}>{instructorName}</h5>
        <div className={styles.instructorLocation}>
          <span>
            {location} | {title}
          </span>
        </div>
      </div>

      <div className={styles.instructorBtnContainer}>
        <button
          className={styles.instructorButton}
          onClick={() => {
            /* TODO: go to instructor profile*/
          }}
        >
          View Full Profile
        </button>
      </div>
    </div>
  );
};

export default InstructorProfileTile;
