import {
  baseURL,
  GET_COUNTRY_DATA,
  GET_PROVIDER_DATA,
  GET_RESORT_DATA
} from "./action-types";

import { submit } from "redux-form";
import agent from "../../agent";

export function getCountryList() {
  return function(dispatch) {
    return agent.Constants.getCountry()
      .then(response => {
        if (response.status === 200) {
          dispatch({ type: GET_COUNTRY_DATA, payload: response });
        }
      })
      .catch(error => {
        console.log("error", error);
        // dispatch(alertSubmitForm(false));
      });
  };
}

export function getResortList(id) {
  return function(dispatch) {
    return agent.Constants.getResort(id)
      .then(response => {
        if (response.status === 200) {
          dispatch({ type: GET_RESORT_DATA, payload: response });
        }
      })
      .catch(error => {
        console.log("error", error);
        // dispatch(alertSubmitForm(false));
      });
  };
}

export function getProviderList(id) {
  return function(dispatch) {
    return agent.ProviderData.getProviderData(id)
      .then(response => {
        if (response.status === 200) {
          dispatch({ type: GET_PROVIDER_DATA, payload: response });
        }
      })
      .catch(error => {
        console.log("error", error);
        // dispatch(alertSubmitForm(false));
      });
  };
}
