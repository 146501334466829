import { SAVE_EQUIP_TO_STORE } from "../actions/action-types";

//YYYY-MM-DD  original date format
//The initialState is for testing purposes. Delete it during implementation.

const today = new Date();
const initialState = [
  {
    RentalID: 1234,
    MasterID: 101,
    StartDate: today,
    EndDate: new Date(new Date().setDate(today.getDate() + 1)),
    EquipmentName: "skis",
    EquipmentType: "sport",
    AgeGroup: "adult",
    Quantity: 2,
    Comment: "no comment"
  }
];

//Only one situation: saving logic.
const equipmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_EQUIP_TO_STORE:
      return state.slice().push(action.payload);
    default:
      return state;
  }
};
export default equipmentReducer;
