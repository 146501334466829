import React, { useEffect, useState } from "react";

import agent from "../../../agent";
import {
  CLIENT_STEPPER,
  PROVIDER_STEPPER,
  USER_TYPE
} from "../../../constants/common";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

// Redux
import { connect } from "react-redux";
import {
  incrementActiveStep,
  decrementActiveStep,
  incrementActiveStepHost,
  decrementActiveStepHost,
  getClientProfile,
  getProviderProfile
} from "../../../redux/actions/stepperActions.js";
import { loadUser } from "../../../redux/reducers/auth";

// Store
import Store from "../../../redux/store";

// Components
import SignupProviderOrClient from "../../../components/Common/SignupForm/SignupProviderOrClient.js";
import SignupStepper from "../../../components/Common/SignupForm/SignupStepper.js";
import SetAccount from "../../../components/Common/SignupForm/SetAccount.js";
import SetDetails from "../../../components/Common/SignupForm/SetDetails";
import EmailVerification from "../../../components/Common/SignupForm/EmailVerification.js";

import ProviderInfo from "../../../components/Common/SignupForm/ProviderInfo.js";
import ClientInfo from "../../../components/Common/SignupForm/ClientInfo.js";
import ProviderServices from "../../../pages/serviceMarket/profile/ProviderServices.js";

import {
  saveImageUrlToStore,
  saveCertificateUrlToStore
} from "../../../redux/actions/awsS3Actions";
import SignupComplete from "../../../components/Common/SignupForm/SignupComplete";
import ConfirmEmail from "../../../components/Common/SignupForm/ConfirmEmail";

const useStyles = makeStyles({
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 48,
    padding: "0 30px"
  },
  signupContentContainer: {
    paddingTop: "1em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    marginTop: "1rem"
  },

  arrowIcon: {
    transform: "rotate(180deg)"
  },
  backButton: {
    width: "60%",
    marginTop: "1em",
    marginBottom: "1em",
    marginLeft: "20%",
    marginRight: "auto",
    padding: "0",
    fontFamily: "PT Sans Narrow"
  },
  
  buttonRoot: {
    "&.MuiButton-outlined": {
      color: "#FF6161",
      borderColor: "#FF6161"
    }
  },
  buttonContainer: {
    height: 0
  },
  main: {
    paddingBottom: "5em"
  },
  mainForQeury: {
    backgroundColor: "#FFFFFF",
    paddingBottom: "5em"
  }
});

const SignupPage = props => {
  const store = Store.getState();
  const classes = useStyles();
  const [imageURLUpdated, setImageURLUpdated] = useState(false);
  const [qualificationURLUpdated, setQualificationURLUpdated] = useState(false);

  const handleFormChangeClient = stepperState => {
    if (stepperState === CLIENT_STEPPER.SET_ACCOUNT) {
      return <SetAccount onSubmit={handleOnSubmit} />;
    } else if (stepperState === CLIENT_STEPPER.EMAIL_VERIFICATION) {
      return <EmailVerification onSubmit={handleOnSubmit} />;
    } else if (stepperState === CLIENT_STEPPER.SET_DETAILS) {
      return <SetDetails onSubmit={handleOnSubmit} />;
    } else if (stepperState === CLIENT_STEPPER.CLIENT_INFO) {
      return <ClientInfo onSubmit={handleOnSubmit} />;
    } else if (stepperState === CLIENT_STEPPER.SIGNUP_COMPLETE) {
      return <SignupComplete onSubmit={handleOnSubmit} />;
    }
  };

  const handleFormChangeProvider = stepperState => {
    if (stepperState === PROVIDER_STEPPER.SET_ACCOUNT) {
      return <SetAccount onSubmit={handleOnSubmit} isProvider={true} />;
    } else if (stepperState === PROVIDER_STEPPER.EMAIL_VERIFICATION) {
      return <EmailVerification onSubmit={handleOnSubmit} />;
    } else if (stepperState === PROVIDER_STEPPER.SET_DETAILS) {
      return <SetDetails onSubmit={handleOnSubmit} isProvider={true} />;
    } else if (stepperState === PROVIDER_STEPPER.PROVIDER_INFO) {
      return <ProviderInfo onSubmit={handleOnSubmit} isProvider={true} />;
    } else if (stepperState === PROVIDER_STEPPER.SERVICES) {
      return <ProviderServices 
                onSubmit={handleOnSubmit} 
                isProvider={true} 
              />;
    } else if (stepperState === PROVIDER_STEPPER.SIGNUP_COMPLETE) {
      return <SignupComplete onSubmit={handleOnSubmit} isProvider={true} />;
    }
  };

  useEffect(() => {
    setQualificationURLUpdated(true);
  }, [props.certificateURL]);

  useEffect(() => {
    if (props.userData && props.userData.data) {
      if (props.userData.data.is_provider) {
        props.getProviderProfile(props.userData.data.user_id);
      } else {
        props.getClientProfile(props.userData.data.user_id);
      }
    }
  }, [props.userData]);

  useEffect(() => {
    if (props.userProfile == undefined && props.userData) {
      if (props.userData.data.is_provider) {
        props.getProviderProfile(props.userData.data.user_id);
      } else {
        props.getClientProfile(props.userData.data.user_id);
      }
    }
  }, [props.userProfile]);

  const handleCreateProvider = (imageURL, certificateURL) => {
    const values = store.form.signup.values;

    let qual1 = (values.BASI && "BASI") || "";
    let qual2 = (values.skiSchool && "Ski School Instructor") || "";

    const providerData = {
      /* User Variables */
      email: values.email,
      password: values.password,
      firstName: values.firstName,
      lastName: values.lastName,
      providerBio: values.providerBio,
      gender: values.gender,
      AvatarSrc: values.imageURL,

      /* Provider Variables */
      years_as_local: values.years_as_local,
      providerBio: values.providerBio,

      /* Qualifications Variables */
      qualifications: qual1 + ", " + qual2,
      qualificationProof: certificateURL,

      /* Provider Sub-Tables */
      languages: values.languages,
      specialties: props.specialties,
      country: values.country
    };

    const services = [
      // { service: VirtualLessons, groupSize: values.numberOfMembers }
    ];

    const newAccount = agent.Providers.create(providerData)
      .then(response => {
        const responseData = response.data;
        localStorage.setItem("userID", responseData.id);
        agent.setToken(responseData.tokens.token);
        agent.Users.currentUser().then(response => {
          props.loadUser(response.data);
        });

        //create services tables
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleCreateClient = () => {
    const values = store.form.signup.values;

    const clientData = {
      /* User Variables */
      email: values.email,
      password: values.password,
      firstName: values.firstName,
      lastName: values.lastName,
      bio: values.bio,
      gender: values.gender,
      picture: values.picture,

      /* User Sub-Tables */
      languages: values.languages,
      country: values.country,
      allergies: values.allergies
    };

    const newAccount = agent.Users.create(clientData)
      .then(response => {
        const responseData = response.data;
        localStorage.setItem("userID", responseData.id);
        agent.setToken(responseData.tokens.token);
        agent.Users.currentUser().then(response => {
          props.loadUser(response.data);
        });

        //create services tables
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleOnSubmit = event => {
    const store = Store.getState();
    const values = store.form.signup.values;

    props.incrementActiveStep(incrementActiveStep());
    console.log("activeStep", props.activeStep);
    if (values.userType === 1) {
      const requestData = {
        email: values.email,
        password: values.password
      };
    } else if (values.userType === 2) {
    }
  };

  const handleBackButton = event => {
    props.decrementActiveStep(decrementActiveStep());
  };

  function RenderStepPages() {
    if (props.userData) {
      if (props.userData.data.is_provider) {
        return handleFormChangeProvider(props.activeStep);
      } else {
        return handleFormChangeClient(props.activeStep);
      }
    } else {
      if (props.activeStep <= 0) {
        return (
          <SignupProviderOrClient onSubmit={handleOnSubmit} isProvider={true} />
        );
      } else {
        if (store.form.signup.values.userType === USER_TYPE.PROVIDER) {
          return handleFormChangeProvider(props.activeStep);
        } else if (store.form.signup.values.userType === USER_TYPE.CLIENT) {
          return handleFormChangeClient(props.activeStep);
        }
      }
    }

    return null;

    // Updated for provider login
    // if (props.userData && props.activeStep <= 0) {
    //   return (
    //     <SignupProviderOrClient onSubmit={handleOnSubmit} isProvider={true} />
    //   );
    // } else {
    //   if (props.userData.data.is_provider) {
    //     return handleFormChangeProvider(6);
    //   } else {
    //     return handleFormChangeClient(props.activeStep);
    //   }
    // }
  }

  return (
    <div className={classes.signupPageContainer}>
      <Grid container justify="flex-start">
        <Grid item xs={12} md={12} justify="center">
          {props.activeStep >= 1 && (
            <div className={classes.signupContentContainer}>
              <SignupStepper />
            </div>
          )}
        </Grid>
      </Grid>

      <div className={classes.main}>
        <RenderStepPages />
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    incrementActiveStep: () => dispatch(incrementActiveStep()),
    decrementActiveStep: () => dispatch(decrementActiveStep()),
    incrementActiveStepHost: () => dispatch(incrementActiveStepHost()),
    decrementActiveStepHost: () => dispatch(decrementActiveStepHost()),
    loadUser: user => {
      dispatch(loadUser(user));
    },
    saveImageUrlToStore: url => dispatch(saveImageUrlToStore(url)),
    saveCertificateUrlToStore: url => dispatch(saveCertificateUrlToStore(url)),
    getClientProfile: userId => dispatch(getClientProfile(userId)),
    getProviderProfile: userId => dispatch(getProviderProfile(userId))
  };
}

function mapStateToProps(state, ownProps) {
  return {
    resortIDs: state.signupReducer.resortID,
    activeStep: state.stepperReducer.activeStep,
    activeStepHost: state.stepperReducer.activeStepHost,
    imageUploadEvent: state.awsS3Reducer.imageUploadEvent,
    qualificationUploadEvent: state.awsS3Reducer.qualificationUploadEvent,
    imageURL: state.awsS3Reducer.imageURL,
    certificateURL: state.awsS3Reducer.certificateURL,
    userData: state.loginReducer.userData,
    userProfile: state.stepperReducer.userProfile
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupPage);
