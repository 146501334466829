import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

const useStyles = makeStyles({
  //absolute position is placeholder
  wrapper: {
    width: "343px",
    height: "530px",
    background: "#1277B8",
    border: "3px solid #1277B8",
    boxSizing: "border-box",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px"
  },
  heading: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "29px",
    lineHeight: "45px",
    color: "#FFFFFF",
    marginTop: ".3em",
    marginBottom: ".5em"
  },
  instructorName: {
    position: "absolute",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "22px",
    lineHeight: "128.91%",
    textAlign: "center",

    color: "#FFFFFF"
  },
  line: {
    position: "relative",
    width: "90%",
    height: "0px",
    border: "0.5px solid #FFFFFF"
  },
  circle: {
    width: "16px",
    height: "16px",
    left: "111px",
    top: "1069px",

    background: "rgba(255, 255, 255, 0.83)",
    borderRadius: "50%"
  },
  divider: {
    height: "1em"
  },
  gridContainer: { marginTop: "100px" }
});

const FavouritedInstructor = props => {
  const classes = useStyles();
  return (
    <div className="gridContainer">
      <Grid container justify="center">
        <Grid item xs={3}>
          <IconButton
            color="secondary"
            onClick={() => props.onClick(props.name)}
            aria-label="unfavourite"
          >
            <RemoveCircleOutlineIcon style={{ fill: "white" }} />
          </IconButton>
        </Grid>
        <Grid item xs={6}>
          <div style={{ height: "8px" }} />
          <Typography className={classes.instructorName}>
            {props.name}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const ExploreInstructors = props => {
  const classes = useStyles();
  const heading = "Saved Instructors";
  return (
    <Grid container justify="center">
      <div className={classes.wrapper}>
        <Grid item container justify="center">
          <Typography className={classes.heading}>{heading}</Typography>
        </Grid>

        <Grid item container justify="center">
          <div className={classes.line} />
        </Grid>
        <div className={classes.divider} />
        {props.instructors.length === 0
          ? null
          : props.instructors.map(name1 => (
              <Grid container>
                <Grid item xs={12}>
                  <FavouritedInstructor
                    key={name1}
                    name={name1}
                    onClick={props.onClick}
                    class={classes.instructor}
                  />
                </Grid>
              </Grid>
            ))}
      </div>
    </Grid>
  );
};

function mapStateToProps(state) {
  return {
    name: state.exploreHostsReducer.name
  };
}

export default connect(
  mapStateToProps,
  {}
)(ExploreInstructors);
