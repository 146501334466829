import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Grid,
  FormControl,
  FormHelperText
} from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import { connect } from "react-redux";
import agent from "../../../agent";
import {
  incrementActiveStep,
  decrementActiveStep,
  incrementActiveStepHost,
  decrementActiveStepHost,
  updateUserDetails,
  getClientProfile,
  getProviderProfile
} from "../../../redux/actions/stepperActions.js";

// Redux
import { Field, reduxForm, getFormValues, change } from "redux-form";

//Components
import Gender from "./UserInfo/Gender";

// Component Input
import { renderField, renderSelectField } from "../FormField/FormField.js";

const useStyles = makeStyles(theme => ({
  grid: {
    width: "80%",
    marginLeft: "10%",
    marginRight: "10%"
  },
  mobileForm: {
    border: "1px solid #000000",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    borderBottomLeftRadius: "10px",
    paddingLeft: "0.5em",
    paddingRight: "0.5em",
    width: "35%"
  },
  dobContainer: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "1rem"
    }
  },
  formPrompts: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    textTransform: "uppercase",
    marginBottom: "0.5em",
    marginLeft: "7.5%",
    color: "black",
    fontSize: "18px"
  },
  nextButton: {
    padding: "0rem 1rem",
    width: "9em",
    height: "2.5em",
    borderRadius: 100,
    textAlign: "center"
  },
  buttonLabel: {
    color: "white",
    fontWeight: "normal",
    fontFamily: "PT Sans Narrow"
  },
  buttonRoot: {
    backgroundColor: "#FF6161"
  },
  backButton: {
    width: "60%",
    padding: "0rem 1rem",
    fontFamily: "PT Sans Narrow",
    border: "white"
  },
  backButtonLabel: {
    color: "#FF6161",
    fontSize: 16,
    fontWeight: "normal",
    fontFamily: "PT Sans Narrow"
  },
  mobile: {
    fontFamily: "PT Sans Narrow",
    marginLeft: "7.5%",
    width: "85%",
    textTransform: "uppercase",
    display: "inline-flex",
    height: "35px"
  },
  phoneForm: {
    fontFamily: "PT Sans Narrow",
    border: "5px solid red"
  },
  rootGrid: {
    backgroundColor: "white"
  },
  buttonContainer: {
    margin: "2rem 0rem",
    padding: "0rem 2rem"
  },
  mobileForm: {
    width: "100%",
    border: "1px solid #000000",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    borderBottomLeftRadius: "10px"
  }
}));

function validate(values) {
  let errors = {};

  if (!values.firstName) {
    errors.firstName = "*Required";
  }

  if (!values.lastName) {
    errors.lastName = "*Required";
  }

  if (!values.gender) {
    errors.gender = "*Required";
  }

  if (!values.dob) {
    errors.dob = "*Required";
  }
  return errors;
}

let SetDetails = props => {
  const classes = useStyles();
  const {
    loading,
    updateDetailsSuccess,
    updateDetailsError,
    activeStep,
    isProvider,
  } = props;

  //get the user data from user login state
  const dispatch = useDispatch();
  const userIn = useSelector(state => state.loginReducer.userData);

  // Below sets the state
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [mobile, setMobile] = useState((props.formValues.mobileNumber && props.formValues.mobileNumber != "+61") ? props.formValues.mobileNumber : "+61"); //Default AU(+61) if store form doesn't has any other stored value

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  //get the profile avatar
  useEffect(() => {
    setIsLoading(true);
    if (props.userProfile) {
      if (isProvider){
        if(props.userProfile.user.first_name != null) setFirstName(props.userProfile.user.first_name);
        if(props.userProfile.user.last_name != null) setLastName(props.userProfile.user.last_name);
        if(props.userProfile.user.gender != null) setGender(props.userProfile.user.gender);
        if(props.userProfile.user.dob != null) setDob(props.userProfile.user.dob);
        if(props.userProfile.user.mobile_number != null) setMobile(props.userProfile.user.mobile_number);
      } else {
        if(props.userProfile.firstName != null) setFirstName(props.userProfile.firstName);
        if(props.userProfile.lastName != null) setLastName(props.userProfile.lastName);
        if(props.userProfile.gender != null)  setGender(props.userProfile.gender);
        if(props.userProfile.dob != null)  setDob(props.userProfile.dob);
        if(props.userProfile.mobileNumber != null) setMobile(props.userProfile.mobileNumber);
      }
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [props.userProfile]);

  const handleBackButton = event => {
    props.decrementActiveStep(decrementActiveStep());
  };

  function handleOnChange(value, country) {
    if (value.length < 6) {
      setError(true);
    } else {
      setError(false);
      setMobile(value);
    }
  }

  const handleSubmit = async event => {
    event.preventDefault();

    if (
      props.formValues.firstName == null ||
      props.formValues.lastName == null ||
      props.formValues.gender == null ||
      // props.formValues.dob == null ||
      mobile == null
    ) {
      alert("Please enter required information");
    } else {
      const { user_id } = props.userData.data;
      const signupStep = activeStep + 1;
      const user = {
        userId: user_id,
        firstName: props.formValues.firstName,
        lastName: props.formValues.lastName,
        gender: props.formValues.gender.toLowerCase(),
        //dob: props.formValues.dob,
        dob: '1900-1-1',
        mobileNumber: mobile,
        signupStep: signupStep
      };
      props.updateUserDetails(user);

      if (props.userData.data.is_provider) {
        props.getProviderProfile(user_id);
      } else {
        props.getClientProfile(user_id);
      }
    }
  };

  useEffect(() => {
    if (updateDetailsSuccess) {
      console.log("User Information was successfully updated!");
      props.onSubmit();
    }
  }, [updateDetailsSuccess]);

  useEffect(() => {
    if (updateDetailsError) {
      alert(updateDetailsError);
    }
  }, [updateDetailsError]);

  return (
    <div>
      {isLoading ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={0}
          direction="column"
          style={{ marginTop: "3rem" }}
        >
          <Grid
            container
            justify="center"
            alignItems="flex-start"
            xs={12}
            sm={10}
            md={8}
            lg={6}
            spacing={2}
            direction="row"
            style={{ backgroundColor: "white", padding: "2rem 0px" }}
          >
            <div className="loader-container">
              <div className="loader" />
            </div>
          </Grid>
        </Grid>
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
            className={classes.rootGrid}
          >
            {/* Firstname, Lastname, Gender, DOB */}
            <Grid
              item
              container
              sm={6}
              xs={12}
              direction="row"
              alignItems="flex-start"
            >
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Field
                  name="firstName"
                  component={renderField}
                  type="text"
                  value={firstName}
                  label="First Name"
                  onChange={event => setFirstName(event.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Field
                  name="lastName"
                  component={renderField}
                  type="text"
                  label="Last Name"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Gender
                  renderSelectField={renderSelectField}
                  gender={props.formValues.gender}
                  handleGenderChange={e => props.setGender(e.target.value)}
                />
              </Grid>

              
                {/* <Field
                  name="dob"
                  component={renderField}
                  type="date"
                  label="Date Of Birth"
                  placeholder="DD/MM/YYYY"
                  value={dob}
                  onChange={e => setDob(e.target.value)} */}

              {/* Mobile Phone */}
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Typography className={classes.formPrompts}>
                  Mobile Phone
                </Typography>

                {/* code for mobile form */}
                <div className={classes.mobile}>
                  <FormControl
                    variant="outlined"
                    classes={{ root: classes.mobileForm }}
                  >
                    <MuiPhoneNumber
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          marginLeft: "0.5rem",
                          fontFamily: "PT SANS NARROW"
                        }
                      }}
                      disableAreaCodes={true}
                      onChange={handleOnChange}
                      enableLongNumbers={true}
                      value={mobile}
                    />
                  </FormControl>
                </div>

                {error ? (
                  <FormHelperText
                    style={{ color: "#f44336", marginLeft: "25px" }}
                  >
                    *Required
                  </FormHelperText>
                ) : (
                  <FormHelperText>&nbsp;</FormHelperText>
                )}
              
                
              </Grid>
            </Grid>

            
          

            {/* Button */}
            <Grid
              container
              item
              xs={12}
              sm={6}
              lg={6}
              justify="flex-end"
              className={classes.buttonContainer}
            >
              <Grid
                item
                style={{ alignSelf: "center", padding: "0rem 0.5rem" }}
              >
                {props.activeStep < 4 ? null : (
                  <Button
                    className={classes.backButton}
                    classes={{ label: classes.backButtonLabel }}
                    variant="outlined"
                    onClick={handleBackButton}
                  >
                    Back
                  </Button>
                )}
                {/* </div> */}
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  className={classes.nextButton}
                  classes={{
                    label: classes.buttonLabel,
                    root: classes.buttonRoot
                  }}
                  variant="contained"
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </div>
  );
};

// destroyOnUnmount - saves it in state
SetDetails = reduxForm({
  form: "signup",
  destroyOnUnmount: false,
  enableReinitialize: true,
  validate
})(SetDetails);

function mapDispatchToProps(dispatch) {
  return {
    incrementActiveStep: () => dispatch(incrementActiveStep()),
    decrementActiveStep: () => dispatch(decrementActiveStep()),
    incrementActiveStepHost: () => dispatch(incrementActiveStepHost()),
    decrementActiveStepHost: () => dispatch(decrementActiveStepHost()),
    updateUserDetails: user => dispatch(updateUserDetails(user)),
    setGender: value => dispatch(change("signup", "gender", value)),
    getClientProfile: userId => dispatch(getClientProfile(userId)),
    getProviderProfile: userId => dispatch(getProviderProfile(userId))
  };
}

function mapStateToProps(state, ownProps) {
  return {
    formValues: getFormValues("signup")(state) || {},
    activeStep: state.stepperReducer.activeStep,
    activeStepHost: state.stepperReducer.activeStepHost,
    loading: state.stepperReducer.loading,
    updateDetailsSuccess: state.stepperReducer.updateDetailsSuccess,
    updateDetailsError: state.stepperReducer.updateDetailsError,
    userData: state.loginReducer.userData,
    userProfile: state.stepperReducer.userProfile
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetDetails);
