import agent from "../../agent";
import {
  GET_LANGUAGE_DATA,
  GET_SERVICES_DATA,
  GET_SUITABLE_FOR_DATA,
  SEND_HOST_NAME,
  TOGGLE_HOST_LIKE
} from "./action-types";

//Currently not implemented in equipment page.
export const sendHostName = name => {
  return {
    type: SEND_HOST_NAME,
    payload: { name }
  };
};

export const toggleHostLike = hostId => {
  return {
    type: TOGGLE_HOST_LIKE,
    payload: { hostId }
  };
};

export function getLanguageData() {
  return function(dispatch) {
    return agent.Constants.getAllLanguages()
      .then(response => {
        if (response.status === 200) {
          dispatch({ type: GET_LANGUAGE_DATA, payload: response });
        }
      })
      .catch(error => {
        console.log("error", error);
        // dispatch(alertSubmitForm(false));
      });
  };
}

export function getSuitableForData() {
  return function(dispatch) {
    return agent.Constants.getSuitableforList()
      .then(response => {
        if (response.status === 200) {
          dispatch({ type: GET_SUITABLE_FOR_DATA, payload: response });
        }
      })
      .catch(error => {
        console.log("error", error);
        // dispatch(alertSubmitForm(false));
      });
  };
}

export function getServiceData() {

  return function(dispatch) {
    return agent.Constants.getServices()
      .then(response => {
        if (response.status === 200) {
          dispatch({ type: GET_SERVICES_DATA, payload: response });
        }
      })
      .catch(error => {
        console.log("error", error);
        // dispatch(alertSubmitForm(false));
      });
  };
}
