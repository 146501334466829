import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import AvailabilityCard from "../../../components/virtualLessons/Availability/AvailabilityCard.js";
import {
  createMuiTheme,
  responsiveFontSizes,
  Typography
} from "@material-ui/core";

import { UPDATE_REASONS } from "../../../redux/actions/action-types";
import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
  typographyFormat1: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontSize: 32,
    alignContent: "center",
    color: "#1277B8",
    marginTop: "2rem",
    marginBottom: "2rem",
    fontWeight: "bolder",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      fontSize: 24
    }
  },
  typographyFormat2: {
    fontFamily: "PT Sans Narrow",
    fontWeight: "700",
    fontStyle: "normal",
    fontSize: 25,
    textAlign: "center",
    color: "#1277B8",
    fontWeight: "bolder",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: 20
    }
  },
  typographyFormat3: {
    fontFamily: "PT Sans Narrow",
    fontWeight: "700",
    fontStyle: "normal",
    fontWeight: "bolder",
    fontSize: 25,
    alignContent: "left",
    color: "#1277B8",
    paddingBottom: 15,
    textAlign: "left",
    margin: "0 auto",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: 20
    }
  },
  main: {
    textAlign: "center",
    boxShadow: "none"
  },
  selectFieldFormat: {
    fontFamily: "PT Sans Narrow",
    fontWeight: "400",
    fontStyle: "normal",
    boxShadow: "none",
    width: "100%",
    height: "2rem",
    fontSize: 18,
    background: "#DBEBF4",
    textAlign: "left",
    paddingLeft: 10,
    margin: "1em 0",
    marginBottom: "3rem",
    border: "0 !important",
    outline: 0,
    boxShadow: "none",
    "& div.MuiSelect-select:focus": {
      backgroundColor: "transparent"
    }
  },
  textareaFieldFormat: {
    textAlign: "left",
    fontFamily: "PT Sans Narrow",
    background: "#DBEBF4",
    fontWeight: "400",
    fontStyle: "normal",
    height: 100,
    width: "100%",
    fontSize: 18,
    border: 0,
    resize: "none",
    margin: "0 auto",
    boxShadow: "none"
  },
  submitButtonFormat: {
    width: 150,
    marginTop: "2rem",
    borderRadius: "100px",
    height: 35,
    fontSize: 15,
    backgroundColor: "#FF6161",
    paddingTop: 10,
    paddingBottom: 10,
    [theme.breakpoints.down("sm")]: {
      width: 100,
      fontSize: 16
    }
  },
  divContainer: {
    width: "45%",
    margin: "0 auto",
    boxShadow: "none"
  },
  buttonContainer: {
    paddingTop: 50,
    paddingBottom: 10
  },
  formControl: {
    width: "100%",
    boxShadow: "none",
    "& div.MuiInput-underline:before": {
      borderBottom: "none"
    },
    "& div.MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: "none"
    },
    "& div.MuiInput-underline:after": {
      transition: "none",
      transform: "scaleX(0)"
    }
  },
  cancelConfirmContainer: {
    margin: "4rem 0",
    textAlign: "center",
    fontFamily: "PT Sans Narrow"
  },
  commonFormat: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal"
  }
}));
const CancelBooking = props => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [reason, setReason] = React.useState(0);
  const [enableReasonUtil, setEnableReasonUtil] = React.useState(false);
  const [enableCalendar, setEnableCalendar] = React.useState(false);
  const { reasons } = props;

  // This function to handle 1st option: "I want to reschedule for another time"
  const handleClickOpen = () => {
    setOpen(true);
  };
  let theme = createMuiTheme();
  theme = responsiveFontSizes(theme);
  function onReasonChange(event) {
    console.log(event);
    setReason(event.target.value);
    if (event.target.value === 2) {
      setEnableReasonUtil(true);
    } else {
      setEnableReasonUtil(false);
    }
    if (event.target.value === 1) {
      setEnableCalendar(true);
    } else {
      setEnableCalendar(false);
    }
    // Ternary opreator
    // setEnableCalendar(event.target.value === "1" ? true : false);
  }
  if (open) {
    return (
      <div className={classes.cancelConfirmContainer}>
        INTEGRATED AUTOMATED STRIPE MODULE PAGE FOR REFUND
        <br />
        OR
        <br />
        GENERATE THE REQUEST FOR REFUND
      </div>
    );
  } else {
    return (
      <div className={classes.main}>
        <div className={classes.divContainer}>
          <Typography variant="h4" className={classes.typographyFormat1}>
            CANCEL LESSON BOOKING
          </Typography>
          <Typography variant="h5" className={classes.typographyFormat2}>
            What is the reason behind cancelling the booking?
          </Typography>
          <FormControl className={classes.formControl}>
            <Select
              labelId="label"
              id="select"
              value={reason}
              className={classes.selectFieldFormat}
              onChange={onReasonChange}
            >
              <MenuItem value={0} selected border={0}>
                Select reason
              </MenuItem>
              {reasons.map(reason => (
                <MenuItem value={reason.value} selected border={0}>
                  {reason.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {enableCalendar && <AvailabilityCard />}
          {enableReasonUtil && (
            <div>
              <Typography className={classes.typographyFormat3}>
                Other
              </Typography>
              <textarea
                className={classes.textareaFieldFormat}
                placeholder="Please, provide details for cancellation"
              />
            </div>
          )}
          <Button
            variant="contained"
            color="secondary"
            className={classes.submitButtonFormat}
            onClick={handleClickOpen}
            disabled={reason === "No reason selected"}
          >
            Submit
          </Button>
        </div>
      </div>
    );
  }
};

// Reducer part
function mapStateToProps(state) {
  return {
    reasons: state.cancelBookingReducer.reasons
  };
}

// Connect part
export default connect(
  mapStateToProps,
  {
    UPDATE_REASONS
  },
  null,
  { forwardRef: true }
)(CancelBooking);
// export default CancelBooking;
