import React, { useEffect, useState } from "react";

import useStyles from "../ServicesStyles";

import CollapsibleCheckBox from "../../../../CollapsibleCheckBox";

//Child Components
import OnSlopeSkiing from "./OnSlopeSkiing";
import OnSlopeSnowboarding from "./OnSlopeSnowboarding";
import OffSlope from "./OffSlope";
import SpecialCare from "./SpecialCare";
import CanDriveCar from "./CanDriveCar";
import CanDriveOwnCar from "./CanDriveOwnCar";
import { PROVIDER_SERVICES } from "../../../../../../constants/common";
import { CardContent, Grid } from "@material-ui/core";
import { AdditionalChild, MaxGroupSize, PricePerHead } from "../InputFields";
import UploadDocument from "../Virtual-Lessons/UploadDocument";

const ChildEntertainment = props => {
  const {
    parent,
    nestingLevel,
    selectService,
    addOnSlopSnowBoarding,
    addOnSlopSkiing,
    addOffSlope,
    services,
    isDisabled,
    setoffSlop,
    setonSlopSkiing,
    setonSlopSnowBoarding
  } = props;
  const classes = useStyles();
  const [checkboxSelected, setCheckboxSelected] = useState(false);
  const [
    checkboxOnSlopeSnowboardingSelected,
    setOnSlopeSnowboardingCheckboxSelected
  ] = useState(false);
  const [
    checkboxOnSlopeSkiingSelected,
    setOnSlopeSkiingCheckboxSelected
  ] = useState(false);
  const [
    checkboxOffSlopeSkiingSelected,
    setOnOffSlopeCheckboxSelected
  ] = useState(false);

  const onCheckboxSelected = event => {
    setCheckboxSelected(event);
  };

  const onCheckboxOnSlopeSnowboardingSelected = event => {
    setOnSlopeSnowboardingCheckboxSelected(event);
    selectService(event, 16);
    addOnSlopSnowBoarding("is_provided", event);
  };

  const onSlopeSkiingCheckboxSelected = event => {
    setOnSlopeSkiingCheckboxSelected(event);
    selectService(event, 17);
    addOnSlopSkiing("is_provided", event);
  };

  const onOffSlopeCheckboxSelected = event => {
    setOnOffSlopeCheckboxSelected(event);
    selectService(event, 18);
    addOffSlope("is_provided", event);
  };

  // Prefill service data
  let [onSlopeSnowboardingDetail, setOnSlopeSnowboardingDetail] = useState({});
  let [onSlopeSkiingDetail, setSlopeSkiingDetail] = useState({});
  let [offSlopeDetail, setOffSlopeDetail] = useState({});
  const [credential, setCredential] = useState("");
  const [driverLicense, setDriverLicense] = useState("")

  useEffect(() => {
    if (services.length > 0 && services !== undefined){
      services.forEach(service => {
        if (service.service_id === 16){
          setOnSlopeSnowboardingDetail(service);
          setOnSlopeSnowboardingCheckboxSelected(service.is_provided);
          selectService(service.is_provided, 16);
          setonSlopSnowBoarding(service);
        }
        if (service.service_id === 17){
          setSlopeSkiingDetail(service);
          setOnSlopeSkiingCheckboxSelected(service.is_provided);
          selectService(service.is_provided, 17);
          setonSlopSkiing(service);
        }
        if (service.service_id === 18){
          setOffSlopeDetail(service);
          setoffSlop(service);
          setOnOffSlopeCheckboxSelected(service.is_provided);
          selectService(service.is_provided, 18);
        }
      });
    }
  }, [services]);

  const hasChildWorkingRight = (onSlopeSnowboardingDetail.child_working_credential_link || onSlopeSkiingDetail.child_working_credential_link || offSlopeDetail.child_working_credential_link) ? true : false;
  // const driverLicense = onSlopeSnowboardingDetail.driver_license_link || onSlopeSkiingDetail.driver_license_link || offSlopeDetail.driver_license_link;
  // const credential = onSlopeSnowboardingDetail.child_working_credential_link || onSlopeSkiingDetail.child_working_credential_link || offSlopeDetail.child_working_credential_link;
  useEffect(() => {
    if(onSlopeSnowboardingDetail.child_working_credential_link){
      setCredential(onSlopeSnowboardingDetail.child_working_credential_link);
    } else if(onSlopeSkiingDetail.child_working_credential_link) {
      setCredential(onSlopeSkiingDetail.child_working_credential_link);
    } else {
      setCredential(offSlopeDetail.child_working_credential_link);
    }

    if(onSlopeSnowboardingDetail.driver_license_link){
      setDriverLicense(onSlopeSnowboardingDetail.driver_license_name);
    } else if(onSlopeSkiingDetail.driver_license_link) {
      setDriverLicense(onSlopeSkiingDetail.driver_license_name);
    } else {
      setDriverLicense(offSlopeDetail.driver_license_name);
    }
  }, [onSlopeSnowboardingDetail, onSlopeSkiingDetail, offSlopeDetail]);

  useEffect(() => {
    props.setChildServiceCredential(credential);
    props.setDriverLicense(driverLicense);
  }, [credential, driverLicense]);

  useEffect(() => {
    if (checkboxOffSlopeSkiingSelected || checkboxOnSlopeSkiingSelected || checkboxOnSlopeSnowboardingSelected) {
      setCheckboxSelected(true);
    }
  }, [
    checkboxOffSlopeSkiingSelected,
    checkboxOnSlopeSkiingSelected,
    checkboxOnSlopeSnowboardingSelected
  ]);

  const OnSlopeSnowboarding = (parent, nestingLevel) => {
    const classes = useStyles();

    const inputFields = () => {
      return (
        <CardContent>
          <Grid
            container
            justify="center"
            alignItems="center"
            xs={8}
            md={8}
            lg={8}
          >
            <MaxGroupSize onTextChange={addOnSlopSnowBoarding} groupSize={onSlopeSnowboardingDetail.max_group_size} isDisabled={isDisabled}/>
            <PricePerHead onTextChange={addOnSlopSnowBoarding} pricePerHead={onSlopeSnowboardingDetail.price_per_head} isDisabled={isDisabled}/>
            <AdditionalChild onTextChange={addOnSlopSnowBoarding} childPrice={onSlopeSnowboardingDetail.additional_children_price} isDisabled={isDisabled}/>
          </Grid>
          <CollapsibleCheckBox
            name={"SpecialCare"}
            text="SPECIAL CARE NEEDS EXPERIENCE"
            parent={parent}
            children={null}
            nestingLevel={nestingLevel + 1}
            onCheckboxSelected={event =>
              addOnSlopSnowBoarding("special_care_experience", event)
            }
            isStar={true}
            isDisabled={isDisabled}
            isSelected={onSlopeSnowboardingDetail.special_care_experience}
            showCheckBox={isDisabled ? true : false}
          />
        </CardContent>
      );
    };

    const children = [inputFields];

    return (
      <CollapsibleCheckBox
        name={"OnSlopeSnowboarding"}
        text="ON-SLOPE SNOWBOARDING"
        parent={parent}
        children={children}
        nestingLevel={nestingLevel + 1}
        onCheckboxSelected={onCheckboxOnSlopeSnowboardingSelected}
        isDisabled={isDisabled}
        isSelected={checkboxOnSlopeSnowboardingSelected}
      />
    );
  };

  const OnSlopeSkiing = (parent, nestingLevel) => {
    const classes = useStyles();

    const inputFields = () => {
      return (
        <CardContent>
          <Grid
            container
            justify="center"
            alignItems="center"
            xs={8}
            md={8}
            lg={8}
          >
            <MaxGroupSize onTextChange={addOnSlopSkiing} groupSize={onSlopeSkiingDetail.max_group_size} isDisabled={isDisabled}/>
            <PricePerHead onTextChange={addOnSlopSkiing} pricePerHead={onSlopeSkiingDetail.price_per_head} isDisabled={isDisabled}/>
            <AdditionalChild onTextChange={addOnSlopSkiing} childPrice={onSlopeSkiingDetail.additional_children_price} isDisabled={isDisabled}/>
          </Grid>
          <CollapsibleCheckBox
            name={"SpecialCare"}
            text="SPECIAL CARE NEEDS EXPERIENCE"
            parent={parent}
            children={null}
            nestingLevel={nestingLevel + 1}
            onCheckboxSelected={value =>
              addOnSlopSkiing("special_care_experience", value)
            }
            isStar={true}
            isDisabled={isDisabled}
            isSelected={onSlopeSkiingDetail.special_care_experience}
            showCheckBox={isDisabled ? true : false}
          />
        </CardContent>
      );
    };

    const children = [inputFields];

    return (
      <CollapsibleCheckBox
        name={"OnSlopeSkiing"}
        text="ON-SLOPE SKIING"
        parent={parent}
        children={children}
        nestingLevel={nestingLevel + 1}
        onCheckboxSelected={onSlopeSkiingCheckboxSelected}
        isDisabled={isDisabled}
        isSelected={checkboxOnSlopeSkiingSelected}
      />
    );
  };

  const OffSlope = (parent, nestingLevel) => {
    const classes = useStyles();

    const inputFields = () => {
      return (
        <CardContent>
          <Grid
            container
            justify="center"
            alignItems="center"
            xs={8}
            md={8}
            lg={8}
          >
            <MaxGroupSize onTextChange={addOffSlope} groupSize={offSlopeDetail.max_group_size} isDisabled={isDisabled}/>
            <PricePerHead onTextChange={addOffSlope} pricePerHead={offSlopeDetail.price_per_head} isDisabled={isDisabled}/>
            <AdditionalChild onTextChange={addOffSlope} childPrice={offSlopeDetail.additional_children_price} isDisabled={isDisabled}/>
          </Grid>
          <CollapsibleCheckBox
            name={"SpecialCare"}
            text="SPECIAL CARE NEEDS EXPERIENCE"
            parent={parent}
            children={null}
            nestingLevel={nestingLevel + 1}
            onCheckboxSelected={(value) =>
              addOffSlope("special_care_experience", value)
            }
            // onCheckboxSelected = {event => addSpecialRequirements({}, "special_care_experience", event)}
            isStar={true}
            isDisabled={isDisabled}
            isSelected={offSlopeDetail.special_care_experience}
            showCheckBox={isDisabled ? true : false}
          />
          <CollapsibleCheckBox
            name={"CanDriveCar"}
            text="CAN DRIVE CAR"
            parent={parent}
            children={null}
            nestingLevel={nestingLevel + 1}
            onCheckboxSelected={event => addOffSlope("can_drive_car", event)}
            isStar={true}
            isDisabled={isDisabled}
            isSelected={offSlopeDetail.can_drive_car}
            showCheckBox={isDisabled ? true : false}
          />
          <CollapsibleCheckBox
            name={"CanDriveOwnCar"}
            text="CAN DRIVE OWN CAR"
            parent={parent}
            children={null}
            nestingLevel={nestingLevel + 1}
            onCheckboxSelected={event =>
              addOffSlope("can_drive_own_car", event)
            }
            isStar={true}
            isDisabled={isDisabled}
            isSelected={offSlopeDetail.can_drive_own_car}
            showCheckBox={isDisabled ? true : false}
          />
          <CollapsibleCheckBox
            name={"DogWalking"}
            text="DOG WALKING"
            parent={parent}
            children={null}
            nestingLevel={nestingLevel + 1}
            onCheckboxSelected={event => addOffSlope("dog_walking", event)}
            isStar={true}
            isDisabled={isDisabled}
            isSelected={offSlopeDetail.dog_walking}
            showCheckBox={isDisabled ? true : false}
          />
        </CardContent>
      );
    };

    const children = [inputFields];

    return (
      <CollapsibleCheckBox
        name={"OffSlope"}
        text="OFF SLOPE"
        parent={parent}
        children={children}
        nestingLevel={nestingLevel + 1}
        onCheckboxSelected={onOffSlopeCheckboxSelected}
        isDisabled={isDisabled}
        isSelected={checkboxOffSlopeSkiingSelected}
      />
    );
  };

  const workWithChildrenCheck = () => {
    const text = "All providers of child minding services need to produce a valid working with children check"
    return <UploadDocument onImageUpload={addOnSlopSnowBoarding} onImageUpload2={addOnSlopSkiing} onImageUpload3={addOffSlope} isDisabled={isDisabled} hasFile={hasChildWorkingRight} text={text} fileLink={credential}/>
  };

  const children = [OnSlopeSnowboarding, OnSlopeSkiing, OffSlope, workWithChildrenCheck];

  return (
    <CollapsibleCheckBox
      name={"ChildEntertainment"}
      text={PROVIDER_SERVICES.CHILD_MINDING}
      parent={null}
      children={children}
      nestingLevel={0}
      onCheckboxSelected={onCheckboxSelected}
      isDisabled={isDisabled}
      isSelected={checkboxSelected}
    />
  );
};

export default ChildEntertainment;
