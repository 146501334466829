import React, {useState} from "react";
import Grid  from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Button, IconButton, Popover } from "@material-ui/core";
import blankImage from "../profile/blank_photo_icon.jpg";
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
  divImage: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    paddingRight: "10px",
		[theme.breakpoints.down("xs")]: {
			paddingRight: "18px",
		}
  },
  editIcon: {
    position: "absolute",
    zIndex: "2",
    color: "white",
    backgroundColor: "royalblue",
    margin: "5px"
  },
  disabledEditIcon: {
	position: "absolute",
    zIndex: "2",
    color: "white",
    backgroundColor: "darkgrey",
    margin: "5px"
  },
  imageStyle: {
	objectFit: "cover",
	objectPosition: "center"
  }
}));

export default function ImageWithEditIcon(props) {
	const classes = useStyles();
	const { index, handleImageDataChange } = props;
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;
	let [image, setImage] = useState({});
	let [uploadedImage, setUploadedImage] = useState("");

	// when the edit button is clicked, showing the "remove/" popover
	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	// handle upload image event
	const handleImageUpload = event => {
		let uploadedImageUrl = URL.createObjectURL(event.target.files[0]);
		let imageFile = event.target.files[0];
		// console.log(uploadedImageUrl);
		// console.log(imageFile); // the file info
		setUploadedImage(uploadedImageUrl);
		setImage(event.target.files[0]);
		handleImageDataChange(uploadedImageUrl, imageFile, index);
	};

	// handle remove image event
	const handleRemoveImage = () => {
		setUploadedImage(blankImage);
		setImage(null);
		handleImageDataChange(blankImage, "", index);
	}

  return (
		<Grid>
			<div className={classes.divImage}>
				<img 
					src={ uploadedImage || props.image } 
					alt="Gallery Images" 
					height={100} 
					width={100}
					className={classes.imageStyle}
				/>
				{props.isEditMode ? (
						<IconButton 
							className={classes.editIcon}  
							onClick={handleClick}
							aria-describedby={id}
							size="small"
						>
							<EditIcon fontSize="small"/>
						</IconButton>
				) : (
					<IconButton 
						className={classes.disabledEditIcon}  
						// onClick={handleClick}
						aria-describedby={id}
						size="small"
					>
						<EditIcon fontSize="small"/>
					</IconButton>
				) }
			</div>

			{props.isEditMode ? ( 
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right"
					}}
				>
					<Grid>
						<div>
							<Button color="primary" onClick={handleRemoveImage}>Remove</Button>
						</div>
					</Grid>
					<Grid>
					<div>
							<input 
								accept="image/*"
								className={classes.input}
								id="btn--image"
								multiple
								type="file"
								onChange={handleImageUpload}
							/>
							<label htmlFor="btn--image">
								<Button color="primary" component="span"></Button>
							</label>
						</div>
					</Grid>

				</Popover>
			) : null }
		</Grid>
	);
}