import {
  CHANGE_TRIP_START_DATE,
  CHANGE_TRIP_END_DATE
} from "../actions/action-types";

const today = new Date();

const parseTimeZone = date => {
  const adjustedDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  );

  return adjustedDate;
};

const start = new Date(new Date().setDate(today.getDate() + 2)); // initially, start date is today + 1 day,
const end = new Date(new Date().setDate(today.getDate() + 6));

const initialState = {
  startDate: parseTimeZone(start),
  endDate: parseTimeZone(end)
};

function tripWhenReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_TRIP_START_DATE:
      return { ...state, startDate: action.payload.startDate };
    case CHANGE_TRIP_END_DATE:
      return { ...state, endDate: action.payload.endDate };
    default:
      return state;
  }
}

export default tripWhenReducer;
