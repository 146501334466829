import { monthNames } from "../constant/month";

/**
 * Calculate the end date using startDate and durationDays
 * @param {Date} startDate - from redux, start date of the lesson
 * @param {Date} durationDays - from redux, indicates how many the lesson days go
 *
 * @returns {Date, Number, String} - contains end date, end day and month
 */
export const calculateEndDate = (startDate, durationDays) => {
  const endDate = new Date(startDate.getTime());
  const date = endDate.getDate();
  endDate.setDate(date + parseInt(durationDays));

  const endDay = endDate.getDate();
  const endMonth = monthNames[endDate.getMonth()];

  return {
    endDate,
    endDay,
    endMonth
  };
};

/**
 * Given startDate and endDate, list each single day in between
 * @param {Date} startDate - from redux, start date of the lesson
 * @param {Date} endDate - calculated, end date of the lesson
 *
 * @returns {array} - array of Date
 */
export const listAllDates = (startDate, endDate) => {
  const oneDay = 24 * 3600 * 1000;
  let allDays = [];

  for (let ms = startDate * 1, last = endDate * 1; ms < last; ms += oneDay) {
    allDays.push(new Date(ms));
  }

  return allDays;
};

/**
 * Format the time string to am/pm
 * @param {Date} date - date object
 *
 * @returns {String} string format of time (am/pm)
 */
export const formatAMPM = date => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  hours = hours < 10 ? "0" + hours : hours;
  const strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const calculateSession = (startTime, durationHours) => {
  // tempDate is just used to format am/pm
  const tempDate = new Date("1970-1-1 " + startTime);
  const formattedStartTime = formatAMPM(tempDate);
  tempDate.setTime(tempDate.getTime() + durationHours * 60 * 60 * 1000);
  const endTime = formatAMPM(tempDate);

  const hourStr = durationHours === 1 ? "hour" : "hours";
  const session = `${formattedStartTime} - ${endTime} (${durationHours} ${hourStr})`;

  return session;
};

export const calculatePrivateDayRange = (startDate, endDate) => {
  let filteredDays = [];
  const maxDateRange =
    (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
  for (let i = 1; i <= maxDateRange; i++) {
    filteredDays.push(`${i}`);
  }
  return filteredDays;
};

// split durationDays to flexibleDay and durationDay
// e.g.: 4 out of 6 => 4, 6
export const splitDurationDays = durationDays => {
  let flexibleDay = 0;
  let durationDay;
  if (isNaN(durationDays)) {
    durationDay = parseInt(durationDays.slice(0, durationDays.indexOf(" ")));
    const index = durationDays.indexOf("of");

    if (index !== -1) {
      // flexible_day not 0/null
      const last = durationDays.lastIndexOf(" ");
      flexibleDay = parseInt(durationDays.slice(last + 1));
    }
  } else {
    durationDay = parseInt(durationDays);
  }

  return { flexibleDay, durationDay };
};

// split session to start time and durationHours
// e.g.: 10:30 AM - 1:00 PM
export const splitSession = session => {
  let startTime, durationHours;
  const bracket = session.indexOf("(");
  const emptySpace = session.indexOf(" ", bracket);
  durationHours = session.slice(bracket + 1, emptySpace);
  const time = session.slice(0, session.indexOf(" "));
  startTime = `${time}`;
  return { startTime, durationHours };
};
