import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../styling/css/Footer.css";
import PrivacyStatement from "./PrivacyStatementPopup";
import TermsOfUse from "./TermsOfUsePopup";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const FONT_SIZE = "12px";

export default class Footer extends React.Component {
  render() {
    return (
      <div style={{ flexGrow: 1, overflowX: "hidden" }}>
        {/* footer container */}
        <Grid
          container
          alignItems="center"
          xs={12}
          spacing={2}
          justifyContent="center"
          style={{ margin: 0, padding: "10px 0px" }}
        >
          <Grid item container style={{ padding: "0px" }}>
            {/* terms of use */}
            <Grid
              item
              xs={4}
              md={4}
              style={{ padding: "0px", fontSize: FONT_SIZE }}
            >
              <TermsOfUse type="footer" />
            </Grid>

            {/* social media links */}
            <Grid
              item
              container
              xs={4}
              md={4}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={2} md={1} style={{ margin: "0rem 1rem" }}>
                <a
                  href="https://www.facebook.com/RESORTerapp-720393314994703/"
                  target="_blank"
                >
                  <i
                    className="fab fa-facebook "
                    style={{ fontSize: "30px", color: "black" }}
                  />
                </a>
              </Grid>

              <Grid item xs={2} md={1} style={{ margin: "0rem 1rem" }}>
                <a
                  href="https://instagram.com/resorter.app?igshid=15u0376hpccny"
                  target="_blank"
                >
                  <i
                    className="fab fa-instagram"
                    style={{ fontSize: "30px", color: "black" }}
                  />
                </a>
              </Grid>
            </Grid>

            {/* privacy statement */}
            <Grid
              item
              xs={4}
              md={4}
              justify="center"
              style={{ fontSize: FONT_SIZE }}
            >
              <PrivacyStatement type="footer" />
            </Grid>
          </Grid>

          <Grid
            item
            container
            justifyContent="center"
            style={{ padding: "0px" }}
          >
            {/* Resorter HQ */}
            <Grid
              item
              xs={6}
              md={6}
              style={{
                fontFamily: "Roboto",
                fontSize: FONT_SIZE,
                padding: "0px"
              }}
            >
              <Typography align="center" style={{ fontSize: FONT_SIZE }}>
                Melbourne, Australia
              </Typography>
            </Grid>

            {/* Resorter email */}
            <Grid item xs={6} md={6} style={{ padding: "0px" }}>
              <a
                href="mailto:info@resorter.app"
                style={{
                  fontFamily: "Roboto",
                  fontSize: FONT_SIZE
                }}
              >
                <Typography align="center" style={{ fontSize: FONT_SIZE }}>
                  info@resorter.app
                </Typography>
              </a>
            </Grid>
          </Grid>

          {/* copyright */}
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="center"
            style={{
              fontFamily: "Roboto",
              fontSize: FONT_SIZE,
              padding: "0px"
              // fontWeight: "700"
            }}
          >
            Copyright 2017 - RESORTer - All Rights Reserved
          </Grid>
        </Grid>
      </div>
    );
  }
}
