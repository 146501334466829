import React, { useEffect, useRef, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, TextField, Button } from "@material-ui/core";
import { FONTFAMILY } from "../../../styling/theme/Theme";
import { useParams } from "react-router-dom";
import { instance } from "../../../agent";
import { Label } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  title: {
    margin: "2rem 0rem",
    fontSize: 26,
    fontWeight: "400",
    fontFamily: FONTFAMILY.FONT_SANS_NARROW,
    color: "#000000"
  },
  textboxlabel: {
    fontSize: "18px",
    fontWeight: "blod",
    fontFamily: "PT Sans Narrow",
    margin: "0.5em",
    textTransform: "uppercase"
  },
  textFields: {
    width: "20em",
    "& .MuiOutlinedInput-root": {
      // - The Input-root, inside the TextField-root
      "& fieldset": {
        // - The <fieldset> inside the Input-root
        borderColor: "black" // - Set the Input border
      },
      "&:hover fieldset": {
        borderColor: "black" // - Set the Input border when parent has :hover
      },
      "&.Mui-focused fieldset": {
        // - Set the Input border when parent is focused
        borderColor: "black"
      }
    },
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      width: "25em",
      marginLeft: "3em"
    },
    [theme.breakpoints.down("xs")]: {
      width: "15em",
      marginLeft: "3em",
      marginRight: "3em"
    }
  },
  loginBtn: {
    borderRadius: 100,
    width: "14em",
    height: "3em",
    margin: "2em",
    [theme.breakpoints.down("sm")]: {
      width: "2em",
      height: "3em",
      margin: "1rem 0rem"
    },
    backgroundColor: "rgb(255,97,97)",
    "&:hover": {
      backgroundColor: "rgb(255,97,97)"
    },
    boxShadow:
      "rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px",
    [theme.breakpoints.down("lg")]: {
      width: "15em"
    }
  },
  btnLabel: {
    color: "white",
    fontFamily: "PT Sans Narrow",
    fontWeight: "bold",
    textTransform: "none"
  },
  errorMsg: {
    textAlign: "center",
    color: "#ff0033"
  }
}));

function CreateNewPassword() {
  const ref = useRef(null);
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setconfirmPass] = useState("");
  const [isError, setIsError] = useState(false);
  const [errors, setErrors] = useState({});
  const { id, token } = useParams();
  const [valid, setValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [displayMessage, setdisplayMessage] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    if (!password) {
      setErrors({ requiredPassword: "*Required" });
    } else if (!confirmPassword) {
      setErrors({ requiredConfirmPassword: "*Required" });
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setErrors({ emailFormat: "Please enter valid email!" });
    } else if (
      !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/.test(password)
    ) {
      setErrors({
        passwordFormat:
          "Password must be 8 charaters long, has at least a number, and at least a special character"
      });
    } else if (password !== confirmPassword) {
      setErrors({ passwordMismatch: "Passwords don't match" });
    } else {
      setErrors({});
      setLoading(true);
      instance
        .post("/auth/password-reset", {
          email,
          password
        })
        .then(res => {
          setLoading(false)
          setdisplayMessage(true);
        })
        .catch(error => {
          console.log(error.toJSON());
        });
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current) {
        // setvisible(false)
        if (!password) {
          setErrors({ requiredPassword: "*Required" });
        } else if (!confirmPassword) {
          setErrors({ requiredConfirmPassword: "*Required" });
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
          setErrors({ emailFormat: "Please enter valid email!" });
        } else if (
          !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/.test(
            password
          )
        ) {
          setErrors({
            passwordFormat:
              "Password must be 8 charaters long, has at least a number, and at least a special character"
          });
        } else if (password !== confirmPassword) {
          setErrors({ passwordMismatch: "Passwords don't match" });
        } else {
          setErrors({});
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [email, password, confirmPassword]);

  useEffect(()=>{
    const checkUser = async () => {
      instance({
        url: `/auth/reset-password-email-verify/${id}/${token}`,
        method: "GET"
      }).then(res => {
        setEmail(res.data.email);
        setValid(true);
      }).catch((e)=>{
        setValid(false);
      });
    }
    checkUser();
  },[])

  return (
    <div ref={ref}>
      {!valid && (
        <div style={{ padding: "10rem 0", textAlign: "center" }}>
          This link is invalid, please use the correct link.
        </div>
      )}
      {valid && (
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item sm={12} xs={12}>
            <Typography align="center" className={classes.title}>
              Create New Password
            </Typography>
          </Grid>

          {loading && (
            <Grid
              container
              justify="center"
              alignItems="center"
              spacing={0}
              direction="column"
            >
              <Grid
                container
                justify="center"
                alignItems="flex-start"
                xs={12}
                sm={10}
                md={8}
                lg={6}
                spacing={2}
                direction="row"
                style={{ backgroundColor: "white", padding: "2rem 0px" }}
              >
                <div className="loader-container">
                  <div className="loader" />
                </div>
              </Grid>
            </Grid>
          )}
          {!loading && displayMessage && (
            <div>
              <Typography className={classes.errorMsg}>
                The password has been successfully reset!
              </Typography>
            </div>
          )}
          {!loading && !displayMessage && (
            <Grid>
              <Grid item md={12} style={{ margin: "1rem 0rem" }}>
                <Typography className={classes.textboxlabel}>
                  {" "}
                  Email
                </Typography>
                <div style={{ margin: "1rem 1rem" }}>{email}</div>
              </Grid>
              <Grid item md={12} style={{ margin: "1rem 0rem" }}>
                <Typography className={classes.textboxlabel}>
                  {" "}
                  Password
                </Typography>
                <TextField
                  value={password}
                  onChange={e => {
                    setPassword(e.target.value);
                  }}
                  variant="outlined"
                  color="black"
                  focused
                  size="small"
                  borderColor="yellow !important"
                  className={classes.textFields}
                  type="password"
                />
              </Grid>
              <div className={classes.errorMsg}>
                {errors.requiredPassword ||
                  errors.passwordFormat ||
                  errors.passwordMismatch}
              </div>
              <Grid item md={12} style={{ margin: "1rem 0rem" }}>
                <Typography className={classes.textboxlabel}>
                  Confirm Password
                </Typography>
                <TextField
                  value={confirmPassword}
                  onChange={e => {
                    setconfirmPass(e.target.value);
                  }}
                  variant="outlined"
                  color="black"
                  focused
                  size="small"
                  borderColor="yellow !important"
                  className={classes.textFields}
                  type="password"
                />
                <div className={classes.errorMsg}>
                  {errors.requiredConfirmPassword || errors.passwordMismatch}
                </div>
              </Grid>
            </Grid>
          )}
          {!loading && !displayMessage && (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                className={classes.loginBtn}
                classes={{ label: classes.btnLabel }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
}

export default CreateNewPassword;
