//This is the card for making bookings on instructor page
//installed packages
import React, { useState } from "react";
import { connect } from "react-redux";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import axios from "axios";
import moment from "moment";
//MaterialUI
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
//Components
import StyledButton from "../StyledButton";
import SelectedBookingTimeslots from "./SelectedBookingTimeslots";
import {
  toggleVirtInstructorLike,
  createBooking,
  updateErrorsTimeslot
} from "../../../redux/actions/virtInstructorDataActions";
import { useHistory } from "react-router";

//styling
const useStyles = makeStyles({
  smallSectionHeading: {
    fontSize: 16
  },
  root: {
    width: "100%",
    padding: 10,
    height: "100%"
  },
  textHeading: {
    fontSize: 16,
    color: "#1277B8",
    fontWeight: "bold",
    fontFamily: "PT Sans Narrow",
    lineHeight: "140.62%",
    padding: 6
  },
  buttonContainer: {
    margin: "0.5rem 0"
  }
});

//Main function
//Provides a card for viewing availability and booking lessons
const AvailabilityCard = props => {
  const {
    instructorData,
    timeslots,
    toggleVirtInstructorLike,
    createBooking,
    _updateErrorsTimeslot
  } = props;
  //styling
  const classes = useStyles();

  const history = useHistory();

  //constants
  const SELECT_LABEL = "Selected Timeslot";
  const BOOKING_COMPLETE_LABEL = "Booking Successful!";
  const BOOKING_RETRY_LABEL = "Please select a valid time";

  //default states
  const [resort_id, setResortID] = React.useState("");
  const [canBook, setCanBook] = React.useState(true); //determines if bookings are currently clickable
  const [specialisation_id, setSpecialisationID] = React.useState();
  const [timeslotLabel, setTimeslotLabel] = React.useState(SELECT_LABEL);
  const [bookings, setBookings] = React.useState("");
  const [unavailableDates, setUnavailability] = React.useState([]);
  const [chosenday, setChosenday] = React.useState(new Date());
  const [availableTimes, setAvailableTimes] = React.useState([]);

  //function for changing bottom calendar viewing date
  // const updateTime = props.updateTime;

  //Detects if resorts or resort_bookings have been loaded yet
  React.useEffect(() => {
    //load available resorts to book
    // setResorts(instructorData.resorts);
    //sets default resort to book
    if (typeof instructorData.resorts[0] !== "undefined")
      setResortID(instructorData.resorts[0].id);
    //sets default specialisation to book
    if (typeof instructorData.specialisations[0] !== "undefined") {
      setSpecialisationID(instructorData.specialisations[0].id);
    }
    // setChosenday(new Date());
    // setCanBook(true);
    // updateBookings();
  }, [instructorData.resort_bookings, instructorData.resorts, resort_id]);

  const newAvList = [];
  let modifiers = {
    disabled: unavailableDates,
    selected: [chosenday],
    partials: newAvList
  };

  if (bookings.length > 0 && unavailableDates.length > 0) {
    for (let i = 0; i < bookings.length; i++) {
      let found = false;
      let j = 0;
      while (j < unavailableDates.length && found === false) {
        if (
          new Date(
            bookings[i].start_time.getFullYear(),
            bookings[i].start_time.getMonth(),
            bookings[i].start_time.getDate()
          ).getTime() ===
            new Date(
              unavailableDates[j].getFullYear(),
              unavailableDates[j].getMonth(),
              unavailableDates[j].getDate()
            ).getTime() ||
          new Date(
            bookings[i].end_time.getFullYear(),
            bookings[i].end_time.getMonth(),
            bookings[i].end_time.getDate()
          ).getTime() ===
            new Date(
              unavailableDates[j].getFullYear(),
              unavailableDates[j].getMonth(),
              unavailableDates[j].getDate()
            ).getTime()
        ) {
          found = true;
        }
        j++;
      }
      if (found === false) {
        newAvList.push(bookings[i]);
      }
    }
  } else {
    modifiers = {
      disabled: unavailableDates,
      partials: bookings,
      selected: [chosenday]
    };
  }

  const modifiersStyles = {
    disabled: {
      color: "#686868",
      backgroundColor: "#D3D3D3"
    },
    partials: {
      color: "#686868",
      backgroundColor: "#EEE8AA"
    },
    selected: {
      color: "FF0000"
    }
  };

  //Creates a database entry for the chosen booking
  const createBookingUtil = timeslot => {
    const baseURL = "../../api/";

    axios
      .post(baseURL + "/virtualBooking/create", {
        //TODO: change this to currently logged in user
        user_id: instructorData.id,
        instructor_id: instructorData.id,
        resort_id: resort_id,
        specialisation_id: specialisation_id,
        start: timeslot.datefrom.toString(),
        end: timeslot.dateto.toString()
      })
      .then(response => {
        const responseData = response.data;
      })
      .catch(error => {
        console.log(error);
      });
  };

  //Loads the current bookings at the selected resort
  const updateBookings = () => {
    let booking_data = [];
    if (instructorData) booking_data = [...instructorData.resort_bookings];
    booking_data = booking_data.filter(
      booking => booking.resort_id === resort_id
    );
    //const resortObject = [];
    if (booking_data.length > 0) {
      setBookings(booking_data);
      setUnavailability(booking_data[0].unavailableDays);
    } else {
      setBookings([]);
      setUnavailability([]);
    }
  };

  // function that check whether there is overlap in the selected time slots, raise an error if so
  const checkOverlap = () => {
    // let copyOftimeslots = timeslots.map(slot => ({ ...slot, error: false }));
    let copyOftimeslots = [...timeslots];
    // including index to prevent comparing the timeslot with itself
    copyOftimeslots.forEach((targetTimeslot, indexOfTarget) => {
      let isOverlapping = false;
      const minTimeError =
        moment(targetTimeslot.datefrom).isSameOrBefore(new Date()) ||
        moment(targetTimeslot.dateto).isSameOrBefore(new Date()) ||
        moment(targetTimeslot.dateto).isSameOrBefore(
          moment(targetTimeslot.datefrom).add(15, "minutes")
        );
      copyOftimeslots.forEach((comparedTimeslot, indexOfCompared) => {
        if (indexOfTarget != indexOfCompared) {
          if (
            moment(targetTimeslot.datefrom).isBetween(
              // make sure the instructor has 15-minute slack time
              moment(comparedTimeslot.datefrom).subtract(15, "minutes"),
              moment(comparedTimeslot.dateto).add(15, "minutes"),
              undefined,
              "()"
            ) ||
            moment(targetTimeslot.dateto).isBetween(
              moment(comparedTimeslot.datefrom).subtract(15, "minutes"),
              moment(comparedTimeslot.dateto).add(15, "minutes"),
              undefined,
              "()"
            )
          ) {
            isOverlapping = true;
          }
        }
      });
      copyOftimeslots[indexOfTarget].error = isOverlapping || minTimeError;
    });
    _updateErrorsTimeslot(copyOftimeslots);
  };

  //renders buttons for finally booking selected dates or cancelling selections
  //Only viewable when booking a new date.
  const renderBookButtons = () => {
    if (!canBook) {
      return null;
    } else {
      return (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
          className={classes.buttonContainer}
        >
          <Grid item>
            <StyledButton displayText={"Book"} onClick={bookButtonClicked} />
          </Grid>
          <Grid item>
            <StyledButton
              displayText={"Cancel"}
              outlined
              onClick={() => {
                setCanBook(false);
              }}
            />
          </Grid>
        </Grid>
      );
    }
  };

  //Renders the legend for the calendar.
  //Only viewable when not booking a new date. (ie. canBook === false)
  const renderLegend = () => {
    if (!canBook)
      return (
        <Grid xs={12} item container justify="flex-start">
          <Grid xs={12} item container justify="center">
            <span
              style={{
                color: "#686868",
                backgroundColor: "#D3D3D3",
                borderRadius: "50%",
                height: "25px",
                width: "25px"
              }}
            >
              {" "}
            </span>
            <span> Unavailable </span>
          </Grid>
          <Grid xs={12} item container justify="center">
            <span
              style={{
                color: "#686868",
                backgroundColor: "#EEE8AA",
                borderRadius: "50%",
                height: "25px",
                width: "25px"
              }}
            >
              {" "}
            </span>
            <span> Partially Available </span>
          </Grid>
          <Grid xs={12} item container justify="center">
            <span
              style={{
                color: "#686868",
                backgroundColor: "##ECF0F1",
                border: "2px solid black",
                borderRadius: "50%",
                height: "25px",
                width: "25px"
              }}
            >
              {" "}
            </span>
            <span> Available </span>
          </Grid>
        </Grid>
      );
    else return null;
  };

  const renderTimeslots = () => {
    if (canBook)
      return (
        <SelectedBookingTimeslots
          timeslotLabel={timeslotLabel}
          availableTimes={availableTimes}
          bookings={bookings}
          chosenday={chosenday}
          setAvailableTimes={setAvailableTimes}
          canBook={canBook}
          resort_id={resort_id}
          setTimeslotLabel={setTimeslotLabel}
          pricing={instructorData.rates}
        />
      );
    else return <div />;
  };

  //function that fires when "Book" is pressed
  const bookButtonClicked = () => {
    checkOverlap();
    if (!timeslots.find(element => element.error)) {
      if (canBook) {
        setCanBook(false);
        console.log(timeslots);
        timeslots.map(timeslot => {
          // createBookingUtil(timeslot);
          createBooking(resort_id, timeslot);
          history.push({
            pathname: "/vl/payment"
          });
        });
      } else {
        setTimeslotLabel(BOOKING_RETRY_LABEL);
      }
    }
  };

  return (
    <Card className={classes.root} variant="outlined">
      <Typography className={classes.textHeading} align="center" gutterBottom>
        BOOKING
      </Typography>

      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid xs={12} item>
          <select
            id="resorts"
            key="resorts"
            onChange={event => setResortID(event.target.value)}
            value={resort_id}
          >
            <option value="" disabled>
              Select Resort
            </option>
            {instructorData.resorts.map(resort => {
              return (
                resort &&
                resort.fullname && (
                  <option key={resort.id} value={resort.id}>
                    {resort.fullname}
                  </option>
                )
              );
            })}
          </select>
        </Grid>

        {/* Legend for datepicker */}
        {/* {renderLegend()} */}

        {renderTimeslots()}
        {renderBookButtons()}
        {renderLegend()}
      </Grid>
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    instructorData: state.virtualInstructorReducer.currentProfile,
    timeslots: state.virtualInstructorReducer.timeslots
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleVirtInstructorLike: virtInstructorId =>
      dispatch(toggleVirtInstructorLike(virtInstructorId)),
    createBooking: (resort_id, timeslot) =>
      dispatch(createBooking(resort_id, timeslot)),
    _updateErrorsTimeslot: timeslots =>
      dispatch(updateErrorsTimeslot(timeslots))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AvailabilityCard);
