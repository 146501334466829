import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Collapse from "@material-ui/core/Collapse";
import ReactPlayer from "react-player";

const useStyles = makeStyles({
  root: {
    minWidth: 200
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  name: {
    color: "#0B9AC7",
    fontWeight: "bold"
  },
  manageChip: {
    margin: 5,
    backgroundColor: "#FF6161"
  },
  messageChip: {
    marginBottom: 5,
    backgroundColor: "#ff6161"
  },
  calendarChip: {
    color: "#0A8CB4",
    borderColor: "#0A8CB4",
    marginTop: 5
  },
  price: {
    marginLeft: 10,
    fontWeight: "bold"
  },
  meetingChip: {
    float: "right",
    backgroundColor: "#FF6161",
    marginTop: 5
  },
  schedule: {
    fontWeight: "bold"
  },
  video: {
    maxHeight: 500
  }
});

export default function LessonCard(props) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  const name = props.name;
  const price = props.price;
  const schedule = props.schedule;
  const videoLink = props.videoLink;
  const messageLink = props.messageLink;
  const calenderLink = props.calenderLink;
  const meetingLink = props.meetingLink;

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card elevation={0} className={classes.root}>
      <CardContent>
        <Typography display={"inline"} className={classes.name} gutterBottom>
          {name}
        </Typography>
        <Chip
          onClick={handleExpandClick}
          className={classes.manageChip}
          label="Manage Booking"
          size="small"
          color="secondary"
          aria-expanded={expanded}
          // variant={"outlined"}
        />
        <Typography display={"inline"} className={classes.price}>
          ${price}
        </Typography>
        <Typography className={classes.schedule} color={"textSecondary"}>
          {schedule}
        </Typography>
        {/*<IconButton*/}
        {/*  className={clsx(classes.expand, {*/}
        {/*    [classes.expandOpen]: expanded*/}
        {/*  })}*/}
        {/*  size={"small"}*/}
        {/*  onClick={handleExpandClick}*/}
        {/*  aria-expanded={expanded}*/}
        {/*  aria-label="show more"*/}
        {/*>*/}
        {/*  <ExpandMoreIcon />*/}
        {/*</IconButton>*/}
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {/*<Typography paragraph>Videos:</Typography>*/}
            <Chip
              label="Send Message"
              className={classes.messageChip}
              component="a"
              href={messageLink}
              clickable
              color="secondary"
              size="small"
              // variant="outlined"
            />
            <ReactPlayer
              className={classes.video}
              url={videoLink}
              width="100%"
              controls
            />
            <Chip
              label="Add to Calendar"
              className={classes.calendarChip}
              component="a"
              href={calenderLink}
              clickable
              color="primary"
              size="small"
              variant="outlined"
            />
            <Chip
              label="Go to Meeting"
              className={classes.meetingChip}
              component="a"
              href={meetingLink}
              clickable
              color="secondary"
              size="small"
              // variant="outlined"
            />
          </CardContent>
        </Collapse>
      </CardContent>
    </Card>
  );
}
