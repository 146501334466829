import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { getProviderProfile } from "../../../redux/actions/stepperActions";

//Database
import agent from "../../../agent";

//Material UI
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

//Components
import Services from "../../../components/Common/SignupForm/ProviderInfo/Services/Services";
import ImageGallery from "./ImageGallery";
import Bio from "../../../components/Common/SignupForm/UserInfo/Bio";
// import Nationality from "../../../components/Common/SignupForm/UserInfo/Nationality";
import Nationality from "./NationalityDropdown";
import { renderSelectField } from "../../../components/Common/FormField/FormField";
import Languages from "./LanguageDropdown";
import AbilityLevel from "./AbilityLevelDropdown";
import Countries from "./CountryDropdown";
import SuitableFor from "./SuitableForDropdown";
import Resorts from "../../../components/Common/SignupForm/ProviderInfo/Resorts";
import ProfileAvatar from "./ProfileAvatar";
import Store from "../../../redux/store";
import DropdownMenu from "./NationalityDropdown";

const useStyles = makeStyles(theme => ({
  myProfileLabel: {
    width: "100%",
    fontSize: "x-large",
    width: "50%",
    margin: "3rem 0",
    fontFamily: "PT Sans Narrow",
    paddingLeft: "1rem"
  },
  imageGalleryTitle: {
    width: "50%",
    marginTop: "3rem"
  },
  uploadBtn: {
    width: "50%",
    marginTop: "3rem",
    justifyContent: "end"
  },
  sectionMargin: {
    margin: "2rem 0",
    width: "100%"
  },
  saveButton: {
    // marginTop: "3em",
    width: "102px",
    height: "2.5em",
    borderRadius: 100,
    textAlign: "center",
    backgroundColor: "rgb(255,97,97)",
    marginLeft: "1em"
  },
  cancelButtonLabel: {
    color: "#495057",
    fontWeight: 700,
    letterSpacing: "1%",
    fontFamily: "PT Sans"
  },
  buttonLabel: {
    color: "white",
    fontWeight: 700,
    letterSpacing: "1%",
    fontFamily: "PT Sans"
  },
}));


function MyAccountEdit(props) {
  const classes = useStyles();
  const store = Store.getState();
  console.log(store);
  console.log(props);
  const { userData, userProfile } = props;

  let [profileImage, setProfileImage] = useState({});
  let [bio, setBio] = useState("");
  let [nationality, setNationality] = useState({});
  let [languages, setLanguages] = useState([{}]);
  let [abilityLevel, setAbilityLevel] = useState([]);
  let [skiLevel, setSkiLevel] = useState(0);
  let [snowboardLevel, setSnowboardLevel] = useState(0);
  let [countryResorts, setCountryResorts] = useState([]);
  // let [resorts, setResorts] = useState([]);
  let [suitableFor, setSuitableFor] = useState([{}]);
  let [providedServices, setProvidedServices] = useState([{}]);
  let [gallery, setGallery] = useState([]);

  // update userProfile for testing purpose - user_id 197 or 250
  useEffect(() => {
    props.getProviderProfile(197);
  }, []);

  // Update UI
  useEffect(() => {  
    // if (Object.hasOwn(userProfile, "id")){
    if (userProfile && userProfile !== undefined){
      // console.log("TESTING 2");
      // console.log(userProfile.user.languages);
      // let languageList = [];
      // userProfile.user.languages.forEach(language => {
      //   languageList.push(language.language);
      // });
      // console.log(languageList);
      // console.log(userProfile.resorts);
      // let resortCountryList = [];
      // let resortList = [];
      // userProfile.resorts.forEach(resort => {
      //   resortCountryList.push(resort.country);
      //   resortList.push(resort.resort_name);
      // });
      // console.log([...new Set(resortCountryList)]);
      // console.log(resortList);

      // let suitableForList = [];
      // userProfile.suitableFors.forEach(level => {
      //   suitableForList.push(level.suitable_for);
      // });
      // console.log(suitableForList);

      // let abilityLevelList = [];
      setSkiLevel(userProfile.user.skiing_level);
      setSnowboardLevel(userProfile.user.snowboarding_level);
    
      setBio(userProfile.providerBio);
      setNationality(userProfile.user.country);
      setCountryResorts(userProfile.resorts);
      // setResorts(resortList);
      setSuitableFor(userProfile.suitableFors);
      setLanguages(userProfile.user.languages);
      // setAbilityLevel(abilityLevelList);
      setProfileImage(userProfile.profileImage);
      setProvidedServices(userProfile.providerServices);
      setGallery(userProfile.gallery);
    }
  }, [userProfile]);

  // upload image to S3 bucket
  const uploadProfileImage = async () => {
    const profileImageData = new FormData();
    profileImageData.append("profile_picture", profileImage);
    try {
      await agent.Users.uploadProfileImage(197, profileImageData);
      console.log("profile picture successfully uploaded!");
    } catch (error) {
      console.log(error);
    }
  };

  // Once a user changes a profile picture, call the upload image function
  useEffect(() => {
    uploadProfileImage();
  }, [profileImage]); 
  console.log(profileImage);
  console.log(bio); 
  console.log(nationality);

  return (
    <form onSubmit={handleSubmit}>
      <Grid 
        container
        justify="center"
        alignItems="center"
        spacing={0}
        direction="column" 
        style={{ marginTop: "3rem"}}
      >
        <Grid 
          container
          xs={12}
          sm={10}
          md={8}
          lg={6}
          direction="row"
          justifyContent="flex-start"
          alignItems="baseline"
        >
          <div className={classes.myProfileLabel}>My Profile</div>
        </Grid>

        {/* Avatar and Bio section */}
        <Grid 
          container
          xs={12}
          sm={10}
          md={8}
          lg={6}
          spacing={2}
          direction="row"
          justifyContent="space-evenly"
          alignItems="flex-start"
        >
          {/* Avatar section */}
          <ProfileAvatar 
            setProfileImage={setProfileImage} 
            profileImage={profileImage}
            isEditable={true}
          />
          {/* BIO section */}
          <Bio setBio={setBio} bio={bio} isDisabled={false}/>
        </Grid>

        {/* Nationality and Language */}
        <Grid
          item
          container
          xs={12}
          sm={10}
          md={8}
          lg={6}
          justify="center"
          alignItems="flex-start"
          direction="row"
          // style={{ width: "100%", margin: "2rem 0"}}
          className={classes.sectionMargin}
        >
          <Grid item xs={12} sm={6}>
            <Nationality 
              setNationality={setNationality}
              nationality={nationality}
              isDisabled={false}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Languages
              setLanguages={setLanguages}
              languages={languages}
              isDisabled={false}
            />
          </Grid>
        </Grid>

        {/* Ability level and Suitable for selections */}
        <Grid
          item
          container
          xs={12}
          sm={10}
          md={8}
          lg={6}
          justify="center"
          alignItems="flex-start"
          direction="row"
          className={classes.sectionMargin}
        >
          <Grid item xs={12} sm={6}>
            <AbilityLevel
              skiLevel = {skiLevel}
              snowboardLevel={snowboardLevel}
              setSkiLevel={setSkiLevel}
              setSnowboardLevel={setSnowboardLevel}
              isDisabled={false}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SuitableFor 
              setSuitableFor={setSuitableFor}
              selectedLevels={suitableFor}
              isDisabled={false}
              isEditable={true}
            />
          </Grid>
        </Grid>

        {/* Countries and  Resorts selections */}
        <Grid
          item
          container
          xs={12}
          sm={10}
          md={8}
          lg={6}
          justify="center"
          alignItems="flex-start"
          direction="row"
          className={classes.sectionMargin}
        >
          <Grid item xs={12} sm={12}>
            <Countries 
              setCountryResorts={setCountryResorts}
              countryResorts={countryResorts}
              isDisabled={false}
            />
          </Grid>
          
        </Grid>
        
        {/* Offered Services and Pricing section */}
        <Grid 
          container 
          item
          xs={12}
          sm={10}
          md={8}
          lg={6}
          justify="center"
          alignItems="flex-start"
          direction="column"
        >
          <div className={classes.sectionMargin} >
            <hr />
          </div>
          <div className={classes.sectionMargin} >
            <Services 
              fromHostIntroSection={true}
              providedServices={providedServices}
              setProvidedServices={setProvidedServices}
            />
          </div> 
        </Grid>

        {/* Images Gallery*/}
        <Grid 
          container 
          xs={12}
          sm={10}
          md={8}
          lg={6}
          justify="center"
          alignItems="center"
          direction="row"
        >
          <div className={classes.sectionMargin}>
            <ImageGallery setGallery={setGallery} gallery={gallery} isEditable={true}/>
          </div>
          <div className={classes.sectionMargin}>
            <hr />
          </div>
          
        </Grid>

        {/* Cancel and Save Button */}
        <Grid
         container
         item
         xs={12}
         sm={10}
         md={8}
         lg={6}
         direction="row"
         justifyContent="flex-end"
         alignItems="stretch"
         className={classes.sectionMargin}
        >

          <Grid item >
            <Button style={{color: "rgb(255,97,97)"}}>
              Cancel
            </Button>  
          </Grid>

          <Grid item>
            <Button
              type="submit"
              className={classes.saveButton}
              classes={{
                label: classes.buttonLabel,
              }}
              variant="contained"
            >
              Save
            </Button>
          </Grid>
        </Grid>

      </Grid>
    </form>
  );
}

// get current user data from store
function mapStateToProps(state) {
  return {
  userData: state.loginReducer.userData,
  currentUser: state.auth.currentUser,
  userProfile: state.stepperReducer.userProfile,
  };
}

// event or function are used to update existing values in Store
const mapDispatchToProps = dispatch => ({
  getProviderProfile: userId => dispatch(getProviderProfile(userId)),
  // getProviderServices: provider_id => dispatch(getProviderServices(provider_id))
});
  
const handleSubmit = async event => {
  event.preventDefault();
  // data for updating provider info
  const newProfile = {
    user: {

    }
  };
};

MyAccountEdit = reduxForm({
    form: "editProProfile",
    destroyOnUnmount: false
})(MyAccountEdit);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyAccountEdit);