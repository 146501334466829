import React from "react";
import { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  IconButton,
  Popover,
  Card,
  Badge
} from "@material-ui/core";
import blankImage from "./blankImage.png";
import EditIcon from "@material-ui/icons/Edit";

//---Components
import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
  oldContainer: {
    backgroundColor: "#fafbdf"
  },
  contentContainer: {
    paddingTop: "1em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    marginTop: "1rem"
  },
  main: {
    paddingBottom: "5em"
  },
  titleStyle: {
    marginTop: 190,
    paddingLeft: "5em",
    paddingRight: "5em",
    fontFamily: "PT Sans Narrow",
    color: "#000000",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "140.62%",
    paddingTop: "1em",
    paddingBottom: "1em",
    fontSize: "26px",
    [theme.breakpoints.up("xs")]: {
      marginTop: 140,
      paddingLeft: "0em",
      paddingRight: "0em"
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "190px"
    }
  },
  typograhyFormat: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontSize: 16,
    lineHeight: "22.50px",
    textAlign: "left",
    color: "#1277B8",
    marginTop: "2rem",
    marginBottom: "2rem",
    marginLeft: 50,
    fontWeight: "bolder",
    width: "100%"
  },
  arrowIcon: {
    transform: "rotate(180deg)"
  },
  backButton: {
    margin: "3em",
    borderColor: "#FF6161",
    color: "#FF6161"
  },
  headerLabel: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "26px",
    color: "#000000"
  },
  imageSize: {
    width: "110px",
    height: "110px",
    [theme.breakpoints.down("sm")]: {
      width: "10rem",
      height: "10rem"
    },
    [theme.breakpoints.down("xs")]: {
      width: "6rem",
      height: "6rem"
    },
    fit: "crop",
    auto: "format",
    dpr: "2 2x"
  },
  iconbutton: {
    color: "white",
    backgroundColor: "#BDBDBD",
    "&:hover": {
      backgroundColor: "#BDBDBD",
      cursor: "default"
    },
    "& .MuiIconButton-sizeSmall": {
      padding: "1px"
    }
  },
  iconbuttonEditable: {
    color: "white",
    backgroundColor: "#1277B8",
    "&:hover": {
      backgroundColor: "#1277B8"
    },
    "& .MuiIconButton-sizeSmall": {
      padding: "1px"
    }
  },
  popover: {
    "& .MuiPaper-rounded": { borderRadius: "0" }
  },
  btnPopup: {
    fontFamily: "PT Sans",
    fontWeight: "bold",
    backgroundColor: "#ffffff",
    width: "100%",
    borderRadius: "0",
    "&:hover": {
      backgroundColor: "#1277B8",
      color: "#ffffff",
      boxShadow: "none"
    }
  }
}));

const ImageGalleryForm = props => {
  const classes = useStyles();

  let [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { item_id, gallery, setGallery, isDisabled } = props;
  let [image, setImage] = useState("");
  let [previewImage, setPreviewImage] = useState("");

  // Popover: Remove or Replace Image
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = event => {
    event.preventDefault();

    setAnchorEl(null);
  };

  // Remove Image
  const handleRemoveImage = event => {
    event.preventDefault();

    const newArr = gallery.map(obj => {
      if (obj.id === item_id) {
        return { ...obj, image_link: "", image_file: "" };
      }

      return obj;
    });
    setGallery(newArr);
    setPreviewImage("");

    setAnchorEl(null);
  };

  // Replace Image
  const handleReplaceImage = event => {
    event.preventDefault();

    let uploaded = URL.createObjectURL(event.target.files[0]);
    setPreviewImage(uploaded);
    const newArr = gallery.map(obj => {
      if (obj.id === item_id) {
        return {
          ...obj,
          image_link: uploaded,
          image_file: event.target.files[0]
        };
      }

      return obj;
    });
    setGallery(newArr);

    setAnchorEl(null);
  };

  useEffect(() => {
    if (gallery.length > 0) {
      setImage(gallery[item_id].image_link);
      setPreviewImage(gallery[item_id].image_link);
    }
  }, [isDisabled]);
  useEffect(() => {
    setAnchorEl(null);

    if (gallery.length > 0) {
      setImage(gallery[item_id].image_link);
    }
  }, [gallery]);

  return (
    <Grid
      item
      container
      xs={4}
      sm={4}
      md={2}
      justify="center"
      alignItems="center"
      direction="row"
      justifyContent="center"
    >
      {image ? (
        isDisabled ? (
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            badgeContent={
              <IconButton size="small" className={classes.iconbutton}>
                <EditIcon />
              </IconButton>
            }
          >
            <img
              src={image || blankImage}
              className={classes.imageSize}
              loading="lazy"
            />
          </Badge>
        ) : (
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            badgeContent={
              <div>
              <IconButton
                size="small"
                className={classes.iconbuttonEditable}
                onClick={handleClick}
              >
                <EditIcon />
              </IconButton>

              <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                  className={classes.popover}
                >
                  <Card className={classes.uploadCard}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      
                    >
                      <Grid item style={{ width: "100%" }}>
                        <Button
                          component="label"
                          className={classes.btnPopup}
                          onClick={handleRemoveImage}
                          
                        >
                          Remove
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button component="label" className={classes.btnPopup}>
                          Replace
                          <input
                            accept="image/*"
                            id="contained-button-file"
                            multiple
                            hidden
                            type="file"
                            onChange={handleReplaceImage}
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  </Card>
              </Popover>
              </div>
              
            }
          >
            <img
              src={previewImage || image || blankImage}
              className={classes.imageSize}
              loading="lazy"
            />
          </Badge>
        )
      ) : (
        <img src={blankImage} className={classes.imageSize} loading="lazy" />
      )}
    </Grid>
  );
};

// Reducer part
function mapStateToProps(state) {
  return {};
}

// Adding Dispatch to update the existing values in Redux
const mapDispatchToProps = dispatch => {
  return {};
};

// Connect part
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageGalleryForm);
