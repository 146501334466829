import React, { useState, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import Store from "../../../redux/store";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import {
  incrementActiveStep,
  decrementActiveStep,
  incrementActiveStepHost,
  decrementActiveStepHost,
  getActivatedUserStatus
} from "../../../redux/actions/stepperActions.js";
import SignupStepper from "./SignupStepper";
import agent from "../../../agent";

const useStyles = makeStyles(theme => ({
  confirmationPromptDiv: {
    textAlign: "center" + "!important"
  },

  confirmationPrompt: {
    fontFamily: "PT Sans Narrow",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "23px",
    letterSpacing: "0em",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "18px"
    }
  },

  headPromptDiv: {
    textAlign: "center" + "!important",
    borderBottom: "1px solid #BFBFBF",
    marginBlockEnd: "1rem"
    // [theme.breakpoints.down("sm")]: {
    //   marginBlockEnd: "1rem"
    // }
  },

  headPrompt: {
    fontFamily: "PT Sans Narrow",
    fontSize: "26px",
    fontStyle: "normal",
    fontWeight: 400,
    // lineHeight: "37px",
    letterSpacing: "0em",
    textAlign: "left"
    // [theme.breakpoints.down("sm")]: {
    //   fontSize: "18px"
    // }
  },

  container: {
    margin: "100px auto 200px auto",
    width: "40%",
    [theme.breakpoints.down("md")]: {
      width: "50%"
    },
    [theme.breakpoints.down("sm")]: {
      margin: "100px auto 100px auto",
      width: "80%"
    }
  },
  buttonContainer: {
    // height: 200
  },
  backToHomeButton: {
    backgroundColor: "#FF6161",
    borderRadius: 100,
    textAlign: "center",
    color: "#FFFFFF",
    width: "12em",
    height: "3em",
    marginBottom: "18em",
    marginTop: "1em"
  },

  verifyEmialAddressButton: {
    backgroundColor: "#FF6161",
    borderRadius: 100,
    color: "#FFFFFF",
    width: "12em",
    height: "3em"
  },

  verifyBtnLabel: {
    fontFamily: "PT Sans Narrow",
    textTransform: "none",
    paddingTop: "0.4em",
    paddingBottom: "0.4em"
  },

  tips: {
    fontFamily: "PT Sans Narrow",
    fontSize: 16,
    color: "#1277B8",
    paddingLeft: "5em",
    paddingRight: "5em"
  },
  nextButton: {
    // marginTop: "3em",
    width: "102px",
    height: "2.5em",
    borderRadius: 100,
    textAlign: "center"
    // marginRight: "1em"
  },
  buttonRoot: {
    backgroundColor: "#FF6161"
  },
  buttonLabel: {
    color: "white",
    fontWeight: 700,
    letterSpacing: "1%",
    fontFamily: "PT Sans"
  },
  backButton: {
    width: "60%",
    // marginTop: "3em",
    // marginBottom: "1em",
    // marginLeft: "20%",
    marginRight: "1em",
    fontFamily: "PT Sans",
    border: "white"
  },
  backButtonLabel: {
    color: "#FF6161",
    fontWeight: 700,
    letterSpacing: "1%",
    fontFamily: "PT Sans"
  },
  spaceContainer: {
    height: "15rem",
    [theme.breakpoints.down("md")]: {
      height: "22rem"
    },
    [theme.breakpoints.down("sm")]: {
      height: "15rem"
    },
    [theme.breakpoints.down("xs")]: {
      height: "9rem"
    }
  }
}));

function ActivateUserGoogle(props) {
  const classes = useStyles();
  const { activationStatus, activationError } = props;
  //let { token, id } = useParams();
  let stepNumber = 3;
  const [userMessage, setMessage] = useState("");

//   useEffect(() => {
//     props.getActivatedUserStatus(token, id, stepNumber);
//   }, []);
  useEffect(() => {
    activationStatus && setMessage(activationStatus.data.message);
    activationError && setMessage(activationError.data.message);
  }, [activationStatus, activationError]);
  const store = Store.getState();
  // Below gets the first and last name from redux store
  // const firstName = store.form.signup.values.firstName;
  const originalObject = store.form;
  let newObject = { signup: { values: { userType: 2 }, anyTouched: true } };
  console.log(JSON.stringify(store));
  //   store.form.add;
  //   {"signup":{"values":{"userType":2},"anyTouched":true}}

  const navigateToLoginPage = () => {
    console.log("Navigating to Login page");
  };

  const handleBackButton = event => {
    props.decrementActiveStep(decrementActiveStep());
  };

  return (
    <div style={{ flex: 1, justifyContent: "center" }}>
      <Grid item xs={12} md={12} justify="center">
        <Box className={classes.spaceContainer} />
      </Grid>
      <Grid
        container
        item
        justify="center"
        alignContent="center"
        className={classes.container}
      >
        <Grid item sm={12} xs={8}>
          <div className={classes.headPromptDiv}>
            <Typography className={classes.headPrompt}>
              User activated successfully!
            </Typography>
          </div>
        </Grid>

        <Grid item sm={12} xs={12}>
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            // style={{ height: 200, width: 800 }}
            className={classes.buttonContainer}
          >
            <Grid item>
              <Button
                style={{ textTransform: "none" }}
                href="/login"
                className={classes.nextButton}
                classes={{
                  label: classes.buttonLabel,
                  root: classes.buttonRoot
                }}
                variant="contained"
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

// destroyOnUnmount - saves it in state
ActivateUserGoogle = reduxForm({
  form: "signup",
  destroyOnUnmount: false
})(ActivateUserGoogle);

function mapDispatchToProps(dispatch) {
  return {
    incrementActiveStep: () => dispatch(incrementActiveStep()),
    decrementActiveStep: () => dispatch(decrementActiveStep()),
    incrementActiveStepHost: () => dispatch(incrementActiveStepHost()),
    decrementActiveStepHost: () => dispatch(decrementActiveStepHost()),
    // getActivatedUserStatus: (token, id, signup_step) =>
    //   dispatch(getActivatedUserStatus(token, id, signup_step))
  };
}

function mapStateToProps(state, ownProps) {
  return {
    activeStep: state.stepperReducer.activeStep,
    activeStepHost: state.stepperReducer.activeStepHost,
    activationStatus: state.stepperReducer.activationStatus,
    activationError: state.stepperReducer.activationError
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivateUserGoogle);
