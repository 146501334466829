import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import StarRating from "../../../components/Common/StarRating/StarRating.js";
import "react-calendar-timeline/lib/Timeline.css";
import IconButton from "@material-ui/core/IconButton";
import FavoriteIcon from "@material-ui/icons/Favorite";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  root: {
    margin: "0 auto",
    width: "auto",
    marginTop: "2rem",
    alignItems: "center"
  },
  avatarSize: {
    width: "10rem",
    height: "10rem",
    marginBottom: "15%",
    [theme.breakpoints.down("sm")]: {
      width: "10rem",
      height: "10rem"
    }
  },
  textName: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#1277B8",
    lineHeight: "22.5px",
    marginLeft: "20%",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      fontWeight: "bold",
      marginTop: "2rem",
      marginBottom: "1rem",
      marginLeft: "0"
    }
  },
  pricetextFormat: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#1277B8",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18
    }
  },
  hourTextFormat: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#1277B8"
  },
  messageBtn: {
    background: "#FF6161",
    width: "74%",
    height: "30%",
    fontSize: 14,
    backgroundColor: "#FF6161",
    color: "white",
    fontWeight: "normal",
    letterSpacing: "0.01em",
    borderRadius: 100,
    height: "fit-content",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      marginLeft: "2rem",
      marginTop: "2rem",
      marginBottom: "2rem"
    }
  },
  bioFieldFormat: {
    color: "#000000",
    width: "100%",
    height: "9%",
    fontSize: 14,
    marginLeft: "-6%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "1rem",
      fontSize: 16,
      marginBottom: "2rem",
      color: "#000000"
    }
  },
  divAvatar: {
    textAlign: "center",
    margin: "0 auto"
  },
  ratingFormat: {
    textAlign: "center",
    fontWeight: "bold",
    margin: "0 auto",
    width: "fit-content",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px"
    }
  },
  favouriteIcon: {
    width: "3rem",
    height: "3rem",
    zIndex: "10",
    outline: "none",
    color: "#D75A4A",
    fontSize: "large",
    backgroundColor: "#c6dbf1"
  },
  gridFormat3: {
    marginLeft: "10%"
  },
  commonFormat: {
    fontStyle: "normal",
    fontFamily: "PT Sans Narrow"
  },
  editProfileBtn: {
    borderRadius: 100,
    background: "#FF6161",
    width: "70%",
    fontSize: 14,
    lineHeight: "140.62%",
    backgroundColor: "#FF6161",
    color: "white",
    fontWeight: "normal",
    letterSpacing: "0.01em",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "40%"
    }
  }
}));

function InstructorMyProfile(props) {
  const classes = useStyles();
  const { name, bio, rating, avatar, intialprice, isStudent } = props;

  return (
    <Grid container className={classes.root}>
      <Grid container item spacing={1} md={3}>
        {/* This Grid Item will wrap picture  */}
        <Grid container item xs={12} spacing={2} md={12} lg={12}>
          <div className={classes.divAvatar}>
            <Avatar src={avatar} className={classes.avatarSize} />
          </div>
        </Grid>

        {/* This Grid Item will wrap edit profile  */}
        {isStudent ? null : (
          <Grid item xs={12} md={12} lg={12} spacing={2}>
            <div className={classes.divAvatar}>
              <Button
                className={classNames(
                  classes.editProfileBtn,
                  classes.commonFormat
                )}
                color="primary"
                variant="contained"
              >
                Edit Profile
              </Button>
            </div>
          </Grid>
        )}
      </Grid>

      {/* This Grid outer container of heart icon, message button, rating, price per hour  */}
      <Grid container item spacing={2} md={9}>
        {/* This Grid Container will wrap the right section of the page (heart icon, message button, rating, price per hour) */}
        <Grid container item spacing={1} xs={12}>
          {/* This Grid Item will wrap Name  */}
          <Grid
            item
            container
            xs={12}
            spacing={1}
            md={4}
            lg={2}
            justify="center"
          >
            <Typography
              className={classNames(classes.textName, classes.commonFormat)}
            >
              {name.firstName + " " + name.lastName}
            </Typography>
          </Grid>

          {isStudent ? (
            <Grid
              item
              container
              xs={12}
              spacing={2}
              md={4}
              lg={1}
              justify="center"
            >
              <IconButton
                onClick={props.onClick}
                color="secondary"
                aria-label="unfavourite"
                className={classes.favouriteIcon}
              >
                <FavoriteIcon fontSize="large" />
              </IconButton>
            </Grid>
          ) : null}

          <Grid
            item
            container
            xs={12}
            spacing={2}
            md={4}
            lg={4}
            justify="center"
          >
            <Button
              className={classNames(classes.messageBtn, classes.commonFormat)}
              color="primary"
              variant="contained"
            >
              {isStudent
                ? `Message ${name.firstName}`
                : "View Upcoming Lessons"}
            </Button>
          </Grid>
          {/* This Grid Item will wrap Rating  */}
          <Grid item xs={12} spacing={2} md={4} lg={3}>
            <div className={classes.ratingFormat}>
              <StarRating currentRating={rating} />
            </div>
          </Grid>

          {isStudent ? (
            <Grid item xs={12} spacing={1} md={4} lg={2}>
              <div className={classes.ratingFormat}>
                <Typography
                  className={classNames(
                    classes.pricetextFormat,
                    classes.commonFormat
                  )}
                >
                  {"Starting" + " " + "Price"}
                </Typography>
                {/* {name.firstName + " " + name.lastName} */}
                <spnan
                  className={classNames(
                    classes.hourTextFormat,
                    classes.commonFormat
                  )}
                >
                  {"$" + intialprice + "/hr"}
                </spnan>
              </div>
            </Grid>
          ) : null}
        </Grid>

        <Grid
          container
          item
          spacing={1}
          xs={12}
          lg={10}
          className={classes.gridFormat3}
        >
          <p
            className={classNames(classes.bioFieldFormat, classes.commonFormat)}
          >
            {bio}
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default InstructorMyProfile;
