import _ from "lodash";
import {
  calculateSession,
  splitDurationDays,
  splitSession
} from "./LessonTimeCalculator";

export const filterAgeGroup = (activity, allLessons, isFirstTimer) => {
  const suitableLessons = isFirstTimer
    ? _.filter(allLessons, { activity: activity, maximum_level: 1 })
    : _.filter(allLessons, { activity: activity });

  let filteredAgeGroups = [];
  _.forEach(suitableLessons, lesson => {
    filteredAgeGroups.push(lesson.age_group);
  });

  return _.uniq(filteredAgeGroups);
};

export const filterNumDays = (activity, allLessons, ageGroup, isFirstTimer) => {
  const suitableLessons = isFirstTimer
    ? _.filter(allLessons, {
        activity: activity,
        age_group: ageGroup,
        maximum_level: 1
      })
    : _.filter(allLessons, {
        activity: activity,
        age_group: ageGroup
      });

  let filteredNumDays = [];
  _.forEach(suitableLessons, lesson => {
    if (lesson.flexible_days) {
      filteredNumDays.push(
        `${lesson.duration_days} out of ${lesson.flexible_days}`
      );
    } else {
      filteredNumDays.push(`${lesson.duration_days}`);
    }
  });
  return _.uniq(filteredNumDays);
};

export const filterSession = (
  activity,
  allLessons,
  ageGroup,
  durationDays,
  isFirstTimer
) => {
  const { flexibleDay, durationDay } = splitDurationDays(durationDays);

  const suitableLessons = isFirstTimer
    ? _.filter(allLessons, {
        activity: activity,
        age_group: ageGroup,
        duration_days: durationDay,
        flexible_days: flexibleDay,
        maximum_level: 1
      })
    : _.filter(allLessons, {
        activity: activity,
        age_group: ageGroup,
        duration_days: durationDay,
        flexible_days: flexibleDay
      });

  let filteredSession = [];
  let sessionMapper = {};
  _.forEach(suitableLessons, lesson => {
    const startTime = lesson.start_time;
    const durationHours = lesson.duration_hours;

    let session = calculateSession(startTime, durationHours);
    filteredSession.push(session);
    sessionMapper[session] = { startTime, durationHours };
  });

  return { filteredSession: _.uniq(filteredSession), mapper: sessionMapper };
};

export const filterDurationHours = (activity, allLessons, isFirstTimer) => {
  const suitableLessons = isFirstTimer
    ? _.filter(allLessons, { activity: activity, maximum_level: 1 })
    : _.filter(allLessons, { activity: activity });

  let filteredDurationHours = [];
  _.forEach(suitableLessons, lesson => {
    filteredDurationHours.push(lesson.duration_hours);
  });

  return _.uniq(filteredDurationHours);
};

export const filterStartTime = (
  activity,
  allLessons,
  durationHours,
  isFirstTimer
) => {
  const suitableLessons = isFirstTimer
    ? _.filter(allLessons, {
        activity: activity,
        duration_hours: durationHours,
        maximum_level: 1
      })
    : _.filter(allLessons, {
        activity: activity,
        duration_hours: durationHours
      });

  let filteredStartTime = [];
  _.forEach(suitableLessons, lesson => {
    filteredStartTime.push(lesson.start_time);
  });

  return _.uniq(filteredStartTime);
};

export const filterPrivateParticipants = (
  privateLessons,
  activity,
  ageGroup,
  durationHours,
  startTime,
  isFirstTimer
) => {
  const suitableLessons = isFirstTimer
    ? _.filter(privateLessons, {
        activity: activity,
        duration_hours: durationHours,
        maximum_level: 1,
        start_time: startTime,
        age_group: ageGroup
      })
    : _.filter(privateLessons, {
        activity: activity,
        duration_hours: durationHours,
        start_time: startTime,
        age_group: ageGroup
      });

  // return the first suitable lesson found because currently have duplicates in database
  return suitableLessons[0];
};

export const filterGroupParticipants = (
  groupLessons,
  activity,
  ageGroup,
  durationDays,
  session,
  isFirstTimer
) => {
  const { flexibleDay, durationDay } = splitDurationDays(durationDays);
  const { startTime, durationHours } = splitSession(session);

  const suitableLessons = isFirstTimer
    ? _.filter(groupLessons, {
        activity: activity,
        age_group: ageGroup,
        duration_days: durationDay,
        flexible_days: flexibleDay,
        start_time: startTime,
        duration_hours: parseFloat(durationHours),
        maximum_level: 1
      })
    : _.filter(groupLessons, {
        activity: activity,
        age_group: ageGroup,
        duration_days: durationDay,
        flexible_days: flexibleDay,
        start_time: startTime,
        duration_hours: parseFloat(durationHours)
      });
  // return the first suitable lesson found because currently have duplicates in database
  return suitableLessons[0];
};
