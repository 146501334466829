import {
  REMOVE_TRIP_MEMBER,
  ADD_TRIP_MEMBER,
  CREATE_MEMBER,
  UPDATE_MEMBER,
  DELETE_MEMBER,
  CREATE_MASTER,
  UPDATE_MASTER,
  GROUP_MEMBER_INFO_RETRIEVED
} from "../actions/action-types";
import uuid from "uuid";

const initialState = {
  // all the family members related to master member
  masterUuid: "",

  familyMembers:
    process.env.REACT_APP_NODE_ENV === "production"
      ? {}
      : {
          /** people who are initially added into lessons */
          //TODO: Replace the keys as ids and not string names
          Mike: {
            FirstName: "Mike",
            LastName: "Wong",
            DOB: new Date(new Date().setFullYear(1995)),
            Gender: "male",
            SkiAbility: 2,
            SnowboardAbility: 2,
            TelemarkAbility: 1,
            SnowbikeAbility: 1,
            SnowmobileAbility: 6,
            SnowshoeAbility: 7,
            isMaster: false,
            IsDisabled: false,
            DisabilityDetail: "Extremely Lazy",
            DisabilityMembership: "DWA",
            DisabilityMembershipID: "83641280",
            hasAllergy: false
          },
          James: {
            FirstName: "James",
            LastName: "Bond",
            DOB: new Date(new Date().setFullYear(2016)),
            Gender: "male",
            SkiAbility: 2,
            SnowboardAbility: 2,
            TelemarkAbility: 1,
            SnowbikeAbility: 4,
            SnowmobileAbility: 1,
            SnowshoeAbility: 1,
            isMaster: false,
            IsDisabled: false,
            DisabilityDetail: "Super Lazy",
            DisabilityMembership: "",
            DisabilityMembershipID: "",
            hasAllergy: false
          },
          Janice: {
            FirstName: "Janice",
            LastName: "Last",
            DOB: new Date(new Date().setFullYear(1920)),
            Gender: "female",
            SkiAbility: 2,
            SnowboardAbility: 2,
            TelemarkAbility: 1,
            SnowbikeAbility: 4,
            SnowmobileAbility: 1,
            SnowshoeAbility: 1,
            isMaster: false,
            IsDisabled: true,
            DisabilityDetail: "Very Lazy",
            DisabilityMembership: "",
            DisabilityMembershipID: "",
            hasAllergy: false
          },
          Carrie: {
            FirstName: "Carrie",
            LastName: "Collins",
            DOB: new Date(new Date().setFullYear(1949)),
            Gender: "not specified",
            SkiAbility: 2,
            SnowboardAbility: 3,
            TelemarkAbility: 3,
            SnowbikeAbility: 1,
            SnowmobileAbility: 1,
            SnowshoeAbility: 2,
            isMaster: false,
            IsDisabled: false,
            DisabilityDetail: "",
            DisabilityMembership: "",
            DisabilityMembershipID: "",
            hasAllergy: false
          },
          Alena: {
            FirstName: "Alena",
            LastName: "Zhang",
            DOB: new Date(new Date().setFullYear(2012)),
            Gender: "male",
            SkiAbility: 1,
            SnowboardAbility: 2,
            TelemarkAbility: 1,
            SnowbikeAbility: 4,
            SnowmobileAbility: 1,
            SnowshoeAbility: 1,
            isMaster: false,
            IsDisabled: false,
            DisabilityDetail: "Super Lazy",
            DisabilityMembership: "",
            DisabilityMembershipID: "",
            hasAllergy: false
          },
          Tony: {
            FirstName: "Tony",
            LastName: "Stark",
            DOB: new Date(new Date().setFullYear(2016)),
            Gender: "male",
            SkiAbility: 1,
            SnowboardAbility: 2,
            TelemarkAbility: 1,
            SnowbikeAbility: 4,
            SnowmobileAbility: 1,
            SnowshoeAbility: 1,
            isMaster: false,
            IsDisabled: true,
            DisabilityDetail: "Super Lazy",
            DisabilityMembership: "",
            DisabilityMembershipID: "",
            hasAllergy: false
          },
          Steve: {
            FirstName: "Steve",
            LastName: "Rogers",
            DOB: new Date(new Date().setFullYear(2008)),
            Gender: "male",
            SkiAbility: 2,
            SnowboardAbility: 1,
            TelemarkAbility: 1,
            SnowbikeAbility: 4,
            SnowmobileAbility: 1,
            SnowshoeAbility: 1,
            isMaster: false,
            IsDisabled: false,
            DisabilityDetail: "Super Lazy",
            DisabilityMembership: "",
            DisabilityMembershipID: "",
            hasAllergy: false
          },
          Thor: {
            FirstName: "Thor",
            LastName: "ThunderGod",
            DOB: new Date(new Date().setFullYear(2004)),
            Gender: "male",
            SkiAbility: 3,
            SnowboardAbility: 3,
            TelemarkAbility: 1,
            SnowbikeAbility: 4,
            SnowmobileAbility: 1,
            SnowshoeAbility: 1,
            isMaster: false,
            IsDisabled: false,
            DisabilityDetail: "Super Lazy",
            DisabilityMembership: "",
            DisabilityMembershipID: "",
            hasAllergy: false
          },
          Bucky: {
            FirstName: "Bucky",
            LastName: "Barnes",
            DOB: new Date(new Date().setFullYear(1991)),
            Gender: "male",
            SkiAbility: 1,
            SnowboardAbility: 1,
            TelemarkAbility: 1,
            SnowbikeAbility: 4,
            SnowmobileAbility: 1,
            SnowshoeAbility: 1,
            isMaster: false,
            IsDisabled: false,
            DisabilityDetail: "Super Lazy",
            DisabilityMembership: "",
            DisabilityMembershipID: "",
            hasAllergy: false
          },

          /** people who are not in lessons initially */
          Peter: {
            FirstName: "Peter",
            LastName: "Parker",
            DOB: new Date(new Date().setFullYear(2010)),
            Gender: "male",
            SkiAbility: 1,
            SnowboardAbility: 3,
            TelemarkAbility: 1,
            SnowbikeAbility: 4,
            SnowmobileAbility: 1,
            SnowshoeAbility: 1,
            isMaster: false,
            IsDisabled: true,
            DisabilityDetail: "Super Lazy",
            DisabilityMembership: "",
            DisabilityMembershipID: "",
            hasAllergy: false
          },
          Bruce: {
            FirstName: "Bruce",
            LastName: "Banner",
            DOB: new Date(new Date().setFullYear(1920)),
            Gender: "male",
            SkiAbility: 2,
            SnowboardAbility: 3,
            TelemarkAbility: 1,
            SnowbikeAbility: 4,
            SnowmobileAbility: 1,
            SnowshoeAbility: 1,
            isMaster: false,
            IsDisabled: true,
            DisabilityDetail: "Super Lazy",
            DisabilityMembership: "",
            DisabilityMembershipID: "",
            hasAllergy: false
          },
          Natasha: {
            FirstName: "Natasha",
            LastName: "Romanoff",
            DOB: new Date(new Date().setFullYear(2012)),
            Gender: "female",
            SkiAbility: 1,
            SnowboardAbility: 1,
            TelemarkAbility: 1,
            SnowbikeAbility: 4,
            SnowmobileAbility: 1,
            SnowshoeAbility: 1,
            isMaster: false,
            IsDisabled: false,
            DisabilityDetail: "Super Lazy",
            DisabilityMembership: "",
            DisabilityMembershipID: "",
            hasAllergy: false
          },
          Sam: {
            FirstName: "Sam",
            LastName: "Wilson",
            DOB: new Date(new Date().setFullYear(2004)),
            Gender: "male",
            SkiAbility: 1,
            SnowboardAbility: 1,
            TelemarkAbility: 1,
            SnowbikeAbility: 4,
            SnowmobileAbility: 1,
            SnowshoeAbility: 1,
            isMaster: false,
            IsDisabled: false,
            DisabilityDetail: "Super Lazy",
            DisabilityMembership: "",
            DisabilityMembershipID: "",
            hasAllergy: false
          },
          Clint: {
            FirstName: "Clint",
            LastName: "Barton",
            DOB: new Date(new Date().setFullYear(1920)),
            Gender: "male",
            SkiAbility: 2,
            SnowboardAbility: 2,
            TelemarkAbility: 1,
            SnowbikeAbility: 4,
            SnowmobileAbility: 1,
            SnowshoeAbility: 1,
            isMaster: false,
            IsDisabled: false,
            DisabilityDetail: "Super Lazy",
            DisabilityMembership: "",
            DisabilityMembershipID: "",
            hasAllergy: false
          },
          Wanda: {
            FirstName: "Wanda",
            LastName: "Maximoff",
            DOB: new Date(new Date().setFullYear(1990)),
            Gender: "female",
            SkiAbility: 2,
            SnowboardAbility: 2,
            TelemarkAbility: 1,
            SnowbikeAbility: 4,
            SnowmobileAbility: 1,
            SnowshoeAbility: 1,
            isMaster: false,
            IsDisabled: false,
            DisabilityDetail: "Super Lazy",
            DisabilityMembership: "",
            DisabilityMembershipID: "",
            hasAllergy: false
          },
          Scott: {
            FirstName: "Scott",
            LastName: "Lang",
            DOB: new Date(new Date().setFullYear(2004)),
            Gender: "male",
            SkiAbility: 2,
            SnowboardAbility: 2,
            TelemarkAbility: 1,
            SnowbikeAbility: 4,
            SnowmobileAbility: 1,
            SnowshoeAbility: 1,
            isMaster: false,
            IsDisabled: false,
            DisabilityDetail: "Super Lazy",
            DisabilityMembership: "",
            DisabilityMembershipID: "",
            hasAllergy: false
          }
        },
  // uuids of members who will go in this trip (include master member)
  tripMembers:
    process.env.REACT_APP_NODE_ENV === "production"
      ? []
      : [
          "James",
          "Mike",
          "Janice",
          "Carrie",
          "Tony",
          "Steve",
          "Alena",
          "Thor",
          "Bucky",
          "Peter",
          "Bruce",
          "Natasha",
          "Sam",
          "Clint",
          "Wanda",
          "Scott"
        ]
};

function tripWhoReducer(state = initialState, action) {
  switch (action.type) {
    case GROUP_MEMBER_INFO_RETRIEVED:
      return {
        ...state,
        masterUuid: action.payload.memberInfos.masterMemberId,
        familyMembers: {
          ...action.payload.memberInfos.formattedFamilyMembers
        },
        tripMembers: []
      };
    case ADD_TRIP_MEMBER:
      return {
        ...state,
        tripMembers: state.tripMembers.concat(action.payload.memberID)
      };
    case REMOVE_TRIP_MEMBER:
      const memberID = action.payload.memberID;
      const memberIndex = state.tripMembers.indexOf(memberID);
      return {
        ...state,
        tripMembers: [
          ...state.tripMembers.slice(0, memberIndex),
          ...state.tripMembers.slice(memberIndex + 1)
        ]
      };
    case CREATE_MEMBER:
      const new_uuid = action.payload.memberInfo.uuid;
      const new_info = action.payload.memberInfo.info;

      return {
        ...state,
        familyMembers: { ...state.familyMembers, [new_uuid]: new_info },
        tripMembers: state.tripMembers.concat(new_uuid)
      };
    case UPDATE_MEMBER:
      const update_uuid = action.payload.memberInfo.uuid;
      const update_info = action.payload.memberInfo.info;

      return {
        ...state,
        familyMembers: {
          ...state.familyMembers,
          [update_uuid]: { ...state.familyMembers[update_uuid], ...update_info }
        }
      };
    case DELETE_MEMBER:
      const remove_uuid = action.payload.memberID;
      const {
        [remove_uuid]: value,
        ...remaining_members
      } = state.familyMembers;

      const deleteTripMemberIndex = state.tripMembers.indexOf(remove_uuid);

      if (deleteTripMemberIndex < 0) {
        return {
          ...state,
          familyMembers: remaining_members
        };
      } else {
        return {
          ...state,
          familyMembers: remaining_members,
          tripMembers: [
            ...state.tripMembers.slice(0, deleteTripMemberIndex),
            ...state.tripMembers.slice(deleteTripMemberIndex + 1)
          ]
        };
      }
    case CREATE_MASTER:
      let newMasterState = { ...state };
      if (state.masterUuid === "") {
        const masterUuid = uuid.v4();
        let newMasterTrip = state.tripMembers.slice();
        newMasterTrip.splice(0, 0, masterUuid);
        newMasterState = {
          ...state,
          masterUuid: masterUuid,
          familyMembers: {
            ...state.familyMembers,
            [masterUuid]: {
              ...action.payload.memberInfo,
              isMaster: true
            }
          },
          tripMembers: newMasterTrip
        };
      } else {
        newMasterState = {
          ...state,
          familyMembers: {
            ...state.familyMembers,
            [state.masterUuid]: {
              ...action.payload.memberInfo,
              isMaster: true
            }
          }
        };
      }

      return newMasterState;

    case UPDATE_MASTER:
      return {
        ...state,
        familyMembers: {
          ...state.familyMembers,
          [state.masterUuid]: {
            ...state.familyMembers[state.masterUuid],
            ...action.payload.memberInfo
          }
        }
      };
    default:
      return state;
  }
}

export default tripWhoReducer;
