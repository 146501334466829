import { CONTACT_US_EMAIL_SENT } from "../actions/action-types";

const initialState = {
  contactUsEmailSent: false
};

function contactUsReducer(state = initialState, action) {
  if (action.type === CONTACT_US_EMAIL_SENT) {
    return { ...state, contactUsEmailSent: action.payload };
  } else {
    return state;
  }
}

export default contactUsReducer;
