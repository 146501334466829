import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: 20
  },
  roomCard: {
    width: "100%"
  },
  avatarGrid: {
    margin: "auto"
  },
  avatar: {
    margin: "auto"
  },
  confirmedName: {
    textAlign: "left",
    verticalAlign: "middle",
    color: "#4caf50"
  },
  canceledName: {
    textAlign: "left",
    justifyContent: "center"
  },
  message: {
    textAlign: "left"
  },
  dataGrid: {
    verticalAlign: "middle"
  },
  messageDate: {
    textAlign: "right",
    margin: "auto"
  }
}));

const MessageRoomCard = props => {
  const classes = useStyles();

  const roomID = props.roomID;
  const name = props.name;
  const latestMessage = props.latestMessage;
  let messageDate = props.messageDate;
  const status = props.status;
  const avatarLink = props.avatarLink;
  const messageLink = "/chatpage/" + roomID;
  if (messageDate !== "None") {
    messageDate = new Date(messageDate);
    let messageMonth = messageDate.toLocaleString("default", {
      month: "short"
    });
    let messageDay = messageDate.getDate();
    messageDate = messageDay + "/" + messageMonth;
  } else {
    console.log(messageDate);
  }

  // if the status is confirm: make it green
  var statusName;
  if (status == "Confirmed") {
    statusName = (
      <Typography className={classes.confirmedName}>
        {status} - {name}
      </Typography>
    );
  } else {
    statusName = (
      <Typography className={classes.canceledName} align={"center"}>
        {status} - {name}
      </Typography>
    );
  }

  return (
    <div className={classes.root}>
      <Link to={messageLink}>
        <Paper>
          <Grid container className={classes.roomCard} spacing={2}>
            <Grid item xs={3} sm={2} className={classes.avatarGrid}>
              <Avatar alt={name} src={avatarLink} className={classes.avatar} />
            </Grid>

            <Grid item xs={9} sm={10}>
              <Grid container className={classes.info} spacing={2}>
                <Grid item xs={8} sm={3}>
                  {statusName}
                </Grid>
                <Grid item xs={4} sm={2} className={classes.dataGrid}>
                  <Typography className={classes.messageDate} noWrap>
                    {messageDate}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Typography className={classes.message} noWrap>
                    {latestMessage}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Link>
    </div>
  );
};

export default MessageRoomCard;
