import styled from "styled-components";

export const TextCenterDiv = styled.div`
  text-align: center;
`;

export const SuccessText = styled.span`
  font-size: 1.6rem;
`;

export const ResortText = styled.span`
  font-weight: 700;
  font-size: 1.2rem;
`;
