import {
  SELECT_COUNTRY,
  SELECT_RESORT,
  SELECT_RESORT_ID,
  SHOW_RESORT_ERRORS,
  UPDATE_RESORT_ERRORS,
  SET_COUNTRIES_AND_RESORTS
} from "./action-types";

export const selectResort = resortName => {
  return {
    type: SELECT_RESORT,
    payload: { resortName }
  };
};

export const selectResortId = resortId => {
  return {
    type: SELECT_RESORT_ID,
    payload: { resortId }
  };
};

export const selectCountry = country => {
  return {
    type: SELECT_COUNTRY,
    payload: { country }
  };
};

export const showResortErrors = show => {
  return {
    type: SHOW_RESORT_ERRORS,
    payload: { show }
  };
};

export const setCountriesAndResorts = val => {
  return {
    type: SET_COUNTRIES_AND_RESORTS,
    payload: val
  };
};

export const updateResortErrors = errors => {
  return {
    type: UPDATE_RESORT_ERRORS,
    payload: { errors }
  };
};
