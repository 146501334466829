import styled from "styled-components";
import {
  OrangeColor1,
  DangerColor,
  GrayColor1,
  GrayColor2,
  GrayColor3,
  GrayColor4,
  BlueColor2,
  BlueColor3,
  BlueColor4
} from "./color-themes_old";

import {
  media_xs_max,
  media_sm_max,
  //media_md_max,
  media_lg_max
} from "./dimension-themes";

/*
 * Header
 */
export const SectionTitle = styled.span`
  color: ${BlueColor4};
  font-size: 1.45rem;
  font-family: "Open Sans Condensed", sans-serif;
  font-weight: 700;
`;

export const SectionTitleSmaller = styled.span`
  color: ${BlueColor3};
  font-size: 1rem;
  font-family: "Open Sans Condensed", sans-serif;
  font-weight: 700;
`;

/*
 * Buttons
 */
export const OrangeButton = styled.button`
  background-color: ${OrangeColor1};
  color: white;
  padding: 4px 20px;
  border: 0 solid ${OrangeColor1};
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.4s ease-in;
  &:hover {
    background-color: black;
  }

  @media (max-width: ${media_sm_max}) {
    transform: scale(0.84);
  }
`;

export const NextButton = styled(OrangeButton)`
  margin: 10px;
  margin-top: 20px;
`;

export const ToggleButton = styled.button`
  background-color: ${props => (props.on ? GrayColor3 : "white")};
  color: ${props =>
    props.disabled ? GrayColor2 : props.on ? "white" : GrayColor3};
  border: 1px solid ${props => (props.disabled ? GrayColor2 : GrayColor3)};
  border-radius: 30px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.4s ease-in;
  &:focus {
    outline: none;
  }
`;

export const GrayButton = styled(OrangeButton)`
  background-color: ${GrayColor3};
`;

export const DangerButton = styled(OrangeButton)`
  background-color: ${DangerColor};
`;

export const SkipButton = styled.button`
  width: 160px;
  background-color: ${GrayColor1};
  padding: 4px 0;
  border: 2px solid ${GrayColor2};
  color: ${GrayColor3};
  font-weight: bold;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }
`;

/*
 * Spacing
 */

export const VerticalSpaceSM = styled.div`
  height: 10px;
`;

export const VerticalSpaceMD = styled.div`
  height: 20px;
`;

export const VerticalSpaceLG = styled.div`
  height: 50px;
`;

export const VerticalSpaceXL = styled.div`
  height: 100px;
`;

/*
 * Slick Carousel
 */
export const SlickHeaderWrapper = styled.div`
  position: relative;
  width: 95%;
  height: 45px;
  background-color: ${GrayColor4};
  border: 2px solid ${GrayColor2};
  border-radius: 8px;
  margin: auto;
  overflow: hidden;

  @media (max-width: ${media_sm_max}) {
    width: 98%;
  }
`;

export const SlickButtonWrapper = styled.div`
  height: inherit;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  display: inline-block;
  width: 18%;
  border-radius: 8px;
  background-color: ${GrayColor3};

  @media (max-width: ${media_lg_max}) {
    width: 25%;
  }

  @media (max-width: ${media_sm_max}) {
    width: 33%;
    font-size: 0.77rem;
  }
`;

export const SlickPrevButtonWrapper = styled(SlickButtonWrapper)`
  left: 0%;
  text-align: left;
`;
export const SlickNextButtonWrapper = styled(SlickButtonWrapper)`
  right: 0%;
  text-align: right;
`;

export const ScrollButton = styled.button`
  position: relative;
  color: white;
  background-color: transparent;
  width: 100%;
  height: inherit;
  border: none;
  font-weight: bold;
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`;

// the left right arrow
export const ScrollSign = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const SlickTitleWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2rem;

  display: inline-block;
  width: 70%;
  text-align: center;

  @media (max-width: ${media_sm_max}) {
    width: 34%;
    font-size: 0.87rem;
  }
`;

export const SlickTitle = styled.span`
  color: white;
  font-weight: bold;
`;

/*
 * Form Styling
 */

export const FormLabelWrapper = styled.div`
  width: 24%;
  display: inline-block;
  vertical-align: top;

  @media (max-width: ${media_sm_max}) {
    width: 40%;
  }
`;

export const FormLabel = styled.label`
  margin: 0 10px;
  color: ${BlueColor3};
  font-size: 1.1rem;
  font-weight: bold;
`;

export const FormExplanation = styled.span`
  margin: 0 10px;
  color: ${GrayColor3};
  font-size: 0.9rem;
  font-weight: normal;
`;

export const FormLabelLight = styled(FormLabel)`
  font-size: 0.9rem;
  font-weight: normal;
`;

export const FormInputText = styled.input`
  border: 2px solid ${BlueColor2};
  width: 33%;
  height: 25px;
  border-radius: 4px;
  padding: 0 8px;
  color: ${BlueColor4};

  @media (max-width: ${media_sm_max}) {
    width: 40%;
  }
`;

export const FormInputRadioWrapper = styled.div`
  display: inline-block;
  @media (max-width: ${media_xs_max}) {
    display: block;
    height: 20px;
  }
`;

export const RadioLabel = styled.label`
  padding: 0 10px;
`;

export const FormInputRadio = styled.input`
  // outer circle
  &:checked + ${RadioLabel}:before, &:not(:checked) + ${RadioLabel}:before {
    content: "";
    position: absolute;
    left: 2%;
    top: 4px;
    width: 18px;
    height: 18px;
    border: 1px solid rgba(198, 226, 247, 1);
    border-radius: 100%;
    background: #fff;
  }

  // on hover, change background color
  &:not(:checked):hover + ${RadioLabel}:before {
    background: rgba(198, 226, 247, 1);
  }

  // inner circle
  &:checked + ${RadioLabel}:after, &:not(:checked) + ${RadioLabel}:after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background: #00a6ff;
    top: 8px;
    left: 2px;
    border-radius: 100%;
    -webkit-transition: all 0.1s ease-in;
    transition: all 0.1s ease-in;
  }

  // hide inner circle if not checked
  &:not(:checked) + ${RadioLabel}:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  // show inner circle if checked
  &:checked + ${RadioLabel}:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

export const FormInputCheckbox = styled.input`
  & {
    opacity: 0;
  }

  // outer square
  &:checked + label:before,
  &:not(:checked) + label:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
    width: 19px;
    height: 19px;
    border: 1px solid rgba(198, 226, 247, 1);
    border-radius: 20%;
    background: #fff;
  }

  // on hover, change background
  &:not(:checked):hover + label:before {
    background: rgba(198, 226, 247, 1);
  }

  // inner checkmark
  &:checked + label:after,
  &:not(:checked) + label:after {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";

    position: absolute;
    top: 3px;
    left: 7px;

    font-size: 0.9em;
    color: ${BlueColor3};
    font-weight: 600;
    -webkit-transition: all 0.1s ease-in;
    transition: all 0.1s ease-in;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }

  // inner square not shown if unchecked
  &:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  // inner square shown if checked
  &:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

export const FormTextarea = styled.textarea`
  width: 90%;
  height: 100px;
  border: 2px solid ${BlueColor2};
  padding-left: 12px;
  border-radius: 8px;
  color: ${BlueColor4};
  resize: none;
  &::-webkit-input-placeholder {
    color: ${GrayColor4};
    font-size: smaller;
  }
`;

export const FormSelect = styled.select`
  border: 2px solid ${BlueColor2};
  background-color: white;
  color: ${BlueColor4};
`;
