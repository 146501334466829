import {
  UPDATE_ACTIVITY_ERRORS,
  EDIT_MEMBER_IN_ACTIVITY,
  REMOVE_MEMBER_FROM_ACTIVITIES,
  SHOW_ACTIVITY_ERRORS
} from "./action-types";

export const editMemberInActivity = target => {
  return {
    type: EDIT_MEMBER_IN_ACTIVITY,
    payload: target
  };
};

export const removeMemberFromActivities = uuid => {
  return {
    type: REMOVE_MEMBER_FROM_ACTIVITIES,
    payload: uuid
  };
};

export const showActivityErrors = show => {
  return {
    type: SHOW_ACTIVITY_ERRORS,
    payload: { show }
  };
};

export const updateActivityErrors = errors => {
  return {
    type: UPDATE_ACTIVITY_ERRORS,
    payload: { errors }
  };
};
