import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { useHistory } from "react-router-dom";
import { getProviderProfile } from "../../../redux/actions/stepperActions";

import store from '../../../redux/store';
//Database
import agent from "../../../agent";
// import Store from "../../../redux/store";

//Material UI
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

//Components
import Services from "../../../components/Common/SignupForm/ProviderInfo/Services/Services";
import ImageGallery from "./ImageGallery";
import Bio from "../../../components/Common/SignupForm/UserInfo/Bio";
import Nationality from "./NationalityDropdown";
import Languages from "./LanguageDropdown";
import AbilityLevel from "./AbilityLevelDropdown";
import Countries from "./CountryDropdown";
import SuitableFor from "./SuitableForDropdown";
import ProfileAvatar from "./ProfileAvatar";

const useStyles = makeStyles(theme => ({
  myProfileLabel: {
    fontSize: "x-large",
    width: "50%",
    margin: "3rem 0",
    fontFamily: "PT Sans Narrow",
    paddingLeft: "1rem"
  },
  editProfileLabel: {
    width: "50%",
    margin: "3rem 0",
    textAlign: "right",
    paddingRight: "1rem",
    fontFamily: "PT Sans Narrow",
    color: "royalblue"
  },
  sectionMargin: {
    margin: "2rem 0",
    width: "100%"
  },
  saveButton: {
    width: "102px",
    height: "2.5em",
    borderRadius: 100,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      marginRight: "20px"
    }
  },
  buttonLabel: {
    color: "white",
    fontWeight: 700,
    letterSpacing: "1%",
    fontFamily: "PT Sans"
  },
  centerBtns: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-around",
      alignItems: "center"
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
      alignItems: "center"
    }
  }
}));

function MyAccountView(props) {
  const classes = useStyles();
  const { userData, userProfile } = props;
  console.log(userProfile);
  const history = useHistory();
  let [profileImage, setProfileImage] = useState({});
  let [bio, setBio] = useState("");
  let [nationality, setNationality] = useState({});
  let [languages, setLanguages] = useState([{}]);
  let [skiLevel, setSkiLevel] = useState(0);
  let [snowboardLevel, setSnowboardLevel] = useState(0);
  let [countryResorts, setCountryResorts] = useState([]);
  let [suitableFor, setSuitableFor] = useState([{}]);
  let [providedServices, setProvidedServices] = useState([{}]);
  let [gallery, setGallery] = useState([]);


  console.log(store.getState())
  // update userProfile for testing purpose - user_id 197 or 250
  useEffect(() => {
    props.getProviderProfile(252);
  }, []);

  // Update UI
  useEffect(() => {
    if (userProfile && userProfile !== undefined) {
      //setSkiLevel(userProfile.user.skiing_level);
      //setSnowboardLevel(userProfile.user.snowboarding_level);
      setBio(userProfile.providerBio);
      //setNationality(userProfile.user.country);
      //setCountryResorts(userProfile.resorts);
      setSuitableFor(userProfile.suitableFors);
      //setLanguages(userProfile.user.languages);
      setProfileImage(userProfile.profileImage);
      setProvidedServices(userProfile.providerServices);
      setGallery(userProfile.gallery);
    }
  }, [userProfile]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={0}
        direction="column"
        style={{ marginTop: "3rem" }}
      >
        <Grid
          container
          xs={12}
          sm={10}
          md={8}
          lg={6}
          direction="row"
          justifyContent="flex-start"
          alignItems="baseline"
        >
          <div className={classes.myProfileLabel}>My Profile</div>
          <div className={classes.editProfileLabel}>
            <Button
              color="primary"
              onClick={() => history.push("/pro/edit-my-profile")}
            >
              <span>edit profile</span>
            </Button>
          </div>
          {/* <div className={classes.editProfileLabel} onClick={() => history.push("/pro/edit-my-profile")}><span className={classes.highLightText}>edit profile</span></div> */}
        </Grid>

        {/* Avatar and Bio section */}
        <Grid
          container
          xs={12}
          sm={10}
          md={8}
          lg={6}
          spacing={2}
          direction="row"
          justifyContent="space-evenly"
          alignItems="flex-start"
        >
          {/* Avatar section */}
          <ProfileAvatar
            setProfileImage={setProfileImage}
            profileImage={profileImage}
            isEditable={false}
          />
          {/* BIO section */}
          <Bio setBio={setBio} bio={bio} isDisabled={true} />
        </Grid>

        {/* Nationality and Language */}
        <Grid
          item
          container
          xs={12}
          sm={10}
          md={8}
          lg={6}
          spacing={2}
          justify="center"
          alignItems="flex-start"
          direction="row"
          className={classes.sectionMargin}
        >
          <Grid item xs={12} sm={6}>
            <Nationality
              setNationality={setNationality}
              nationality={nationality}
              isDisabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Languages
              setLanguages={setLanguages}
              languages={languages}
              isDisabled={true}
            />
          </Grid>
        </Grid>

        {/* Ability level and Suitable for selections */}
        <Grid
          item
          container
          xs={12}
          sm={10}
          md={8}
          lg={6}
          spacing={2}
          justify="center"
          alignItems="flex-start"
          direction="row"
          className={classes.sectionMargin}
        >
          <Grid item xs={12} sm={6}>
            <AbilityLevel
              skiLevel={skiLevel}
              snowboardLevel={snowboardLevel}
              setSkiLevel={setSkiLevel}
              setSnowboardLevel={setSnowboardLevel}
              isDisabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SuitableFor
              setSuitableFor={setSuitableFor}
              selectedLevels={suitableFor}
              isDisabled={true}
              isEditable={true}
            />
          </Grid>
        </Grid>

        {/* Countries and  Resorts selections */}
        <Grid
          item
          container
          xs={12}
          sm={10}
          md={8}
          lg={6}
          spacing={2}
          justify="center"
          alignItems="flex-start"
          direction="row"
          className={classes.sectionMargin}
        >
          <Grid item xs={12} sm={12}>
            <Countries
              setCountryResorts={setCountryResorts}
              countryResorts={countryResorts}
              isDisabled={true}
            />
          </Grid>
        </Grid>

        {/* Offered Services and Pricing section */}
        <Grid
          container
          item
          xs={12}
          sm={10}
          md={8}
          lg={6}
          justify="center"
          alignItems="flex-start"
          direction="column"
        >
          <div className={classes.sectionMargin}>
            <hr />
          </div>
          <div className={classes.sectionMargin}>
            <Services
              fromHostIntroSection={true}
              providedServices={providedServices}
              setProvidedServices={setProvidedServices}
              isDisabled={true}
            />
          </div>
        </Grid>

        {/* Images Gallery*/}
        <Grid
          container
          xs={12}
          sm={10}
          md={8}
          lg={6}
          justify="center"
          alignItems="center"
          direction="row"
        >
          <div className={classes.sectionMargin}>
            <ImageGallery
              setGallery={setGallery}
              gallery={gallery}
              isEditable={false}
            />
          </div>
          <div className={classes.sectionMargin}>
            <hr />
          </div>
        </Grid>

        {/* Cancel and Save Button */}
        <Grid
          container
          item
          xs={12}
          sm={10}
          md={8}
          lg={6}
          direction="row"
          style={{ margin: "2rem 0", width: "100%" }}
          className={classes.centerBtns}
        >
          <Grid item>
            <Button style={{ marginRight: "20px" }} disabled>
              Cancel
            </Button>
          </Grid>

          <Grid item>
            <Button
              disabled
              type="submit"
              className={classes.saveButton}
              classes={{
                label: classes.buttonLabel
              }}
              variant="contained"
              // style={{ background: "dimgrey"}}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

// get current user data from store
//specific which props we want to sync on this page
const mapStateToProps = state => {
  return {
    userData: state.loginReducer.userData,
    currentUser: state.auth.currentUser,
    userProfile: state.stepperReducer.userProfile
  };
};

// event or function are used to update existing values in Store
const mapDispatchToProps = dispatch => ({
  getProviderProfile: userId => dispatch(getProviderProfile(userId))
  // getProviderServices: provider_id => dispatch(getProviderServices(provider_id))
});

const handleSubmit = async event => {
  event.preventDefault();
};

MyAccountView = reduxForm({
  form: "profile",
  destroyOnUnmount: false
})(MyAccountView);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyAccountView);
