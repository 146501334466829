import React, { useState, useEffect } from "react";

//Database
import agent from "../../agent";

// redux imports:
import { loadUser } from "../../redux/reducers/auth";
import { userLogin } from "../../redux/actions/loginAction";
import { connect } from "react-redux";
import {
  incrementActiveStep,
  incrementActiveStepManual,
  getClientProfile,
  getProviderProfile
} from "../../redux/actions/stepperActions";
import Store from "../../redux/store";

// style imports:
import { makeStyles } from "@material-ui/core/styles";
import { Grid, FormControl, Typography, Button } from "@material-ui/core";
import GoogleLoginButton from "../../components/Common/Login/GoogleLoginButton";
import { FONTFAMILY } from "../../styling/theme/Theme";
import { Field, reduxForm } from "redux-form";
import { renderFieldForLogin } from "../../components/Common/FormField/FormField";

const useStyles = makeStyles(theme => ({
  title: {
    margin: "2rem 0rem",
    fontSize: 26,
    fontWeight: "400",
    fontFamily: FONTFAMILY.FONT_SANS_NARROW,
    color: "#000000"
  },

  prompts: {
    color: "black",
    marginTop: "2em",
    fontWeight: "700",
    fontSize: "18px",
    fontFamily: FONTFAMILY.FONT_SANS_NARROW,
    color: "#000000"
  },

  textFields: {
    width: "30em",
    height: "4em",
    [theme.breakpoints.down("md")]: {
      width: "25em",
      marginLeft: "3em"
    },
    [theme.breakpoints.down("xs")]: {
      width: "15em",
      marginLeft: "3em",
      marginRight: "3em"
    }
  },

  loginBtn: {
    borderRadius: 100,
    width: "14em",
    height: "3em",
    [theme.breakpoints.down("sm")]: {
      width: "2em",
      height: "3em",
      margin: "1rem 0rem"
    },
    backgroundColor: "rgb(255,97,97)",
    "&:hover": {
      backgroundColor: "rgb(255,97,97)"
    },
    boxShadow:
      "rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px",
    [theme.breakpoints.down("lg")]: {
      width: "14em"
    }
  },

  btnLabel: {
    color: "white",
    fontFamily: "PT Sans Narrow",
    fontWeight: "bold",
    textTransform: "none"
  },

  buttons: {
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    },

    margin: "2em 0em",
    width: "100%"
  },

  errorMsg: {
    textAlign: "center",
    marginBottom: "5em",
    color:"#ff0033",
    background:"#f3c6c6",
    padding:"15px 10px",
    borderRadius:"10px"

  },

  textFieldsRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 1)"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 1)"
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0,0,0)"
    },
    "& .MuiOutlinedInput-input": {
      padding: "0.5rem 0.5rem"
    }
  },
  selectRoot: {
    color: "black",
    width: "100%",
    "& .svg.MuiSvgIcon-root .MuiStepIcon-completed, path": {
      color: "#4A443F"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black  ",
      borderWidth: "1px  "
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black  ",
      borderWidth: "1px  "
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black  ",
      borderWidth: "1px  "
    }
  },
  btnPosition: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  btnGooglePosition: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  inputTextStyle: {
    overflowX: "clip",

    whiteSpace: "nowrap",
    wordBreak: "break-word",
    "& .MuiInputLabel-outlined": {
      transform: "translate(10px, 10px) scale(1)",
      "&.MuiInputLabel-shrink": {
        background: "#ffffff",
        color: "#000"
      }
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px"
    }
  }
}));

function validate(values) {
  let errors = {};

  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid Email";
  }

  
  if (!values.password) {
    errors.password = "Required";
  } else if (
    !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/.test(
      values.password
    )
  ) {
    
    errors.password =
      "Password must be 8 characters long, has at least a number, and at least a special character";
  }
  return errors;
}

let Login = props => {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [errorText, setErrorText] = useState("");
  const [errorPass, setPassErrorText] = useState(false);
  const [errorEmail, setEmailErrorText] = useState(false);

  const { userData } = props;
  const store = Store.getState();

  const handleEmail = event => {
    setEmailErrorText(false);
    setEmail(event.target.value);
  };
  const handlePass = event => {
    setPassErrorText(false);
    setPassword(event.target.value);
  };

  const handleSubmit = event => {
    if (email && password) {
      event.preventDefault();
      setError(false);
      props.userLogin(email, password);
    } else {
      if (email) {
        setErrorText("Please enter emil");
      }
      setPassErrorText(true);
      setEmailErrorText(true);
    }
  };

  useEffect(() => {
    if (store.loginReducer.userData) {
      if (store.loginReducer.userData.data.message == "User not active") {
        alert(
          "Your account is not activated yet. We have sent a confirmation link to your email address."
        );
      } else if (
        store.loginReducer.userData.data.message ==
        "Invalid username or password"
      ) {
        // alert("Invalid username or password");
        setEmailErrorText(true)
        setPassErrorText(true)
      }
    }
  }, [store.loginReducer]);

  useEffect(() => {
    if (userData && userData.data.is_provider == false) {
      //Client
      if (userData.data.signup_step < 5) {
        const access = userData.data.access;
        const userObject = {
          user_id: userData.data.user_id,
          accessToken: access
        };
        props.loadUser(userObject);

        localStorage.setItem("resorter", JSON.stringify(userObject));
        agent.setToken(access);

        props.getClientProfile(userData.data.user_id);

        props.incrementActiveStepManual(userData.data.signup_step);
        props.history.replace("/sign-up");
      } else {
        const access = userData.data.access;
        const userObject = {
          user_id: userData.data.user_id,
          accessToken: access
        };
        props.loadUser(userObject);

        localStorage.setItem("resorter", JSON.stringify(userObject));
        agent.setToken(access);

        props.getClientProfile(userData.data.user_id);
        props.history.replace("/");
      }
    } else if (userData && userData.data.is_provider == true) {
      //Provider
      if (userData.data.signup_step < 6) {
        const access = userData.data.access;
        const userObject = {
          user_id: userData.data.user_id,
          accessToken: access
        };
        props.loadUser(userObject);

        localStorage.setItem("resorter", JSON.stringify(userObject));
        agent.setToken(access);

        props.getProviderProfile(userData.data.user_id);

        props.incrementActiveStepManual(userData.data.signup_step);
        props.history.replace("/sign-up");
      } else {
        const access = userData.data.access;
        const userObject = {
          user_id: userData.data.user_id,
          accessToken: access
        };
        props.loadUser(userObject);

        localStorage.setItem("resorter", JSON.stringify(userObject));
        agent.setToken(access);

        props.getProviderProfile(userData.data.user_id);
        props.history.replace("/");
      }
    }
  }, [userData]);

  let errorMessage = (
    <div>
      <br />
      {/* <p className={classes.errorMsg} /> */}
    </div>
  );
  if (error) {
    errorMessage = (
      <Typography className={classes.errorMsg}>
        Sorry, incorrect email or password
      </Typography>
    );
  }
  if (errorEmail || errorPass) {
    errorMessage = (
      <Typography className={classes.errorMsg}>
       Incorrect email or password. Try again. <br/>
       To reset password, please <a href="/password-reset">click here</a>.
      </Typography>
    );
  }

  return (
    <div>
      <Grid container justify="center" alignItems="center">
        <form onSubmit={handleSubmit}>
          {/* Login Header */}
          <Grid item sm={12} xs={12}>
            <Typography align="center" className={classes.title}>
              Login
            </Typography>
          </Grid>

          {/* Email and Password */}
          <Grid item md={12} style={{ margin: "1rem 0rem" }}>
            <Field
              name="email"
              component={renderFieldForLogin}
              type="email"
              label="Email"
              value={email}
              onChange={handleEmail}
            />
          </Grid>
          <Grid item md={12}>
            <Field
              name="password"
              component={renderFieldForLogin}
              type="password"
              label="PASSWORD"
              value={password}
              onChange={handlePass}
            />
          </Grid>

          {/* Google and Login button */}
          <Grid container md={12} style={{ textAlign: "center" }}>
            <FormControl className={classes.buttons}>
              {/* <Grid container > */}
                <Grid item xs={12} md={6} className={classes.btnGooglePosition}>
                  <GoogleLoginButton /> 
                </Grid>
                <Grid item xs={12} md={6} className={classes.btnPosition}>
                  <Button
                    variant="contained"
                    className={classes.loginBtn}
                    classes={{ label: classes.btnLabel }}
                    onClick={handleSubmit}
                  >
                    Login
                  </Button>
                {/* </Grid> */}
              </Grid>
            </FormControl>
          </Grid>

          {/* Signup Link */}
          <Grid item container direction="row" xs={12}>
            <Typography>Don’t have an account?&nbsp;</Typography>
            <Typography />
            <a href="/sign-up">Create one here</a>
          </Grid>

          <Grid item sm={12} xs={12}>
            {errorMessage}
          </Grid>
        </form>
      </Grid>
    </div>
  );
};

Login = reduxForm({
  form: "login",
  destroyOnUnmount: false,
  validate
})(Login);

const mapStateToProps = state => {
  return {
    userData: state.loginReducer.userData,
    userProfile: state.stepperReducer.userProfile
  };
};

const mapDispatchToProps = dispatch => ({
  incrementActiveStep: () => dispatch(incrementActiveStep()),
  incrementActiveStepManual: stepNumber =>
    dispatch(incrementActiveStepManual(stepNumber)),
  loadUser: user => {
    dispatch(loadUser(user));
  },
  userLogin: (emailID, password) => {
    dispatch(userLogin(emailID, password));
  },
  getClientProfile: userId => dispatch(getClientProfile(userId)),
  getProviderProfile: userId => dispatch(getProviderProfile(userId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);