export const FONTSIZE = {
  SMALL: 16,
  NORMAL: 18,
  HEADING: 26,
  LARGE: 30
};

export const FONTFAMILY = {
  FONT_SANS: "PT Sans",
  FONT_SANS_NARROW: "PT Sans Narrow"
};

export const COLOR = {
  PRIMARY: "#000000",
  SECONDARY: "#FF6161",
  BACKGROUND_PRIMARY: "#FFFFFF",
  BACKGROUND_SECONDARY: "#EFEFEF",
  SELECTION_COLOR: "#1277B8"
};
