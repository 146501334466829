// adopted from Bootstrap

export const media_xs_max = "575.98px";
export const media_sm_max = "767.98px";
export const media_md_max = "991.98px";
export const media_lg_max = "1199.98px";

export const media_sm_min = "576px";
export const media_md_min = "768px";
export const media_lg_min = "992px";
export const media_xl_min = "1200px";

export const NAV_BAR_HEIGHT = "64px";

export const TAB_BAR_HEIGHT = "48px";
