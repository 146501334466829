import React, {useState} from "react";
import Grid  from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useStyles from "../../../components/Common/SignupForm/ClientInfoStyles";
import { Button, IconButton, Popover, Typography, Card } from "@material-ui/core";
import blankImage from "../profile/blank_photo_icon.jpg";
import EditIcon from '@material-ui/icons/Edit';
import Avatar from '@material-ui/core/Avatar';

const useClasses = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
  divImage: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    alignItems: "end",
    paddingRight: "10px"
  },
  editIcon: {
    position: "absolute",
    zIndex: "2",
    color: "white",
    backgroundColor: "royalblue",
    margin: "-35px"
  },
  large: {
    width: "10rem",
    height: "10rem"
  },
  disabledEditIcon: {
    position: "absolute",
    zIndex: "2",
    color: "white",
    backgroundColor: "darkgrey",
    margin: "13px"
  },
  titleText: {
	fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "25.31px",
    display: "flex",
    alignItems: "center",
    color: "#000000"
  },
  nextButton: {
    width: "9em",
    height: "2.5em",
    borderRadius: 100,
    textAlign: "center",
    marginRight: "1em"
  },
  titleSpacing: {
    padding: "0.5rem 0rem 0.5rem 0rem"
  }
}));

export default function ProfileAvatar(props) {
	const classes = useClasses();
  const styles = useStyles();
	let [uploadedImage, setUploadedImage] = useState("");

	// handle browse image from computer and update the profile picture state
	const handleBrowseImage = event => {
		let uploadedImageUrl = URL.createObjectURL(event.target.files[0]);
		setUploadedImage(uploadedImageUrl);
    props.setProfileImage(event.target.files[0]);
	};

  return (
		<Grid container item sm={4} direction="column" justifyContent="center" alignItems="center">
			<Grid item className={classes.titleSpacing}>
				<Typography className={classes.titleText}>
					PROFILE PICTURE
				</Typography>
			</Grid>
			<Grid item>
				<div className={classes.divImage}>
					<Avatar alt="Avatar" src={ uploadedImage || props.profileImage } className={classes.large}/>
          <input 
            accept="image/*"
            className={classes.input}
            id="icon-button-file"
            type="file"
            onChange={handleBrowseImage}
          />
          {props.isEditable ? (
            <label htmlFor="icon-button-file">
              <IconButton 
                className={classes.editIcon}  
                aria-label="upload picture"
                size="small"
                component="span"
              >
                <EditIcon fontSize="small"/>
              </IconButton>
            </label>
					) : (
						<IconButton 
							className={classes.disabledEditIcon}  
							// aria-describedby={id}
							size="small"
						>
							<EditIcon fontSize="small"/>
						</IconButton>
					)}
				</div>
			</Grid>
		</Grid>
	);
}