import React, { Fragment, useEffect } from "react";

import Store from "../../redux/store";
import useStyles from "./SignupForm/ProviderInfo/Services/ServicesStyles";

// material ui
import {
  Checkbox,
  ListItem,
  ListItemText,
  Collapse,
  Typography,
  Box,
  ListItemSecondaryAction
} from "@material-ui/core";

import {
  CheckBox as CheckBoxIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowRight as ArrowRightIcon,
  AcUnit as AcUnitIcon
} from "@material-ui/icons";

export default function CollapsibleCheckBox(props) {
  const classes = useStyles();

  const {
    name,
    text,
    parent,
    children,
    nestingLevel,
    hasCheckBox,
    isStar,
    onCheckboxSelected,
    isSelected
  } = props;
  
  // const [selected, setSelected] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  
  useEffect(() => {
    if(isSelected !== undefined){
      setChecked(isSelected);
      setOpen(isSelected);
    }
  }, [isSelected]);

  // Handle expanding nested view
  const handleClick = () => {
    setOpen(!open);
  };

  // Handle checkbox status change
  const onChange = (event) => {
    setChecked(event.target.checked);
    onCheckboxSelected(event.target.checked);
  }

  const loadChildElements = () => {
    return children.map((child, i) => {
      {
        // console.log("Child Nesting Level: ", child);
      }
      return (
        // <Collapse key={i} in={selected} timeout="auto" unmountOnExit>
        //   {child(selected, nestingLevel)}
        // </Collapse>
        <Collapse key={i} in={open} timeout="auto" unmountOnExit>
        {child(open, nestingLevel)}
      </Collapse>
      );
    });
  };

  const getNestingLevelStyling = value => {
    switch (value) {
      case 0:
        return null;

      case 1:
        return classes.nested1;

      case 2:
        return classes.nested2;

      default:
        break;
    }
  };

  const contents = () => {
    return (
      <Fragment>
        {" "}
        {/*key={name+'-Contents'}*/}
        {/* {console.log("Nesting Level: ", nestingLevel)} */}
        {/* {console.log("Nesting Styling: ", getNestingLevelStyling(1))} */}
        <ListItem
          button
          onClick={handleClick}
          className={getNestingLevelStyling(nestingLevel)}
        >
            {isStar ? (
              <Typography
                style={{
                  fontFamily: "PT Sans Narrow",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "36px"
                }}
              >
                *
              </Typography>
            ) : open ? (
              <ArrowDropDownIcon />
            ) : (
              <ArrowRightIcon />
            )}

            <ListItemText primary={text} />
          <ListItemSecondaryAction>
            {props.isDisabled ? (
              props.showCheckBox ? (
                <Checkbox checked={checked} name="checkedB" color="primary" disabled/>
              ) : null
            ) : (
              <Checkbox checked={checked} name="checkedB" color="primary" onChange={onChange}/>
            )}
          </ListItemSecondaryAction>
        </ListItem>
        {/* Load Children if they exist */}
        {children != null && loadChildElements()}
      </Fragment>
    );
  };

  return (
    <Fragment>
      {" "}
      {/*key={name}*/}
      {/* {console.log("Parent: ", parent)} */}
      {/* Load contents inside a parent collapsible if it exists */}
      {parent == null ? (
        contents()
      ) : (
        <Collapse
          id={name}
          in={parent}
          timeout="auto"
          unmountOnExit
          children={contents()}
        />
      )}
    </Fragment>
  );
}
