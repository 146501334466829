import _ from "lodash";

/************************* initialization related *****************************/
/**
 * fill in requied data to initialState, especially when its in edit mode
 * @param {string} tripLessonId id of tripLesson, null if new
 * @param {object} initialState initial values
 * @param {object} otherValOrFunc values or functions to complete the setup
 *
 * @returns {object} initializedState
 */
export function initialize(tripLessonId, initialState, otherValOrFunc) {
  let groupLessonDurationDays;
  let initialParticipants = {},
    initializedState = {};
  const { tripLessons, familyMembers, main, calculateSession } = otherValOrFunc;

  if (tripLessonId) {
    const tripLesson = tripLessons.find(
      tripLesson => tripLesson.tripLessonId === tripLessonId
    );

    const flexibleDays = tripLesson.flexibleDays;
    const durationDays = tripLesson.durationDays;
    if (flexibleDays) {
      groupLessonDurationDays = `${durationDays} out of ${flexibleDays}`;
    } else {
      groupLessonDurationDays = durationDays;
    }

    let selectedInstructorOption = tripLesson.selectedInstructorOption
      ? tripLesson.selectedInstructorOption
      : initialState.selectedInstructorOption;

    initializedState = {
      ...initialState,
      ...tripLesson,
      selectedInstructorOption,
      groupLessonDurationDays,
      session: calculateSession(tripLesson.startTime, tripLesson.durationHours)
    };
    // initial participants should be ticked
    _.map(familyMembers, (participant, id) => {
      if (tripLesson.participants.includes(id)) {
        initialParticipants[id] = {
          ...participant,
          isChecked: true
        };
      }
    });
  } else {
    // when click add new lesson button
    // initial participants should be unticked
    const allActivityMembers = _.union(main["ski"], main["snowboard"]);
    _.map(allActivityMembers, id => {
      const memberInfo = familyMembers[id];
      initialParticipants[id] = {
        ...memberInfo,
        isChecked: false
      };
    });
    initializedState = initialState;
  }

  return {
    ...initializedState,
    initialParticipants
  };
}

/************************* render related *************************************/
/**
 * get string of full name list of members with time clashes
 * @param {array} memberIds ids of members who have time clashes with existing lessons
 * @param {object} members  dictionary of members
 *
 * @returns {string}
 */
export function membersWithTimeClashes(memberIds, members) {
  let member_string =
    "The following participant(s) already has(have) lessons with same time schedule:\n ";
  memberIds.map(
    id =>
      (member_string +=
        '"' + members[id].FirstName + " " + members[id].LastName + '" ')
  );
  return member_string;
}

/************************* handle save related ********************************/
/**
 * whether users selected participants with same ability level in private lessosn
 * @param {array} participants an array of participant object
 * @param {string} activity ski, snowboard, etc
 *
 * @returns {boolean} warning msg
 */
export function doParticipantsHaveSameAbilityLevel(participants, activity) {
  let warningMsg = "";
  let abilityLevels = new Set();
  let abilityMapper = {
    ski: "SkiAbility",
    snowboard: "SnowboardAbility"
  };
  let checkedParticipantIds = getParticipantsList(participants);
  checkedParticipantIds.map(id => {
    abilityLevels.add(participants[id][abilityMapper[activity.toLowerCase()]]);
  });

  return abilityLevels.size <= 1;
}

/**
 * validate form
 * @param {object} error object of errors
 * @param {object} values  form fileds
 * @returns {array} modified error object indicating which filed has error
 */
export function validateForm(errors, values) {
  let errorsCopy = { ...errors };
  // check if lesson already exists
  const {
    newLesson,
    tripLessons,
    tripLessonId,
    familyMembers
  } = values.timeClashCheckerObject;
  let memberIdsWithTimeClash = checkTimeClash(
    newLesson,
    tripLessons,
    tripLessonId
  );

  if (memberIdsWithTimeClash.length > 0) {
    errorsCopy = {
      ...errorsCopy,
      inOldLessonsErrorMsg: membersWithTimeClashes(
        memberIdsWithTimeClash,
        familyMembers
      )
    };
  }
  if (!values.activity) {
    errorsCopy = { ...errorsCopy, activityError: true };
  }
  if (!values.ageGroup) {
    errorsCopy = { ...errorsCopy, ageGroupError: true };
  }
  if (!values.durationDays) {
    errorsCopy = { ...errorsCopy, daysError: true };
  }
  if (!values.lessonType) {
    errorsCopy = { ...errorsCopy, lessonTypeError: true };
  }
  // allow removing all participants in non-first timer lessons
  if (!values.tripLessonId && values.participantsList.length === 0) {
    errorsCopy = { ...errorsCopy, membersError: true };
  }
  if (values.isPrivateLesson) {
    if (!values.durationHours) {
      errorsCopy = { ...errorsCopy, durationError: true };
    }
    if (!values.startTime) {
      errorsCopy = { ...errorsCopy, startTimeError: true };
    }
    if (values.selectedInstructorOption !== "1" && !values.instructorDetails) {
      errorsCopy = { ...errorsCopy, instructorDetailsError: true };
    }
    if (
      !doParticipantsHaveSameAbilityLevel(
        values.participantsList,
        values.activity
      )
    ) {
      errorsCopy = { ...errorsCopy, differentAbilityLevelError: true };
    }
  } else {
    if (!values.session) {
      errorsCopy = { ...errorsCopy, sessionError: true };
    }
  }

  return errorsCopy;
}

/**
 * compare error with initial setup
 * @param {object} err
 * @param {object} initialErrors

 * @returns {boolean} inidicate whether there is an error exists
 */
export function hasErrors(err, initialErrors) {
  return (
    hasFieldsRequiredToBeFilledError(err, initialErrors) ||
    err.inOldLessonsErrorMsg !== initialErrors.inOldLessonsErrorMsg
  );
}

/**
 * compare error with initial setup
 * @param {object} err
 * @param {object} initialErrors

 * @returns {boolean} inidicate whether there are fileds left that are required
 */
export function hasFieldsRequiredToBeFilledError(err, initialErrors) {
  return !(
    initialErrors.activityError === err.activityError &&
    initialErrors.ageGroupError === err.ageGroupError &&
    initialErrors.lessonTypeError === err.lessonTypeError &&
    initialErrors.durationError === err.durationError &&
    initialErrors.sessionError === err.sessionError &&
    initialErrors.startTimeError === err.startTimeError &&
    initialErrors.instructorDetailsError === err.instructorDetailsError &&
    initialErrors.daysError === err.daysError &&
    initialErrors.membersError === err.membersError
  );
}

/**
 * get ids from a list of members who are been checked
 * @param {array} participants array of member objects
 *
 * @returns {array} array of ids
 */
export function getParticipantsList(participants) {
  let participantsList = [];
  _.map(participants, (participant, id) => {
    if (participant.isChecked) participantsList = [...participantsList, id];
  });
  return participantsList;
}

/**
 * get lesson's time information
 * @param {object} lsn trip lesson object

 * @returns {object} an obejct of activity, lessonType, startDate, startTime,
           durationHours, durationDays and flexibleDays
 */
export function getLessonTimeInfo(lsn) {
  return {
    activity: lsn.activity,
    lessonType: lsn.lessonType,
    startDate: lsn.startDate,
    startTime: lsn.startTime,
    durationHours: lsn.durationHours,
    durationDays: lsn.durationDays,
    flexibleDays: lsn.flexibleDays
  };
}

/**
 * compare two lesson time object
 * @param {object} lsnTime1
 * @param {object} lsnTime2

 * @returns {boolean} inidicate whether they are the same or not
 */
export function compareLessonTime(lsnTime1, lsnTime2) {
  let nonNullValComparison =
    lsnTime1.activity === lsnTime2.activity &&
    lsnTime1.lessonType === lsnTime2.lessonType &&
    lsnTime1.startDate.getDate() === lsnTime2.startDate.getDate() &&
    lsnTime1.startDate.getFullYear() === lsnTime2.startDate.getFullYear() &&
    lsnTime1.startDate.getMonth() === lsnTime2.startDate.getMonth() &&
    lsnTime1.startTime === lsnTime2.startTime &&
    lsnTime1.durationHours.toString() === lsnTime2.durationHours.toString() &&
    lsnTime1.durationDays.toString() === lsnTime2.durationDays.toString();
  try {
    return (
      nonNullValComparison &&
      lsnTime1.flexibleDays.toString() === lsnTime2.flexibleDays.toString()
    );
  } catch (err) {
    return nonNullValComparison;
  }
}

/**
 * validate form
 * @param {object} newLesson object of new lesson
 * @param {array} tripLessons array of lessons
 * @param {string} tripLessonId id of trip lesson, null if new
 *
 * @returns {array} an array of ids of members who have time clashes on their existing lessons
 */
export function checkTimeClash(newLesson, tripLessons, tripLessonId) {
  let idsWithTimeClashes = [];
  newLesson.participants.map(id => {
    let allLessonsTime = [];
    tripLessons.map(lsn => {
      if (lsn.participants.includes(id) && lsn.tripLessonId != tripLessonId) {
        allLessonsTime.push(getLessonTimeInfo(lsn));
      }
    });
    if (
      allLessonsTime.some(lsnTime =>
        compareLessonTime(lsnTime, getLessonTimeInfo(newLesson))
      )
    ) {
      idsWithTimeClashes.push(id);
    }
  });

  return idsWithTimeClashes;
}

/**
 * get correct days and time
 * @param {boolean} isPrivateLesson
 * @param {object} values an object of durationHours, startTime, session, durationHours
 * @param {object} sessionMapper a dictionary of mapping session to (startTime, durationHours)
 *
 * @returns {array} modified error object indicating which filed has error
 */
export function getDaysAndTime(isPrivateLesson, values, sessionMapper) {
  let timeAndDays;
  if (isPrivateLesson) {
    if (!values.durationHours || !values.startTime || !values.durationDays)
      return;
    timeAndDays = {
      durationHours: values.durationHours,
      startTime: values.startTime,
      durationDays: values.durationDays
    };
  } else {
    if (!values.session || !values.durationDays) return;
    let days;
    // no flexible days
    if (values.durationDays.toString().length == 1) {
      days = {
        durationDays: values.durationDays,
        flexibleDays: 0
      };
    } else {
      // has flexible days
      days = {
        durationDays: values.durationDays.charAt(0),
        flexibleDays: values.durationDays.charAt(values.durationDays.length - 1)
      };
    }
    timeAndDays = {
      ...days,
      ...sessionMapper[values.session]
    };
  }
  return timeAndDays;
}

/************************* filter related *************************************/
/**
 * According to the activity, get the member's ability
 * @param {string} activity - ski or snowboard
 * @param {object} member - member object contains all info
 *
 * @returns true/false depends on the activity
 */
const getFirstTimer = (activity, member) => {
  let firstTimer;
  if (activity === "ski") {
    firstTimer = member.SkiAbility === 1 ? true : false;
  } else if (activity === "snowboard") {
    firstTimer = member.SnowboardAbility === 1 ? true : false;
  }
  return firstTimer;
};

/**
 * calculate a member's age
 * @param {Object} member - contains all information of the member
 */
const calculateAge = member => {
  const today = new Date();
  const DOB = member["DOB"];
  const age = today.getFullYear() - DOB.getFullYear();
  return age;
};

/**
 * Check if the member can be added into the lesson:
 * true if activity, age group are the same
 * @param {string} activity - the trip lesson is for which activity
 * @param {object} member - member object contains all info
 * @param {object} filteredLesson - resort lesson that has been filtered, it contains all info
 * @param {boolean} isAdaptive
 * @param {boolean} isFirstTimer
 *
 * @returns true if the member can be added directly in the lesson
 **/
export function isMemberFitInLesson(
  activity,
  member,
  filteredLesson,
  isAdaptive,
  isFirstTimer
) {
  // check activity if fits
  if (!filteredLesson || activity !== filteredLesson.activity) return false;
  // check age if fits
  const age = calculateAge(member);
  if (!(age >= filteredLesson.minimum_age && age <= filteredLesson.maximum_age))
    return false;
  // check adaptive if fits
  if (member.IsDisabled !== isAdaptive) return false;
  // check firstTimer if fits
  const isMemberfirstTimer = getFirstTimer(activity, member);
  if (isMemberfirstTimer !== isFirstTimer && member.IsDisabled !== isAdaptive)
    return false;

  return true;
}

/**
 * Get eligible participants who can be added into this lesson, make them unchecked by default
 * @param {array} allParticipants - array of memberIds who enrolled in the activity
 * @param {object} filteredLesson - resort lesson that has been filtered, it contains all info
 * @param {string} activity - the trip lesson is for which activity
 * @param {object} values - familyMembers, isAdaptive, isFirstTimer, participants, tripLessonId
 *
 * @returns {object} object of eligible participants, key is id
 */
export function getEligibleParticipants(
  allParticipants,
  filteredLesson,
  activity,
  values
) {
  const {
    familyMembers,
    isAdaptive,
    isFirstTimer,
    participants,
    tripLessonId
  } = values;
  const eligibleParticipants = _.filter(allParticipants, participantId => {
    const memberInfo = familyMembers[participantId];
    return isMemberFitInLesson(
      activity,
      memberInfo,
      filteredLesson,
      isAdaptive,
      isFirstTimer
    );
  });
  //defaultParticipants stores the initial participants who are already in a particular lesson card
  const defaultParticipants = _.map(participants, (participant, id) => id);

  let filteredParticipants = {};
  _.map(familyMembers, (participant, id) => {
    if (eligibleParticipants.includes(id)) {
      // if edit popup, make default participants ticked, others unticked
      if (tripLessonId) {
        if (!defaultParticipants.includes(id)) {
          filteredParticipants[id] = {
            ...participant,
            isChecked: false
          };
        }
      }
      //if add popup, all the initial participants should be unticked
      else {
        filteredParticipants[id] = {
          ...participant,
          isChecked: false
        };
      }
    }
  });

  return filteredParticipants;
}

export const instructorOptionsMapper = {
  1: {
    text: "Assign me one"
  },
  2: {
    text: "I have a specific instructor in mind.",
    placeholder: "Type the name of the instructor you want"
  },
  3: {
    text: "I have a specific type of instructor in mind.",
    placeholder: "Type a description of the type you want"
  }
};
