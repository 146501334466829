import { CONTACT_US_EMAIL_SENT } from "./action-types";
import agent from "../../agent";

export function sendEmail(request) {
  return function(dispatch) {
    return agent.Helpers.contactUs({ ...request }).then(response => {
      if (response.status === 200) {
        dispatch({ type: CONTACT_US_EMAIL_SENT, payload: true });
      }
    });
  };
}

export function emailSent() {
  return function(dispatch) {
    return dispatch({ type: CONTACT_US_EMAIL_SENT, payload: false });
  };
}
