import React, { Component } from "react";
import styles from "./Register.module.css";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import connect from "react-redux/es/connect/connect";
import getFormValues from "redux-form/es/getFormValues";

import { OrangeButton } from "../../styling/styled-components";
import { baseURL } from "../../redux/actions/action-types";
import agent from "../../agent";
import { loadUser } from "../../redux/reducers/auth";
import { PROFILE } from "../../components/lessonsEnquiry/Book/section/section-types";

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      error: false,
      errorMessage: ""
    };
  }

  componentDidMount() {
    if (this.props.formValues != null) {
      this.setState({ email: this.props.formValues.Email });
    }
  }

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({
      error: false,
      errorMessage: ""
    });
    // console.log(this.state.email + " " + this.state.password);

    let memberData = null;

    if (this.props.formValues != null) {
      const { DOB, countryCode, ...remainder } = this.props.formValues;
      memberData = { ...remainder };
      memberData.DOB = DOB;
      memberData.PhoneAreaCode = countryCode.dial_code;
      memberData.Country = countryCode.name;
    }

    const memberID = localStorage.getItem("memberID");

    agent.Users.register({
      email: this.state.email,
      password: this.state.password,
      masterMemberID: memberID,
      ...(this.props.formValues != null && { member: memberData })
    })
      .then(response => {
        const responseData = response.data;
        localStorage.setItem("userID", responseData.id);
        agent.setToken(responseData.tokens.token);
        agent.Users.currentUser().then(response => {
          this.props.loadUser(response.data);
        });
        this.props.history.replace("/enquiry-success", {});
      })
      .catch(error => {
        console.log(error);
        this.setState({
          error: true,
          errorMessage: error.response.data.error
        });
      });
  };

  render() {
    let errorMessage = null;
    if (this.state.error) {
      errorMessage = (
        <label className={styles.Error}>
          {this.state.errorMessage.message}
        </label>
      );
    }

    return (
      <Container>
        <Row>
          <Col>
            <Form className={styles.Register} onSubmit={this.handleSubmit}>
              <Row className="justify-content-center">
                <Col style={{ textAlign: "center" }}>
                  <label className={styles.Topic}>Register</label>
                </Col>
              </Row>
              <Form.Group as={Row} className="justify-content-center">
                <Col md={5}>
                  <Form.Label htmlFor="email">Email:</Form.Label>
                  <Form.Control
                    type="email"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    name="email"
                    id="email"
                    // disabled={this.props.formValues != null}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="justify-content-center">
                <Col md={5}>
                  <Form.Label htmlFor="password">Password:</Form.Label>
                  <Form.Control
                    type="password"
                    value={this.state.password}
                    onChange={this.handleInputChange}
                    name="password"
                    id="password"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="justify-content-center">
                <Col md={3}>
                  <OrangeButton
                    style={{
                      width: "100%",
                      margin: "15px 0",
                      borderRadius: 4,
                      height: 38
                    }}
                    onClick={this.handleSubmit}
                  >
                    Register
                  </OrangeButton>
                </Col>
              </Form.Group>
              <Row className="justify-content-center">
                <Col
                  style={{
                    textAlign: "center",
                    position: "absolute",
                    color: "red"
                  }}
                >
                  {this.state.errorMessage}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    formValues: getFormValues(PROFILE)(state)
  };
}

const mapDispatchToProps = dispatch => ({
  loadUser: user => {
    dispatch(loadUser(user));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
