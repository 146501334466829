import {
  LOAD_MESSAGE_ROOM_INFO,
  LOAD_INSTRUCTOR_INFO,
  LOAD_STUDENT_INFO,
  LOAD_VIRTUAL_BOOKING_INFO
} from "./action-types";

export const loadMessageRoomInfo = messageRoomData => {
  return {
    type: LOAD_MESSAGE_ROOM_INFO,
    payload: { messageRoomData }
  };
};

export const loadInstructorInfo = instructorData => {
  return {
    type: LOAD_INSTRUCTOR_INFO,
    payload: { instructorData }
  };
};
export const loadStudentInfo = studentData => {
  return {
    type: LOAD_STUDENT_INFO,
    payload: { studentData }
  };
};
export const loadVirtualBookingInfo = virtualBookingData => {
  return {
    type: LOAD_VIRTUAL_BOOKING_INFO,
    payload: { virtualBookingData }
  };
};
