import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import {
  sendHostName,
  toggleHostLike,
  getLanguageData,
  getSuitableForData,
  getServiceData
} from "../../../redux/actions/exploreHostsActions.js";
import Button from "@material-ui/core/Button";
import agent from "../../../agent.js"
import ProviderCard from "../../../components/serviceMarket/home/ProviderCard/ProviderCard.js";



import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Typography,
  ListSubheader,
  Checkbox,
  ListItemText,
  TextField,
  Grid,
  Radio,
  Collapse,
  RadioGroup,
  FormControlLabel,
  Box
} from "@material-ui/core";
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider
} from "@material-ui/core/styles";

import CardPagination from "../../Common/CardPagination";
import HostCard from "../HostCard/HostCard.js";
import { COLOR, FONTFAMILY } from "../../../styling/theme/Theme.js";
import {
  FIRST_TIMER_API_VALUE,
  FIRST_TIMER_VALUE,
  SERVICES_NAMES,
  SKI,
  SNOWBOARD
} from "../../../constants/common.js";

const useStyles = makeStyles(theme => ({
  main: {
    flexGrow: 1,
    width: "100%"
  },
  filter: {
    textAlign: "center",
    margin: "1rem 0rem"
    
    // marginBottom: "2em",
    // marginRight: "2em",
    // marginTop: "2em"
  },
  prompt: {
    fontWeight: "bold",
    fontStyle: "normal",
    color: "#505050",
    display: "inline-block",
    margin: "1rem 0rem"
    // margin: "12px"
  },
  bodytext: {
    fontWeight: "bold",
    fontStyle: "normal",
    color: "#505050",
    display: "inline-block",
    color: "rgb(255,97,97)",
    marginTop: "0.5em",
    marginRight: "1em",
    textAlign: "right"
  },
  noProviders: {
    textAlign: "center",
    margin: "2rem 0rem",
    backgroundColor: COLOR.BACKGROUND_SECONDARY,
    padding: "1rem 0rem"
  },
  noResultMessage: {
    fontFamily: FONTFAMILY.FONT_SANS_NARROW,
    textAlign: "center",
    fontStyle: "normal",
    fontSize: "26px"
  },
  // select1: {
  //   minWidth: "8em",
  //   maxWidth: "8em",
  //   marginRight: "1.5em"
  // },
  select2: {
    //TODO: need to remove min and max
    // minWidth: "18em",
    // maxWidth: "18em",
    height: "2.1rem",
    "& .MuiFormLabel-root": {
      "& .Mui-focused hover": {
        color: COLOR.PRIMARY,
        borderWidth: 1,
        borderRadius: 5
      }
    },
    "& .MuiInputLabel-outlined": {}
  },
  // select3: {
  //   minWidth: "9em",
  //   maxWidth: "9em",
  //   marginRight: "1.5em"
  // },
  // select4: {
  //   minWidth: "11em",
  //   maxWidth: "11em",
  //   marginRight: "1.5em"
  // },
  // select5: {
  //   minWidth: "8em",
  //   maxWidth: "8em"
  // },
  label: {
    color: COLOR.PRIMARY,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    justifyContent: "center",
    alignItems: "center",
    "&.MuiFormLabel-root": {
      "&.Mui-focused": {
        color: COLOR.PRIMARY,
        backgroundColor: "#ffffff"
      }
    },
    "&.MuiInputLabel-outlined": {
      transform: "translate(10px, 10px) scale(1)",
      "&.MuiInputLabel-shrink": {
        background: "#ffffff",
        transform: "translate(10px, -6px) scale(0.75)"
      }
    }
  },
  inputSelect: {
    marginTop: "0.2em",
    textAlign: "center",
    flexWrap: "wrap",
    display: "flex"
  },
  selectMenu: {
    width: "90%"
    // height: "0.1em"
  },
  selectRootText: {
    textAlign: "center",
    // color: "rgb(255,97,97)"
    color: COLOR.PRIMARY,
    // margin: "12px",
    //TODO: add  width
    width: "90%",
    "& div.MuiOutlinedInput-root": {
      height: "2.1rem",
      fontFamily: "Roboto"
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      borderColor: "black !important"
    },
    "& .MuiOutlinedInput-root .MuiSelect-select:focus": {
      backgroundColor: "#EFEFEF"
    }
  },

  selectRoot: {
    textAlign: "center",
    margin: "1rem 0rem",
    // color: "rgb(255,97,97)"
    color: COLOR.PRIMARY,
    // margin: "12px",
    borderColor: "black",
    //TODO: add  width
    // maxWidth: "90%",
    // minWidth: "90%",
    width: "90%",
    "& div.MuiOutlinedInput-root": {
      height: "2.1rem",
      fontFamily: "Roboto"
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      borderColor: "black !important"
    },
    "& .MuiOutlinedInput-root .MuiSelect-select:focus": {
      backgroundColor: "transparent"
    }
  },
  selectIcon: {
    // color: "rgb(255,97,97)",
    color: COLOR.PRIMARY
  },
  search: {
    width: "17em"
  },
  checkBoxStyle: {
    "& .MuiCheckbox-root": {
      color: "#1277B8"
    }
  },
  searchRoot: {
    borderRadius: 5,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: COLOR.PRIMARY,
        borderWidth: 1,
        borderRadius: 5
      },
      "&:hover fieldset": {
        borderColor: COLOR.PRIMARY,
        borderWidth: 1,
        borderRadius: 5
      },
      "&.Mui-focused fieldset": {
        borderColor: "#ff0000",
        borderWidth: 1,
        borderRadius: 5
      }
    }
  },
  searchInput: {
    color: "rgb(255,97,97)",
    height: "3em",
    marginTop: "0.3em",
    textAlign: "center",
    flexWrap: "wrap",
    display: "flex"
  },
  searchLabel: {
    color: "rgb(255,97,97)"
  },
  listRoot: {
    whiteSpace: "normal",
    // width: "18em",
    alignContent: "center"
  },
  subHeaderRoot: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "140.62%",
    display: "flex",
    alignItems: "center",
    color: COLOR.PRIMARY
  },
  subHeaderRoot2: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "140.62%",
    display: "flex",
    alignItems: "center",
    color: COLOR.PRIMARY
  },
  primary: {
    fontFamily: "PT Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "140.62%",
    color: COLOR.PRIMARY
  },
  primaryOfSuitable: {
    textAlign: "left"
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  inputTextStyleDefault: {
    //TODO: remove min and max
    // minWidth: "18em",
    // maxWidth: "18em",
    margin: "1rem 0rem",
    "& .MuiFilledInput-input": {
      padding: "10px 10px",
      color: COLOR.PRIMARY
    },
    "& .MuiFilledInput-underline.Mui-disabled:before ": {
      borderBottomStyle: "none"
    },
    "& .MuiFilledInput-root": {
      borderRadius: 5
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(10px, 10px) scale(1)",
      "&.MuiInputLabel-shrink": {
        background: "#ffffff",
        transform: "translate(10px, -6px) scale(0.75)",
        color: "#000"
      }
    }
    // "& .MuiFormLabel-root": {
    //   "& .Mui-focused hover": {
    //     color: COLOR.PRIMARY,
    //     borderWidth: 1,
    //     borderRadius: 6
    //   }
    // },
    // "& .MuiInputLabel-outlined": {}
  },
  inputTextStyle: {
    //TODO: remove min and max
    // minWidth: "18em",
    // maxWidth: "18em",
    // margin: "1rem 0rem",
    overflowX: "clip",
    whiteSpace: "nowrap",
    wordBreak: "break-word",
    // overflow: "hidden",
    height: "2.1rem",
    "& .MuiInputLabel-outlined": {
      transform: "translate(10px, 10px) scale(1)",
      // Change "Search by host/guide name..." placeholder fontsize
      [theme.breakpoints.between("1099", "1200")]: {
        transform: "translate(10px, 10px) scale(.9)"
      },
      [theme.breakpoints.between("960", "1098")]: {
        transform: "translate(10px, 10px) scale(.8)"
      },
      "&.MuiInputLabel-shrink": {
        background: "#ffffff",
        transform: "translate(10px, -6px) scale(0.75)"
        // color: "#000"
      }
    }
    // "& .MuiFormLabel-root": {
    //   "& .Mui-focused hover": {
    //     color: COLOR.PRIMARY,
    //     borderWidth: 1,
    //     borderRadius: 6
    //   }
    // },
    // "& .MuiInputLabel-outlined": {}
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5"
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)"
    }
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3"
    }
  },
  customCheckBox: {
    alignSelf: "flex-start"
  },
  filterLabels: {
    textAlign: "start",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  button: {
    borderRadius: 100,
    height: "3em",
    width: "90%",
    background: "#FF6161",
    alignSelf: "flex-end",
    margin: "1rem 0rem",
    "&:hover.MuiButton-contained": {
      background: "#FF6161"
    }
  },
  buttonLabel: {
    fontWeight: "300",
    textTransform: "none",
    color: "#ffffff",
    fontWeight: 700,
    fontSize: 16,
    fontFamily: "PT Sans"
  },
  collapseView: {
    overflow: "hidden",
    width: "inherit",
    "& .MuiCollapse-wrapper": {
      display: "flex",
      margin: "1rem 0rem"
    }
  },
  providerCardList: {
    rowGap: 20,
    columnGap: 20,
    maxWidth: 700,
    //margin changed by mamta from 90px to 20px
    margin: "20px 0 116px 0",
  },
  hideDiv: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
}));

const theme = createMuiTheme({
  overrides: {
    MuiMenu: {
      list: {
        maxHeight: "40em"
      }
    },
    MuiIconButton: {
      label: {
        justifyContent: "flex-start",
        color: "#f00"
      }
    },
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "black !important",
          borderWidth: 1
        },
        "&$focused $notchedOutline": {
          borderColor: "black !important",
          borderWidth: 1
        },
        "&:hover $notchedOutline": {
          borderColor: "black !important",
          borderWidth: 1
        }
      },
      notchedOutline: {}
    }
  },
  palette: {
    primary: {
      main: "#FFFFFF"
    },
    secondary: {
      main: COLOR.PRIMARY
    }
  }
});


function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    window.addEventListener("resize", handleResize);

    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}

const ContentFilter = props => {
  const hosts = props.hosts;
  const toggleHostLike = props.toggleHostLike;
  
  const classes = useStyles();

  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [selectedTravellers, setSelectedTravellers] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [selectedVirtualLessons, setVirtualLessons] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedPrice, setSelectedPrice] = useState([]);
  const [favHosts, setFavHosts] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [findByName, setName] = React.useState("");
  const [skiingAbilityLevel, setSkiAbilityLevel] = React.useState();
  const [
    selectedSnowBoardingAbilityLevel,
    setSnowBoardAbilityLevel
  ] = React.useState();
  const [selectedAbilityLevel, setAbilityLevel] = React.useState([]);
  const [isAbilityLevelVisible, setAbilitylevelVisible] = React.useState(false);
  const [servicesData, setServicesData] = React.useState([]);
  const [selectedAbilityLabels, setAbilityLabels] = React.useState([]);
  const [visibilitySkiing, setVisibilitySkiing] = React.useState(true);
  const [isNoProvider, setIsNoProvider] = React.useState(false);
  const [providerResults, setProviderResults] = useState({});
  const [selectedCountry, selectCountry] = React.useState();
  const [selectedResort, selectResort] = React.useState();
  const [selectedResortID, setSelectedResortID] = useState();
  const [checked, setChecked] = React.useState(false);
  const [filteredProv,setFilteredProv]=React.useState([]);
  
  const [visibilitySnowBoarding, setVisibilitySnowBoarding] = React.useState(
    true
  );
  const {
    language,
    numberOfTravellers,
    suitableFor,
    generalConcierge,
    generalAssistance,
    activities,
    tours,
    childMindingServices,
    expertiseInterests,
    pricing,
    vistualLessons,
    snowBoardAbilityLevel,
    skiAbilityLevel,
    serviceData
  } = props;


  const sampleProvider={
    id: 260,
    provider_bio: "sample",
    resorts: [
      {
        id: 39,
        resort_name: 'Vorobyovy Gory – Moscow'
      }
    ],
    services:[
      {
        id: 6, catagory_name: 'TOURING HOSTS', service_name: 'Hiking'
      },
      {
        id: 8, catagory_name: 'TOURING HOSTS', service_name: 'Snowboard backcountry touring'
      }
    ],
    suitable_fors:[
      {
        id: 2, suitable_for: 'Kids / 6-12yo'
      }
    ],
    user:
    {
      bio: "Machine teacher red which.",
      country: {id: 40, country_name: 'Northwest '},
      dob: "1987-05-27",
      first_name: "Sample",
      gender: "male",
      languages:[
        {id: 2, language: 'Amharic'}
      ],
      last_name: "Provider",
      mobile_number: "+499922840523",
      profile_picture: "https://test-resorter-images.s3.amazonaws.com/https%3A/picsum.photos/200?AWSAccessKeyId=AKIAWCHE4E6JA6W67TG5&Signature=Rn1sPvdoZcaefbkgJetQhkOONV4%3D&Expires=1654225270",
      signup_step: 3,
      skiing_level: 3,
      snowboarding_level: 1
    }
  }

  const isTablet = useWindowSize();
  // useEffect(() => {
  //   setLocations(geodata);
  //   updateLocationData(size.value); // call the location updater on mount with the initial size state value
  // }, []);

  useEffect(() => {
    props.getLanguageData();
    props.getSuitableForData();
    props.getServiceData();
    
  }, []);




  useEffect(() => {
    console.log("useeffect 1")
    setIsNoProvider(props.isNoProvider);
    selectCountry(props.selectedCountry);
    selectResort(props.selectedResort)
    setSelectedResortID(props.selectedResortID);
    setChecked(props.checked);
  }, [props]);


  useEffect(() => {
  
    if (selectedResortID!=null){
      
      // The async function to search the providers via the api
      const fetchProviderResults = async () => {
        const res = await agent.Users.listProviders(selectedResortID);
        setProviderResults(res.data);
        console.log(res.data)
      }
  
    fetchProviderResults()
      // make sure to catch any error
      .catch(console.error);

    //setChecked(val);
    }
  }, [props]);

  const handleLanguage = event => {
    setSelectedLanguage(event.target.value);
  };

  useState(() => {}, [selectedAbilityLevel]);

  const handleTravellers = event => {
    setSelectedTravellers(event.target.value);
  };

  const handleExperience = event => {
    setSelectedExperience(event.target.value);
  };


  useEffect(() => {
    var categoryNameArray = [];
    var objectData = {};
    var objectArray = [];
    var index = 0;
    serviceData &&
      serviceData.forEach(service => {
        if (!categoryNameArray.includes(service.catagory_name)) {
          categoryNameArray.push(service.catagory_name);
          objectData.category_name = service.catagory_name;
          objectData.id = "";
          objectData.header = true;
          index = objectArray.length;
          objectArray[index] = { ...objectData };
        }
        objectData.category_name = service.catagory_name;
        objectData.id = service.id;
        objectData.service_name = service.service_name;
        objectData.header = false;
        index = objectArray.length;
        objectArray[index] = { ...objectData };
      });
    objectArray.splice(23, 0, {
      category_name: SERVICES_NAMES.CHILD_MINDING,
      id: "SpecialNeed",
      header: false,
      service_name: "Special needs care experience"
    });
    objectArray.splice(24, 0, {
      category_name: SERVICES_NAMES.CHILD_MINDING,
      id: "DriveCar",
      header: false,
      service_name: "Can drive a car"
    });
    objectArray.splice(25, 0, {
      category_name: SERVICES_NAMES.CHILD_MINDING,
      id: "DriveOwnCar",
      header: false,
      service_name: "Can drive own car"
    });
    objectArray.splice(26, 0, {
      category_name: SERVICES_NAMES.CHILD_MINDING,
      id: "DogWalk",
      header: false,
      service_name: "Dog walking"
    });
    setServicesData(objectArray);
  }, [serviceData]);

  useEffect(() => {
    var skiValue = selectedInterests.some(
      v => v && v.toLowerCase().includes(SKI.toLowerCase())
    );
    var snowValue = selectedInterests.some(
      v => v && v.toLowerCase().includes(SNOWBOARD.toLowerCase())
    );
    var firstTimerValue = selectedInterests.some(
      v => v && v.toLowerCase().includes(FIRST_TIMER_VALUE.VALUE.toLowerCase())
    );
    if (firstTimerValue) {
      setAbilitylevelVisible(true);
      setVisibilitySnowBoarding(false);
      setVisibilitySkiing(false);
    } else if (skiValue || snowValue) {
      if (skiValue && snowValue) {
        setAbilityLevel([]);
        setVisibilitySnowBoarding(false);
        setVisibilitySkiing(false);
      } else if (skiValue) {
        setAbilityLevel([]);
        setVisibilitySnowBoarding(true);
        setVisibilitySkiing(false);
      } else if (snowValue) {
        setAbilityLevel([]);
        setVisibilitySnowBoarding(false);
        setVisibilitySkiing(true);
      }
      setAbilitylevelVisible(true);
    } else {
      setAbilityLevel([]);
      setAbilitylevelVisible(false);
    }
  }, [selectedInterests]);

  const handleInterests = event => {
    setIsNoProvider(false)
    setSelectedInterests(event.target.value);
    //console.log(event.target.value)
  };

  const handleSearch = event => {
    
    setIsNoProvider(true)
    let filteredProviders=0
    
    
    if(selectedInterests.length>0)
    {
      console.log(selectedInterests)
      
        filteredProviders=providerResults.filter(p => p.services.some(s =>
           s.service_name.length>30?
           selectedInterests.includes(s.service_name.substring(8,28)+", "+s.service_name.substring(37,88))
           :selectedInterests.includes(s.service_name)))


      if(selectedAbilityLevel.length>0)
      {
        

        filteredProviders=filteredProviders.filter(p => selectedAbilityLevel.length==2?
          p.user.skiing_level>=selectedAbilityLevel[0].substr(-1,1)||
          p.user.snowboarding_level>=selectedAbilityLevel[1].substr(-1,1):selectedAbilityLevel[0].search("Ski")!=-1?
          p.user.skiing_level>=selectedAbilityLevel[0].substr(-1,1)
          :p.user.snowboarding_level>=selectedAbilityLevel[0].substr(-1,1))

      }
    }

    if(selectedLanguage.length>0)
    {
      if(selectedInterests.length>0)
      {
        let temp=filteredProviders

        filteredProviders=filteredProviders.filter
        (l=>l.user.languages.some(lang=>selectedLanguage.includes(lang.language)))

        //filteredProviders.length==0?filteredProviders=temp:null
        
      }
      else
      {
        filteredProviders=providerResults.filter
        (l=>l.user.languages.some(lang=>selectedLanguage.includes(lang.language)))
      }
    }

    if(selectedTravellers.length>0)
    {
      if(selectedInterests.length>0||selectedLanguage.length>0)
      {
        filteredProviders=filteredProviders.filter
        (s=>s.suitable_fors.some(suit=>selectedTravellers.includes(suit.suitable_for)))
      }
      else
      {
        filteredProviders=providerResults.filter
        (s=>s.suitable_fors.some(suit=>selectedTravellers.includes(suit.suitable_for)))
      }
    }

    

    if(findByName.length>0)
    {
      if(selectedInterests.length>0||selectedLanguage.length>0||selectedTravellers.length>0)
      {
        filteredProviders=filteredProviders.filter
        (n=>(n.user.first_name+" "+n.user.last_name).toLowerCase().search(findByName.toLowerCase())!=-1)
      }
      else{
        filteredProviders=providerResults.filter
        (n=>(n.user.first_name+" "+n.user.last_name).toLowerCase().search(findByName.toLowerCase())!=-1)
      }
    }

      
    setFilteredProv(filteredProviders)
    console.log("test",filteredProviders)
    window.scrollTo({
      top: 1300,
      behavior: "smooth"
    });
    
  };

  const handlePrice = event => {
    setSelectedPrice(event.target.value);
  };
  const handleNameChange = event => {
    setName(event.target.value);
  };
  var skiValue = "";
  var snowValue = "";
  const handleAbilityLevelChange = event => {
    event.target.value.forEach((item, index) => {
      if (item.toLowerCase().includes(SKI.toLowerCase())) {
        if (skiValue != item) {
          skiValue = item;
        }
      }
      if (item.toLowerCase().includes(SNOWBOARD.toLowerCase())) {
        if (snowValue != item) {
          snowValue = item;
        }
      }
    });
    if (snowValue && skiValue) {
      setAbilityLevel([snowValue, skiValue]);
    } else {
      if (skiValue) {
        setAbilityLevel([skiValue]);
      }
      if (snowValue) {
        setAbilityLevel([snowValue]);
      }
    }
  };

  const handleSkiAbilitLevelChange = event => {
    setSkiAbilityLevel(event.target.value);
  };

  const visibleAbilityLevel = event => {};

  const filteredCards = hosts.filter(host => {
    return (
      host.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
      /* containsAll(host.criteria, selectedCriteria) &&
      instructor.pricing
        .toLowerCase()
        .indexOf(selectedPricing.toLowerCase()) !== -1 &&
      containsAll(instructor.dates, selectedDates) &&
      (instructor.abilities.indexOf(selectedAbilityLevel) !== -1 ||
        selectedAbilityLevel === -1) &&
      (instructor.activities.indexOf(selectedActivityType) !== -1 ||
        selectedActivityType === "") &&
      containsAll(instructor.resorts, selectedResort) */
    );
  });

  const cardsOnPage = filteredCards.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage
  );


  return (
    <div className={classes.main}>
      <Grid container className={classes.filter}>
        <ThemeProvider theme={theme} />
        {/* <Grid container item xs={12}> */}
        {/* <Grid container item xs={12} md={12} justify="center">
            <Grid container item xs={12} md={6}>
              <Grid item xs={12} md={2} className={classes.filterLabels}>
                <Typography variant="body1" className={classes.prompt}>
                  Results for:
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <FormControl
                  variant="outlined"
                  color="primary"
                  classes={{ root: classes.selectRootText }}
                >
                  <TextField
                    disabled
                    id="filled-disabled"
                    className={classes.inputTextStyleDefault}
                    InputProps={{
                      readOnly: true
                    }}
                    variant="filled"
                    value={props.selectedCountry}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={5}>
                <FormControl
                  variant="outlined"
                  color="primary"
                  classes={{ root: classes.selectRootText }}
                >
                  <TextField
                    disabled
                    id="filled-disabled"
                    className={classes.inputTextStyleDefault}
                    InputProps={{
                      readOnly: true
                    }}
                    variant="filled"
                    value={props.selectedResort}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid> */}
        {/* <Grid container item xs={12} md={12} justify="center">
            <Grid container item xs={12} md={6}>
              <Grid item xs={12} md={2} classes={{ root: classes.hideDiv }} />
              <Grid item xs={12} md={5}>
                <FormControl
                  variant="outlined"
                  color="primary"
                  classes={{ root: classes.selectRootText }}
                >
                  <TextField
                    disabled
                    id="filled-disabled"
                    className={classes.inputTextStyleDefault}
                    InputProps={{
                      readOnly: true
                    }}
                    variant="filled"
                    value={props.selectedStartDate}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={5}>
                <FormControl
                  variant="outlined"
                  color="primary"
                  classes={{ root: classes.selectRootText }}
                >
                  <TextField
                    disabled
                    id="filled-disabled"
                    className={classes.inputTextStyleDefault}
                    InputProps={{
                      readOnly: true
                    }}
                    variant="filled"
                    value={props.selectedEndDate}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
        <Grid container item xs={12}>
          <Grid container item xs={12} md={12} justify="center">
            <Grid container item xs={12} sm={6} md={6}>
              <Grid item xs={12} md={2} className={classes.filterLabels}>
                <Typography variant="body1" className={classes.prompt}>
                  Filter:
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <FormControl
                  variant="outlined"
                  color="primary"
                  classes={{ root: classes.selectRoot }}
                >
                  <InputLabel classes={{ root: classes.label }}>
                    Interested in
                  </InputLabel>
                  <Select
                    multiple
                    value={selectedInterests}
                    onChange={handleInterests}
                    renderValue={selectedInterests =>
                      selectedInterests.join(",")
                    }
                    className={classes.select2}
                    classes={{
                      selectMenu: classes.selectMenu,
                      root: classes.selectRoot,
                      icon: classes.selectIcon,
                      selected: classes.selected
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}
                  >
                    {servicesData &&
                      servicesData.map(service =>
                        service.header ? (
                          <ListSubheader
                            classes={{ root: classes.subHeaderRoot }}
                            disableSticky
                          >
                            {service.category_name ==
                            SERVICES_NAMES.VIRTUAL_LESSONS
                              ? service.category_name + "*"
                              : service.category_name ==
                                SERVICES_NAMES.CHILD_MINDING
                              ? service.category_name + "**"
                              : service.category_name}
                          </ListSubheader>
                        ) : (
                          <MenuItem
                            key={service.id}
                            value={
                              service.category_name ==
                              SERVICES_NAMES.FIRST_TIMER
                                ? FIRST_TIMER_VALUE.VALUE
                                : service.service_name
                            }
                            classes={{ root: classes.listRoot }}
                          >
                            <Checkbox
                              // onChange={event => handleInterests(event)}
                              checked={
                                selectedInterests.indexOf(
                                  service.service_name ==
                                    FIRST_TIMER_API_VALUE.VALUE
                                    ? FIRST_TIMER_VALUE.VALUE
                                    : service.service_name
                                ) > -1
                              }
                              className={
                                service.category_name ==
                                SERVICES_NAMES.FIRST_TIMER
                                  ? [classes.root, classes.customCheckBox]
                                  : classes.root
                              }
                              disableRipple
                              color="default"
                              checkedIcon={
                                <span
                                  className={clsx(
                                    classes.icon,
                                    classes.checkedIcon
                                  )}
                                />
                              }
                              icon={<span className={classes.icon} />}
                              inputProps={{
                                "aria-label": "decorative checkbox"
                              }}
                            />
                            <ListItemText
                              primary={
                                service.category_name ==
                                SERVICES_NAMES.FIRST_TIMER ? (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: service.service_name
                                    }}
                                  />
                                ) : (
                                  service.service_name
                                )
                              }
                              className={classes.listitem}
                              classes={{ primary: classes.primary }}
                            />
                          </MenuItem>
                        )
                      )}

                    <ListSubheader
                      classes={{ root: classes.subHeaderRoot2 }}
                      disableSticky
                    >
                      * requires an instruction qualification check
                    </ListSubheader>
                    <ListSubheader
                      classes={{ root: classes.subHeaderRoot2 }}
                      disableSticky
                    >
                      ** requires a Working with Children’s certificate check
                    </ListSubheader>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={5}>
                <FormControl
                  variant="outlined"
                  color="primary"
                  classes={{ root: classes.selectRoot }}
                  disabled={!isAbilityLevelVisible}
                >
                  <InputLabel classes={{ root: classes.label }}>
                    Your Ability Level
                  </InputLabel>
                  <Select
                    multiple
                    value={selectedAbilityLevel}
                    onChange={handleAbilityLevelChange}
                    renderValue={selectedAbilityLevel =>
                      selectedAbilityLevel.join(",")
                    }
                    className={classes.select2}
                    classes={{
                      selectMenu: classes.selectMenu,
                      root: classes.selectRoot,
                      icon: classes.selectIcon,
                      selected: classes.selected
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}
                  >
                    <ListSubheader
                      classes={{ root: classes.subHeaderRoot }}
                      disableSticky
                    >
                      SKIING
                    </ListSubheader>
                    {skiAbilityLevel.map(skiingAbilityLevel => (
                      <MenuItem
                        key={skiingAbilityLevel.id}
                        value={skiingAbilityLevel.key}
                        disabled={visibilitySkiing}
                        classes={{ root: classes.listRoot }}
                      >
                        <Radio
                          checked={selectedAbilityLevel.includes(
                            skiingAbilityLevel.key
                          )}
                          value={skiingAbilityLevel.key}
                          // inputProps={{ "aria-label": "C" }}
                          disableRipple
                          color="primary"
                          className={classes.root}
                        />
                        <ListItemText
                          primary={skiingAbilityLevel.level}
                          className={classes.listitem}
                          classes={{ primary: classes.primary }}
                        />
                      </MenuItem>
                    ))}

                    <ListSubheader
                      classes={{ root: classes.subHeaderRoot }}
                      disableSticky
                    >
                      SNOWBOARDING
                    </ListSubheader>
                    {snowBoardAbilityLevel.map(snowBoardingAbilityLevel => (
                      <MenuItem
                        disabled={visibilitySnowBoarding}
                        key={snowBoardingAbilityLevel.id}
                        value={snowBoardingAbilityLevel.key}
                        classes={{ root: classes.listRoot }}
                      >
                        <Radio
                          checked={selectedAbilityLevel.includes(
                            snowBoardingAbilityLevel.key
                          )}
                          value={snowBoardingAbilityLevel.key}
                          // inputProps={{ "aria-label": "C" }}
                          disableRipple
                          color="primary"
                          className={classes.root}
                        />
                        <ListItemText
                          primary={snowBoardingAbilityLevel.level}
                          className={classes.listitem}
                          classes={{ primary: classes.primary }}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12} md={12} justify="center">
            <Grid container item xs={12} sm={6} md={6}>
              <Grid item xs={12} md={2} classes={{ root: classes.hideDiv }} />
              <Grid item xs={12} md={5}>
                <FormControl
                  variant="outlined"
                  color="primary"
                  classes={{ root: classes.selectRoot }}
                >
                  <InputLabel classes={{ root: classes.label }}>
                    Your Language
                  </InputLabel>
                  <Select
                    multiple
                    value={selectedLanguage}
                    onChange={handleLanguage}
                    renderValue={selected => selected.join(", ")}
                    className={classes.select2}
                    classes={{
                      selectMenu: classes.selectMenu,
                      root: classes.selectRoot,
                      icon: classes.selectIcon,
                      selected: classes.selected
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}
                  >
                    {language.map(lang => (
                      <MenuItem key={lang.id} value={lang.language}>
                        <Checkbox
                          className={classes.root}
                          disableRipple
                          color="default"
                          checkedIcon={
                            <span
                              className={clsx(
                                classes.icon,
                                classes.checkedIcon
                              )}
                            />
                          }
                          checked={selectedLanguage.indexOf(lang.language) > -1}
                          icon={<span className={classes.icon} />}
                          inputProps={{ "aria-label": "decorative checkbox" }}
                        />
                        <ListItemText primary={lang.language} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={5}>
                <FormControl
                  variant="outlined"
                  color="primary"
                  classes={{ root: classes.selectRoot }}
                >
                  <InputLabel classes={{ root: classes.label }}>
                    Suitable for
                  </InputLabel>
                  <Select
                    multiple
                    value={selectedTravellers}
                    onChange={handleTravellers}
                    renderValue={selectedTravellers =>
                      selectedTravellers.join(", ")
                    }
                    className={classes.select2}
                    classes={{
                      selectMenu: classes.selectMenu,
                      root: classes.selectRoot,
                      icon: classes.selectIcon,
                      selected: classes.selected
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}
                  >
                    {suitableFor.map(expert => (
                      <MenuItem key={expert.id} value={expert.suitable_for}>
                        <Checkbox
                          className={classes.root}
                          disableRipple
                          color="default"
                          checkedIcon={
                            <span
                              className={clsx(
                                classes.icon,
                                classes.checkedIcon
                              )}
                            />
                          }
                          checked={
                            selectedTravellers.indexOf(expert.suitable_for) > -1
                          }
                          icon={<span className={classes.icon} />}
                          inputProps={{ "aria-label": "decorative checkbox" }}
                        />
                        <ListItemText primary={expert.suitable_for} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12} md={12} justify="center">
            <Grid container item xs={12} sm={6} md={6}>
              <Grid item xs={12} md={2} classes={{ root: classes.hideDiv }} />
              <Grid item xs={12} md={5}>
                <FormControl
                  variant="outlined"
                  color="primary"
                  classes={{ root: classes.selectRoot }}
                >
                  <TextField
                    className={classes.inputTextStyle}
                    id="outlined-basic"
                    label="Search by host/guide name..."
                    variant="outlined"
                    value={findByName}
                    maxRows={1}
                    onChange={handleNameChange}
                  />
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} md={5}>
                <FormControl
                  variant="outlined"
                  color="primary"
                  classes={{ root: classes.selectRoot }}
                >
                  <InputLabel classes={{ root: classes.label }}>
                    Price
                  </InputLabel>
                  <Select
                    multiple
                    value={selectedPrice}
                    onChange={handlePrice}
                    renderValue={selectedPrice => selectedPrice.join(", ")}
                    className={classes.select2}
                    classes={{
                      selectMenu: classes.selectMenu,
                      root: classes.selectRoot,
                      icon: classes.selectIcon,
                      selected: classes.selected
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}
                  >
                    {pricing.map(price => (
                      <MenuItem key={price} value={price}>
                        <Checkbox
                          className={classes.root}
                          disableRipple
                          color="default"
                          checkedIcon={
                            <span
                              className={clsx(
                                classes.icon,
                                classes.checkedIcon
                              )}
                            />
                          }
                          checked={selectedPrice.indexOf(price) > -1}
                          icon={<span className={classes.icon} />}
                          inputProps={{ "aria-label": "decorative checkbox" }}
                        />
                        <ListItemText primary={price} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}
            </Grid>
          </Grid>

          <Grid container item xs={12} md={12} justify="center">
            <Grid container item xs={12} sm={6} md={6}>
              <Grid item xs={12} md={2} classes={{ root: classes.hideDiv }} />
              <Grid item xs={12} md={5} classes={{ root: classes.hideDiv }} />
              <Grid item xs={12} md={5}>
                <Button
                  variant="contained"
                  onClick={handleSearch}
                  className={classes.button}
                  classes={{ label: classes.buttonLabel }}
                >
                  Update Search
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        
        {/* {isNoProvider? */}

        {/* display sample provider */}
        
        <Grid
                container
                direction="column"
                justifyContent="center"
                alignContent="center"
              >
        <Grid container style={{
          rowGap: 20,
          columnGap: 20,
          maxWidth: 700,
          margin: "20px 0 116px 0",
        }} justifyContent="center" alignContent="center" direction="row" alignItems="stretch" >
        <ProviderCard provider={sampleProvider} />
        </Grid>
        </Grid>

        {providerResults.length > 0?filteredProv.length > 0 && selectedCountry && selectedResort ?(
            // if results exist
            <Collapse
              in={checked}
              className={classes.collapseView}
              style={{ overflow: "hidden" }}
            >
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignContent="center"
              >
                <Grid container className={classes.providerCardList} justifyContent="center" alignContent="center" direction="row" alignItems="stretch" >
                  {filteredProv.map(provider => (
                    <ProviderCard provider={provider} />
                  ))}

                  {filteredProv > 6 && (
                    <Grid container item justify="center">
                      <Button>see more results</Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Collapse>):

            isNoProvider && filteredProv.length==0&&selectedInterests.length>0?
            (<Collapse
              in={checked}
              className={classes.collapseView}
              style={{ overflow: "hidden" }}
            >
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignContent="center"
              >
              <Typography variant="h4" className={classes.noResultMessage}>
              <b>No providers for selected filters but following are some other providers. </b>
              </Typography>

                <Grid container className={classes.providerCardList} justifyContent="center" alignContent="center" direction="row" alignItems="stretch" >
                  {providerResults.map(provider => (
                    <ProviderCard provider={provider} />
                  ))}

                  {providerResults > 6 && (
                    <Grid container item justify="center">
                      <Button>see more results</Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Collapse>):
            isNoProvider && filteredProv.length==0&&selectedLanguage.length>0||selectedTravellers.length>0?
            (<Collapse
              in={checked}
              className={classes.collapseView}
              style={{ overflow: "hidden" }}
            >
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignContent="center"
              >
              <Typography variant="h4" className={classes.noResultMessage}>
              <b>No results found. </b>
              </Typography>

                
                
              </Grid>
            </Collapse>):

            (<Collapse
              in={checked}
              className={classes.collapseView}
              style={{ overflow: "hidden" }}
            >
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignContent="center"
              >
              

                <Grid container className={classes.providerCardList} justifyContent="center" alignContent="center" direction="row" alignItems="stretch" >
                  {providerResults.map(provider => (
                    <ProviderCard provider={provider} />
                  ))}

                  {providerResults > 6 && (
                    <Grid container item justify="center">
                      <Button>see more results</Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Collapse>)
            :
            (<Grid 
              container
              direction="column"
              justify="center"
              className={classes.noProviders}
              >
              {isTablet.width < 960 ? ( 
              <>
              <Typography variant="h4" className={classes.noResultMessage}>
              <b>No providers yet at</b>
              </Typography>
              <br />
              <Typography variant="h5" className={classes.noResultMessage}>
              {props.selectedResort}
              </Typography>
              <br />
             </>
            ) : (
            <>
              <Typography variant="h4" className={classes.noResultMessage}>
                <b>No providers yet at</b> {props.selectedResort}
              </Typography>
              <br />
            </>
            )}
          </Grid>)
          }



        {/* <Grid item xs={12} justify="center">
            <Typography variant="body1" className={classes.prompt}>
              Filter:
            </Typography>
          </Grid> */}
        {/* <Grid item xs={12} md={6} justify="center" alignItems="center">
            <FormControl
              variant="outlined"
              color="primary"
              classes={{ root: classes.selectRoot }}
            >
              <InputLabel classes={{ root: classes.label }}>
                Language
              </InputLabel>
              <Select
                multiple
                value={selectedLanguage}
                onChange={handleLanguage}
                renderValue={selected => selected.join(", ")}
                className={classes.select2}
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot,
                  icon: classes.selectIcon,
                  selected: classes.selected
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              >
                {language.map(lang => (
                  <MenuItem key={lang} value={lang}>
                    <Checkbox
                      className={classes.root}
                      disableRipple
                      color="default"
                      checkedIcon={
                        <span
                          className={clsx(classes.icon, classes.checkedIcon)}
                        />
                      }
                      checked={selectedLanguage.indexOf(lang) > -1}
                      icon={<span className={classes.icon} />}
                      inputProps={{ "aria-label": "decorative checkbox" }}
                    />
                    <ListItemText primary={lang} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} justify="center" alignItems="center">
            <FormControl
              variant="outlined"
              color="primary"
              classes={{ root: classes.selectRoot }}
            >
              <InputLabel classes={{ root: classes.label }}>
                Suitable for
              </InputLabel>
              <Select
                multiple
                value={selectedTravellers}
                onChange={handleTravellers}
                renderValue={selectedTravellers =>
                  selectedTravellers.join(", ")
                }
                className={classes.select2}
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot,
                  icon: classes.selectIcon,
                  selected: classes.selected
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              >
                {experienceLevel.map(expert => (
                  <MenuItem key={expert} value={expert}>
                    <Checkbox
                      className={classes.root}
                      disableRipple
                      color="default"
                      checkedIcon={
                        <span
                          className={clsx(classes.icon, classes.checkedIcon)}
                        />
                      }
                      checked={selectedTravellers.indexOf(expert) > -1}
                      icon={<span className={classes.icon} />}
                      inputProps={{ "aria-label": "decorative checkbox" }}
                    />
                    <ListItemText primary={expert} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}
        {/* <RadioGroup
                      name="snowboarding"
                      value={snowBoardingAbilityLevel}
                      classes={{ root: classes.listRoot }}
                      // onChange={handleSkiAbilitLevelChange}
                    >
                      {snowBoardAbilityLevel.map(snowBoardingAbilityLevel => (
                        <FormControlLabel
                          value={snowBoardingAbilityLevel}
                          control={
                            <Radio
                              disableRipple
                              color="primary"
                              className={classes.root}
                            />
                          }
                          label={snowBoardingAbilityLevel}
                        />
                      ))}
                    </RadioGroup> */}
        {/* <RadioGroup
                      name="skiing"
                      // value={skiAbilityLevel}
                      classes={{ root: classes.listRoot }}
                      // onChange={handleSkiAbilitLevelChange}
                      // onChange={handleAbilityLevelChange}
                    >
                      {skiAbilityLevel.map(skiAbilityLevel => (
                        <FormControlLabel
                          key={skiAbilityLevel}
                          value={skiAbilityLevel}
                          onChange={handleAbilityLevelChange}
                          control={
                            <Radio
                              disableRipple
                              color="primary"
                              className={classes.root}
                            />
                          }
                          label={skiAbilityLevel}
                        />
                      ))}
                    </RadioGroup> */}

        {/* <ThemeProvider theme={theme}>
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            item
            sm={10}
            xs={10}
          >
            <Grid
              container
              item
              justify="center"
              alignItems="center"
              md={1}
              sm={12}
              xs={12}
            >
              <Typography variant="body1" className={classes.prompt}>
                Filter:
              </Typography>
            </Grid>
            <FormControl
              variant="outlined"
              color="secondary"
              className={classes.select1}
            >
              <InputLabel className={classes.label}>Language</InputLabel>
              <Select
                multiple
                value={selectedLanguage}
                onChange={handleLanguage}
                className={classes.inputSelect}
                renderValue={selected => selected.join(", ")}
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot,
                  icon: classes.selectIcon,
                  selected: classes.selected
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              >
                {language.map(lang => (
                  <MenuItem key={lang} value={lang}>
                    <Checkbox checked={selectedLanguage.indexOf(lang) > -1} />
                    <ListItemText primary={lang} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              color="secondary"
              className={classes.select2}
            >
              <InputLabel className={classes.label}>
                Number of travellers
              </InputLabel>
              <Select
                value={selectedTravellers}
                onClick={handleTravellers}
                className={classes.inputSelect}
                renderValue={selected => selected}
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot,
                  icon: classes.selectIcon
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              >
                {numberOfTravellers.map(num => (
                  <MenuItem key={num} value={num}>
                    <ListItemText primary={num} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              color="secondary"
              className={classes.select3}
            >
              <InputLabel className={classes.label}>Suitable for</InputLabel>
              <Select
                value={selectedExperience}
                onChange={handleExperience}
                className={classes.inputSelect}
                renderValue={selected => selected}
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot,
                  icon: classes.selectIcon
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              >
                {experienceLevel.map(exp => (
                  <MenuItem key={exp} value={exp}>
                    <ListItemText
                      primary={exp}
                      classes={{ primary: classes.primaryOfSuitable }}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              color="secondary"
              className={classes.select4}
            >
              <InputLabel className={classes.label}>
                Interested in...
              </InputLabel>
              <Select
                multiple
                value={selectedInterests}
                onChange={handleInterests}
                renderValue={selected => selected.join(", ")}
                className={classes.inputSelect}
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot,
                  icon: classes.selectIcon
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  root: {
                    maxHeight: "30em"
                  },
                  getContentAnchorEl: null
                }}
              >
                <ListSubheader
                  classes={{ root: classes.subHeaderRoot }}
                  disableSticky
                >
                  PRE-ARRIVAL INFO
                </ListSubheader>
                <ListSubheader
                  classes={{ root: classes.subHeaderRoot }}
                  disableSticky
                >
                  General Concierge
                </ListSubheader>
                {generalConcierge.map(interest => (
                  <MenuItem
                    key={interest}
                    value={interest}
                    classes={{ root: classes.listRoot }}
                  >
                    <Checkbox
                      checked={selectedInterests.indexOf(interest) > -1}
                      size="small"
                    />
                    <ListItemText
                      primary={interest}
                      className={classes.listitem}
                      classes={{ primary: classes.primary }}
                    />
                  </MenuItem>
                ))}
                <ListSubheader
                  classes={{ root: classes.subHeaderRoot }}
                  disableSticky
                >
                  POST-ARRIVAL
                </ListSubheader>
                <ListSubheader
                  classes={{ root: classes.subHeaderRoot }}
                  disableSticky
                >
                  General Assistance
                </ListSubheader>
                {generalAssistance.map(interest => (
                  <MenuItem
                    key={interest}
                    value={interest}
                    classes={{ root: classes.listRoot }}
                  >
                    <Checkbox
                      checked={selectedInterests.indexOf(interest) > -1}
                      color="primary"
                      size="small"
                    />
                    <ListItemText
                      primary={interest}
                      className={classes.listitem}
                      classes={{ primary: classes.primary }}
                    />
                  </MenuItem>
                ))}
                <ListSubheader
                  classes={{ root: classes.subHeaderRoot }}
                  disableSticky
                >
                  Activities
                </ListSubheader>
                {activities.map(interest => (
                  <MenuItem
                    key={interest}
                    value={interest}
                    classes={{ root: classes.listRoot }}
                  >
                    <Checkbox
                      checked={selectedInterests.indexOf(interest) > -1}
                      size="small"
                    />
                    <ListItemText
                      primary={interest}
                      className={classes.listitem}
                      classes={{ primary: classes.primary }}
                    />
                  </MenuItem>
                ))}
                <ListSubheader
                  classes={{ root: classes.subHeaderRoot }}
                  disableSticky
                >
                  Tours
                </ListSubheader>
                {tours.map(interest => (
                  <MenuItem
                    key={interest}
                    value={interest}
                    classes={{ root: classes.listRoot }}
                  >
                    <Checkbox
                      checked={selectedInterests.indexOf(interest) > -1}
                      size="small"
                    />
                    <ListItemText
                      primary={interest}
                      className={classes.listitem}
                      classes={{ primary: classes.primary }}
                    />
                  </MenuItem>
                ))}
                <ListSubheader
                  classes={{ root: classes.subHeaderRoot }}
                  disableSticky
                >
                  Child-minding Services
                </ListSubheader>
                <ListSubheader
                  classes={{ root: classes.subHeaderRoot2 }}
                  disableSticky
                >
                  All child-minding hosts have Working With Children
                </ListSubheader>
                <ListSubheader
                  classes={{ root: classes.subHeaderRoot2 }}
                  disableSticky
                >
                  certificates
                </ListSubheader>
                {childMindingServices.map(interest => (
                  <MenuItem
                    key={interest}
                    value={interest}
                    classes={{ root: classes.listRoot }}
                  >
                    <Checkbox
                      checked={selectedInterests.indexOf(interest) > -1}
                      size="small"
                    />
                    <ListItemText
                      primary={interest}
                      className={classes.listitem}
                      classes={{ primary: classes.primary }}
                    />
                  </MenuItem>
                ))}
                <ListSubheader
                  classes={{ root: classes.subHeaderRoot }}
                  disableSticky
                >
                  EXPERTISE/INTERESTS
                </ListSubheader>
                {expertiseInterests.map(interest => (
                  <MenuItem
                    key={interest}
                    value={interest}
                    classes={{ root: classes.listRoot }}
                  >
                    <Checkbox
                      checked={selectedInterests.indexOf(interest) > -1}
                      size="small"
                    />
                    <ListItemText
                      primary={interest}
                      className={classes.listitem}
                      classes={{ primary: classes.primary }}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              variant="outlined"
              color="secondary"
              className={classes.select5}
            >
              <InputLabel className={classes.label}>Adv. filter</InputLabel>
              <Select
                // TODO fix select crashing app when clicking the subheader section of the menu
                value={selectedPrice}
                onChange={handlePrice}
                renderValue={selected => selected}
                className={classes.inputSelect}
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot,
                  icon: classes.selectIcon
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  root: {
                    maxHeight: "30em"
                  },
                  getContentAnchorEl: null
                }}
              >
                <ListSubheader classes={{ root: classes.subHeaderRoot }}>
                  PRICING
                </ListSubheader>
                {pricing.map(price => (
                  <MenuItem key={price} value={price}>
                    <Checkbox checked={selectedPrice.indexOf(price) > -1} />
                    <ListItemText primary={price} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}

        {/* search card input box */}
        {/* <Grid
            container
            justify="flex-start"
            alignItems="center"
            item
            sm={9}
            xs={9}
          >
            <Grid
              container
              item
              justify="flex-end"
              alignItems="flex-start"
              md={1}
              sm={12}
              xs={12}
            >
              <Typography variant="body1" className={classes.bodytext}>
                Or:
              </Typography>
            </Grid>

            <TextField
              id="search"
              label="Search by host/guide name"
              type="search"
              color="secondary"
              onChange={handleSearch}
              value={search}
              className={classes.search}
              classes={{
                root: classes.searchRoot
              }}
              InputProps={{
                className: classes.searchInput
              }}
              InputLabelProps={{
                className: classes.searchLabel
              }}
              margin="normal"
              variant="outlined"
            />
          </Grid> */}

        {/*host cards whole section */}
        {/* <Grid
            container
            justify="center"
            spacing={10}
            className={classes.cards}
          > */}
        {/* listed cards */}
        {/* <Grid container item xs={10} md={8} spacing={4}>
              {cardsOnPage.map(card => (
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  item
                  key={card}
                  lg={4}
                  md={6}
                  sm={6}
                  xs={12}
                >
                  <HostCard name={card} onClick={toggleHostLike} {...card} />
                </Grid>
              ))}
            </Grid> */}

        {/* card pagination */}
        {/* <Grid
              container
              justify="center"
              alignItems="flex-start"
              item
              sm={12}
              xs={12}
            >
              <Grid item xs={6}>
                <CardPagination
                  count={filteredCards.length}
                  setPage={setPage}
                  setRowsPerPage={setRowsPerPage}
                />
              </Grid>
            </Grid> */}
        {/* </Grid> */}
        {/* </ThemeProvider> */}
      </Grid>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    hosts: state.exploreHostsReducer.fakeHostData,
    language: state.exploreHostsReducer.language,
    numberOfTravellers: state.exploreHostsReducer.numberOfTravellers,
    suitableFor: state.exploreHostsReducer.suitableFor,
    generalConcierge: state.exploreHostsReducer.generalConcierge,
    generalAssistance: state.exploreHostsReducer.generalAssistance,
    activities: state.exploreHostsReducer.activities,
    tours: state.exploreHostsReducer.tours,
    childMindingServices: state.exploreHostsReducer.childMindingServices,
    expertiseInterests: state.exploreHostsReducer.expertiseInterests,
    pricing: state.exploreHostsReducer.pricing,
    vistualLessons: state.exploreHostsReducer.vistualLessons,
    snowBoardAbilityLevel: state.exploreHostsReducer.snowBoardAbilityLevel,
    skiAbilityLevel: state.exploreHostsReducer.skiAbilityLevel,
    serviceData: state.exploreHostsReducer.serviceData
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getLanguageData: () => dispatch(getLanguageData()),
    getSuitableForData: () => dispatch(getSuitableForData()),
    getServiceData: () => dispatch(getServiceData()),
    toggleHostLike: hostId => dispatch(toggleHostLike(hostId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentFilter);
