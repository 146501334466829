import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  main: {
    height: "18em",
    background: "#d1e3ef",
    alignContent: "center"
  },
  text: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "28px",
    lineHeight: "140.62%",
    textAlign: "center",
    color: "#000000"
  },
  button: {
    width: "173px",
    height: "50px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "140.62%",
    textAlign: "center",
    letterSpacing: "0.01em",
    color: "#ffffff",
    background: "#ff6161",
    borderRadius: "100px",
    border: "none"
  }
});

const SignUpSection = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.main}>
      <Grid item container justify="center">
        <Button href="/signup" className={classes.button} onClick={() => {}}>
          Sign up
        </Button>
      </Grid>
    </Grid>
  );
};

export default SignUpSection;
