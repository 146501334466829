import { LOAD_PROFILE } from "./action-types";
import { createMaster } from "./tripWhoActions";

export function submitProfileContents(request) {
  return function(dispatch) {
    // const { countryCode, ...remainder } = request;
    return dispatch(createMaster(request));

    // return axios
    //   .post(baseURL + apiRoot, {
    //     ...remainder,
    //     Country: countryCode.name,
    //     PhoneAreaCode: countryCode.dial_code
    //   })
    //   .then(response => {
    //     if (response.status === 201 || response.status === 200) {
    //       dispatch({ type: MEMBER_ID_RETRIEVED, payload: response.data });
    //       dispatch(createMaster(request));
    //     }
    //   })
    //   .catch(() => {});
  };
}

export const load = data => ({ type: LOAD_PROFILE, data });
