import React from "react";

// CSS
import styles from "./StarRating.module.css";
import classNames from "classnames";
import { Grid } from "@material-ui/core";

const StarRating = props => {
  const { currentRating } = props;
  const rating = Math.floor(currentRating);
  const maxRating = 5.0;

  const renderStars = () => {
    let stars = [];

    // Below runs a loop through the state to figure out how many stars to display
    for (let counter = 0; counter < rating; counter++) {
      stars.push(
        <i
          className={classNames("fas fa-star rating-stars", styles.ratingStars)}
          key={counter}
        />
      );
    }

    // The below checks if the current rating is less than max. If so then it prints empty stars
    if (currentRating < maxRating) {
      for (let counter = rating; counter < maxRating; counter++) {
        stars.push(
          <i
            className={classNames("far fa-star", styles.ratingStars)}
            key={counter}
          />
        );
      }
    }

    return <div>{stars}</div>;
  };

  return (
    <Grid container direction="row" item className={styles.ratingStarContainer}>
      <Grid item>
        <p>Rating: </p>
      </Grid>
      <Grid item>{renderStars()}</Grid>
    </Grid>
    // <div className={styles.ratingStarContainer}>
    //   <p> Rating: </p> {renderStars()}
    // </div>
  );
};

export default StarRating;
