import React from "react";
import useStyles from "./MyAccountStyles";
import { Button, Grid } from "@material-ui/core";

const Bio = ({ studentData, _editBio }) => {
  const classes = useStyles();

  return (
    <Grid item container md={12} direction="row">
      <Grid item lg={2} md={2} sm={2} xs={2}>
        <div align="right" className={classes.textFieldStyle}>
          Bio:
        </div>
      </Grid>
      <Grid item lg={1} md={1} sm={1} xs={1} />
      <Grid item lg={9} md={9} sm={9} xs={9}>
        <div align="left">
          <input
            fullWidth
            id="cardname"
            variant="filled"
            className={classes.textFieldBio}
            size="small"
            onChange={event => _editBio(event.target.value)}
            value={studentData.bio}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Bio;
