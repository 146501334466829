import React, { Fragment, useEffect, useState } from "react";

// Redux
import { Field, reduxForm } from "redux-form";
import Store from "../../../../../redux/store";
import { connect } from "react-redux";
import {
  incrementActiveStep,
  decrementActiveStep,
  incrementActiveStepHost,
  decrementActiveStepHost,
  setProviderServices
} from "../../../../../redux/actions/stepperActions.js";
import { getServiceData } from "../../../../../redux/actions/exploreHostsActions.js";

// material ui
import { List, Grid, Button, Divider } from "@material-ui/core";

import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowRight as ArrowRightIcon,
  AcUnit as AcUnitIcon
} from "@material-ui/icons";

import { renderNumberField, LabelError } from "../../../FormField/FormField.js";

import useStyles from "./ServicesStyles";

import FirstTimerAssistance from "./First-Timer-Assistance/FirstTimerAssistance";
import ChildEntertainment from "./Child-Entertainment/ChildEntertainment";
import Tours from "./Tours/Tours";
import Activities from "./Activities/Activities";
import VirtualLessons from "./Virtual-Lessons/VirtualLessons";
import _ from "lodash";
import { set } from "date-fns";
import { SERVICES_NAMES } from '../../../../../constants/common';

/**
 * Offered Services & Pricing Context Update:
 * A user wants to click the service name to expand the view to see more info
 * A user only can add a service with all info provided. (etc. mas_group_size, price_per_head...)
 * Give a error message if one of the required input fields is blank 
 */

let ProviderServices = props => {
  const { setUpdatedServices } = props;
  useEffect(() => {
    props.getServiceData();
  }, []);
  const classes = useStyles();
  const { serviceData } = props
  const provider_id = props.providerId;
  const [servicesData, setServicesData] = React.useState([]);
  const [servicesDataObject, setServicesDataObject] = React.useState([]);
  const [servicesID, setServiceIDs] = React.useState([]);
  let [firstTimerAssistance, setFirstTimerAssistance] = React.useState({});
  let [skiTourSlopes, setSkiTourSlopes] = React.useState({});
  let [snowBoardTourSlopes, setSnowBoardTourSlopes] = React.useState({});
  let [tourVillage, setTourVillage] = React.useState({});
  let [backCountry, setBackCountry] = React.useState({});
  let [hiking, setHiking] = React.useState({});
  let [skiBackCountryTouring, setSkiBackCountryTouring] = React.useState({});
  let [snowBackCountryTouring, setSnowBackCountryTouring] = React.useState({});
  let [snowShoeingTouring, setSnowShoeing] = React.useState({});
  let [snowMobilingTouring, setSnowMobilingTouring] = React.useState({});
  let [bikeRiding, setBikeRiding] = React.useState({});
  let [flyFishing, setFlyFishing] = React.useState({});
  let [skiing, setSkiing] = React.useState({});
  let [snowBoarding, setSnowBoarding] = React.useState({});
  let [yogaPilates, setYogaPilates] = React.useState({});
  let [onSlopSnowBoarding, setonSlopSnowBoarding] = React.useState({});
  let [onSlopSkiing, setonSlopSkiing] = React.useState({});
  let [offSlope, setoffSlop] = React.useState({});
  let [servicesObject, setServiceObject] = React.useState({});
  let [lessonCredential, setLessonCredential] = React.useState("");
  let [childServiceCredential, setChildServiceCredential] = React.useState("");
  let [driverLicense, setDriverLicense] = React.useState("");
  const [serviceChanged, setServiceChanged] = useState(false);
  // get provider's offered services from props that passed from my profile page
  let [providedServices, setProvidedService] = React.useState([]);
  useEffect(() => {
    if (props.providedServices !== undefined && props.providedServices){
      setProvidedService(props.providedServices);
      let servicesIDtemp = [];
      props.providedServices.forEach(service => {
        servicesIDtemp.push(service.service_id);
      });
      setServiceIDs(servicesIDtemp);
    }
  }, [props.providedServices]);

  useEffect(() => {
    var categoryNameArray = [];
    var objectData = {};
    var objectArray = [];
    var index = 0;
    serviceData &&
      serviceData.forEach(service => {
        if (!categoryNameArray.includes(service.catagory_name)) {
          categoryNameArray.push(service.catagory_name);
          objectData.category_name = service.catagory_name;
          objectData.id = "";
          objectData.header = true;
          index = objectArray.length;
          objectArray[index] = { ...objectData };
        }
        objectData.category_name = service.catagory_name;
        objectData.id = service.id;
        objectData.service_name = service.service_name;
        objectData.header = false;
        index = objectArray.length;
        objectArray[index] = { ...objectData };
      });
    objectArray.splice(6, 0, {
      category_name: "CHILD MINDING SERVICES",
      id: "SpecialNeed",
      header: false,
      service_name: "Special needs care experience"
    });
    objectArray.splice(7, 0, {
      category_name: "CHILD MINDING SERVICES",
      id: "DriveCar",
      header: false,
      service_name: "Can drive a car"
    });
    objectArray.splice(8, 0, {
      category_name: "CHILD MINDING SERVICES",
      id: "DriveOwnCar",
      header: false,
      service_name: "Can drive own car"
    });
    objectArray.splice(9, 0, {
      category_name: "CHILD MINDING SERVICES",
      id: "DogWalk",
      header: false,
      service_name: "Dog walking"
    });
    setServicesData(objectArray);
  }, [serviceData]);

  useEffect(() => {
    setServicesDataObject(
      _.chain(servicesData)
        // Group the elements of Array based on `color` property
        .groupBy("category_name")
        // `key` is group's name (color), `value` is the array of objects
        .map((value, key) => ({ key: key, services: value }))
        .value()
    );
  }, [servicesData]);

  const handleBackButton = event => {
    props.decrementActiveStep(decrementActiveStep());
  };

  const addServiceIDs = (isAdded, id) => {
    if(isAdded){
      let servicesIDtemp = [...servicesID, id];
      setServiceIDs([...new Set(servicesIDtemp)]);
    } else {
      let servicesIDtemp= [...servicesID].filter(item => item !== id);
      setServiceIDs(servicesIDtemp);
    }
  }

  //#region Notify services data change
  const addFirstTimerAssistance = value => {
    const updatedService = updateSingleFieldService(firstTimerAssistance, value, 1, SERVICES_NAMES.FIRST_TIMER);
    setFirstTimerAssistance(updatedService);
    setServiceChanged(true);
  };

  const addSkiToursSlopeValues = (key, value) => {
    const updatedService = updateMultiFieldsService(skiTourSlopes, key, value, 2, SERVICES_NAMES.TOURING);
    setSkiTourSlopes(updatedService);
    setServiceChanged(true);
  };

  const addSnowBoardToursSlopeValues = (key, value) => {
    const updatedService = updateMultiFieldsService(snowBoardTourSlopes, key, value, 3, SERVICES_NAMES.TOURING);
    setSnowBoardTourSlopes(updatedService);
    setServiceChanged(true);
  };

  const addTourVillageValues = (key, value) => {
    const updatedService = updateMultiFieldsService(tourVillage, key, value, 4, SERVICES_NAMES.TOURING);
    setTourVillage(updatedService);
    setServiceChanged(true);
  };

  const addBackCountryValues = (key, value) => {
    const updatedService = updateMultiFieldsService(backCountry, key, value, 5, SERVICES_NAMES.TOURING);
    setBackCountry(updatedService);
    setServiceChanged(true);
  };

  const addHikingValues = (key, value) => {
    const updatedService = updateMultiFieldsService(hiking, key, value, 6, SERVICES_NAMES.TOURING);
    setHiking(updatedService);
    setServiceChanged(true);
  };

  const addSkiBackCountryTouring = (key, value) => {
    const updatedService = updateMultiFieldsService(skiBackCountryTouring, key, value, 7, SERVICES_NAMES.TOURING);
    setSkiBackCountryTouring(updatedService);
    setServiceChanged(true);
  };


  const addSnowBackCountryTouring = (key, value) => {
    const updatedService = updateMultiFieldsService(snowBackCountryTouring, key, value, 8, SERVICES_NAMES.TOURING);
    setSnowBackCountryTouring(updatedService);
    setServiceChanged(true);
  };


  const addSnowShoeingTouring = (key, value) => {
    const updatedService = updateMultiFieldsService(snowShoeingTouring, key, value, 9, SERVICES_NAMES.TOURING);
    setSnowShoeing(updatedService);
    setServiceChanged(true);
  };

  const addSnowMobilingTouring = (key, value) => {
    const updatedService = updateMultiFieldsService(snowMobilingTouring, key, value, 10, SERVICES_NAMES.TOURING);
    setSnowMobilingTouring(updatedService);
    setServiceChanged(true);
  };

  const addBikeRiding = (key, value) => {
    const updatedService = updateMultiFieldsService(bikeRiding, key, value, 11, SERVICES_NAMES.TOURING);
    setBikeRiding(updatedService);
    setServiceChanged(true);
  };

  const addFlyFishing = (key, value) => {
    const updatedService = updateMultiFieldsService(flyFishing, key, value, 12, SERVICES_NAMES.ACTIVITY);
    setFlyFishing(updatedService);
    setServiceChanged(true);
  };

  const addYogaPilates = (key, value) => {
    const updatedService = updateMultiFieldsService(yogaPilates, key, value, value, 13, SERVICES_NAMES.ACTIVITY);
    setYogaPilates(updatedService);
    setServiceChanged(true);
  };

  const addSkiing = value => {
    const updatedService = updateSingleFieldService(skiing, value, 14, SERVICES_NAMES.VIRTUAL_LESSONS);
    setSkiing(updatedService);
    setServiceChanged(true);
  };
 
  const addSnowboarding = value => {
    const updatedService = updateSingleFieldService(snowBoarding, value, 15, SERVICES_NAMES.VIRTUAL_LESSONS);
    setSnowBoarding(updatedService);
    setServiceChanged(true);
  };

  const addOnSlopSnowBoarding = (key, value) => {
    const updatedService = updateMultiFieldsService(onSlopSnowBoarding, key, value, 16, "CHILD MINDING HOSTS");
    updatedService["service_name"] = "On slope - snowboarding";
    setonSlopSnowBoarding(updatedService);

    if(typeof value === "boolean"){
      const temp = addSpecialRequirements({...onSlopSnowBoarding}, key, value, 16, "CHILD MINDING HOSTS");
      setonSlopSnowBoarding(temp);
    }
    setServiceChanged(true);
  };

  const addOnSlopSkiing = (key, value) => {
    const updatedService = updateMultiFieldsService(onSlopSkiing, key, value, 17, "CHILD MINDING HOSTS");
    updatedService["service_name"] = "On slope - skiing";
    setonSlopSkiing(updatedService);

    if(typeof value === "boolean"){
      const temp = addSpecialRequirements({...onSlopSkiing}, key, value, 17, "CHILD MINDING HOSTS");
      setonSlopSkiing(temp);
    }
    setServiceChanged(true);
  };
  
  const addOffSlope = (key, value) => {
    const updatedService = updateMultiFieldsService(offSlope, key, value, 18, "CHILD MINDING HOSTS");
    updatedService["service_name"] = "Off slope (general babysitting)";
    setoffSlop(updatedService);

    if(typeof value === "boolean"){
      const temp = addSpecialRequirements({...offSlope}, key, value);
      setoffSlop(temp);
    }
    setServiceChanged(true);
  };

  const addSpecialRequirements = (serviceObject, key, value) => {
    serviceObject[key] = value;
    return serviceObject;
  }
  //#endregion
  const updateMultiFieldsService = (serviceOjb, key, value, id, catagory_name) => {
    if(serviceOjb){
      serviceOjb = objectOperation(serviceOjb, key, value);
    } else {
      const obj = {};
      serviceOjb = objectOperation(obj, key, value);
    }
    serviceOjb["provider_id"] = provider_id;
    // When the service is new provided service
    if(!Object.hasOwn(serviceOjb, "provider_service_id")){
      serviceOjb["service_id"] = id;
      serviceOjb['catagory_name'] = catagory_name;
      serviceOjb['provider_service_id'] = null;
      serviceOjb['service_detail_id'] = null;
      if (typeof value === "object") {
        if(catagory_name === "CHILD MINDING HOSTS"){
          serviceOjb["driver_license_link"] = null;
          serviceOjb["child_working_credential_link"] = value;
        }
      }
    }
    return serviceOjb;
  }

  const updateSingleFieldService = (serviceObj, value, id, catagory_name) => {
    const temp = {...serviceObj};
    if(Object.keys(temp).length > 0 && value > 0) {
      temp["provider_id"] = provider_id;
      temp["price_per_hour"] = parseInt(value);
    } else {
      // When the service is new provided service
      temp["provider_id"] = provider_id;
      temp["service_id"] = id;
      temp["catagory_name"] = catagory_name;
      temp['provider_service_id'] = null;
      temp['service_detail_id'] = null;
      if (typeof value === "object") {
        if(catagory_name === SERVICES_NAMES.VIRTUAL_LESSONS){
          temp['credential_link'] = value;
        }
      } else {
        temp["price_per_hour"] = parseInt(value);
      }
    }
    return temp;
  }

  // collect the key-value pair of service details, etc, key: additional_guests_price, value: 200
  const objectOperation = (object, key, value) => {
    if (value != 0) {
      if ("provider_id" in object) {
        typeof value === "object" ? Object.assign(object, { [key]: value }) : Object.assign(object, { [key]: parseInt(value) });
      } else {
        Object.assign(object, { ["provider_id"]: provider_id });
        typeof value === "object" ? Object.assign(object, { [key]: value }) : Object.assign(object, { [key]: parseInt(value) });
      }
    } else {
      if (Object.keys(object).length > 0) {
        delete object[key];
        if ("provider_id" in object && Object.keys(object).length == 1) {
          delete object["provider_id"];
        }
      } else {
        object = {};
      }
    }
    return object; // return a formatted service detail obj, etc, { "provider_id": 97, "additional_guests_price": 200 }
  };

  const handleSubmit = () => {
    if (Object.keys(servicesID).length > 0) {
      console.log("SERVICES ID: ", servicesID);
      console.log("Services Object: ", servicesObject);

      // First timer assistance service
      Object.keys(firstTimerAssistance).length > 0 &&
      Object.assign(servicesObject, {
        ["first_timer_assistance_service"]: firstTimerAssistance
      });
      
      // Touring hosts services
      Object.keys(skiTourSlopes).length > 0 &&
        Object.assign(servicesObject, { ["ski_tour_service"]: skiTourSlopes });

      Object.keys(snowBoardTourSlopes).length > 0 &&
        Object.assign(servicesObject, {
          ["snowboard_tour_service"]: snowBoardTourSlopes
        });
      Object.keys(tourVillage).length > 0 &&
        Object.assign(servicesObject, {
          ["walking_tour_service"]: tourVillage
        });
      Object.keys(backCountry).length > 0 &&
        Object.assign(servicesObject, {
          ["ebike_touring_service"]: backCountry
        });
      Object.keys(hiking).length > 0 &&
        Object.assign(servicesObject, {
          ["hiking_service"]: hiking
        });
      Object.keys(skiBackCountryTouring).length > 0 &&
        Object.assign(servicesObject, {
          ["ski_backcountry_service"]: skiBackCountryTouring
        });
      Object.keys(snowBackCountryTouring).length > 0 &&
        Object.assign(servicesObject, {
          ["snowboarding_backcountry_service"]: snowBackCountryTouring
        });
      Object.keys(snowShoeingTouring).length > 0 &&
        Object.assign(servicesObject, {
          ["snowshoeing_service"]: snowShoeingTouring
        });
      Object.keys(snowMobilingTouring).length > 0 &&
        Object.assign(servicesObject, {
          ["snowmobiling_service"]: snowMobilingTouring
        });
      Object.keys(bikeRiding).length > 0 &&
        Object.assign(servicesObject, {
          ["bike_riding_service"]: bikeRiding
        });

      // Activity Hosts services
      Object.keys(flyFishing).length > 0 &&
        Object.assign(servicesObject, {
          ["fly_fishing_service"]: flyFishing
        });
      Object.keys(yogaPilates).length > 0 &&
        Object.assign(servicesObject, {
          ["yoga_pilates_service"]: yogaPilates
        });

      //Virtual Lessons services
      Object.keys(skiing).length > 0 &&
        Object.assign(servicesObject, {
          ["skiing_service"]: skiing
        });

      Object.keys(snowBoarding).length > 0 &&
        Object.assign(servicesObject, {
          ["snowboarding_service"]: snowBoarding
        });
    
      if(Object.keys(onSlopSnowBoarding).length > 0){
        servicesObject["on_slope_snowboarding_service"] = onSlopSnowBoarding;
      }
      if(Object.keys(onSlopSkiing).length > 0){
        servicesObject["on_slope_skiing_service"] = onSlopSkiing;
      }
      if(Object.keys(offSlope).length > 0){
        servicesObject["off_slope_service"] = offSlope;
      }

      Object.values(servicesObject).forEach(service => {
        const result = servicesID.indexOf(service.service_id)
        if(result !== -1){
          if(service["price_per_hour"]){
            service["is_provided"] = true;
          }
          if(service["max_group_size"] && service["price_per_head"] && service["additional_guests_price"]){
            service["is_provided"] = true;
          }
          if(service["max_group_size"] && service["price_per_head"] && service["additional_children_price"]){
            service["is_provided"] = true;
          }
        }else{
          service["is_provided"] = false;
        }
      });
      const servicesData = { services: Object.values(servicesObject) }
      setUpdatedServices(servicesData);
      setServiceChanged(false);
    }
  };

  useEffect(() => {
    handleSubmit()
  }, [serviceChanged, servicesID])

  return (
    <form>
      {/* <div align={props.alignItems ? props.alignItems : " flex-start"}>
        {props.fromHostIntroSection ? null : (
          <h1
            style={{
              fontFamily: "PT Sans Narrow",
              fontSize: "24px",
              fontWeight: "normal"
            }}
          >
            {" "}
            Offered Services & Pricing{" "}
          </h1>
        )} */}
      <Grid container justify="center" style={{ backgroundColor: "white" }}>
        <Grid item align="flex-start" lg={12} md={12} sm={12} xs={12}>
          <h1
            style={{
              fontFamily: "PT Sans Narrow",
              fontSize: "24px",
              fontWeight: "normal",
              paddingLeft: "1em"
            }}
          >
            {" "}
            Offered Services & Pricing{" "}
          </h1>
        </Grid>
        <Grid item align="center" lg={12} md={12} sm={12} xs={12}>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.text}
          >
            <FirstTimerAssistance
              selectService={addServiceIDs}
              addFirstTimerAssistance={addFirstTimerAssistance}
              services={providedServices}
              isDisabled={props.isDisabled}
              setFirstTimerAssistance={setFirstTimerAssistance}
            />
            <Divider />

            <Tours
              selectService={addServiceIDs}
              addSkiToursSlopeValues={addSkiToursSlopeValues}
              addSnowBoardToursSlopeValues={addSnowBoardToursSlopeValues}
              addTourVillageValues={addTourVillageValues}
              addBackCountryValues={addBackCountryValues}
              addHikingValues={addHikingValues}
              addSkiBackCountryTouring={addSkiBackCountryTouring}
              addSnowBackCountryTouring={addSnowBackCountryTouring}
              addSnowShoeingTouring={addSnowShoeingTouring}
              addSnowMobilingTouring={addSnowMobilingTouring}
              addBikeRiding={addBikeRiding}

              services={providedServices}
              isDisabled={props.isDisabled}
              setSkiTourSlopes={setSkiTourSlopes}
              setSnowBoardTourSlopes={setSnowBoardTourSlopes}
              setTourVillage={setTourVillage}
              setBackCountry={setBackCountry}
              setHiking={setHiking}
              setSkiBackCountryTouringState={setSkiBackCountryTouring}
              setSnowBackCountryTouringState={setSnowBackCountryTouring}
              setSnowShoeing={setSnowShoeing}
              setSnowMobilingTouring={setSnowMobilingTouring}
              setBikeRiding={setBikeRiding}
            />
            <Divider />
            <Activities
              selectService={addServiceIDs}
              addYogaPilates={addYogaPilates}
              addFlyFishing={addFlyFishing}

              services={providedServices}
              isDisabled={props.isDisabled}
              setYogaPilates={setYogaPilates}
              setFlyFishing={setFlyFishing}
            />
            <Divider />
            <VirtualLessons
              selectService={addServiceIDs}
              addSkiing={addSkiing}
              addSnowboarding={addSnowboarding}

              services={providedServices}
              isDisabled={props.isDisabled}
              setSkiing={setSkiing}
              setSnowBoarding={setSnowBoarding}
              setLessonCredential={setLessonCredential}
            />
            <Divider />
            <ChildEntertainment
              selectService={addServiceIDs}
              addOnSlopSnowBoarding={addOnSlopSnowBoarding}
              addOnSlopSkiing={addOnSlopSkiing}
              addOffSlope={addOffSlope}

              services={providedServices}
              isDisabled={props.isDisabled}
              setoffSlop={setoffSlop}
              setonSlopSkiing={setonSlopSkiing}
              setonSlopSnowBoarding={setonSlopSnowBoarding}
              setChildServiceCredential={setChildServiceCredential}
              setDriverLicense={setDriverLicense}
            />
            <Divider />
          </List>
        </Grid>
      </Grid>

      {props.fromMyProfilePage ? null : (
        <Grid
          container
          justify="center"
          alignItems="flex-start"
          spacing={2}
          direction="row"
        >
          {props.fromHostIntroSection ? null : (
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <div className={classes.buttonContainer}>
                  {props.activeStep < 1 ? null : (
                    <Button
                      className={classes.backButton}
                      classes={{ label: classes.backButtonLabel }}
                      variant="outlined"
                      // style={{color:"#FF6161"}}
                      onClick={handleBackButton}
                    >
                      Back
                    </Button>
                  )}
                </div>
              </Grid>
              <Grid item>
                <Button
                  // type="submit"
                  className={classes.nextButton}
                  classes={{
                    root: classes.buttonRoot,
                    label: classes.buttonLabel
                  }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      {/* </div> */}
    </form>
  );
};

function validate(values) {
  let errors = {};

  // if (!values.numberOfMembers) {
  //     errors.numberOfMembers = "Required";
  // }

  // if (!values.oneHourlyRate) {
  //     errors.oneHourlyRate = "Required";
  // }

  // if (!values.twoHourlyRate) {
  //     errors.twoHourlyRate = "Required";
  // }

  return errors;
}

// destroyOnUnmount - saves it in state
ProviderServices = reduxForm({
  form: "signup",
  destroyOnUnmount: false,
  validate
})(ProviderServices);

function mapDispatchToProps(dispatch) {
  return {
    incrementActiveStep: () => dispatch(incrementActiveStep()),
    decrementActiveStep: () => dispatch(decrementActiveStep()),
    incrementActiveStepHost: () => dispatch(incrementActiveStepHost()),
    decrementActiveStepHost: () => dispatch(decrementActiveStepHost()),
    getServiceData: () => dispatch(getServiceData()),
    setProviderServices: (provider_id, services) =>
      dispatch(setProviderServices(provider_id, services))
  };
}

function mapStateToProps(state, ownProps) {
  return {
    activeStep: state.stepperReducer.activeStep,
    activeStepHost: state.stepperReducer.activeStepHost,
    serviceData: state.exploreHostsReducer.serviceData,
    createProviderServices: state.stepperReducer.createProviderServices
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderServices);
