import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import StarRating from "../../../components/Common/StarRating/StarRating.js";
import "react-calendar-timeline/lib/Timeline.css";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  arrowIcon: {
    transform: "rotate(180deg)"
  },
  root: {
    margin: "0 auto",
    width: "100%",
    marginTop: "2rem",
    alignItems: "center"
  },
  backButton: {
    margin: "3em",
    borderColor: "#FF6161",
    color: "#FF6161"
  },
  avatarSize: {
    width: "10rem",
    height: "10rem",
    marginBottom: "15%",
    [theme.breakpoints.down("sm")]: {
      width: "10rem",
      height: "10rem"
    }
  },
  editProfileBtn: {
    borderRadius: 100,
    background: "#FF6161",
    width: "70%",
    fontSize: 14,
    lineHeight: "140.62%",
    backgroundColor: "#FF6161",
    color: "white",
    fontWeight: "normal",
    letterSpacing: "0.01em",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "40%"
    }
  },
  textName: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#1277B8",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      marginTop: "2rem",
      textAlign: "center"
    }
  },
  viewUpcommingBtn: {
    background: "#FF6161",
    width: "70%",
    fontSize: 14,
    lineHeight: "140.62%",
    backgroundColor: "#FF6161",
    color: "white",
    fontWeight: "normal",
    letterSpacing: "0.01em",
    borderRadius: 100,
    [theme.breakpoints.down("sm")]: {
      width: "40%",
      marginTop: "1rem",
      marginBottom: "1rem"
    }
  },
  textFieldFormat: {
    color: "#000000",
    width: "100%",
    height: "9%",
    fontSize: 14,
    resize: "none",
    border: "0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "1rem",
      marginBottom: "2rem",
      color: "#000000"
    }
  },
  divAvatar: {
    textAlign: "center",
    margin: "0 auto"
  },
  ratingFormat: {
    textAlign: "center",
    margin: "0 auto",
    width: "fit-content"
  },
  commonFormat: {
    fontStyle: "normal",
    fontFamily: "PT Sans Narrow"
  }
}));

function StudentMyProfile(props) {
  const classes = useStyles();
  const { name, bio, rating, avatar, isStudent } = props;

  return (
    <Grid container className={classes.root}>
      {/* This Grid Container has picture + edit profile button */}
      <Grid container item spacing={1} md={3} lg={3}>
        {/* This Grid Item will wrap picture  */}
        <Grid container item xs={12} spacing={2} md={12} lg={12}>
          <div className={classes.divAvatar}>
            <Avatar className={classes.avatarSize} src={avatar} />
          </div>
        </Grid>

        {/* This Grid Item will wrap edit profile  */}
        {isStudent ? (
          <Grid item xs={12} md={12} lg={12} spacing={2}>
            <div className={classes.divAvatar}>
              <Button
                className={classNames(
                  classes.editProfileBtn,
                  classes.commonFormat
                )}
                color="primary"
                variant="contained"
              >
                Edit Profile
              </Button>
            </div>
          </Grid>
        ) : null}
      </Grid>

      {/* This Grid Container will wrap the right section of the page (Name + upcoing vide Button + Rating) */}
      <Grid container item spacing={2} md={9} lg={9}>
        <Grid container item spacing={1} xs={12}>
          {/* This Grid Item will wrap Name  */}
          <Grid item xs={12} spacing={2} md={4} lg={2}>
            <div>
              <Typography className={classes.textName}>
                {name.firstName + " " + name.lastName}
              </Typography>
            </div>
          </Grid>

          {/* This Grid Item will wrap Upcoming video button  */}
          <Grid item xs={12} spacing={2} md={4} lg={4}>
            <div className={classes.divAvatar}>
              <Button
                className={classNames(
                  classes.viewUpcommingBtn,
                  classes.commonFormat
                )}
                color="primary"
                variant="contained"
              >
                {isStudent
                  ? "View upcoming lessons"
                  : `Message ${name.firstName}`}
              </Button>
            </div>
          </Grid>

          {/* This Grid Item will wrap Rating  */}
          <Grid item xs={12} spacing={2} md={4} lg={6}>
            <div className={classes.ratingFormat}>
              <StarRating currentRating={rating} />
            </div>
          </Grid>
        </Grid>
        {/* This Grid Item will wrap Bio  */}
        <Grid item xs={12} spacing={2} lg={10}>
          <p
            className={classNames(
              classes.textFieldFormat,
              classes.commonFormat
            )}
          >
            {bio}
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default StudentMyProfile;
