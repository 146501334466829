import React from "react";
import TablePagination from "@material-ui/core/TablePagination";

export default function CardPagination(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    props.setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    props.setPage(0);
  };

  return (
    <TablePagination
      component="div"
      count={props.count}
      page={page}
      onChangePage={handleChangePage}
      rowsPerPage={rowsPerPage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
}
