import React, { useEffect, useRef, useState } from "react";

// Redux
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { sendInstructorSpecialityValues } from "../../../redux/actions/virtInstructorDataActions";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { SportDropdownMenu } from "./SportDropdownMenu";

let SportSpecialFilter = props => {
  // speciality & resort will eventually move into the DB.
  // Will need to add setX to resort and speciality once data comes from DB.
  let [sportSpeciality, setSportSpeciality] = useState([
    "SKI",
    "SNOWBOARD",
    "TELEMARK",
    "CROSS COUNTRY"
  ]);
  let [sportSpecialityList, setSportSpecialityList] = useState([
    [
      "Teenagers",
      "3-5 Year olds",
      "Kids",
      "Moguls",
      "Terrain Park",
      "Race",
      "Backcountry Touring"
    ],
    [
      "Teenagers",
      "3-5 Year olds",
      "Kids",
      "Moguls",
      "Terrain Park",
      "Race",
      "Backcountry Touring"
    ],
    ["Backcountry Touring"],
    ["Classic", "Skate"]
  ]);

  return (
    <Grid>
      <Field
        name="specialisations"
        component={SportDropdownMenu}
        variant="primary"
        sendSelectedValues={props.sendSpecialtyValues}
        headings={sportSpeciality}
        subheadingLists={sportSpecialityList}
      />
    </Grid>
  );
};

// destroyOnUnmount - saves it in state
function mapDispatchToProps(dispatch) {
  return {
    sendSpecialtyValues: values =>
      dispatch(sendInstructorSpecialityValues(values))
  };
}

function mapStateToProps(state) {
  return {
    specialties: state.virtualInstructorReducer.specialties
  };
}

SportSpecialFilter = connect(
  mapStateToProps,
  mapDispatchToProps
)(SportSpecialFilter);

export default reduxForm({
  form: "signup",
  destroyOnUnmount: false
})(SportSpecialFilter);
