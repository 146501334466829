// Dependencies
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// Redux
import Store from "../../../redux/store"
import {
  getServiceData
} from "../../../redux/actions/exploreHostsActions";
import {
  getCountryList
} from "../../../redux/actions/homePageAction";

// APIs
import agent from "../../../agent.js"

// Material UI Components
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Hidden,
  Button, Collapse, IconButton
} from "@material-ui/core/";

// Project Components
import Header from "../../../components/Common/Home/HeaderSection.js";
import ProviderCard from "../../../components/serviceMarket/home/ProviderCard/ProviderCard.js";
import ContentFilter from "../../../components/exploreHosts/home/ContentFilter";
import BackgroundImg from "../../../materials/HomePage/background_landing.jpg";
import croppedBackgroundImg from "../../../materials/HomePage/cropped_background_landing.jpg";
import downArrow from "../../../materials/HomePage/down_arrow.png";

// CSS
import { COLOR, FONTFAMILY } from "../../../styling/theme/Theme";

// Constants
const BACKGROUND_SIZE = "60vh";

const useStyles = makeStyles(theme => ({
  background: {
    minHeight: BACKGROUND_SIZE,
    backgroundImage: `url(${BackgroundImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100vh",
    width: "100%",
    backgroundPosition: "fixed",
    alignContent: "center",
    [theme.breakpoints.down("sm")]: {
      backgroundPosition: "80%"
    },
    [theme.breakpoints.down("xs")]: {
      backgroundImage: `url(${croppedBackgroundImg})`,
      backgroundPosition: "35%"
    }
  },
  collapseView: {
    overflow: "hidden",
    width: "inherit",
    "& .MuiCollapse-wrapper": {
      display: "flex",
      margin: "1rem 0rem"
    }
  },
  downIconButtonGrid: {
    position: "absolute",
    paddingBottom: "1rem",
    textAlign: "center",
    bottom: 0,
    left: 0,
    right: 0,
    [theme.breakpoints.down("xs")]: {
      // 600px
      bottom: "-400px"
    },
    "@media (max-width: 420px)": {
      //iphone 6/7/8 plus
      bottom: "-31rem"
    },
    "@media (max-width: 375px)": {
      //iphone 6/7/8
      bottom: "-30.5rem"
    },
    "@media (max-width: 360px)": {
      //galaxy s3
      bottom: "-30.5rem"
    },
    "@media (max-width: 320px)": {
      //iphone 4
      bottom: "-35.5rem"
    }
  },
  filter: {
    margin: "1rem 0rem"
  },
  iconbutton: {
    backgroundColor: COLOR.PRIMARY,
    height: "2rem",
    width: "2rem",

    "&:focus": {
      // outline: "none"
    },
    "&:hover": {
      background: COLOR.PRIMARY,
      borderRadius: "1rem",
      zIndex: 10000
    }
  },
  logo: {
    height: "2rem",
    width: "2rem"
  },
  noProviders: {
    textAlign: "center",
    margin: "2rem 0rem",
    backgroundColor: COLOR.BACKGROUND_SECONDARY,
    padding: "1rem 0rem"
  },
  noResultMessage: {
    fontFamily: FONTFAMILY.FONT_SANS_NARROW,
    textAlign: "center",
    fontStyle: "normal",
    fontSize: "26px"
  },
  providerCardList: {
    rowGap: 20,
    columnGap: 20,
    maxWidth: 700,
    margin: "90px 0 116px 0",
  },
  results: {
    [theme.breakpoints.down("xs")]: { // screen < 600px AS we haven't implement the filter TODO: update in future
      marginTop: -100
    }
  },
  searchCardPush: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "27rem"
    },
    [theme.breakpoints.down("xs")]: {
      //600px
      marginBottom: "27rem"
    },
    "@media (max-width: 375px)": {
      //iphone 6/7/8
      marginBottom: "28em"
    },
    "@media (max-width: 360px)": {
      //galaxy s3
      marginBottom: "28rem"
    },
    "@media (max-width: 320px)": {
      //iphone 4
      marginBottom: "32rem"
    }
  },
  searchCardPushXS: {
    // border: '1px solid red',
    marginBottom: "27rem",
    "@media (max-width: 415px)": {
      //iphone 6/7/8 plus & Nexus 6
      // border: "1px solid blue",
      marginBottom: "25em"
    },
    "@media (max-width: 384px)": {
      //Nexus 4
      // border: "1px solid blue",
      marginBottom: "28rem"
    }
  },
  textField: {
    width: "10em",
    height: "1em",
    border: "1px solid red",
    searchCardContainer: {
      [theme.breakpoints.down("xs")]: {
        border: "1px solid red",
        margin: "0rem 1rem"
      },
      [theme.breakpoints.down("sm")]: {
        margin: "0rem 1rem"
      }
    }
  },
}));

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    window.addEventListener("resize", handleResize);

    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}

/** This is the home page of BUILD: ServiceMarket
 * 
 * @param {*} props 
 * @see /src/pages/exploreHosts/home/ExploreHosts.jsx as reference and origin
 */
const Home = props => {
  const { serviceData } = props;
  const classes = useStyles();

  const [checked, setChecked] = React.useState(false);
  const [servicesData, setServicesData] = useState([]);
  const [selectedCountry, selectCountry] = React.useState();
  const [selectedResort, selectResort] = React.useState();
  const [selectedStartDate, selectStartDate] = React.useState();
  const [selectedEndDate, selectEndDate] = React.useState();
  const [providerResults, setProviderResults] = useState({});
  const [selectedResortID, setSelectedResortID] = useState();
  const [isNoProvider, setIsNoProvider] = React.useState(false);

  const slogan = "Browse and book Experienced Hosts local to the resort";
  const underSlogan =
    "...just for YOURSELF, your FAMILY, the KIDS, or for a group of FRIENDS";
  const lookingFor = false;

  const isTablet = useWindowSize();

  useEffect(() => {
    var categoryNameArray = [];
    var objectData = {};
    var objectArray = [];
    var index = 0;

    serviceData &&
      serviceData.forEach(service => {
        if (!categoryNameArray.includes(service.catagory_name)) {
          categoryNameArray.push(service.catagory_name);
          objectData.category_name = service.catagory_name;
          objectData.id = "";
          objectData.header = true;
          index = objectArray.length;
          objectArray[index] = { ...objectData };
        }
        objectData.category_name = service.catagory_name;
        objectData.id = service.id;
        objectData.service_name = service.service_name;
        objectData.header = false;
        index = objectArray.length;
        objectArray[index] = { ...objectData };
      });
    setServicesData(objectArray);
  }, [serviceData]);

  useEffect(() => {
    props.getCountryList();
    props.getServiceData();
  }, []);

  

  const handleCountry = countryValue => {
    selectCountry(countryValue);
    setIsNoProvider(false)
    
  };
  const handleResort = resortValue => {
    selectResort(resortValue);
  };
  const handleStartDate = startDateValue => {
    selectStartDate(startDateValue);
  };
  const handleEndDate = endDateValue => {
    selectEndDate(endDateValue);
  };

  const handleChange = val => {
    
    setChecked(!checked);
  };

  const handleOpen = val => {
    
    if (selectedResortID!=null){
      // The async function to search the providers via the api
      console.log(selectedResortID)
      const fetchProviderResults = async () => {
        const res = await agent.Users.listProviders(selectedResortID);
        setProviderResults(res.data);
      }
  
    fetchProviderResults()
      // make sure to catch any error
      .catch(console.error);

    setChecked(val);
    }
  };

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
      >
        {/* PROVIDER SEARCH BAR SECTION */}
        <Grid
          container
          item
          className={
            isTablet.height <= 825 && isTablet.width <= 420
              ? classes.background + " " + classes.searchCardPushXS
              : classes.background + " " + classes.searchCardPush
          }
        >
          <Grid
            item
            xs={12}
            className={classes.searchCardContainer}
          >
            <Header
              slogan={slogan}
              underSlogan={underSlogan}
              lookingFor={lookingFor}
              center={true}
              searchCardBlue={true}
              onSelectCountry={handleCountry}
              onSelectResort={handleResort}
              onSelectStartDate={handleStartDate}
              onSelectEndDate={handleEndDate}
              onSearchClick={handleOpen}
              setSelectedResortID={setSelectedResortID}
            />
          </Grid>
        </Grid>
        
        {/* SEARCH RESULTS SECTION */}
        <Grid container className={classes.results}>

          {/* Hidden Bar */}
          {providerResults.length >= 0 && selectedCountry && selectedResort && (
            <Hidden smDown>
              <Grid
                item
                xs={12}
                className={classes.downIconButtonGrid}
                id="result"
              >
                <IconButton
                  onClick={handleChange}
                  style={{
                    transform: checked ? ["rotate(90deg)"] : ["rotate(270deg)"]
                  }}
                  className={classes.iconbutton}
                >
                  <img
                    src={downArrow}
                    className={classes.logo}
                    alt="down arrow"
                  />
                </IconButton>
              </Grid>
            </Hidden>
          )}        
       
          {/* Provider Results List */}
          {/* {providerResults.length > 0 && selectedCountry && selectedResort ? (
            // if results exist
            <Collapse
              in={checked}
              className={classes.collapseView}
              style={{ overflow: "hidden" }}
            >
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignContent="center"
              >
                <Grid container className={classes.providerCardList} justifyContent="center" alignContent="center" direction="row" alignItems="stretch" >
                  {providerResults.map(provider => (
                    <ProviderCard provider={provider} />
                  ))}

                  {providerResults > 6 && (
                    <Grid container item justify="center">
                      <Button>see more results</Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Collapse>
          ) : ( */}
            {/* // if no results */}
            <Collapse
              in={checked}
              className={classes.collapseView}
              style={{ overflow: "hidden" }}
            >
              {/* Further Filter */}
              <Grid
                container
                item
                className={classes.filter}
              >
                <ContentFilter
                  selectedCountry={selectedCountry}
                  selectedResort={selectedResort}
                  selectedStartDate={selectedStartDate}
                  selectedEndDate={selectedEndDate}
                  isNoProvider={isNoProvider}
                  selectedResortID={selectedResortID}
                  checked={checked}
                  // handleLanguage={setSelectedLanguage}
                  // handleTravellers={setSelectedTravellers}
                  // handleSuitableFor={setSelectedSuitability}
                  // handleActivities={setSelectedActivity}
                  // handleInterestedIn={setSelectedInterest}
                />
              </Grid>

              {/* Message of no results */}
              {/* <Grid
                container
                direction="column"
                justify="center"
                className={classes.noProviders}
              >
                {isTablet.width < 960 ? (
                <>
                  <Typography variant="h4" className={classes.noResultMessage}>
                    <b>No providers yet at</b>
                  </Typography>
                  <br />
                  <Typography variant="h5" className={classes.noResultMessage}>
                    {selectedResort}
                  </Typography>
                  <br />
                </>
                ) : (
                <>
                  <Typography variant="h4" className={classes.noResultMessage}>
                    <b>No providers yet at</b> {selectedResort}
                  </Typography>
                  <br />
                </>
                )}
              </Grid> */}
            </Collapse>
          

        </Grid>
      </Grid>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    getCountryList: () => dispatch(getCountryList()),
    getServiceData: () => dispatch(getServiceData())
  };
}

function mapStateToProps(state) {
  return {
    serviceData: state.exploreHostsReducer.serviceData
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);