import agent from "../../agent";
import { LOGIN, LOGOUT } from "./action-types";

export function userLogin(emailID, password) {
  return function(dispatch) {
    return agent.Users.login(emailID, password)
      .then(response => {
        if (response.status === 200) {
          dispatch({ type: LOGIN, payload: response });

          window.localStorage.setItem("user_id", response.data.user_id);
          window.localStorage.setItem("signup_step", response.data.signup_step);
          window.localStorage.setItem("is_provider", response.data.is_provider);
          window.localStorage.setItem("refresh", response.data.refresh);
          window.localStorage.setItem("access", response.data.access);
        }
      })
      .catch(error => {
        dispatch({ type: LOGIN, payload: error.response });
        console.log("error", error.response);
        // dispatch(alertSubmitForm(false));
      });
  };
}

// action when user logout
export const userLogout = () => dispatch => {
  window.localStorage.removeItem("user_id");
  window.localStorage.removeItem("signup_step");
  window.localStorage.removeItem("is_provider");
  window.localStorage.removeItem("refresh");
  window.localStorage.removeItem("access");
  dispatch({ type: LOGOUT });
};
