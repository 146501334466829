import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { Container } from "@material-ui/core";
import UpcomingVideoLesson from "../../../components/virtualLessons/VideoLesson/UpcomingVideoLesson";
import PastVideoLesson from "../../../components/virtualLessons/VideoLesson/PastVideoLesson";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import agent from "../../../agent";
import {
  displayPastLesson,
  displayUpcomingLesson
} from "../../../redux/actions/displayVideoLessonAction";

// for using the tab
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  tab: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "25px",
    lineHeight: "140.62%",
    color: "#1277B8",
    alignItems: "flex-start",
    textTransform: "capitalize",
    padding: 0
    //borderBottomStyle: "solid",
  },
  btn: {
    color: "red",
    borderColor: "red",
    textAlign: "center",
    margin: "2em"
  },
  font: {
    color: "#0B9AC7",
    fontFamily: "PT Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    margin: "1em"
  }
}));

const overrideStyles = makeStyles(
  {
    textColorInherit: {
      color: "black",
      opacity: 1,
      fontWeight: "normal",
      "&$selected": {
        color: "#1277B8",
        fontWeight: "bold"
      }
    },
    wrapper: {
      alignItems: "flex-start"
    },
    selected: {}
  },
  { name: "MuiTab" }
);

function VideoLesson(props) {
  const classes = useStyles();
  overrideStyles();
  const [value, setValue] = React.useState(0);
  const [currentUserType, setUserType] = React.useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Get the data from database if user login
  useEffect(() => {
    // ORIGIN: if (props.currentUser !== null) {
    // ORIGIN:   agent.VirtualLesson.getVirtualLesson(props.currentUser.id)
    if (true) {
      // TESTING: turn the check currentUser off
      agent.VirtualLesson.getVirtualLesson(1) // TESTING: turn the check currentUser off
        .then(res => {
          // ORIGIN: let responseData = res.data.lessons;
          // TESTING: use dummy data
          let responseData = [
            // past lessons
            {
              instructor_id: 1,
              FirstName: "chris",
              LastName: "scott",
              inv_url: "xyz",
              theOtherGuyUserID: 2,
              minPrice: 10.0,
              start: "2021-03-21: 11:13:24", // NOTE: date needs to be this format "YYYY-MM-DD"
              end: "2021-03-21: 23:11:11",
              status: "this is the status property"
            },
            {
              instructor_id: 1,
              FirstName: "jack",
              LastName: "weng",
              inv_url: "xyz",
              theOtherGuyUserID: 2,
              minPrice: 100.0,
              start: "2021-01-14: 8:00:00",
              end: "2021-01-14: 9:15:00",
              status: "this is the status property"
            },
            {
              instructor_id: 1,
              FirstName: "chris",
              LastName: "scott",
              inv_url: "xyz",
              theOtherGuyUserID: 2,
              minPrice: 10.0,
              start: "2020-08-10: 9:00:00",
              end: "2020-08-10: 10:00:00",
              status: "this is the status property"
            },
            // upcoming lessons
            {
              instructor_id: 1,
              FirstName: "slav",
              LastName: "s",
              inv_url: "xyz",
              theOtherGuyUserID: 2,
              minPrice: 10.0,
              start: "2021-11-11: 12:06:00",
              end: "2021-11-11: 18:00:00"
            },
            {
              instructor_id: 1,
              FirstName: "slav",
              LastName: "s",
              inv_url: "xyz",
              theOtherGuyUserID: 2,
              minPrice: 10.0,
              start: "2021-12-03: 12:15:00",
              end: "2021-12-03: 14:15:00"
            },
            {
              instructor_id: 1,
              FirstName: "thomas",
              LastName: "long-last-name",
              inv_url: "xyz",
              theOtherGuyUserID: 2,
              minPrice: 10.1,
              start: "2021-11-11: 00:00:00",
              end: "2021-11-11: 00:00:00"
            }
          ];

          setUserType("student");
          // filter the upcoming lessons by comparing the date
          let upcomingTemp = responseData
            .filter(booking => new Date(booking.start) > new Date())
            .map(datum => ({
              ...datum,
              id: datum.id,
              name: datum.FirstName + " " + datum.LastName,
              price: datum.MinPrice,
              start: new Date(datum.start),
              end: new Date(datum.end),
              status: datum.status,
              videoLink: datum.video_url,
              meetingLink: datum.inv_url
            }));
          // send the data to redux
          props.displayUpcomingLesson(upcomingTemp);
          // props.displayUpcomingLesson(responseData);

          //filter the past lessons by comparing the date
          let patTemp = res.data.lessons;
          //let pastTemp = patTemp
          let pastTemp = responseData
            .filter(booking => new Date(booking.start) < new Date())
            .map(datum => ({
              ...datum,
              id: datum.id,
              name: datum.FirstName + " " + datum.LastName,
              price: datum.minPrice,
              start: new Date(datum.start),
              end: new Date(datum.end),
              status: datum.status,
              videoLink: datum.video_url,
              meetingLink: datum.inv_url
            }));
          // send the data to redux
          props.displayPastLesson(pastTemp);
        })
        .catch(err => console.log(err));
    } else {
      console.log("no user ID yet");
    }
  }, [props.currentUser]);

  //get data from redux props to here
  const reduxUpcomingData = props.upcomingLessonData;
  const reduxPastData = props.pastLessonData;

  const waitForUpcomingData = responseData => {
    if (responseData.length !== undefined) {
      return responseData.map(responseData => (
        <UpcomingVideoLesson
          virtualbookingID={responseData.id}
          name={responseData.name}
          price={responseData.minPrice.toFixed(2)}
          start={responseData.start}
          end={responseData.end}
          status={responseData.status}
          videoLink={responseData.videoLink}
          messageLink={responseData.messageLink}
          meetingLink={responseData.meetingLink}
          theOtherGuyUserID={responseData.theOtherGuyUserID}
          userType={currentUserType}
        />
      ));
    } else {
      return (
        <Typography>
          {" "}
          Loading data / you Don't have any upcoming lessons / no login yet
        </Typography>
      );
    }
  };

  const waitForPastData = responseData => {
    if (responseData.length !== undefined) {
      return responseData.map(function(responseData) {
        return (
          <PastVideoLesson
            key={responseData.id}
            name={responseData.name}
            price={responseData.price}
            start={responseData.start}
            end={responseData.end}
            status={responseData.status}
          />
        );
      });
    } else {
      return (
        <Typography>
          {" "}
          Loading data / you Don't have any upcoming lessons / no login yet
        </Typography>
      );
    }
  };

  return (
    <div className={classes.root}>
      {/* Back button - the button has no function yet:*/}
      <Button
        variant="outlined"
        className={classes.btn}
        classes={{ root: classes.btnRoot, label: classes.btnLabel }}
      >
        Back
      </Button>

      {/* Container of main page */}
      <Grid
        container
        xs={12}
        direction="column"
        justify="flex-start"
        alignItems="center"
      >
        {/* Tab Bar */}
        <Grid container item xs={11}>
          <AppBar
            elevation={0} //clear the shadow
            className={classes.AppBar}
            position="static"
            color={"transparent"}
            style={{ paddingBottom: "32px" }}
          >
            {/* Two tabs*/}
            <Grid item xs={12} justify="center">
              <Tabs
                TabIndicatorProps={{
                  style: {
                    width: "47%",
                    backgroundColor: "#1277B8",
                    height: "5px"
                  }
                }}
                variant="fullWidth"
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                {/* Tab 1: upcoming lessons */}
                <Tab label="Upcoming Lesson" className={classes.tab} />

                {/* Tab 2: past lessons */}
                <Tab
                  label="Past Lesson"
                  className={classes.tab}
                  {...a11yProps(1)}
                />
                {/*<Tab label="Item Three" {...a11yProps(2)} />*/}
              </Tabs>
            </Grid>
          </AppBar>
        </Grid>

        {/* Tab Panel of upcoming lessones list */}
        <Grid item xs={11}>
          <TabPanel value={value} index={0}>
            {/* <Typography display="block" className={classes.font} gutterBottom>
              {currentUserType == "student" ? "INSTRUCTOR" : "STUDENT"}
            </Typography> */}
            {waitForUpcomingData(reduxUpcomingData)}
          </TabPanel>
        </Grid>

        {/* Tab Panel of past lessones list */}
        <Grid item xs={11}>
          <TabPanel value={value} index={1}>
            {/* <Typography display="block" className={classes.font} gutterBottom>
              {currentUserType == "student" ? "INSTRUCTOR" : "STUDENT"}
            </Typography> */}
            {waitForPastData(reduxPastData)}
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  );
}

// pass data to redux by dispatch
function mapDispatchToProps(dispatch) {
  return {
    displayUpcomingLesson: upcomingLessonData =>
      dispatch(displayUpcomingLesson(upcomingLessonData)),
    displayPastLesson: pastLessonData =>
      dispatch(displayPastLesson(pastLessonData))
  };
}

//get data from redux to here, read only
function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser,
    upcomingLessonData: state.displayVideoLessonReducer.upcomingLessonData,
    pastLessonData: state.displayVideoLessonReducer.pastLessonData
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoLesson);
