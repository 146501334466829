import {
  SAVE_IMAGE_URL_TO_STORE,
  SAVE_CERTIFICATE_URL_TO_STORE,
  STORE_IMAGE_UPLOAD_EVENT,
  STORE_QUALIFICATION_UPLOAD_EVENT
} from "./../actions/action-types";

const awsS3ReducerDefaultReducer = {
  imageURL: undefined,
  certificateURL: undefined,
  imageUploadEvent: undefined,
  qualificationUploadEvent: undefined
};

const awsS3Reducer = (state = awsS3ReducerDefaultReducer, action) => {
  switch (action.type) {
    case SAVE_IMAGE_URL_TO_STORE:
      console.log("action payload is ", action.payload);
      return {
        ...state,
        imageURL: action.payload
      };
    case SAVE_CERTIFICATE_URL_TO_STORE:
      return {
        ...state,
        certificateURL: action.payload
      };
    case STORE_IMAGE_UPLOAD_EVENT:
      return {
        ...state,
        imageUploadEvent: action.payload
      };
    case STORE_QUALIFICATION_UPLOAD_EVENT:
      return {
        ...state,
        qualificationUploadEvent: action.payload
      };
    default:
      return state;
  }
};

export default awsS3Reducer;
