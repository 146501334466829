import React from "react";
import useStyles from "./MyAccountStyles";
import { Button, Grid, TextField } from "@material-ui/core";

import ContactPage from "../../../pages/lessonsEnquiry/ContactPage";

const Contact = ({
  studentData,
  filterData,
  _editCountrycode,
  disabled,
  select
}) => {
  const classes = useStyles();

  return (
    <Grid item container md={12} direction="row">
      <Grid item lg={2} md={2} sm={2} xs={2}>
        <div align="right" className={classes.textFieldStyle}>
          Contact No:
        </div>
      </Grid>
      <Grid item lg={1} md={1} sm={1} xs={1} />
      <Grid item lg={1} md={2} sm={2} xs={2}>
        <div align="left" className={classes.textFieldStyleBlack}>
          Country:
        </div>
      </Grid>
      <Grid item lg={5} md={5} sm={5} xs={6}>
        <div align="left">
          <TextField
            id="standard-select-currency"
            variant="outlined"
            disabled={disabled}
            select={select}
            value={studentData.countrycode}
            onChange={event => {
              _editCountrycode(event.target.value);
            }}
            SelectProps={{
              native: true
            }}
            className={classes.textFieldCountry}
          >
            {filterData.countrycode.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}{" "}
              </option>
            ))}
          </TextField>
        </div>
      </Grid>
      <Grid item lg={3} md={2} sm={2} xs={1} />
    </Grid>
  );
};

export default Contact;
