import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { connect } from "react-redux";

class InstructorReviewCarousel extends React.Component {
  state = {
    currentIndex: 0,
    responsive: {
      500: { items: 2 },
      800: { items: 3 },
      1024: { items: 4 }
    },
    stagePadding: {
      paddingLeft: 5,
      paddingRight: 5
    },
    galleryItems: this.galleryItems()
  };

  slideTo = i => this.setState({ currentIndex: i });

  onSlideChanged = e => this.setState({ currentIndex: e.item });

  slideNext = () =>
    this.setState({ currentIndex: this.state.currentIndex + 1 });

  slidePrev = () =>
    this.setState({ currentIndex: this.state.currentIndex - 1 });

  thumbItem = (item, i) => <span onClick={() => this.slideTo(i)}>* </span>;

  galleryItems() {
    const { classes } = this.props;
    const { reviewsItem } = this.props.instructorData.filter(
      instructor => instructor.id == this.props.instructorId
    )[0];

    return reviewsItem.map(reviewsItem => (
      <Card elevation={0}>
        <CardContent>
          <Typography align={"center"} gutterBottom>
            {reviewsItem.comment}
          </Typography>
          <Typography align={"center"} variant="body2" component="p">
            {reviewsItem.author}
          </Typography>
        </CardContent>
      </Card>
    ));
  }

  render() {
    const { galleryItems, responsive, currentIndex } = this.state;
    return (
      <div>
        <AliceCarousel
          dotsDisabled={true}
          buttonsDisabled={true}
          items={galleryItems}
          responsive={responsive}
          slideToIndex={currentIndex}
          onSlideChanged={this.onSlideChanged}
          autoHeight
        />
        <div align={"center"}>
          <IconButton aria-label="ChevronLeft" onClick={() => this.slidePrev()}>
            <ChevronLeft />
          </IconButton>
          <IconButton
            aria-label="ChevronRight"
            onClick={() => this.slideNext()}
          >
            <ChevronRight />
          </IconButton>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    instructorData: state.virtualInstructorReducer.data
  };
}

export default connect(mapStateToProps)(InstructorReviewCarousel);
