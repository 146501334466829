import {
  UPDATE_AGE,
  UPDATE_GENDER,
  UPDATE_COUNTRY,
  UPDATE_LANGUAGES,
  UPDATE_ABILITY,
  UPDATE_AVATAR,
  UPDATE_NAME,
  UPDATE_RATING,
  UPDATE_BIO
} from "../actions/action-types";

import profilePic from "../../materials/HomePage/host.jpg";

const initialState = {
  visitorIntroSection: {
    age: 50,
    gender: "Male",
    country: ["Germany", "Australia"],
    languages: ["English", "spanish"],
    abilities: [
      {
        name: "Ski",
        skill: 5
      },
      { name: "Snowboard", skill: 3 }
    ]
  },
  visitorMyProfile: {
    avtar: profilePic,
    name: {
      firstName: "Peter",
      lastName: "Smith"
    },
    rating: 5,
    bio:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
  }
};

const visitorProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_AGE:
      return { ...state, age: action.payload };
    case UPDATE_GENDER:
      return { ...state, gender: action.payload };
    case UPDATE_COUNTRY:
      return {
        ...state,
        country: [...state.country, action.payload]
      };
    case UPDATE_LANGUAGES:
      return { ...state, languages: [...state.languages, action.payload] };
    case UPDATE_ABILITY:
      return { ...state, ability: action.payload };
    case UPDATE_AVATAR:
      return { ...state, avatar: action.payload };
    case UPDATE_NAME:
      return { ...state, name: action.payload };
    case UPDATE_RATING:
      return { ...state, rating: action.payload };
    case UPDATE_BIO:
      return { ...state, bio: action.payload };
    default:
      return state;
  }
};

export default visitorProfileReducer;
