import React, { useState } from "react";
import {
  Grid,
  CardContent,
  Box,
  makeStyles,
  Typography,
  Button,
  Card,
  Popover,
  IconButton
} from "@material-ui/core";
import useStyles from "../../../../../../components/Common/SignupForm/ClientInfoStyles";
import CloseIcon from "@material-ui/icons/Close";

const useClasses = makeStyles((theme) => ({
  container: {
    backgroundColor: "#E8F2F8"
  },
  uploadButton: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  nextButton: {
    width: "9em",
    height: "2.5em",
    borderRadius: 100,
    textAlign: "center",
    marginRight: "1em",
    marginLeft: "1rem"
  },
  editMode: {
    backgroundColor: "#E8F2F8",
    alignItems: "center",
    padding: "1rem",
    borderRadius: "10px",
    marginTop: "1rem",
    marginBottom: "1rem",
    width: "90%",
    [theme.breakpoints.down("xs")]: {
      direction: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    [theme.breakpoints.up("sm")]: {
      direction: "row",
      justifyContent: "baseline",
      alignContent: "center"
    }
  },
  viewMode: {
    backgroundColor: "#4caf5024",
    alignItems: "center",
    padding: "1rem",
    borderRadius: "10px",
    marginTop: "1rem",
    marginBottom: "1rem",
    width: "90%",
    [theme.breakpoints.down("xs")]: {
      direction: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    [theme.breakpoints.up("sm")]: {
      direction: "row",
      justifyContent: "baseline",
      alignContent: "center"
    }
  }
}));

const UploadDocument = props => {
  const { onImageUpload, onImageUpload2,onImageUpload3,text, hasFile, fileLink } = props;
  const styles = useStyles();
  const classes = useClasses(true);
  const [inputValue, setValue] = useState(0);
  let [image, setImage] = React.useState("");
  let [previewImage, setPreviewImage] = React.useState("");
  let [fileName, setFileName] = React.useState("");
  const [uploaded, setUploaded] = React.useState("");
  let [file, setFile] = React.useState({});

  const handleImageUpload = event => {
    setUploaded(URL.createObjectURL(event.target.files[0]));
    setFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
    // setImage(event.target.files[0]);
  };

  const handleSaveImage = event => {
    if (onImageUpload3)  {
      onImageUpload("child_working_credential_link",file);
      onImageUpload2("child_working_credential_link",file);
      onImageUpload3("child_working_credential_link",file);
    }else {
      onImageUpload(file);
      onImageUpload2(file);
    }
    handleClose(null);
    setFileName("");
  };

  const onTextChange = event => {
    setValue(event);
    console.log("service price==>", event);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleViewFile = event => {
    window.open(fileLink, "_blank").focus();
  };
  const handleClose = () => {
    setFileName("");
    setAnchorEl(null);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Grid
      container
      item
      className={hasFile ? classes.viewMode : classes.editMode }
    >
 
        <Grid lg={8} sm={8} md={8} xs={12} direction="column">
        <Typography style={{ textAlign: "left", marginBottom: "0.5rem" }}>*Required</Typography>
        <Typography style={{ textAlign: "left", marginBottom: "0.5rem" }}>
          {text}
        </Typography>
      </Grid>
      <Grid lg={4} sm={4} md={4} xs={12}>
        {hasFile? (
          <Button
            onClick={handleViewFile}
            className={classes.nextButton}
            classes={{
              label: styles.buttonLabel,
              root: styles.uploadButtonRoot
            }}
          >
            View
            {/* {hasFile ? "View" : "Upload"} */}
          </Button>
        ) : (
          <Button
            disabled={props.isDisabled || false}
            onClick={handleClick}
            className={classes.nextButton}
            classes={{
              label: styles.buttonLabel,
              root: styles.uploadButtonRoot
            }}
          >
            Upload
            {/* {hasFile ? "View" : "Upload"} */}
          </Button>
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          // transformOrigin={{
          //   vertical: 'top',
          //   horizontal: 'center',
          // }}
          // anchorOrigin={{
          //   vertical: "top",
          //   horizontal: "left"
          // }}
          // transformOrigin={{
          //   vertical: "top",
          //   horizontal: "left"
          // }}
        >
          <Card className={styles.uploadCard}>
            {/* <Grid container direction="row" item className={classes.popUpTitle}>
              <Grid item xs={11}>
                <Typography className={classes.uploadPhoto}>
                  Upload Photo
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  onClick={handleClose}
                  className={classes.iconbutton}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid> */}

            <Grid container direction="row" justify="center" item>
              <Grid
                container
                justifyContent="space-evenly"
                alignItems="center"
                style={{ padding: "0.5rem 0px" }}
              >
                <Grid item xs={8}>
                  <Typography
                    variant="h5"
                    style={{ fontFamily: "PT Sans Narrow" }}
                  >
                    Upload Photo
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <IconButton
                    onClick={handleClose}
                    className={classes.iconbutton}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} style={{ margin: "1rem 0rem 1rem 4rem  " }}>
                  <Typography
                    style={{ fontFamily: "PT Sans Narrow", fontSize: "1rem" }}
                  >
                    {fileName}
                  </Typography>
                </Grid>

                <Grid item>
                  <Button
                    component="label"
                    className={classes.nextButton}
                    classes={{
                      label: styles.browseLabel,
                      root: styles.browseRoot
                    }}
                  >
                    Browse
                    <input
                      accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                      className={styles.input}
                      id="contained-button-file"
                      multiple
                      hidden
                      type="file"
                      onChange={handleImageUpload}
                    />
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={handleSaveImage}
                    className={classes.nextButton}
                    classes={{
                      label: styles.buttonLabel,
                      root: styles.uploadButtonRoot
                    }}
                  >
                    Upload
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Popover>
      </Grid>
    </Grid>
  );
};

export default UploadDocument;
