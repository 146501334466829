export const marshallAgeGroup = {
  "mighty mites (3-5yrs)": "3-5yrs",
  "mighty mites (3 - 5yrs)": "3-5yrs",
  "child (6-14yrs)": "6-14yrs",
  "Adult (young) (15-17yrs)": "15-17yrs",
  "Adult (18-64yrs)": "18-64yrs",
  "Senior (65-69yrs)": "65-69yrs",
  "70yrs & over": "70yrs+"
};

export const ageGroupMapper = {
  "mighty mites (3-5yrs)": {
    minimumAge: 3,
    maximumAge: 5
  },
  "mighty mites (3 - 5yrs)": {
    minimumAge: 3,
    maximumAge: 5
  },
  "child (6-14yrs)": {
    minimumAge: 6,
    maximumAge: 14
  },
  "Adult (young) (15-17yrs)": {
    minimumAge: 15,
    maximumAge: 17
  },
  "Adult (18-64yrs)": {
    minimumAge: 18,
    maximumAge: 64
  },
  "Senior (65-69yrs)": {
    minimumAge: 65,
    maximumAge: 69
  },
  "70yrs & over": {
    minimumAge: 70,
    maximumAge: 200
  },
  "6+ yrs": {
    minimumAge: 6,
    maximumAge: 200
  },
  "3 - 5yrs": {
    minimumAge: 3,
    maximumAge: 5
  }
};
