import {
  SAVE_INSTRUCTOR_STATE,
  TOGGLE_VIRTUAL_INSTRUCTOR_LIKE,
  CREATE_VIRT_BOOKING,
  MODIFY_VIRT_BOOKING,
  SEND_INSTRUCTOR_SPECIALITY_VALUES,
  ADJUST_UNAVAILABILITY,
  ADD_TIMESLOT,
  UPDATE_TIMESLOT,
  DELETE_TIMESLOT,
  UPDATE_ERRORS_TIMESLOT,
  ADD_RATE,
  REMOVE_RATE
} from "./action-types";

export const addTimeslot = newSlot => {
  console.log(newSlot);
  return {
    type: ADD_TIMESLOT,
    payload: newSlot
  };
};

export const deleteTimeslot = id => {
  return {
    type: DELETE_TIMESLOT,
    payload: id
  };
};

export const updateTimeslot = (id, timeslot) => {
  return {
    type: UPDATE_TIMESLOT,
    payload: { id: id, timeslot: timeslot }
  };
};

export const updateErrorsTimeslot = timeslots => {
  return {
    type: UPDATE_TIMESLOT,
    payload: timeslots
  };
};

export const toggleVirtInstructorLike = VirtInstructorId => {
  return {
    type: TOGGLE_VIRTUAL_INSTRUCTOR_LIKE,
    payload: { VirtInstructorId }
  };
};

export const sendInstructorSpecialityValues = values => {
  return {
    type: SEND_INSTRUCTOR_SPECIALITY_VALUES,
    payload: { values }
  };
};

export const createBooking = (resort_id, timeslot) => {
  return {
    type: CREATE_VIRT_BOOKING,
    payload: {
      resort_id,
      timeslot
    }
  };
};

export const modifyBooking = items => ({
  type: MODIFY_VIRT_BOOKING,
  payload: {
    items
  }
});

export const adjustUnavailability = (resort_info_id, dates) => ({
  type: ADJUST_UNAVAILABILITY,
  payload: { resort_info_id, dates }
});

//Why are there two seperate instructors - all these different
//virual/non-virtual accounts are v confusing
export const saveInstructorState = instructorData => {
  return {
    type: SAVE_INSTRUCTOR_STATE,
    payload: { ...instructorData }
  };
};

export const addRate = rate => {
  return {
    type: ADD_RATE,
    payload: rate
  };
};

export const removeRate = rates => {
  return {
    type: REMOVE_RATE,
    payload: rates
  };
};
