import {
  UPDATE_AGE,
  UPDATE_GENDER,
  UPDATE_PHYSICAL_DISABILITY,
  UPDATE_LANGUAGES,
  UPDATE_YEARS_OF_EXPERIENCE,
  UPDATE_ABILITY,
  UPDATE_AVATAR,
  UPDATE_NAME,
  UPDATE_RATING,
  UPDATE_BIO,
  UPDATE_STU_PROFILE
} from "../actions/action-types";

import profilePic from "../../materials/HomePage/host.jpg";

const initialState = {
  studentIntroSection: {
    age: 50,
    gender: "Male",
    physicalDisability: ["Abcde Disability", "Blindness"],
    languages: ["English", "spanish"],
    experience: 7,
    abilities: [
      {
        name: "Ski",
        skill: 5
      },
      { name: "Snowboard", skill: 3 }
    ]
  },
  studentMyProfile: {
    avtar: profilePic,
    name: {
      firstName: "Paul",
      lastName: "Barber"
    },
    rating: 5,
    bio:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
  }
};

const studentProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_AGE:
      return { ...state, age: action.payload };
    case UPDATE_GENDER:
      return { ...state, gender: action.payload };
    case UPDATE_PHYSICAL_DISABILITY:
      return {
        ...state,
        physicalDisability: [...state.physicalDisability, action.payload]
      };
    case UPDATE_LANGUAGES:
      return { ...state, languages: [...state.languages, action.payload] };
    case UPDATE_YEARS_OF_EXPERIENCE:
      return { ...state, experience: action.payload };
    case UPDATE_ABILITY:
      return { ...state, abilities: action.payload };
    case UPDATE_AVATAR:
      return { ...state, avatar: action.payload };
    case UPDATE_NAME:
      return { ...state, name: action.payload };
    case UPDATE_RATING:
      return { ...state, rating: action.payload };
    case UPDATE_BIO:
      return { ...state, bio: action.payload };
    case UPDATE_STU_PROFILE:
      return {
        ...state,
        studentIntroSection: {
          ...state.studentIntroSection,
          age: action.payload.age,
          gender: action.payload.gender,
          abilities: action.payload.abilities,
          experience: action.payload.experience,
          physicalDisability: action.payload.physicalDisability
        },
        studentMyProfile: {
          ...state.studentMyProfile,
          avatar: action.payload.avatar,
          name: action.payload.name,
          bio: action.payload.bio
        }

        // physicalDisability: action.payload.physicalDisability,
        // languages: action.payload.languages,

        // rating: action.payload.rating,

        // ...action.payload
      };
    default:
      return state;
  }
};

export default studentProfileReducer;
