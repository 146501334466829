import React, { useState, useEffect } from "react";

// Styles
import useStyles from "../../../Common/SignupForm/ClientInfoStyles";
import { makeStyles } from "@material-ui/core/styles";
import { COLOR } from "../../../../styling/theme/Theme";

// Component Input
import {
  Grid,
  MenuItem,
  FormControl,
  Select,
  ListItemText,
  Typography,
  Box,
  Chip,
  FormHelperText
} from "@material-ui/core";

// Redux
import { connect } from "react-redux";
import { getCountryList } from "../../../../redux/actions/homePageAction.js";

const useClasses = makeStyles(theme => ({
  errorText:{
    color: "#f44336", marginLeft: "14px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "22px",
    },
  },
  select2: {
    fontColor: "1277B8",
    fontFamily: "PT Sans Narrow",
    borderRadius: "10px",
    "& .MuiOutlinedInput-input": {
      padding: "0.5rem 0.5rem",
      height: "auto"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black  ",
      borderWidth: "1px  "
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black  ",
      borderWidth: "1px  "
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black  ",
      borderWidth: "1px  "
    },
    "& .MuiListItemText-root": {
      fontFamily: "PT Sans Narrow",
      marginTop: "0px"
    },
    "& .MuiTypography-body1": {
      lineHeight: "inherit"
    }
  },
  label: {
    marginLeft: "7.5%",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    marginBottom: "0.25em",
    textTransform: "uppercase",
    color: "#000000"
  },
  inputSelect: {
    marginTop: "0.2em",
    textAlign: "center",
    flexWrap: "wrap",
    display: "flex"
  },
  selectMenu: {
    width: "90%"
    // height: "0.1em"
  },
  selectRootText: {
    textAlign: "center",
    color: COLOR.PRIMARY,
    width: "90%",
    "& div.MuiOutlinedInput-root": {
      height: "2.1rem",
      fontFamily: "PT Sans"
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      borderColor: "black "
    },
    "& .MuiOutlinedInput-root .MuiSelect-select:focus": {
      backgroundColor: "#EFEFEF"
    }
  },
  selectMenu: {
    width: "90%"
    // height: "0.1em"
  },
  selectRoot: {
    textAlign: "center",
    borderColor: "black",
    width: "100%",
    "& div.MuiOutlinedInput-root": {
      height: "Auto",
      fontFamily: "PT Sans"
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      borderColor: "black !important"
    },
    "& .MuiOutlinedInput-root .MuiSelect-select:focus": {
      backgroundColor: "transparent"
    }
  },
  selectIcon: {
    // color: "rgb(255,97,97)",
    color: COLOR.PRIMARY
  },
  search: {
    width: "17em"
  },
  checkBoxStyle: {
    "& .MuiCheckbox-root": {
      color: "#1277B8"
    }
  },
  searchRoot: {
    borderRadius: 5,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: COLOR.PRIMARY,
        borderWidth: 1,
        borderRadius: 5
      },
      "&:hover fieldset": {
        borderColor: COLOR.PRIMARY,
        borderWidth: 1,
        borderRadius: 5
      },
      "&.Mui-focused fieldset": {
        borderColor: "#ff0000",
        borderWidth: 1,
        borderRadius: 5
      }
    }
  },
  listRoot: {
    whiteSpace: "normal",
    // width: "18em",
    alignContent: "center"
  },
  subHeaderRoot: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "140.62%",
    display: "flex",
    alignItems: "center",
    color: COLOR.PRIMARY
  },
  primary: {
    fontFamily: "PT Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "140.62%",
    color: COLOR.PRIMARY
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5"
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)"
    }
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3"
    }
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function NationalityForm(props) {
  const styles = useStyles();
  const classes = useClasses();

  const { country_list } = props;
  const { nationality, setNationality, isDisabled } = props;

  const [error, setError] = useState(false);
  let [selectedNationality, setSelectedNationality] = useState([]);

  // get ALL nationality data
  useEffect(() => {
    props.getCountryList();
  }, []);
  // load nationality data
  useEffect(() => {
    if (nationality != undefined) {
      if (nationality.length == 0) {
        setError(true);
      } else {
        setError(false);
      }
      setSelectedNationality(nationality.country_name);
    }
  }, [nationality]);

  const handleNationality = event => {
    let selectedItem = event.target.value;
    setSelectedNationality(selectedItem.country_name);
    setNationality(selectedItem);
  };

  const handleClose = event => {
    if (selectedNationality == "" || selectedNationality == null) {
      setError(true);
    }
  };

  return (
    <Grid
      item
      container
      xs={12}
      sm={4}
      direction="column"
      justify="center"
      alignItems="left"
      style={{ marginBottom: "1rem" }}
    >
      <Grid item style={{ padding: "0.25rem 0" }}>
        <Typography className={styles.allergyandotherinformation}>
          NATIONALITY
        </Typography>
      </Grid>
      <Grid item container justify="center" alignItems="left">
        <FormControl
          variant="outlined"
          color="primary"
          classes={{ root: classes.selectRoot }}
          disabled={isDisabled}
          error={error}
        >
          <Select
            onClose={handleClose}
            value={selectedNationality}
            onChange={handleNationality}
            renderValue={selected => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                <Chip
                  style={{ fontFamily: "PT Sans Narrow" }}
                  key={selectedNationality}
                  label={selectedNationality}
                />
              </Box>
            )}
            className={classes.select2}
            classes={{
              selectMenu: classes.selectMenu,
              root: classes.selectRoot,
              icon: classes.selectIcon,
              selected: classes.selected
            }}
            MenuProps={MenuProps}
          >
            {country_list.map((item, index) => {
              return (
                <MenuItem key={index} value={item}>
                  <ListItemText primary={item.country_name} />
                </MenuItem>
              );
            })}
          </Select>
          {error ? <FormHelperText className={classes.errorText}>*Required</FormHelperText> : (
          <FormHelperText className={classes.errorText}>&nbsp;</FormHelperText>)}
        </FormControl>
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    country_list: state.homePageReducer.country_list
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getCountryList: () => dispatch(getCountryList())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NationalityForm);
