import React, { Component } from "react";
import { DateDisplay } from "../../../../styling/styled-components/whoSection";

export default class WhoSectionCustomDateInput extends Component {
  render() {
    const { value, onClick } = this.props;

    return <DateDisplay onClick={onClick}>{value}</DateDisplay>;
  }
}
