import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import agent from "../../../agent";
import "../../../styling/css/loader.css";
import GoogleSignup from "./GoogleSignup"


import {
  incrementActiveStep,
  decrementActiveStep,
  incrementActiveStepHost,
  decrementActiveStepHost
} from "../../../redux/actions/stepperActions.js";

// Redux
import { Field, reduxForm } from "redux-form";

// Component Input
import { renderField } from "../FormField/FormField.js";

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    marginTop: "60px",
    paddingRight: "20px"
  },
  nextButton: {
    width: "102px",
    height: "2.5em",
    borderRadius: 100,
    textAlign: "center"
  },
  buttonRoot: {
    backgroundColor: "#FF6161"
  },
  buttonLabel: {
    color: "white",
    fontWeight: 700,
    letterSpacing: "1%",
    fontFamily: "PT Sans"
  },
  backButton: {
    width: "60%",
    marginRight: "1em",
    fontFamily: "PT Sans",
    border: "white"
  },
  btnGooglePosition: {
    paddingTop: "25px",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  backButtonLabel: {
    color: "#FF6161",
    fontWeight: 700,
    letterSpacing: "1%",
    fontFamily: "PT Sans"
  }
}));

function validate(values) {
  let errors = {};

  if (!values.email) {
    errors.email = "*Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid Email";
  }

  if (!values.password) {
    errors.password = "*Required";
  } else if (
    !/^(?=.*[0-9])(?=.*[!@#$%^&,.*])[a-zA-Z0-9!@#$%^&,.*]{8,16}$/.test(
      values.password
    )
  ) {
    errors.password =
      "Password must be 8 charaters long, has at least a number, and at least a special character";
  }

  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = "Passwords don't match";
  }

  return errors;
}

let SetAccount = props => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [isLoading, setLoading] = useState();

  const handleSubmit = event => {
    setLoading(true);
    event.preventDefault();

    if (!email || !password || !password2) {
      alert("Please enter required information!");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      alert("Please enter valid email!");
    } else if (
      !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/.test(password)
    ) {
      alert("Please enter valid password!");
    } else if (password !== password2) {
      alert("Please enter matched passwords!");
    } else {
      agent.Users.register(email, password, props.isProvider, props.activeStep)
        .then(response => {
          setLoading(false);
          props.handleSubmit();
        })
        .catch(error => {
          setLoading(false);
          alert(error.response.data.email);
        });
    }
    setLoading(false);
  };

  const handleEmail = event => {
    setEmail(event.target.value);
  };

  const handlePassword = event => {
    setPassword(event.target.value);
  };

  const handlePassword2 = event => {
    setPassword2(event.target.value);
  };

  const handleBackButton = event => {
    props.decrementActiveStep(decrementActiveStep());
  };

  if (isLoading) {
    return (
      <div class="loader-container">
        <div class="loader" />
      </div>
    );
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{ backgroundColor: "white" }}
        >
          <Grid
            item
            container
            lg={4}
            md={5}
            sm={6}
            xs={12}
            justify="center"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <Field
                name="email"
                component={renderField}
                type="email"
                label="Email"
                value={email}
                onChange={handleEmail}
              />
            </Grid>

            <Grid item xs={12}>
              <Field
                name="password"
                component={renderField}
                type="password"
                label="Password"
                value={password}
                onChange={handlePassword}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="confirmPassword"
                component={renderField}
                type="password"
                label="confirm Password"
                onChange={handlePassword2}
              />
            </Grid>

            <Grid item xs={12} style={{ marginLeft: "7.5%" }} className={classes.btnGooglePosition}>
                <div>
                  <GoogleSignup isProvider={props.isProvider}/>
                </div>
            </Grid>


            <Grid
              container
              item
              xs={12}
              justify="flex-end"
              className={classes.buttonContainer}
            >
              

              <Grid item>
                <div>
                  {props.activeStep < 1 ? null : (
                    <Button
                      className={classes.backButton}
                      classes={{ label: classes.backButtonLabel }}
                      variant="outlined"
                      onClick={handleBackButton}
                    >
                      Back
                    </Button>
                  )}
                </div>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  className={classes.nextButton}
                  classes={{
                    label: classes.buttonLabel,
                    root: classes.buttonRoot
                  }}
                  variant="contained"
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

// destroyOnUnmount - saves it in state
SetAccount = reduxForm({
  form: "signup",
  destroyOnUnmount: false,
  validate
})(SetAccount);

function mapDispatchToProps(dispatch) {
  return {
    incrementActiveStep: () => dispatch(incrementActiveStep()),
    decrementActiveStep: () => dispatch(decrementActiveStep()),
    incrementActiveStepHost: () => dispatch(incrementActiveStepHost()),
    decrementActiveStepHost: () => dispatch(decrementActiveStepHost())
  };
}

function mapStateToProps(state, ownProps) {
  return {
    activeStep: state.stepperReducer.activeStep,
    activeStepHost: state.stepperReducer.activeStepHost
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetAccount);
