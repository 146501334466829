import React from "react";

//MaterialUI
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import axios from "axios";
//Components
import WeeklySchedule from "./WeeklySchedule";

const API_PATH = "../api/";

const useStyles = makeStyles({
  //Next Button
  nextButtonTextStyle: {
    padding: "0px 3px 0px 3px",
    color: "white",
    fontWeight: 500,
    fontSize: "18px"
  },
  nextButtonStyle: {
    borderRadius: "50px",
    margin: "1rem",
    backgroundColor: "#FF6161",
    padding: "5px 30px 5px 30px"
  },
  //Add button
  addButtonTextStyle: {
    padding: "0px 3px 0px 3px",
    color: "white",
    fontWeight: 500,
    fontSize: "12px"
  },
  addButtonStyle: {
    borderRadius: "50px",
    margin: "1rem",
    backgroundColor: "#FF6161",
    padding: "5px 10px 5px 10px"
  },
  //title heading
  textHeading: {
    fontSize: "16",
    color: "#1277B8",
    fontWeight: "bold",
    fontFamily: "PT Sans Narrow",
    lineHeight: "140.62%",
    padding: "6px"
  },
  //Center elements inside div
  centeredDiv: {
    textAlign: "center"
  }
});

//NEXT STEP:
//1) make schedule state modifiable inside WeeklySchedule.js
//2) submit changes to backend
const AvailabilitySettings = props => {
  //styling
  const classes = useStyles();

  //states

  const [schedule, setSchedule] = React.useState([
    [
      {
        day: "monday",
        //pass times through as strings as timezone confusery occurs otherwise
        start: new Date().toString(),
        end: new Date().toString()
      },
      {
        day: "tuesday",
        start: new Date().toString(),
        end: new Date().toString()
      },
      {
        day: "wednesday",
        start: new Date().toString(),
        end: new Date().toString()
      },
      {
        day: "thursday",
        start: new Date().toString(),
        end: new Date().toString()
      },
      {
        day: "friday",
        start: new Date().toString(),
        end: new Date().toString()
      },
      {
        day: "saturday",
        start: new Date().toString(),
        end: new Date().toString()
      },
      {
        day: "sunday",
        start: new Date().toString(),
        end: new Date().toString()
      }
    ]
  ]);

  //Adds an empty dict object to schedule state
  const addEmptyWeekToSchedule = () => {
    setSchedule([...schedule, []]);
  };

  const { id } = props.instructorData;

  const modifyAvailability = () => {
    console.log("test");
    //const id = 1;
    //modifies booking on back end
    if (id) {
      axios
        .put(API_PATH + "/instructor/availability/" + id, {
          //data structure for weekly schedule
          schedule: schedule
        })
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  //render availabilities
  const availabilities = schedule.map(week => {
    return (
      <WeeklySchedule
        week={schedule}
        setSchedule={setSchedule}
        id={schedule.indexOf(week)}
      />
    );
  });

  //render
  return (
    <div>
      <Grid xs={12} item container justify="center" spacing={2}>
        {/* Title */}
        <Grid item xs={12}>
          <Typography
            className={classes.textHeading}
            align="center"
            gutterBottom
          >
            AVAILABILITY
          </Typography>
        </Grid>
        {/* List of availabilities */}
        {availabilities}
        {/* UI Buttons */}
        <Grid item xs={9} className={classes.centeredDiv}>
          <Button
            variant="contained"
            color="primary"
            onClick={addEmptyWeekToSchedule}
            classes={{
              containedPrimary: classes.addButtonStyle,
              label: classes.addButtonTextStyle
            }}
          >
            Add
          </Button>
          <Typography>
            Don't worry, you'll be able to customise your availability later on.
          </Typography>
        </Grid>
        <Grid item xs={12} justify="center" className={classes.centeredDiv}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.setScreenNumber(props.screenNumber + 1)}
            classes={{
              containedPrimary: classes.nextButtonStyle,
              label: classes.nextButtonTextStyle
            }}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    instructorData: state.virtualInstructorReducer.currentProfile
  };
}

export default connect(mapStateToProps)(AvailabilitySettings);
