import {
  UPDATE_RESORTS,
  UPDATE_SPORT,
  UPDATE_SPECIALISATIONS,
  UPDATE_LANGUAGES,
  UPDATE_YEARS_OF_EXPERIENCE,
  UPDATE_QUALIFICATIONS,
  UPDATE_AVATAR,
  UPDATE_NAME,
  UPDATE_RATING,
  UPDATE_STARTING_PRICE,
  UPDATE_BIO,
  UPDATE_INS_PROFILE
} from "./action-types";

// Update Instructor Page
export const updateInsProfile = data => {
  return {
    type: UPDATE_INS_PROFILE,
    payload: data
  };
};

// Avatar action
export const updateAvatar = avatar => {
  return {
    type: UPDATE_AVATAR,
    payload: avatar
  };
};
// Name action
export const updateName = name => {
  return {
    type: UPDATE_NAME,
    payload: name
  };
};
// Rating action
export const updateRating = rating => {
  return {
    type: UPDATE_RATING,
    payload: rating
  };
};

// Starting Rate
export const updateStartingRate = intialprice => {
  return {
    type: UPDATE_STARTING_PRICE,
    payload: intialprice
  };
};

// Bio action
export const updateBio = bio => {
  return {
    type: UPDATE_BIO,
    payload: bio
  };
};
// Resorter action
export const updateResorters = resorters => {
  return {
    type: UPDATE_RESORTS,
    payload: resorters
  };
};

// Sports action
export const updateSports = sports => {
  return {
    type: UPDATE_SPORT,
    payload: sports
  };
};

// Specialisations action
export const updateSpecialisations = specialisations => {
  return {
    type: UPDATE_SPECIALISATIONS,
    payload: specialisations
  };
};

// Languages action
export const updateLanguages = languages => {
  return {
    type: UPDATE_LANGUAGES,
    payload: languages
  };
};

//Years of Experience  action
export const updateYearsOfExperience = years => {
  return {
    type: UPDATE_YEARS_OF_EXPERIENCE,
    payload: years
  };
};

// Qulification action
export const updateQulification = qulification => {
  return {
    type: UPDATE_QUALIFICATIONS,
    payload: qulification
  };
};
