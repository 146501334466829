import React from "react";
import useStyles from "./MyAccountStyles";

import { Button, Grid } from "@material-ui/core";

const Title = ({ handleDisabled }) => {
  const classes = useStyles();

  return (
    <Grid item container direction="row" md={12}>
      <Grid item lg={7} md={8} sm={8} xs={9} align="right">
        <div>
          <p className={classes.headingStyle}>My Account</p>
        </div>
      </Grid>
      <Grid item lg={1} md={1} sm={1} xs={1} />
      <Grid item lg={4} md={3} sm={3} xs={2} align="left">
        <button className={classes.btnEdit} onClick={handleDisabled}>
          Edit
        </button>
      </Grid>
    </Grid>
  );
};

export default Title;
