import React, { Component } from "react";
import { connect } from "react-redux";
import {
  RESORT,
  PROFILE,
  WHEN_WHO,
  //SLEEP,
  DOING,
  //LIFT_PASS,
  //EQUIPMENT,
  LESSONS,
  CHECKOUT,
  PAYMENT
} from "../../components/lessonsEnquiry/Book/section/section-types";
import Accordion from "../../components/lessonsEnquiry/Book/section/Accordion";
import FormContents from "../../components/lessonsEnquiry/Book/section/FormContents";
import ProfileSection from "../../components/lessonsEnquiry/Book/profile/ProfileSection";
import ResortSection from "../../components/lessonsEnquiry/Book/where/ResortSection";
import BookingWho from "../../components/lessonsEnquiry/Book/who/BookingWho";
import ActivitySection from "../../components/lessonsEnquiry/Book/activity/ActivitySection";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";

// import BookingLesson from "../lesson/BookingLessonSAF";
import LessonSection from "../../components/lessonsEnquiry/Book/lesson/LessonSection";

import CheckoutSection from "../../components/lessonsEnquiry/Book/checkout/CheckoutSection";
import PaymentSection from "../../components/lessonsEnquiry/Book/payment/PaymentSection";
import { Redirect } from "react-router-dom";
import { VerticalSpaceLG } from "../../styling/styled-components";
import _ from "lodash";
import { baseURL } from "../../redux/actions/action-types";
import { load as loadProfile } from "../../redux/actions/profileActions";
import { retrieveGroupMemberInfo } from "../../redux/actions/tripWhoActions";

import agent from "../../agent";
import {
  loadGroupLessons,
  loadPrivateLessons
} from "../../redux/actions/tripLessonActions";

// modify WITH CARE, order of sections
const order = [
  RESORT,
  PROFILE,
  WHEN_WHO,
  //SLEEP,
  DOING,
  //EQUIPMENT,
  LESSONS,
  //LIFT_PASS,
  CHECKOUT
];

// container for all sections, knows about what each section does

const FormContainer = props => {
  React.useEffect(() => {
    const user = props.currentUser;
    // TODO: load lessons accoridng to resort Id after data set up in database
    console.log(user);
    agent.Lessons.groupLessons(4).then(response =>
      props.loadGroupLessons(response.data)
    );
    agent.Lessons.privateLessons(4).then(response => {
      return props.loadPrivateLessons(response.data);
    });

    if (user != null) {
      const userID = props.currentUser.id;
      agent.Trips.pastTrip({
        params: {
          id: userID
        }
      })
        .then(response => {
          const { profile, who } = response.data;
          props.loadProfile({
            ...profile,
            DOB: new Date(profile.DOB)
          });
          let formattedFamilyMembers = {};
          _.map(who, (value, key) => {
            formattedFamilyMembers[key] = {
              ...value,
              DOB: new Date(value.DOB)
            };
          });
          formattedFamilyMembers[profile.id] = {
            ...profile,
            DOB: new Date(profile.DOB),
            isMaster: true
          };
          props.retrieveGroupMemberInfo({
            formattedFamilyMembers,
            masterMemberId: profile.id
          });
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
    }
    // agent.Lessons.allGroupLessons().then(response =>
    //   this.props.loadGroupLessons(response.data)
    // );
    // agent.Lessons.allPrivateLessons().then(response =>
    //   this.props.loadPrivateLessons(response.data)
    // );
  }, [props.currentUser]);

  const renderResort = () => {
    const position = order.indexOf(RESORT);
    return (
      <FormContents
        label="Where & When"
        thisSection={order[position]}
        nextSection={order[position + 1]}
      >
        <ResortSection />
      </FormContents>
    );
  };

  const renderProfile = () => {
    const position = order.indexOf(PROFILE);
    return (
      <FormContents
        label="About You"
        prevSection={order[position - 1]}
        thisSection={order[position]}
        nextSection={order[position + 1]}
      >
        <ProfileSection />
      </FormContents>
    );
  };

  const renderWho = () => {
    const position = order.indexOf(WHEN_WHO);
    return (
      <FormContents
        label="Trip Members"
        prevSection={order[position - 1]}
        thisSection={order[position]}
        nextSection={order[position + 1]}
      >
        <BookingWho />
      </FormContents>
    );
  };

  // renderSleep = () => {
  //   const position = order.indexOf(SLEEP);
  //   return (
  //     <FormContents
  //       label="Sleep"
  //       prevSection={order[position - 1]}
  //       thisSection={order[position]}
  //       nextSection={order[position + 1]}
  //     />
  //   );
  // };

  const renderDoing = () => {
    const position = order.indexOf(DOING);
    return (
      <FormContents
        label="Activity"
        prevSection={order[position - 1]}
        thisSection={order[position]}
        nextSection={order[position + 1]}
      >
        <ActivitySection />
      </FormContents>
    );
  };

  // renderEquipment = () => {
  //   const position = order.indexOf(EQUIPMENT);
  //   return (
  //     <FormContents
  //       label="Equipment"
  //       prevSection={order[position - 1]}
  //       thisSection={order[position]}
  //       nextSection={order[position + 1]}
  //     />
  //   );
  // };

  const renderLessons = () => {
    const position = order.indexOf(LESSONS);
    return (
      <FormContents
        label="Lessons"
        prevSection={order[position - 1]}
        thisSection={order[position]}
        nextSection={order[position + 1]}
      >
        {/* <BookingLesson /> */}
        <LessonSection />
      </FormContents>
    );
  };

  // renderLiftPass = () => {
  //   const position = order.indexOf(LIFT_PASS);
  //   return (
  //     <FormContents
  //       label="Lift Passes"
  //       prevSection={order[position - 1]}
  //       thisSection={order[position]}
  //       nextSection={order[position + 1]}
  //     />
  //   );
  // };

  const renderCheckout = () => {
    const position = order.indexOf(CHECKOUT);
    return (
      <FormContents
        label="Checkout"
        prevSection={order[position - 1]}
        thisSection={order[position]}
      >
        <CheckoutSection />
      </FormContents>
    );
  };

  const renderPayment = () => {
    const position = order.indexOf(PAYMENT);
    return (
      <FormContents
        label="Payment"
        prevSection={order[position - 1]}
        thisSection={order[position]}
      >
        <PaymentSection />
      </FormContents>
    );
  };

  const {
    bookingEmailSent,
    skiFirstTimersWithoutLesson,
    snowboardFirstTimersWithoutLesson
  } = props;
  if (bookingEmailSent) {
    // return <Redirect push to="/enquiry-success" />;
    return <Redirect push to="/register" />;
  }

  return (
    <div>
      <VerticalSpaceLG />
      <Accordion>
        {renderResort()}
        {renderProfile()}
        {renderWho()}
        {/*{this.renderSleep()}*/}
        {renderDoing()}
        {/*{this.renderEquipment()}*/}
        {renderLessons()}
        {/*{this.renderLiftPass()}*/}
        {renderCheckout()}
        {/*this.renderPayment()*/}
      </Accordion>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    bookingEmailSent: state.tripCoreReducer.bookingEmailSent,
    currentUser: state.auth.currentUser
  };
}

const mapDispatchToProps = dispatch => {
  return {
    loadPrivateLessons: privateLessons =>
      dispatch(loadPrivateLessons(privateLessons)),
    loadGroupLessons: groupLessons => dispatch(loadGroupLessons(groupLessons)),
    loadProfile: profile => dispatch(loadProfile(profile)),
    retrieveGroupMemberInfo: info => dispatch(retrieveGroupMemberInfo(info))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormContainer);
