import React from "react";
import Grid from "@material-ui/core/Grid";
import SearchCardForExploreInstructors from "./SearchCardForExploreInstructors";
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider
} from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: "100%",
    height: "687px",
    // dark overlay trick
    background:
      "linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2)) , var(--image-url) center center",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  contentContainer: {
    alignItems: "center"
  },
  sloganContainer: {
    justifyContent: "center",
    padding: "5%"
  },
  slogan: {
    color: "#ffffff",
    textShadow: "7px 7px 20px rgba(0, 0, 0, 0.25)",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "48px",
    lineHeight: "140.62%",
    textAlign: "end",
    letterSpacing: "0.02em",
    fontFamily: "PT Sans"
  },
  underSlogan: {
    color: "#FFFFFF",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontFamily: "PT Sans Narrow",
    textAlign: "right",
    width: "100%",
    letterSpacing: "0.01em",
    lineHeight: "45px"
  },
  [theme.breakpoints.down("sm")]: {
    wrapper: {
      height: "100%",
      paddingTop: "10em",
      paddingBottom: "10em"
    },
    contentContainer: {
      flexDirection: "column-reverse"
    },
    sloganContainer: {
      justifyContent: "center",
      alignItems: "center",
      padding: "1em"
    },
    slogan: {
      fontSize: "45px"
    },
    searchCardWrapper: {
      display: "none"
    }
  },
  [theme.breakpoints.down("xs")]: {
    wrapper: {
      height: "100%",
      paddingTop: "2em",
      paddingBottom: "3em"
    },
    contentContainer: {
      flexDirection: "column-reverse"
    },
    sloganContainer: {
      justifyContent: "center",
      alignItems: "center"
    },
    slogan: {
      fontSize: "45px"
    },
    searchCardWrapper: {
      display: "none"
    }
  }
}));

const HeaderSection = props => {
  const classes = useStyles();

  return (
    <div
      className={classes.wrapper}
      // style={{
      //   "--image-url": `url(${"https://resorter-images.s3-ap-southeast-2.amazonaws.com/ski-lessons-at-cardrona-alpine-resort.jpg"})`
      // }}
    >
      <Grid container className={classes.contentContainer} space={3}>
        <Grid
          className={classes.searchCardWrapper}
          item
          container
          md={5}
          sm={12}
          xs={12}
        >
          <SearchCardForExploreInstructors />
        </Grid>

        <Grid
          className={classes.sloganContainer}
          item
          container
          md={7}
          sm={12}
          xs={12}
        >
          <h1 className={classes.slogan}>
            {props.slogan == null
              ? "Book lessons and hosts for your next snow adventure"
              : props.slogan}
          </h1>
          <Typography className={classes.underSlogan}>
            {props.underSlogan == null ? "" : props.underSlogan}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default HeaderSection;
