// Modules
import React, { useEffect, useState } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";

// Settings
import agent from "../../../agent";

// Redux
import { updateProviderProfile } from "../../../redux/actions/providerProfileAction";
import {
  incrementActiveStep,
  decrementActiveStep,
  incrementActiveStepHost,
  decrementActiveStepHost,
  updateUserProfile,
  getProviderProfile
} from "../../../redux/actions/stepperActions.js";
import { getCountryList, getResortList } from "../../../redux/actions/homePageAction.js";
import { getProfileData } from "../../../redux/actions/profileDataAction.js";


// CSS
// import useStyles from "./ClientInfoStyles";

// Components
import ProfilePicture from "../../serviceMarket/profile/form/ProfilePictureForm";
import Bio from "../../serviceMarket/profile/form/BioForm";
import Nationality from "../../../pages/serviceMarket/profile/NationalityDropdown";
import Languages from "../../../pages/serviceMarket/profile/LanguageDropdown";
import AbilityLevelForm from "../../serviceMarket/profile/form/AbilityForm";
import SuitableFor from "../../../pages/serviceMarket/profile/SuitableForDropdown";
import Countries from "../../../pages/serviceMarket/profile/CountryDropdown";


function validate(values) {
  let errors = {};

  if (!values.bio) {
    errors.bio = "Required";
  }

  if (!values.nationality) {
    errors.nationality = "Required";
  }

  if (!values.languages) {
    errors.languages = "Required";
  }

  return errors;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "auto",
    marginTop: "2rem",
    display: "flex",
    padding: "0 10rem"
  },
  headerLabel: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "26px",
    color: "#000000"
  },
  avatarSize: {
    width: "10rem",
    height: "10rem",
    marginBottom: "15%",
    [theme.breakpoints.down("sm")]: {
      width: "10rem",
      height: "10rem"
    }
  },
  divAvatar: {
    textAlign: "center",
    margin: "0 auto"
  },
  myProfileLabel: {
    fontSize: "x-large",
    width: "50%",
    margin: "3rem 0",
    fontFamily: "PT Sans Narrow",
    paddingLeft: "1rem"
  },
  editProfileLabel: {
    width: "50%",
    margin: "3rem 0",
    textAlign: "right",
    paddingRight: "1rem",
    fontFamily: "PT Sans Narrow",
    color: "royalblue"
  },
  sectionMargin: {
    margin: "2rem 0",
    width: "100%"
  },
  btnDisabled: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 16,
    marginTop: 8,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14
    },
    color: "#BDBDBD",
    border: "none",
    backgroundColor: "transparent",
    padding: 0
  },
  saveButton: {
    width: "102px",
    height: "2.5em",
    borderRadius: 100,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      marginRight: "20px"
    }
  },
  nextButton: {
    width: "102px",
    height: "2.5em",
    borderRadius: 100,
    textAlign: "center"
  },
  buttonRoot: {
    backgroundColor: "#FF6161"
  },
  buttonLabel: {
    color: "white",
    fontWeight: 700,
    letterSpacing: "1%",
    fontFamily: "PT Sans"
  },
  centerBtns: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-around",
      alignItems: "center"
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
      alignItems: "center"
    }
  },
  backButton: {
    width: "60%",
    marginRight: "1em",
    fontFamily: "PT Sans",
    border: "white"
  },
  backButtonLabel: {
    color: "#FF6161",
    fontWeight: 700,
    letterSpacing: "1%",
    fontFamily: "PT Sans"
  },
  providerMarginStyle:{
    marginLeft: "2.5%",
    width: "95%"
  }
}));


let ProviderInfo = props => {
  const classes = useStyles();

  const { user_id } = props.userData.data;
  const {
    updateProfileSuccess,
    updateProfileError,
    activeStep,
    userProfile
  } = props;

  let [isLoading, setIsLoading] = useState(true);

  const [providerId, setProviderId] = useState();
  let [profileImage, setProfileImage] = useState("");
  let [bio, setBio] = useState("");
  let [nationality, setNationality] = useState({});
  let [languages, setLanguages] = useState([{}]);
  let [skiLevel, setSkiLevel] = useState(0);
  let [snowboardLevel, setSnowboardLevel] = useState(0);
  let [suitableFor, setSuitableFor] = useState([{}]);
  let [countryResorts, setCountryResorts] = useState([{}]);

  // Update State if provider's userProfile changes
  useEffect(() => {
    setIsLoading(true);

    if (userProfile != undefined) {
      setProviderId(userProfile.id);

      setProfileImage(userProfile.profileImage);
      setBio(userProfile.providerBio);

      setNationality(userProfile.user.country); 
      setLanguages(userProfile.user.languages);

      setSkiLevel(userProfile.user.skiing_level);
      setSnowboardLevel(userProfile.user.snowboarding_level);
      setSuitableFor(userProfile.suitableFors);
      setCountryResorts(userProfile.resorts);

      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [userProfile]);

  useEffect(() => {
    props.getCountryList();
  }, []);

  // Update response
  useEffect(() => {
    if (updateProfileSuccess) {
      console.log("User Information was successfully update!");
      return props.onSubmit();
    }
  }, [updateProfileSuccess]);

  useEffect(() => {
    if (updateProfileError) {
      console.log(updateProfileError);
      alert(updateProfileError);
    }
  }, [updateProfileError]);

  // Handle Back button
  const handleBackButton = event => {
    props.decrementActiveStep(decrementActiveStep());
  };

  // Handle Next button
  const handleSubmit = async event => {
    event.preventDefault();

    if (
      profileImage == null ||
      bio == null ||
      nationality == null ||
      languages.length == 0 ||
      skiLevel == 0 || snowboardLevel == 0 ||
      countryResorts.length == 0  || 
      suitableFor.length ==0 
    ) {
      alert("Please enter required information");
    } else {
      // ------ Update provider info -------
      let languageIdArray = [];
      languages.forEach(language => languageIdArray.push(language.id));
      let resortIdArray = [];
      countryResorts.forEach(countryResort => resortIdArray.push(countryResort.id));
      let suitableIdArray = [];
      suitableFor.forEach(level => suitableIdArray.push(level.id));
      console.log(activeStep)
      let user = {
        country: nationality.id,
        languages: languageIdArray,
        skiing_level: skiLevel,
        snowboarding_level: snowboardLevel,
        signup_step: activeStep + 1,
      }
      const provider = {
        providerId: providerId,
        user: user,
        provider_bio: bio,
        resorts: resortIdArray,
        suitable_fors: suitableIdArray
      }
      try{
        await props.updateProviderProfile(provider);
      }catch(error){
        props.updateUserProfile(error);
      }

      // ------ Update provider profile picture -------
      await uploadProfileImage();

      // ------ Update Redux states -------
      props.getProfileData(user_id);
      props.getProviderProfile(user_id)
      props.incrementActiveStep()
    }
  };

  // Upload profile image to AWS and update data in database
  const uploadProfileImage = async () => {
    if (typeof profileImage === "object") { // in case that image is not changed which is string link as fetched
      const profileImageData = new FormData();
      profileImageData.append("profile_picture", profileImage);
      try {
        await agent.Users.uploadProfileImage(user_id, profileImageData);
        const res = await agent.Users.getClientProfileImage(user_id);
        const { profile_picture } = res.data;
        setProfileImage(profile_picture);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div>
      {isLoading ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={0}
          direction="column"
          style={{ marginTop: "3rem" }}
        >
          <Grid
            container
            justify="center"
            alignItems="flex-start"
            xs={12}
            sm={10}
            md={8}
            lg={6}
            spacing={2}
            direction="row"
            style={{ backgroundColor: "white", padding: "2rem 0px" }}
          >
            <div className="loader-container">
              <div className="loader" />
            </div>
          </Grid>
        </Grid>
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid
            container
            justify="center"
            alignItems="center"
            spacing={0}
            direction="column"
            style={{ marginTop: "3rem" }}
          >
            {/* Avatar and Bio */}
            <Grid
              container
              xs={12}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              direction="row"
              justifyContent="space-evenly"
              alignItems="flex-start"
            >
              {/* Avatar section */}
              <ProfilePicture
                    profilePicture={profileImage}
                    setProfilePicture={setProfileImage}
                  />
              {/* BIO section */}
              <Bio providerOnlyStyle={{marginLeft: "2.5%", width: "95%"}} bio={bio} setBio={setBio} />
            </Grid>

            {/* Nationality and Language */}
            <Grid
              item
              container
              xs={12}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              justify="center"
              alignItems="flex-start"
              direction="row"
              className={classes.sectionMargin}
            >
              {/* Nationality section */}
              <Grid item xs={12} sm={6}>
                <Nationality
                  setNationality={setNationality}
                  nationality={nationality}
                  isDisabled={false}
                />
              </Grid>
              {/* Languages section */}
              <Grid item xs={12} sm={6}>
                <Languages
                  setLanguages={setLanguages}
                  languages={languages}
                  isDisabled={false}
                />
              </Grid>
            </Grid>

            {/* Ability level and Suitable for */}
            <Grid
              container
              xs={12}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              justify="center"
              alignItems="flex-start"
              direction="row"
              className={classes.sectionMargin}
            >
              {/* Ability level section */}
              <Grid item xs={12} sm={6}>
                <AbilityLevelForm
                    skiLevel={skiLevel}
                    setSkiLevel={setSkiLevel}
                    snowboardLevel={snowboardLevel}
                    setSnowboardLevel={setSnowboardLevel}
                    providerOnlyStyle={true}
                  />
              </Grid>
              {/* SuitableFor section */}
              <Grid item xs={12} sm={6}>
                <SuitableFor
                  setSuitableFor={setSuitableFor}
                  selectedLevels={suitableFor}
                  isDisabled={false}
                  isEditable={true}
                />
              </Grid>
            </Grid>

            {/* Countries and Resorts */}
            <Grid
              item
              container
              xs={12}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              justify="center"
              alignItems="flex-start"
              direction="row"
              className={classes.sectionMargin}
            >
              <Grid item xs={12} sm={12}>
                <Countries
                  setCountryResorts={setCountryResorts}
                  countryResorts={countryResorts}
                />
              </Grid>
            </Grid>

            {/* Back and Next Button */}
            <Grid
              container
              item
              xs={12}
              sm={10}
              md={8}
              lg={6}
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              className={classes.buttonContainer}
              style={{ padding: "3rem 1.5rem" }}
            >
              {/* Back Button section */}
              <Grid item>
                {props.activeStep < 1 ? null : (
                  <Button
                    className={classes.backButton}
                    classes={{ label: classes.backButtonLabel }}
                    variant="outlined"
                    onClick={handleBackButton}
                  >
                    Back
                  </Button>
                )}
              </Grid>
              
              {/* Next Button section */}
              <Grid item>
                <Button
                  type="submit"
                  className={classes.nextButton}
                  classes={{
                    label: classes.buttonLabel,
                    root: classes.buttonRoot
                  }}
                  variant="contained"
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </div>
  );
};

ProviderInfo = reduxForm({
  form: "signup",
  destroyOnUnmount: false,
  enableReinitialize: true,
  validate
})(ProviderInfo);

// destroyOnUnmount - saves it in state
function mapDispatchToProps(dispatch) {
  return {
    updateProviderProfile: provider => dispatch(updateProviderProfile(provider)),
    getProviderProfile: user_id => dispatch(getProviderProfile(user_id)),
    incrementActiveStep: () => dispatch(incrementActiveStep()),
    decrementActiveStep: () => dispatch(decrementActiveStep()),
    incrementActiveStepHost: () => dispatch(incrementActiveStepHost()),
    decrementActiveStepHost: () => dispatch(decrementActiveStepHost()),
    updateUserProfile: user => dispatch(updateUserProfile(user)),
    getCountryList: () => dispatch(getCountryList()),
    getProfileData: client_id => dispatch(getProfileData(client_id))
  };
}

function mapStateToProps(state) {
  return {
    updateProfileSuccess: state.stepperReducer.updateProfileSuccess,
    updateProfileError: state.stepperReducer.updateProfileError,
    userProfile: state.stepperReducer.userProfile,
    activeStep: state.stepperReducer.activeStep,
    activeStepHost: state.stepperReducer.activeStepHost,
    userData: state.loginReducer.userData,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderInfo);
