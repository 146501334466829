import React, { useState } from "react";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { connect } from "react-redux";
import { NextButton } from "../../../../styling/styled-components/index";
import $ from "jquery";
import { validateResort } from "../where/ResortSelector";
import { validateActivity } from "../activity/ActivitySection";
import { formValueSelector, getFormSyncErrors } from "redux-form";
import { DOING, LESSONS, PROFILE, RESORT } from "../section/section-types";

import { scrollerOptions } from "../../../../constants/common";
import { scroller } from "react-scroll";

import Chip from "@material-ui/core/Chip";
import InfoIcon from "@material-ui/icons/Info";
import {
  showResortErrors,
  updateResortErrors
} from "../../../../redux/actions/tripWhereActions";
import {
  showActivityErrors,
  updateActivityErrors
} from "../../../../redux/actions/activityActions";
import {
  change_comment,
  showLessonErrors,
  updateLessonErrors
} from "../../../../redux/actions/tripLessonActions";
import {
  completeForm,
  openSection,
  alertSubmitForm
} from "../../../../redux/actions/formActions";
import {
  getFormattedDate,
  getCategorizedLessons,
  getFormattedLessons,
  getAllGroupsOfLessons,
  dateToString,
  getMembersWithNoSnowpass,
  getFomarttedMembers
} from "./helper";

import { submit, isSubmitting } from "redux-form";
import { scrollToProfileError } from "../profile/ProfileSection";
import { baseURL } from "../../../../redux/actions/action-types";
import getFormValues from "redux-form/es/getFormValues";
import _ from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Grid from "@material-ui/core/Grid";
import "../../../../styling/css/Book/Checkout.css";
import { Button } from "@material-ui/core/es";
import { makeStyles } from "@material-ui/core/styles";

import {
  ADAPTIVE_LESSON_COLOR,
  FIRST_TIMER_LESSON_COLOR,
  NON_FIRST_TIMERS_COLOR,
  ADAPTIVE_LESSON_COLOR_SECONDARY,
  FIRST_TIMER_LESSON_COLOR_SECONDARY,
  NON_FIRST_TIMERS_COLOR_SECONDARY,
  DEFAULT_TABLE_COLOR,
  DEFAULT_TABLE_COLOR_SECONDARY,
  DEFAULT_TABLE_COLOR_BORDER,
  BLUECOLOR4,
  GRAYCOLOR3,
  GRAYCOLOR4,
  WHITECOLOR,
  SNOWPASS_TABLE_COLOR_SECONDARY,
  SNOWPASS_TABLE_COLOR
} from "../lesson/constant/color";
import classNames from "classnames";
import agent from "../../../../agent";
const HEADING_TEXT_COLOR = "#4682B4";

const useStyles = makeStyles(theme => ({
  div20: {
    width: "20%",
    textAlign: "left"
  },
  div100: {
    width: "100%",
    textAlign: "left"
  },
  div25: {
    width: "25%",
    textAlign: "left"
  },
  contentDiv20: {
    width: "20%",
    display: "flex",
    flexDirection: "column",
    textAlign: "left"
  },
  chip: {
    width: "80%",
    height: "80%"
  },
  label: {
    fontSize: "10px",
    width: "100%"
  },
  [theme.breakpoints.up("xs")]: {
    label: {
      fontSize: "3.9px"
    }
  },
  boldText: {
    fontWeight: 700
  },
  padding: {
    padding: "0 10px"
  },
  LessonAttributesRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    borderWidth: "1px",
    borderStyle: "solid",
    height: "36px",
    fontWeight: 700,
    fontFamily: ["Open Sans Condensed", "sans-serif"],
    color: HEADING_TEXT_COLOR,
    "@media (max-width: ${media_sm_max})": {
      fontSize: "0.9rem"
    }
  },
  [theme.breakpoints.down("xs")]: {
    LessonAttributesRow: {
      fontSize: "0.8rem"
    }
  },
  [theme.breakpoints.up("sm")]: {
    LessonAttributesRow: {
      fontSize: "1rem"
    }
  },
  LessonAttributesRowColorAdaptive: {
    backgroundColor: ADAPTIVE_LESSON_COLOR,
    borderColor: ADAPTIVE_LESSON_COLOR
  },
  LessonAttributesRowColorFirstTimer: {
    backgroundColor: FIRST_TIMER_LESSON_COLOR,
    borderColor: FIRST_TIMER_LESSON_COLOR
  },
  LessonAttributesRowColorExperienced: {
    backgroundColor: NON_FIRST_TIMERS_COLOR,
    borderColor: NON_FIRST_TIMERS_COLOR
  },
  LessonAttributesRowColorTable: {
    backgroundColor: DEFAULT_TABLE_COLOR,
    borderColor: DEFAULT_TABLE_COLOR_BORDER
  },
  LessonAttributesRowColorSnowPass: {
    backgroundColor: SNOWPASS_TABLE_COLOR,
    borderColor: SNOWPASS_TABLE_COLOR
  },
  LessonContentRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    borderWidth: "1px",
    borderStyle: "solid",
    paddingLeft: "1.25em",
    "@media (max-width: ${media_sm_max})": {
      fontSize: "0.9rem"
    }
  },
  [theme.breakpoints.down("sm")]: {
    LessonContentRow: {
      fontSize: "0.8rem"
    }
  },
  [theme.breakpoints.up("sm")]: {
    LessonContentRow: {
      fontSize: "1rem"
    }
  },
  LessonContentRowColorAdaptive: {
    backgroundColor: ADAPTIVE_LESSON_COLOR_SECONDARY,
    borderColor: ADAPTIVE_LESSON_COLOR_SECONDARY
  },
  LessonContentRowColorFirstTimer: {
    backgroundColor: FIRST_TIMER_LESSON_COLOR_SECONDARY,
    borderColor: FIRST_TIMER_LESSON_COLOR_SECONDARY
  },
  LessonContentRowColorExperienced: {
    backgroundColor: NON_FIRST_TIMERS_COLOR_SECONDARY,
    borderColor: NON_FIRST_TIMERS_COLOR_SECONDARY
  },
  LessonContentRowColorTable: {
    backgroundColor: DEFAULT_TABLE_COLOR_SECONDARY,
    borderColor: DEFAULT_TABLE_COLOR_BORDER
  },
  LessonContentRowColorSnowPass: {
    backgroundColor: SNOWPASS_TABLE_COLOR_SECONDARY,
    borderColor: SNOWPASS_TABLE_COLOR_SECONDARY
  },
  LessonContentRowInstructor: {
    backgroundColor: WHITECOLOR,
    borderColor: WHITECOLOR
  },
  LessonSectionRow: {
    display: "flex",
    height: "50px",
    backgroundColor: "white",
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: DEFAULT_TABLE_COLOR,
    paddingLeft: "10px",
    alignItems: "center",
    fontWeight: "700",
    fontSize: "1rem",
    fontFamily: ["Open Sans Condensed", "sans-serif"]
  },
  secondaryHeading: {
    marginLeft: "1em",
    marginBottom: "0em",
    fontWeight: 800
  },
  VerticalSpaceSM: {
    height: "10px"
  },
  VerticalSpaceMD: {
    height: "20px"
  },
  VerticalSpaceLG: {
    height: "40px"
  },
  SectionTitle: {
    color: BLUECOLOR4,
    fontSize: "1.45rem",
    fontFamily: ["Open Sans Condensed", "sans-serif"],
    fontWeight: "700"
  },
  SectionBodyWrapper: {
    margin: "auto",
    width: "95%",

    "@media (max-width: ${media_sm_max})": {
      width: "98%"
    }
  },
  CommentWrapper: {
    fontSize: "1.3rem",
    fontWeight: "bold"
  },
  FormTextarea: {
    width: "100%",
    height: "150px",
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: BLUECOLOR4,
    paddingLeft: "12px",
    borderRadius: "8px",
    color: BLUECOLOR4,
    resize: "none",
    "&::-webkit-input-placeholder": {
      color: GRAYCOLOR4,
      fontSize: "smaller"
    }
  }
}));

const activityNameSwitcher = name => {
  switch (name) {
    case "Ski":
      return "SKI";
    case "Snowboard":
      return "SNB";
    case "Telemark":
      return "TLM";
    default:
      break;
  }
};

const foodAllergyMessage = <div>I have Peanut Allergy</div>;

const CheckoutSection = props => {
  const [submitting_icon, setSubmitting_icon] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [infoSnackOpen, setInfoSnackOpen] = useState(false);
  const [infoSnackOpenSection, setinfoSnackOpenSection] = useState("");

  const handleInfoClick = section => {
    setInfoSnackOpen(!infoSnackOpen);
    setinfoSnackOpenSection(section);
  };

  const handleInfoClose = () => {
    setInfoSnackOpen(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleOpenSnackbar = foodAllergyMessage => {
    setOpenSnackbar(true);
    setSnackbarMessage(foodAllergyMessage);
  };

  const handleNext = async () => {
    const {
      showResortErrors,
      showActivityErrors,
      updateResortErrors,
      updateActivityErrors,
      openSection,
      activityReducer,
      profileErrors,
      submit,
      completeForm,
      Email,
      FirstName,
      resortName,
      country,
      tripMembers,
      familyMembers,
      startDate,
      endDate,
      tripLessons,
      lessonValidated
    } = props;
    // validate everything

    setSubmitting_icon(true);

    // scroll to resort section
    const resortErrors = validateResort(resortName);
    if (Object.keys(resortErrors).length !== 0) {
      showResortErrors(true);
      updateResortErrors(resortErrors);
      openSection({ section: RESORT });
      scroller.scrollTo(RESORT, scrollerOptions);
      setSubmitting_icon(false);
      return;
    }

    // scroll to resort section
    submit();
    if (Object.keys(profileErrors).length !== 0) {
      openSection({ section: PROFILE });
      setTimeout(() => {
        scrollToProfileError(profileErrors);
      }, 500);
      setSubmitting_icon(false);
      return;
    }

    // scroll to activity section
    const activityErrors = validateActivity(
      Object.values(activityReducer.main)
    );
    if (Object.keys(activityErrors).length !== 0) {
      // scroll to activity section
      showActivityErrors(true);
      updateActivityErrors(activityErrors);
      openSection({ section: DOING });
      scroller.scrollTo(DOING, scrollerOptions);
      setSubmitting_icon(false);
      return;
    }

    // validation process has taken take in the tripLessonMiddleware already
    if (lessonValidated) {
      // scroll to lessons section
      openSection({ section: LESSONS });
      scroller.scrollTo(LESSONS, scrollerOptions);
      setSubmitting_icon(false);
      return;
    }

    // TODO replace with new /members API
    const userID = localStorage.getItem("userID");
    let header = {};
    if (userID != null) {
      header = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken")
        }
      };
    }

    let masterMember;
    _.map(props.familyMembers, (value, key) => {
      if (value.isMaster) {
        masterMember = value;
        return;
      }
    });
    /* this is caused by user didnt click next button in the profile sections
       which means familyMembers didnt store the data for master member*/
    if (!masterMember) {
      masterMember = props.profile;
    }

    const requestData = {
      userID,
      when: props.when,
      where: props.where,
      profile: masterMember,
      who: props.familyMembers,
      lessons: props.tripLessons
    };

    agent.Trips.postTrip(requestData)
      .then(response => {
        localStorage.setItem("memberID", response.data.id);
        completeForm({
          Email,
          FirstName,
          resortName,
          country,
          tripStartDate: getFormattedDate(startDate),
          tripEndDate: getFormattedDate(endDate),
          familyMembers: getFomarttedMembers(familyMembers),
          allLessons: getAllGroupsOfLessons(
            getFormattedLessons(tripLessons, familyMembers)
          ),
          membersWithNoSnowpass: getMembersWithNoSnowpass(familyMembers),
          comment: props.comment,
          specificInstructor: props.specificInstructor
        });
      })
      .catch(error => {
        console.log(error);
        props.alertSubmitForm(false);
      });
  };

  const {
    changeComment,
    comment,
    tripMembers,
    familyMembers,
    startDate,
    endDate,
    country,
    resortName,
    manLesChosen,
    groLesChosen,
    priLesChosen,
    adaLesChosen,
    tripLessons
  } = props;

  Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const dateToString = (type, date, time, duration) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    const monChar = d.toLocaleString("default", { month: "short" });

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    if (type === "DOB") {
      return [day, month, year].join("-");
    } else if (type === "DETAILS") {
      return [day, monChar, year].join(" ");
    } else {
      const daysToAdd = parseInt((duration + "")[0]);
      const endDate = d.addDays(daysToAdd);
      var endmonth = "" + (endDate.getMonth() + 1),
        endDay = "" + endDate.getDate(),
        endYear = endDate.getFullYear();
      if (endmonth.length < 2) endmonth = "0" + endmonth;
      if (endDay.length < 2) endDay = "0" + endDay;
      const endMonChar = endDate.toLocaleString("default", {
        month: "short"
      });

      if (year === endYear && month === endmonth) {
        return day + " - " + endDay + " " + monChar + " " + time;
      } else if (year === endYear) {
        return (
          day + " " + monChar + " - " + endDay + " " + endMonChar + " " + time
        );
      } else {
        return (
          day +
          " " +
          monChar +
          " " +
          year +
          " - " +
          endDay +
          " " +
          endMonChar +
          " " +
          endYear +
          " " +
          time
        );
      }
    }
  };

  const classes = useStyles();

  const sectionGenerator = (array, header, type, isPrivate) => {
    {
      return array !== undefined && array.length > 0 ? (
        <div>
          <Grid container alignItems="center">
            <Grid item xs={1} md={1} />
            <Grid item xs={11} md={11}>
              <div className={classes.LessonSectionRow}>
                {" "}
                {header.charAt(0).toUpperCase() + header.slice(1)}
              </div>
              <div
                className={[
                  classes.LessonAttributesRow,
                  type === "ADAPTIVE"
                    ? classes.LessonAttributesRowColorAdaptive
                    : "",
                  type === "FIRSTTIMER"
                    ? classes.LessonAttributesRowColorFirstTimer
                    : "",
                  type === "EXPERIENCED"
                    ? classes.LessonAttributesRowColorExperienced
                    : ""
                ].join(" ")}
              >
                <div className={classes.div20}>
                  &nbsp;&nbsp;&nbsp;&nbsp;Time
                </div>
                <div className={classes.div20}>Participants</div>
                <div className={classes.div20}>Number of days</div>
                {isPrivate ? (
                  <div className={classes.div20}>Instructor</div>
                ) : null}
                <div className={classes.div20}>Unit Price</div>
              </div>
            </Grid>
          </Grid>

          {array.map(lesson => {
            return (
              <div key={lesson.tripLessonId}>
                <Grid container alignItems="center">
                  <Grid item xs={1} md={1}>
                    {lesson.activity === "ski" ? (
                      <div
                        style={{
                          border: "2px solid black",
                          borderRadius: "20px",
                          textAlign: "center",
                          backgroundColor: "white",
                          fontSize: "10px"
                        }}
                      >
                        {"SKI"}
                      </div>
                    ) : (
                      <div
                        style={{
                          border: "2px solid black",
                          borderRadius: "20px",
                          textAlign: "center",
                          backgroundColor: "black",
                          fontSize: "10px",
                          color: "white"
                        }}
                      >
                        {"SNB"}
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={11} md={11}>
                    <div
                      className={[
                        classes.LessonContentRow,
                        type === "ADAPTIVE"
                          ? classes.LessonContentRowColorAdaptive
                          : "",
                        type === "FIRSTTIMER"
                          ? classes.LessonContentRowColorFirstTimer
                          : "",
                        type === "EXPERIENCED"
                          ? classes.LessonContentRowColorExperienced
                          : ""
                      ].join(" ")}
                    >
                      <div className={classes.contentDiv20}>
                        {
                          <div>
                            <div>
                              {dateToString(
                                "LESSON",
                                lesson.startDate,
                                lesson.startTime,
                                lesson.durationDays
                              )}
                            </div>
                            {lesson.flexibleDays ? (
                              <div>
                                {" (" +
                                  lesson.durationDays +
                                  " of " +
                                  lesson.flexibleDays +
                                  " days)"}
                              </div>
                            ) : null}
                          </div>
                        }
                      </div>
                      <div className={classes.contentDiv20}>
                        {lesson.participants.map(participant => {
                          return (
                            <div key={participant}>
                              {familyMembers[participant].FirstName}
                            </div>
                          );
                        })}
                      </div>
                      <div className={classes.div20}>{lesson.durationDays}</div>
                      {isPrivate ? (
                        <div className={classes.div20}>
                          {lesson.selectedInstructorOption == "1" ||
                          lesson.selectedInstructorOption == "3"
                            ? "Assigned"
                            : lesson.instructorDetails}
                        </div>
                      ) : null}
                      <div className={classes.div20}>
                        {"TBC "}
                        <InfoIcon
                          fontSize="inherit"
                          onClick={() => handleInfoClick(type + header + "")}
                        />
                      </div>
                    </div>
                    {lesson.selectedInstructorOption != undefined &&
                    lesson.selectedInstructorOption == 3 ? (
                      <div
                        className={[
                          classes.LessonContentRow,
                          classes.LessonContentRowInstructor
                        ].join(" ")}
                      >
                        <div className={classes.div100}>
                          {lesson.instructorDetails}
                        </div>
                      </div>
                    ) : null}
                  </Grid>
                </Grid>
              </div>
            );
          })}

          <div className={classes.VerticalSpaceSM} />
          {infoSnackOpen && infoSnackOpenSection === type + header + "" ? (
            <SnackbarContent
              key={type}
              open={infoSnackOpen}
              message={
                "To be confirmed by resort after they've confirmed availability"
              }
              action={
                <div>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={handleInfoClose}
                  >
                    Dismiss
                  </Button>
                </div>
              }
            />
          ) : null}
          <div className={classes.VerticalSpaceSM} />
        </div>
      ) : null;
    }
  };

  //tripLessons
  let allLessonsArray = Object.keys(tripLessons).map(lesson => {
    return tripLessons[lesson];
  });

  //filter function
  const filterfunc = (firstTimer, adaptive, lessonType, ageGroup) => {
    return allLessonsArray.filter(
      lesson =>
        lesson.firstTimer === firstTimer &&
        lesson.adaptive === adaptive &&
        lesson.lessonType === lessonType &&
        lesson.participants.length > 0 &&
        ((ageGroup === "Everyone else" && lesson.ageGroup !== "3 - 5yrs") ||
          (ageGroup !== "Everyone else" &&
            ageGroup !== null &&
            lesson.ageGroup === ageGroup) ||
          ageGroup === null)
    );
  };
  // both ski and snow
  const objToMap = {
    adaptiveLessons: {
      firstTimerLessons: {
        "3-5yrs": filterfunc(true, true, "private", "3 - 5yrs"),
        "Everyone else": filterfunc(true, true, "private", "Everyone else")
      },
      experiencedLessons: {
        "3-5yrs": filterfunc(false, true, "private", "3 - 5yrs"),
        "Everyone else": filterfunc(false, true, "private", "Everyone else")
      }
    },
    firstTimerLessons: {
      private: {
        "3-5yrs": filterfunc(true, false, "private", "3 - 5yrs"),
        "Everyone else": filterfunc(true, false, "private", "Everyone else")
      },
      group: {}
    },
    experiencedLessons: {
      private: {
        "3-5yrs": filterfunc(false, false, "private", "3 - 5yrs"),
        "Everyone else": filterfunc(false, false, "private", "Everyone else")
      },
      group: {}
    }
  };

  //age values function
  const distinctAgegrps = (firstTimer, adaptive, lessonType) => {
    return [
      ...new Set(
        tripLessons
          .filter(
            lesson =>
              lesson.firstTimer === firstTimer &&
              lesson.adaptive === adaptive &&
              lesson.lessonType === lessonType &&
              lesson.participants.length > 0
          )
          .map(item => {
            return {
              agegrp: item.ageGroup,
              minAge: item.minimumAge
            };
          })
      )
    ];
  };

  //map group values
  distinctAgegrps(true, false, "group").map(item => {
    objToMap.firstTimerLessons.group = {
      ...objToMap.firstTimerLessons.group,
      [item.minAge + "|" + item.agegrp]: filterfunc(
        true,
        false,
        "group",
        item.agegrp
      )
    };
  });

  distinctAgegrps(false, false, "group").map(item => {
    objToMap.experiencedLessons.group = {
      ...objToMap.experiencedLessons.group,
      [item.minAge + "|" + item.agegrp]: filterfunc(
        false,
        false,
        "group",
        item.agegrp
      )
    };
  });

  //sort for display and update mapping object
  const sortedFirstTimerGroupKeys = Object.keys(
    objToMap.firstTimerLessons.group
  )
    .sort()
    .map(key => {
      let newKey = key.substring(key.indexOf("|") + 1);
      objToMap.firstTimerLessons.group[newKey] =
        objToMap.firstTimerLessons.group[key];
      delete objToMap.firstTimerLessons.group[key];
      return newKey;
    });

  const sortedExperiencedGroupKeys = Object.keys(
    objToMap.experiencedLessons.group
  )
    .sort()
    .map(key => {
      let newKey = key.substring(key.indexOf("|") + 1);
      objToMap.experiencedLessons.group[newKey] =
        objToMap.experiencedLessons.group[key];
      delete objToMap.experiencedLessons.group[key];
      return newKey;
    });

  return (
    <div className={classes.SectionBodyWrapper}>
      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        action={
          <Button
            key="close"
            color="secondary"
            size="small"
            onClick={handleCloseSnackbar}
          >
            Close
          </Button>
        }
      />
      <div className={classes.VerticalSpaceSM} />

      <Grid container alignItems="center">
        <Grid item xs={1} md={1} />
        <Grid item xs={10} md={10} item container>
          <Grid item xs={12} md={12}>
            <div className={classes.SectionTitle}>Trip Details</div>
            <div className={classes.VerticalSpaceSM} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.boldText}>
              {dateToString("DETAILS", startDate, null, null) +
                " to " +
                dateToString("DETAILS", endDate, null, null)}
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.boldText}>
              {resortName}, {country}
            </div>
          </Grid>
        </Grid>
        <Grid item xs={1} md={1} />
      </Grid>

      <div className={classes.VerticalSpaceSM} />

      <Grid container alignItems="center">
        <Grid item xs={1} md={1} />
        <Grid item xs={11} md={11}>
          <div className={classes.SectionTitle}>Guest Details</div>
          <div className={classes.VerticalSpaceSM} />
          <div>
            <div>
              <div>
                <div
                  className={[
                    classes.LessonAttributesRow,
                    classes.LessonAttributesRowColorTable
                  ].join(" ")}
                  style={{ paddingLeft: "1.25em" }}
                >
                  <div className={classes.div20}>Trip Member</div>
                  <div className={classes.div20}>DOB</div>
                  <div className={classes.div20}>Age</div>
                  <div className={classes.div20}>Ability Level</div>
                  <div className={classes.div20}>Food Allergies</div>
                  <div className={classes.div20}>Disability</div>
                </div>
                {Object.keys(familyMembers).map(member => {
                  const memberData = familyMembers[member];
                  const age =
                    new Date().getFullYear() - memberData.DOB.getFullYear();
                  const abilityLevels =
                    memberData.SkiAbility + " - " + memberData.SnowboardAbility;

                  return (
                    <div key={member + "maindiv"}>
                      <div
                        key={member}
                        className={[
                          classes.LessonContentRow,
                          classes.LessonContentRowColorTable
                        ].join(" ")}
                      >
                        <div className={classes.div20}>
                          {memberData.FirstName}
                        </div>
                        <div className={classes.div20}>
                          {dateToString("DOB", memberData.DOB, "", "")}
                        </div>
                        <div className={classes.div20}>{age}</div>
                        <div className={classes.div20}>{abilityLevels}</div>
                        <div className={classes.div20}>
                          {memberData.hasAllergy ? "Yes" : "None"}
                        </div>
                        <div className={classes.div20}>
                          {memberData.IsDisabled
                            ? memberData.DisabilityDetail
                            : "None"}
                        </div>
                      </div>
                      {(memberData.hasAllergy &&
                        memberData.allergyDetails.length > 0) ||
                      (memberData.IsDisabled &&
                        memberData.DisabilityDetail.length > 0) ? (
                        <div
                          key={member + "Allergy"}
                          className={[
                            classes.LessonContentRow,
                            classes.LessonContentRowColorTable
                          ].join(" ")}
                        >
                          <div className={classes.div100}>
                            {memberData.hasAllergy &&
                            memberData.allergyDetails.length > 0 ? (
                              <div>
                                {<strong>&nbsp;&nbsp;Food Allergy : </strong>}
                                {memberData.allergyDetails}
                              </div>
                            ) : null}
                            {memberData.IsDisabled &&
                            memberData.DisabilityDetail.length > 0 ? (
                              <div>
                                {<strong>&nbsp;&nbsp;Disability: </strong>}
                                {memberData.DisabilityDetail}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={classes.VerticalSpaceSM} />
          </div>
        </Grid>
      </Grid>

      <div className={classes.VerticalSpaceSM} />

      <div>
        <Grid container alignItems="center">
          <Grid item xs={1} md={1} />
          <Grid item xs={11} md={11}>
            <div className={classes.SectionTitle}>Lessons</div>
            <div className={classes.VerticalSpaceSM} />
          </Grid>
        </Grid>

        {filterfunc(true, true, "private", null).length > 0 ||
        filterfunc(false, true, "private", null).length > 0 ? (
          <Grid container alignItems="center">
            <Grid item xs={1} md={1} />
            <Grid item xs={11} md={11}>
              <div className={classes.LessonSectionRow}>
                {" "}
                {"ADAPTIVE LESSONS"}
              </div>
            </Grid>
          </Grid>
        ) : null}
        {objToMap.adaptiveLessons.firstTimerLessons["3-5yrs"].length > 0 ||
        objToMap.adaptiveLessons.firstTimerLessons["Everyone else"].length >
          0 ? (
          <div>
            <div className={classes.VerticalSpaceSM} />
            <Grid container alignItems="center">
              <Grid item xs={1} md={1} />
              <Grid item xs={11} md={11}>
                <h6 className={classes.secondaryHeading}>{"FIRST TIMERS"}</h6>
              </Grid>
            </Grid>
            <div className={classes.VerticalSpaceSM} />

            {sectionGenerator(
              objToMap.adaptiveLessons.firstTimerLessons["3-5yrs"],
              "3-5yr olds",
              "ADAPTIVE",
              true
            )}
            {sectionGenerator(
              objToMap.adaptiveLessons.firstTimerLessons["Everyone else"],
              "Everyone else",
              "ADAPTIVE",
              true
            )}
          </div>
        ) : null}
        {objToMap.adaptiveLessons.experiencedLessons["3-5yrs"].length > 0 ||
        objToMap.adaptiveLessons.experiencedLessons["Everyone else"].length >
          0 ? (
          <div>
            <div className={classes.VerticalSpaceSM} />
            <Grid container alignItems="center">
              <Grid item xs={1} md={1} />
              <Grid item xs={11} md={11}>
                <h6 className={classes.secondaryHeading}>{"EXPERIENCED"}</h6>
              </Grid>
            </Grid>
            <div className={classes.VerticalSpaceSM} />

            {sectionGenerator(
              objToMap.adaptiveLessons.experiencedLessons["3-5yrs"],
              "3-5yr olds",
              "EXPERIENCED",
              true
            )}
            {sectionGenerator(
              objToMap.adaptiveLessons.experiencedLessons["Everyone else"],
              "Everyone else",
              "EXPERIENCED",
              true
            )}
          </div>
        ) : null}

        {filterfunc(true, false, "private", null).length > 0 ||
        filterfunc(true, false, "group", null).length > 0 ? (
          <div>
            <div className={classes.VerticalSpaceMD} />
            <Grid container alignItems="center">
              <Grid item xs={1} md={1} />
              <Grid item xs={11} md={11}>
                <div className={classes.LessonSectionRow}>
                  {" "}
                  {"FIRST TIMERS LESSONS"}
                </div>
              </Grid>
            </Grid>
          </div>
        ) : null}
        {filterfunc(true, false, "group", null).length > 0 ? (
          <div>
            <div className={classes.VerticalSpaceSM} />
            <Grid container alignItems="center">
              <Grid item xs={1} md={1} />
              <Grid item xs={11} md={11}>
                <h6 className={classes.secondaryHeading}>{"GROUP LESSONS"}</h6>
              </Grid>
            </Grid>
            <div className={classes.VerticalSpaceSM} />
            <div>
              {sortedFirstTimerGroupKeys.map(agegrp => {
                return (
                  <div key={agegrp}>
                    {sectionGenerator(
                      objToMap.firstTimerLessons.group[agegrp],
                      agegrp,
                      "FIRSTTIMER",
                      false
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
        {filterfunc(true, false, "private", null).length > 0 ? (
          <div>
            <div className={classes.VerticalSpaceSM} />
            <Grid container alignItems="center">
              <Grid item xs={1} md={1} />
              <Grid item xs={11} md={11}>
                <h6 className={classes.secondaryHeading}>
                  {"PRIVATE LESSONS"}
                </h6>
              </Grid>
            </Grid>
            <div className={classes.VerticalSpaceSM} />
            <div>
              {Object.keys(objToMap.firstTimerLessons.private).map(agegrp => {
                return (
                  <div key={agegrp}>
                    {sectionGenerator(
                      objToMap.firstTimerLessons.private[agegrp],
                      agegrp,
                      "FIRSTTIMER",
                      true
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}

        {filterfunc(false, false, "private", null).length > 0 ||
        filterfunc(false, false, "group", null).length > 0 ? (
          <div>
            <div className={classes.VerticalSpaceMD} />
            <Grid container alignItems="center">
              <Grid item xs={1} md={1} />
              <Grid item xs={11} md={11}>
                <div className={classes.LessonSectionRow}>
                  {" "}
                  {"EXPERIENCED LESSONS"}
                </div>
              </Grid>
            </Grid>
            <div className={classes.VerticalSpaceSM} />
          </div>
        ) : null}
        {filterfunc(false, false, "group", null).length > 0 ? (
          <div>
            <Grid container alignItems="center">
              <Grid item xs={1} md={1} />
              <Grid item xs={11} md={11}>
                <h6 className={classes.secondaryHeading}>{"GROUP LESSONS"}</h6>
              </Grid>
            </Grid>
            <div className={classes.VerticalSpaceSM} />
            <div>
              {sortedExperiencedGroupKeys.map(agegrp => {
                return (
                  <div key={agegrp}>
                    {sectionGenerator(
                      objToMap.experiencedLessons.group[agegrp],
                      agegrp,
                      "EXPERIENCED",
                      false
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
        {filterfunc(false, false, "private", null).length > 0 ? (
          <div>
            <div className={classes.VerticalSpaceSM} />
            <Grid container alignItems="center">
              <Grid item xs={1} md={1} />
              <Grid item xs={11} md={11}>
                <h6 className={classes.secondaryHeading}>
                  {"PRIVATE LESSONS"}
                </h6>
              </Grid>
            </Grid>
            <div className={classes.VerticalSpaceSM} />
            <div>
              {Object.keys(objToMap.experiencedLessons.private).map(agegrp => {
                return (
                  <div key={agegrp}>
                    {sectionGenerator(
                      objToMap.experiencedLessons.private[agegrp],
                      agegrp,
                      "EXPERIENCED",
                      true
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
      <Grid container alignItems="center">
        <Grid item xs={1} md={1} />
        <Grid item xs={11} md={11}>
          <div className={classes.VerticalSpaceLG} />
          <div className={classes.CommentWrapper}>COMMENTS:</div>
          <div style={{ textAlign: "center" }}>
            <textarea
              className={classes.FormTextarea}
              placeholder="What do you want to get out of this lesson?"
              value={comment}
              onChange={e => changeComment(e.target.value)}
            />
          </div>

          <div className={classes.VerticalSpaceLG} />
          <div className={classes.LessonSectionRow}>
            {" "}
            Requires Plastic Snowpass Card
          </div>
          <div className={classes.VerticalSpaceSM} />
          <div
            className={[
              classes.LessonAttributesRow,
              classes.LessonAttributesRowColorSnowPass
            ].join(" ")}
            style={{ paddingLeft: "1.25em" }}
          >
            <div className={classes.div100}> Participants </div>
            {/* <div className={classes.div25}> Unit Price </div> */}
          </div>
          {Object.keys(familyMembers).map((member, key) => {
            return !familyMembers[member].hasSnowPass ? (
              <div
                key={key}
                className={[
                  classes.LessonContentRow,
                  classes.LessonContentRowColorSnowPass
                ].join(" ")}
              >
                <div className={classes.div100}>
                  &nbsp;&nbsp;{familyMembers[member].FirstName}
                </div>
                {/* <div className={classes.div25}>
                  {"TBC"}
                  <InfoIcon
                    fontSize="inherit"
                    onClick={() => handleInfoClick("snowpass")}
                  />
                </div> */}
              </div>
            ) : null;
          })}
          {/* {infoSnackOpen && infoSnackOpenSection === "snowpass" ? (
            <SnackbarContent
              key={"snowpass"}
              open={infoSnackOpen}
              message={
                "To be confirmed by resort after they've confirmed availability"
              }
              action={
                <div>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={handleInfoClose}
                  >
                    Dismiss
                  </Button>
                </div>
              }
            />
          ) : null} */}
          <div className={classes.VerticalSpaceMD} />

          <div style={{ textAlign: "right" }}>
            {submitting_icon ? (
              <NextButton>
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                {/* Showing Alert Diaglog */}
                <Dialog
                  open={props.alertSubmitForm}
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle>
                    <div className="Background">
                      <h3>ALMOST THERE</h3>
                    </div>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      className="Background"
                      id="alert-dialog-description"
                    >
                      Please do not click back or refresh your browser, we are
                      processing your order which will just take a moment.
                      <br />
                      <br />
                    </DialogContentText>
                    <div className="Background">
                      <CircularProgress color="primary" />
                    </div>
                  </DialogContent>
                </Dialog>
              </NextButton>
            ) : localStorage.getItem("userID") !== undefined &&
              localStorage.getItem("userID") !== null ? (
              <NextButton onClick={handleNext}>Send to Resort</NextButton>
            ) : (
              <NextButton onClick={handleNext}>
                REGISTER to send to resort
              </NextButton>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

function mapStateToProps(state) {
  const selector = formValueSelector(PROFILE);
  return {
    profileErrors: getFormSyncErrors(PROFILE)(state),
    tripMembers: state.tripWhoReducer.tripMembers,
    familyMembers: state.tripWhoReducer.familyMembers,
    startDate: state.tripWhenReducer.startDate,
    endDate: state.tripWhenReducer.endDate,
    resortName: state.tripWhereReducer.resortName,
    country: state.tripWhereReducer.country,
    manLesChosen: state.tripLessonReducer.manLesChosen,
    groLesChosen: state.tripLessonReducer.groLesChosen,
    priLesChosen: state.tripLessonReducer.priLesChosen,
    adaLesChosen: state.tripLessonReducer.adaLesChosen,
    tripLessons: state.tripLessonReducer.tripLessons,
    activityReducer: state.activityReducer,
    submittingProfile: isSubmitting(PROFILE)(state),
    open: state.sectionReducer.open,
    Email: selector(state, "Email"),
    FirstName: selector(state, "FirstName"),
    formValues: getFormValues(PROFILE)(state),
    specificInstructor: state.tripLessonReducer.specificInstructor,
    comment: state.tripLessonReducer.comment,
    alertSubmitFormOpen: state.tripCoreReducer.alertSubmitForm,
    lessonValidated: state.tripLessonReducer.lessonValidated,
    // post trip related data
    when: state.tripWhenReducer,
    where: state.tripWhereReducer,
    who: state.tripWhoReducer,
    activity: state.activityReducer,
    profile: state.profileReducer.data
  };
}

function mapDispatchToProps(dispatch) {
  return {
    submit: () => dispatch(submit(PROFILE)),
    showResortErrors: show => dispatch(showResortErrors(show)),
    showActivityErrors: show => dispatch(showActivityErrors(show)),
    showLessonErrors: show => dispatch(showLessonErrors(show)),
    updateResortErrors: errors => dispatch(updateResortErrors(errors)),
    updateActivityErrors: errors => dispatch(updateActivityErrors(errors)),
    updateLessonErrors: errors => dispatch(updateLessonErrors(errors)),
    openSection: section => dispatch(openSection(section)),
    completeForm: request => dispatch(completeForm(request)),
    changeComment: value => dispatch(change_comment(value)),
    alertSubmitForm: value => dispatch(alertSubmitForm(value))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(CheckoutSection);
