// Dependencies
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GoogleLogin from 'react-google-login';
import  Button  from "@material-ui/core/Button";
import { connect } from "react-redux";
import { SvgIcon } from "@material-ui/core";
import {gapi} from 'gapi-script';

import agent from "../../../agent";
import { userLogin } from "../../../redux/actions/loginAction";
import { LOGIN } from "../../../redux/actions/action-types";
import { loadUser } from "../../../redux/reducers/auth";
import Google from "../../../materials/google.svg";

import {
  incrementActiveStep,
  incrementActiveStepManual,
  getClientProfile,
  getProviderProfile
} from "../../../redux/actions/stepperActions"

const useStyles = makeStyles(theme => ({
  button: {
    width: "16em",
    height: "4em",
    "& span": {
      color: "black",
      fontFamily: "PT Sans Narrow",
      fontWeight: "bold" + "!important",
      marginLeft: 10,
      fontSize: "16px"
    },
    "& div": {
      marginLeft: 30,
      marginRight: 30
    },
    [theme.breakpoints.down("md")]: {
      width: "13em",
      // marginLeft: "2em",
      "& span": {
        marginLeft: 5
      },
      "& div": {
        marginLeft: 15,
        marginRight: 15
      }
    },
    [theme.breakpoints.down("lg")]: {
      width: "15em",
      // marginLeft: "2.8em",
      marginRight: 0,
      "& div": {
        marginLeft: "0",
        marginRight: "0" + "!important"
      }
    }
  },
  btnLabel: {
    color: "black",
    fontFamily: "PT Sans Narrow",
    fontWeight: "bold",
    textTransform: "none"
  },
  loginBtn: {
    borderRadius: "10px",
    width: "13em",
    height: "3em",
    border: "1px solid",
    [theme.breakpoints.down("sm")]: {
      width: "2em",
      height: "3em",
      margin: "1rem 0rem"
    },
    [theme.breakpoints.down("lg")]: {
      width: "13em"
    }
  },
  logoImg: {
    height: "20px",
    verticalAlign: "super",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0
    }
  },
}));

const GoogleLoginButton = props => {
  const classes = useStyles();
  const { userData } = props;
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const clientId =
    "747047264880-p5t7h6i8bp5ldfmeh0g68fcvgd5ldgis.apps.googleusercontent.com";

  useEffect(()=>{
    function start(){
      gapi.auth2.init({
        client_id: clientId,
        scope: ""
      })
    };
    gapi.load('client:auth2', start);
  });

  useEffect(() => {
    if (userData && userData.data.is_provider == false) {
      //Client
      if (userData.data.signup_step < 5) {
        const access = userData.data.access;
        const userObject = {
          user_id: userData.data.user_id,
          accessToken: access
        };
        props.loadUser(userObject);

        localStorage.setItem("resorter", JSON.stringify(userObject));
        agent.setToken(access);

        props.getClientProfile(userData.data.user_id);

        props.incrementActiveStepManual(userData.data.signup_step);
        props.history.replace("/sign-up");
      } else {
        const access = userData.data.access;
        const userObject = {
          user_id: userData.data.user_id,
          accessToken: access
        };
        props.loadUser(userObject);

        localStorage.setItem("resorter", JSON.stringify(userObject));
        agent.setToken(access);

        props.getClientProfile(userData.data.user_id);
        props.history.replace("/");
      }
    } else if (userData && userData.data.is_provider == true) {
      //Provider
      if (userData.data.signup_step < 6) {
        const access = userData.data.access;
        const userObject = {
          user_id: userData.data.user_id,
          accessToken: access
        };
        props.loadUser(userObject);

        localStorage.setItem("resorter", JSON.stringify(userObject));
        agent.setToken(access);

        props.getProviderProfile(userData.data.user_id);

        props.incrementActiveStepManual(userData.data.signup_step);
        props.history.replace("/sign-up");
      } else {
        const access = userData.data.access;
        const userObject = {
          user_id: userData.data.user_id,
          accessToken: access
        };
        props.loadUser(userObject);

        localStorage.setItem("resorter", JSON.stringify(userObject));
        agent.setToken(access);

        props.getProviderProfile(userData.data.user_id);
        props.history.replace("/");
      }
    }
  }, [userData]);

  const signup = (response) => {
    console.log("Successfully signed up in",response);
    setIsLoggedIn(true);
    
    const email = response.profileObj.email
    const password="google@res"

    agent.Users.login(email, password)
      .then(response => {
        console.log(response)
        if (response.status === 200) {
          props.updateUserData(response)
          setIsLoggedIn(true);
          window.localStorage.setItem("user_id", response.data.user_id);
          window.localStorage.setItem("signup_step", response.data.signup_step);
          window.localStorage.setItem("is_provider", response.data.is_provider);
          window.localStorage.setItem("refresh", response.data.refresh);
          window.localStorage.setItem("access", response.data.access);
        }
      })
      .catch(error => {
        props.updateUserData(error.response)
      });
  };


  const handleLoginFailure = (response) => {
    alert("Failed to log in", response);
  };

  return (
      <GoogleLogin
        onSuccess={signup}
        onFailure={handleLoginFailure}
        cookiePolicy={"single_host_origin"}
        responseType="code,token"
        className={classes.button}
        render={renderProps => (
          <Button
            className={classes.loginBtn}
            classes={{ label: classes.btnLabel }}
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            startIcon={
              <SvgIcon component="object">
                <img src={Google} className={classes.logoImg} />
              </SvgIcon>
            }
          >
            Continue with Google
          </Button>
        )}
        style={{ "border-radius": "3rem" }}
      />
  );
};

const mapStateToProps = state => {
  return {
    userData: state.loginReducer.userData,
  };
};

const mapDispatchToProps = dispatch => ({
  updateUserData: (response) => dispatch({ type: LOGIN, payload: response }),
  incrementActiveStep: () => dispatch(incrementActiveStep()),
  incrementActiveStepManual: stepNumber =>
    dispatch(incrementActiveStepManual(stepNumber)),
  loadUser: user => {
    dispatch(loadUser(user));
  },
  userLogin: (emailID, password) => {
    dispatch(userLogin(emailID, password));
  },
  getClientProfile: userId => dispatch(getClientProfile(userId)),
  getProviderProfile: userId => dispatch(getProviderProfile(userId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoogleLoginButton);
