import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import MessageRoomCard from "./MessageRoomCard";
import { Container } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import agent from "../../../agent";
import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  title: {
    textAlign: "center",
    marginTop: 20
    // fontSize: "large"
  },
  container: {
    minHeight: 300
  },
  button: {
    alignContent: "center"
  },
  gridRoot: {
    width: "100%",
    marginTop: 20
  },
  text: {
    fontFamily: "PT Sans Narrow"
  }
}));
const Inbox = props => {
  const classes = useStyles();

  const [inboxDataset, setInboxDataset] = useState();

  // using useEffect will just stop the infinite loop
  useEffect(() => {
    console.log("props.currentUser", props.currentUser);
    if (props.currentUser !== null) {
      agent.Message.getInboxDataset(props.currentUser.id)
        .then(response => {
          setInboxDataset(response.data);
        })
        .catch(e => console.log(e));
      console.log(inboxDataset);
    } else {
      // alert("please login first")
      console.log("no user");
    }
  }, [props.currentUser]);

  const displayRooms = inboxDataset => {
    console.log("inboxDataset ", inboxDataset);
    // when it is loading
    if (inboxDataset == undefined) {
      return (
        <Container maxWidth={"sm"} className={classes.container}>
          <Typography
            align={"center"}
            variant="h5"
            gutterBottom
            className={classes.text}
          >
            loading
          </Typography>
          <Typography
            align={"center"}
            variant="h5"
            gutterBottom
            className={classes.text}
          >
            or you haven't logged in yet
          </Typography>
        </Container>
      );
    } else if (inboxDataset.length == 0) {
      // If there is no message at all
      return (
        <Container maxWidth={"sm"}>
          <Typography
            align={"center"}
            variant="h5"
            gutterBottom
            className={classes.text}
          >
            you don't have any message yet
          </Typography>
        </Container>
      );
    } else {
      // the data is loaded
      return inboxDataset.map(inboxDataset => (
        <MessageRoomCard
          roomID={inboxDataset.id}
          name={inboxDataset.firstNameOfOtherGuy}
          latestMessage={inboxDataset.latestMessage}
          messageDate={inboxDataset.messageCreateTime}
          status={inboxDataset.status}
          avatarLink={inboxDataset.AvatarSrc}
        />
      ));
    }
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h4">
        Inbox
      </Typography>
      <Grid container className={classes.gridRoot}>
        <Container maxWidth="sm" className={classes.container}>
          {displayRooms(inboxDataset)}
        </Container>
      </Grid>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser
  };
}

export default connect(mapStateToProps)(Inbox);
