import React from "react";
import agent from "../../../agent";
import LikeCounter from "../../Common/Home/LikeCounter";

const styles = {
  root: {
    textAlign: "left"
  }
};

export default class ConnectInstructors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connectInstructorsCount: 0,
      connectInstructorsVoted: false,
      becomeInstructorCount: 0,
      becomeInstructorVoted: false
    };
  }

  componentDidMount() {
    agent.Votes.getVotes()
      .then(response => {
        this.setState({ ...response.data });
      })
      .catch(error => {});
  }

  handleVote = counter => {
    let id;
    if (counter === "connectInstructorsCount") {
      id = 4;
    } else {
      id = 5;
    }
    this.setState({ [counter]: this.state[counter] + 1 }, () => {
      agent.Votes.putVotes(`api/votes/${id}`, { votes: this.state[counter] });
    });
  };

  render() {
    const state = this.state;
    return (
      <div className="row justify-content-center ">
        <div className="col-11 col-sm-8 col-md-6" style={styles.root}>
          <h1 style={{ textAlign: "center" }}>
            Coming Soon...
            <br />
          </h1>
          <h5>
            Browse instructor profiles to book and follow your favourite ones.
            Read their latest blogs on ski/snowboard technique, resort
            recommendations, etc.
          </h5>
          <LikeCounter
            onVote={this.handleVote}
            count={state.connectInstructorsCount}
            counter="connectInstructorsCount"
            voted={state.connectInstructorsVoted}
          />
          <br />
          <h5>
            Are you an instructor wanting to reach a wider network of skiers or
            snowboarders? Would you like your own space - accessible only to
            your followers - to post blogs, video content etc?
          </h5>
          <LikeCounter
            onVote={this.handleVote}
            count={state.becomeInstructorCount}
            counter="becomeInstructorCount"
            voted={state.becomeInstructorVoted}
          />
          <br />
          <h5>
            <a href="/contact">Add comments</a>
          </h5>
        </div>
      </div>
    );
  }
}
