import {
  DISPLAY_UPCOMING_LESSON,
  DISPLAY_PAST_LESSON
} from "../actions/action-types";

const initialState = {
  upcomingLessonData: {},
  pastLessonData: {}
};

function displayVideoLessonReducer(state = initialState, action) {
  if (typeof state === "undefined") {
    return initialState;
  } else if (action.type === DISPLAY_UPCOMING_LESSON) {
    return { ...state, upcomingLessonData: action.payload.upcomingLessonData };
  } else if (action.type === DISPLAY_PAST_LESSON) {
    return { ...state, pastLessonData: action.payload.pastLessonData };
  } else return state;
}

export default displayVideoLessonReducer;
