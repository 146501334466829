import React from "react";
import FooterBar from "../../../components/Common/Footer/FooterBar";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    background: "#FFFFFF"
  },
  headingStyle: {
    color: "#1277B8",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 30,
    lineHeight: "140.62%",
    alignItems: "center",
    textAlign: "center"
  },
  textField: {
    height: 50,
    fontSize: 20,
    size: "small",
    width: "100%",
    padding: "0em",
    paddingLeft: "1rem",
    border: 10,
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    borderRadius: 0,
    marginBottom: 15,
    textAlign: "left"
  },
  textFieldStyle: {
    fontFamily: "PT Sans Narrow",
    fontWeight: "bold",
    color: "#1277B8",
    fontSize: 15,
    lineHeight: "140.62%"
  },
  textFieldStyleTitle: {
    fontFamily: "PT Sans Narrow",
    fontWeight: "bold",
    color: "#000000",
    fontSize: 24,
    lineHeight: "140.62%",
    textAlign: "center",
    marginTop: 15,
    marginBottom: 15
  },
  btnbot: {
    borderRadius: 100,
    background: "#FF6161",
    height: "35%",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontSize: "1.5vh",
    fontWeight: "normal",
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
    border: "none"
    // width: "100px"
  },
  tab: {
    fontFamily: "PT Sans Narrow",
    fontWeight: "bolder",
    fontSize: 18,
    textTransform: "none",
    color: "#1277B8",
    "&:focus": {
      outline: "none"
    },
    "& span": {
      alignItems: "normal"
    },
    textAlign: "left"
  },
  btn: {
    color: "red",
    height: "3em",
    width: "7em",
    margin: "2em",
    border: "1px solid #FF6161"
  },
  btnArrow: {
    position: "absolute",
    left: "0",
    transform: "rotate(180deg)"
  },
  btnpay: {
    borderRadius: 100,
    background: "#FF6161",
    height: 35,
    width: 70,
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontSize: 17,
    fontWeight: "normal",
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
    border: "none",
    marginTop: 20,
    marginBottom: 80
  },
  cardInfoContainer: {
    width: "75%",
    margin: "0 auto"
  },
  cardInfo: {
    height: 44,
    backgroundColor: "rgba(18, 119, 184, 0.15)"
  }
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function AutoGrid() {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root} align="center">
      <Grid
        container
        lg={8}
        md={9}
        sm={10}
        xs={12}
        direction="column"
        align="center"
      >
        <div align="left">
          <Link href="/PayoutTab">
            <Button
              variant="outlined"
              className={classes.btn}
              classes={{ root: classes.btnRoot, label: classes.btnLabel }}
            >
              <ArrowRightAltIcon
                className={classes.btnArrow}
                variant="outlined"
              />
              Back
            </Button>
          </Link>
        </div>

        <div>
          <p className={classes.headingStyle}>Payment and Payouts</p>
        </div>

        <div className={classes.cardInfoContainer}>
          <AppBar
            elevation={0} //clear the shadow
            className={classes.AppBar}
            position="static"
            color={"transparent"}
          >
            <Tabs
              className={classes.Tabs}
              // change the color of indicator to grey
              TabIndicatorProps={{
                style: { backgroundColor: "#1277B8" }
              }}
              variant="fullWidth"
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="Payments" className={classes.tab} disabled />
              <Tab label="Payouts" className={classes.tab} {...a11yProps(1)} />
            </Tabs>
          </AppBar>
        </div>

        <TabPanel value={value} index={1}>
          <div align="center">
            <Grid container xs={12}>
              <Grid align="center" item lg={11} md={12} sm={11} xs={12}>
                <div className={classes.textFieldStyleTitle}>
                  What's the address for this payout method?
                </div>
              </Grid>
              <Grid item lg={1} />
            </Grid>

            <Grid
              lg={6}
              md={6}
              sm={7}
              xs={9}
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              align="center"
            >
              <Grid item xs={12}>
                <div align="left" className={classes.textFieldStyle}>
                  STREET ADDRESS
                </div>
              </Grid>

              <Grid item lg={11} md={12} xs={11}>
                <div align="center" className={classes.textFieldStyle}>
                  <input
                    fullWidth
                    id="cardname"
                    variant="filled"
                    className={classes.textField}
                    size="small"
                  />
                </div>
              </Grid>

              <Grid item lg={12} xs={12}>
                <div align="left" className={classes.textFieldStyle}>
                  APT, SUITE, BLDG. (OPTIONAL)
                </div>
              </Grid>

              <Grid item lg={11} md={12} xs={11}>
                <div align="left" className={classes.textFieldStyle}>
                  <input
                    fullWidth
                    id="cardname"
                    variant="filled"
                    className={classes.textField}
                    size="small"
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div align="left" className={classes.textFieldStyle}>
                  CITY
                </div>
              </Grid>

              <Grid item lg={11} md={12} xs={11}>
                <div align="left" className={classes.textFieldStyle}>
                  <input
                    fullWidth
                    id="cardname"
                    variant="filled"
                    className={classes.textField}
                    size="small"
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div align="left" className={classes.textFieldStyle}>
                  STATE/PROVINCE
                </div>
              </Grid>

              <Grid item lg={11} md={12} xs={11}>
                <div align="left" className={classes.textFieldStyle}>
                  <input
                    fullWidth
                    id="cardname"
                    variant="filled"
                    className={classes.textField}
                    size="small"
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div align="left" className={classes.textFieldStyle}>
                  POSTCODE
                </div>
              </Grid>

              <Grid item lg={11} md={12} xs={11}>
                <div align="left" className={classes.textFieldStyle}>
                  <input
                    fullWidth
                    id="cardname"
                    variant="filled"
                    className={classes.textField}
                    size="small"
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div align="left" className={classes.textFieldStyle}>
                  COUNTRY/REGION
                </div>
              </Grid>

              <Grid item lg={11} md={12} xs={11}>
                <div align="left" className={classes.textFieldStyle}>
                  <input
                    fullWidth
                    id="cardname"
                    variant="filled"
                    className={classes.textField}
                    size="small"
                  />
                </div>
              </Grid>

              <Grid container item xs={12}>
                <Grid item lg={2} xs={1} />
                <Grid item sm={3} xs={4}>
                  <div align="center">
                    <Link href="/PayoutSuccess">
                      <button
                        color="primary"
                        variant="contained"
                        className={classes.btnpay}
                      >
                        Save
                      </button>
                    </Link>
                  </div>
                </Grid>
                <Grid item sm={2} xs={1} />
                <Grid item lg={2} sm={3} xs={4}>
                  <div align="center">
                    <Link href="/PayoutTab">
                      <button
                        color="primary"
                        variant="contained"
                        className={classes.btnpay}
                      >
                        Cancel
                      </button>
                    </Link>
                  </div>
                </Grid>
                <Grid item lg={3} md={2} sm={3} xs={2} />
              </Grid>
            </Grid>
          </div>
        </TabPanel>
      </Grid>
    </div>
  );
}

export default function PayoutAddress() {
  return (
    <div>
      <AutoGrid />
    </div>
  );
}
