import { CardContent, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import CollapsibleCheckBox from "../../../../CollapsibleCheckBox";
import { PricePerHour } from "../InputFields";

//Child Components
import Skiing from "./Skiing";
import UploadDocument from "./UploadDocument";

const VirtualLessons = props => {
  const {
    parent,
    nestingLevel,
    selectService,
    addSkiing,
    addSnowboarding,
    services,
    isDisabled,
    setSkiing,
    setSnowBoarding
  } = props;

  const [checkboxSelected, setCheckboxSelected] = useState(false);
  const [checkboxSnowBoardingSelected, setCheckboxSnowBoardingSelected] = useState(false);
  const [checkboxSkiingSelected, setCheckboxSkiingSelected] = useState(false);
  const [credential, setCredential] = useState("");
  

  const onCheckboxSelected = event => {
    setCheckboxSelected(event);
  };

  const onSkiingCheckboxSelected = event => {
    setCheckboxSkiingSelected(event);
    selectService(event, 14);
  };

  const onSnowBoardingCheckboxSelected = event => {
    setCheckboxSnowBoardingSelected(event);
    selectService(event, 15);
  };

  let [skiingDetail, setSkiingDetail] = useState({});
  let [snowboardingDetail, setSnowBoardingDetail] = useState({});

  useEffect(() => {
    if (services.length > 0 && services !== undefined){
      let isExpanded = false;
      services.forEach(service => {
        if (service.service_id === 14){
          setSkiingDetail(service);
          setSkiing(service);
          setCheckboxSkiingSelected(true);
          isExpanded = true;
        }
        if (service.service_id === 15){
          setSnowBoardingDetail(service);
          setSnowBoarding(service);
          setCheckboxSnowBoardingSelected(true);
          isExpanded = true;
        }
      });
      isExpanded ? setCheckboxSelected(true) : setCheckboxSelected(false);
    }
  }, [services]);

  const hasLink = (skiingDetail.credential_link || snowboardingDetail.credential_link) ? true : false;
  useEffect(() => {
    if(skiingDetail.credential_link){
      setCredential(skiingDetail.credential_link);
    } else {
      setCredential(snowboardingDetail.credential_link);
    }
  }, [skiingDetail, snowboardingDetail]);

  useEffect(() => {
    props.setLessonCredential(credential);
  }, [credential]);

  const Skiing = (parent, nestingLevel) => {
    const inputFields = () => {
      return (
        <CardContent>
          <Grid
            container
            justify="center"
            alignItems="center"
            xs={8}
            md={8}
            lg={8}
          >
            <PricePerHour onTextChange={addSkiing} defaultPrice={skiingDetail.price_per_hour} isDisabled={isDisabled}/>
          </Grid>
          {/* <UploadDocument isDisabled={isDisabled}/> */}
        </CardContent>
      );
    };

    const children = [inputFields];

    return (
      <CollapsibleCheckBox
        name={"Skiing"}
        text="SKIING"
        parent={parent}
        children={children}
        nestingLevel={nestingLevel + 1}
        onCheckboxSelected={onSkiingCheckboxSelected}
        isDisabled={isDisabled}
        isSelected={checkboxSkiingSelected}
      />
    );
  };

  const Snowboarding = (parent, nestingLevel) => {
    const inputFields = () => {
      return (
        <CardContent>
          <Grid
            container
            justify="center"
            alignItems="center"
            xs={8}
            md={8}
            lg={8}
          >
            <PricePerHour onTextChange={addSnowboarding} defaultPrice={snowboardingDetail.price_per_hour} isDisabled={isDisabled}/>
          </Grid>
          {/* <UploadDocument isDisabled={isDisabled}/> */}
        </CardContent>
      );
    };

    const children = [inputFields];

    return (
      <CollapsibleCheckBox
        name={"Snowboarding"}
        text="SNOWBOARDING"
        parent={parent}
        children={children}
        nestingLevel={nestingLevel + 1}
        onCheckboxSelected={onSnowBoardingCheckboxSelected}
        isDisabled={isDisabled}
        isSelected={checkboxSnowBoardingSelected}
      />
    );
  };

  const uploadFile = () => {
    const text = "All providers of virtual lessons will need to produce valid credentials"
    return <UploadDocument onImageUpload={addSkiing} onImageUpload2={addSnowboarding} isDisabled={isDisabled} hasFile={hasLink} text={text} fileLink={credential}/>
  };

  const children = [Skiing, Snowboarding, uploadFile];
  return (
    <CollapsibleCheckBox
      name={"VirtualLessons"}
      text="VIRTUAL LESSONS"
      parent={null}
      children={children}
      nestingLevel={0}
      onCheckboxSelected={onCheckboxSelected}
      isDisabled={isDisabled}
      isSelected={checkboxSelected}
    />
    
  );
};

export default VirtualLessons;
