import React, { Component } from "react";
import { DateDisplay } from "../../../../styling/styled-components/whereSection";

export default class WhereSectionCustomDateInput extends Component {
  render() {
    const { value, onClick } = this.props;

    return <DateDisplay onClick={onClick}>{value}</DateDisplay>;
  }
}
