import React, { useState } from "react";
import { connect } from "react-redux";
import { plugToRequest } from "react-cookies";
import { useEffect } from "react";
import agent from "../../../agent";
import { loadCurrentInstructor } from "../../../redux/actions/virtInstructorDataActions";
import { sendHostName } from "../../../redux/actions/exploreHostsActions";

import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";

import Header from "../../../components/virtualLessons/home/HeaderSection.js";
import SignUpSection from "../../../components/virtualLessons/home/SignUpSection.jsx";
import InstructorCard from "../../../components/virtualLessons/InstructorCard/InstructorCard.js";
import ContentFilter from "../../../components/virtualLessons/home/ContentFilterInstructor";
import SavedInstructors from "../../../components/virtualLessons/home/SavedInstructors";
import CardPagination from "../../../components/Common/CardPagination";

import FiltersForExploreInstructors from "../../../components/virtualLessons/home/filtersForExploreInstructors";
import SearchCardForExploreInstructors from "../../../components/virtualLessons/home/SearchCardForExploreInstructors";

const useStyles = makeStyles(theme => ({
  main: {
    justifyContent: "center",
    alignItems: "center"
  },
  backgroundContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "5em",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0px"
    }
  },
  cards: {
    paddingBottom: "4em"
  },
  headingGrid: {
    marginBottom: "32px"
  },
  heading: {
    fontFamily: "PT Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "29px",
    lineHeight: "45px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.01em",
    color: "#1277B8",
    padding: "0 1rem"
  },
  hideSearchCard: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      "& .MuiCard-root": {
        margin: "4% 0%"
      }
    }
  }
}));

const ExploreInstructors = props => {
  const classes = useStyles();

  // maybe needed in future development
  /* {  const API_PATH = "../api/";
  let responseData;

  useEffect(() => {
    //getting instructor profiles from database
    agent.Instructors.getAll()
      .then(response => {
        responseData = response.data;
        responseData.map(item => {
          item.name = item.FirstName + " " + item.LastName;
          //TODO this is dummy data - pull latest changes from signup branches (feature/brody/InstructorSignupResort) for resorts, criteria (criteria and activities renamed as specialities), pricing (named as rates),
          item.criteria = [];
          item.resorts = [];
          item.dates = [];
          item.activities = [];
          item.abilities = [];
          item.pricing = "";
        });
        //props.getName(responseData);
        setInstructors(responseData);
      })
      .catch(error => {
        console.log(error);
      });
  }, [props.name, props.getName]);} */

  const slogan = "Browse & Book Private Instructors";
  const underSlogan = "";
  const lookingFor = false;
  const heading = "Bookmark the instructors you like for your trip.";

  return (
    <div>
      <Grid container className="main">
        <Grid
          container
          item
          sm={12}
          xs={12}
          className={classes.backgroundContainer}
        >
          <Header
            slogan={slogan}
            underSlogan={underSlogan}
            lookingFor={lookingFor}
            center={true}
          />
        </Grid>

        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.hideSearchCard}
        >
          <SearchCardForExploreInstructors />
        </Grid>

        <Grid
          container
          justify="center"
          alignItems="center"
          item
          sm={12}
          xs={12}
          className={classes.headingGrid}
        >
          <Typography className={classes.heading}>{heading}</Typography>
        </Grid>

        <Grid container justify="center" alignItems="center">
          <FiltersForExploreInstructors />
        </Grid>

        <Grid container justify="center" alignItems="center">
          <SignUpSection />
        </Grid>
      </Grid>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    getName: name => dispatch(sendHostName(name))
  };
}

function mapStateToProps(state) {
  return {
    name: state.exploreHostsReducer.name
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExploreInstructors);
