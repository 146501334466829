import { DISPLAY_UPCOMING_LESSON, DISPLAY_PAST_LESSON } from "./action-types";

export const displayUpcomingLesson = upcomingLessonData => {
  return {
    type: DISPLAY_UPCOMING_LESSON,
    payload: { upcomingLessonData }
  };
};

export const displayPastLesson = pastLessonData => {
  return {
    type: DISPLAY_PAST_LESSON,
    payload: { pastLessonData }
  };
};
