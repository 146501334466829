import React, { Fragment, useEffect } from "react";
import { Field } from "redux-form";
import { getCountryList } from "../../../../redux/actions/homePageAction.js";
import { makeStyles } from "@material-ui/core/styles";

// Component Input
import { renderTextAreaField } from "../../FormField/FormField.js";

import { Typography, Grid } from "@material-ui/core";

// import useStyles from "../ClientInfoStyles";
import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
  selectedRoot: {
    // "& .MuiOutlinedInput-notchedOutline": {
    //   // borderWidth: "0"
    //   borderColor: "black  ",
    //   borderWidth: "1px  "
    // },
    // "&:hover .MuiOutlinedInput-notchedOutline": {
    //   // borderWidth: "0"
    //   borderColor: "black  ",
    //   borderWidth: "1px  "
    // },
    // "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    //   // borderWidth: "0"
    //   borderColor: "black  ",
    //   borderWidth: "1px  "
    // },
    // "& fieldset.MuiOutlinedInput-notchedOutline": {
    //   borderColor: "black !important"
    // },
  }
}));

const Countries = props => {
  const { renderSelectField } = props;
  const { country_list, countries, isDisabled, isNative, isEditable } = props;
  const classes = useStyles();
  // console.log(props);

  // Find out the selected resorts' country names
  let countryNames = [];
  if (countries.length > 0){
    countries.forEach(countryId => {
      let result = country_list.find(country => country.id === countryId);
      // console.log(result["country_name"]);
      countryNames.push(result["country_name"]);
    });
  }
  // console.log(countryNames);
   

  useEffect(() => {
    props.getCountryList();
  }, []);

  // This obj is used to decide what data should be render 
  // on different modes (e.g., editable mode, disable mode)
  let obj = {}
  if (isEditable) {
    obj = {
      disabled: isDisabled,
      native: isNative
    }
  } else {
    obj = {
      disabled: isDisabled,
      native: isNative,
      value: countryNames,
      renderValue: (selected) => {
          console.log("Form Field");
          console.log(selected)
          if (selected.length > 0) {
            let selectedOptions = selected.join(", ");
            return selectedOptions;
          }
        }
      
    }
  }

  return (
    // <Grid item xs={12} sm={4}>
    <Grid>
      <Field
        name="countries"
        component={renderSelectField}
        options={country_list}
        // spacing={{ width: "80%" }}
        spacing={{}}
        props={obj}
        type="select"
        label="Countries"
        placeholder="Please select an option"
        onChange={event => props.setCountries(event.target.value)}
        className={classes.selectedRoot}
      />
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    country_list: state.homePageReducer.country_list
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getCountryList: () => dispatch(getCountryList())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Countries);
