import Timeline from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import React, { useEffect, useState } from "react";

const GanttCalendar = props => {
  const {
    groups,
    items,
    startdate,
    handleItemMove,
    handleItemResize,
    itemRenderer
  } = props;
  let calendar = <div />;

  const [startdateLocal, setStart] = useState(
    startdate.getTime() - 60000 * 60 * 24
  );
  const [enddateLocal, setEnd] = useState(
    startdate.getTime() + 60000 * 60 * 24
  );

  const onTimeChange = (
    visibleTimeStart,
    visibleTimeEnd,
    updateScrollCanvas
  ) => {
    setStart(visibleTimeStart);
    setEnd(visibleTimeEnd);
    updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
  };

  useEffect(() => {
    setStart(startdate.getTime() - 60000 * 60 * 24);
    setEnd(startdate.getTime() + 60000 * 60 * 24);
  }, [startdate]);

  //render
  if (items)
    calendar = (
      <div>
        {/* pass through group(user names) information here */}
        <Timeline
          id={items.length}
          groups={groups}
          items={items}
          visibleTimeStart={startdateLocal}
          visibleTimeEnd={enddateLocal}
          onTimeChange={onTimeChange}
          sidebarWidth={150}
          canMove
          canResize
          onItemMove={handleItemMove}
          onItemResize={handleItemResize}
          itemRenderer={itemRenderer}
        />
      </div>
    );

  return <div>{calendar}</div>;
};

export default GanttCalendar;
