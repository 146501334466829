import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

function handleSearch() {}

const useStyles = makeStyles(theme => ({
  header: {
    width: 285,
    height: 87,
    display: "flex",
    alignItems: "center",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 30,
    color: "#4a443f",
    marginTop: 18,
    fontFamily: "PT Sans"
  },
  test: {
    marginTop: "0.3em"
  },
  selectRoot: {
    color: "black",
    display: "inline",
    "& .svg.MuiSvgIcon-root .MuiStepIcon-completed, path": {
      color: "#4A443F"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black  ",
      borderWidth: "1px  "
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black  ",
      borderWidth: "1px  "
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black  ",
      borderWidth: "1px  "
    }
  },
  selectRoot2: {
    display: "inline",
    paddingBottom: "0em",
    paddingTop: "0em",
    marginBottom: "0em",
    height: "2.1em",
    lineHeight: 2.5
  },
  labels: {
    display: "inline",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    color: "#4A443F",
    paddingTop: "0.4em",
    marginRight: "1em",
    verticalAlign: "sub"
  },
  labels2: {
    display: "inline",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    color: "#4A443F",
    marginRight: "1em"
  },
  dateRoot: {
    "& .MuiOutlinedInput-root": {
      height: "2.1em",
      width: "11.5em"
    },
    marginTop: "0em",
    marginBottom: "0em",
    "& .MuiIconButton-root": {
      padding: "7px",
      marginTop: "1px"
    },
    "& svg.MuiSvgIcon-root": {
      color: "black",
      height: "15px"
    },
    "& svg.MuiSvgIcon-root .MuiStepIcon-completed, path": {
      color: "#4A443F",
      opacity: 0.5
    }
  },
  searchCard: {
    padding: "0 48px 0 48px",
    borderRadius: 15,
    boxShadow: "7px 7px 20px rgba(0, 0, 0, 0.25)",
    minWidth: 406,
    maxWidth: 406,
    minHeight: 415,
    maxHeight: 415,
    marginLeft: "4em"
  },
  row: {
    marginBottom: "0.7em"
  },
  button: {
    borderRadius: 100,
    margin: "30px 100px 30px 80px",
    height: "3em",
    width: "10em",
    justify: "center",
    background: "#FF6161"
  },
  buttonLabel: {
    fontWeight: "300",
    textTransform: "none",
    color: "white",
    fontFamily: "Roboto"
  },
  select1: {
    width: "10.7em"
  },
  select2: {
    width: "13em"
  },
  date2: {
    marginLeft: "0.5em"
  }
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#FFFFFF"
    },
    secondary: {
      main: "#FF6161"
    }
  }
});

const SearchCard = props => {
  const classes = useStyles();
  const today = new Date();
  const minStartDate = new Date(new Date().setDate(today.getDate() + 4));
  const minEndDate = new Date(new Date().setDate(today.getDate() + 11));
  const [startDate, setStartDate] = useState(minStartDate);
  const [endDate, setEndDate] = useState(minEndDate);

  useEffect(() => {
    if (startDate >= endDate) {
      setEndDate(startDate);
    }
  }, [startDate, endDate]); // Only re-run the effect if startDate changes

  const { checkList, resortList } = props;

  const checkOptions = checkList.map(data => (
    <MenuItem key={data.id} value={data.value}>
      {data.name}
    </MenuItem>
  ));
  const resortOptions = resortList.map(data => (
    <MenuItem key={data.id} value={data.value}>
      {data.name}
    </MenuItem>
  ));

  return (
    <div>
      <Grid container>
        <Grid container justify="flex" alignItems="flex" item sm={9} xs={12}>
          <ThemeProvider theme={theme}>
            <Card className={classes.searchCard}>
              <h2 className={classes.header}>Let's get started.</h2>
              <Grid
                justify="flex-start"
                alignItems="flex-start"
                item
                sm={12}
                xs={12}
                className={classes.row}
              >
                <div>
                  {props.lookingFor == null ? (
                    <FormControl
                      variant="outlined"
                      color="primary"
                      classes={{ root: classes.selectRoot }}
                    >
                      <Typography className={classes.labels2}>
                        Looking for:{" "}
                      </Typography>
                      <Select
                        className={classes.select1}
                        classes={{
                          root: classes.selectRoot2
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null
                        }}
                      >
                        {checkOptions}
                      </Select>
                    </FormControl>
                  ) : (
                    <p />
                  )}
                </div>
              </Grid>
              <Grid
                justify="flex-start"
                alignItems="flex-start"
                item
                sm={12}
                xs={12}
                className={classes.row}
              >
                <FormControl
                  variant="outlined"
                  color="primary"
                  classes={{ root: classes.selectRoot }}
                >
                  <Typography className={classes.labels2}>Resort: </Typography>
                  <Select
                    className={classes.select2}
                    classes={{
                      root: classes.selectRoot2
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}
                  >
                    {resortOptions}
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                justify="flex-start"
                alignItems="flex-start"
                item
                sm={12}
                xs={12}
                className={classes.row}
              >
                <FormControl classes={{ root: classes.selectRoot }}>
                  <Typography className={classes.labels}>
                    Start date:{" "}
                  </Typography>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      selected={startDate}
                      value={startDate}
                      minDate={minStartDate}
                      onChange={date => setStartDate(date)}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                      inputVariant="outlined"
                      classes={{
                        root: classes.dateRoot
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>

              <Grid
                justify="flex-start"
                alignItems="flex-start"
                item
                sm={12}
                xs={12}
                className={classes.row}
              >
                <FormControl classes={{ root: classes.selectRoot }}>
                  <Typography className={classes.labels}>End date: </Typography>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      selected={endDate}
                      value={endDate}
                      minDate={startDate}
                      onChange={date => setEndDate(date)}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                      inputVariant="outlined"
                      className={classes.date2}
                      classes={{
                        root: classes.dateRoot
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>

              <Grid
                justify="center"
                alignItems="center"
                item
                sm={12}
                xs={12}
                className={classes.row}
              >
                <Button
                  variant="contained"
                  onClick={handleSearch}
                  className={classes.button}
                  classes={{ label: classes.buttonLabel }}
                >
                  Search
                </Button>
              </Grid>
            </Card>
          </ThemeProvider>
        </Grid>
      </Grid>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    checkList: state.homePageReducer.country_list,
    resortList: state.homePageReducer.resortList
  };
}

export default connect(
  mapStateToProps,
  null
)(SearchCard);
