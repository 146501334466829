import _ from "lodash";

/**
 * format date
 * @param {Date} date
 *
 * @returns {String} mm/dd/yyy
 */
export function getFormattedDate(date) {
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  let yyyy = date.getFullYear();

  if (dd < 10) dd = "0" + dd;

  if (mm < 10) mm = "0" + mm;

  return mm + "/" + dd + "/" + yyyy;
}

/**
 * get formatted lesson, format date and participant names
 * @param {Array} lessons list of lessons
 * @param {Object} familyMembers a dictionary for members
 *
 * @returns {Array} updated list of lessons
 */
export function getFormattedLessons(lessons, familyMembers) {
  let newLessons = [];
  lessons.map(lsn => {
    let participantStr = "",
      price = "TBC";

    for (let i = 0; i < lsn.participants.length; i++) {
      let comma = ", ";
      let memberID = lsn.participants[i];
      const firstName = familyMembers[memberID].FirstName;
      const lastName = familyMembers[memberID].LastName;

      if (i + 1 === lsn.participants.length) {
        comma = "";
      }
      participantStr += firstName + " " + lastName + comma;
    }

    if (lsn.price) {
      price = lsn.price;
    }

    newLessons.push({
      ...lsn,
      formattedDateTime: lsn.startTime + ", " + getFormattedDate(lsn.startDate),
      participants: participantStr,
      price
    });
  });
  return newLessons;
}

/**
 * get formatted lesson, format date and participant names
 * @param {Array} lessons list of lessons
 * @param {Object} familyMembers a dictionary for members
 *
 * @returns {Object} four lists of grouped lessons
 *                   manLesChosen, groLesChosen, priLesChosen, adaLesChosen
 */
export function getCategorizedLessons(lessons, familyMembers) {
  let manLesChosen = [],
    groLesChosen = [],
    priLesChosen = [],
    adaLesChosen = [];
  lessons.map(lsn => {
    if (lsn.firstTimer && lsn.lessonType.toLowerCase() === "group") {
      manLesChosen.push(lsn);
    }
    if (lsn.lessonType.toLowerCase() === "group") {
      groLesChosen.push(lsn);
    }
    if (lsn.lessonType.toLowerCase() === "private") {
      priLesChosen.push(lsn);
    }
    if (lsn.adaptive) {
      adaLesChosen.push(lsn);
    }
  });

  return {
    manLesChosen: getFormattedLessons(manLesChosen, familyMembers),
    groLesChosen: getFormattedLessons(groLesChosen, familyMembers),
    adaLesChosen: getFormattedLessons(adaLesChosen, familyMembers),
    priLesChosen: getFormattedLessons(priLesChosen, familyMembers)
  };
}

/**
 * get members without snowpass
 * @param {Object} familyMembers a dictionary for members
 *
 * @returns {Array} list of members'full names who do not have snowpass
 */
export function getMembersWithNoSnowpass(familyMembers) {
  let nameList = [];
  Object.keys(familyMembers).map(memberID => {
    if (!familyMembers[memberID].hasSnowPass) {
      const firstName = familyMembers[memberID].FirstName;
      const lastName = familyMembers[memberID].LastName;

      nameList.push(firstName + " " + lastName);
    }
  });
  return nameList;
}

/**
 * get formatted members to fit email tempaltes pug files
 * @param {Object} familyMembers a dictionary for members
 *
 * @returns {Array} list of formatted member ojects;
 */
export function getFomarttedMembers(familyMembers) {
  let newFamilyMembers = [];
  _.map(familyMembers, (val, key) => {
    newFamilyMembers.push({
      name: val.FirstName + " " + val.LastName,
      price: val.price,
      ability:
        val.SkiAbility === val.SnowboardAbility
          ? val.SkiAbility.toString()
          : val.SkiAbility.toString() + "-" + val.SnowboardAbility.toString(),
      hasAllergy: val.hasAllergy,
      allergyDetails: val.allergyDetails,
      disabilityDetail: val.DisabilityDetail,
      IsDisabled: val.IsDisabled,
      age: new Date().getFullYear() - val.DOB.getFullYear(),
      DOB: dateToString("DOB", val.DOB, "", "")
    });
  });
  return newFamilyMembers;
}

/**
 * get distinct age groups
 * @param {Boolean} firstTimer is first timer lesson or not
 * @param {Boolean} adaptive is adaptive lesson or not
 * @param {String} activity activity Type(ski or snowboard)
 * @param {String} lessonType lesson type
 * @param {Array} allLessonsArray all lessons
 *
 * @returns {Array} list of age groups
 **/
export function distinctAgegrps(
  firstTimer,
  adaptive,
  activity,
  lessonType,
  allLessonsArray
) {
  return [
    ...new Set(
      allLessonsArray
        .filter(
          lesson =>
            lesson.firstTimer === firstTimer &&
            lesson.adaptive === adaptive &&
            lesson.activity === activity &&
            lesson.lessonType === lessonType &&
            lesson.participants.length > 0
        )
        .map(item => item.ageGroup)
    )
  ];
}

/**
 * filter lessons
 * @param {Boolean} firstTimer is first timer lesson or not
 * @param {Boolean} adaptive is adaptive lesson or not
 * @param {String} lessonType lesson type
 * @param {String} ageGroup age group
 * @param {Array} allLessonsArray all lessons
 *
 * @returns {Array} list of lessons
 **/
export function filterfunc(
  firstTimer,
  adaptive,
  lessonType,
  ageGroup,
  allLessonsArray
) {
  return allLessonsArray.filter(
    lesson =>
      lesson.firstTimer === firstTimer &&
      lesson.adaptive === adaptive &&
      lesson.lessonType === lessonType &&
      lesson.participants.length > 0 &&
      ((ageGroup === "Everyone else" && lesson.ageGroup !== "3 - 5yrs") ||
        (ageGroup !== "Everyone else" &&
          ageGroup !== null &&
          lesson.ageGroup === ageGroup) ||
        ageGroup === null)
  );
}

const lessonGroupsTemplate = {
  "Adaptive Lessons": {
    "first timer": {},
    experienced: {}
  },
  "First Timer Lessons": {
    private: {},
    group: {}
  },
  "Experienced Lessons": {
    private: {},
    group: {}
  }
};

const groupLessonAgeGroups = [
  "mighty mites (3-5yrs)",
  "child (6-14yrs)",
  "Adult (young) (15-17yrs)",
  "Adult (18-64yrs)",
  "Senior (65-69yrs)",
  "70yrs & over"
];

const privateLessonAgeGroups = ["3 - 5yrs", "Everyone else"];

const allCombinations = [
  {
    lessonGroupName: "Adaptive Lessons",
    subLessonGroupName: "first timer",
    fristTimer: true,
    adaptive: true,
    lessonType: "private",
    ageGroupNames: privateLessonAgeGroups
  },
  {
    lessonGroupName: "Adaptive Lessons",
    subLessonGroupName: "experienced",
    fristTimer: false,
    adaptive: true,
    lessonType: "private",
    ageGroupNames: privateLessonAgeGroups
  },
  {
    lessonGroupName: "First Timer Lessons",
    subLessonGroupName: "private",
    fristTimer: true,
    adaptive: false,
    lessonType: "private",
    ageGroupNames: privateLessonAgeGroups
  },
  {
    lessonGroupName: "Experienced Lessons",
    subLessonGroupName: "private",
    fristTimer: false,
    adaptive: false,
    lessonType: "private",
    ageGroupNames: privateLessonAgeGroups
  },
  {
    lessonGroupName: "First Timer Lessons",
    subLessonGroupName: "group",
    fristTimer: true,
    adaptive: false,
    lessonType: "group",
    ageGroupNames: groupLessonAgeGroups
  },
  {
    lessonGroupName: "Experienced Lessons",
    subLessonGroupName: "group",
    fristTimer: false,
    adaptive: false,
    lessonType: "group",
    ageGroupNames: groupLessonAgeGroups
  }
];

/**
 * update allGroupsOfLessons if filtered lesson array is not empty
 * @param {String} lessonGroupName main category
 * @param {String} subLessonGroupName
 * @param {Object} allGroupsOfLessons
 * @param {Boolean} firstTimer is first timer lesson or not
 * @param {Boolean} adaptive is adaptive lesson or not
 * @param {String} lessonType lesson type
 * @param {String} ageGroup age group
 * @param {Array} allLessonsArray all lessons
 *
 * @returns {Array} list of lessons
 **/
export function getFilteredLesson(
  lessonGroupName,
  subLessonGroupName,
  allGroupsOfLessons,
  firstTimer,
  adaptive,
  lessonType,
  ageGroup,
  allLessonsArray
) {
  let lessons = filterfunc(
    firstTimer,
    adaptive,
    lessonType,
    ageGroup,
    allLessonsArray
  );

  if (lessons.length > 0) {
    return lessons;
  }
}

/**
 * get all groups of lessons
 * @param {Array} lessons list of lessons
 *
 * @returns {Object} lessonGroupsTemplate
 *
 */
export function getAllGroupsOfLessons(allLessonsArray) {
  let allGroupsOfLessons = {};
  allCombinations.map(combination => {
    const {
      lessonGroupName,
      subLessonGroupName,
      fristTimer,
      adaptive,
      lessonType,
      ageGroupNames
    } = combination;
    ageGroupNames.map(ageGroup => {
      let lessons = getFilteredLesson(
        lessonGroupName,
        subLessonGroupName,
        allGroupsOfLessons,
        fristTimer,
        adaptive,
        lessonType,
        ageGroup,
        allLessonsArray
      );
      if (lessons) {
        if (allGroupsOfLessons[lessonGroupName]) {
          if (allGroupsOfLessons[lessonGroupName][subLessonGroupName]) {
            allGroupsOfLessons[lessonGroupName][subLessonGroupName][
              ageGroup
            ] = lessons;
          } else {
            allGroupsOfLessons[lessonGroupName][subLessonGroupName] = {
              [ageGroup]: lessons
            };
          }
        } else {
          allGroupsOfLessons[lessonGroupName] = {
            [subLessonGroupName]: {
              [ageGroup]: lessons
            }
          };
        }
      }
    });
  });

  return allGroupsOfLessons;
}

export const dateToString = (type, date, time, duration) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  const monChar = d.toLocaleString("default", { month: "short" });

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  if (type === "DOB") {
    return [day, month, year].join("-");
  } else if (type === "DETAILS") {
    return [day, monChar, year].join(" ");
  } else {
    const daysToAdd = parseInt((duration + "")[0]);
    const endDate = d.addDays(daysToAdd);
    var endmonth = "" + (endDate.getMonth() + 1),
      endDay = "" + endDate.getDate(),
      endYear = endDate.getFullYear();
    if (endmonth.length < 2) endmonth = "0" + endmonth;
    if (endDay.length < 2) endDay = "0" + endDay;
    const endMonChar = endDate.toLocaleString("default", {
      month: "short"
    });

    if (year === endYear && month === endmonth) {
      return day + " - " + endDay + " " + monChar + " " + time;
    } else if (year === endYear) {
      return (
        day + " " + monChar + " - " + endDay + " " + endMonChar + " " + time
      );
    } else {
      return (
        day +
        " " +
        monChar +
        " " +
        year +
        " - " +
        endDay +
        " " +
        endMonChar +
        " " +
        endYear +
        " " +
        time
      );
    }
  }
};
