import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: "54px 0px",
    align: "center",
    "& a": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 16,
      [theme.breakpoints.down("xs")]: {
        fontSize: 11.5
      },
      lineHeight: "140.62%",
      color: "black"
    },
    "& span": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 16,
      lineHeight: "140.62%",
      [theme.breakpoints.down("xs")]: {
        fontSize: 14
      }
    }
  },
  contentWrapper: {
    width: "92%",
    display: "inline-flex"
  },
  shareWrapper: {
    width: "8%",
    display: "inline-flex",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      width: "100%"
    },
    "&:i": {
      fontSize: 18
    }
  },
  adjustColWrapper: {
    width: "auto",
    marginTop: 16
  },
  fab: {
    fontSize: 18
  }
}));

const FooterBar = props => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <div className={classes.wrapper} align="center">
      <Grid
        container
        direction="row"
        className={classes.contentWrapper}
        justify="space-around"
        xs={12}
        sm={9}
        md={7}
        lg={6}
      >
        <Grid
          container
          item
          className={classes.adjustColWrapper}
          direction="column"
          align="left"
          xs={5}
        >
          <Grid item>
            <span>Partners</span>
          </Grid>
          <Grid item>
            <a href="/">Instructor Login</a>
          </Grid>
          <Grid item>
            <a href="/">Host Login</a>
          </Grid>
          <Grid item>
            <a href="/">Instructor Registration</a>
          </Grid>
          <Grid item>
            <a href="/">Host Registration</a>
          </Grid>
        </Grid>
        <Grid
          container
          item
          className={classes.adjustColWrapper}
          direction="column"
          align="left"
          xs={4}
          md={5}
        >
          <Grid item>
            <span>Company</span>
          </Grid>
          <Grid item>
            <a href="/">About Us</a>
          </Grid>
          <Grid item>
            <a href="/">FAQs</a>
          </Grid>
        </Grid>
        {/* <Grid container item className={classes.adjustColWrapper} direction="column" justify="flex-start"
          alignItems="flex-start" spacing={1} xs={5} sm={5} md={5} lg={5}>
          <Grid item>
            <span>Legal</span>
          </Grid>
          <Grid item>
            <a href="/">Privacy Policy</a>
          </Grid>
          <Grid item>
            <a href="/">Cookie Policy</a>
          </Grid>
          <Grid item>
            <a href="/">Terms of Service</a>
          </Grid>
        </Grid> */}
        <Grid
          container
          item
          className={classes.adjustColWrapper}
          direction="column"
          align="left"
          xs={3}
          md={2}
        >
          <Grid item>
            <span>Discover</span>
          </Grid>
          <Grid item>
            <a href="/">Resorts</a>
          </Grid>
          <Grid item>
            <a href="/">Ski Lessons</a>
          </Grid>
          <Grid item>
            <a href="/">Instructors</a>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid
        container
        className={classes.shareWrapper}
        justify="center"
        alignItems="center"
      >
        <Grid item>
          <span>Find Us</span>
        </Grid>
        <Grid container direction="row" spacing={1} justify="center">
          <Grid item>
            <a href="/">
              <i className="fab fa-facebook" />
            </a>
          </Grid>
          <Grid item>
            <a href="/">
              <i className="fab fa-twitter" />
            </a>
          </Grid>
          <Grid item>
            <a href="/">
              <i className="fab fa-instagram" />
            </a>
          </Grid>
        </Grid>
      </Grid> */}
    </div>
  );
};

export default FooterBar;
