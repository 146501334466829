/*
 * Age
 */
// export const adult_threshold = 15;
// export const mini_threshold = 5;

export const SKI = "Ski";
export const SNOWBOARD = "Snowboard";
export const TELEMARK = "Telemark";
export const SNOWSHOE = "Snowshoe";
export const SNOWMOBILE = "Snowmobile";
export const SNOWBIKE = "Snowbike";
export const ADULT_THRESHOLD = 15;
export const MINI_THRESHOLD = 5;
export const FIRST_TIMER_THRESHOLD = 1;

export const DATE_FORMAT = "dd/MM/yyyy";

export const USER_TYPE = {
  PROVIDER: 1,
  CLIENT: 2
};

export const PROFILE_FIELD_NAMES = {
  FIRST_NAME: "FirstName",
  LAST_NAME: "LastName",
  GENDER: "Gender",
  DOB: "DOB",
  SKI_ABILITY: "SkiAbility",
  SNOWBOARD_ABILITY: "SnowboardAbility",
  TELEMARK_ABILITY: "TelemarkAbility",
  SNOWBIKE_ABILITY: "SnowbikeAbility",
  SNOWMOBILE_ABILITY: "SnowmobileAbility",
  SNOWSHOE_ABILITY: "SnowshoeAbility",
  IS_DISABLED: "IsDisabled",
  DISABILITY_DETAIL: "DisabilityDetail",
  DISABILITY_MEMBERSHIP: "DisabilityMembership",
  DISABILITY_MEMBERSHIP_ID: "DisabilityMembershipID",
  COUNTRY_CODE: "countryCode",
  PHONE_NUMBER: "PhoneNumber",
  EMAIL: "Email",
  POSTCODE: "Postcode",
  MEMBER_TYPE: "MemberType",
  PASSWORD: "Password",
  HASSNOWPASS: "hasSnowPass"
};

export const SERVICE_NAMES = {
  book: "Lessons Enquiry",
  instructor: "Follow Instructors",
  connect: "Connect with Others",
  host: "Become a Resort Host",
  invite: "Invite your Mates",
  about: "Behind the Desks",
  how: "How it works",
  contact: "Contact us"
};

export const scrollerOptions = {
  activeClass: "active",
  duration: 0,
  delay: 0,
  smooth: true,
  isDynamic: true
};

export const CLIENT_STEPPER = {
  SET_ACCOUNT: 1,
  EMAIL_VERIFICATION: 2,
  SET_DETAILS: 3,
  CLIENT_INFO: 4,
  SIGNUP_COMPLETE: 5
};

export const PROVIDER_STEPPER = {
  SET_ACCOUNT: 1,
  EMAIL_VERIFICATION: 2,
  SET_DETAILS: 3,
  PROVIDER_INFO: 4,
  SERVICES: 5,
  SIGNUP_COMPLETE: 6
};

//Calculates all posible times of increment fifteen minutes
export const allTimesHour = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23"
];

export const allTimesMinute = ["00", "15", "30", "45"];

let allTimesString = [];
allTimesHour.map(hour => {
  allTimesMinute.map(minute => {
    let hour_str = hour.length > 1 ? hour + "" : "0" + hour;
    let minute_str = minute.length > 1 ? minute + "" : "0" + minute;
    allTimesString.push(hour_str + ":" + minute_str);
  });
});
export { allTimesString };

export const SERVICES_NAMES = {
  VIRTUAL_LESSONS: "VIRTUAL LESSONS by Instructors",
  CHILD_MINDING: "CHILD MINDING HOSTS",
  FIRST_TIMER: "FIRST TIMER ASSISTANCE HOSTS",
  TOURING: "TOURING HOSTS",
  ACTIVITY: "ACTIVITY HOSTS"
};
export const PROVIDER_SERVICES = {
  CHILD_MINDING: "CHILD MINDING HOSTS (2 hours min duration)",
  TOURS: "TOURING HOSTS (2 hours min duration)"
};

export const FIRST_TIMER_VALUE = {
  VALUE:
    "Trip planning advice, Rental shop support & getting started on the slopes"
};

export const FIRST_TIMER_API_VALUE = {
  VALUE:
    "<ul><li>Trip planning advice</li><li>Rental shop support & getting started on the slopes</li></ul>"
};
