import {
  EDIT_NAME,
  EDIT_BIO,
  EDIT_COUNTRYCODE,
  EDIT_PHONENUMBER,
  EDIT_EMAIL,
  EDIT_PASSWORD,
  EDIT_GENDER,
  EDIT_EXPERIENCE,
  EDIT_AGE,
  EDIT_EXPERIENCE_INS,
  EDIT_EXPERIENCE_LOCAL,
  EDIT_LANGUAGES,
  EDIT_ABILITIES,
  EDIT_DISABILITIES,
  EDIT_SPORT_SPECIAL,
  EDIT_PRE_LANGUAGE,
  EDIT_PRE_CURRENCY,
  EDIT_TIMEZONE,
  EDIT_QUALIFICATIONS,
  EDIT_RESORTS,
  EDIT_COUNTRY,
  EDIT_SPECIALIATIES,
  EDIT_TARGET_AUDIENCE
} from "../actions/action-types";

const initialState = {
  myaccount: {
    id: 1,
    name: "Paul Barber",
    bio:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    countrycode: "Australia +61",
    phonenumber: "0453879123",
    email: "paulbarber@xyz.com",
    password: "······",
    gender: "Male",
    age: 40,
    experience: "2 Years",
    experienceIns: "3 Years",
    experienceLocal: "5 Years",
    languages: ["English", "French"],
    abilities: [
      {
        name: "Ski",
        skill: 5
      },
      { name: "Snowboard", skill: 3 }
    ],
    disabilities: {
      details: "abd disability",
      membertype: "DSUSA",
      memberid: "123456"
    },
    galleryItem: [
      {
        id: "1",
        name: "John",
        src:
          "https://www.newzealand.com/assets/Operator-Database/1e3a62a78b/img-1574395061-8070-17949-p-4D10865C-AA2B-61EE-8F0574F3F17ED5C8-2544003__aWxvdmVrZWxseQo_CropResizeWzk0MCw1MzAsNzUsImpwZyJd.jpg"
        // src:"https://img.redbull.com/images/c_crop,x_969,y_0,h_1824,w_1459/c_fill,w_860,h_1075/q_auto,f_auto/redbullcom/2019/12/04/8e89fbee-f686-4ff3-b680-2b54178fd1b6/switzerland-skiing"
      },
      {
        id: "2",
        name: "Peter",
        src:
          "https://www.newzealand.com/assets/Operator-Database/25e6389408/img-1574395172-9299-12937-p-4511D717-04E3-499C-70D8B36673C80D0A-2544003__aWxvdmVrZWxseQo_CropResizeWzk0MCw1MzAsNzUsImpwZyJd.jpg"
        // src:"https://www.skimag.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTY3MTQ5MTQ5NDkyMzU2MjEy/20190305-skitest-allmtn-068.jpg"
      },
      {
        id: "3",
        name: "Taylor",
        src:
          "https://www.larosiere.net/wp-content/uploads/2018/03/ski_famille-800x797.jpg"
      },
      {
        id: "4",
        name: "Ellie",
        src:
          "https://www.skimag.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTY3MTQ5MTQ5NDkyMzU2MjEy/20190305-skitest-allmtn-068.jpg"
      }
    ],
    preferLanguage: "English",
    preferCurrency: "$ AUD",
    timeZone: "Eastern Australia Standard Time (AEST)",
    resorts: ["Australia", "Fall Creek"],
    country: "Australia",
    targetAudience: "Kids"
  },
  filterValues: {
    countrycode: [
      {
        key: 1,
        value: "Afghanistan +93",
        label: "Afghanistan +93"
      },
      {
        key: 2,
        value: "Australia +61",
        label: "Australia +61"
      },
      {
        key: 3,
        value: "Egypt +20",
        label: "Egypt +20"
      },
      {
        key: 4,
        value: "India +91",
        label: "India +91"
      }
    ],
    gender: [
      {
        key: 1,
        value: "Male",
        label: "Male"
      },
      {
        key: 2,
        value: "Female",
        label: "Female"
      },
      {
        key: 3,
        value: "Other",
        label: "Other"
      }
    ],
    languages: [
      "English",
      "French",
      "German",
      "Russian",
      "Spanish",
      "Mandarin"
    ],
    country: [
      {
        key: 1,
        value: "Afghanistan",
        label: "Afghanistan"
      },
      {
        key: 2,
        value: "Australia",
        label: "Australia"
      },
      {
        key: 3,
        value: "Egypt",
        label: "Egypt"
      },
      {
        key: 4,
        value: "India",
        label: "India"
      }
    ],
    preferLanguage: [
      {
        key: 1,
        value: "English",
        label: "English"
      },
      {
        key: 2,
        value: "French",
        label: "French"
      },
      {
        key: 3,
        value: "Chinese",
        label: "Chinese"
      },
      {
        key: 4,
        value: "German",
        label: "German"
      },
      {
        key: 5,
        value: "Indian",
        label: "Indian"
      }
    ],
    preferCurrency: [
      {
        key: 1,
        value: "+$",
        label: "$ AUD"
      },
      {
        key: 2,
        value: "$",
        label: "$ USD"
      },
      {
        key: 3,
        value: "€",
        label: "€ Euro"
      },
      {
        key: 4,
        value: "£",
        label: "£ Pound"
      }
    ],
    timeZone: [
      {
        key: 1,
        value: "GMT+0",
        label: "Greenwich Standard Time"
      },
      {
        key: 2,
        value: "GMT-3",
        label: "Argentina Time"
      },
      {
        key: 3,
        value: "GMT+1",
        label: "Central European Time (CET)"
      },
      {
        key: 4,
        value: "GMT+12",
        label: "New Zealand Standard Time (NZST)"
      },
      {
        key: 5,
        value: "GMT+10",
        label: "Eastern Australia Standard Time (AEST)"
      },
      {
        key: 6,
        value: "GMT-5",
        label: "Eastern Standard Time (EST)"
      },
      {
        key: 7,
        value: "GMT-6",
        label: "Central Standard Time (CST)"
      }
    ],
    targetAudience: [
      {
        key: 1,
        value: "Kids",
        label: "Kids"
      },
      {
        key: 2,
        value: "Adults",
        label: "Adults"
      },
      {
        key: 3,
        value: "Both",
        label: "Both Kids and Adults"
      }
    ]
  }
};

const myAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_NAME:
      return {
        ...state,
        myaccount: {
          ...state.myaccount,
          name: action.payload
        }
      };
    case EDIT_BIO:
      return {
        ...state,
        myaccount: {
          ...state.myaccount,
          bio: action.payload
        }
      };
    case EDIT_COUNTRYCODE:
      return {
        ...state,
        myaccount: {
          ...state.myaccount,
          countrycode: action.payload
        }
      };
    case EDIT_PHONENUMBER:
      return {
        ...state,
        myaccount: {
          ...state.myaccount,
          phonenumber: action.payload
        }
      };
    case EDIT_EMAIL:
      return {
        ...state,
        myaccount: {
          ...state.myaccount,
          email: action.payload
        }
      };
    case EDIT_PASSWORD:
      return {
        ...state,
        myaccount: {
          ...state.myaccount,
          password: action.payload
        }
      };
    case EDIT_GENDER:
      return {
        ...state,
        myaccount: {
          ...state.myaccount,
          gender: action.payload
        }
      };
    case EDIT_EXPERIENCE:
      return {
        ...state,
        myaccount: {
          ...state.myaccount,
          experience: action.payload
        }
      };
    case EDIT_AGE:
      return {
        ...state,
        myaccount: {
          ...state.myaccount,
          age: action.payload
        }
      };
    case EDIT_EXPERIENCE_INS:
      return {
        ...state,
        myaccount: {
          ...state.myaccount,
          experienceIns: action.payload
        }
      };
    case EDIT_EXPERIENCE_LOCAL:
      return {
        ...state,
        myaccount: {
          ...state.myaccount,
          experienceLocal: action.payload
        }
      };
    case EDIT_LANGUAGES:
      return {
        ...state,
        myaccount: {
          ...state.myaccount,
          languages: action.payload
        }
      };
    case EDIT_ABILITIES:
      return {
        ...state,
        myaccount: {
          ...state.myaccount,
          abilities: action.payload
        }
      };
    case EDIT_DISABILITIES:
      return {
        ...state,
        myaccount: {
          ...state.myaccount,
          disabilities: action.payload
        }
      };
    case EDIT_SPORT_SPECIAL:
      return {
        ...state,
        myaccount: {
          ...state.myaccount,
          sportAndSpecialisation: action.payload
        }
      };
    case EDIT_PRE_LANGUAGE:
      return {
        ...state,
        myaccount: {
          ...state.myaccount,
          preferLanguage: action.payload
        }
      };
    case EDIT_PRE_CURRENCY:
      return {
        ...state,
        myaccount: {
          ...state.myaccount,
          preferCurrency: action.payload
        }
      };
    case EDIT_TIMEZONE:
      return {
        ...state,
        myaccount: {
          ...state.myaccount,
          timeZone: action.payload
        }
      };
    case EDIT_QUALIFICATIONS:
      return {
        ...state,
        myaccount: {
          ...state.myaccount,
          qualifications: action.payload
        }
      };
    case EDIT_RESORTS:
      return {
        ...state,
        myaccount: {
          ...state.myaccount,
          resorts: action.payload
        }
      };
    case EDIT_COUNTRY:
      return {
        ...state,
        myaccount: {
          ...state.myaccount,
          country: action.payload
        }
      };
    case EDIT_SPECIALIATIES:
      return {
        ...state,
        myaccount: {
          ...state.myaccount,
          specialiaties: action.payload
        }
      };
    case EDIT_TARGET_AUDIENCE:
      return {
        ...state,
        myaccount: {
          ...state.myaccount,
          targetAudience: action.payload
        }
      };

    default: {
      return state;
    }
  }
};

export default myAccountReducer;
