import React, { Component, useState, useEffect } from "react";

// Redux imports
import { connect } from "react-redux";
import { sendEmail } from "../../redux/actions/contactUsActions";
import { emailSent } from "../../redux/actions/contactUsActions";

// MUI imports
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@material-ui/core";
import { FormLabel, Alert, Stack } from "@mui/material";
// import LoadingButton from "@mui/lab/LoadingButton";
// import SaveIcon from "@mui/icons-material/Save";

// Style imports
import { FONTFAMILY } from "../../styling/theme/Theme";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: "0rem 1rem"
  },
  title: {
    margin: "2rem 0rem",
    fontSize: 26,
    fontWeight: "400",
    fontFamily: FONTFAMILY.FONT_SANS_NARROW,
    color: "#000000"
  },
  box: {
    marginTop: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  typography: {
    marginTop: 5,
    fontSize: "18px",
    fontFamily: FONTFAMILY.FONT_SANS_NARROW,
    align: { xs: "center", sm: "right" }
  },
  alert: {
    width: "100%",
    pt: 2,
    alignItems: "center"
  },
  button: {
    borderRadius: 100,
    backgroundColor: "#FF6161",
    boxShadow:
      "rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px"
  },
  btnLabel: {
    color: "white",
    fontFamily: "PT Sans Narrow",
    fontWeight: "bold",
    textTransform: "none"
  },
  disabledBtnLabel: {
    color: "inherit",
    fontFamily: "PT Sans Narrow",
    fontWeight: "bold",
    textTransform: "none"
  }
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF"
    },
    secondary: {
      main: "#FF6161"
    }
  }
});

// contact page
const ContactPage = props => {
  const classes = useStyles();
  const {
    contactUsEmailSent, // Redux store prop
    sendEmail, // Redux dispatch action
    emailSent // Redux dispatch action
  } = props;

  // local state
  const initialState = {
    name: null,
    telephone: null,
    email: null,
    message: null,
    invest: null,
    work: null,
    submitting_icon: false
  };
  const [formFields, setFormFields] = useState(initialState);
  const {
    name,
    telephone,
    email,
    message,
    invest,
    work,
    submitting_icon
  } = formFields;

  const [formErrors, setFormErrors] = useState({});

  // form validation
  const validate = () => {
    const errors = {};
    errors.name = formFields.name ? "" : "Please enter your name";
    errors.telephone = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(
      formFields.telephone
    )
      ? ""
      : "Please enter a valid telephone number";
    errors.email = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(formFields.email)
      ? ""
      : "Please enter a valid email";
    errors.message = formFields.message != 0 ? "" : "Please enter a message";
    errors.invest = formFields.invest ? "" : "Please select an option";
    errors.work = formFields.work ? "" : "Please select an option";
    setFormErrors({
      ...errors
    });
    return Object.values(errors).every(i => i === "");
  };

  // change handlers
  const handleChange = e => {
    setFormFields({
      ...formFields,
      [e.target.name]: e.target.value
    });
    setFormErrors({
      ...formErrors,
      [e.target.name]: ""
    });
  };

  const handleRadioChange = e => {
    if (e.target.checked) {
      setFormFields({
        ...formFields,
        [e.target.name]: e.target.value
      });
      setFormErrors({
        ...formErrors,
        [e.target.name]: ""
      });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (validate()) {
      setFormFields({
        ...formFields,
        submitting_icon: true
      });
      sendEmail({
        name,
        telephone,
        email,
        message,
        invest,
        work
      });
    }
  };

  // cleans the form fields and dispatches an action after 5 seconds to change ContactUsEmailSent to false which removes the success alert
  useEffect(() => {
    setFormFields({
      name: "",
      telephone: "",
      email: "",
      message: "",
      invest: "",
      work: "",
      submitting_icon: false
    });
    setTimeout(() => {
      emailSent();
    }, 5000);
  }, [contactUsEmailSent]);

  return (
    <div className={classes.root}>
      <Grid className={classes.backgroundTest} container justify="center">
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="md">
            <CssBaseline />
            {contactUsEmailSent ? (
              <Stack className={classes.alert}>
                <Alert severity="success">
                  Your enquiry was successfully submitted!
                </Alert>
              </Stack>
            ) : null}
            <Box className={classes.box}>
              <Typography className={classes.title}>Contact Us</Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{
                  mt: 3
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="name"
                      name="name"
                      value={name}
                      required
                      fullWidth
                      id="name"
                      label="Name"
                      variant="outlined"
                      onChange={handleChange}
                      error={formErrors.name}
                      helperText={formErrors.name ? "Please enter your name" : ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="telephone"
                      pattern="^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$"
                      name="telephone"
                      value={telephone}
                      required
                      fullWidth
                      id="telephone"
                      label="Telephone Number"
                      variant="outlined"
                      onChange={handleChange}
                      error={formErrors.telephone}
                      helperText={formErrors.telephone || telephone !== "" && !/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(telephone)
                        ? "Please enter a valid telephone number"
                        : ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="email"
                      name="email"
                      value={email}
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      variant="outlined"
                      onChange={handleChange}
                      error={formErrors.email}
                      helperText={formErrors.email || email !== "" && !/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(formFields.email)
                      ? "Please enter a valid email"
                      : ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="message"
                      name="message"
                      value={message}
                      required
                      fullWidth
                      id="message"
                      multiline
                      rows={3}
                      label="Message"
                      variant="outlined"
                      onChange={handleChange}
                      error={formErrors.message}
                      helperText={formErrors.message ? "Please enter a message" : ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={5} lg={4}>
                    <Typography
                      className={classes.typography}
                      variant="body1"
                      color="text.secondary"
                    >
                      Interested in investing with us?*
                    </Typography>
                  </Grid>
                  {/* conditional render for invest validation */}
                  {formErrors.invest ? (
                    <Grid item xs={12} sm={6} md={7} lg={8}>
                      <RadioGroup
                        row
                        aria-labelledby="investing-row-radio-buttons"
                        name="invest"
                        value={invest}
                        required
                        onChange={handleRadioChange}
                        error={formErrors.invest}
                        helperText={formErrors.invest}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" />}
                          label="No"
                        />
                        <Typography
                          className={classes.typography}
                          variant="body1"
                          color="error"
                        >
                          {formErrors.invest}
                        </Typography>
                      </RadioGroup>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={6} md={7} lg={8}>
                      <RadioGroup
                        row
                        aria-labelledby="investing-row-radio-buttons"
                        name="invest"
                        value={invest}
                        required
                        onChange={handleRadioChange}
                        error={formErrors.invest}
                        helperText={formErrors.invest}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" />}
                          label="No"
                        />
                      </RadioGroup>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={5} lg={4}>
                    <Typography
                      className={classes.typography}
                      variant="body1"
                      color="text.secondary"
                    >
                      Interested in working with us?*
                    </Typography>
                  </Grid>
                  {/* conditional render for work validation */}
                  {formErrors.work ? (
                    <Grid item xs={12} sm={6} md={7} lg={8}>
                      <RadioGroup
                        row
                        aria-labelledby="working-row-radio-buttons"
                        name="work"
                        value={work}
                        required
                        onChange={handleRadioChange}
                        error={formErrors.work}
                        helperText={formErrors.work}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" />}
                          label="No"
                        />
                        <Typography
                          className={classes.typography}
                          variant="body1"
                          color="error"
                        >
                          {formErrors.work}
                        </Typography>
                      </RadioGroup>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={6} md={7} lg={8}>
                      <RadioGroup
                        row
                        aria-labelledby="working-row-radio-buttons"
                        name="work"
                        value={work}
                        required
                        onChange={handleRadioChange}
                        error={formErrors.work}
                        helperText={formErrors.work}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" />}
                          label="No"
                        />
                      </RadioGroup>
                    </Grid>
                  )}
                </Grid>
                {submitting_icon ? (
                  // <LoadingButton
                  //   type="submit"
                  //   loading
                  //   loadingPosition="start"
                  //   // startIcon={<SaveIcon />}
                  //   variant="outlined"
                  //   fullWidth
                  //   // variant="contained"
                  //   sx={{
                  //     mt: 3,
                  //     mb: 2
                  //   }}
                  // >
                  //   Loading...
                  // </LoadingButton>
                  <Button
                    className={classes.button}
                    classes={{
                      label: classes.disabledBtnLabel
                    }}
                    disabled
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2
                    }}
                  >
                    Submitting Enquiry... Please do not refresh the page
                  </Button>
                ) : (
                  <Button
                    className={classes.button}
                    classes={{
                      label: classes.btnLabel
                    }}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2
                    }}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    Send Enquiry
                  </Button>
                )}
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </Grid>
    </div>
  );
};;

function mapStateToProps(state) {
  return {
    contactUsEmailSent: state.contactUsReducer.contactUsEmailSent
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendEmail: request => dispatch(sendEmail(request)),
    emailSent: request => dispatch(emailSent())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactPage);
