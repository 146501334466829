import React from "react";
import useStyles from "./MyAccountStyles";
import { Button, Grid } from "@material-ui/core";
import InstructorGalleryCarousel from "../../virtualLessons/InstructorGalleryCarousel";

const GalleryVis = ({ journey }) => {
  const classes = useStyles();

  return journey === "h-vis" ? (
    <Grid item container lg={12} md={12} sm={12} xs={12}>
      <Grid item lg={2} md={2} sm={2} xs={2}>
        <div className={classes.textFieldStyle} align="right">
          Gallery:
        </div>
      </Grid>
      <Grid item lg={1} md={1} sm={1} xs={1} />
      <Grid item container lg={9} md={9} sm={9} xs={9}>
        <Grid lg={12} md={12} sm={12} xs={12}>
          <InstructorGalleryCarousel instructorId={1} />
        </Grid>
        {/* Gallery Buttons*/}
        <Grid item container lg={12} md={12} sm={12} xs={12} direction="row">
          <Grid item align="center" lg={6} md={6} sm={6} xs={6}>
            <button
              color="primary"
              variant="contained"
              className={classes.btnbot}
            >
              Add Images
            </button>
          </Grid>
          <Grid item align="center" lg={6} md={6} sm={6} xs={6}>
            <button
              color="primary"
              variant="contained"
              className={classes.btnbot}
            >
              Remove Images
            </button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : null;
};

export default GalleryVis;
