import React from "react";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import moment from "moment";

import FooterBar from "../../../components/Common/Footer/FooterBar";
import PaymentConfirmation from "../../../components/Common/payment/PaymentConfirmation";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

import PaymentPopup from "../../../materials/payment/PaymentPopup";
import profilePic from "../../../materials/HomePage/host.jpg";

const useStyles = makeStyles(theme => ({
  backBtn: {
    color: "red",
    margin: "2rem",
    borderColor: "red",
    padding: "0.5rem 2rem",
    [theme.breakpoints.only("xs")]: {
      width: "25%"
    }
  },

  bluebtn: {
    margin: "1rem",
    padding: "0.5rem 2rem",
    background: "#1277B8",
    width: "25%",
    color: "white",
    borderRadius: 20,
    [theme.breakpoints.only("xs")]: {
      width: "50%",
      margin: "0.5rem 1rem 2rem 1rem"
    }
  },

  btnArrow: {
    position: "absolute",
    left: "0",
    transform: "rotate(180deg)",
    rotate: "180deg"
  }

  // paygrid: {
  //   // [theme.breakpoints.only("xs")]: {
  //   padding: "0"
  //   //}
  // }
}));

function AutoGrid(props) {
  const { timeslots } = props;

  const classes = useStyles();

  const getTotalTimeInfo = (timeA, timeB) => {
    const hourA = timeA.toDate().getHours();
    const hourB = timeB.toDate().getHours();
    const minuteA = timeA.toDate().getMinutes();
    const minuteB = timeB.toDate().getMinutes();

    const hours = hourB - hourA;
    const minutes = minuteB - minuteA;

    let time = 0.0;

    if (minutes < 0) {
      const modifiedMinuteA = minuteA;
      const modifiedMinuteB = minuteB + 60;
      const modifiedMinutes = modifiedMinuteB - modifiedMinuteA;
      const modifiedHours = hours - 1;

      time = modifiedHours + modifiedMinutes / 15;
      //time = duration;
    } else {
      time = hours + minutes / 15 / 4;
      //time = duration;
    }

    return time;
  };

  // const lessonDate = timeslots.map(timeslot =>{
  //   return moment(timeslot.datefrom).format("MMM Do YY");
  // });

  //renders the slots
  const slots = timeslots.map(timeslot => {
    const { price, datefrom, dateto } = timeslot;
    return {
      price: price / getTotalTimeInfo(moment(datefrom), moment(dateto)),
      hours: getTotalTimeInfo(moment(datefrom), moment(dateto)),
      lessonDate: moment(timeslot.datefrom).format("DD MMM YY")
    };
  });

  return (
    <div className={classes.root} align="center">
      <Grid container>
        <Grid item md={12} xs={12}>
          <div align="left">
            <Button variant="outlined" className={classes.backBtn}>
              <ArrowRightAltIcon
                className={classes.btnArrow}
                variant="outlined"
              />
              Back
            </Button>
          </div>
        </Grid>
      </Grid>

      <Grid container position="center">
        <Grid item md={12} xs={12}>
          <div variant="contained" color="primary" className={classes.bluebtn}>
            Confirm and Pay
          </div>
        </Grid>
      </Grid>

      <Grid container direction="row" position="center" justify="space-around">
        <Grid item md={8} className={classes.cardpaygrid}>
          <div padding="0">
            <PaymentPopup hideActionButtons={true} />
          </div>
        </Grid>
        <Grid item md={4}>
          <PaymentConfirmation
            username={"James Bond"}
            rating={4}
            typeOfBooking={"Virtual Lessons"}
            avatar={profilePic}
            serviceCharge={5}
            // cancellationDeadline={new Date()}
            slots={slots}
            // lessonDate={lessonDate}
            bookingDate={new Date()}
            moreDetailsLink={"/"}
          />
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    timeslots: state.virtualInstructorReducer.timeslots
  };
};

function Payment(props) {
  const { timeslots } = props;
  return (
    <div>
      <AutoGrid timeslots={timeslots} />
      <FooterBar />
    </div>
  );
}

export default connect(
  mapStateToProps,
  null
)(Payment);
