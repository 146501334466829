import { change } from "redux-form";
import agent from "../../agent.js";
import {
  INCREMENT_STEPPER,
  DECREMENT_STEPPER,
  INCREMENT_STEPPER_HOST,
  DECREMENT_STEPPER_HOST,
  GET_ACTIVATION_STATUS,
  GET_ERROR_MESSAGE,
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILURE,
  LOAD_USER_DETAILS,
  LOAD_USER_DETAILS_FAILURE,
  // GET_PROVIDER_PROFILE_REQUEST,
  // GET_PROVIDER_PROFILE_SUCCESS,
  // GET_PROVIDER_PROFILE_FAILURE,
  UPDATE_USER_DETAILS_SUCCESS_REQUEST,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAILURE,
  UPDATE_USER_PROFILE_SUCCESS_REQUEST,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILURE,
  INCREMENT_STEPPER_MANUL,
  CREATE_PROVIDER_SERVICES,
  UPDATE_PROVIDER_SERVICES
} from "./action-types.js";

export const incrementActiveStep = () => ({
  type: INCREMENT_STEPPER
});
export const incrementActiveStepManual = payload => dispatch => {
  dispatch({ type: INCREMENT_STEPPER_MANUL, payload: payload });
};

export const decrementActiveStep = () => ({
  type: DECREMENT_STEPPER
});

export const incrementActiveStepHost = () => ({
  type: INCREMENT_STEPPER_HOST
});

export const decrementActiveStepHost = () => ({
  type: DECREMENT_STEPPER_HOST
});

export function getActivatedUserStatus(token, id, signup_step) {
  return function(dispatch) {
    return agent.ActivatedUser.getActivatedUserDetail(token, id, signup_step)
      .then(response => {
        console.log(response);
        if (response.status === 200) {
          dispatch({ type: GET_ACTIVATION_STATUS, payload: response });
        }
      })
      .catch(error => {
        dispatch({ type: GET_ERROR_MESSAGE, payload: error.response });
        console.log("error", error.response);
        // dispatch(alertSubmitForm(false));
      });
  };
}

export const updateUserProfile = status => async dispatch => {
  dispatch({ type: UPDATE_USER_PROFILE_SUCCESS_REQUEST });

  if (status == 200){
    dispatch({ type: INCREMENT_STEPPER });
    dispatch({ type: UPDATE_USER_PROFILE_SUCCESS, payload: true });}
  else{
    dispatch({ type: UPDATE_USER_PROFILE_FAILURE, payload: status });}
};

export const getClientProfile = userId => async dispatch => {
  dispatch({ type: GET_USER_PROFILE_REQUEST });
  try {
    const response = await agent.Users.getClientProfile(userId);
    const response2 = await agent.Users.getClientProfileImage(userId);
    const response3 = await agent.Users.getGallery(userId);

    const {
      first_name,
      last_name,
      gender,
      dob,
      mobile_number,
      bio,
      country,
      languages,
      skiing_level,
      snowboarding_level
    } = response.data;

    const { profile_picture } = response2.data;

    const imageGallery = response3.data;

    const userProfile = {
      firstName: first_name,
      lastName: last_name,
      bio,
      profile_picture,
      country,
      skiLevel: skiing_level,
      snowLevel: snowboarding_level,
      languages,
      gender,
      dob,
      mobileNumber: mobile_number
    };

    Object.keys(userProfile).forEach(key =>
      dispatch(change("signup", key, userProfile[key]))
    );

    dispatch({
      type: GET_USER_PROFILE_SUCCESS,
      payload: userProfile
    });
  } catch (error) {
    const message =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    dispatch({ type: GET_USER_PROFILE_FAILURE, payload: message });
    console.log(message);
  }
};

export const getProviderProfile = userId => async dispatch => {
  dispatch({ type: GET_USER_PROFILE_REQUEST });
  try {
    // const response = await agent.Users.getProviderProfile(userId);
    const response = await agent.Users.getProviderProfile(userId);
    const imageResponse = await agent.Users.getProviderProfileImage(userId);
    const galleryResponse = await agent.Users.getGallery(userId);

    const {
      id,
      provider_bio,
      resorts,
      // services,
      suitable_fors,
      user
    } = response.data;

    const serviceData = await agent.Users.getProviderServices(id);

    const { profile_picture } = imageResponse.data;
    const gallery = galleryResponse.data;
    const serviceList = translateServiceData(serviceData);

    const userProfile = {
      id,
      providerBio: provider_bio,
      resorts,
      // services,
      suitableFors: suitable_fors,
      user,
      profileImage: profile_picture,
      gallery,
      providerServices: serviceList
    };

    Object.keys(userProfile).forEach(key =>
      // dispatch(change("signup", key, userProfile[key]))
      dispatch(change("signup", key, userProfile[key]))
    );

    dispatch({
      type: GET_USER_PROFILE_SUCCESS,
      payload: userProfile
    });
  } catch (error) {
    const message =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    dispatch({ type: GET_USER_PROFILE_FAILURE, payload: message });
  }
};

export const loadUserDetails = (userId, isProvider) => async dispatch => {
  try {
    let response;

    if (isProvider) {
      response = await agent.Users.getProviderProfile(userId);
    } else {
      response = await agent.Users.getClientProfile(userId);
    }

    dispatch({ type: LOAD_USER_DETAILS, payload: response.data });
  } catch (error) {
    const message =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;

    dispatch({ type: LOAD_USER_DETAILS_FAILURE, payload: message });
  }
};

export const updateUserDetails = user => async dispatch => {
  dispatch({ type: UPDATE_USER_DETAILS_SUCCESS_REQUEST });
  try {
    await agent.Users.updateUserInfo(
      user.userId,
      user.firstName,
      user.lastName,
      user.gender,
      user.dob,
      user.mobileNumber,
      user.signupStep
    );
    dispatch({ type: INCREMENT_STEPPER });
    dispatch({ type: UPDATE_USER_DETAILS_SUCCESS, payload: true });
  } catch (error) {
    const message =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    dispatch({ type: UPDATE_USER_DETAILS_FAILURE, payload: message });
  }
};

// Action for updating, creating, deleting provider's services
export function setProviderServices(provider_id, services) {
  console.log("Stepper Action: ", services);
  return function(dispatch) {
    return agent.updateProviderServices
      .setProviderServices(provider_id, services)
      // .then(response => console.log(response))
      .then(dispatch({ type: UPDATE_PROVIDER_SERVICES, payload: services }))
      //   if (response.status === 200) {
      //     dispatch({ type: CREATE_PROVIDER_SERVICES, payload: response });
      //   }
      // })
      // .catch(error => {
      //   console.log("error", error.response);
      // });
  };
}

// Action for formatting service data to desired formate
function translateServiceData(serviceData) {
  let serviceList = [];
  if (serviceData !== undefined) {
    serviceData.data.services_pricing.forEach(service => {
      if (Object.hasOwn(service["service_detail"], "price_per_hour")) {
        let serviceItem = {
          service_id: service["service"],
          catagory_name: service["service_info"].catagory_name,
          service_name: service["service_info"].service_name,
          is_provided: true,
          provider_service_id: service.id,
          service_detail_id: service["service_detail"].id,
          price_per_hour: service["service_detail"].price_per_hour
          // credential_link: service["service_detail"].credential_link || null
        };
        if(Object.hasOwn(service["service_detail"], "credential_link")){
          serviceItem["credential_link"] = service["service_detail"].credential_link;
          serviceItem["credential_name"] = service["service_detail"].credential_name;
        }
        serviceList.push(serviceItem);
      } else {
        let serviceItem = {
          service_id: service["service"],
          catagory_name: service["service_info"].catagory_name,
          service_name: service["service_info"].service_name,
          is_provided: true,
          provider_service_id: service.id,
          service_detail_id: service["service_detail"].id,
          max_group_size: service["service_detail"].max_group_size,
          price_per_head: service["service_detail"].price_per_head,
        };

        if(Object.hasOwn(service["service_detail"], "special_care_experience")){
          serviceItem["additional_children_price"] = service["service_detail"].additional_children_price;
          serviceItem["child_working_credential_link"] = service["service_detail"].child_working_credential_link;
          serviceItem["child_working_credential_name"] = service["service_detail"].child_working_credential_name;
          
          serviceItem["driver_license_link"] = service["service_detail"].driver_license_link;
          serviceItem["driver_license_name"] = service["service_detail"].driver_license_name;
          serviceItem["special_care_experience"] = service["service_detail"].special_care_experience;
        } else {
          serviceItem["additional_guests_price"] = service["service_detail"].additional_guests_price;
        }
        
        if(Object.hasOwn(service["service_detail"], "dog_walking")){
          serviceItem["dog_walking"] = service["service_detail"].dog_walking;
          serviceItem["can_drive_car"] = service["service_detail"].can_drive_car;
          serviceItem["can_drive_own_car"] = service["service_detail"].can_drive_own_car;
        }
        serviceList.push(serviceItem);
      }
    });
  }
  return serviceList;
}