// TODO: Uncomment and Check the entire file for issues once the frontend server is started. Commenting this now!
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import Rating from "@material-ui/lab/Rating";
import {
  BlueColor3,
  BlueColor4,
  GrayColor4
} from "../../../styling/styled-components/color-themes_old";

// const BACKGROUND_SIZE = "60vh";
const SERVICE_LENGTH = 3;

const useStyles = makeStyles(theme => ({
  root: {
    // maxWidth: 345,
    width: 400,
    height: "600",
    borderRadius: 10
  },
  container: {
    // border: '1px solid blue',
    margin: 10,
    maxWidth: "fit-content"
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    height: "5rem",
    width: "5rem"
  },
  ratingBox: {
    display: "box"
  },
  ratingIcon: {
    marginLeft: 5,
    color: "#FF6161"
  },
  checkIcon: {
    color: "blue"
  },
  cardContent: {
    // border: '1px solid red',
    minHeight: 150
  },
  services: {
    // border: '1px solid red',
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginBottom: 5
  },
  serviceItems: {
    // border: '1px solid green',
    display: "flex",
    width: "70%"
  },
  servicePrice: {
    display: "flex",
    width: "30%",
    justifyContent: "flex-end"
  },
  bold: {
    fontWeight: 900,
    color: GrayColor4
  },
  profileLink: {
    margin: 10,
    display: "flex",
    justifyContent: "flex-end"
    // border: '1px solid green',
  },
  linkColor: {
    color: BlueColor3,
    fontWeight: 700
  }
}));

const ProviderResultCard = props => {
  const { providerDetails } = props;
  const classes = useStyles();
  // const [favHosts, setFavHosts] = useState([]);
  // const [checked, setChecked] = useState(false);
  // const [bottom, setBottom] = useState(false);

  // const handleChange = () => {
  //   setChecked(prev => !prev);
  // };

  const fullname = () => {
    return `${providerDetails.first_name} ${providerDetails.last_name}`;
  };

  const cardServices = service => {
    return (
      <Grid container alignContent="row" className={classes.services}>
        service name
        <Grid item key={service.id} className={classes.serviceItems}>
          <Box key={service.id} display="flex" alignItems="center">
            <Box mr={1}>
              <CheckIcon style={{ color: BlueColor4 }} />
            </Box>
            <Box>
              <Typography variant="caption" component="p">
                {service.service_name}
              </Typography>
            </Box>
          </Box>
        </Grid>
        {/* service price */}
        <Grid item className={classes.servicePrice}>
          <Box display="flex" alignItems="center">
            {service.service_details && (
              <>
                <Typography variant="body2" color="textSecondary" component="p">
                  from
                </Typography>

                <Box ml={1}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className={classes.bold}
                  >
                    {service.service_details.price_per_head ? (
                      <>${service.service_details.price_per_head}</>
                    ) : service.service_details.price_per_hour ? (
                      <>${service.service_details.price_per_hour}</>
                    ) : (
                      <>no price</>
                    )}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    );
  };
  return (
    <Grid container xs={12} md={6} className={classes.container}>
      {/* card avatar, fullname and rating */}
      <Card variant="outlined" className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              <img src={providerDetails.profile_picture} />
            </Avatar>
          }
          title={fullname()}
          titleTypographyProps={{ variant: "body1" }}
          subheader={
            <Box className={classes.ratingBox}>
              <Typography>Rating</Typography>
              <Rating
                className={classes.ratingIcon}
                label={"rating"}
                name={"rating"}
                value={providerDetails.overall_rating}
                size={"medium"}
                readOnly
              />
            </Box>
          }
        />

        {/* card services and price */}
        <CardContent className={classes.cardContent}>
          {/* code to render if provider has services less than 3 */}
          {providerDetails.services.length <= SERVICE_LENGTH && (
            <>
              {providerDetails.services.map(service => cardServices(service))}
            </>
          )}

          {/* code to render if provider has services more than 3 */}
          {providerDetails.services.length > SERVICE_LENGTH && (
            <>
              {providerDetails.services
                .slice(0, SERVICE_LENGTH)
                .map(service => cardServices(service))}
              <Box ml={1}>
                <Typography variant="body2" color="textSecondary" component="p">
                  {providerDetails.services.length - 3}+ more services
                </Typography>
              </Box>
            </>
          )}
        </CardContent>

        {/* Link to provider's profile */}
        <CardActions>
          <Grid item container className={classes.profileLink}>
            <Link to="/instructor" className={classes.linkColor}>
              View Profile
            </Link>
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  );
};

function mapDispatchToProps(dispatch) {
  return {};
}

function mapStateToProps(state) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderResultCard);
