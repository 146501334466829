import agent from "../../agent.js";
import { GET_PROFILE_DATA } from "./action-types";

export const loadProfileData = profileData => {
  return {
    type: GET_PROFILE_DATA,
    payload: profileData
  };
};

export const getProfileData = client_id => async dispatch => {
  const response = await agent.Users.getClientProfile(client_id);
  const response2 = await agent.Users.getClientProfileImage(client_id);
  const response3 = await agent.Users.getGallery(client_id);

  const {
    first_name,
    last_name,
    gender,
    dob,
    mobile_number,
    bio,
    country,
    languages,
    skiing_level,
    snowboarding_level
  } = response.data;

  const { profile_picture } = response2.data;

  const imageGallery = response3.data;

  const profileData = {
    firstName: first_name,
    lastName: last_name,
    bio,
    country,
    skiLevel: skiing_level,
    snowLevel: snowboarding_level,
    languages,
    gender,
    dob,
    mobileNumber: mobile_number,
    profile_picture,
    imageGallery
  };
  await dispatch(loadProfileData(profileData));
};
