import React from "react";
import { Link } from "react-router-dom";
import { Grid, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  wrapper: {
    // minHeight: "80vh"
  },
  container: {
    width: "50%"
  },
  headerText: {
    fontSize: "26px",
    fontWeight: 400,
    fontFamily: "PT Sans Narrow"
  },
  verifyButton: {
    width: "224px",
    height: "40px",
    marginTop: "3em",
    borderRadius: 100,
    textAlign: "center"
  },
  buttonRoot: {
    backgroundColor: "#FF6161"
  },
  buttonLabel: {
    color: "white",
    fontWeight: 700,
    letterSpacing: "1%",
    fontFamily: "PT Sans"
  },
  finePrint: {
    marginTop: "5rem",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "PT Sans Narrow"
  }
}));

const ConfirmEmail = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={0}
      direction="row"
      alignItems="center"
      justifyContent="center"
      className={classes.wrapper}
    >
      <Grid item xs={11} sm={10} md={6} className={classes.container}>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Typography align="center" className={classes.headerText}>
              {/* replace email with user data */}
              To secure your RESORTer account, verify your email address
              johnsmith@gmail.com
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid itemm xs={11} sm={10} md={6}>
            <Link to="/landing-page" style={{ textDecoration: "none" }}>
              <Button
                type="submit"
                className={classes.verifyButton}
                classes={{
                  label: classes.buttonLabel,
                  root: classes.buttonRoot
                }}
                variant="contained"
              >
                Verify email address
              </Button>
            </Link>
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          className={classes.finePrint}
        >
          <Grid itemm xs={11}>
            <Typography color="textSecondary">
              You’re receiving this email because you reccently created a new
              RESORTer account. If this wasn’t you, please ignore this email.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConfirmEmail;
