import React from "react";
import FooterBar from "../../components/Common/Footer/FooterBar";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Rating from "@material-ui/lab/Rating";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
  mainGrid: {
    position: "center"
  },
  roundimg: {
    width: "100px",
    height: "100px"
  },
  iconFilled: {
    color: "#1277B8"
  },
  iconHover: {
    color: "#1277B8"
  },
  textfieldbackground: {
    padding: "0em",
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    borderRadius: "0px",
    "& .PrivateNotchedOutline-root-23": {
      border: "none"
    }
  },
  textcolor: {
    fontFamily: "PT Sans Narrow",
    fontWeight: "bold",
    color: "#1277B8",
    fontSize: 20,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16
    }
  },
  bottombutton: {
    color: "secondary",
    align: "center",
    borderRadius: 20,
    background: "#FF6161",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 18,
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
    border: "none",
    width: 80,
    height: 40
  },
  maindiv: {
    marginTop: 50
  },
  textname: {
    fontFamily: "PT Sans Narrow",
    color: "#1277B8",
    fontWeight: "bold",
    fontSize: 30,
    [theme.breakpoints.down("xs")]: {
      fontSize: 25
    }
  },
  textreview: {
    fontFamily: "PT Sans Narrow",
    color: "#1277B8",
    fontWeight: "bold",
    fontSize: 25,
    [theme.breakpoints.down("xs")]: {
      fontSize: 20
    }
  },
  gridstylecommon: {
    marginTop: 20
  },
  gridreview: {
    marginTop: 40
  }
}));

function AutoGrid() {
  const classes = useStyles();
  const [valuefri, setValueFri] = React.useState(0);
  const [valuetech, setValueTech] = React.useState(0);
  const [valueclarity, setValueClarity] = React.useState(0);
  const [valueoverall, setValueOverall] = React.useState(0);

  return (
    <div align="center" className={classes.maindiv}>
      <Grid container direction="column" md={8} sm={10} xs={12}>
        {/* grid 1  Name*/}
        <Grid
          container
          item
          justify="center"
          direction="row"
          position="center"
          md={12}
          sm={12}
          xs={12}
        >
          <Grid item md={1} sm={1} xs={1} />
          <Grid item justify="center" align="center" md={3} sm={3} xs={3}>
            {/* <Grid item justify="flex-end" margin="0" padding="0" md={3}> */}
            <Avatar
              position={"left"}
              className={classes.roundimg}
              alt="Remy Sharp"
              src="/static/images/avatar/1.jpg"
            />
          </Grid>
          <Grid item md={2} sm={2} xs={2} />
          <Grid item md={6} sm={6} xs={6}>
            <div className={classes.textname} justify="center" align="left">
              Casey Flemmings
            </div>
          </Grid>
        </Grid>

        {/* grid 2 Friendliness*/}
        <Grid
          container
          item
          justify="center"
          direction="row"
          position="center"
          className={classes.gridstylecommon}
          md={12}
          sm={12}
          xs={12}
        >
          <Grid item md={2} sm={2} xs={1} />
          <Grid item md={3} sm={4} xs={4}>
            <div align="left" className={classes.textcolor}>
              Friendliness
            </div>
          </Grid>
          <Grid item md={3} sm={1} xs={1} />
          <Grid item md={4} sm={5} xs={6}>
            <div>
              <Rating
                className={classes.iconHover}
                name="rating-friendliness"
                value={valuefri}
                onChange={(event, newValueFri) => {
                  setValueFri(newValueFri);
                }}
              />
            </div>
          </Grid>
        </Grid>

        {/* grid 3 Techinical Expertise*/}
        <Grid
          container
          item
          justify="center"
          direction="row"
          position="center"
          className={classes.gridstylecommon}
          md={12}
          sm={12}
          xs={12}
        >
          <Grid item md={2} sm={2} xs={1} />
          <Grid item md={3} sm={4} xs={4}>
            <div align="left" className={classes.textcolor}>
              Technical Expertise
            </div>
          </Grid>
          <Grid item md={3} sm={1} xs={1} />
          <Grid item md={4} sm={5} xs={6}>
            <div>
              <Rating
                className={classes.iconHover}
                name="rating-technical"
                value={valuetech}
                onChange={(event, newValueTech) => {
                  setValueTech(newValueTech);
                }}
              />
            </div>
          </Grid>
        </Grid>

        {/* grid 4 Clarity*/}
        <Grid
          container
          item
          justify="center"
          direction="row"
          position="center"
          className={classes.gridstylecommon}
          md={12}
          sm={12}
          xs={12}
        >
          <Grid item md={2} sm={2} xs={1} />
          <Grid item md={3} sm={4} xs={4}>
            <div align="left" className={classes.textcolor}>
              Clarity
            </div>
          </Grid>
          <Grid item md={3} sm={1} xs={1} />
          <Grid item md={4} sm={5} xs={6}>
            <div>
              <Rating
                className={classes.iconHover}
                name="rating-clarity"
                value={valueclarity}
                onChange={(event, newValueClarity) => {
                  setValueClarity(newValueClarity);
                }}
              />
            </div>
          </Grid>
        </Grid>

        {/* grid 5 Overall*/}
        <Grid
          container
          item
          justify="center"
          direction="row"
          position="center"
          className={classes.gridstylecommon}
          md={12}
          sm={12}
          xs={12}
        >
          <Grid item md={2} sm={2} xs={1} />
          <Grid item md={3} sm={4} xs={3}>
            <div align="left" className={classes.textname}>
              Overall
            </div>
          </Grid>
          <Grid item md={3} sm={1} xs={1} />
          <Grid item md={4} sm={5} xs={7}>
            <div>
              <Rating
                alignItems="left"
                className={classes.iconFilled}
                size="large"
                name="rating-overall"
                value={valueoverall}
                onChange={(event, newValueOverall) => {
                  setValueOverall(newValueOverall);
                }}
              />
            </div>
          </Grid>
        </Grid>

        {/* grid 6 Leave a Review*/}
        <Grid
          container
          item
          justify="center"
          direction="row"
          position="center"
          className={classes.gridreview}
          md={12}
          sm={12}
          xs={12}
        >
          <Grid item md={2} sm={2} xs={1} />
          <Grid item md={10} sm={10} xs={11}>
            <div align="left" className={classes.textreview}>
              Leave a Review
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          item
          justify="center"
          direction="row"
          position="center"
          md={12}
          sm={12}
          xs={12}
        >
          <Grid item md={2} sm={2} xs={1} />
          <Grid item md={9} sm={9} xs={10}>
            <TextField
              className={classes.textfieldbackground}
              id="outlined-multiline-static"
              multiline
              fullWidth
              rows={3}
              defaultValue=""
              variant="outlined"
            />
          </Grid>
          <Grid item md={1} sm={1} xs={1} />
        </Grid>

        <Grid
          container
          item
          justify="center"
          direction="row"
          position="center"
          className={classes.gridreview}
          md={12}
          sm={12}
          xs={12}
        >
          <Grid item md={4} sm={4} xs={3} />
          <Grid item md={5} sm={4} xs={6} align="center">
            <button
              className={classes.bottombutton}
              variant="contained"
              color="secondary"
            >
              Submit
            </button>
          </Grid>
          <Grid item md={3} sm={4} xs={3} />
        </Grid>
      </Grid>
    </div>
  );
}

export default function Review() {
  return (
    <div>
      <AutoGrid />
    </div>
  );
}
