import { PROFILE_FIELD_NAMES } from "../../constants/common";
import { LOAD_PROFILE } from "../actions/action-types";

const {
  FIRST_NAME,
  LAST_NAME,
  DOB,
  GENDER,
  SKI_ABILITY,
  SNOWBOARD_ABILITY,
  TELEMARK_ABILITY,
  SNOWBIKE_ABILITY,
  SNOWMOBILE_ABILITY,
  SNOWSHOE_ABILITY,
  IS_DISABLED,
  // DISABILITY_DETAIL,
  // DISABILITY_MEMBERSHIP,
  // DISABILITY_MEMBERSHIP_ID,
  COUNTRY_CODE,
  PHONE_NUMBER,
  EMAIL,
  POSTCODE,
  HASSNOWPASS
  // MEMBER_TYPE,
  // PASSWORD,
} = PROFILE_FIELD_NAMES;

const today = new Date();

const initialState = {
  data:
    process.env.REACT_APP_NODE_ENV === "production"
      ? {
          [SKI_ABILITY]: 1,
          [SNOWBOARD_ABILITY]: 1,
          [TELEMARK_ABILITY]: 1,
          [SNOWBIKE_ABILITY]: 1,
          [SNOWMOBILE_ABILITY]: 1,
          [SNOWSHOE_ABILITY]: 1,
          [HASSNOWPASS]: false,
          [COUNTRY_CODE]: { name: null, dial_code: null }
        }
      : {
          [SKI_ABILITY]: 1,
          [SNOWBOARD_ABILITY]: 1,
          [TELEMARK_ABILITY]: 1,
          [SNOWBIKE_ABILITY]: 1,
          [SNOWMOBILE_ABILITY]: 1,
          [SNOWSHOE_ABILITY]: 1,
          [HASSNOWPASS]: false,
          [FIRST_NAME]: "Homer",
          [LAST_NAME]: "Simpson",
          [GENDER]: "male",
          [DOB]: new Date(new Date().setFullYear(today.getFullYear() - 18)),

          [IS_DISABLED]: false,
          // [DISABILITY_DETAIL]: "Can't be bothered",
          // [DISABILITY_MEMBERSHIP]: "DSUSA",
          // [DISABILITY_MEMBERSHIP_ID]: "47328572"
          [COUNTRY_CODE]: { name: "Australia", dial_code: "+61" },
          [PHONE_NUMBER]: 0,
          [EMAIL]: "test@hotmail.com",
          [POSTCODE]: "2222"
        }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PROFILE:
      return {
        data: {
          ...action.data
        }
      };
    default:
      return state;
  }
};

/**
 * Simulates data loaded into this reducer from somewhere
 */

export default reducer;
