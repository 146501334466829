import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import reformatDate from "./UpcomingVideoLesson";
import moment from "moment";
import MomentUtils from "@date-io/moment";

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 200,
    borderBottomStyle: "groove",
    paddingBottom: "6px"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  name: {
    color: "black",
    fontWeight: "noraml",
    fontSize: "16px",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "22.5px",
    textAlign: "center"
  },
  price: {
    // hide the margin Right when it is mobile view
    [theme.breakpoints.up("sm")]: {
      marginRight: 39
    },
    fontFamily: "PT Sans Narrow",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "22.5px",
    textAlign: "center"
  },
  schedule: {
    margin: "10px 0px",
    fontWeight: "bold",
    fontFamily: "PT Sans Narrow",
    fontSize: "16px",
    fontStyle: "normal",
    lineHeight: "22.5px",
    textAlign: "left",
    color: "#606060",
    paddingBottom: "6px",
    display: "inline-flex"
  },
  font: {
    fontFamily: "PT Sans Narrow",
    color: "black",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px"
  }
}));

export default function PastVideoLesson(props) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  const name = props.name;
  const price = props.price;
  const start = props.start;
  const end = props.end;
  let status = props.status;
  if (status == "Scheduled") {
    status = "Complete";
  }

  // re-format the time and date
  const year = start.getFullYear();
  const month = start.toLocaleString("default", { month: "short" });
  const day = start.getDate();
  const date = day + " " + month + " " + year;
  const startTime = moment(start).format("h:mm A");
  const endTime = moment(end).format("h:mm A");
  const schedule = startTime + " to " + endTime + ", " + date;

  return (
    <Card elevation={0} className={classes.root}>
      <CardContent style={{ padding: "16px 0 0 4px" }}>
        <Grid container item spacing={2}>
          <Grid
            container
            item
            xs={6}
            direction="column"
            alignItems="flex-start"
          >
            <Grid item className={classes.font}>
              INSTRUCTOR
            </Grid>
            <Grid item>
              <Typography
                display={"inline"}
                className={classes.name}
                gutterBottom
              >
                {name}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={6}
            direction="column"
            alignItems="flex-start"
          >
            <Grid item className={classes.font}>
              PRICE
            </Grid>
            <Grid item>
              <Typography display={"block"} className={classes.price}>
                ${price}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item className={classes.schedule}>
          <Typography
            display={"inline"}
            className={classes.schedule}
            color={"textSecondary"}
          >
            Completed: {schedule}
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  );
}
