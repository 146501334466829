import { TOGGLE_INSTRUCTOR_LIKE, SAVE_INSTRUCTOR_STATE } from "./action-types";

export const toggleInstructorLike = instructorId => {
  return {
    type: TOGGLE_INSTRUCTOR_LIKE,
    payload: { instructorId }
  };
};

export const saveInstructorState = instructorData => {
  return {
    type: SAVE_INSTRUCTOR_STATE,
    payload: { ...instructorData }
  };
};
