import React from "react";
import useStyles from "./MyAccountStyles";
import Grid from "@material-ui/core/Grid";

import FileUpload from "./FileUpload";

import InsQualification from "./InsQualification";

const Qualifications = () => {
  const classes = useStyles();

  return (
    <Grid item container lg={6} md={6} sm={6} xs={12}>
      <Grid item lg={4} md={4} sm={4} xs={2}>
        <div align="right" className={classes.textFieldStyle}>
          Qualifications:
        </div>
      </Grid>
      <Grid item lg={2} md={2} sm={2} xs={1} />
      <Grid item lg={5} md={5} sm={5} xs={9}>
        <div align="left">
          <InsQualification />
        </div>
      </Grid>
      <Grid item lg={1} md={1} sm={1} />
      <Grid item lg={4} md={4} sm={4} xs={2}>
        <div align="right" className={classes.textFieldStyle}>
          Qualification Proof:
        </div>
      </Grid>
      <Grid item lg={2} md={2} sm={2} xs={1} />
      <Grid item lg={5} md={5} sm={5} xs={9}>
        <div align="left">
          <FileUpload />
        </div>
      </Grid>
      <Grid item lg={1} md={1} sm={1} />
    </Grid>
  );
};

export default Qualifications;
