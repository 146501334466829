import React from "react";
import Grid from "@material-ui/core/Grid";
import SectionHeading from "./SectionHeading";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  leftPane: {
    background: "rgba(196, 196, 196, 0.12)",
    borderRadius: "10px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  text: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "27px",
    lineHeight: "128.91%",
    letterSpacing: "0.02em",
    color: "#000000",
    marginBottom: "2%"
  },
  button: {
    width: "100%",
    height: "100%",
    border: "none",
    background: "#ff6161",
    borderRadius: "100px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "140.62%",
    textAlign: "center",
    letterSpacing: "0.01em",
    color: "#ffffff"
  },
  btnContainer: {
    width: "47%",
    height: "18%",
    "&&": {
      marginTop: "6%"
    }
  },
  image: {
    width: "100%",
    borderRadius: "10px"
  },
  [theme.breakpoints.down("md")]: {
    text: {
      fontSize: "22px"
    },
    button: {
      fontSize: "16px"
    }
  },
  [theme.breakpoints.down("sm")]: {
    wrapper: {
      flexDirection: "column-reverse"
    },
    textContainer: {
      "&&": {
        marginTop: "4%"
      }
    },
    text: {
      fontSize: "19px"
    },
    button: {
      fontSize: "13px"
    },
    btnContainer: {
      "&&": {
        marginBottom: "6%"
      }
    }
  },
  [theme.breakpoints.down("xs")]: {
    text: {
      fontSize: "17px"
    },
    button: {
      fontSize: "11px",
      padding: "3% 0"
    }
  }
}));

const fakeData = {
  imageURL:
    "https://images.unsplash.com/photo-1482594254723-cc424817c99a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1041&q=80",
  alt: "group lesson"
};

const GroupLessonSection = () => {
  const classes = useStyles();

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <SectionHeading children="Book Group Lessons" />
        </Grid>

        <Grid item container className={classes.wrapper}>
          <Grid
            item
            container
            sm={7}
            md={7}
            lg={7}
            className={classes.leftPane}
          >
            <Grid item className={classes.textContainer}>
              <p className={classes.text}>
                First time? Returning to the slope?
              </p>
              <p className={classes.text}>
                Meet others just like you in group lessons.
              </p>
            </Grid>

            <Grid item className={classes.btnContainer}>
              <button className={classes.button}>Book Group Lesson</button>
            </Grid>
          </Grid>

          <Grid item sm={5} md={5} lg={5}>
            <img
              src={fakeData.imageURL}
              alt={fakeData.alt}
              className={classes.image}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default GroupLessonSection;
