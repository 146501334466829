import {
  SHOW_GRO_LES_ADD,
  CHANGE_COMMENT,
  MAN_DATE_CHANGE,
  MAN_ADD_MEMBER,
  MAN_REMOVE_MEMBER,
  MAN_CREATE_LESSON,
  MAN_DELETE_LESSON,
  GRO_DATE_CHANGE,
  GRO_REMOVE_MEMBER,
  GRO_CREATE_LESSON,
  PRI_DATE_CHANGE,
  PRI_REMOVE_MEMBER,
  ADA_DATE_CHANGE,
  ADA_REMOVE_MEMBER,
  SHOW_LESSON_ERRORS,
  UPDATE_LESSON_ERRORS,
  LOAD_PRIVATE_LESSONS,
  LOAD_GROUP_LESSONS,
  SWITCH_LESSON_TABS,
  SHOW_FIRST_TIMERS,
  CREATE_TRIP_LESSONS,
  DELETE_LESSON,
  EDIT_LESSON,
  CHANGE_NEW_LESSON_EFFECT,
  FISRT_TIMER_NO_LESSON_WARNING
} from "./action-types";

export function show_gro_les_add() {
  return {
    type: SHOW_GRO_LES_ADD,
    payload: {}
  };
}

export function change_comment(value) {
  return {
    type: CHANGE_COMMENT,
    payload: { value }
  };
}

/*
 * MANDATORY LESSONS
 */

export function man_date_change({ key, new_date }) {
  return {
    type: MAN_DATE_CHANGE,
    payload: { key, new_date }
  };
}

export function man_add_member({ key, memberIDs }) {
  return {
    type: MAN_ADD_MEMBER,
    payload: { key, memberIDs }
  };
}

export function man_remove_member({ key, memberID }) {
  return {
    type: MAN_REMOVE_MEMBER,
    payload: { key, memberID }
  };
}

export function man_delete_lesson(key) {
  return {
    type: MAN_DELETE_LESSON,
    payload: { key }
  };
}

export function man_create_lesson(new_lesson) {
  return {
    type: MAN_CREATE_LESSON,
    payload: { new_lesson }
  };
}

/*
 * GROUP LESSONS
 */

export function gro_date_change({ key, new_date }) {
  return {
    type: GRO_DATE_CHANGE,
    payload: { key, new_date }
  };
}

export function gro_remove_member({ key, memberID }) {
  return {
    type: GRO_REMOVE_MEMBER,
    payload: { key, memberID }
  };
}

export function gro_create_lesson(new_lesson) {
  return {
    type: GRO_CREATE_LESSON,
    payload: { new_lesson }
  };
}

export function switchLessonTabs(tabIndex) {
  return {
    type: SWITCH_LESSON_TABS,
    payload: { tabIndex }
  };
}

/*
 * PRIVATE LESSONS
 */

export function pri_date_change({ key, new_date }) {
  return {
    type: PRI_DATE_CHANGE,
    payload: { key, new_date }
  };
}

export function pri_remove_member({ key, memberID }) {
  return {
    type: PRI_REMOVE_MEMBER,
    payload: { key, memberID }
  };
}
/*
 * ADAPTIVE LESSONS
 */

export function ada_date_change({ key, new_date }) {
  return {
    type: ADA_DATE_CHANGE,
    payload: { key, new_date }
  };
}

export function ada_remove_member({ key, memberID }) {
  return {
    type: ADA_REMOVE_MEMBER,
    payload: { key, memberID }
  };
}

export function showLessonErrors(show) {
  return {
    type: SHOW_LESSON_ERRORS,
    payload: { show }
  };
}

export function updateLessonErrors(errors) {
  return {
    type: UPDATE_LESSON_ERRORS,
    payload: { errors }
  };
}

export function loadPrivateLessons(privateLessons) {
  return {
    type: LOAD_PRIVATE_LESSONS,
    payload: { privateLessons: privateLessons }
  };
}

export function loadGroupLessons(groupLessons) {
  return {
    type: LOAD_GROUP_LESSONS,
    payload: { groupLessons: groupLessons }
  };
}

export function createTripLessons(tripLessons) {
  return {
    type: CREATE_TRIP_LESSONS,
    payload: { tripLessons }
  };
}

export function deleteTripLesson(tripLessonId) {
  return {
    type: DELETE_LESSON,
    payload: { tripLessonId }
  };
}

export function editTripLesson(newTripLessons) {
  return {
    type: EDIT_LESSON,
    payload: { newTripLessons }
  };
}

export function changeNewLessonEffect() {
  return {
    type: CHANGE_NEW_LESSON_EFFECT,
    payload: {}
  };
}
export function firstTimerNoLessonWarning(firstTimersWithoutLessons) {
  return {
    type: FISRT_TIMER_NO_LESSON_WARNING,
    payload: { firstTimersWithoutLessons }
  };
}
