import React from "react";
import $ from "jquery";
import {
  OrangeButton,
  SectionTitle
} from "../../../../styling/styled-components";
import StyledModal from "../../StyledModal";

export default function GPDRWarning() {
  const id = "GPDRWarning";
  const body = (
    <div>
      <SectionTitle>GPDR Euro Legislation</SectionTitle>
      <p>
        Check back soon to book this resort. It is undergoing a privacy health
        check with the new privacy legislation in the EU.
      </p>
    </div>
  );
  const footer = (
    <OrangeButton
      style={{ margin: "0" }}
      onClick={() => {
        $("#" + id).modal("hide");
      }}
    >
      OK
    </OrangeButton>
  );
  return <StyledModal id={id} body={body} footer={footer} />;
}
