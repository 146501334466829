import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content"
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120
  },
  formControlLabel: {
    marginTop: theme.spacing(1)
  },
  small: {
    width: theme.spacing(17),
    height: theme.spacing(17),
    padding: "4px"
  },
  dialogContent: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "36px",
    lineHeight: "140.62%",
    color: "#1277B8"
  }
}));

export default function BookingPopup(props) {
  const classes = useStyles();

  const handleClose = () => {
    props.handleClose(false);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      open={props.open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      {/* <DialogTitle id="max-width-dialog-title"></DialogTitle> */}
      <Grid item container xs={2} justify="center" alignItems="center">
        <Button onClick={handleClose} color="primary">
          Back
        </Button>
      </Grid>

      <Grid item container xs={12} justify="center" alignItems="center">
        <DialogContentText className={classes.dialogContent}>
          BOOK A VIRTUAL LESSON
        </DialogContentText>
      </Grid>

      <DialogContent>
        <Grid
          item
          container
          xs={12}
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item container xs={5} justify="center" alignItems="center">
            SELECT DATE
          </Grid>

          <Grid item container xs={3} justify="center" alignItems="center">
            <Avatar className={classes.small} src={props.avatarSrc} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
