import dummyLesson from "../../materials/DummyData/dummyLessonOptions";

const initial_state = {
  // group lesson options (resort-specific)
  groLesOptions: dummyLesson["group"],

  priLesOptions: dummyLesson["private"],

  adaLesOptions: dummyLesson["adaptive"],

  peakST: "", // start date of peak
  peakEND: "", // end date of peak

  groLesAMStartTime: "9", // start time of AM group lesson
  groLesPMStartTime: "1" // start time of PM group lesson
};

const resortReducer = (state = initial_state, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default resortReducer;
