import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Button } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/Close";

// Styles
import useStyles from "../../Common/SignupForm/ClientInfoStyles";

// Database
import agent from "../../../agent";

// Redux
import { getProfileData } from "../../../redux/actions/profileDataAction.js";

// Component
import ProfilePicture from "./form/ProfilePictureForm";
import Bio from "./form/BioForm";
import Languages from "./form/LanguageForm";
import Nationality from "./form/NationalityForm";
import AbilityLevel from "./form/AbilityForm";
import ImageGalleryForm from "./ImageGalleryForm";

const useClasses = makeStyles(theme => ({
  avatarSize: {
    width: "10rem",
    height: "10rem",
    [theme.breakpoints.down("sm")]: {
      width: "10rem",
      height: "10rem"
    }
  },
  btnEdit: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 16,
    marginTop: 8,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14
    },
    color: "#1277B8",
    border: "none",
    backgroundColor: "transparent",
    padding: 0
  },
  btnDisabled: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 16,
    marginTop: 8,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14
    },
    color: "#BDBDBD",
    border: "none",
    backgroundColor: "transparent",
    padding: 0
  },
  headerLabel: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "26px",
    color: "#000000"
  },
  buttonRoot: {
    backgroundColor: "#FF6161"
  },
  buttonLabel: {
    color: "white",
    fontWeight: 700,
    letterSpacing: "1%",
    fontFamily: "PT Sans"
  },
  nextButton: {
    width: "102px",
    height: "2.5em",
    borderRadius: 100,
    textAlign: "center"
  },
  backButton: {
    width: "60%",
    marginRight: "1em",
    fontFamily: "PT Sans",
    border: "white"
  },
  backButtonLabel: {
    color: "#FF6161",
    fontWeight: 700,
    letterSpacing: "1%",
    fontFamily: "PT Sans"
  },
  iconbutton: {
    color: "white",
    backgroundColor: "#BDBDBD",
    "&:hover": {
      backgroundColor: "#BDBDBD",
      cursor: "default"
    }
  }
}));

let MyInfoForm = props => {
  const styles = useStyles();
  const classes = useClasses();

  let [isLoading, setIsLoading] = useState(true);
  let [isEditing, setEditing] = useState(false);
  let [isUploadPhotos, setUploadPhotos] = useState(false);

  const user_id = props.currentUser.user_id;

  let [profilePicture, setProfilePicture] = useState();
  let [bio, setBio] = useState();
  let [nationality, setNationality] = useState();
  let [languages, setLanguages] = useState([]);
  let [skiLevel, setSkiLevel] = useState("");
  let [snowboardLevel, setSnowboardLevel] = useState("");
  let [gallery, setGallery] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    if (props.profileData != undefined) {
      setProfilePicture(props.profileData.profile_picture);
      setBio(props.profileData.bio);
      if (props.profileData.country != undefined) {
        setNationality(props.profileData.country);
      }
      setLanguages(props.profileData.languages);
      if (props.profileData.skiLevel != undefined) {
        setSkiLevel(props.profileData.skiLevel);
      }
      if (props.profileData.snowLevel != undefined) {
        setSnowboardLevel(props.profileData.snowLevel);
      }

      let imageArray = [];
      if (props.profileData.imageGallery != undefined) {
        if (props.profileData.imageGallery.length > 0) {
          Array.from(new Array(6)).map((_, i) => {
            if (props.profileData.imageGallery[i] != undefined) {
              imageArray.push({
                id: i,
                image_id: props.profileData.imageGallery[i].id,
                image_link: props.profileData.imageGallery[i].image_link,
                image_file: ""
              });
            } else {
              imageArray.push({
                id: i,
                image_id: 0,
                image_link: "",
                image_file: ""
              });
            }
          });
          setGallery(imageArray);
        }

        if (props.profileData.imageGallery.length == 6) {
          setUploadPhotos(false);
        } else {
          setUploadPhotos(true);
        }
      }

      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [props.profileData]);
  useEffect(() => {
    for (const obj of gallery) {
      if (obj.image_link === "") {
        setUploadPhotos(true);
      } else {
        setUploadPhotos(false);
      }
    }
  }, [gallery]);

  // Upload profile image to AWS and update data in database
  const uploadProfileImage = async () => {
    const profileImageData = new FormData();
    profileImageData.append("profile_picture", profilePicture);
    try {
      await agent.Users.uploadProfileImage(user_id, profileImageData);
      console.log("profile picture successfully uploaded!");

      const response2 = await agent.Users.getClientProfileImage(user_id);
      const { profile_picture } = response2.data;
      setProfilePicture(profile_picture);
    } catch (error) {
      console.error(error);
    }
  };
  // Button: Edit profile
  const handleEditing = async event => {
    event.preventDefault();
    setEditing(true);
  };

  // Upload image gallery to AWS and update data in database
  const manageGallery = async () => {
    for (const obj of gallery) {
      if (obj.image_id == 0 && obj.image_file != "") {
        //Uploaded image
        const imageData = new FormData();
        imageData.append("image_link", obj.image_file);
        imageData.append("user", user_id);
        try {
          await agent.Users.uploadGalleryImage(imageData);
          console.log("Image successfully uploaded!");
        } catch (error) {
          console.error(error);
          const message =
            error.response && error.response.data.detail
              ? error.response.data.detail
              : error.detail;
        }
      } else if (obj.image_id != 0 && obj.image_link == "") {
        //Removed image
        try {
          await agent.Users.deleteGalleryImage(obj.image_id);
          console.log("Image successfully removed!");
        } catch (error) {
          console.error(error);
          const message =
            error.response && error.response.data.detail
              ? error.response.data.detail
              : error.detail;
        }
      } else if (obj.image_id != 0 && obj.image_file != "") {
        //Replace image
        const imageData = new FormData();
        imageData.append("image_link", obj.image_file);
        imageData.append("user", user_id);
        try {
          await agent.Users.updateGalleryImage(obj.image_id, imageData);
          console.log("Image successfully replaced!");
        } catch (error) {
          console.error(error);
          const message =
            error.response && error.response.data.detail
              ? error.response.data.detail
              : error.detail;
        }
      }
    }

    const response3 = await agent.Users.getGallery(user_id);
    const imageGallery = response3.data;
    let imageArray = [];
    if (imageGallery != undefined) {
      Array.from(new Array(6)).map((_, i) => {
        if (imageGallery[i] != undefined) {
          imageArray.push({
            id: i,
            image_id: imageGallery[i].id,
            image_link: imageGallery[i].image_link,
            image_file: ""
          });
        } else {
          imageArray.push({
            id: i,
            image_id: 0,
            image_link: "",
            image_file: ""
          });
        }
      });
      setGallery(imageArray);

      if (props.profileData.imageGallery.length == 6) {
        setUploadPhotos(false);
      } else {
        setUploadPhotos(true);
      }
    }
  };
  // Popover: Upload a new gallery image
  const handleBrowseImage = event => {
    event.preventDefault();

    let uploaded = URL.createObjectURL(event.target.files[0]);
    let uploadedId = 0;
    let newArr = [];
    if (gallery.length == 0) {
      Array.from(new Array(6)).map((_, i) => {
        if (i == 0) {
          newArr.push({
            id: i,
            image_id: 0,
            image_link: uploaded,
            image_file: event.target.files[0]
          });
        } else {
          newArr.push({
            id: i,
            image_id: 0,
            image_link: "",
            image_file: ""
          });
        }
      });
    } else {
      for (const obj of gallery) {
        if (obj.image_link === "") {
          uploadedId = obj.id;

          break;
        }
      }
      newArr = gallery.map(obj => {
        if (obj.id === uploadedId) {
          return {
            ...obj,
            image_link: uploaded,
            image_file: event.target.files[0]
          };
        }
        return obj;
      });
    }
    setGallery(newArr);

    setEditing(true);
  };

  // Button: Save and Cancel
  const handleCancel = async event => {
    // Profile Picture
    setProfilePicture(props.profileData.profile_picture);

    // Bio
    setBio(props.profileData.bio);

    // Language
    setLanguages(props.profileData.languages);

    // Image Gallery
    let imageArray = [];
    if (props.profileData.imageGallery != undefined) {
      if (props.profileData.imageGallery.length > 0) {
        Array.from(new Array(6)).map((_, i) => {
          if (props.profileData.imageGallery[i] != undefined) {
            imageArray.push({
              id: i,
              image_id: props.profileData.imageGallery[i].id,
              image_link: props.profileData.imageGallery[i].image_link,
              image_file: ""
            });
          } else {
            imageArray.push({
              id: i,
              image_id: 0,
              image_link: "",
              image_file: ""
            });
          }
        });
        setGallery(imageArray);
      }

      if (props.profileData.imageGallery.length == 6) {
        setUploadPhotos(false);
      } else {
        setUploadPhotos(true);
      }
    }

    setEditing(false);
  };
  const handleSubmit = async event => {
    event.preventDefault();

    if (bio == "" || languages.length == 0) {
      alert("Please fill in all the required fields");
    } else {
      let nationalityId = nationality.id;
      let languagesArray = [];
      languages.forEach(item => {
        languagesArray.push(item.id);
      });

      const user = {
        user_id,
        bio,
        country: nationalityId,
        languages: languagesArray,
        skiing_level: skiLevel,
        snowboarding_level: snowboardLevel
      };

      try {
        await agent.Users.updateUser(
          user.user_id,
          user.bio,
          user.country,
          user.languages,
          user.skiing_level,
          user.snowboarding_level
        );
      } catch (error) {
        console.log(error);
        const message =
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail;
      }

      await uploadProfileImage();
      await manageGallery();

      props.getProfileData(user.user_id);

      setEditing(false);
    }
  };

  return (
    <div>
      {isLoading ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={0}
          direction="column"
          style={{ padding: "2rem 20px" }}
        >
          <div className="loader-container">
            <div className="loader" />
          </div>
        </Grid>
      ) : (
        <div>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              justify="center"
              alignItems="center"
              spacing={0}
              direction="column"
              style={{ padding: "0 20px" }}
            >
              {/* My Profile Header & Edit button */}
              <Grid
                container
                justify="center"
                alignItems="center"
                xs={12}
                sm={10}
                md={8}
                lg={6}
                spacing={2}
                direction="row"
                style={{ padding: "2rem 0" }}
              >
                <Grid item xs={8} sm={10}>
                  <Typography className={classes.headerLabel}>
                    My Profile
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  xs={4}
                  sm={2}
                >
                  {isEditing ? null : (
                    <Button className={classes.btnEdit} onClick={handleEditing}>
                      Edit Profile
                    </Button>
                  )}
                </Grid>
              </Grid>
              {/* Profile Picture, Bio */}
              <Grid
                container
                justify="center"
                alignItems="center"
                xs={12}
                sm={10}
                md={8}
                lg={6}
                spacing={2}
                direction="row"
              >
                <ProfilePicture
                  profilePicture={profilePicture}
                  setProfilePicture={setProfilePicture}
                  isDisabled={!isEditing}
                />

                <Bio bio={bio} setBio={setBio} isDisabled={!isEditing} />
              </Grid>
              {/* Nationality, Languages, Abilities */}
              <Grid
                container
                justify="center"
                alignItems="flex-start"
                xs={12}
                sm={10}
                md={8}
                lg={6}
                spacing={2}
                direction="row"
                style={{ padding: "2rem 0px" }}
              >
                {/* Nationality */}
                <Nationality
                  nationality={nationality}
                  setNationality={setNationality}
                  isDisabled={!isEditing}
                />
                {/* Languages */}
                <Languages
                  setLanguages={setLanguages}
                  languages={languages}
                  isDisabled={!isEditing}
                />
                {/* Abilities */}
                <AbilityLevel
                  skiLevel={skiLevel}
                  setSkiLevel={setSkiLevel}
                  snowboardLevel={snowboardLevel}
                  setSnowboardLevel={setSnowboardLevel}
                  isDisabled={!isEditing}
                />
              </Grid>
              {/* Divider */}
              <Grid
                container
                justify="center"
                alignItems="center"
                spacing={0}
                direction="column"
              >
                <Grid
                  container
                  justify="center"
                  alignItems="flex-start"
                  xs={12}
                  sm={10}
                  md={8}
                  lg={6}
                  spacing={2}
                  direction="row"
                  style={{ padding: "0.5rem" }}
                >
                  <Grid item xs={12} sm={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </Grid>
              {/* Image Gallery Header & Upload photos button */}
              <Grid
                container
                justify="center"
                alignItems="center"
                xs={12}
                sm={10}
                md={8}
                lg={6}
                spacing={2}
                direction="row"
                style={{ padding: "2rem 0" }}
              >
                <Grid item xs={8} sm={9}>
                  <Typography className={classes.headerLabel}>
                    Image Gallery
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  xs={4}
                  sm={3}
                >
                  {isEditing ? (
                    isUploadPhotos ? (
                      <label>
                        <input
                          accept="image/*"
                          className={styles.input}
                          id="contained-button-file"
                          multiple
                          hidden
                          type="file"
                          onChange={handleBrowseImage}
                        />
                        <Button
                          className={classes.btnEdit}
                          variant="text"
                          component="span"
                        >
                          Upload photos
                        </Button>
                      </label>
                    ) : (
                      <Button
                        className={classes.btnDisabled}
                        variant="text"
                        component="span"
                      >
                        Upload photos
                      </Button>
                    )
                  ) : (
                    <Button
                      className={classes.btnDisabled}
                      variant="text"
                      component="span"
                    >
                      Upload photos
                    </Button>
                  )}
                </Grid>
              </Grid>
              {/* Image Gallery List */}
              <Grid
                container
                justify="center"
                alignItems="flex-start"
                xs={12}
                sm={10}
                md={8}
                lg={6}
                spacing={2}
                direction="row"
                style={{ padding: "2rem 0px" }}
              >
                <ImageGalleryForm
                  item_id={0}
                  gallery={gallery}
                  setGallery={setGallery}
                  isDisabled={!isEditing}
                />
                <ImageGalleryForm
                  item_id={1}
                  gallery={gallery}
                  setGallery={setGallery}
                  isDisabled={!isEditing}
                />
                <ImageGalleryForm
                  item_id={2}
                  gallery={gallery}
                  setGallery={setGallery}
                  isDisabled={!isEditing}
                />
                <ImageGalleryForm
                  item_id={3}
                  gallery={gallery}
                  setGallery={setGallery}
                  isDisabled={!isEditing}
                />
                <ImageGalleryForm
                  item_id={4}
                  gallery={gallery}
                  setGallery={setGallery}
                  isDisabled={!isEditing}
                />
                <ImageGalleryForm
                  item_id={5}
                  gallery={gallery}
                  setGallery={setGallery}
                  isDisabled={!isEditing}
                />
              </Grid>
              {/* Divider */}
              <Grid
                container
                justify="center"
                alignItems="center"
                spacing={0}
                direction="column"
              >
                <Grid
                  container
                  justify="center"
                  alignItems="flex-start"
                  xs={12}
                  sm={10}
                  md={8}
                  lg={6}
                  spacing={2}
                  direction="row"
                  style={{ padding: "0.5rem" }}
                >
                  <Grid item xs={12} sm={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </Grid>
              {/* Button */}
              <Grid
                container
                justify="flex-end"
                alignItems="center"
                xs={12}
                sm={10}
                md={8}
                lg={6}
                spacing={2}
                direction="row"
                style={{ padding: "2rem 0" }}
              >
                {/* Cancel button */}
                <Grid
                  item
                  container
                  justify="center"
                  alignItems="center"
                  xs={6}
                  sm={2}
                >
                  {isEditing ? (
                    // Editable Mode
                    <Button
                      className={classes.backButton}
                      classes={{ label: classes.backButtonLabel }}
                      variant="outlined"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  ) : (
                    // Non-Editable Mode
                    <Button
                      className={classes.btnDisabled}
                      variant="text"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  )}
                </Grid>
                {/* Save button */}
                <Grid
                  item
                  container
                  justify="center"
                  alignItems="center"
                  xs={6}
                  sm={2}
                >
                  {isEditing ? (
                    // Editable Mode
                    <Button
                      type="submit"
                      className={classes.nextButton}
                      classes={{
                        label: classes.buttonLabel,
                        root: classes.buttonRoot
                      }}
                      variant="contained"
                    >
                      Save
                    </Button>
                  ) : (
                    // Non-Editable Mode
                    <Button
                      className={classes.nextButton}
                      classes={{
                        label: classes.buttonLabel,
                        root: classes.buttonRoot
                      }}
                      variant="contained"
                      disabled="true"
                    >
                      Save
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    </div>
  );
};

// Reducer part
function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth.currentUser,
    profileData: state.profileDataReducer.profileData
  };
}

// Adding Dispatch to update the existing values in Redux
const mapDispatchToProps = dispatch => {
  return {
    getProfileData: client_id => dispatch(getProfileData(client_id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyInfoForm);
