import React, { useEffect, useRef, useState } from "react";

// Redux
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";

import { sendInstructorSpecialityValues } from "../../../redux/actions/virtInstructorDataActions";

// Component Input
import { renderCheckboxField } from "../FormField/FormField.js";

let InsQualification = props => {
  return (
    <Grid item xs={12}>
      <Field
        name="CSIA"
        component={renderCheckboxField}
        type="checkbox"
        label="CSIA 2"
      />
      <Field
        name="APSI"
        component={renderCheckboxField}
        type="checkbox"
        label="APSI Level 5"
      />
    </Grid>
  );
};

// destroyOnUnmount - saves it in state
function mapDispatchToProps(dispatch) {
  return {
    sendSpecialtyValues: values =>
      dispatch(sendInstructorSpecialityValues(values))
  };
}

InsQualification = connect(mapDispatchToProps)(InsQualification);

export default reduxForm({
  destroyOnUnmount: false
})(InsQualification);
