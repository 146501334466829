import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { useHistory } from "react-router-dom";
import { getProviderProfile, setProviderServices } from "../../../redux/actions/stepperActions";
import { updateProviderProfile } from "../../../redux/actions/providerProfileAction";
import { getProfileData } from "../../../redux/actions/profileDataAction.js";

// Styles
import useStyles from "../../../components/Common/SignupForm/ClientInfoStyles";

// APIs
import agent from "../../../agent";

// Material UI
import Grid from "@material-ui/core/Grid";
import { Button, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

// Components
import Services from "../../../components/Common/SignupForm/ProviderInfo/Services/Services";
import Bio from "../../../components/serviceMarket/profile/form/BioForm";
import Nationality from "./NationalityDropdown";
import Languages from "./LanguageDropdown";
import AbilityLevelForm from "../../../components/serviceMarket/profile/form/AbilityForm";
import Countries from "./CountryDropdown";
import SuitableFor from "./SuitableForDropdown";
import ProfileAvatar from "./ProfileAvatar";
import ImageGalleryForm from "../../../components/serviceMarket/profile/ImageGalleryForm";


// constants for gallery image change
const REPLACE = "replace";
const REMOVE = "remove";
const UPLOAD = "upload";

const useClasses = makeStyles(theme => ({
  myProfileLabel: {
    width: "100%",
    fontSize: "x-large",
    width: "50%",
    margin: "3rem 0",
    fontFamily: "PT Sans Narrow",
    paddingLeft: "1rem"
  },
  headerLabel: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "26px",
    color: "#000000"
  },
  btnDisabled: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 16,
    marginTop: 8,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14
    },
    color: "#BDBDBD",
    border: "none",
    backgroundColor: "transparent",
    padding: 0
  },
  imageGalleryTitle: {
    width: "50%",
    marginTop: "3rem"
  },
  uploadBtn: {
    width: "50%",
    marginTop: "3rem",
    justifyContent: "end"
  },
  sectionMargin: {
    margin: "0.5rem 0",
    width: "100%"
  },
  btnEdit: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 16,
    marginTop: 8,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14
    },
    color: "#1277B8",
    border: "none",
    backgroundColor: "transparent",
    padding: 0
  },
  saveButton: {
    // marginTop: "3em",
    width: "102px",
    height: "2.5em",
    borderRadius: 100,
    textAlign: "center",
    backgroundColor: "rgb(255,97,97)",
    marginLeft: "1em"
  },
  cancelButtonLabel: {
    color: "#495057",
    fontWeight: 700,
    letterSpacing: "1%",
    fontFamily: "PT Sans"
  },
  buttonLabel: {
    color: "white",
    fontWeight: 700,
    letterSpacing: "1%",
    fontFamily: "PT Sans"
  },
  centerBtns: {
    margin: "2rem 0",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-around",
      alignItems: "center"
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
      alignItems: "center"
    }
  },
}));


function EditableProviderProfile(props) {
  const classes = useClasses();
  const history = useHistory();
  const styles = useStyles();

  const { userData, userProfile } = props;
  const [providerId, setProviderId] = useState(0);
  const [userId, setUserId] = useState(0);
  let [profileImage, setProfileImage] = useState({});
  let [bio, setBio] = useState("");
  let [nationality, setNationality] = useState({});
  let [languages, setLanguages] = useState([{}]);
  let [skiLevel, setSkiLevel] = useState(0);
  let [snowboardLevel, setSnowboardLevel] = useState(0);
  let [countryResorts, setCountryResorts] = useState([]);
  let [suitableFor, setSuitableFor] = useState([{}]);
  let [providedServices, setProvidedServices] = useState([{}]);
  let [gallery, setGallery] = useState([]);
  let [uploadImages, setUploadImages] = useState([]);
  let [updatedServices, setUpdatedServices] = useState({});
  let [isUploadPhotos, setUploadPhotos] = useState(false);
  const user_id = props.currentUser ? props.currentUser.user_id : null;


  // Get user id
  useEffect(() => {
    if(userData){
      setUserId(userData.data.user_id);
    }
  }, [userData]);

  // Get provider profile data
  useEffect(() => {
    if(userId){
      props.getProviderProfile(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (props.currentUser != undefined || props.currentUser != null) {
      console.log(props.currentUser);
    } else {
      props.history.replace("/login");
    }
  }, [ props.currentUser]);


  // Update UI
  useEffect(() => {  
    if (userProfile && userProfile !== undefined){
      setSkiLevel(userProfile.user.skiing_level);
      setSnowboardLevel(userProfile.user.snowboarding_level);
      setBio(userProfile.providerBio);
      setNationality(userProfile.user.country);
      setCountryResorts(userProfile.resorts);
      setSuitableFor(userProfile.suitableFors);
      setLanguages(userProfile.user.languages);
      setProfileImage(userProfile.profileImage);
      setProvidedServices(userProfile.providerServices);
      setProviderId(userProfile.id);
            // setGallery(userProfile.gallery);
      
            let imageArray = [];
            if (userProfile.gallery != undefined) {
              if (userProfile.gallery.length > 0) {
                Array.from(new Array(6)).map((_, i) => {
                  if (userProfile.gallery[i] != undefined) {
                    imageArray.push({
                      id: i,
                      image_id: userProfile.gallery[i].id,
                      image_link: userProfile.gallery[i].image_link,
                      image_file: ""
                    });
                  } else {
                    imageArray.push({
                      id: i,
                      image_id: 0,
                      image_link: "",
                      image_file: ""
                    });
                  }
                });
                setGallery(imageArray);
              }
      
              if (userProfile.gallery.length == 6) {
                setUploadPhotos(false);
              } else {
                setUploadPhotos(true);
              }
            }
    }
  }, [userProfile]);

  // upload profile image to S3 bucket
  const uploadProfileImage = async () => {
    const profileImageData = new FormData();
    if(typeof profileImage === "object"){
      profileImageData.append("profile_picture", profileImage);
      try {
        await agent.Users.uploadProfileImage(userId, profileImageData);
        console.log("profile picture successfully uploaded!");
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("profile image type is a string")
    }
  };

  // useEffect(() => {
  //   uploadProfileImage();
  // }, [profileImage]);

  // update the image files that are needed to upload
  const updateUploadImageFiles = (imageFiles) => {
    setUploadImages(imageFiles);
  };

  /**
   * This function is to append Files in JSON data into FormData.
   * 
   * @param {FormData} formData This is the formData needed to be appended data into.
   * @param {any} data This is the JSON object requiring appended into formData.
   * @param {any} parentKey This is optional for nested JSON object only.
   */
  function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        buildFormData(formData, data[key], key);
      });
    } else if (data && typeof data === 'object' && (data instanceof File)) {
      formData.append(parentKey, data);
    } else {
    }
  }
  
  /**
   * This function is to transform Files in JSON data to FormData.
   * 
   * @param {any} data This is the JSON object requiring appended into formData.
   */
  function jsonToFormData(data) {
    const formData = new FormData();
    buildFormData(formData, data);
    return formData;
  }

  console.log("SERVICES: ", updatedServices);

  const handleSubmit = async event => {
    event.preventDefault();
    // format submitting data
    console.log(userId, providerId);

    let languageIdArray = [];
    languages.forEach(language => languageIdArray.push(language.id));
    let resortIdArray = [];
    countryResorts.forEach(countryResort => resortIdArray.push(countryResort.id));
    let suitableIdArray = [];
    suitableFor.forEach(level => suitableIdArray.push(level.id));
    let user = {
      country: nationality.id,
      languages: languageIdArray,
      skiing_level: skiLevel,
      snowboarding_level: snowboardLevel
    }
    // ------ Update provider info -------
    const provider = {
      providerId: providerId,
      user: user,
      provider_bio: bio,
      resorts: resortIdArray,
      suitable_fors: suitableIdArray
    }

    console.log(provider)
    // ------ Update provider profile -------
    await props.updateProviderProfile(provider);
    // ------ Update gallery -------
    await manageGallery();
    // ------ Update profile image -------
    await uploadProfileImage();
    // ------ Update services -------
    // Find out FILEs in the JSON object and append it to create new FormData as payload
    let postServiceFile = jsonToFormData(updatedServices)
    // Append all again into the FormData: postServiceFile
    postServiceFile.append('data',JSON.stringify(updatedServices))

    await props.updateProviderServices(providerId, postServiceFile);

    props.getProfileData(user_id);
    await props.getProviderProfile(user_id);

    history.push("/pro/my-profile");
  };

  useEffect(() => {
    for (const obj of gallery) {
      if (obj.image_link === "") {
        setUploadPhotos(true);
      } else {
        setUploadPhotos(false);
      }
    }
  }, [gallery]);

    // Upload image gallery to AWS and update data in database
    const manageGallery = async () => {
      for (const obj of gallery) {
        if (obj.image_id == 0 && obj.image_file != "") {
          //Uploaded image
          const imageData = new FormData();
          imageData.append("image_link", obj.image_file);
          imageData.append("user", user_id);
          try {
            await agent.Users.uploadGalleryImage(imageData);
            console.log("Image successfully uploaded!");
          } catch (error) {
            console.error(error);
            const message =
              error.response && error.response.data.detail
                ? error.response.data.detail
                : error.detail;
          }
        } else if (obj.image_id != 0 && obj.image_link == "") {
          //Removed image
          try {
            await agent.Users.deleteGalleryImage(obj.image_id);
            console.log("Image successfully removed!");
          } catch (error) {
            console.error(error);
            const message =
              error.response && error.response.data.detail
                ? error.response.data.detail
                : error.detail;
          }
        } else if (obj.image_id != 0 && obj.image_file != "") {
          //Replace image
          const imageData = new FormData();
          imageData.append("image_link", obj.image_file);
          imageData.append("user", user_id);
          try {
            await agent.Users.updateGalleryImage(obj.image_id, imageData);
            console.log("Image successfully replaced!");
          } catch (error) {
            console.error(error);
            const message =
              error.response && error.response.data.detail
                ? error.response.data.detail
                : error.detail;
          }
        }
      }
  
      const response3 = await agent.Users.getGallery(user_id);
      const imageGallery = response3.data;
      let imageArray = [];
      if (imageGallery != undefined) {
        Array.from(new Array(6)).map((_, i) => {
          if (imageGallery[i] != undefined) {
            imageArray.push({
              id: i,
              image_id: imageGallery[i].id,
              image_link: imageGallery[i].image_link,
              image_file: ""
            });
          } else {
            imageArray.push({
              id: i,
              image_id: 0,
              image_link: "",
              image_file: ""
            });
          }
        });
        setGallery(imageArray);
  
        if (userProfile.gallery.length == 6) {
          setUploadPhotos(false);
        } else {
          setUploadPhotos(true);
        }
      }
    };
    // Popover: Upload a new gallery image
    const handleBrowseImage = event => {
      event.preventDefault();
  
      let uploaded = URL.createObjectURL(event.target.files[0]);
      let uploadedId = 0;
      let newArr = [];
      if (gallery.length == 0) {
        Array.from(new Array(6)).map((_, i) => {
          if (i == 0) {
            newArr.push({
              id: i,
              image_id: 0,
              image_link: uploaded,
              image_file: event.target.files[0]
            });
          } else {
            newArr.push({
              id: i,
              image_id: 0,
              image_link: "",
              image_file: ""
            });
          }
        });
      } else {
        for (const obj of gallery) {
          if (obj.image_link === "") {
            uploadedId = obj.id;
  
            break;
          }
        }
        newArr = gallery.map(obj => {
          if (obj.id === uploadedId) {
            return {
              ...obj,
              image_link: uploaded,
              image_file: event.target.files[0]
            };
          }
          return obj;
        });
      }
      setGallery(newArr);
  
      // setEditing(true);
    };

  return (
    <form>
      <Grid 
        container
        justify="center"
        alignItems="center"
        spacing={0}
        direction="column" 
        style={{ marginTop: "3rem"}}
      >
        <Grid 
          container
          item
          xs={12}
          sm={10}
          md={8}
          lg={6}
          direction="row"
          justifyContent="flex-start"
          alignItems="baseline"
        >
          <div className={classes.myProfileLabel}>My Profile</div>
        </Grid>

        {/* Avatar and Bio section */}
        <Grid 
          container
          item
          xs={12}
          sm={10}
          md={8}
          lg={6}
          spacing={2}
          direction="row"
          justifyContent="space-evenly"
          alignItems="flex-start"
        >
          {/* Avatar section */}
          <ProfileAvatar 
            setProfileImage={setProfileImage} 
            profileImage={profileImage}
            isEditable={true}
          />
          {/* BIO section */}
          <Bio providerOnlyStyle={{marginLeft: "2.5%", width: "95%"}} setBio={setBio} bio={bio} isDisabled={false}/>
        </Grid>

        {/* Nationality and Language */}
        <Grid
          item
          container
          xs={12}
          sm={10}
          md={8}
          lg={6}
          spacing={2}
          justify="center"
          alignItems="flex-start"
          className={classes.sectionMargin}
        >
          <Grid item xs={12} sm={6}>
            <Nationality 
              setNationality={setNationality}
              nationality={nationality}
              isDisabled={false}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Languages
              setLanguages={setLanguages}
              languages={languages}
              isDisabled={false}
            />
          </Grid>
        </Grid>

        {/* Ability level and Suitable for selections */}
        <Grid
          item
          container
          xs={12}
          sm={10}
          md={8}
          lg={6}
          spacing={2}
          justify="center"
          alignItems="flex-start"
          direction="row"
          className={classes.sectionMargin}
        >
          <Grid item xs={12} sm={6}>
            <AbilityLevelForm
              skiLevel = {skiLevel}
              snowboardLevel={snowboardLevel}
              setSkiLevel={setSkiLevel}
              setSnowboardLevel={setSnowboardLevel}
              isDisabled={false}
              providerOnlyStyle={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SuitableFor 
              setSuitableFor={setSuitableFor}
              selectedLevels={suitableFor}
              isDisabled={false}
              isEditable={true}
            />
          </Grid>
        </Grid>

        {/* Countries and  Resorts selections */}
        <Grid
          item
          container
          xs={12}
          sm={10}
          md={8}
          lg={6}
          spacing={2}
          justify="center"
          alignItems="flex-start"
          direction="row"
          className={classes.sectionMargin}
        >
          <Grid item xs={12} sm={12}>
            <Countries 
              setCountryResorts={setCountryResorts}
              countryResorts={countryResorts}
              isDisabled={false}
            />
          </Grid>
          
        </Grid>
        
        {/* Offered Services and Pricing section */}
        <Grid 
          container 
          item
          xs={12}
          sm={10}
          md={8}
          lg={6}
          spacing={2}
          justify="center"
          alignItems="flex-start"
          direction="column"
        >
          <div className={classes.sectionMargin} >
            <hr />
          </div>
          <div className={classes.sectionMargin} >
            <Services 
              fromHostIntroSection={true}
              providedServices={providedServices}
              setProvidedServices={setProvidedServices}
              setUpdatedServices={setUpdatedServices}
              isDisabled={false}
              providerId={providerId}
            />
          </div> 
        </Grid>

              {/* Image Gallery Header & Upload photos button */}
              <Grid
                container
                justify="center"
                alignItems="center"
                xs={12}
                sm={10}
                md={8}
                lg={6}
                spacing={2}
                direction="row"
                style={{ padding: "2rem 0" }}
              >
                <Grid item xs={8} sm={9}>
                  <Typography className={classes.headerLabel}>
                    Image Gallery
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  xs={4}
                  sm={3}
                >
                  {
                    isUploadPhotos ? (
                      <label>
                        <input
                          accept="image/*"
                          className={styles.input}
                          id="contained-button-file"
                          multiple
                          hidden
                          type="file"
                          onChange={handleBrowseImage}
                        />
                        <Button
                          className={classes.btnEdit}
                          variant="text"
                          component="span"
                        >
                          Upload photos
                        </Button>
                      </label>
                    ) : (
                      <Button
                        className={classes.btnDisabled}
                        variant="text"
                        component="span"
                      >
                        Upload photos
                      </Button>
                    )
                  }
                </Grid>
              </Grid>
              {/* Image Gallery List */}
              <Grid
                container
                justify="center"
                alignItems="flex-start"
                xs={12}
                sm={10}
                md={8}
                lg={6}
                spacing={2}
                direction="row"
                style={{ padding: "2rem 0px" }}
              >
                <ImageGalleryForm
                  item_id={0}
                  gallery={gallery}
                  setGallery={setGallery}
                  isDisabled={false}
                />
                <ImageGalleryForm
                  item_id={1}
                  gallery={gallery}
                  setGallery={setGallery}
                  isDisabled={false}
                />
                <ImageGalleryForm
                  item_id={2}
                  gallery={gallery}
                  setGallery={setGallery}
                  isDisabled={false}
                />
                <ImageGalleryForm
                  item_id={3}
                  gallery={gallery}
                  setGallery={setGallery}
                  isDisabled={false}
                />
                <ImageGalleryForm
                  item_id={4}
                  gallery={gallery}
                  setGallery={setGallery}
                  isDisabled={false}
                />
                <ImageGalleryForm
                  item_id={5}
                  gallery={gallery}
                  setGallery={setGallery}
                  isDisabled={false}
                />
              </Grid>

        {/* Cancel and Save Button */}
        <Grid
         container
         item
         xs={12}
         sm={10}
         md={8}
         lg={6}
         spacing={2}
         direction="row"
         justifyContent="flex-end"
         alignItems="stretch"
         className={classes.centerBtns}
        >

          <Grid item >
            <Button 
              style={{color: "rgb(255,97,97)"}}
              type="button"
              onClick={() => history.push("/pro/my-profile")}
            >
              Cancel
            </Button>  
          </Grid>

          <Grid item>
            <Button
              type="submit"
              onClick={handleSubmit}
              className={classes.saveButton}
              disabled={props.submitting}
              classes={{
                label: classes.buttonLabel,
              }}
              variant="contained"
            >
              Save
            </Button>
          </Grid>
        </Grid>

      </Grid>
    </form>
  );
}

// upload gallery images to S3 bucket
const handleGalleryChange = async (images, user_id) => {
  images.forEach(image => {
    const imageData = new FormData();
    imageData.append("image_link", image.image_file);
    imageData.append("user", user_id);

    if(image.image_status === UPLOAD) {
      // upload new image to DB
      uploadGalleryImage(imageData);
    } 
    else if(image.image_status === REPLACE) {
      // replace old image from DB
      updateGalleryImage(image.image_id, imageData);
      
    } else if(image.image_status === REMOVE) {
      // remove old image from DB
      deleteGalleryImage(image.image_id);
    }
  });
};

const uploadGalleryImage = async (imageData) => {
  try {
    await agent.Users.uploadGalleryImage(imageData);
    // console.log("Image successfully uploaded!");
  } catch (error) {
    console.log(error);
  }
}

const updateGalleryImage = async (imageId, imageData) => {
  try {
    await agent.Users.updateGalleryImage(imageId, imageData);
    // console.log("Image successfully updated!");
  } catch (error) {
    console.log(error);
  }
}

const deleteGalleryImage = async (imageId) => {
  try {
    await agent.Users.deleteGalleryImage(imageId);
    // console.log("Image successfully removed!");
  } catch (error) {
    console.log(error);
  }
}

// get current user data from store
function mapStateToProps(state) {
  return {
  userData: state.loginReducer.userData,
  currentUser: state.auth.currentUser,
  userProfile: state.stepperReducer.userProfile,
  };
}

// event or function are used to update existing values in Store
const mapDispatchToProps = dispatch => ({
  getProviderProfile: userId => dispatch(getProviderProfile(userId)),
  updateProviderProfile: provider => dispatch(updateProviderProfile(provider)),
  updateProviderServices: (provider_id, services) => dispatch(setProviderServices(provider_id, services)),
  getProfileData: client_id => dispatch(getProfileData(client_id))
});

EditableProviderProfile = reduxForm({
    form: "editProProfile",
    destroyOnUnmount: false
})(EditableProviderProfile);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditableProviderProfile);