export const GROUP = "group";
export const PRIVATE = "private";
export const FIRST_TIMER_GROUP = "First Timer Group";
export const FIRST_TIMER_PRIVATE = "First Timer Private";
export const PRIVATE_ADAPTIVE = "Private Adaptive";

export const lessonObjs = [
  {
    id: 0,
    lessonHeading: "Private Adaptive",
    lessonBackgroundColor: "#D4EBDA"
  },
  {
    id: 1,
    lessonHeading: "First Timer",
    lessonBackgroundColor: "#F8D7DA"
  },
  {
    id: 2,
    lessonHeading: "Experienced",
    lessonBackgroundColor: "#CDD2FE"
  }
];
