import React from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
// import styles from "../../css/Homepage/instructorCard.module.css";
import InstructorCard from "./InstructorCard";

const InstructorCards = ({ instructorData }) => {
  return (
    <div>
      <Grid container>
        <Grid container item spacing={4} justify="space-around">
          {instructorData.map(data => (
            <Grid container item sm={6} xs={12} key={data.id}>
              <InstructorCard {...data} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

function mapStateToPropsSelect(state) {
  return {
    instructorData: state.instructorFakeReducer.data.fakeData
  };
}

export default connect(mapStateToPropsSelect)(InstructorCards);
