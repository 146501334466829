import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Grid, Slider } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { renderField } from "../FormField/FormField";

const useStyles = makeStyles(theme => ({
  root: {
    background: "#FFFFFF"
  },
  checkIcon: {
    color: "#00FF00"
  },
  textField: {
    height: "5px",
    width: "90%",
    fontSize: "5",
    size: "small",
    padding: "0em",
    border: "5px",
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    borderRadius: "0px",
    marginBottom: "5px",
    textAlign: "left"
  },
  textFieldStyle: {
    fontFamily: "PT Sans Narrow",
    fontWeight: "bold",
    color: "#1277B8",
    fontSize: 16,
    lineHeight: "140.62%",
    [theme.breakpoints.down("xs")]: {
      fontSize: 13
    }
  }
}));

function validate(values) {
  let errors = {};

  if (!values.newpwd) {
    errors.newpwd = "Required";
  }

  if (values.newpwd !== values.confirmpwd) {
    errors.confirmpwd = "Passwords don't match";
  }

  return errors;
}

let EditPassword = props => {
  const classes = useStyles();
  const [code, setCode] = React.useState("Code");
  const [fullWidth] = React.useState(true);

  const [newpwd, setNewpwd] = React.useState();
  const [confirmpwd, setConfirmpwd] = React.useState();
  const [pwderror, setPwderror] = React.useState(false);

  const handleChange = event => {
    setCode(event.target.value);
  };

  const handleChangePwd = event => {
    if (!newpwd || newpwd != confirmpwd) {
      setPwderror(true);
    } else {
      setPwderror(false);
    }
  };

  return (
    <div>
      <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item md={5} xs={4}>
          <div align="right" className={classes.textFieldStyle}>
            Current Password:
          </div>
        </Grid>
        <Grid item md={6} xs={6}>
          <div>
            <Field
              type="password"
              fullWidth
              id="currpwd"
              variant="filled"
              className={classes.textField}
              size="small"
              component={renderField}
            />
          </div>
        </Grid>
        <Grid item md={1} xs={2} />
      </Grid>
      <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item md={5} xs={4}>
          <div align="right" className={classes.textFieldStyle}>
            New Password:
          </div>
        </Grid>
        <Grid item md={6} xs={6}>
          <div>
            <Field
              type="password"
              fullWidth
              name="newpwd"
              variant="filled"
              className={classes.textField}
              size="small"
              component={renderField}
              // value={newpwd}
              // onChange={handleChangePwd}
            />
          </div>
        </Grid>
        <Grid item md={1} xs={2}>
          <CheckIcon className={classes.checkIcon} />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item md={5} xs={4}>
          <div align="right" className={classes.textFieldStyle}>
            Confrim New Password:
          </div>
        </Grid>
        <Grid item md={6} xs={6}>
          <div>
            <Field
              type="password"
              fullWidth
              name="confirmpwd"
              variant="filled"
              size="small"
              component={renderField}
              className={classes.textField}
              // value={confirmpwd}
              // onChange={handleChangePwd}
            />
          </div>
        </Grid>
        <Grid item md={1} xs={2}>
          <CheckIcon className={classes.checkIcon} />
        </Grid>
      </Grid>
    </div>
  );
};

EditPassword = reduxForm({
  form: "editpassword",
  destroyOnUnmount: false,
  validate
})(EditPassword);

export default EditPassword;
