import React from "react";

import useStyles from "./MyAccountStyles";
import { Grid, Dialog } from "@material-ui/core";

import EditPassword from "./EditPassword";

const Password = ({
  studentData,
  _editPassword,
  handleClickOpenPassword,
  handleClose,
  open
}) => {
  const classes = useStyles();

  return (
    <Grid item container md={12} direction="row">
      <Grid item lg={2} md={2} sm={2} xs={2}>
        <div align="right" className={classes.textFieldStyle}>
          Password:
        </div>
      </Grid>
      <Grid item lg={1} md={1} sm={1} xs={1} />
      <Grid item lg={6} md={6} sm={7} xs={9}>
        <div align="left">
          <input
            type="password"
            fullWidth
            id="cardname"
            variant="filled"
            className={classes.textField}
            size="small"
            onClick={handleClickOpenPassword}
            onChange={event => _editPassword(event.target.value)}
            value={studentData.password}
          />
        </div>
        <Dialog
          fullWidth="true"
          open={open}
          onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <div className={classes.passwordDialog}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <EditPassword />
            </Grid>
            <Grid item lg={10} md={10} sm={10} xs={10} align="right">
              <button onClick={handleClose} className={classes.btnUpdate}>
                Update
              </button>
            </Grid>
          </div>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default Password;
