import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Switch, Route, withRouter } from "react-router-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import "@fortawesome/fontawesome-free/css/all.css";

import agent from "./agent";
import { loadUser } from "./redux/reducers/auth";
import { ACCESS_TOKEN } from "./constants/localStorageKeys"; ////NOT-USED
import ContentFilter from "./components/exploreHosts/home/ContentFilter"; ////NOT-USED
//#region Components Imports

// NavBar
import NavBar from "./components/Common/Navbar/NavBar";

// Footer
import FooterBar from "./components/Common/Footer/FooterBar";
import Footer from "./components/Common/Footer/Footer";

//#region Chatbox - These have URL's, I assume for testing purposes
import Chatbox from "./components/Common/Chatbox/Chatbox"; // "/Chatbox"
import MessengerPage from "./components/Common/Chatbox/Messenger.js"; // "/message/:id"
import InboxPage from "./components/Common/Chatbox/Inbox.js"; // "/inbox"
import ChatPage from "./components/Common/Chatbox/ChatPage"; // "/chatpage/:id"
import VerifyEmail from "./components/Common/Chatbox/VerifyEmail"; // "/VerifyEmail"
import PaymentEmail from "./components/Common/Chatbox/PaymentEmail"; // "/PaymentEmail"
import PasswordChange from "./components/Common/Chatbox/PasswordChange"; //"/PasswordChange"
//#endregion Chatbox

// Misc. Looks like testing
// import Host from "./components/Host"; // NOT-USED/LINKED/EXISTENT
import HostCard from "./components/exploreHosts/HostCard/HostCard.js"; // "/cardTest"
import SearchCard from "./components/Common/Home/SearchCard"; // "/search-card"
import AccountPage from "./components/Common/Dashboard/AccountPage"; // "/accountPage"

//#endregion Component Imports

//#region Page Imports

//#region Common

// https://resorter.atlassian.net/browse/VLHMVP-1001

// Home
import MasterHomePage from "./pages/Common/home/MasterHomePage"; // "/master/explore"

// My Account
import MyAccount from "./pages/Common/profile/MyAccount"; // "/host/host/my-account", "/host/vis/my-account", "/vl/stu/my-account", "/vl/ins/my-account"
import ClientProfile from "./pages/serviceMarket/profile/ClientProfile"; // "/client-profile"
import ProviderProfile from "./pages/serviceMarket/profile/ProviderProfile";
import EditableProviderProfile from "./pages/serviceMarket/profile/EditableProviderProfile";
import Profile from "./pages/serviceMarket/profile/Profile";

import MyAccountView from "./pages/serviceMarket/profile/MyAccountView";
import MyAccountEdit from "./pages/serviceMarket/profile/MyAccountEdit";
// Login
import Login from "./pages/Common/Login"; // "/login"

// Sign Up
import SignupPage from "./pages/Common/signup/SignupPage.js"; // "/host/sign-up", "/vl/sign-up"

//#region Payment
import PaymentandPayout from "./pages/Common/payment/PaymentandPayout.js"; // "/PaymentandPayout"
import PaymentTab from "./pages/Common/payment/PaymentTab.js"; // NOT-USED/LINKED
import Payouttab from "./pages/Common/payment/Payouttab.js"; // "/PayoutTab"
import Payment from "./pages/Common/payment/Payment"; // "/vl/Payment"
import PayoutAddress from "./pages/Common/payment/PayoutAddress.js"; // "/PayoutAddress"
import PayoutSuccess from "./pages/Common/payment/PayoutSuccess.js"; // "/PayoutSuccess"
//#endregion Payment

// TESTING SIGN-UP.
import Services from "./components/Common/SignupForm/ProviderInfo/Services/Services"; // "/services"
import ClientInfo from "./components/Common/SignupForm/ClientInfo"; // "/clientInfo"
import ProviderInfo from "./components/Common/SignupForm/ProviderInfo"; // "/providerInfo"

//#endregion Common

//#region Explore Hosts

// https://resorter.atlassian.net/browse/VLHMVP-1003

//Home
import Home from "./pages/serviceMarket/home/Home"; // "/"
import ExploreHosts from "./pages/exploreHosts/home/ExploreHosts"; // "/host/explore"
import LandingPage from "./pages/landingPage/home/LandingPage"; //"/landing-page"

//Profile - Host
// import HostProfile from "./pages/exploreHosts/index";
import HostProfile from "./pages/exploreHosts/profile/HostProfile.js"; //"/host/vis/host-profile"
import HostProfileVisitorView from "./pages/exploreHosts/profile/HostProfileVisitorView.js"; // NOT-USED/LINKED
import VisitorProfile from "./pages/exploreHosts/profile/VisitorProfile.js"; // "/host/host/vis-profile"

//#endregion Explore Hosts

//#region Virtual Lessons

// https://resorter.atlassian.net/browse/VLHMVP-1002

//Home
import ExploreInstructors from "./pages/virtualLessons/home/ExploreInstructors"; // "/vl/explore"

//Video Lessons
import VideoUpload from "./pages/virtualLessons/videoLessons/VideoUpload"; // "/VideoUpload"
import CancelBooking from "./pages/virtualLessons/videoLessons/CancelBooking"; // "/cancel-booking"
import VideoLesson from "./pages/virtualLessons/videoLessons/VideoLesson.js"; // "/lesson"
import Lesson from "./pages/virtualLessons/videoLessons/VideoLesson.js"; // "/VideoLesson"

//Profiles
import myProfile from "./pages/virtualLessons/profile/myProfile"; // "/my-profile/:virtInstructorId"

//Profiles - Instructor
import InstructorNewProfile from "./pages/virtualLessons/profile/InstructorNewProfile.js"; // "/vl/ins/my-profile"
import InstructorProfile from "./pages/virtualLessons/profile/InstructProfile"; // "/instructor-profile/:virtInstructorId"
import InstructorMyProfile from "./pages/virtualLessons/profile/InstructorMyProfile.js"; // NOT-LINKED
import InstructorIntroSection from "./pages/virtualLessons/profile/InstructorIntroSection.js"; // NOT-LINKED

//Profiles - Student
import StudentProfile from "./pages/virtualLessons/profile/StudentProfile.js"; // "/vl/ins/stu-profile", "/vl/stu/my-account"
import StudentMyProfile from "./pages/virtualLessons/profile/StudentMyProfile.js"; // NOT-LINKED
import StudentIntroSection from "./pages/virtualLessons/profile/StudentIntroSection.js"; // NOT-LINKED

//'Redundant' // Came from a folder called 'Instructor-redundant'
import InstructorPage from "./pages/virtualLessons/Instructors-redundant/InstructorPage"; // "/instructors"
import ZoomAuthorize from "./pages/virtualLessons/Instructors-redundant/ZoomAuthorize"; // "/ZoomAuthorize"

//#endregion Virtual Lessons

//#region Lessons Enquiry (Live Site) / exploreLiveInstructors

// https://resorter.atlassian.net/browse/VLHMVP-1004

//Home
import LessonsEnquiryHomePage from "./pages/lessonsEnquiry/home/LessonsEnquiryHomePage"; // "/"

import Register from "./pages/lessonsEnquiry/Register"; // "/register"
// import Register from "./pages/Common/Register";
import HowItWorkPage from "./pages/lessonsEnquiry/HowItWorksPage"; // "/how-it-works", "/about-us"
import ContactPage from "./pages/serviceMarket/ContactPage"; // "/contact "
import FormContainer from "./pages/lessonsEnquiry/FormContainer"; // "/lessons-enquiry"
import BookSuccessPage from "./pages/lessonsEnquiry/BookSuccessPage"; // "/enquiry-success" (OC: // TODO: remove later )
import EmailStudent from "./components/Common/EmailStudent"; // "/EmailStudent"
import Review from "./pages/lessonsEnquiry/Review"; // "/Review"
import TermPrivacy from "./pages/Common/TermPrivacy"; // "/term-privacy"
import SearchCardHorizontal from "./components/Common/Home/SearchCardHorizontal";
import SignUpConfirmation from "./components/Common/SignUpConfirmation";
import ActivateUser from "./components/Common/SignupForm/ActivateUser";
import ProviderView from "./components/serviceMarket/profile/ProviderView";
import ClientView from "./components/serviceMarket/profile/ClientView";
import MyInfoView from "./components/serviceMarket/profile/MyInfoView";

import ActivateUserGoogle from "./components/Common/SignupForm/ActivateUserGoogle";
import OrderForm from "./components/serviceMarket/profile/form/OrderForm";
import PasswordReset from "./components/Common/Passwordreset/PasswordReset";
import CreateNewPassword from "./components/Common/Passwordreset/CreateNewPassword";
import EditAccount from "./pages/Common/EditAccount/EditAccount";

//#endregion Lessons Enquiry

const routedHowItWork = withRouter(props => <HowItWorkPage {...props} />);

//#endregion Page Imports

export default function App(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    //get token from session using agent
    const token = agent.Users.login()
      .then(response => {
        //use the token to restore session
        const accessToken = response.data.tokens.token;
        if (accessToken != null) {
          agent.setToken(accessToken);
          agent.Users.currentUser()
            .then(response => {
              dispatch(loadUser(response.data));
            })
            .catch(error => console.log(error));
        }
      })
      .catch(e => console.log(e));
  });

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <nav>
        <NavBar />
      </nav>
      <main style={{ flex: 1 }}>
        <Switch>
          {/*  
          // ----------------------------- Information --------------------------------
          // |         The URL's should follow this naming convention:'/<page-name>'  |
          // |                       For example: /my-account                         |
          // |             (All must use lowercase letters and hyphens.)              |
          // --------------------------------------------------------------------------        
          */}

          {/* --- HOMEPAGE --- */}
          <Route exact path="/" component={Home} transparentNav />

          {/* --- SIGNUP --- */}
          <Route
            path="/sign-up"
            component={props => <SignupPage {...props} />}
          />
          <Route
            path="/activate-user/:token/:id"
            component={props => <ActivateUser {...props} />}
          />
          <Route
            path="/activate-user"
            component={props => <ActivateUserGoogle {...props} />}
          />

          <Route
            path="/register"
            component={props => <Register {...props} />}
          />

          {/* --- LOGIN --- */}
          <Route path="/login" component={props => <Login {...props} />} />

          {/* PASSWORD RESET */}
          <Route path="/password-reset" component={PasswordReset} />

          {/* CREATE NEW PASSWORD */}
          <Route
            path="/create-new-password/:id/:token"
            component={CreateNewPassword}
          />

          <Route
            path="/my-account"
            component={props => <EditAccount {...props} />}
          />

          {/* --- PROFILE PAGES --- */}
          <Route
            path="/client-profile"
            component={props => <ClientProfile {...props} />}
          />
          <Route
            path="/pro/my-profile"
            component={props => <ProviderProfile {...props} />}
          />
          <Route
            path="/pro/edit-my-profile"
            component={props => <EditableProviderProfile {...props} />}
          />

          {/* --- Profile from other's perspective --- */}
          <Route
            path="/profile/:user_id"
            component={props => <ProviderView {...props} />}
          />
          <Route
            path="/profile-client/:user_id"
            component={props => <ClientView {...props} />}
          />

          {/* --- BOOKING --- */}
          {/* TODO: orderform requires further development */}
          {/* <Route
            path="/orderform/:provider_id"
            component={props => <OrderForm {...props} />}
          /> */}

          <Route
            path="/contact"
            component={props => <ContactPage {...props} />}
          />

          {/* <Route
            path="/how-it-works"
            component={routedHowItWork}
            key="how-it-works"
          /> */}

          {/* <Route path="/about-us" key="about-us" component={routedHowItWork} /> */}

          {/*          
          // ----------------------------- Warning --------------------------------
          // |         All routes and paths below are from previous builds        |
          // |                         FOR REFERENCE ONLY                         |
          // ----------------------------------------------------------------------

          The URL's should follow this naming convention:

          '/<build-name>/<journey-name>/<page-name>'

          For example: /vl/stu/my-account
          (All must use lowercase letters and hyphens.)
          */}

          {/* --- ROUTES without Auth--- */}
          {/* Landing */}
          {/* <Route path="/landing-page" component={LandingPage} transparentNav /> */}

          {/* <Route path="/explore" component={ExploreHosts} transparentNav /> */}

          {/* LOGIN */}
          <Route path="/login" component={props => <Login {...props} />} />
          {/* --- ROUTES with Auth--- */}

          {/* <Route
            path="/confirmation"
            component={SignUpConfirmation}
            transparentNav
          /> */}
          {/* <-------------------------------------------------------Old Code------------------------------------> */}
          {/* - TESTS? - */}
          {/* <Route path="/search-card" component={SearchCardHorizontal} />  */}
          {/* - TESTS? - */}
          {/* - COMMON - */}
          {/* HOME */}
          {/* <Route
            exact
            path="/master/explore"
            component={MasterHomePage}
            transparentNav
          /> */}
          {/* FOOTER */}
          {/* <Route path="/footer" component={FooterBar} /> */}
          {/* SIGN UP */}
          {/* Services Build */}
          {/* <Route
            path="/sign-up"
            component={props => <SignupPage {...props} isHost={true} />}
          /> */}
          {/* Host Build */}
          {/* <Route
            path="/host/sign-up"
            component={props => <SignupPage {...props} isHost={true} />}
          /> */}
          {/* Virtual Lessons */}
          {/* <Route
            path="/vl/sign-up"
            component={props => <SignupPage {...props} isHost={false} />}
          /> */}
          {/* LOGIN */}

          {/* ACCOUNT */}
          {/* <Route path="/accountPage" component={AccountPage} /> */}
          {/* PAYMENT */}
          {/* <Route path="/PaymentandPayout" component={PaymentandPayout} />{" "} */}
          {/* NW */}
          {/* <Route path="/PaymentTab" component={PaymentTab} /> */}
          {/* <Route path="/PayoutTab" component={Payouttab} /> */}
          {/* <Route path="/PayoutAddress" component={PayoutAddress} /> */}
          {/* <Route path="/PayoutSuccess" component={PayoutSuccess} /> */}
          {/* CHATBOX */}
          {/* <Route path="/Chatbox" component={Chatbox} />
          <Route path="/message/:id" component={MessengerPage} />
          <Route path="/inbox" component={InboxPage} />
          <Route path="/chatpage/:id" component={ChatPage} />
          <Route path="/VerifyEmail" component={VerifyEmail} />
          <Route path="/PaymentEmail" component={PaymentEmail} />
          <Route path="/PasswordChange" component={PasswordChange} /> */}
          {/* MISC */}
          {/* <Route path="/services" component={Services} />
          <Route path="/clientInfo" component={ClientInfo} />
          <Route path="/providerInfo" component={ProviderInfo} />
          <Route path="/term-privacy" component={TermPrivacy} />
          <Route path="/Review" component={Review} />
          <Route path="/EmailStudent" component={EmailStudent} /> */}
          {/* - COMMON - */}
          {/* - LESSONS ENQUIRY (LIVE SITE) - */}
          {/* Previously commented Route */}
          {/* <Route
            path="/register"
            component={props => <Register {...props} />}
          /> */}

          {/* <Route
            path="/lessons-enquiry"
            component={props => <FormContainer {...props} />}
          />
          <Route
            path="/how-it-works"
            component={routedHowItWork}
            key="how-it-works"
          />
          <Route path="/about-us" key="about-us" component={routedHowItWork} />
          <Route
            path="/contact"
            component={props => <ContactPage {...props} />}
          />
          <Route
            path="/enquiry-success"
            component={props => <BookSuccessPage {...props} />}
          /> */}
          {/* - LESSONS ENQUIRY (LIVE SITE) - */}
          {/* - EXPLORE HOSTS - */}
          {/* Sign Up */}
          {/* <Route
            path="/host/sign-up"
            component={props => <SignupPage {...props} isHost={true} />}
          /> */}
          {/* Payment */}
          {/* <Route path="/host/Payment" component={Payment} /> */}
          {/* HOST VIEW */}
          {/* <Route
            path="/host/host/my-profile"
            component={props => <HostProfile {...props} isHost={true} />}
          />
          <Route
            path="/host/host/my-account"
            component={props => <MyAccount {...props} journey={"h-host"} />}
          />
          <Route
            path="/host/host/vis-profile"
            component={props => <VisitorProfile {...props} isHost={true} />}
          /> */}
          {/* VISITOR VIEW */}
          {/* <Route
            path="/host/vis/my-profile"
            component={props => <VisitorProfile {...props} isHost={false} />}
          />
          <Route
            path="/host/vis/my-account"
            component={props => <MyAccount {...props} journey={"h-vis"} />}
          />
          <Route
            path="/host/vis/host-profile"
            component={props => <HostProfile {...props} isHost={false} />}
          /> */}
          {/* TESTING */}
          {/* <Route path="/cardTest" component={HostCard} /> */}
          {/* - EXPLORE HOSTS - */}
          {/* - VIRTUAL LESSONS - */}
          {/* Sign Up */}
          {/* <Route
            path="/vl/sign-up"
            component={props => <SignupPage {...props} isHost={false} />}
          /> */}
          {/* Explore */}
          {/* <Route
            path="/vl/explore"
            component={ExploreInstructors}
            transparentNav
          /> */}
          {/* Payment */}
          {/* <Route path="/vl/Payment" component={Payment} /> */}
          {/* Previously commented Route */}
          {/* <Route path="/vl/stu/confirm-booking" component={props => <Payment {...props} />} /> */}
          {/*  VIDEO LESSONS */}
          {/* <Route path="/VideoLesson" component={VideoLesson} />
          <Route path="/Lesson" component={Lesson} />
          <Route path="/VideoUpload" component={VideoUpload} />
          <Route path="/cancel-booking" component={CancelBooking} /> */}
          {/* PROFILES */}
          {/* <Route
            path="/my-profile/:virtInstructorId"
            component={myProfile}
          />{" "} */}
          {/* doesn't read id / also duplicate url */}
          {/* PROFILES - INSTRUCTOR VIEW */}
          {/* <Route
            path="/vl/ins/my-account"
            component={props => <MyAccount {...props} journey={"vl-ins"} />}
          />
          <Route
            path="/vl/ins/my-profile"
            component={props => (
              <InstructorNewProfile {...props} isStudent={false} />
            )}
          />
          <Route
          /> */}
          {/* <Route
            path="/pro/edit-my-profile"
            component={props => (
              <EditableProviderProfile {...props} />
              )}
          /> */}

          {/* <Route
            path="/instructor-profile/:virtInstructorId"
            component={InstructorProfile}
          />{" "} */}
          {/* duplicate url */}
          {/* <Route
            path="/vl/ins/stu-profile"
            component={props => <StudentProfile {...props} isStudent={false} />}
          /> */}
          {/* PROFILES - STUDENT VIEW */}
          {/* <Route
            path="/vl/stu/my-account"
            component={props => <MyAccount {...props} journey={"vl-stu"} />}
          />
          <Route
            path="/vl/stu/my-profile"
            component={props => <StudentProfile {...props} isStudent={true} />}
          />
          <Route
            path="/vl/stu/ins-profile"
            component={props => (
              <InstructorNewProfile {...props} isStudent={true} />
            )}
          /> */}
          {/* 'Redundant' */}
          {/* <Route path="/instructors" component={InstructorPage} />
          <Route path="/ZoomAuthorize" component={ZoomAuthorize} /> */}
          {/* - VIRTUAL LESSONS - */}
        </Switch>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}
