import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import SearchCard from "./SearchCard";
import SearchCardForExploreInstructors from "../../virtualLessons/home/SearchCardForExploreInstructors";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import SearchCardHorizontal from "./SearchCardHorizontal";

const useStyles = makeStyles(theme => ({
  container: {
    height: "80%",
    display: "none"
  },
  wrapper: {
    width: "100%",
    // height: "100vh",
    // dark overlay trick
    background:
      "linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2)) , var(--image-url) center center",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  contentContainer: {
    alignItems: "center"
  },
  sloganContainer: {
    justifyContent: "center",
    padding: "5%"
  },
  slogan: {
    color: "#ffffff",
    textShadow: "7px 7px 20px rgba(0, 0, 0, 0.25)",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "40px",
    lineHeight: "178.91%",
    textAlign: "right",
    letterSpacing: "0.02em",
    fontFamily: "PT Sans"
  },
  underSlogan: {
    color: "#FFFFFF",
    fontSize: "24px",
    fontStyle: "normal",
    fontFamily: "PT Sans Narrow",
    textAlign: "right",
    width: "100%",
    letterSpacing: "0.01em",
    lineHeight: "45px"
  },
  sloganInstructor: {
    color: "#ffffff",
    textShadow: "7px 7px 20px rgba(0, 0, 0, 0.25)",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "40px",
    lineHeight: "128.91%",
    textAlign: "center",
    letterSpacing: "0.02em",
    fontFamily: "PT Sans Narrow",
    minWidth: 383,
    maxWidth: 383,
    display: "flex",
    alignItems: "center"
  },
  // tabletWrapper: {
  //     width: "100%",
  //     border: '1px solid orange',
  //     background:
  //       "linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2)) , var(--image-url) center center",
  //     backgroundPosition: "center center",
  //     backgroundRepeat: "no-repeat",
  //     backgroundSize: "cover",
  //     display: "flex",
  //     justifyContent: "center",
  //     alignItems: "center"
  // },
  [theme.breakpoints.down("sm")]: {
    tabletWrapper: {
      height: "fit-content",
      // paddingTop: "1em",
      paddingBottom: "3em",
      marginTop: "4rem"
      // border: '1px solid green',
    },
    container: {
      display: "block"
    },
    wrapper: {
      height: "fit-content",
      paddingTop: "2em",
      paddingBottom: "3em",
      marginTop: "8rem"
    },
    contentContainer: {
      flexDirection: "column-reverse"
    },
    sloganContainer: {
      justifyContent: "center",
      alignItems: "center",
      padding: "1em"
    },
    slogan: {
      fontSize: "45px"
    }
    // searchCardWrapper: {
    //   display: "none"
    // }
  },
  [theme.breakpoints.down("xs")]: {
    container: {
      display: "block"
    },
    wrapper: {
      height: "fit-content",
      paddingTop: "1em",
      paddingBottom: "3em",
      marginTop: "3rem",
      "@media (max-width: 320px)": {
        // iphone 4
        marginTop: "-2rem"
      }
    },
    contentContainer: {
      flexDirection: "column-reverse"
    },
    sloganContainer: {
      border: "1px solid red",
      justifyContent: "center",
      alignItems: "center"
    },
    slogan: {
      fontSize: "45px"
    }
  },
  "@media (max-width: 375px) and @media (max-height: 815px)": {
    border: "1px solid red"
  }
}));

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    window.addEventListener("resize", handleResize);

    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}

const HeaderSection = props => {
  const classes = useStyles();

  const isTablet = useWindowSize();

  const getBackground = props => {
    let result;
    if (props.image) {
      result = props.image;
    } else {
      result =
        "https://resorter-images.s3-ap-southeast-2.amazonaws.com/Copy+of+alain-wong-f-oxr0Lk5EU-unsplash.jpg";
    }
    return result;
  };

  // const sloganType = (
  //   <div>
  //     {props.version === "instructor" ? (
  //       <Grid
  //         container
  //         item
  //         justify="center"
  //         alignItems="center"
  //         md={4}
  //         sm={4}
  //         xs={4}
  //       >
  //         <h1 className={classes.sloganInstructor}>
  //           {props.slogan == null
  //             ? "Book lessons and hosts for your next snow adventure"
  //             : props.slogan}
  //         </h1>
  //       </Grid>
  //     ) : (
  //       <h1 className={classes.slogan}>
  //         {props.slogan == null
  //           ? "Browse and book experience hosts local to the resort"
  //           : props.slogan}
  //       </h1>
  //     )}
  //   </div>
  // );

  return (
    <>
      <div className={classes.container} />
      <div
        className={
          isTablet.height < 799 ? classes.tabletWrapper : classes.wrapper
        }
        // style={{
        //   backgroundImage: `url(${getBackground(props)})`
        // }}
      >
        <Grid
          container
          className={classes.contentContainer}
          // space={3}
          xs={12}
        >
          {props.searchCardBlue ? (
            <Grid className={classes.searchCardWrapper} item xs={12}>
              <SearchCardHorizontal
                onSelectCountry={props.onSelectCountry}
                onSelectResort={props.onSelectResort}
                onSelectStartDate={props.onSelectStartDate}
                onSelectEndDate={props.onSelectEndDate}
                handleChange={props.handleChange}
                onSearchClick={props.onSearchClick}
                setSelectedResortID={props.setSelectedResortID}
              />
            </Grid>
          ) : (
            <Grid className={classes.searchCardWrapper} item xs={12}>
              <SearchCardHorizontal
                onSelectCountry={props.onSelectCountry}
                onSelectResort={props.onSelectResort}
                onSelectStartDate={props.onSelectStartDate}
                onSelectEndDate={props.onSelectEndDate}
                handleChange={props.handleChange}
                onSearchClick={props.onSearchClick}
                setSelectedResortID={props.setSelectedResortID}
              />
            </Grid>
          )}

          {/* <Grid
          className={classes.sloganContainer}
          container
          item
          md={7}
          sm={12}
          xs={12}
        >
          {sloganType}
          <Typography className={classes.underSlogan}>
            {props.underSlogan == null
              ? "...just for YOURSELF; your FAMILY; the KIDS; or for a group of FRIENDS"
              : props.underSlogan}
          </Typography>
        </Grid> */}
        </Grid>
      </div>
    </>
  );
};

export default HeaderSection;
