import React, { useState, useEffect } from "react";
import "../../../../styling/css/Homepage/search.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { connect } from "react-redux";
import "../../../../styling/css/template/ValidationErrors.css";
import _ from "lodash";
import $ from "jquery";
import GPDRWarning from "../Common/GPDRWarning";
import { SectionTitle } from "../../../../styling/styled-components";
import { getCountriesAndOpeningStatus } from "./helper";
import {
  selectCountry,
  selectResort,
  selectResortId,
  setCountriesAndResorts
} from "../../../../redux/actions/tripWhereActions";

export function validateResort(value) {
  let errors = {};
  // set error if resortName is empty
  if ("" === value || undefined === value) {
    errors.resortName = "required";
  }
  return errors;
}

const ResortSelector = props => {
  const [blurred, setBlurred] = useState(false);

  // TODO: find a way to remove duplicate of this function
  const validateResort = value => {
    let errors = {};
    // set error if resortName is empty
    if ("" === value || undefined === value) {
      errors.resortName = "required";
    }
    return errors;
  };

  // Will be called in "Country" class so as to change the "selectedCountry" in state
  const handleChangedCountry = event => {
    const selected = event.target.value;
    const {
      resortsWithContinent,
      selectCountry,
      selectResort,
      selectResortId
    } = props;

    selectCountry(selected);
    selectResort("");
    selectResortId("");
  };

  //Set the selected Resort, it will be used to make order
  const handleSelectedCountryResorts = event => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const id = optionElement.getAttribute("id");
    const selected = event.target.value;

    if (props.resortsWithContinent[selected] === "Europe") {
      handleEurope();
    } else {
      props.selectResort(selected);
      props.selectResortId(id);
    }
  };

  const handleEurope = () => {
    // reset country and resort
    selectCountry("");
    selectResort("");
    selectResortId("");
    $("#GPDRWarning").modal("show");
  };

  return (
    <div>
      <GPDRWarning />
      <div className="container">
        {props.homePage ? (
          <h2 className="text-justify">Select Resort</h2>
        ) : (
          <SectionTitle>Select Resort</SectionTitle>
        )}
        <div className="row">
          <div className="col-sm resortSelection">
            <select
              className="custom-select"
              onChange={handleChangedCountry}
              value={props.country}
              disabled={props.countriesAndOpeningStatus.length === 0}
            >
              {(props.country === "" || props.country == null) && (
                <option disabled value="">
                  By Country
                </option>
              )}
              {props.countriesAndOpeningStatus.map(option => (
                <option key={option.name} disabled={option.notOpening}>
                  {option.name}
                </option>
              ))}
            </select>

            {/*<DropDown*/}
            {/*  defaultName="By Country"*/}
            {/*  options={countryName}*/}
            {/*  value={props.country}*/}
            {/*  dropDownValue={handleChangedCountry}*/}
            {/*/>*/}
          </div>
          <div className="col-sm">
            <select
              className="custom-select"
              onChange={handleSelectedCountryResorts}
              value={props.resortName}
              disabled={
                (props.countryResortInfoDic && !props.countryResortInfoDic[props.country]) ||
                (props.countryResortInfoDic && props.countryResortInfoDic[props.country].length) === 0
              }
              onBlur={() => setBlurred(true)}
            >
              {(props.resortName === "" || props.country == null) && (
                <option disabled value="">
                  By Resort
                </option>
              )}
              {(props.countryResortInfoDic && props.countryResortInfoDic[props.country]) &&
                props.countryResortInfoDic[props.country].resorts.map(
                  option => (
                    <option key={option.id} id={option.id}>
                      {option.name}
                    </option>
                  )
                )}
            </select>
            {/*<DropDown*/}
            {/*  id="countryDropDown "*/}
            {/*  defaultName="By Resort"*/}
            {/*  options={countryResorts}*/}
            {/*  value={props.resortName}*/}
            {/*  dropDownValue={handleSelectedCountryResorts}*/}
            {/*  onBlur={validateResort}*/}
            {/*/>*/}
            {(blurred === true || props.resortValidated === true) &&
              validateResort(props.resortName).resortName != null && (
                <div className="div-error">
                  {validateResort(props.resortName).resortName}
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    startDate: state.tripWhenReducer.startDate,
    endDate: state.tripWhenReducer.endDate,
    resortName: state.tripWhereReducer.resortName,
    resortId: state.tripWhereReducer.resortId,
    country: state.tripWhereReducer.country,
    countryResortInfoDic: state.tripWhereReducer.countryResortInfoDic,
    countriesAndOpeningStatus: state.tripWhereReducer.countriesAndOpeningStatus,
    resortValidated: state.tripWhereReducer.resortValidated,
    resortsWithContinent: state.tripWhereReducer.resortsWithContinent
  };
}

export default connect(
  mapStateToProps,
  { selectCountry, selectResort, selectResortId },
  null,
  { forwardRef: true }
)(ResortSelector);
