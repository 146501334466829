import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

import "react-calendar-timeline/lib/Timeline.css";
import classNames from "classnames";
import agent from "../../../agent";
// Components
import ItemsCarousel from "react-items-carousel";

// Style
import useStyles from "../../Common/SignupForm/ClientInfoStyles";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import { getProviderProfile } from "../../../redux/actions/stepperActions";

import {TextContent, ListContent} from "../../Common/SignupForm/ProviderInfo/Services/InputFields";
import { getCountryList } from "../../../redux/actions/homePageAction.js";
import ReactStars from "react-rating-stars-component";

//import Box from '@mui/material/Box';
//import Rating from 'material-ui-rating';

import {
  Typography,
  Grid,
  Avatar,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  // Box,
  Paper,
  ImageList,
  ImageListItem
} from "@material-ui/core";
import createTypography from "@material-ui/core/styles/createTypography";



const useClasses = makeStyles(theme => ({
  arrowIcon: {
    transform: "rotate(180deg)"
  },
  root: {
    margin: "0 auto",
    width: "100%",
    marginTop: "2rem",
    alignItems: "center"
  },
  backButton: {
    margin: "3em",
    borderColor: "#FF6161",
    color: "#FF6161"
  },
  avatarSize: {
    width: "10rem",
    height: "10rem",
    marginBottom: "15%",
    [theme.breakpoints.down("sm")]: {
      width: "10rem",
      height: "10rem"
    }
  },
  editProfileBtn: {
    borderRadius: 100,
    background: "#FF6161",
    width: "70%",
    fontSize: 14,
    lineHeight: "140.62%",
    backgroundColor: "#FF6161",
    color: "white",
    fontWeight: "normal",
    letterSpacing: "0.01em",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "40%"
    }
  },
  textName: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#1277B8",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      marginTop: "2rem",
      textAlign: "center"
    }
  },
  viewUpcommingBtn: {
    background: "#FF6161",
    width: "70%",
    fontSize: 14,
    lineHeight: "140.62%",
    backgroundColor: "#FF6161",
    color: "white",
    fontWeight: "normal",
    letterSpacing: "0.01em",
    borderRadius: 100,
    [theme.breakpoints.down("sm")]: {
      width: "40%",
      marginTop: "1rem",
      marginBottom: "1rem"
    }
  },
  textFieldFormat: {
    color: "#000000",
    width: "100%",
    height: "9%",
    fontSize: 14,
    resize: "none",
    border: "0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "1rem",
      marginBottom: "2rem",
      color: "#000000"
    }
  },
  divAvatar: {
    textAlign: "center",
    margin: "0 auto"
  },
  ratingFormat: {
    textAlign: "center",
    margin: "0 auto",
    width: "fit-content"
  },
  commonFormat: {
    fontStyle: "normal",
    fontFamily: "PT Sans Narrow"
  },
  nameLabel: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "30px",
    color: "#000000"
  },
  label: {
    marginLeft: "7.5%",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    marginBottom: "0.5em",
    textTransform: "uppercase",
    color: "#000000"
  },
  galleryLabel: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "26px",
    color: "#000000"
  }
}));

function MyInfoView(props) {
  const styles = useStyles();
  const classes = useClasses();

  useEffect(() => {
    props.getProviderProfile(2);
    props.getCountryList();

    //props.getProviderReviewRating('52');
  }, []);
  console.log(props);

  const { avatar, profileData } = props;
  const {userProfile} =props;

  console.log(userProfile);
  console.log(profileData);
  const {country_list}=props;
  

  var items = [
    {
        name: "Random Name #1",
        description: "Probably the most random thing you have ever seen!"
    },
    {
        name: "Random Name #2",
        description: "Hello World!"
    }
]; 
  
  const itemData = [
    {
      index: 0,
      img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
      title: "Breakfast"
    },
    {
      index: 1,
      img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
      title: "Burger"
    },
    {
      index: 2,
      img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
      title: "Camera"
    },
    {
      index: 3,
      img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
      title: "Coffee"
    },
    {
      index: 4,
      img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
      title: "Hats"
    },
    {
      index: 5,
      img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
      title: "Honey"
    }
  ];

  let [activeItem, setActiveItem] = useState(1); //Image Gallery

  let [isLoading, setIsLoading] = useState(true);
  let [profilePicture, setProfilePicture] = useState("");
  let [name, setName] = useState("");
  let [bio, setBio] = useState("");
  let [nationality, setNationality] = useState("");
  let [languages, setLanguages] = useState([]);
  let [skiLevel, setSkiLevel] = useState("");
  let [snowboardLevel, setSnowboardLevel] = useState("");
  let [gallery, setGallery] = useState([]);
  let [countries, setCountries] = useState([]);
 
  let [firstTimer, setFirstTimer]=useState([]);
  let [tours, setTours]=useState([]);
  let [activities, setActivities]=useState([]);
  let [virtualLessons, setVirtualLessons]=useState([]);
  let [childMining, setchildMining]=useState([]);
  let [allServices, setAllServices]=useState([]);
  
  let [providerReviews, setProviderReviews]=useState([]);
  let [providerRating, setProviderRating]=useState(0);

  const [activeItemIndex, setActiveItemIndex] = useState(0);

  useEffect(() => {
    setIsLoading(true);

    if (profileData != undefined) {
      setProfilePicture(profileData.profile_picture);
      setName(profileData.firstName + " " + profileData.lastName);
      setBio(profileData.bio);

      console.log(profileData);
  
      if (profileData.country != undefined) {
        setNationality(profileData.country.country_name);
      }

      

      setLanguages(profileData.languages);

      if (profileData.skiLevel != undefined) {
        if (profileData.skiLevel == 1) {
          setSkiLevel("Ski - Level 1 - First Timer");
        } else if (profileData.skiLevel == 2) {
          setSkiLevel("Ski - Level 2 - Low Beginner");
        } else if (profileData.skiLevel == 3) {
          setSkiLevel("Ski - Level 3 - High Beginner");
        } else if (profileData.skiLevel == 4) {
          setSkiLevel("Ski - Level 4 - Low Intermediate");
        } else if (profileData.skiLevel == 5) {
          setSkiLevel("Ski - Level 5 - High Intermediate");
        } else if (profileData.skiLevel == 6) {
          setSkiLevel("Ski - Level 6 - Low Advanced");
        } else if (profileData.skiLevel == 7) {
          setSkiLevel("Ski - Level 7 - High Advanced");
        }
      }

      if (profileData.snowLevel != undefined) {
        if (profileData.snowLevel == 1) {
          setSnowboardLevel("Snowboard - Level 1 - First Timer");
        } else if (profileData.snowLevel == 2) {
          setSnowboardLevel("Snowboard - Level 2 - Low Beginner");
        } else if (profileData.snowLevel == 3) {
          setSnowboardLevel("Snowboard - Level 3 - High Beginner");
        } else if (profileData.snowLevel == 4) {
          setSnowboardLevel("Snowboard - Level 4 - Low Intermediate");
        } else if (profileData.snowLevel == 5) {
          setSnowboardLevel("Snowboard - Level 5 - High Intermediate");
        } else if (profileData.snowLevel == 6) {
          setSnowboardLevel("Snowboard - Level 6 - Low Advanced");
        } else if (profileData.snowLevel == 7) {
          setSnowboardLevel("Snowboard - Level 7 - High Advanced");
        }
      }

      setGallery(profileData.imageGallery);

      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [profileData]);





  //for provider profile
  useEffect(() => {
    setIsLoading(true);


    //setServices(userProfile.providerServices);
    
    if (userProfile!=undefined)
    {
      if (userProfile.resorts!=undefined)
          {

            //approach 2

            let countries_temp=[];
            for(let i=0;i<userProfile.resorts.length;i++)
            {
              country_list.map((item)=>
                item.id==userProfile.resorts[i].country?
                countries_temp.includes(item.country_name)?null:
                countries_temp.push(item.country_name):null
              );
            }

            //countries_temp.map((country)=>console.log(country));

            setCountries(countries_temp);
            


          }
   
          setIsLoading(false);
    }
    else{
      setIsLoading(true);
    }
    }, [userProfile]);


    useEffect(() => {
      setIsLoading(true);
      if (userProfile!=undefined)
      {
        
        if (userProfile.providerReview!=undefined)
          {
            setProviderReviews(userProfile.providerReview);
            
          }
      
          setIsLoading(false);
      }
      
      else{
        setIsLoading(true);
      }
      }, [userProfile]);
    
    

    console.log(typeof(providerRating))
      console.log(providerRating) 




    //for setting response to all services
    useEffect(() => {

      
      agent.Constants.getServices().then(response => {

        if (response.status === 200) {

         
          setAllServices(response.data);

        }

      }).catch(error => {

          console.log("error", error);

      });

    }, []);



    //setting provider rating
    useEffect(() => {

      
      agent.Users.getProviderRating('20').then(response => {

        if (response.status === 200) {

         
          setProviderRating(response.data.average_rating_value);
          

        }

      }).catch(error => {

          console.log("error", error);

      });

    }, []);


    console.log(providerRating)



    //for provider services and all services
    useEffect(() => {
      

      setIsLoading(true);
      
      setExperiences("FIRST TIMER ASSISTANCE HOSTS");
      setExperiences("TOURING HOSTS");
      setExperiences("ACTIVITY HOSTS");
      setExperiences("VIRTUAL LESSONS by Instructors");
      setExperiences("CHILD MINDING HOSTS");
      
    }, [userProfile]);



const setExperiences=category=>
{
  if (userProfile!=undefined)
  {
    console.log("test")
    if (userProfile.providerServices!=undefined)
      {
        
        console.log(userProfile.providerServices);

        let tempServices=[];
        
        for(let i=0;i<userProfile.providerServices.length;i++)
        {
          if(userProfile.providerServices[i].catagory_name==category)
          {
            
            tempServices.push(userProfile.providerServices[i].service_name);
            
          }
          
        }
        

        
        category=='FIRST TIMER ASSISTANCE HOSTS'?setFirstTimer(tempServices):
        category=='TOURING HOSTS'?setTours(tempServices):
        category=='ACTIVITY HOSTS'?setActivities(tempServices):
        category=='VIRTUAL LESSONS by Instructors'?setVirtualLessons(tempServices):
        category=='CHILD MINDING HOSTS'?setchildMining(tempServices):console.log("null");
            
        
        
      }
      setIsLoading(false);
}
else{
  setIsLoading(true);
}
};

    
console.log(virtualLessons);


  const handleSetActiveItem = value => {
    setActiveItem(value);
  };

  return (

    

    <div>
      
      {isLoading ? (
        <div className="loader-container">
          <div className="loader" />
        </div>
      ) : (
        <div>
          <Grid
            container
            justify="center"
            alignItems="center"
            spacing={0}
            direction="column"
            style={{ padding: "2rem 15px" }}
          >
            {/* Profile Picture, Bio */}
            <Grid
              container
              justify="flex-start"
              alignItems="flex-start"
              xs={12}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              direction="row"
              display="flex"
            >
              <Grid
                item
                container
                xs={12}
                sm={4}
                lg={4}
                direction="column"
                justify="center"
                alignItems="center"
               
              >
                {profileData.profile_picture == undefined ? (
                  <Avatar className={classes.avatarSize} src={avatar} />
                ) : (
                  <Avatar className={classes.avatarSize}>
                    <img src={profilePicture} />
                  </Avatar>
                )}
              </Grid>
              
              <Grid
                item
                container
                xs={12}
                sm={4}
                lg={8}
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
                
              >
                <Grid
                item
                container   
                xs={12}
                sm={12}
                lg={12}    
                direction="row"
                justify="space-between"
                alignItems="center"
                display="flex"
                flexWrap="wrap"
              >
                <Typography className={classes.nameLabel}>{name}</Typography>
                
                  
                
                {bio}
              </Grid>
                
                  
                  </Grid>

                
              
            </Grid>

            {/* <FormControl>
  <InputLabel htmlFor="my-input">Email address</InputLabel>
  <Input id="my-input" aria-describedby="my-helper-text" />
  <FormHelperText id="my-helper-text">We'll never share your email.</FormHelperText>
</FormControl> */}

            {/* Nationality, Languages, Abilities */}
            <Grid
              container
              justify="center"
              alignItems="flex-start"
              xs={12}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              direction="row"
              style={{ padding: "2rem 0px" }}
            >
              {/* Nationality */}
              <Grid
                item
                container
                xs={12}
                sm={4}
                direction="column"
                justify="center"
                alignItems="left"
                style={{ marginBottom: "1rem" }}
              >
                <Grid item style={{ padding: "0.25rem 0" }}>
                  <Typography className={styles.allergyandotherinformation}>
                    NATIONALITY
                  </Typography>
                </Grid>
                <Grid item container justify="left" alignItems="left">
                  {nationality}
                </Grid>
              </Grid>

              {/* Languages */}
              <Grid
                item
                container
                xs={12}
                sm={4}
                direction="column"
                justify="center"
                alignItems="left"
                style={{ marginBottom: "1rem" }}
              >
                <Grid item style={{ padding: "0.25rem 0" }}>
                  <Typography className={styles.allergyandotherinformation}>
                    LANGUAGES
                  </Typography>
                </Grid>
                <Grid item container justify="left" alignItems="left">
                  {nationality}
                </Grid>
              </Grid>

              {/* Abilities */}
              <Grid
                item
                container
                xs={12}
                sm={4}
                direction="column"
                justify="center"
                alignItems="left"
                style={{ marginBottom: "1rem" }}
              >
                <Grid item style={{ padding: "0.25rem 0" }}>
                  <Typography className={styles.allergyandotherinformation}>
                    ABILITY LEVEL
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  justify="left"                             //align items vertically
                  alignItems="left"                        //align items horizontally  but if dir is row then these both are flipped
                >
                  <Typography align="left">{skiLevel}</Typography>
                  <Typography align="left">{snowboardLevel}</Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* Nationality Countries Suitable for */}
            <Grid
              container
              justify="center"
              alignItems="flex-start"
              xs={12}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              direction="row"
              style={{ padding: "2rem 0px" }}
            >
              {/* Nationality */}
              <Grid
                item
                container
                xs={12}
                sm={4}
                direction="column"
                justify="center"
                alignItems="left"
                style={{ marginBottom: "1rem" }}
              >
                <Grid item style={{ padding: "0.25rem 0" }}>
                  <Typography className={styles.allergyandotherinformation}>
                    NATIONALITY
                  </Typography>
                </Grid>
                <Grid item container justify="left" alignItems="left">
                  {nationality}
                </Grid>
              </Grid>

              {/* Countries */}
              <Grid
                item
                container
                xs={12}
                sm={4}
                direction="column"
                justify="center"
                alignItems="left"
                style={{ marginBottom: "1rem" }}
              >
                <Grid item style={{ padding: "0.25rem 0" }}>
                  <Typography className={styles.allergyandotherinformation}>
                    COUNTRIES
                  </Typography>
                </Grid>
                
                  
                {countries.map((country)=>{
                  
                  return (
                    <Grid item container justify="left" alignItems="left">
                  <Typography align="left">{country}</Typography>
                  </Grid>)
                  
                  })}

                
              </Grid>

              {/* Suitable for */}
              <Grid
                item
                container
                xs={12}
                sm={4}
                direction="column"
                justify="center"
                alignItems="left"
                style={{ marginBottom: "1rem" }}
              >
                <Grid item style={{ padding: "0.25rem 0" }}>
                  <Typography className={styles.allergyandotherinformation}>
                    Suitable for
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  justify="left"
                  alignItems="left"
                >
                  <Typography align="left">TEST</Typography>
                </Grid>
              </Grid>
            </Grid>



            {/* Experiences Offered */}


            <Grid
              container
              justify="center"
              alignItems="flex-start"
              xs={12}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              direction="row"
              style={{ padding: "2rem 0px" }}
            ><Grid item style={{ padding: "0.25rem 0" }}>
            <Typography align="center">
              Experiences offered
            </Typography></Grid>
              </Grid>

            <Grid
              container
              justify="center"
              alignItems="flex-start"
              xs={12}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              direction="row"
              style={{ padding: "2rem 0px" }}
            >
            
            {/* FIRST TIMER HOSTING */}
            <Grid
                item
                container
                xs={12}
                sm={4}
                direction="column"
                justify="center"
                alignItems="left"
                style={{ marginBottom: "1rem" }}
              >
                
                <Grid style={{height: '200px'}}>
                <Grid item style={{ padding: "0.25rem 0" }}>
                  <Typography className={styles.allergyandotherinformation}>
                    FIRST TIMER HOSTING
                  </Typography>
                </Grid>
                
              {

          
              allServices.map((ft)=>{
                                      
                                        
                return (
                  firstTimer.includes(ft.service_name)?
                  <Grid item container justify="left" alignItems="left">
                <Typography align="left" style={{color:"black"}}><ListContent /></Typography>
                </Grid>:ft.catagory_name=="FIRST TIMER ASSISTANCE HOSTS"?
                <Grid item container justify="left" alignItems="left">
                <Typography align="left" style={{color:"grey"}}><ListContent /></Typography>
                </Grid>:null)
                
                })
            
            }
              </Grid>

              
              {/* Virtual lessons */}
              <Grid style={{height: '200px'}}>
            <Grid item style={{ padding: "0.25rem 0" }}>
                  <Typography className={styles.allergyandotherinformation}>
                    VIRTUAL LESSONS
                  </Typography>
                </Grid>
                {//approach 2

                
                    allServices.map((ft)=>{
                                      
                                        
                      return (
                        virtualLessons.includes(ft.service_name)?
                        <Grid item container justify="left" alignItems="left">
                      <Typography align="left" style={{color:"black"}}>{ft.service_name}</Typography>
                      </Grid>:ft.catagory_name=="VIRTUAL LESSONS by Instructors"?
                      <Grid item container justify="left" alignItems="left">
                      <Typography align="left" style={{color:"grey"}}>{ft.service_name}</Typography>
                      </Grid>:null)
                      
                      })
                    }


                   

              
                

                </Grid>
                </Grid>

                {/* ACTIVITIES */}
                <Grid
                item
                container
                xs={12}
                sm={4}
                direction="column"
                justify="center"
                alignItems="left"
                style={{ marginBottom: "1rem" }}
              >

            <Grid style={{height: '200px'}}>
                <Grid item style={{ padding: "0.25rem 0" }}>
                  <Typography className={styles.allergyandotherinformation}>
                    ACTIVITIES
                  </Typography>
                </Grid>
                {//approach 2

                    
                    allServices.map((ft)=>{
                                                                          
                                                                            
                      return (
                        activities.includes(ft.service_name)?
                        <Grid item container justify="left" alignItems="left">
                      <Typography align="left" style={{color:"black"}}>{ft.service_name}</Typography>
                      </Grid>:ft.catagory_name=="ACTIVITY HOSTS"?
                      <Grid item container justify="left" alignItems="left">
                      <Typography align="left" style={{color:"grey"}}>{ft.service_name}</Typography>
                      </Grid>:null)
                      
                      })}

                    </Grid>

                    {/* Child mining */}

                    <Grid style={{height: '200px'}}>
              <Grid item style={{ padding: "0.25rem 0" }}>
                  <Typography className={styles.allergyandotherinformation}>
                    CHILD MINING
                  </Typography>
                </Grid>
                {//approach 2

                
                allServices.map((ft)=>{
                                                      
                                                        
                  return (
                    childMining.includes(ft.service_name)?
                    <Grid item container justify="left" alignItems="left">
                  <Typography align="left" style={{color:"black"}}>{ft.service_name}</Typography>
                  </Grid>:ft.catagory_name=="CHILD MINDING HOSTS"?
                  <Grid item container justify="left" alignItems="left">
                  <Typography align="left" style={{color:"grey"}}>{ft.service_name}</Typography>
                  </Grid>:null)
                  
                  })}
                                

                </Grid>
                </Grid>
                {/* TOURS */}

                <Grid
                item
                container
                xs={12}
                sm={4}
                direction="column"
                justify="center"
                alignItems="left"
                style={{ marginBottom: "1rem" }}
              >
                <Grid item style={{ padding: "0.25rem 0" }}>
                  <Typography className={styles.allergyandotherinformation}>
                    TOURS
                  </Typography>
                </Grid>
                {


                    allServices.map((ft)=>{
                  
                    
                      return (
                        tours.includes(ft.service_name)?
                        <Grid item container justify="left" alignItems="left">
                      <Typography align="left" style={{color:"black"}}>{ft.service_name}</Typography>
                      </Grid>:ft.catagory_name=="TOURING HOSTS"?
                      <Grid item container justify="left" alignItems="left">
                      <Typography align="left" style={{color:"grey"}}>{ft.service_name}</Typography>
                      </Grid>:null)
                      
                      })
                    } 
                  

                

                </Grid>


                
            </Grid>
            

            <Grid
              container
              justify="center"
              alignItems="flex-start"
              xs={12}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              direction="row"
              style={{ padding: "2rem 0px" }}
            ><Grid item style={{ padding: "0.25rem 0" }}>
            <Typography align="center">
              <b>Reviews</b>
            </Typography></Grid>
              </Grid>


            <Grid
              container
              justify="center"
              alignItems="flex-start"
              xs={12}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              direction="row"
            >
              <Grid
                container
                direction="row"
                justify="center"
                justifyContent="center"
                alignItems="center"
                xs={12}
                sm={10}
                md={8}
                lg={6}
                spacing={2}
                style={{ backgroundColor: "white", padding: "2rem 0px" }}
              >
                <Grid
                  container
                  item
                  xs={8}
                  sm={10}
                  direction="column"
                  alignItems="center"
                  justify="center"
                >
                  <Grid item style={{ width: "100%" }}>
                    <ItemsCarousel
                      placeholderItem={
                        <div style={{ height: 200, background: "#EEE" }} />
                      }
                      enablePlaceholder={true}
                      numberOfPlaceholderItems={3}
                      gutter={12}
                      activePosition={"center"}
                      chevronWidth={100}
                      disableSwipe={false}
                      alwaysShowChevrons={false}
                      numberOfCards={1}
                      slidesToScroll={1}
                      outsideChevron={true}
                      showSlither={false}
                      firstAndLastGutter={false}
                      activeItemIndex={activeItem}
                      requestToChangeActive={value =>
                        handleSetActiveItem(value)
                      }
                      rightChevron={<ArrowForwardIosOutlinedIcon />}
                      leftChevron={<ArrowBackIosOutlinedIcon />}
                    >
                      {providerReviews.map( (item) => <Item item={item} /> )}
                    </ItemsCarousel>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>


           

  
          
            {/* Image Gallery Header */}
            <Grid
              container
              justify="center"
              alignItems="flex-start"
              xs={12}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              direction="row"
            >
              <Grid item xs={12} sm={12}>
                <Typography align="center" className={classes.galleryLabel}>
                  Gallery
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              justify="center"
              alignItems="flex-start"
              xs={12}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              direction="row"
            >
              <Grid
                container
                direction="row"
                justify="center"
                justifyContent="center"
                alignItems="center"
                xs={12}
                sm={10}
                md={8}
                lg={6}
                spacing={2}
                style={{ backgroundColor: "white", padding: "2rem 0px" }}
              >
                <Grid
                  container
                  item
                  xs={8}
                  sm={10}
                  direction="column"
                  alignItems="center"
                  justify="center"
                >
                  <Grid item style={{ width: "100%" }}>
                    <ItemsCarousel
                      placeholderItem={
                        <div style={{ height: 200, background: "#EEE" }} />
                      }
                      enablePlaceholder={true}
                      numberOfPlaceholderItems={3}
                      gutter={12}
                      activePosition={"center"}
                      chevronWidth={100}
                      disableSwipe={false}
                      alwaysShowChevrons={false}
                      numberOfCards={3}
                      slidesToScroll={1}
                      outsideChevron={true}
                      showSlither={false}
                      firstAndLastGutter={false}
                      activeItemIndex={activeItem}
                      requestToChangeActive={value =>
                        handleSetActiveItem(value)
                      }
                      rightChevron={<ArrowForwardIosOutlinedIcon />}
                      leftChevron={<ArrowBackIosOutlinedIcon />}
                    >
                      {itemData.map(item => (
                        <img
                          src={`${item.img}?w=154&h=154&fit=crop&auto=format`}
                          alt={item.title}
                          loading="lazy"
                        />
                      ))}
                    </ItemsCarousel>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>


            
            
          </Grid>
        </div>
      )}
    </div>
  );
}




function RatingComp(props)
{
  let ratingOutOf5=0;
  if(props.xyz!=undefined)
    {
      
      ratingOutOf5=(props.xyz/10)*5;
      console.log(ratingOutOf5);
    }
  console.log(ratingOutOf5)
  return (
    <Grid >
        
    </Grid>
          
      
  )
}


function Item(props)
{
  var date = props.item.creation_date
  console.log(date)
  var d = new Date(date);
  var n = d.getMonth()+1;
  console.log(n)
    return (
      <Grid >
        <p><center>"{props.item.reviewer_content}"</center></p>
          <p><b><center>{props.item.reviewer_name}, {props.item.creation_date}</center></b></p>
          
      </Grid>
            
        
    )
}





// Reducer part
function mapStateToProps(state, ownProps) {
  return {
    profileData: state.profileDataReducer.profileData,   //client data
    userProfile: state.stepperReducer.userProfile,        //provider data
    country_list: state.homePageReducer.country_list
  };
}

// Adding Dispatch to update the existing values in Redux
const mapDispatchToProps = dispatch => ({
  getProviderProfile: userId => dispatch(getProviderProfile(userId)),
  getCountryList: () => dispatch(getCountryList())
  //getProviderReviewRating: providerId=> dispatch(getProviderReviewRating(providerId))
});

//Connect part
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyInfoView);