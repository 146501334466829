import {
  UPDATE_RESORTS,
  UPDATE_SUITABILITY,
  UPDATE_PRE_ARRIVAL,
  UPDATE_POST_ARRIVAL,
  UPDATE_LANGUAGES,
  UPDATE_LOCAL,
  UPDATE_TOURS,
  UPDATE_INTERSTS,
  UPDATE_AVATAR,
  UPDATE_NAME,
  UPDATE_RATING,
  UPDATE_STARTING_PRICE,
  UPDATE_BIO
} from "../actions/action-types";
import profilePic from "../../materials/HomePage/host.jpg";

const initialState = {
  HostIntroSection: {
    resorters: ["Mount Hotham", "Falls Creek"],
    suitability: "Kids ",
    preArrival: ["General Conceirge", "Transfers/Resort Entry"],
    postArrival: ["General Assistance ", "Equipment Rental "],
    languages: ["English", "spanish"],
    local: 12,
    tours: ["General tour of the Slopes ", "General tour of the Village"],
    expertiseAndintersts: ["History", "Comedy"]
  },
  HostMyProfile: {
    avtar: profilePic,
    name: {
      firstName: "Aaron",
      lastName: "Hussain"
    },
    rating: 5,
    intialprice: 80,
    bio:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
  }
};

const hostNewProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_AVATAR:
      return { ...state, avatar: action.payload };
    case UPDATE_NAME:
      return { ...state, name: action.payload };
    case UPDATE_RATING:
      return { ...state, rating: action.payload };
    case UPDATE_STARTING_PRICE:
      return { ...state, intialprice: action.payload };
    case UPDATE_BIO:
      return { ...state, bio: action.payload };
    case UPDATE_SUITABILITY:
      return { ...state, suitability: action.payload };
    case UPDATE_RESORTS:
      return { ...state, resorters: [...state.resorters, action.payload] };
    case UPDATE_PRE_ARRIVAL:
      return { ...state, preArrival: [...state.preArrival, action.payload] };
    case UPDATE_POST_ARRIVAL:
      return { ...state, postArrival: [...state.postArrival, action.payload] };
    case UPDATE_LANGUAGES:
      return { ...state, languages: [...state.languages, action.payload] };
    case UPDATE_LOCAL:
      return { ...state, local: action.payload };
    case UPDATE_TOURS:
      return {
        ...state,
        qulification: [...state.tours, action.payload]
      };
    case UPDATE_INTERSTS:
      return {
        ...state,
        qulification: [...state.expertiseAndintersts, action.payload]
      };
    default:
      return state;
  }
};

export default hostNewProfileReducer;
