import React from "react";
import { connect } from "react-redux";

// CSS
import styles from "./HostCard.module.css";
// Component
import StarRating from "../../Common/StarRating/StarRating.js";

// Redux
import Store from "../../../redux/store";
import { toggleHostLike } from "../../../redux/actions/exploreHostsActions";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  IconButton,
  Typography,
  withWidth
} from "@material-ui/core";

import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";

import hostImg from "../../../materials/HomePage/host.jpg";

const useStyles = makeStyles({
  root: {
    position: "relative",
    maxWidth: 345
  },
  typography: {
    useNextVariants: true
  },
  buttonRoot: {
    marginBottom: "2em",
    textTransform: "none",
    fontWeight: "normal",
    fontFamily: "PT Sans Narrow",
    fontSize: 18,
    borderRadius: 20,
    backgroundColor: "#1277b8",
    "&:focus": {
      outline: "transparent"
    }
  },
  name: {
    color: "#1277b8",
    fontFamily: "PT Sans Narrow",
    fontWeight: "bold",
    fontSize: 17,
    textAlign: "left",
    paddingLeft: "0.3em"
  },
  perHour: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: "500",
    color: "#1277b8"
  },
  priceFrom: {
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: "500",
    color: "#1277b8"
  },
  price: {
    textAlign: "right",
    marginRight: "1em"
  },
  description: {
    textAlign: "left",
    paddingLeft: "0.5em",
    paddingRight: "0.5em",
    fontSize: 15,
    color: "#505050"
  },
  media: {
    position: "relative",
    height: 260
  },
  favourite: {
    marginLeft: "76%",
    position: "absolute",
    zIndex: "10",
    outline: "none",
    "&.MuiIconButton-root": {
      outline: "none"
    },

    "& > *": {
      margin: "1"
    }
  }
});

const HeartButton = props => {
  let btn;
  const classes = useStyles();
  if (props.filled === true)
    btn = (
      <IconButton
        onClick={props.onClick}
        color="secondary"
        aria-label="unfavourite"
        className={classes.favourite}
      >
        <FavoriteIcon />
      </IconButton>
    );
  else
    btn = (
      <IconButton
        onClick={props.onClick}
        aria-label="unfavourite"
        className={classes.favourite}
      >
        <FavoriteBorderIcon />
      </IconButton>
    );

  return btn;
};

const ButtonImageOverlay = props => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <HeartButton
        filled={props.isFilled}
        onClick={() => {
          props.onClick(props.id);
        }}
      />
      <img src={props.url} className={classes.media} />{" "}
    </React.Fragment>
  );
};

function HostCard(props) {
  const classes = useStyles();

  // TODO: uncomment for actual use
  // const { host } = props;

  const { name, id, isLiked, toggleHostLike } = props;

  const host = {
    name: name,
    id: id,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
    imageUrl: hostImg,
    price: 38,
    rating: 5,
    isLiked: isLiked,
    toggleHostLike: toggleHostLike
  };

  // To be moved to props once set up.

  return (
    <Card className={classes.root}>
      <CardMedia
        name={host.name}
        component={ButtonImageOverlay}
        url={host.imageUrl}
        onClick={props.onClick}
        isFilled={isLiked}
        id={id}
      />

      <CardContent>
        <div className={styles.containerHostCard}>
          <Typography
            gutterBottom
            variant="h4"
            className={classes.name}
            component="h1"
          >
            {host.name}
          </Typography>
          <div>
            <p className={classes.price}>
              <span className={classes.priceFrom}>Price From</span>
              <br />
              <span className={classes.perHour}>{`$${host.price}/hr`}</span>
            </p>
          </div>
        </div>
        <Typography
          className={classes.description}
          variant="body2"
          color="textSecondary"
          component="p"
        >
          {host.description}
        </Typography>
      </CardContent>
      <CardActions className={styles.cardAction}>
        <StarRating currentRating={host.rating} />

        <Button
          variant="contained"
          size="small"
          color="primary"
          className="button"
          classes={{ root: classes.buttonRoot }}
        >
          View Full Profile
        </Button>
      </CardActions>
    </Card>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    toggleHostLike: id => dispatch(toggleHostLike(id))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withWidth()(HostCard));
