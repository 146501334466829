import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Flag from "../../virtualLessons/Flag";
import { Typography } from "@material-ui/core";
import AvailabilityCard from "../../virtualLessons/Availability/AvailabilityCard.js";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import GanttCalendar from "../../virtualLessons/ganttCalendar";
import BookingCard from "../../virtualLessons/Booking/BookingCard.js";
import Services from "../../Common/SignupForm/ProviderInfo/Services/Services.js";
import Button from "@material-ui/core/Button";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  smallSectionHeading: {
    margin: "0, 1",
    width: "100%",
    fontSize: 18,
    color: "#1277B8",
    fontWeight: "bold",
    lineHeight: "14.62%",
    paddingTop: "2rem",
    paddingBottom: "2rem"
  },
  root: {
    width: "100%",
    height: "25.05px",
    fontWeight: "normal",
    fontSize: 16,
    lineHeight: "140.62%",
    alignItems: "left",
    color: "#000000",
    padding: "0px"
  },
  typograhyFormat: {
    fontSize: 16,
    lineHeight: "22.50px",
    color: "#1277B8",
    marginTop: "2rem",
    paddingBottom: "1rem",
    fontWeight: "bolder",
    width: "100%"
  },
  mainDiv: {
    margin: "0 auto",
    width: "80%",
    paddingTop: "4rem"
  },
  flagContainer: {
    display: "inline-block"
  },
  spanTextFormat: {
    fontSize: 15,
    paddingBottom: "1rem",
    display: "block",
    width: "130%"
  },
  calendarFormat: {
    margin: "0, auto",
    width: "100%",
    paddingLeft: "10%"
  },
  priceContainer: {
    display: "block",
    backgroundColor: "#D4E3ED",
    padding: 3,
    width: "3rem"
  },
  spanElementFormat: {
    marginLeft: 10
  },
  editPriceBtn: {
    borderRadius: 100,
    background: "#FF6161",
    width: "20%",
    fontSize: "14px",
    lineHeight: "140.62%",
    backgroundColor: "#FF6161",
    color: "white",
    fontWeight: "normal",
    letterSpacing: "0.01em",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "70%"
    }
  },
  commonFormat: {
    fontStyle: "normal",
    fontFamily: "PT Sans Narrow"
  }
}));

const HostIntroSection = props => {
  const classes = useStyles();

  const {
    resorters,
    suitability,
    preArrival,
    postArrival,
    languages,
    local,
    tours,
    expertiseAndintersts,
    activities,
    childServices,
    prices,
    isHost
  } = props;

  const Languages = languages.map(value => (
    <div className={classes.flagContainer}>
      <Flag language={value} key={value} />
    </div>
  ));

  const groups = [
    { id: 1, title: "Booked Slots" },
    { id: 2, title: "" },
    { id: 3, title: "" },
    { id: 4, title: "" }
  ];

  //temporary dummy data
  const items = [
    {
      id: 1,
      group: 1,
      title: "item 1",
      start_time: moment(),
      end_time: moment().add(1, "hour")
    },
    {
      id: 3,
      group: 1,
      title: "item 3",
      start_time: moment().add(2, "hour"),
      end_time: moment().add(3, "hour")
    }
  ];

  const [startdate, setStartdate] = React.useState(new Date());
  const theme = useTheme();

  const { bookings, unavailability, handleItemMove, handleItemResize } = props;
  const breakpoints = {
    matchesSm: useMediaQuery(theme.breakpoints.down("sm")),
    matchesXs: useMediaQuery(theme.breakpoints.down("xs")),
    matchesMd: useMediaQuery(theme.breakpoints.down("md")),
    matchesLg: useMediaQuery(theme.breakpoints.down("lg"))
  };
  const instructorData = props.instructorData;
  let rootSize = classes.root;
  if (breakpoints.matchesXs) {
    rootSize = classes.rootXs;
  } else if (breakpoints.matchesMd) {
    rootSize = classes.rootMd;
  }

  //fake data
  const priceList = [
    { key: 1, displayText: "Full day (up to 2 people)", amount: "$504.00" },
    { key: 2, displayText: "Full day (up to 3 people)", amount: "$505.00" },
    { key: 3, displayText: "Full day (up to 4 people)", amount: "$506.00" },
    { key: 4, displayText: "Full day (up to 5 people)", amount: "$507.00" }
  ];

  const updateTime = props.updateTime;

  //Will be able to edit availability as instructor
  //Will be able to make bookings as student
  let calendarCard = (
    <BookingCard
      instructorData={props.instructorData}
      updateTime={updateTime}
    />
  );

  if (props.isOwnProfile) {
    calendarCard = (
      <AvailabilityCard
        instructorData={props.instructorData}
        updateTime={updateTime}
      />
    );
  }

  //This allows the items to be colored
  const itemRenderer = ({
    item,
    itemContext,
    getItemProps,
    getResizeProps
  }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    const backgroundColor = item.bgColor;
    const borderColor = item.color;
    return (
      <div
        {...getItemProps({
          style: {
            background: item.color,
            backgroundColor: item.color,
            color: item.color,
            borderColor: item.color,
            borderStyle: "solid",
            borderWidth: 1,
            borderRadius: 4,
            borderLeftWidth: itemContext.selected ? 3 : 1,
            borderRightWidth: itemContext.selected ? 3 : 1
          }
        })}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ""}

        <div
          className="rct-item-content"
          style={{ maxHeight: `${itemContext.dimensions.height}` }}
        >
          {itemContext.title}
        </div>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ""}
      </div>
    );
  };

  const handleOnSubmit = () => {
    console.log("x");
  };

  const Items = props => {
    return props.items.map(element => {
      return (
        <div
          className={classNames(classes.spanTextFormat, classes.commonFormat)}
        >
          {element}
        </div>
      );
    });
  };

  return (
    // this is the main Grid Container work as  (DIV)
    <div>
      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        spacing={2}
        justify="space-around"
      >
        <Grid container item sm={6} xs={12} lg={6} spacing={12}>
          <Grid container item spacing={4}>
            <Grid container item xs={12} lg={12} spacing={4}>
              {/* this is the 1st row which has "Resorts" + "Suitable For" + "Pre Arrival" + "Post Arrival" */}
              <Grid item xs={12} lg={3}>
                <Typography
                  className={classNames(
                    classes.typograhyFormat,
                    classes.commonFormat
                  )}
                >
                  RESORTS
                </Typography>
                <Items items={resorters} />
              </Grid>

              <Grid item xs={12} lg={3}>
                <Typography
                  className={classNames(
                    classes.typograhyFormat,
                    classes.commonFormat
                  )}
                >
                  SUITABLE FOR
                </Typography>
                <span
                  className={classNames(
                    classes.spanTextFormat,
                    classes.commonFormat
                  )}
                >
                  {suitability}
                </span>
              </Grid>

              <Grid item xs={12} lg={3}>
                <Typography
                  className={classNames(
                    classes.typograhyFormat,
                    classes.commonFormat
                  )}
                >
                  PRE- ARRIVAL
                </Typography>
                <Items items={preArrival} />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Typography
                  className={classNames(
                    classes.typograhyFormat,
                    classes.commonFormat
                  )}
                >
                  POST- ARRIVAL
                </Typography>
                <Items items={postArrival} />
              </Grid>
            </Grid>
            {/* this is the 2nd row which has "Langange" + "Been a Local For" + "Tours" + "Experince and Intersts" */}
            <Grid container item xs={12} lg={12} spacing={2}>
              <Grid item xs={12} lg={3}>
                <Typography
                  className={classNames(
                    classes.typograhyFormat,
                    classes.commonFormat
                  )}
                >
                  LANGUAGES
                </Typography>
                <span
                  className={classNames(
                    classes.spanTextFormat,
                    classes.commonFormat
                  )}
                >
                  {Languages}
                </span>
                {/* <img src={Flag} /> */}
              </Grid>
              <Grid item xs={12} lg={3}>
                <Typography
                  className={classNames(
                    classes.typograhyFormat,
                    classes.commonFormat
                  )}
                >
                  BEEN A LOCAL FOR
                </Typography>
                <span
                  className={classNames(
                    classes.spanTextFormat,
                    classes.commonFormat
                  )}
                >
                  {local + " years"}
                </span>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Typography
                  className={classNames(
                    classes.typograhyFormat,
                    classes.commonFormat
                  )}
                >
                  TOURS
                </Typography>
                <Items items={tours} />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Typography
                  className={classNames(
                    classes.typograhyFormat,
                    classes.commonFormat
                  )}
                >
                  EXPERTISE/INTERESTS
                </Typography>
                <Items items={expertiseAndintersts} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={12} lg={4} spacing={2}>
          <div className={classes.calendarFormat}>{calendarCard}</div>
        </Grid>

        {/* secondLine of items */}

        <Grid container item xs={12} spacing={1}>
          <Grid container item sm={6} xs={12}>
            <Grid container item xs={12}>
              <div className={classNames(classes.root, classes.commonFormat)} />
            </Grid>
            <Grid container item xs={12}>
              <div className={classNames(classes.root, classes.commonFormat)} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div>
        {isHost ? (
          <Grid container item>
            <Grid
              container
              item
              xs={12}
              justify="flex-start"
              alignItems="center"
            >
              <div
                className={classNames(
                  classes.typograhyFormat,
                  classes.commonFormat
                )}
              >
                PRICE
              </div>
              <Button
                className={classNames(
                  classes.editPriceBtn,
                  classes.commonFormat
                )}
                color="primary"
                variant="contained"
              >
                Edit Pricing
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container item xs={12} lg={12} spacing={2}>
            <Grid item xs={12} lg={3}>
              <Typography
                className={classNames(
                  classes.typograhyFormat,
                  classes.commonFormat
                )}
              >
                ACTIVITIES
              </Typography>
              <Items items={activities} />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Typography
                className={classNames(
                  classes.typograhyFormat,
                  classes.commonFormat
                )}
              >
                CHILD MINDING SERVICES
              </Typography>
              <Grid item xs={12} spacing={2}>
                <Items items={childServices} />
              </Grid>
            </Grid>
            <Grid
              container
              direction="column"
              justify="space-between"
              alignItems="flex-start"
              item
              xs={10}
              md={6}
              lg={3}
              spacing={1}
            >
              <Typography
                className={classNames(
                  classes.typograhyFormat,
                  classes.commonFormat
                )}
              >
                PRICES
              </Typography>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
                item
              >
                <Grid item xs={6}>
                  <span
                    className={classNames(
                      classes.spanTextFormat,
                      classes.commonFormat
                    )}
                  >
                    Max Group Size
                  </span>
                </Grid>
                <Grid item xs={2}>
                  <div className={classes.priceContainer}>$ {prices[0]}</div>
                </Grid>
                <Grid item xs={4}>
                  <span
                    className={classNames(
                      classes.spanElementFormat,
                      classes.commonFormat
                    )}
                  >
                    per group
                  </span>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
                item
              >
                <Grid item xs={6}>
                  <span
                    className={classNames(
                      classes.spanTextFormat,
                      classes.commonFormat
                    )}
                  >
                    Price Per Head
                  </span>
                </Grid>
                <Grid item xs={2}>
                  <div className={classes.priceContainer}>$ {prices[1]}</div>
                </Grid>
                <Grid item xs={4}>
                  <span
                    className={classNames(
                      classes.spanElementFormat,
                      classes.commonFormat
                    )}
                  >
                    per guest
                  </span>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
                item
              >
                <Grid item xs={6}>
                  <span
                    className={classNames(
                      classes.spanTextFormat,
                      classes.commonFormat
                    )}
                  >
                    Additional Guests
                  </span>
                </Grid>
                <Grid item xs={2}>
                  <div className={classes.priceContainer}>$ {prices[2]}</div>
                </Grid>
                <Grid item xs={4}>
                  <span
                    className={classNames(
                      classes.spanElementFormat,
                      classes.commonFormat
                    )}
                  >
                    per guest
                  </span>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
                item
              >
                <Grid item xs={6}>
                  <span
                    className={classNames(
                      classes.spanTextFormat,
                      classes.commonFormat
                    )}
                  >
                    Pre-Arrival Activities
                  </span>
                </Grid>
                <Grid item xs={2}>
                  <div className={classes.priceContainer}>$ {prices[3]}</div>
                </Grid>
                <Grid item xs={4}>
                  <span
                    className={classNames(
                      classes.spanElementFormat,
                      classes.commonFormat
                    )}
                  >
                    per hour
                  </span>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
                item
              >
                <Grid item xs={6}>
                  <span
                    className={classNames(
                      classes.spanTextFormat,
                      classes.commonFormat
                    )}
                  >
                    Post Activities
                  </span>
                </Grid>
                <Grid item xs={2}>
                  <div className={classes.priceContainer}>$ {prices[4]}</div>
                </Grid>
                <Grid item xs={4}>
                  <span
                    className={classNames(
                      classes.spanElementFormat,
                      classes.commonFormat
                    )}
                  >
                    per hour
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>

      {isHost ? (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <Services
              onSubmit={handleOnSubmit}
              isHost={true}
              alignItems="left"
              fromHostIntroSection={true}
            />
          </Grid>
        </Grid>
      ) : null}
      <div
        className={classNames(
          classes.smallSectionHeading,
          classes.commonFormat
        )}
      >
        <div
          style={{ overflowX: "scroll", border: "1px solid red" }}
          className={classes.grantCalendarFormat}
        >
          {/* <GanttCalendar groups={groups} items={items} startdate={startdate} /> */}
          <GanttCalendar
            items={items}
            itemRenderer={itemRenderer}
            groups={groups}
            startdate={startdate}
            handleItemMove={handleItemMove}
            handleItemResize={handleItemResize}
          />
        </div>
      </div>

      {/* {calendar} */}
    </div>
  );
};

export default HostIntroSection;
