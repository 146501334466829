export const baseURL = "";

// AWS S3 Actions
export const SAVE_IMAGE_URL_TO_STORE = "SAVE_IMAGE_URL_TO_STORE";
export const SAVE_CERTIFICATE_URL_TO_STORE = "SAVE_CERTIFICATE_URL_TO_STORE";

// section actions
export const OPEN_SECTION = "OPEN_SECTION";
export const CLOSE_SECTION = "CLOSE_SECTION";
export const SAVE_SECTION = "SAVE_SECTION";

export const BOOKING_EMAIL_SENT = "BOOKING_EMAIL_SENT";

// trip - where actions
export const SELECT_RESORT = "SELECT_RESORT";
export const SELECT_RESORT_ID = "SELECT_RESORT_ID";
export const SET_COUNTRIES_AND_RESORTS = "SET_COUNTRIES_AND_RESORTS";
export const UPDATE_COUNTRIES = "UPDATE_COUNTRIES";
export const SELECT_COUNTRY = "SELECT_COUNTRY";
export const SHOW_RESORT_ERRORS = "SHOW_RESORT_ERRORS";
export const UPDATE_RESORT_ERRORS = "UPDATE_RESORT_ERRORS";

// trip - when actions
export const CHANGE_TRIP_START_DATE = "CHANGE_START_DATE";
export const CHANGE_TRIP_END_DATE = "CHANGE_END_DATE";

// trip - who actions
export const REMOVE_TRIP_MEMBER = "REMOVE_TRIP_MEMBER";
export const ADD_TRIP_MEMBER = "ADD_TRIP_MEMBER";
export const CREATE_MEMBER = "CREATE_MEMBER";
export const UPDATE_MEMBER = "UPDATE_MEMBER";
export const DELETE_MEMBER = "DELETE_MEMBER";
export const CREATE_MASTER = "CREATE_MASTER";
export const UPDATE_MASTER = "UPDATE_MASTER";
export const LOAD_GROUP_MEMBERS = "LOAD_GROUP_MEMBERS";
export const UPDATE_MAN_LESSON = "UPDATE_MAN_LESSON";
export const DELETE_MAN_LESSON = "DELETE_MAN_LESSON";

// Stepper - Stepper actions for signup
export const INCREMENT_STEPPER = "INCREMENT_STEPPER";
export const DECREMENT_STEPPER = "DECREMENT_STEPPER";
export const INCREMENT_STEPPER_HOST = "INCREMENT_STEPPER_HOST";
export const DECREMENT_STEPPER_HOST = "DECREMENT_STEPPER_HOST";

// Signup
export const SEND_INSTRUCTOR_SPECIALITY_VALUES =
  "SEND_INSTRUCTOR_SPECIALITY_VALUES";

// trip - lesson actions
export const SHOW_GRO_LES_ADD = "SHOW_GRO_LES_ADD";
export const LOAD_PRIVATE_LESSONS = "LOAD_PRIVATE_LESSONS";
export const LOAD_GROUP_LESSONS = "LOAD_GROUP_LESSONS";

export const CREATE_TRIP_LESSONS = "CREATE_TRIP_LESSONS";
export const DELETE_LESSON = "DELETE_LESSON";
export const EDIT_LESSON = "EDIT_LESSON";

export const CHANGE_COMMENT = "CHANGE_COMMENT";

export const MAN_DATE_CHANGE = "MAN_DATE_CHANGE";
export const MAN_ADD_MEMBER = "MAN_ADD_MEMBER";
export const MAN_REMOVE_MEMBER = "MAN_REMOVE_MEMBER";
export const MAN_DELETE_LESSON = "MAN_DELETE_LESSON";
export const MAN_CREATE_LESSON = "MAN_CREATE_LESSON";

export const GRO_DATE_CHANGE = "GRO_DATE_CHANGE";
export const GRO_REMOVE_MEMBER = "GRO_REMOVE_MEMBER";
export const GRO_CREATE_LESSON = "GRO_CREATE_LESSON";
export const SWITCH_LESSON_TABS = "SWITCH_LESSON_TABS";

export const PRI_DATE_CHANGE = "PRI_DATE_CHANGE";
export const PRI_REMOVE_MEMBER = "PRI_REMOVE_MEMBER";

export const ADA_DATE_CHANGE = "ADA_DATE_CHANGE";
export const ADA_REMOVE_MEMBER = "ADA_REMOVE_MEMBER";

export const SHOW_LESSON_ERRORS = "SHOW_LESSON_ERRORS";
export const UPDATE_LESSON_ERRORS = "UPDATE_LESSON_ERRORS";
export const CHANGE_NEW_LESSON_EFFECT = "CHANGE_NEW_LESSON_EFFECT";
// book steps

export const SAVE_EQUIP_TO_STORE = "SAVE_EQUIP_TO_STORE";

export const MEMBER_ID_RETRIEVED = "MEMBER_ID_RETRIEVED";
export const GROUP_MEMBER_INFO_RETRIEVED = "GROUP_MEMBER_INFO_RETRIEVED";

// activity actions

export const EDIT_MEMBER_IN_ACTIVITY = "EDIT_MEMBER_IN_ACTIVITY";
export const REMOVE_MEMBER_FROM_ACTIVITIES = "REMOVE_MEMBER_FROM_ACTIVITIES";
export const SHOW_ACTIVITY_ERRORS = "SHOW_ACTIVITY_ERRORS";
export const UPDATE_ACTIVITY_ERRORS = "UPDATE_ACTIVITY_ERRORS";

// profile actions
export const LOAD_PROFILE = "LOAD_PROFILE";

// contact us page
export const CONTACT_US_EMAIL_SENT = "CONTACT_US_EMAIL_SENT";

// alert box in submit form
export const ALERT_SUBMIT_FORM = "ALERT_SUBMIT_FORM";

// auth
export const LOAD_USER = "LOAD_USER";

// instructor data
export const TOGGLE_INSTRUCTOR_LIKE = "TOGGLE_INSTRUCTOR_LIKE";
export const SAVE_INSTRUCTOR_STATE = "SAVE_INSTRUCTOR_STATE";
export const ADJUST_UNAVAILABILITY = "ADJUST_UNAVAILABILITY";

// send host name data
export const SEND_HOST_NAME = "SEND_HOST_NAME";
export const TOGGLE_HOST_LIKE = "TOGGLE_HOST_LIKE";

// warning for first-timers who have not registered a lesson
export const FISRT_TIMER_NO_LESSON_WARNING = "FISRT_TIMER_NO_LESSON_WARNING";

// virtual instructor Like Preference
export const TOGGLE_VIRTUAL_INSTRUCTOR_LIKE = "TOGGLE_INSTRUCTOR_LIKE";

//create booking for virtual instructor
export const CREATE_VIRT_BOOKING = "CREATE_VIRT_BOOKING";
export const MODIFY_VIRT_BOOKING = "MODIFY_VIRT_BOOKING";

//signup actions
export const ADD_RESORT = "ADD_RESORT";
export const REMOVE_RESORT = "REMOVE_RESORT";

//Payment Acions
export const ADD_NEW_PAYMENT_METHOD = "ADD_NEW_PAYMENT_METHOD";

//chatpage actions
export const LOAD_MESSAGE_ROOM_INFO = "LOAD_MESSAGE_ROOM_INFO";
export const LOAD_INSTRUCTOR_INFO = "LOAD_INSTRUCTOR_INFO";
export const LOAD_STUDENT_INFO = "LOAD_STUDENT_INFO";
export const LOAD_VIRTUAL_BOOKING_INFO = "LOAD_VIRTUAL_BOOKING_INFO";

//display video lessons
export const DISPLAY_UPCOMING_LESSON = "DISPLAY_UPCOMING_LESSONS";
export const DISPLAY_PAST_LESSON = "DISPLAY_PAST_LESSONS";

// Visitor Profile Visitor View Actions
export const UPDATE_AGE = "UPDATE_AGE";
export const UPDATE_GENDER = "UPDATE_GENDER";
export const UPDATE_COUNTRY = "UPDATE_COUNTRY";
export const UPDATE_LANGUAGES = "UPDATE_LANGUAGES";
export const UPDATE_ABILITY = "UPDATE_ABILITY";

//host new profile action
export const UPDATE_AVATAR = "UPDATE_AVATAR";
export const UPDATE_NAME = "UPDATE_NAME";
export const UPDATE_RATING = "UPDATE_RATING";
export const UPDATE_BIO = "UPDATE_BIO";

// Instructor Instructor NewProfileAction Actions
export const UPDATE_SPORT = "UPDATE_SPORT";
export const UPDATE_SPECIALISATIONS = "UPDATE_SPECIALISATIONS";
export const UPDATE_QUALIFICATIONS = "UPDATE_QUALIFICATIONS";
export const UPDATE_INS_PROFILE = "UPDATE_INS_PROFILE";

// Student Profile Actions
export const UPDATE_PHYSICAL_DISABILITY = "UPDATE_PHYSICAL_DISABILITY";
export const UPDATE_YEARS_OF_EXPERIENCE = "UPDATE_YEARS_OF_EXPERIENCE";
export const UPDATE_STU_PROFILE = "UPDATE_STU_PROFILE";

// Host Profile Actions
export const UPDATE_RESORTS = "UPDATE_RESORTS";
export const UPDATE_SUITABILITY = "UPDATE_SUITABILITY";
export const UPDATE_PRE_ARRIVAL = "UPDATE_PRE_ARRIVAL";
export const UPDATE_POST_ARRIVAL = "UPDATE_POST_ARRIVAL";
export const UPDATE_BEEN_A_LOCAL_FOR = "UPDATE_BEEN_A_LOCAL_FOR";
export const UPDATE_EXPERTISE_INTERESTS = "UPDATE_EXPERTISE_INTERESTS";
export const UPDATE_ACTIVITIES = "UPDATE_ACTIVITIES";
export const UPDATE_CHILD_MINDING_SERVICES = "UPDATE_CHILD_MINDING_SERVICES";
export const UPDATE_PRICES = "UPDATE_PRICES";
export const UPDATE_LOCAL = "UPDATE_LOCAL";
export const UPDATE_TOURS = "UPDATE_TOURS";
export const UPDATE_INTERSTS = "UPDATE_INTERSTS";
export const UPDATE_STARTING_PRICE = "UPDATE_STARTING_PRICE";

// Timeslots Actions
export const ADD_TIMESLOT = "ADD_TIMESLOT";
export const UPDATE_TIMESLOT = "UPDATE_TIMESLOT";
export const DELETE_TIMESLOT = "DELETE_TIMESLOT";
export const UPDATE_ERRORS_TIMESLOT = "UPDATE_ERRORS_TIMESLOT";

// Virtual lesson pricing Actions
export const ADD_RATE = "ADD_RATE";
export const REMOVE_RATE = "REMOVE_RATE";
// Reasons Action
export const UPDATE_REASONS = "UPDATE_REASONS";

// Add files during signup procedure
export const ADD_PROFILE_IMAGE = "ADD_PROFILE_IMAGE";
export const STORE_IMAGE_UPLOAD_EVENT = "STORE_IMAGE_UPLOAD_EVENT";
export const ADD_QUALIFICATION = "ADD_QUALIFICATION";
export const STORE_QUALIFICATION_UPLOAD_EVENT =
  "STORE_QUALIFICATION_UPLOAD_EVENT";
// MyAccount Actions
export const EDIT_NAME = "EDIT_NAME";
export const EDIT_BIO = "EDIT_BIO";
export const EDIT_COUNTRYCODE = "EDIT_COUNTRYCODE";
export const EDIT_PHONENUMBER = "EDIT_PHONENUMBER";
export const EDIT_EMAIL = "EDIT_EMAIL";
export const EDIT_PASSWORD = "EDIT_PASSWORD";
export const EDIT_GENDER = "EDIT_GENDER";
export const EDIT_EXPERIENCE = "EDIT_EXPERIENCE";
export const EDIT_AGE = "EDIT_AGE";
export const EDIT_EXPERIENCE_INS = "EDIT_EXPERIENCE_INS";
export const EDIT_EXPERIENCE_LOCAL = "EDIT_EXPERIENCE_LOCAL";
export const EDIT_LANGUAGES = "EDIT_LANGUAGES";
export const EDIT_ABILITIES = "EDIT_ABILITIES";
export const EDIT_DISABILITIES = "EDIT_DISABILITIES";
export const EDIT_SPORT_SPECIAL = "EDIT_SPORT_SPECIAL";
export const EDIT_PRE_LANGUAGE = "EDIT_PRE_LANGUAGE";
export const EDIT_PRE_CURRENCY = "EDIT_CURRENCY";
export const EDIT_TIMEZONE = "EDIT_TIMEZONE";
export const EDIT_QUALIFICATIONS = "EDIT_QUALIFICATIONS";
export const EDIT_RESORTS = "EDIT_RESORTS";
export const EDIT_COUNTRY = "EDIT_COUNTRY";
export const EDIT_SPECIALIATIES = "EDIT_SPECIALIATIES";
export const EDIT_TARGET_AUDIENCE = "EDIT_TARGET_AUDIENCE";

export const GET_USER_PROFILE_REQUEST = "GET_USER_PROFILE_REQUEST";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAILURE = "GET_USER_PROFILE_FAILURE";

export const GET_PROVIDER_PROFILE_REQUEST = "GET_PROVIDER_PROFILE_REQUEST";
export const GET_PROVIDER_PROFILE_SUCCESS = "GET_PROVIDER_PROFILE_SUCCESS";
export const GET_PROVIDER_PROFILE_FAILURE = "GET_PROVIDER_PROFILE_FAILURE";

export const LOAD_USER_DETAILS = "LOAD_USER_DETAILS";
export const LOAD_USER_DETAILS_FAILURE = "LOAD_USER_DETAILS_FAILURE";

export const UPDATE_USER_DETAILS_SUCCESS_REQUEST =
  "UPDATE_USER_DETAILS_SUCCESS_REQUEST";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILURE = "UPDATE_USER_DETAILS_FAILURE";

export const UPDATE_USER_PROFILE_SUCCESS_REQUEST =
  "UPDATE_USER_PROFILE_SUCCESS_REQUEST";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAILURE = "UPDATE_USER_PROFILE_FAILURE";

export const UPDATE_PROVIDER_PROFILE_SUCCESS_REQUEST =
  "UPDATE_PROVIDER_PROFILE_SUCCESS_REQUEST";
export const UPDATE_PROVIDER_PROFILE_SUCCESS = "UPDATE_PROVIDER_PROFILE_SUCCESS";
export const UPDATE_PROVIDER_PROFILE_FAILURE = "UPDATE_PROVIDER_PROFILE_FAILURE";

//Home Page Action
export const GET_COUNTRY_DATA = "GET_COUNTRY_DATA";
export const GET_RESORT_DATA = "GET_RESORT_DATA";
export const GET_LANGUAGE_DATA = "GET_LANGUAGE_DATA";
export const GET_SUITABLE_FOR_DATA = "GET_SUITABLE_FOR_DATA";
export const GET_SERVICES_DATA = "GET_SERVICES_DATA";
export const GET_PROVIDER_DATA = "GET_PROVIDER_DATA";

//UserActivation
export const GET_ACTIVATION_STATUS = "GET_ACTIVATION_STATUS";
export const GET_ERROR_MESSAGE = "GET_ERROR_MESSAGE";

//Login
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export const GET_PROFILE_DATA = "GET_PROFILE_DATA";
export const INCREMENT_STEPPER_MANUL = "INCREMENT_STEPPER_MANUL";

export const CREATE_PROVIDER_SERVICES = "CREATE_PROVIDER_SERVICES";
export const UPDATE_PROVIDER_SERVICES = "UPDATE_PROVIDER_SERVICES";
