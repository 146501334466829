import React from "react";
import useStyles from "./MyAccountStyles";
import { Button, Grid } from "@material-ui/core";

import "../../../styling/css/template/SliderBar.css";

const Ability = ({ journey }) => {
  const classes = useStyles();

  const [sliderValue, setSliderValue] = React.useState(1);
  const [sliderValue2, setSliderValue2] = React.useState(1);

  const handleSliderChange = event => {
    setSliderValue(event.target.value);
  };

  const handleSliderChange2 = event => {
    console.log("trigger", event.target.value);
    setSliderValue2(event.target.value);
  };

  return journey === "vl-ins" ? null : (
    <div>
      <Grid item container md={12} direction="row">
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <div align="right" className={classes.textFieldStyle}>
            Ability:
          </div>
        </Grid>
        <Grid item lg={1} md={1} sm={1} xs={1} />
        <Grid item container direction="row" lg={9} md={9} sm={9} xs={9}>
          {/* snowboarding  */}
          <Grid item container lg={12} md={12} sm={12} xs={12}>
            <Grid item lg={1} md={2} sm={3} xs={2}>
              <div align="right" className={classes.slider}>
                Snowboarding:
              </div>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={10} />
            <Grid item lg={9} md={8} sm={7} xs={11} align="left">
              <div className="col">
                <input
                  type="range"
                  className="color-slider-bar"
                  min={1}
                  max={7}
                  step={1}
                  value={sliderValue}
                  onChange={handleSliderChange}
                />
              </div>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={1} align="center">
              <div>{sliderValue}</div>
            </Grid>
          </Grid>
          <Grid item container lg={12} md={12} sm={12} xs={12} direction="row">
            <div className={classes.emptyCard3} />
          </Grid>
          {/* ski */}
          <Grid item container lg={12} md={12} sm={12} xs={12}>
            <Grid item lg={1} md={2} sm={3} xs={2}>
              <div align="right" className={classes.slider}>
                Ski:
              </div>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={10} />
            <Grid item lg={9} md={8} sm={7} xs={11} align="left">
              <div className="col">
                <input
                  type="range"
                  className="color-slider-bar"
                  min={1}
                  max={7}
                  step={1}
                  value={sliderValue2}
                  onChange={handleSliderChange2}
                />
              </div>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={1} align="center">
              <div>{sliderValue2}</div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container md={12} direction="row">
        <div className={classes.emptyCard3} />
      </Grid>
    </div>
  );
};

export default Ability;
