import { Component } from "react";
import { connect } from "react-redux";
import React from "react";
import {
  editMemberInActivity,
  removeMemberFromActivities,
  showActivityErrors,
  updateActivityErrors
} from "../../../../redux/actions/activityActions";
import _ from "lodash";
import ActivityMemberMultiSelect from "./ActivityMemberMultiSelect";
import { SectionBodyWrapper } from "../../../../styling/styled-components/sectionWrapper";
import {
  VerticalSpaceSM,
  VerticalSpaceMD,
  SectionTitle,
  NextButton
} from "../../../../styling/styled-components/index";

const SELECT_CONTAINER_WIDTH = "100%";

const styles = {
  input: {},

  label: {
    display: "block",
    textAlign: "left"
  },
  selectGroupContainer: {
    display: "flex",
    flexWrap: "wrap",
    margin: "auto"
  },

  select: {
    padding: 0,
    textAlign: "left",
    width: SELECT_CONTAINER_WIDTH
  },
  selectContainer: {
    marginBottom: 50,
    width: SELECT_CONTAINER_WIDTH
  }
};

// activities: array of arrays, e.g. [Ski, Snowboard] from redux
export function validateActivity(activities) {
  for (let i = 0; i < activities.length; i++) {
    if (activities[i].length !== 0) {
      return {};
    }
  }
  return { noActivity: "Please add at least 1 member to an activity" };
}

class ActivitySection extends Component {
  // Initialize the state
  state = {
    open: {
      ski: false,
      snowboard: false
    }
  };

  //TODO use TripWhoAction instead
  componentDidUpdate(prevProps, prevState, snapshot) {
    // check if members have been removed from tripMembers
    const difference = _.difference(
      prevProps.tripMembers,
      this.props.tripMembers
    );
    // difference found, remove from activities
    if (difference.length > 0) {
      _.forEach(difference, uuid => {
        this.props.removeMemberFromActivities(uuid);
      });
    }
  }

  // Two functions to handle multi select form

  handleOpenMultiSelect = activity => {
    const newOpen = { ...this.state.open };
    newOpen[activity] = true;
    this.setState({ ...this.state, open: newOpen });
  };

  handleCloseMultiSelect = activity => {
    const newOpen = { ...this.state.open };
    newOpen[activity] = false;
    this.setState({ ...this.state, open: newOpen });
  };

  // --------------------------------------

  handleSelect = (e, activity) => {
    const { name, value } = e.target;
    if (value.includes("addAll")) {
      this.props.editMemberInActivity({ name, value: this.props.tripMembers });
    } else if (value.includes("close")) {
      this.handleCloseMultiSelect(activity);
    } else {
      this.props.editMemberInActivity({ name, value });
    }
  };

  handleNext = () => {
    const activitiesToValidate = [
      this.props.activityReducer.main.ski,
      this.props.activityReducer.main.snowboard
    ];
    const errors = validateActivity(activitiesToValidate);

    if (Object.keys(errors).length === 0) {
      this.props.onNext();
    } else {
      this.props.updateActivityErrors(errors);
      this.props.showActivityErrors(true);
    }
  };

  // mainActivityKeys = Object.keys(this.props.activities.main);
  mainActivityKeys = ["ski", "snowboard"];
  //otherActivityKeys = Object.keys(this.props.activities.other);

  render() {
    const { activityValidated, activityErrors } = this.props;
    return (
      <SectionBodyWrapper>
        <VerticalSpaceSM />
        <SectionTitle>Who's skiing? Who's snowboarding?</SectionTitle>
        {activityValidated === true &&
          Object.keys(
            validateActivity([
              this.props.activityReducer.main.ski,
              this.props.activityReducer.main.snowboard
            ])
          ).length !== 0 && (
            <div className="div-error">{activityErrors.noActivity}</div>
          )}

        {/*<h6 style={styles.title}>Main snow activities</h6>*/}
        <VerticalSpaceMD />

        <div
          className="row justify-content-between"
          style={styles.selectGroupContainer}
        >
          {this.mainActivityKeys.map((activity, index) => {
            return (
              <div key={index} className="col-sm-12 col-md-6">
                <ActivityMemberMultiSelect
                  open={this.state.open[activity]}
                  key={activity}
                  activity={activity}
                  onChange={this.handleSelect}
                  onClose={() => this.handleCloseMultiSelect(activity)}
                  onOpen={() => this.handleOpenMultiSelect(activity)}
                  activities={this.props.activityReducer.main}
                />
                <VerticalSpaceMD />
              </div>
            );
          })}
        </div>
        {/*<hr />*/}
        {/*<h6 style={styles.title}>Other snow activities</h6>*/}
        {/*<hr />*/}
        {/*<div*/}
        {/*  className="row justify-content-between"*/}
        {/*  style={styles.selectGroupContainer}*/}
        {/*>*/}
        {/*  {this.otherActivityKeys.map(activity => {*/}
        {/*    return (*/}
        {/*      <ActivityMemberMultiSelect*/}
        {/*        key={activity}*/}
        {/*        activity={activity}*/}
        {/*        onChange={this.handleSelect}*/}
        {/*        activities={this.props.activities.other}*/}
        {/*      />*/}
        {/*    );*/}
        {/*  })}*/}
        {/*</div>*/}

        <div style={{ textAlign: "right" }}>
          <NextButton onClick={this.handleNext}>Next</NextButton>
        </div>
      </SectionBodyWrapper>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    editMemberInActivity: target => dispatch(editMemberInActivity(target)),
    removeMemberFromActivities: uuid =>
      dispatch(removeMemberFromActivities(uuid)),
    showActivityErrors: show => dispatch(showActivityErrors(show)),
    updateActivityErrors: errors => dispatch(updateActivityErrors(errors))
  };
}

function mapStateToProps(state) {
  return {
    tripMembers: state.tripWhoReducer.tripMembers,
    activityReducer: state.activityReducer,
    activityErrors: state.activityReducer.activityErrors,
    activityValidated: state.activityReducer.activityValidated
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivitySection);
