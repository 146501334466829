import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import { Typography, CardActionArea } from "@material-ui/core";

const useStyles = makeStyles({
  cardRoot: {
    minWidth: "391px",
    minHeight: "238px",
    background: "#FFFFFF",
    boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)"
  },
  media: {
    position: "relative",
    width: 64,
    height: 68
  },
  cardTitle: {
    paddingTop: 10,
    textAlign: "center",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "140.62%"
  },
  cardAction: { minHeight: 238 }
});

const AccountCard = props => {
  const classes = useStyles();

  return (
    <div>
      <Card className={classes.cardRoot}>
        <CardActionArea className={classes.cardAction}>
          <CardContent>
            <img src={props.src} alt="account card" className={classes.media} />
            <Typography className={classes.cardTitle}>{props.title}</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default AccountCard;
