import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { incrementActiveStep, decrementActiveStep, getProviderProfile, setProviderServices } from "../../../redux/actions/stepperActions";
import { updateProviderProfile } from "../../../redux/actions/providerProfileAction";

// Styles
import useStyles from "../../../components/Common/SignupForm/ClientInfoStyles";

//APIs
import agent from "../../../agent";

//Material UI
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//Components
import Services from "../../../components/Common/SignupForm/ProviderInfo/Services/Services";

const useClasses = makeStyles(theme => ({
  myProfileLabel: {
    width: "100%",
    fontSize: "x-large",
    width: "50%",
    margin: "3rem 0",
    fontFamily: "PT Sans Narrow",
    paddingLeft: "1rem"
  },
  headerLabel: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "26px",
    color: "#000000"
  },
  btnDisabled: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 16,
    marginTop: 8,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14
    },
    color: "#BDBDBD",
    border: "none",
    backgroundColor: "transparent",
    padding: 0
  },
  imageGalleryTitle: {
    width: "50%",
    marginTop: "3rem"
  },
  uploadBtn: {
    width: "50%",
    marginTop: "3rem",
    justifyContent: "end"
  },
  sectionMargin: {
    margin: "2rem 0",
    width: "100%"
  },
  btnEdit: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 16,
    marginTop: 8,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14
    },
    color: "#1277B8",
    border: "none",
    backgroundColor: "transparent",
    padding: 0
  },
  saveButton: {
    // marginTop: "3em",
    width: "102px",
    height: "2.5em",
    borderRadius: 100,
    textAlign: "center",
    backgroundColor: "rgb(255,97,97)",
    marginLeft: "1em"
  },
  cancelButtonLabel: {
    color: "#495057",
    fontWeight: 700,
    letterSpacing: "1%",
    fontFamily: "PT Sans"
  },
  nextButton: {
    width: "102px",
    height: "2.5em",
    borderRadius: 100,
    textAlign: "center"
  },
  buttonRoot: {
    backgroundColor: "#FF6161"
  },
  buttonLabel: {
    color: "white",
    fontWeight: 700,
    letterSpacing: "1%",
    fontFamily: "PT Sans"
  },
  centerBtns: {
    margin: "2rem 0",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-around",
      alignItems: "center"
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
      alignItems: "center"
    }
  },
  backButton: {
    width: "60%",
    marginRight: "1em",
    fontFamily: "PT Sans",
    border: "white"
  },
  backButtonLabel: {
    color: "#FF6161",
    fontWeight: 700,
    letterSpacing: "1%",
    fontFamily: "PT Sans"
  }
}));

function ProviderServices(props) {
  const classes = useClasses();
  const styles = useStyles();

  const { userProfile } = props;
  const [providerId, setProviderId] = useState(0);
  let [providedServices, setProvidedServices] = useState([{}]);
  let [updatedServices, setUpdatedServices] = useState({});
  const user_id = props.currentUser.user_id;

  let [isLoading, setIsLoading] = useState(true);

  // Update UI
  useEffect(() => {  
    setIsLoading(true);

    if (userProfile !== undefined){
      setProvidedServices(userProfile.providerServices);
      setProviderId(userProfile.id);
            
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [userProfile]);

  /**
   * This function is to append Files in JSON data into FormData.
   * 
   * @param {FormData} formData This is the formData needed to be appended data into.
   * @param {any} data This is the JSON object requiring appended into formData.
   * @param {any} parentKey This is optional for nested JSON object only.
   */
  function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        buildFormData(formData, data[key], key);
      });
    } else if (data && typeof data === 'object' && (data instanceof File)) {
      formData.append(parentKey, data);
    } else {
    }
  }
  
  /**
   * This function is to transform Files in JSON data to FormData.
   * 
   * @param {any} data This is the JSON object requiring appended into formData.
   */
  function jsonToFormData(data) {
    const formData = new FormData();
    buildFormData(formData, data);
    return formData;
  }

  const handleBackButton = event => {
    props.decrementActiveStep(decrementActiveStep());
  };

  const handleSubmit = async event => {
    event.preventDefault();

    // ------ Update services -------
    // Find out FILEs in the JSON object and append it to create new FormData as payload
    let postServiceFile = jsonToFormData(updatedServices)
    // Append all again into the FormData: postServiceFile
    postServiceFile.append('data',JSON.stringify(updatedServices))

    await props.updateProviderServices(providerId, postServiceFile);

    // ------ Update signup_step -------
    await agent.Users.updateSignupStep(user_id, props.activeStep + 1);

    props.getProviderProfile(user_id)
    props.incrementActiveStep()

  };

  return (
    <div>
    {isLoading ? (
      // loading...  
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={0}
        direction="column"
        className={styles.whiteBackgroudColor}
      >
        <Grid
          container
          justify="center"
          alignItems="flex-start"
          xs={12}
          sm={10}
          md={8}
          lg={6}
          spacing={2}
          direction="row"
          style={{ backgroundColor: "white", padding: "2rem 0px" }}
        >
          <div className="loader-container">
            <div className="loader" />
          </div>
        </Grid>
      </Grid>
    ) : (
    <form onSubmit={handleSubmit}>
      <Grid 
        container
        justify="center"
        alignItems="center"
        spacing={0}
        direction="column" 
        style={{ marginTop: "3rem"}}
      >
        
        {/* Offered Services and Pricing section */}
        <Grid 
          container 
          item
          xs={12}
          sm={10}
          md={8}
          lg={6}
          spacing={2}
          justify="center"
          alignItems="flex-start"
          direction="column"
        >
          <div className={classes.sectionMargin} >
            <Services 
              fromHostIntroSection={true}
              providedServices={providedServices}
              setProvidedServices={setProvidedServices}
              setUpdatedServices={setUpdatedServices}
              isDisabled={false}
              providerId={providerId}
            />
          </div> 
        </Grid>

        {/* Back and Next Button section */}
        <Grid
          container
          item
          xs={12}
          sm={10}
          md={8}
          lg={6}
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          className={classes.buttonContainer}
          style={{ padding: "3rem 1.5rem" }}
        >
          <Grid item>
            {props.activeStep < 1 ? null : (
              <Button
                className={classes.backButton}
                classes={{ label: classes.backButtonLabel }}
                variant="outlined"
                onClick={handleBackButton}
              >
                Back
              </Button>
            )}
          </Grid>

          <Grid item>
            <Button
              type="submit"
              className={classes.nextButton}
              classes={{
                label: classes.buttonLabel,
                root: classes.buttonRoot
              }}
              variant="contained"
            >
              Next
            </Button>
          </Grid>
        </Grid>

      </Grid>
    </form>
    )}
    </div>
  );
}

// get current user data from store
function mapStateToProps(state) {
  return {
  currentUser: state.auth.currentUser,
  userProfile: state.stepperReducer.userProfile,
  activeStep: state.stepperReducer.activeStep,
  };
}

// event or function are used to update existing values in Store
const mapDispatchToProps = dispatch => ({
  incrementActiveStep: () => dispatch(incrementActiveStep()),
  decrementActiveStep: () => dispatch(decrementActiveStep()),
  getProviderProfile: userId => dispatch(getProviderProfile(userId)),
  updateProviderProfile: provider => dispatch(updateProviderProfile(provider)),
  updateProviderServices: (provider_id, services) => dispatch(setProviderServices(provider_id, services))
});

ProviderServices = reduxForm({
    form: "signup",
    destroyOnUnmount: false,
    enableReinitialize: true,
})(ProviderServices);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderServices);