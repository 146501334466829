import agent from "../../../../../agent";
import _ from "lodash";

const BaseURL = "/api/";

//opening dates: NH = 15 Nov - 15 April; SH = 15 June - 10 October
function getOpeningDatesByLatitude(latitude) {
  if (parseFloat(latitude) > 0) {
    return {
      north: true,
      startDate: "11/15/",
      endDate: "4/15/"
    };
  } else {
    return {
      north: false,
      startDate: "6/15/",
      endDate: "10/10/"
    };
  }
}

// This function is not consistent with Dev
// Correct Version: Dev
// Wrong Version: Master
function isInOpeningDates(startDate, endDate, openingDates) {
  const startDateYear = startDate.getFullYear();
  const endDateYear = startDate.getFullYear();
  let openingStartDate = new Date(
    openingDates.startDate + startDateYear.toString()
  );
  let openingEndDate = new Date(openingDates.endDate + endDateYear.toString());

  if (openingDates.north) {
    if (startDate.getFullYear() !== endDate.getFullYear()) {
      openingEndDate = new Date(
        openingDates.endDate + (startDateYear + 1).toString()
      );
    } else {
      openingStartDate = new Date(
        openingDates.startDate + (startDateYear - 1).toString()
      );
    }
  } else {
    openingEndDate = new Date(openingDates.endDate + startDateYear.toString());
  }
  return startDate >= openingStartDate && endDate <= openingEndDate;
}

/**
 * get resorts and countires info
 * @returns {Obejct} resortsWithContinent, countryResortInfoDic, countries
 **/
export async function getCountriesAndResortsFromDatabase() {
  let resortsWithContinent = {},
    countryResortInfoDic = {},
    countries = [];
  await agent.Resorts.getCountriesAndResorts().then(response => {
    countryResortInfoDic = response.data.countryResortInfoDic;
    countries = _.map(countryResortInfoDic, (value, key) => key);
    // create object with resort as key and continent as value
    _.map(countryResortInfoDic, (value, key) => {
      value.resorts.map(resort => {
        resortsWithContinent[resort] = value.continent;
      });
    });
  });
  return { resortsWithContinent, countryResortInfoDic, countries };
}

/**
 * get resorts
 * @param {String} country
 * @param {Date} startDate
 * @param {Date} endDate
 *
 * @returns {Obejct} resortsWithContinent, countryResorts
 **/
export function getCountriesAndOpeningStatus(
  countryResortInfoDic,
  startDate,
  endDate
) {
  let countriesAndOpeningStatus = _.map(countryResortInfoDic, (value, key) => {
    return {
      name: key,
      notOpening: !isInOpeningDates(
        startDate,
        endDate,
        getOpeningDatesByLatitude(value.latitude)
      )
    };
  });
  return {
    countriesAndOpeningStatus: _.sortBy(countriesAndOpeningStatus, ["name"])
  };
}
