import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  buttonLabel: {
    padding: "0px 3px 0px 3px",
    color: "white",
    fontWeight: 500,
    fontSize: "18px"
  },
  outlinedButtonLabel: {
    padding: "0px 3px 0px 3px",
    color: "red",
    fontWeight: 500,
    fontSize: "18px"
  },
  containedPrimary: {
    backgroundColor: "#FF6161",
    padding: "0px 30px 0px 30px"
  },
  containedSecondary: {
    backgroundColor: "#FFFFFF",
    border: "1px red solid",
    padding: "0px 15px 0px 15px",
    boxShadow: "none",

    "&:hover": {
      backgroundColor: "#FFFFFF",
      boxShadow: "none"
    },
    "&:active": {
      boxShadow: "none",
      borderColor: "#005cbf"
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)"
    }
  }
}));

export default function StyledButton(props) {
  const classes = useStyles();
  const containedStyle = props.outlined
    ? classes.containedSecondary
    : classes.containedPrimary;

  const textStyle = props.outlined
    ? classes.outlinedButtonLabel
    : classes.buttonLabel;

  const bookOnClick = () => {
    //TODO: add real functionality here
  };

  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      align="center"
      onClick={props.onClick}
      style={{ ...props.style, borderRadius: 50 }}
      classes={{
        containedPrimary: containedStyle,
        label: textStyle
      }}
    >
      {props.displayText}
    </Button>
  );
}
