import React from "react";
import useStyles from "./MyAccountStyles";

import Grid from "@material-ui/core/Grid";

const Name = ({ studentData, _editName }) => {
  const classes = useStyles();

  return (
    <Grid item container md={12} direction="row">
      <Grid item lg={2} md={2} sm={2} xs={2}>
        <div align="right" className={classes.textFieldStyle}>
          Name:
        </div>
      </Grid>
      <Grid item lg={1} md={1} sm={1} xs={1} />
      <Grid item lg={6} md={6} sm={5} xs={7}>
        <div align="left">
          <input
            id="cardname"
            variant="filled"
            className={classes.textField}
            size="small"
            onChange={event => _editName(event.target.value)}
            value={studentData.name}
          />
        </div>
      </Grid>
      <Grid item lg={3} md={3} sm={4} xs={2} />
    </Grid>
  );
};

export default Name;
