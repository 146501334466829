import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DropdownIconButton from "./NavbarLogoDropdown";
import { withRouter, Link } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

import ServiceNavBar_img from "../../../materials/HomePage/ServiceNavBar_img.png";
// import API from MUI
import { IconButton, Typography, Menu } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";

import { userLogout } from "../../../redux/actions/loginAction";
import agent from "../../../agent";

//set the drop-down menus
const pages = ["How it works", "Behind the desks", "Contact Us"];
const avatarPages = [
  "Inbox",
  "My Bookings",
  "My Account",
  "Payment & Payouts",
  "Log Out",
  "Switch to Provider View"
];

const wrapper = {
  padding: "0.5%",
  "&:nth-child(2)": {
    width: "30%"
  }
};

//custom styling for header
const useStyles = makeStyles(theme => ({
  // common background of navbar of all pages except homepage
  link: {
    color: "inherit",
    textDecoration: "inherit"
  },
  headerWrapper: {
    ...wrapper,
    position: "relative",
    background:
      "linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2)) , var(--image-url) center center",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  // transparent background of navbar of homepage
  transparentHeaderWrapper: {
    ...wrapper,
    position: "absolute",
    left: 0,
    top: 0
  },
  logoWrapper: {
    // width: "55%",
    alignItems: "center",
    verticalAlign: "none"
  },
  menuButton: {
    dispatch: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginRight: theme.spacing(0.1),
    alignSelf: "center",
    color: "#fff",
    "&:hover": {
      color: "#000"
    }
  },
  menuIcon: {
    color: "#fff",
    fontSize: "2rem",
    display: "flex",
    alignItems: "center"

    /**
     * When desktop screen, menu icon doesn't show up
     * @todo Delete this setting, after developing /contact-me, /how-it-work etc.
     * @see TODOs in this file
     */
    //  [theme.breakpoints.up("sm")]: {
    //   display: "none"
    // }
  },

  menuIconSize: {
    fontSize: "2rem"
  },
  title: {
    flexGrow: 1
  },
  // when mobile screen, signup/login doesn't show up in the navbar
  signButton: {
    flexGrow: "0 ",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  // when mobile screen, signup/login doesn't show up in the dropdown menu
  mobileIcon: {
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  // contentWrapper: {
  //   width: "30%"
  // },
  navAnchorNormal: {
    color: "#ffffff",
    fontFamily: "PT Sans Narrow",
    fontWeight: "700",
    fontSize: 20,
    padding: "0rem 1rem",
    lineHeight: "128.91%",
    minWidth: "5%",
    letterSpacing: "0.02em",
    "&:hover": {
      cursor: "pointer",
      borderRadius: "10px",
      transitionDuration: "0.5s",
      color: "#000",
      padding: "0.7rem 1rem",
      decoration: "none",
      backgroundColor: "#fff",
      textDecoration: "none"
    }
  },
  navAnchor: {
    color: "black",
    fontWeight: "bold",
    fontSize: 15,
    padding: "0rem 3rem",
    lineHeight: "128.91%",
    minWidth: "20%",
    letterSpacing: "0.02em",
    "&:hover": {
      color: "grey",
      cursor: "pointer"
    }
  },
  [theme.breakpoints.down("xs")]: {
    logoWrapper: {
      width: "100%"
    }
  }
}));

/** This is the component of Navigation Bar.
 *
 * @param {*} props
 * @returns
 * @todo Config/Uncomment after developing /contact-us, /how-to-work etc.
 */
const NavBar = props => {
  //get the current pathname
  const pathname = props.location.pathname;
  const profileData = props.profileData;
  //initial avatar
  const [avatar, setAvatar] = useState(null);

  //get the user data from user login state
  const dispatch = useDispatch();
  const currentUserData = useSelector(state => state.loginReducer.userData);

  let user = localStorage.getItem("currentUserData");

  //get the profile avatar
  useEffect(() => {
    if (profileData) {
      setAvatar(profileData.profile_picture);
    }
    if (currentUserData) {
      const imageInfo = agent.Users.getClientProfileImage(
        currentUserData.data.user_id
      );
      imageInfo
        .then(response => setAvatar(response.data.profile_picture))

        .catch(error => console.log(error));
    }
  }, [props.profileData, currentUserData]);

  //import from material-ui
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();

  //get the currentUser
  const currentUser = useSelector(state => state.auth.currentUser);

  //set the initial state(hidden or visible) of the menu
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  //set the function of menu state change when click on the icon;
  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  //function when user logout
  const handleLogout = event => {
    dispatch(userLogout());
    agent.Users.logout().catch(e => console.log(e));
    window.location.replace("/");
  };
  const handleMenu = event => {
    setAnchor(event.currentTarget);
  };
  const [anchor, setAnchor] = React.useState(null);
  const open = Boolean(anchor);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  //function for changing path
  const handleContainerClass = filepath => {
    switch (pathname) {
      case "/":
        return classes.transparentHeaderWrapper;

      default:
        return classes.headerWrapper;
    }
  };

  //import the navbar background image
  const handleHeaderImage = () => {
    return `url(${ServiceNavBar_img})`;
  };

  return (
    <Grid
      container
      className={
        pathname === "/"
          ? classes.transparentHeaderWrapper
          : classes.headerWrapper
      }
      style={{
        "--image-url": handleHeaderImage(pathname),
        height: "90px"
      }}
    >
      {/* resorter logo */}
      <Grid container xs={6} item className={classes.logoWrapper}>
        <Link to="/">
          <DropdownIconButton {...props} />
        </Link>
      </Grid>

      {/* menu of navbar */}
      <Grid
        item
        xs={6}
        container
        justify="flex-end"
        // alignItems="center"
      >
        {/* if user is login, we will dispaly profile icon and block signup/signin button*/}
        {currentUserData ? (
          <>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              {/* the profile avatar drapdown menu  */}

              <Box
                sx={{
                  flexGrow: 0
                }}
              >
                <Tooltip title="Open settings">
                  <IconButton
                    onClick={handleOpenUserMenu}
                    sx={{
                      p: 0
                    }}
                  >
                    <Avatar alt="Remy Sharp" src={avatar} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{
                    mt: "45px"
                  }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {/*  TODO: Below pages are still under development.
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Link
                      to="/inbox"
                      style={{ color: "inherit", textDecoration: "inherit" }}
                    >
                      <Typography textAlign="center">Inbox</Typography>
                    </Link>
                  </MenuItem> */}
                  {/*  TODO: Below pages are still under development.
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Link
                      to="/booking"
                      style={{ color: "inherit", textDecoration: "inherit" }}
                    >
                      <Typography textAlign="center">My bookings</Typography>
                    </Link>
                  </MenuItem> */}
                  {// Depending on provider or client login and signup step, if display link to my profile page
                  // - users still in signup process can not see my profile page option
                  props.userData.data.is_provider &&
                  (props.userData.data.signup_step == 6 ||
                    props.activeStep == 6) ? (
                    <MenuItem onClick={handleCloseUserMenu}>
                      <Link
                        to="/pro/my-profile"
                        style={{
                          color: "inherit",
                          textDecoration: "inherit"
                        }}
                      >
                        <Typography textAlign="center">My Profile</Typography>
                      </Link>
                    </MenuItem>
                  ) : !props.userData.data.is_provider &&
                    (props.userData.data.signup_step == 5 ||
                      props.activeStep == 5) ? (
                    <MenuItem onClick={handleCloseUserMenu}>
                      <Link
                        to="/client-profile"
                        style={{ color: "inherit", textDecoration: "inherit" }}
                      >
                        <Typography textAlign="center">My Profile</Typography>
                      </Link>
                    </MenuItem>
                  ) : null}

                  {/*  TODO: Below pages are still under development.
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Link
                      to="/accounts"
                      style={{ color: "inherit", textDecoration: "inherit" }}
                    >
                      <Typography textAlign="center">My accounts</Typography>
                    </Link>
                  </MenuItem> */}
                  {/*  TODO: Below pages are still under development.
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Link
                      to="/payment"
                      style={{ color: "inherit", textDecoration: "inherit" }}
                    >
                      <Typography textAlign="center">
                        Payments & Payouts
                      </Typography>
                    </Link>
                  </MenuItem> */}
                  <MenuItem onClick={handleLogout}>
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem>
                  {/*  TODO: Below pages are still under development.
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Link
                      to="/view"
                      style={{ color: "inherit", textDecoration: "inherit" }}
                    >
                      <Typography textAlign="center">
                        Switch to Provider View
                      </Typography>
                    </Link>
                  </MenuItem> */}
                </Menu>
              </Box>

              {/* content of menu drop down icon */}
              {/* @TODO: Below pages are still under development. */}

              <Box
                sx={{
                  flexGrow: 0
                }}
              >
                <IconButton
                  size="large"
                  // aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleOpenNavMenu}
                  className={classes.menuIcon}
                >
                  <MenuIcon />
                </IconButton>

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: {
                      xs: "block",
                      md: "none"
                    }
                  }}
                >
                  {/* <MenuItem onClick={handleCloseNavMenu}>
                    <Link to="/how-it-works" className={classes.link}>
                      <Typography textAlign="center">How it works</Typography>
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Link to="/about-us" className={classes.link}>
                      <Typography textAlign="center">
                        Behind the desks
                      </Typography>
                    </Link>
                  </MenuItem> */}
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Link
                      to="/contact"
                      style={{
                        color: "inherit",
                        textDecoration: "inherit"
                      }}
                    >
                      <Typography textAlign="center">Contact us</Typography>
                    </Link>
                  </MenuItem>
                </Menu>
              </Box>
            </Grid>
          </>
        ) : (
          //  when there is no user sign in we will show signin/signup/dropdown menu button

          // <div className={classes.menuButton}>
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.menuButton}
          >
            <Box className={classes.signButton}>
              <a className={classes.navAnchorNormal} href="/sign-up">
                Sign Up
              </a>

              <a className={classes.navAnchorNormal} href="/login">
                Log In
              </a>
            </Box>

            <Box
              sx={{
                flexGrow: 0
              }}
            >
              <IconButton
                size="large"
                // aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                aria-label="menu"
                onClick={handleOpenNavMenu}
                className={classes.menuIcon}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left"
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: {
                    xs: "block",
                    md: "none"
                  }
                }}
              >
                <MenuItem
                  onClick={handleCloseNavMenu}
                  className={classes.mobileIcon}
                >
                  <Link to="/sign-up" className={classes.link}>
                    <Typography textAlign="center">Sign up</Typography>
                  </Link>
                </MenuItem>
                <MenuItem
                  onClick={handleCloseNavMenu}
                  className={classes.mobileIcon}
                >
                  <Link to="/login" className={classes.link}>
                    <Typography textAlign="center">Log in</Typography>
                  </Link>
                </MenuItem>

                {/* TODO: Below pages are still under development. */}

                {/* <MenuItem onClick={handleCloseNavMenu}>
                  <Link to="/how-it-works" className={classes.link}>
                    <Typography textAlign="center">How it works</Typography>
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link to="/about-us" className={classes.link}>
                    <Typography textAlign="center">Behind the desks</Typography>
                  </Link>
                </MenuItem> */}
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link
                    to="/contact"
                    style={{
                      color: "inherit",
                      textDecoration: "inherit"
                    }}
                  >
                    <Typography textAlign="center">Contact us</Typography>
                  </Link>
                </MenuItem>
              </Menu>
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    profileData: state.profileDataReducer.profileData,
    activeStep: state.stepperReducer.activeStep,
    userData: state.loginReducer.userData,
    userProfile: state.stepperReducer.userProfile
  };
}

// Adding Dispatch to update the existing values in Redux
const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NavBar));
