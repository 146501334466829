import React from "react";
import FooterBar from "./Footer/FooterBar";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    background: "FBFBF8",
    padding: "30px",
    textAlign: "center"
  },
  bluebtn: {
    borderRadius: "20px",
    align: "center"
  },
  usercolor: {
    color: "#1277B8",
    fontFamily: "PT Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "1.1",
    alignItems: "center",
    textAlign: "center"
  },
  alignment: {
    width: "478.73px",
    height: "169.45px",
    left: "457px",
    top: "409px"
  },

  textbotton: {
    fontFamily: "PT Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "140.62%",
    alignItems: "center",
    textAlign: "center",
    color: "#1277B8"
  }
}));

function Email() {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <div>
          <p className={classes.usercolor}> Hi [name]</p>
        </div>
        <div>
          <p className={classes.usercolor}>
            {" "}
            At RESORTer we want your experiences with our members
          </p>
        </div>
        <div>
          <p className={classes.usercolor}>
            {" "}
            to be outstanding. You recently had a lesson with{" "}
          </p>
        </div>
        <div>
          <p className={classes.usercolor}>
            [instructor name]. Give us some feedback so we can
          </p>
        </div>
        <div>
          <p className={classes.usercolor}>continue to serve you better. </p>
        </div>

        <Button
          variant="contained"
          color="secondary"
          className={classes.bluebtn}
        >
          Rate and Review
        </Button>
      </div>
      // <br />
      // <br />
      // <br />
      // <br />
      // <br />
      // <br />
      // <br />
      <div align="center">
        <p className={classes.textBottom}>
          {" "}
          Your chat is attached to this mail / link{" "}
        </p>
      </div>
    </div>
  );
}

export default function EmailStudent() {
  return (
    <div>
      <Email />
      <FooterBar />
    </div>
  );
}
