import React, { Component } from "react";
import {
  reduxForm,
  formValueSelector,
  Field,
  hasSubmitSucceeded,
  getFormSyncErrors,
  isSubmitting
} from "redux-form";
import { PROFILE } from "../section/section-types";
import SkillRangeInput from "./SkillRangeInput";
import CustomFormLabel from "./CustomFormLabel";
import CaptionToolTip from "./CaptionToolTip";
import connect from "react-redux/es/connect/connect";
import COUNTRIES from "../../../../materials/navBar/PhoneCode";
import RenderDatePicker from "./RenderDatePicker";
import {
  PROFILE_FIELD_NAMES,
  scrollerOptions
} from "../../../../constants/common";
import _ from "lodash";
import { updateMaster } from "../../../../redux/actions/tripWhoActions";
import skiChart from "../../../../materials/AbilityChart/skiChart.png";
import snowChart from "../../../../materials/AbilityChart/snowChart.png";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { Element } from "react-scroll";
import {
  NextButton,
  VerticalSpaceSM
} from "../../../../styling/styled-components";
import { submitProfileContents } from "../../../../redux/actions/profileActions";
import { scroller } from "react-scroll";
import { SectionBodyWrapper } from "../../../../styling/styled-components/sectionWrapper";
import { baseURL } from "../../../../redux/actions/action-types";
import { load as loadProfile } from "../../../../redux/actions/profileActions";

const today = new Date();

const {
  FIRST_NAME,
  LAST_NAME,
  DOB,
  GENDER,
  SKI_ABILITY,
  SNOWBOARD_ABILITY,
  // TELEMARK_ABILITY,
  // SNOWBIKE_ABILITY,
  // SNOWMOBILE_ABILITY,
  // SNOWSHOE_ABILITY,
  IS_DISABLED,
  DISABILITY_DETAIL,
  DISABILITY_MEMBERSHIP,
  DISABILITY_MEMBERSHIP_ID,
  COUNTRY_CODE,
  PHONE_NUMBER,
  EMAIL,
  POSTCODE,
  MEMBER_TYPE,
  PASSWORD,
  HASSNOWPASS
} = PROFILE_FIELD_NAMES;
// const now = moment();
// const dateFormat = "dd/MM/yyyy";

// validation for profile section
const validate = values => {
  const errors = {};
  const required = {
    FIRST_NAME,
    LAST_NAME,
    GENDER,
    DOB,
    IS_DISABLED,
    PHONE_NUMBER,
    EMAIL,
    POSTCODE
  };
  // check if not filled
  _.map(required, name => {
    if (
      values[name] === "" ||
      values[name] == null ||
      values[name] === undefined
    ) {
      errors[name] = "required";
    }
  });

  if (
    values[IS_DISABLED] &&
    (values[DISABILITY_DETAIL] === "" ||
      values[DISABILITY_DETAIL] == null ||
      values[DISABILITY_DETAIL] === undefined)
  ) {
    errors[DISABILITY_DETAIL] = "required";
  }

  if (values[COUNTRY_CODE] && values[COUNTRY_CODE].name == null) {
    errors[COUNTRY_CODE] = "required";
  }

  const expression = /\S+@\S+/;
  // email check
  if (errors[EMAIL] == null && !expression.test(values[EMAIL])) {
    errors[EMAIL] = "Please enter a valid email";
  }
  if (
    errors[DOB] == null &&
    today.getFullYear() - values[DOB].getFullYear() < 18
  ) {
    errors[DOB] = "Minimum age must be 18";
  }

  // if (
  //   values[MEMBER_TYPE] === "registered" &&
  //   ["", null, undefined].includes(values[PASSWORD])
  // ) {
  //   console.log("failed");
  //   errors[PASSWORD] = "Please enter a password";
  // }

  return errors;
};

const inputStyle = {
  boxShadow: "0px 2px 0px rgba(70,130,180,1)",
  color: "#525252",
  textOverflow: "ellipsis",
  border: "none",
  fontSize: "20px",
  minWidth: 80,
  margin: "auto",
  width: 200,
  textAlign: "center",
  textAlignLast: "center"
};

const inputDivStyle = {
  marginBottom: 50
};

const validatedInput = ({
  style,
  input,
  type,
  children,
  placeholder,
  meta: { touched, error }
}) => {
  let inputComponent;
  if (type === "select") {
    inputComponent = (
      <select
        {...input}
        placeholder={placeholder}
        style={{ ...inputStyle, ...style }}
        className="form-control"
      >
        {children}
      </select>
    );
  } else if (type === "textarea") {
    inputComponent = (
      <textarea
        {...input}
        placeholder={placeholder}
        style={{ ...inputStyle, ...style }}
        className="form-control"
      />
    );
  } else {
    inputComponent = (
      <input
        {...input}
        placeholder={placeholder}
        type={type}
        style={{ ...inputStyle, ...style }}
        className="form-control"
      />
    );
  }
  return (
    <div style={inputDivStyle}>
      {inputComponent}
      {touched && error && <div className="div-error">{error}</div>}
    </div>
  );
};

const emailMessage = (
  <div>
    We need this for us to email your enquiry confirmation and for the resort to
    contact you.
  </div>
);

const numberMessage = (
  <div>
    This is another identifier and a means for the resort to contact you before
    your arrival and during your stay at the the resort.
  </div>
);

const adaptiveMessage = (
  <div>
    Private lessons are recommended for those with a disability to optimise the
    learning experience. The rates can be signficantly discounted if you are a
    member of the resort's country's disabled/adaptive association.
  </div>
);

const DOBMessage = (
  <div>
    So we, and the resort, can identify you amongst those who share your name
    and see if you are eligible for any age related discounts/promotions.
  </div>
);

const abilityChartMessage = (
  <div className="row">
    <div className="col-12 col-sm-6">
      <img style={{ width: "100%" }} src={skiChart} alt="ski-chart" />
    </div>
    <div className="col-12 col-sm-6">
      <img style={{ width: "100%" }} src={snowChart} alt="snowboard-chart" />
    </div>
  </div>
);

export function scrollToProfileError(profileErrors) {
  const order = [
    FIRST_NAME,
    LAST_NAME,
    GENDER,
    DOB,
    IS_DISABLED,
    DISABILITY_DETAIL,
    COUNTRY_CODE,
    PHONE_NUMBER,
    EMAIL,
    POSTCODE,
    PASSWORD
  ];
  const errorKeys = Object.keys(profileErrors);
  let topError;
  for (let i = 0; i < order.length; i++) {
    if (errorKeys.includes(order[i])) {
      topError = order[i];
      break;
    }
  }
  if (topError != null) {
    scroller.scrollTo(topError, scrollerOptions);
  }
}

class ProfileSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryList: [],
      showAbilityChart: false,
      openSnackbar: false,
      snackbarMessage: ""
    };
  }

  // componentDidMount() {
  //   const userID = localStorage.getItem("userID");
  //   if (userID != null) {
  //     axios
  //       .get(baseURL + `/users/${userID}/member`, {
  //         headers: {
  //           Authorization: "Bearer " + localStorage.getItem("accessToken")
  //         }
  //       })
  //       .then(response => {
  //         const member = response.data.member;
  //         if (member != null) {
  //           this.props.load({
  //             ...member,
  //             DOB: new Date(member.DOB),
  //             [COUNTRY_CODE]: {
  //               name: member.Country,
  //               dial_code: member.PhoneAreaCode
  //             }
  //           });
  //         }
  //       });
  //   }
  // }

  handleCloseAbilityChart = () => {
    this.setState({ showAbilityChart: false });
  };

  handleOpenAbilityChart = () => {
    this.setState({ showAbilityChart: true });
  };

  handleCloseSnackbar = () => {
    this.setState({ openSnackbar: false });
  };

  handleOpenSnackbar = message => {
    this.setState({ openSnackbar: true, snackbarMessage: message });
  };

  handleNext = async () => {
    // submit to back end
    const submitter = this.props.handleSubmit(this.props.onSubmit);
    submitter();
    //TODO: better implementation

    setTimeout(() => {
      if (this.props.submitSucceeded) {
        this.props.onNext();
      } else {
        scrollToProfileError(this.props.profileErrors);
      }
    }, 500);
  };

  render() {
    const renderCountryOptions = COUNTRIES.map(country => (
      <option key={country.name} value={JSON.stringify(country)}>
        {country.name} ({country.dial_code})
      </option>
    ));
    return (
      <SectionBodyWrapper>
        <VerticalSpaceSM />

        <div style={{ textAlign: "center" }}>
          <Snackbar
            open={this.state.openSnackbar}
            onClose={this.handleCloseSnackbar}
            message={this.state.snackbarMessage}
            action={
              <Button
                key="close"
                color="secondary"
                size="small"
                onClick={this.handleCloseSnackbar}
              >
                Close
              </Button>
            }
          />
          <Element name={FIRST_NAME} />
          <CustomFormLabel>Your first name?</CustomFormLabel>
          <Field
            name={FIRST_NAME}
            component={validatedInput}
            placeholder="First name"
          />

          <Element name={LAST_NAME} />
          <CustomFormLabel>Your last name?</CustomFormLabel>
          <Field
            name={LAST_NAME}
            component={validatedInput}
            placeholder="Last name"
          />

          <Element name={GENDER} />
          <CustomFormLabel>Your gender?</CustomFormLabel>
          <Field name={GENDER} component={validatedInput} type="select">
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="not specified">Not Specified</option>
          </Field>

          <Element name={DOB} />
          <CustomFormLabel>Your date of birth?</CustomFormLabel>
          <CaptionToolTip
            onClick={this.handleOpenSnackbar}
            message={DOBMessage}
          />
          <Field
            name={DOB}
            component={RenderDatePicker}
            // utc necessary to prevent 1 day off bug
            // parse={value =>{
            //   const dateValue = value ? moment.utc(value).format("YYYY-MM-DD") : now.format("YYYY-MM-DD");
            //   return dateValue;
            //
            // }
            // }
          />
          <hr />

          {/*skills section*/}
          <h2>Your ability</h2>
          <hr />

          <button
            onClick={this.handleOpenAbilityChart}
            className="btn btn-link"
          >
            Not sure about your ability levels for ski and snowboard?
          </button>
          {/*<CaptionToolTip*/}
          {/*  caption="Not sure about your ability levels for ski and snowboard?"*/}
          {/*  onClick = {this.handleOpenSnackbar}*/}
          {/*  message={abilityChartMessage}*/}
          {/*/>*/}

          <Dialog
            onClose={this.handleCloseAbilityChart}
            open={this.state.showAbilityChart}
            maxWidth="lg"
            scroll="body"
          >
            <DialogContent>{abilityChartMessage}</DialogContent>
            <DialogActions>
              <Button
                key="close"
                color="secondary"
                size="small"
                onClick={this.handleCloseAbilityChart}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <Field
            name={SKI_ABILITY}
            component={SkillRangeInput}
            label="Ski"
            min={1}
            max={7}
            parse={value => parseInt(value, 10)}
          />

          <Field
            name={SNOWBOARD_ABILITY}
            component={SkillRangeInput}
            label="Snowboard"
            min={1}
            max={7}
            parse={value => parseInt(value, 10)}
          />

          {/*<Field*/}
          {/*name={TELEMARK_ABILITY}*/}
          {/*component={SkillRangeInput}*/}
          {/*label="Telemark"*/}
          {/*min={1}*/}
          {/*max={7}*/}
          {/*parse={value => parseInt(value, 10)}*/}
          {/*/>*/}

          {/*<Field*/}
          {/*  name={SNOWBIKE_ABILITY}*/}
          {/*  component={SkillRangeInput}*/}
          {/*  label="Snowbike"*/}
          {/*  min={1}*/}
          {/*  max={7}*/}
          {/*  parse={value => parseInt(value, 10)}*/}
          {/*/>*/}

          {/*<Field*/}
          {/*  name={SNOWMOBILE_ABILITY}*/}
          {/*  component={SkillRangeInput}*/}
          {/*  label="Snowmobile"*/}
          {/*  min={1}*/}
          {/*  max={7}*/}
          {/*  parse={value => parseInt(value, 10)}*/}
          {/*/>*/}

          {/*<Field*/}
          {/*  name={SNOWSHOE_ABILITY}*/}
          {/*  component={SkillRangeInput}*/}
          {/*  label="Snowshoe"*/}
          {/*  min={1}*/}
          {/*  max={7}*/}
          {/*  parse={value => parseInt(value, 10)}*/}
          {/*/>*/}

          <Element name={IS_DISABLED} />
          <CustomFormLabel>
            Any physical or learning disabilities?
          </CustomFormLabel>
          <CaptionToolTip
            onClick={this.handleOpenSnackbar}
            message={adaptiveMessage}
          />
          <div>
            <Field
              name={IS_DISABLED}
              normalize={value => value === "true"}
              component={validatedInput}
              type="select"
            >
              <option value="">Select</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </Field>
          </div>

          {this.props.IsDisabled === true && (
            <div>
              <div>
                <label style={{ fontStyle: "italic" }}>
                  An Adaptive Private lesson is recommended
                </label>
              </div>
              <VerticalSpaceSM />
              <CustomFormLabel>Please provide details</CustomFormLabel>
              <Field
                name={DISABILITY_DETAIL}
                component={validatedInput}
                type="textarea"
                placeholder="Enter details here"
              />

              <CustomFormLabel>
                Please enter your adaptive sports membership information if you
                are associated
              </CustomFormLabel>
              <div className="form-row">
                <div className="form-group col" style={{ marginLeft: 0 }}>
                  <label>Membership Type</label>

                  <Field
                    name={DISABILITY_MEMBERSHIP}
                    component={validatedInput}
                    type="text"
                    placeholder="Eg. DSUSA, DWA"
                  />
                </div>

                <div className="form-group col" style={{ marginLeft: 0 }}>
                  <label style={{ marginLeft: 0 }}>Membership ID #</label>
                  <Field
                    name={DISABILITY_MEMBERSHIP_ID}
                    component={validatedInput}
                    type="text"
                    placeholder="000000"
                  />
                </div>
              </div>
            </div>
          )}

          {/* SnowPass */}
          <CustomFormLabel>
            Do you already have a reloadable snowpass for{" "}
            {this.props.resortName}?
          </CustomFormLabel>
          <Field
            name={HASSNOWPASS}
            normalize={value => value === "true"}
            component={validatedInput}
            type="select"
          >
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </Field>

          <hr style={{ marginTop: "5px" }} />
          <h2>Your contact information</h2>
          <hr />

          <Element name={COUNTRY_CODE} />
          <CustomFormLabel>Your country area code?</CustomFormLabel>
          <div
            className="col col-sm-11 col-md-9 col-lg-7"
            style={{ margin: "auto" }}
          >
            <Field
              name={COUNTRY_CODE}
              component={validatedInput}
              type="select"
              format={value => (value === "" ? "" : JSON.stringify(value))}
              parse={value => (value === "" ? "" : JSON.parse(value))}
              style={{ width: "100%" }}
            >
              <option value="">Select country</option>
              {renderCountryOptions}
            </Field>
          </div>

          <Element name={PHONE_NUMBER} />
          <CustomFormLabel>Your number?</CustomFormLabel>
          <CaptionToolTip
            onClick={this.handleOpenSnackbar}
            message={numberMessage}
          />
          <div
            className="input-group"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center"
            }}
          >
            <div
              className="input-group-prepend"
              style={{ textAlign: "center" }}
            >
              <span
                className="input-group-text"
                style={{
                  border: "none",
                  fontWeight: "bold",
                  padding: 0,
                  width: 50,
                  marginBottom: 50,
                  boxShadow: "0px 2px 0px 0px rgba(70,130,180,1)"
                }}
              >
                {this.props.PhoneAreaCode}
              </span>
            </div>
            <Field
              name={PHONE_NUMBER}
              component={validatedInput}
              type="tel"
              style={{
                width: 150,
                textAlign: "left",
                textAlignLast: "left"
              }}
            />
          </div>

          <Element name={EMAIL} />
          <CustomFormLabel>Your email?</CustomFormLabel>
          <CaptionToolTip
            onClick={this.handleOpenSnackbar}
            message={emailMessage}
          />
          <Field name={EMAIL} component={validatedInput} type="email" />

          <Element name={POSTCODE} />
          <CustomFormLabel>Your postcode?</CustomFormLabel>
          <Field name={POSTCODE} component={validatedInput} />

          {/*<Element name={MEMBER_TYPE} />*/}
          {/*<CustomFormLabel>*/}
          {/*  Do you wish to save your info for your next visit?*/}
          {/*</CustomFormLabel>*/}
          {/*<div>*/}
          {/*  <Field name={MEMBER_TYPE} component={validatedInput} type="select">*/}
          {/*    <option value="">Select</option>*/}
          {/*    <option value="guest">No</option>*/}
          {/*    <option value="registered" disabled>*/}
          {/*      Yes (sorry, coming soon)*/}
          {/*    </option>*/}
          {/*  </Field>*/}
          {/*</div>*/}

          {/*{this.props.MemberType === "registered" && (*/}
          {/*  <div>*/}
          {/*    <CustomFormLabel>Please provide a password</CustomFormLabel>*/}
          {/*    <Field name={PASSWORD} component={validatedInput} type="text" />*/}
          {/*  </div>*/}
          {/*)}*/}

          <div style={{ textAlign: "right" }}>
            <NextButton
              disabled={this.props.isSubmitting}
              onClick={this.handleNext}
            >
              Next
            </NextButton>
          </div>
        </div>
      </SectionBodyWrapper>
    );
  }
}

function mapStateToProps(state) {
  const selector = formValueSelector(PROFILE);
  return {
    initialValues: state.profileReducer.data,
    profileErrors: getFormSyncErrors(PROFILE)(state),
    submitSucceeded: hasSubmitSucceeded(PROFILE)(state),
    isSubmitting: isSubmitting(PROFILE)(state),
    IsDisabled: selector(state, IS_DISABLED),
    MemberType: selector(state, MEMBER_TYPE),
    resortName: state.tripWhereReducer.resortName,
    PhoneAreaCode:
      selector(state, "countryCode") == null
        ? ""
        : selector(state, "countryCode").dial_code
  };
}

ProfileSection = reduxForm({
  form: PROFILE,
  validate,
  destroyOnUnmount: false,
  enableReinitialize: true,
  onSubmit: (values, dispatch) => {
    dispatch(submitProfileContents(values));
  },
  onChange: (values, dispatch, props) => {
    if (props.submitSucceeded && Object.keys(validate(values)).length === 0) {
      dispatch(updateMaster(values));
    }
  }
})(ProfileSection);

ProfileSection = connect(
  mapStateToProps,
  { load: loadProfile }
)(ProfileSection);

export default ProfileSection;
