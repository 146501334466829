import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  parent: {
    fontFamily: "PT Sans Narrow",
    maxWidth: "50%",
    margin: "0 auto"
  },
  nested1: {
    paddingLeft: theme.spacing(4)
  },
  nested2: {
    paddingLeft: theme.spacing(8)
  },
  text: {
    fontFamily: "PT Sans Narrow",
    color: "black",
    "& .MuiTypography-body1": {
      fontFamily: "PT Sans Narrow",
      fontSize: "16px",
      fontWeight: "normal"
    }
  },
  subtext: {
    padding: "0px 42px 0px 42px"
  },
  cardContainer: {
    backgroundColor: "#EFEFEF",
    borderRadius: "10px",
    margin: "1em 0"
  },
  cardContainer1: {
    backgroundColor: "#EFEFEF"
    // margin: "1em 0"
  },
  prompt: {
    // fontWeight: "bold",
    fontFamily: "PT Sans Narrow",
    color: "black",
    display: "inline-block",
    fontSize: "18px",
    marginTop: "0.3em"
  },
  title: {
    // paddingLeft: "0 !important",
    "& p": {
      fontWeight: "normal",
      fontFamily: "PT Sans Narrow",
      color: "black",
      fontSize: "16px",
      fontStyle: "normal"
    }
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    backgroundColor: "white",
    textAlignLast: "center",
    fontWeight: "bold",
    "& .MuiInputBase-input": {
      textAlign: "center"
    }
  },
  asterisk: {
    fontSize: "40px",
    marginBottom: "-2.5%",
    marginLeft: "1%",
    marginRight: "1%"
  },
  center: {
    textAlign: "center"
  },
  nextButton: {
    // marginTop: "3em",
    padding: "0rem 1rem",
    width: "9em",
    height: "2.5em",
    borderRadius: 100,
    textAlign: "center"
    // marginRight: "1em"
  },
  buttonContainer: {
    margin: "2rem 0rem",
    padding: "0rem 2rem"
  },
  buttonLabel: {
    color: "white",
    fontWeight: "normal",
    fontFamily: "PT Sans Narrow"
  },
  buttonRoot: {
    backgroundColor: "#FF6161"
  },
  backButton: {
    width: "60%",
    padding: "0rem 1rem",
    // marginTop: "3.5em",
    // marginBottom: "1em",
    // marginLeft: "20%",
    // marginRight: "2em",
    fontFamily: "PT Sans",
    border: "white"
  },
  backButtonLabel: {
    color: "#FF6161",
    fontSize: 16,
    fontWeight: "normal",
    fontFamily: "PT Sans Narrow"
  }
}));

export default useStyles;
