import {
  GET_COUNTRY_DATA,
  GET_PROVIDER_DATA,
  GET_RESORT_DATA
} from "../actions/action-types";

const initialState = {
  fakeHostData: [
    {
      imagePath:
        "https://images.unsplash.com/photo-1551698618-1dfe5d97d256?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
      alt: "host",
      name: "Aaron Hussian",
      location: "Mount Hotham",
      title: "Host",
      id: 1
    },
    {
      imagePath:
        "https://images.unsplash.com/photo-1551698618-1dfe5d97d256?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
      alt: "host",
      name: "Aaron Hussian",
      location: "Mount Hotham",
      title: "Host",
      id: 2
    },
    {
      imagePath:
        "https://images.unsplash.com/photo-1551698618-1dfe5d97d256?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
      alt: "host",
      name: "Aaron Hussian",
      location: "Mount Hotham",
      title: "Host",
      id: 3
    },
    {
      imagePath:
        "https://images.unsplash.com/photo-1551698618-1dfe5d97d256?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
      alt: "host",
      name: "Aaron Hussian",
      location: "Mount Hotham",
      title: "Host",
      id: 4
    }
  ],
  fakeInstructorData: [
    {
      imagePath:
        "https://images.unsplash.com/photo-1551698618-1dfe5d97d256?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
      instructorName: "John Smith",
      location: "Mt Buller",
      title: "Ski Instructor",
      id: 1
    },
    {
      imagePath:
        "https://images.unsplash.com/photo-1551698618-1dfe5d97d256?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
      instructorName: "John Smith",
      location: "Mt Buller",
      title: "Ski Instructor",
      id: 2
    },
    {
      imagePath:
        "https://images.unsplash.com/photo-1551698618-1dfe5d97d256?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
      instructorName: "John Smith",
      location: "Mt Buller",
      title: "Ski Instructor",
      id: 3
    },
    {
      imagePath:
        "https://images.unsplash.com/photo-1551698618-1dfe5d97d256?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
      instructorName: "John Smith",
      location: "Mt Buller",
      title: "Ski Instructor",
      id: 4
    }
  ],
  country_list: [
    { id: 1, value: "privateLessons", name: "Private Lessons" },
    { id: 2, value: "groupLessons", name: "Group Lessons" },
    { id: 3, value: "bookHost", name: "Book a local host" }
  ],
  resortList: [
    { id: 1, value: "Argentina", name: "Argentina" },
    { id: 2, value: "Canada", name: "Canada" },
    { id: 3, value: "Australia", name: "Australia" }
  ],
  providerList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COUNTRY_DATA:
      return {
        ...state,
        country_list: action.payload.data.sort(function(a, b) {
          return a.country_name.localeCompare(b.country_name);
        })
      };
    case GET_RESORT_DATA:
      return {
        ...state,
        resortList: action.payload.data
      };
    case GET_PROVIDER_DATA:
      return {
        ...state,
        providerList: action.payload.data
      };
    default:
      return state;
  }
};