import React, { Component } from "react";

class Accordion extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return <div className="accordion">{this.props.children}</div>;
  }
}

export default Accordion;
