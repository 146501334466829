// #region - all imports
// React
import React, { useEffect, useState } from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import {
  Box, 
  MenuItem,
  FormControl,
  Select,
  Chip,
  ListItemText,
  Grid,
  Checkbox,
  IconButton,
  FormHelperText
} from "@material-ui/core";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

// Redux
import { getCountryList, getResortList } from "../../../redux/actions/homePageAction.js";
import { connect } from "react-redux";

// APIs
import agent from "../../../agent.js";

// CSS
import { COLOR } from "../../../styling/theme/Theme";
import clsx from "clsx";

//#endregion

// Constants
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "auto",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  errorText:{
    color: "#f44336", marginLeft: "14px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "22px",
    },
  },
  select2: {
    fontColor: "1277B8",
    marginLeft: "7.5%",
    width: "95%",
    fontFamily: "PT Sans Narrow",
    borderRadius: "10px",
    "& .MuiOutlinedInput-input": {
      padding: "0.5rem 0.5rem",
      height: "auto"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black  ",
      borderWidth: "1px  "
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black  ",
      borderWidth: "1px  "
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black  ",
      borderWidth: "1px  "
    },
    "& .MuiListItemText-root": {
      marginTop: "0px"
    },
    "& .MuiTypography-body1": {
      lineHeight: "inherit"
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      marginLeft: "4.5%",
    },
  },
  label: {
    marginLeft: "3%",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    marginBottom: "0.5em",
    textTransform: "uppercase",
    color: "#000000",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "4.5%",
    },
  },
  inputSelect: {
    marginTop: "0.2em",
    textAlign: "center",
    flexWrap: "wrap",
    display: "flex"
  },
  selectMenu: {
    width: "90%"
  },
  selectRootText: {
    textAlign: "center",
    color: COLOR.PRIMARY,
    width: "90%",
    "& div.MuiOutlinedInput-root": {
      height: "2.1rem",
      fontFamily: "Roboto"
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      borderColor: "black !important"
    },
    "& .MuiOutlinedInput-root .MuiSelect-select:focus": {
      backgroundColor: "#EFEFEF"
    }
  },
  selectMenu: {
    width: "90%"
  },
  selectRoot: {
    borderColor: "black",
    width: "90%",
    "& div.MuiOutlinedInput-root": {
      height: "auto",
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      borderColor: "black !important"
    },
    "& .MuiOutlinedInput-root .MuiSelect-select:focus": {
      backgroundColor: "transparent"
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  selectIcon: {
    color: COLOR.PRIMARY
  },
  search: {
    width: "17em"
  },
  checkBoxStyle: {
    "& .MuiCheckbox-root": {
      color: "#1277B8"
    }
  },
  searchRoot: {
    borderRadius: 5,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: COLOR.PRIMARY,
        borderWidth: 1,
        borderRadius: 5
      },
      "&:hover fieldset": {
        borderColor: COLOR.PRIMARY,
        borderWidth: 1,
        borderRadius: 5
      },
      "&.Mui-focused fieldset": {
        borderColor: "#ff0000",
        borderWidth: 1,
        borderRadius: 5
      }
    }
  },
  listRoot: {
    whiteSpace: "normal",
    // width: "18em",
    alignContent: "center"
  },
  subHeaderRoot: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "140.62%",
    display: "flex",
    alignItems: "center",
    color: COLOR.PRIMARY
  },
  primary: {
    fontFamily: "PT Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "140.62%",
    color: COLOR.PRIMARY
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5"
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)"
    }
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3"
    }
  },
  btnSize: {
    fontSize: "2.3rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem"
    }
  }
}));

const CountryResortSelection = props => {
  const classes = useStyles();
  const { 
    country_list, 
    isDisabled, 
    countryResorts, // all resorts without grouped by country, ie. original data for or from APIs
    /** countryResorts - example:
     * {
          {id: 1, resort_name: 'Chréa', country: 67}
          {id: 1033, resort_name: 'Blidinje – Risovac', country: 29}
          {id: 2518, resort_name: 'Babin Do – Bjelašnica', country: 29}
          {id: 3254, resort_name: 'Arcalís – Ordino (Vallnord)', country: 25}
          {id: 3255, resort_name: 'Arinsal – La Massana (Vallnord)', country: 25}
        }

        if no content, countryResorts = [] ie. Array(0)
    */
    setCountryResorts,
    resortsGroupByCountry, // all resorts grouped by country
    /** resortsGroupByCountry - example:
     * (3) [Array(2), Array(2), Array(1)]
          0: Array(2)
            0: {id: 3254, resort_name: 'Arcalís – Ordino (Vallnord)', country: 25}
            1: {id: 3255, resort_name: 'Arinsal – La Massana (Vallnord)', country: 25}
          1: Array(2)
            0: {id: 2518, resort_name: 'Babin Do – Bjelašnica', country: 29}
            1: {id: 1033, resort_name: 'Blidinje – Risovac', country: 29}
          2: Array(1)
            0: {id: 1, resort_name: 'Chréa', country: 67}

        if no content, resortsGroupByCountry = [[]] ie. Array(1)
    */
    setResortsGroupByCountry,
    resorts, // all resorts in particular country - ie. resortsGroupByCountry.item for this row
    /** resorts - example for row 0: 
     *  {
          {id: 3254, resort_name: 'Arcalís – Ordino (Vallnord)', country: 25}
          {id: 3255, resort_name: 'Arinsal – La Massana (Vallnord)', country: 25}
        }
        
        if no content, then resorts = [] - ie. new or emptyed row
     */
    rowIndex, // index of this row
    boxWidth,
  } = props;

  const [selectedCountry, setSelectedCountry] = useState(""); // selected country's name
  const [selectedResorts, setSelectedResorts] = useState([]); // array of selected resorts' names
  const [selectedCountryId, setSelectedCountryId] = useState(0); // selected country's id
  const [resortList, setResortList] = useState([]); // resorts of the selected country

  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  // get ALL nationality data
    useEffect(() => {
      props.getCountryList();
    }, []);

  // prefill user selected option
  useEffect(() => { 
    const fetchResortList = async () => {
      const res = await agent.Constants.getResort(resorts[0].country);
      setResortList(res.data);
    }

    if (resorts.length !=0 ) {
      let resortNameList = [];

      let country = country_list.find(country => {
        return country.id === resorts[0].country;
        });

      setSelectedCountry(country.country_name);
      setSelectedCountryId(country.id);      
      fetchResortList().catch(console.error);
    
      resorts.forEach(item => {
        if (item.resort_name != ""){
          resortNameList.push(item.resort_name);
        }
      })
      setSelectedResorts(resortNameList);
    }
  }, [resortsGroupByCountry]);

  const handleCountryChange = async (event) => {
    let selectedItem = event.target.value; // eg. { id: 1, country_name: "Australia" }
    if(selectedItem.country_name !== selectedCountry) {
      // --- CHANGE/NEW SELECT: clear the old country and its resort list ---
      //   The reason of change from uplevel is because add, remove new rows function 
      //      which will affect the resortsGroupByCountry anyway
      let newResortsGroupByCountry = [...resortsGroupByCountry];
      let newData = [
        {
          id: 0, 
          resort_name: "", 
          country: selectedItem.id
        }
      ]
      newResortsGroupByCountry.splice(rowIndex, 1, newData);
      setResortsGroupByCountry(newResortsGroupByCountry);
      let newResortListRes = await agent.Constants.getResort(selectedItem.id);
      setResortList(newResortListRes.data);
      console.log(newResortListRes.data);
    } else { 
      // --- DE-SELECT: change upperlevel props and remove all records with the country id ---
      let newResortsGroupByCountry = [...resortsGroupByCountry];
      newResortsGroupByCountry.splice(rowIndex, 1, []);

      // Reset component
      setResortsGroupByCountry(newResortsGroupByCountry);
      setSelectedCountry(""); // selected country's name
      setSelectedResorts([]); // array of selected resorts' names
      setSelectedCountryId(0); // selected country's id
      setResortList([]);

      // Remove all records with the country id 
      let newCountryResorts = [...countryResorts].filter(
        item => item.country !== selectedItem.id
      );
      setCountryResorts(newCountryResorts);
    }
  };

  const handleResortChange = (event) => {

    // console.log("1 - All Selected Resorts: ", event.target.value); 
    // if the item is selected again, all selected resorts would be like:
    /**  
     *  {
          {'Arcalís – Ordino (Vallnord)'} - ** previous selected **
          {id: 3255, resort_name: 'Arinsal – La Massana (Vallnord)', country: 25} - ** current selected **
        }
     */
    let selectedItem = event.target.value[event.target.value.length - 1];
    // console.log("2 - CURRENT SELECTED RESORT: ", selectedItem); 
    
    // --- check duplicated ie. if the selected option exists. ---
    // if the selected option exists in this row, it means the user wants to deselect this option - duplicated
    // if the selected option exists in another row, it should be prevented to select again - crossDuplicated
    let duplicated = false;
    let crossDuplicated = false;
    resortsGroupByCountry.map((resorts, index) => {
      resorts.forEach(resort => {
        if (resort.country === selectedItem.country 
          && resort.resort_name === selectedItem.resort_name
        ) {
          if (rowIndex == index){
            duplicated = true;
          } else {
            crossDuplicated = true;
          }
        }
      })    
    });

    if (!crossDuplicated) {
      if (!duplicated) { 
        // --- new select in all rows ---
        let allSelectedResorts = [...resorts, selectedItem];
        let newResortsGroupByCountry = [...resortsGroupByCountry];
        newResortsGroupByCountry.splice(rowIndex, 1, allSelectedResorts);
        setResortsGroupByCountry(newResortsGroupByCountry);
        let newCountryResorts = [...countryResorts, selectedItem]
        setCountryResorts(newCountryResorts);

      } else { 
        // --- duplicated select in this rows ie. de-select ---
        let allSelectedResorts = [...resorts].filter(
          item => item.resort_name !== selectedItem.resort_name
        );
        let newResortsGroupByCountry = [...resortsGroupByCountry];
        newResortsGroupByCountry.splice(rowIndex, 1, allSelectedResorts);
        setResortsGroupByCountry(newResortsGroupByCountry);
        let newCountryResorts = [...countryResorts].filter(
          item => item.resort_name !== selectedItem.resort_name
        );
        setCountryResorts(newCountryResorts);
      }
    } else {
      // --- duplicated select in another row ie. prevent it ---
      alert("The resort is already chosen!")
    }
  }

  const addNewRow = (event) => {
    // console.log("ADD - ROW WAS CLICKED: ", rowIndex);
    let temp = [...resortsGroupByCountry, []];
    setResortsGroupByCountry(temp);
  };

  const removeRow = (event) => {
    // console.log("REMOVE - ROW WAS CLICKED: ", rowIndex);
    let temp = [...resortsGroupByCountry];
    temp.splice(rowIndex, 1);
    let newCountryResorts = [...countryResorts];
    resorts.forEach(resort => {
      newCountryResorts = newCountryResorts.filter(
        item => item.resort_name !== resort.resort_name
      )
    })
    setResortsGroupByCountry(temp);
    setCountryResorts(newCountryResorts);
  };

  const handleClose = event => {
    if (selectedResorts.length == 0) {
      setError(true);
      setHelperText("*Required")
    } else{
      setError(false);
    }
  };

  return (
    <div>
      <Grid 
        container
        justify="center"
        alignItems="flex-start"
        direction="row"
      >
        <Grid container item xs={12}>

          {/* Country Selection */}
          <Grid container item xs={ boxWidth || 6 }>
            <FormControl
              variant="outlined"
              color="primary"
              className={classes.selectRoot}
            >
              <Select
                className={classes.select2}
                disabled={isDisabled}
                value={selectedCountry}
                onChange={handleCountryChange}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        <Chip key={selected} label={selected} />
                    </Box>
                )}
                MenuProps={MenuProps}
              >
                {/* country_list:
                      Array(73)...
                        0: {id: 67, country_name: 'Algeria'}
                        1: {id: 25, country_name: 'Andorra'}
                        2: {id: 41, country_name: 'Argentina'}
                        3: {id: 46, country_name: 'Armenia'} 
                        ....
                */}
                {country_list.map((country, index) => (
                  <MenuItem
                    key={index}
                    value={country}
                  >
                    <ListItemText primary={country.country_name} />  
                    {/* eg. 'Algeria' */}
                  </MenuItem>
                ))}
              </Select>
              <Grid item>
                <FormHelperText className={classes.errorText}>
                  &nbsp;
                </FormHelperText>
              </Grid>
            </FormControl>
          </Grid>

          {/* Resort Selection */}
          <Grid container item xs={ boxWidth || 6 }>
            <FormControl
              variant="outlined"
              color="primary"
              className={classes.selectRoot}
            >
              <Select
                multiple
                disabled={isDisabled}
                value={selectedResorts}
                onChange={handleResortChange}
                onClose={handleClose}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                      <Chip key={value} label={value} />
                  ))}
                  </Box>
                )}
                className={classes.select2}
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot,
                  icon: classes.selectIcon,
                  selected: classes.selected
                  }}
                MenuProps={MenuProps}
              >
                {resortList.map((resort, index) => (
                  <MenuItem key={index} value={resort}>
                    <Checkbox
                      className={classes.root}
                      disableRipple
                      color="default"
                      checkedIcon={
                        <span className={clsx(classes.icon, classes.checkedIcon)} />
                      }
                      checked={selectedResorts.indexOf(resort.resort_name) > -1}
                      icon={<span className={classes.icon} />}
                      inputProps={{ "aria-label": "decorative checkbox" }}
                    />
                    <ListItemText primary={resort.resort_name} />
                  </MenuItem>
                ))}
              </Select>
              <Grid item>
                {error ? (
                  <FormHelperText className={classes.errorText}>
                    {helperText}
                  </FormHelperText>
                ) : (
                  <FormHelperText className={classes.errorText}>&nbsp;</FormHelperText>
                )}
              </Grid>
            </FormControl>
          </Grid>

          {/* Add and Remove Buttons */}
          {isDisabled ? null : (
            <Grid 
              container
              direction="row"
              xs={2}
            >
              <Grid container item xs={6}>
                <IconButton size="small" className={classes.root} onClick={addNewRow}>
                  <AddCircleIcon color="primary" className={classes.btnSize}/>
                </IconButton>
              </Grid>
              {rowIndex !== 0 ? (
                <Grid container item xs={6}>
                  <IconButton size="small" className={classes.root} onClick={removeRow}>
                    <RemoveCircleOutlineIcon color="primary" className={classes.btnSize}/>
                  </IconButton>
                </Grid>
              ) : null }
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    country_list: state.homePageReducer.country_list,
    resortList: state.homePageReducer.resortList
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getCountryList: () => dispatch(getCountryList()),
    getResortList: id => dispatch(getResortList(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CountryResortSelection);