import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import Store from "../../../redux/store";
import { connect } from "react-redux";

import {
  incrementActiveStep,
  decrementActiveStep,
  incrementActiveStepHost,
  decrementActiveStepHost
} from "../../../redux/actions/stepperActions.js";

const useStyles = makeStyles(theme => ({
  confirmationPromptDiv: {
    textAlign: "center" + "!important"
  },

  confirmationPrompt: {
    fontFamily: "PT Sans Narrow",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "23px",
    letterSpacing: "0em",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "18px"
    }
  },

  headPromptDiv: {
    textAlign: "center" + "!important",
    borderBottom: "1px solid #BFBFBF",
    marginBlockEnd: "1rem"
    // [theme.breakpoints.down("sm")]: {
    //   marginBlockEnd: "1rem"
    // }
  },

  headPrompt: {
    fontFamily: "PT Sans Narrow",
    fontSize: "26px",
    fontStyle: "normal",
    fontWeight: 400,
    // lineHeight: "37px",
    letterSpacing: "0em",
    textAlign: "left"
    // [theme.breakpoints.down("sm")]: {
    //   fontSize: "18px"
    // }
  },

  container: {
    margin: "100px auto 200px auto",
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      margin: "100px auto 100px auto",
      width: "80%"
    }
  },
  buttonContainer: {
    height: 200
  },
  backToHomeButton: {
    backgroundColor: "#FF6161",
    borderRadius: 100,
    textAlign: "center",
    color: "#FFFFFF",
    width: "12em",
    height: "3em",
    marginBottom: "18em",
    marginTop: "1em"
  },

  verifyEmialAddressButton: {
    backgroundColor: "#FF6161",
    borderRadius: 100,
    color: "#FFFFFF",
    width: "12em",
    height: "3em"
  },

  verifyBtnLabel: {
    fontFamily: "PT Sans Narrow",
    textTransform: "none",
    paddingTop: "0.4em",
    paddingBottom: "0.4em"
  },

  tips: {
    fontFamily: "PT Sans Narrow",
    fontSize: 16,
    color: "#1277B8",
    paddingLeft: "5em",
    paddingRight: "5em"
  },
  nextButton: {
    // marginTop: "3em",
    width: "102px",
    height: "2.5em",
    borderRadius: 100,
    textAlign: "center"
    // marginRight: "1em"
  },
  buttonRoot: {
    backgroundColor: "#FF6161"
  },
  buttonLabel: {
    color: "white",
    fontWeight: 700,
    letterSpacing: "1%",
    fontFamily: "PT Sans"
  },
  backButton: {
    width: "60%",
    // marginTop: "3em",
    // marginBottom: "1em",
    // marginLeft: "20%",
    marginRight: "1em",
    fontFamily: "PT Sans",
    border: "white"
  },
  backButtonLabel: {
    color: "#FF6161",
    fontWeight: 700,
    letterSpacing: "1%",
    fontFamily: "PT Sans"
  }
}));

let EmailVerification = props => {
  const classes = useStyles();

  const store = Store.getState();
  // Below gets the first and last name from redux store
  // const firstName = store.form.signup.values.firstName;

  const navigateToLoginPage = () => {
    console.log("Navigating to Login page");
  };

  const handleBackButton = event => {
    props.decrementActiveStep(decrementActiveStep());
  };

  return (
    <div>
      <form onSubmit={props.handleSubmit}>
        <Grid
          container
          justify="center"
          alignContent="center"
          className={classes.container}
        >
          <Grid item sm={12} xs={8}>
            <div className={classes.headPromptDiv}>
              <Typography className={classes.headPrompt}>
                Almost done!
              </Typography>
            </div>
          </Grid>

          <Grid item sm={12} xs={8}>
            <div className={classes.confirmationPromptDiv}>
              <Typography className={classes.confirmationPrompt}>
                We have sent a confirmation link to your email address.
              </Typography>
            </div>
          </Grid>

          {/* <Grid item sm={12} xs={12}>
            <div className={classes.confirmationPromptDiv}>
              <Button
                type="submit"
                variant="contained"
                className={classes.backToHomeButton}
                classes={{ label: classes.verifyBtnLabel }}
              >
                BACK TO HOME
              </Button>
            </div>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              // style={{ height: 200, width: 800 }}
              className={classes.buttonContainer}
            > */}
          {/* <Grid item>
                {/* {props.activeStep < 1 ? null : ( */}
          {/* <Button
                  className={classes.backButton}
                  classes={{ label: classes.backButtonLabel }}
                  variant="outlined"
                  // style={{color:"#FF6161"}}
                  onClick={handleBackButton}
                >
                  Back
                </Button> */}

          {/* )} */}
          {/* </Grid>  */}

          {/* <Grid item>
                <Button
                  style={{ textTransform: "none" }}
                  href="/"
                  className={classes.nextButton}
                  classes={{
                    label: classes.buttonLabel,
                    root: classes.buttonRoot
                  }}
                  variant="contained"
                >
                  Login
                </Button>
              </Grid> */}
          {/* </Grid>
          </Grid> */}
        </Grid>
      </form>
    </div>
  );
};

// destroyOnUnmount - saves it in state
EmailVerification = reduxForm({
  form: "signup",
  destroyOnUnmount: false
})(EmailVerification);

function mapDispatchToProps(dispatch) {
  return {
    incrementActiveStep: () => dispatch(incrementActiveStep()),
    decrementActiveStep: () => dispatch(decrementActiveStep()),
    incrementActiveStepHost: () => dispatch(incrementActiveStepHost()),
    decrementActiveStepHost: () => dispatch(decrementActiveStepHost())
  };
}

function mapStateToProps(state, ownProps) {
  return {
    activeStep: state.stepperReducer.activeStep,
    activeStepHost: state.stepperReducer.activeStepHost
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailVerification);
