import React from "react";
import "../../../../styling/css/template/SliderBar.css";

export default function SkillRangeInput(props) {
  const { min, max, label, input } = props;

  return (
    <div className="form-row ">
      <div className="col">
        <label style={{ fontWeight: "bold", fontSize: "15px", color: "black" }}>
          {label}
        </label>
      </div>
      <div className="col">
        <input
          type="range"
          className="color-slider-bar"
          min={min}
          max={max}
          step={1}
          value={input.value}
          onChange={input.onChange}
        />
      </div>
      <div className="col">
        <p style={{ fontWeight: "bold" }}>{input.value}</p>
      </div>
    </div>
  );
}
