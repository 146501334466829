import React from "react";
import DatePicker from "react-datepicker/es";

import DatePickerButton from "../Common/DatePickerButton";

const errorStyle = {
  color: "red",
  position: "absolute",
  width: "100%",
  margin: "auto",
  left: 0
};

const today = new Date();

export default function renderDatePicker({
  input,
  meta: { touched, error, warning },
  ...props
}) {
  const selected = input.value ? input.value : null;
  return (
    <div style={{ width: 200, margin: "auto", marginBottom: 50 }}>
      <DatePicker
        selected={selected}
        onChange={input.onChange}
        dropdownMode="select"
        dateFormat={"dd/MM/yyyy"}
        showYearDropdown
        showMonthDropdown
        maxDate={today}
        onBlur={() => input.onBlur(input.value)}
        customInput={<DatePickerButton placeholderText="dd/MM/yyyy" />}
      />
      {touched &&
        ((error && <div style={errorStyle}>{error}</div>) ||
          (warning && <span>{warning}</span>))}
    </div>
  );
}
