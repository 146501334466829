import React, { useState } from "react";
import { Grid, FormControl, Select, Typography } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

const styles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  selectRoot: {
    color: "black",
    paddingRight: "30px",
    // display: "inline",
    "& .svg.MuiSvgIcon-root .MuiStepIcon-completed, path": {
      color: "#4A443F"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black  ",
      borderWidth: "1px  "
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black  ",
      borderWidth: "1px  "
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black  ",
      borderWidth: "1px  "
    }
  },
  selectRoot2: {
    display: "inline",
    paddingBottom: "0em",
    paddingTop: "0em",
    marginBottom: "0em",
    height: "2.3em",
    lineHeight: 2.5
  },
  labels: {
    display: "inline",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    color: "#000000",
    lineHeight: "23px",
    letterSpacing: "0em",
    textAlign: "left",
    paddingBottom: "5px"
    // marginLeft: 15
  },
  labels: {
    display: "inline",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    color: "#000000",
    lineHeight: "23px",
    letterSpacing: "0em",
    textAlign: "left",
    paddingBottom: "5px",
    marginLeft: 30
  },
  labels2: {
    display: "inline",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    color: "#000000",
    lineHeight: "23px",
    letterSpacing: "0em",
    textAlign: "left",
    paddingBottom: "5px"
    // marginLeft: 30
  },
  select1: {
    width: "210px",
    height: "2.1em",
    marginLeft: 30,
    fontFamily: "PT Sans Narrow"
  },
  select2: {
    width: "210px",
    height: "40px",
    marginLeft: 0,
    fontFamily: "PT Sans Narrow",
    border: "1px solid black"
  },
  buttonContainer: {
    // border: '1px solid blue',
    position: "relative",
    left: 150,
    top: -35,
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      //   position: "relative",
      //   left: 30,
      //   top: 30,
      //   display: "flex"
    }
  },
  resort: {
    // border: '1px solid red',
    // marginLeft: 4,
    // paddingLeft: 2,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0
    }
  }
}));

const CountryAndResortFields = props => {
  const {
    checkOptions,
    onCountryHandleChange,
    resortOptions,
    onResortHandleChange,
    disable
  } = props;
  const classes = styles();

  const [formValues, setFormValues] = useState([]);

  const addTextField = e => {
    e.preventDefault();
    setFormValues([...formValues, { name: "", email: "" }]);
  };

  const removeTextField = e => {
    e.preventDefault();
    const newFormValues = [...formValues];
    newFormValues.splice(0, 1);
    setFormValues(newFormValues);
  };

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Grid container direction="column" alignItems="center">
          <Grid
            item
            xs={12}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <FormControl>
              <Typography className={classes.labels2}>Country</Typography>
              <Select
                required
                variant="outlined"
                className={classes.select2}
                classes={{
                  root: classes.selectRoot
                }}
                onChange={onResortHandleChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
                onChange={onCountryHandleChange}
              >
                {checkOptions}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Grid container direction="column" alignItems="center">
          <Grid
            item
            xs={12}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <FormControl>
              <Typography className={classes.labels2}>Resort</Typography>
              <Select
                // style={{marginRight: 40}}
                required
                variant="outlined"
                disabled={disable}
                className={classes.select2}
                classes={{
                  root: classes.selectRoot
                }}
                onChange={onResortHandleChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              >
                {resortOptions}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={2}>
            <div className={classes.buttonContainer}>
              <button
                style={{
                  marginRight: 10,
                  color: "#1277B8",
                  backgroundColor: "white",
                  border: "none"
                }}
                onClick={e => addTextField(e)}
              >
                <AddCircleIcon fontSize="medium" />
              </button>
              <button
                style={{
                  color: "#1277B8",
                  backgroundColor: "white",
                  border: "none"
                }}
                onClick={e => removeTextField(e)}
              >
                <RemoveCircleIcon />
              </button>
            </div>
          </Grid>

          {formValues.map((element, index) => (
            <FormControl key={index}>
              <Grid
                container
                direction="row"
                className={classes.resort}
                style={{ marginBottom: 10 }}
              >
                <Grid item xs={12}>
                  <Select
                    variant="outlined"
                    disabled={disable}
                    className={classes.select2}
                    classes={{
                      root: classes.selectRoot
                    }}
                    onChange={onResortHandleChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}
                  >
                    {resortOptions}
                  </Select>
                </Grid>
              </Grid>
            </FormControl>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default CountryAndResortFields;
