//name of file is self explanatory I hope
import React from "react";
//materialUI
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
//Componentry
import SelectedTimeslot from "../SelectedTimeslot";
import { times } from "lodash";
import { equalsDatetime } from "../Helpers/dates";
import Chip from "@material-ui/core/Chip";
import { connect } from "react-redux";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";

//constants
import {
  allTimesHour,
  allTimesMinute,
  allTimesString
} from "../../../constants/common";

//styling
const useStyles = makeStyles({
  priceLabel: { fontWeight: "bold" },
  smallSectionHeading: {
    fontSize: "16"
  },
  timeslot: {
    marginTop: "5em"
  },
  root: {
    width: "100%",
    padding: "20px"
  },
  textHeading: {
    fontSize: "16",
    color: "#1277B8",
    fontWeight: "bold",
    fontFamily: "PT Sans Narrow",
    lineHeight: "140.62%",
    padding: "6px"
  },
  subHeading: {
    alignText: "left",
    textAlign: "left",
    align: "left",
    fontSize: "16",
    fontWeight: "bold",
    fontFamily: "PT Sans Narrow",
    lineHeight: "140.62%",
    padding: "6px"
  },
  buttonContainer: {
    marginTop: "3em",
    marginBottom: "1em"
  },
  timeslots: {
    height: "13rem",
    overflowY: "scroll",
    margin: "0.5rem"
  },
  errorBadge: {
    backgroundColor: "red",
    borderRadius: 15,
    fontSize: "x-small",
    margin: "0 0.5rem"
  }
});

//main function
const SelectedBookingTimeslots = props => {
  const {
    timeslots,
    timeslotLabel,
    bookings,
    chosenday,
    canBook,
    resort_id,
    setTimeslotLabel,
    pricing
  } = props;

  //styling
  const classes = useStyles();

  //state
  const [allPrices, setAllPrices] = React.useState([]);
  const [totalPrice, setTotalPrice] = React.useState(0);

  //renders the timeslots
  const RenderTimeslots = () => {
    if (timeslots.length > 0) {
      //starts at -1 as loop immediately iterates it to 0 which is beginning of array
      let new_id = -1;
      let disabled = true;
      let selectableTimes = allTimesString;
      let selectableTimesAfter = allTimesString;
      return timeslots.map((timeslot, index) => {
        return (
          <SelectedTimeslot
            datefrom={timeslot.datefrom}
            dateto={timeslot.dateto}
            id={index}
            disabled={disabled}
            selectableTimes={selectableTimes}
            selectableTimesAfter={selectableTimesAfter}
            buttons={true}
            pricing={pricing}
            allPrices={allPrices}
            setAllPrices={setAllPrices}
            className={classes.timeslot}
            price={timeslot.price}
            error={timeslot.error}
          />
        );
      });
    } else {
      return <div />;
    }
  };

  React.useEffect(() => {
    updateTotalPrice();
  }, [timeslots]);

  const updateTotalPrice = () => {
    let sum = 0;
    timeslots.forEach(element => {
      sum += element.price;
    });
    setTotalPrice(sum);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  //render
  return (
    <div>
      <Chip label={`Sessions: ${timeslots.length}`} />
      {timeslots.find(element => element.error) ? (
        <Button
          aria-describedby={id}
          variant="contained"
          color="primary"
          onClick={event => setAnchorEl(event.currentTarget)}
          className={classes.errorBadge}
        >
          Session Error
        </Button>
      ) : null}
      <div className={classes.timeslots}>
        <RenderTimeslots />
      </div>
      <Typography className={classes.priceLabel}>
        {"Total: $" + totalPrice.toFixed(2)}
      </Typography>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <ul>
          <li>There should be 15 min interval between sessions</li>
          <li>Minimum booking time is 15 mins</li>
        </ul>
      </Popover>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    timeslots: state.virtualInstructorReducer.timeslots
  };
};

export default connect(
  mapStateToProps,
  null
)(SelectedBookingTimeslots);
