import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";

import image from "../../../materials/HomePage/ski-lessons-at-cardrona-alpine-resort.png";

import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import IntroSection from "../../../components/virtualLessons/profile/IntroSection";
import MoreInfo from "../../../components/virtualLessons/moreInfo";
import sampleImg from "../../../materials/HomePage/campfire_img.jpg";

import Header from "../../../components/Common/Home/HeaderSection.js";

import Timeline from "react-calendar-timeline";
// make sure you include the timeline stylesheet or the timeline will not be styled
import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";

const useStyles = makeStyles({
  arrowIcon: {
    transform: "rotate(180deg)"
  },
  backButton: {
    margin: "3em",
    borderColor: "#FF6161",
    color: "#FF6161"
  }
});

const InstructorProfile = props => {
  const classes = useStyles();
  const searchCard = false;
  const slogan = "Browse & Book Private Instructors";
  const version = "instructor";
  const theme = useTheme();
  const [startdate, setStartdate] = React.useState(new Date());

  const breakpoints = {
    matchesSm: useMediaQuery(theme.breakpoints.down("sm")),
    matchesXs: useMediaQuery(theme.breakpoints.down("xs")),
    matchesMd: useMediaQuery(theme.breakpoints.down("md")),
    matchesLg: useMediaQuery(theme.breakpoints.down("lg"))
  };

  const fakeHost = props.instructorData;
  let rootSize = classes.root;
  if (breakpoints.matchesXs) {
    rootSize = classes.rootXs;
  } else if (breakpoints.matchesMd) {
    rootSize = classes.rootMd;
  }

  const groups = [
    { id: 1, title: "Student 1" },
    { id: 2, title: "Student 2" },
    { id: 3, title: "Student 3" },
    { id: 4, title: "Student 4" }
  ];

  const items = [
    {
      id: 1,
      group: 1,
      title: "item 1",
      start_time: moment(),
      end_time: moment().add(1, "hour")
    },
    {
      id: 2,
      group: 2,
      title: "item 2",
      start_time: moment().add(-0.5, "hour"),
      end_time: moment().add(0.5, "hour")
    },
    {
      id: 3,
      group: 1,
      title: "item 3",
      start_time: moment().add(2, "hour"),
      end_time: moment().add(3, "hour")
    }
  ];

  return (
    <div>
      <Grid container justify="center" alignItems="center" spacing={2}>
        <Header
          searchCard={searchCard}
          slogan={slogan}
          version={version}
          image={image}
        />
        <Grid
          container
          justify="flex-start"
          alignItems="flex-start"
          item
          sm={12}
          xs={12}
        >
          <Button
            className={classes.backButton}
            variant="outlined"
            startIcon={<ArrowRightAltIcon className={classes.arrowIcon} />}
          >
            Back
          </Button>
        </Grid>
        <Grid
          container
          justify="center"
          alignItems="center"
          item
          sm={10}
          xs={10}
        >
          <Grid container item spacing={2}>
            <IntroSection hostId={fakeHost.id} breakpoints={breakpoints} />
            <Grid xs={12} />
            <MoreInfo
              hostId={fakeHost.id}
              updateTime={setStartdate}
              instructorData={props.instructorData}
            />
            <div>Your Bookings : </div>
            <div
              style={{
                overflowX: "scroll",
                border: "1px solid red"
              }}
            >
              <Timeline
                groups={groups}
                items={items}
                defaultTimeStart={moment().add(-12, "hour")}
                defaultTimeEnd={moment().add(12, "hour")}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    instructorData: state.virtualInstructorReducer.data.filter(
      instructor => instructor.id == ownProps.match.params.virtInstructorId
    )[0]
  };
}

export default connect(mapStateToProps)(withRouter(InstructorProfile));
