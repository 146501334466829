import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ListSubheader from "@material-ui/core/ListSubheader";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import { connect } from "react-redux";

const useStyles = makeStyles({
  textField: {
    height: "45px",
    width: "100%",
    fontSize: "0.5em",
    fontSize: 15,
    fontFamily: "PT Sans Narrow",
    padding: "1em",
    border: "10px",
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    borderRadius: "0px",
    marginBottom: "20px",
    textAlign: "left",
    "& .MuiOutlinedInput-input": {
      padding: 0
    },
    "& .PrivateNotchedOutline-root-43": {
      border: "none"
    }
  },
  label: {
    color: "rgb(255,97,97)"
  },
  inputSelect: {
    marginTop: "0.2em",
    textAlign: "center",
    flexWrap: "wrap",
    display: "flex"
  },
  listRoot: {
    whiteSpace: "normal",
    width: "18em",
    height: "2.1em"
  },
  subHeaderRoot: {
    color: "#1277B8",
    lineHeight: 1.3,
    paddingLeft: "2em",
    fontWeight: 400,
    fontFamily: "PT Sans Narrow"
  },
  subHeaderRoot2: {
    color: "#1277B8",
    lineHeight: 1.3,
    paddingLeft: "2.8em",
    fontWeight: 400,
    fontSize: 10,
    whiteSpace: "normal",
    fontFamily: "PT Sans Narrow"
  },
  primary: {
    textAlign: "left",
    fontSize: 14,
    color: "#505050",
    fontWeight: 300,
    fontFamily: "PT Sans Narrow"
  }
});

const SpecialisationFilter = props => {
  const hosts = props.hosts;
  console.log(hosts);

  const classes = useStyles();
  const [selectedInterests, setSelectedInterests] = useState([
    "General tour of the slopes"
  ]);
  const {
    generalConcierge,
    generalAssistance,
    activities,
    tours,
    childMindingServices,
    expertiseInterests
  } = props;

  const handleInterests = event => {
    setSelectedInterests(event.target.value);
  };

  return (
    <div>
      <Select
        multiple
        variant="outlined"
        value={selectedInterests}
        onChange={handleInterests}
        renderValue={selected => selected.join(", ")}
        className={classes.textField}
      >
        <ListSubheader classes={{ root: classes.subHeaderRoot }} disableSticky>
          PRE-ARRIVAL INFO
        </ListSubheader>
        <ListSubheader classes={{ root: classes.subHeaderRoot }} disableSticky>
          General Concierge
        </ListSubheader>
        {generalConcierge.map(interest => (
          <MenuItem
            key={interest}
            value={interest}
            classes={{ root: classes.listRoot }}
          >
            <Checkbox
              checked={selectedInterests.indexOf(interest) > -1}
              size="small"
            />
            <ListItemText
              primary={interest}
              className={classes.listitem}
              classes={{ primary: classes.primary }}
            />
          </MenuItem>
        ))}
        <ListSubheader classes={{ root: classes.subHeaderRoot }} disableSticky>
          POST-ARRIVAL
        </ListSubheader>
        <ListSubheader classes={{ root: classes.subHeaderRoot }} disableSticky>
          General Assistance
        </ListSubheader>
        {generalAssistance.map(interest => (
          <MenuItem
            key={interest}
            value={interest}
            classes={{ root: classes.listRoot }}
          >
            <Checkbox
              checked={selectedInterests.indexOf(interest) > -1}
              size="small"
            />
            <ListItemText
              primary={interest}
              className={classes.listitem}
              classes={{ primary: classes.primary }}
            />
          </MenuItem>
        ))}
        <ListSubheader classes={{ root: classes.subHeaderRoot }} disableSticky>
          Activities
        </ListSubheader>
        {activities.map(interest => (
          <MenuItem
            key={interest}
            value={interest}
            classes={{ root: classes.listRoot }}
          >
            <Checkbox
              checked={selectedInterests.indexOf(interest) > -1}
              size="small"
            />
            <ListItemText
              primary={interest}
              className={classes.listitem}
              classes={{ primary: classes.primary }}
            />
          </MenuItem>
        ))}
        <ListSubheader classes={{ root: classes.subHeaderRoot }} disableSticky>
          Tours
        </ListSubheader>
        {tours.map(interest => (
          <MenuItem
            key={interest}
            value={interest}
            classes={{ root: classes.listRoot }}
          >
            <Checkbox
              checked={selectedInterests.indexOf(interest) > -1}
              size="small"
            />
            <ListItemText
              primary={interest}
              className={classes.listitem}
              classes={{ primary: classes.primary }}
            />
          </MenuItem>
        ))}
        <ListSubheader classes={{ root: classes.subHeaderRoot }} disableSticky>
          Child-minding Services
        </ListSubheader>
        <ListSubheader classes={{ root: classes.subHeaderRoot2 }} disableSticky>
          All child-minding hosts have Working With Children
        </ListSubheader>
        <ListSubheader classes={{ root: classes.subHeaderRoot2 }} disableSticky>
          certificates
        </ListSubheader>
        {childMindingServices.map(interest => (
          <MenuItem
            key={interest}
            value={interest}
            classes={{ root: classes.listRoot }}
          >
            <Checkbox
              checked={selectedInterests.indexOf(interest) > -1}
              size="small"
            />
            <ListItemText
              primary={interest}
              className={classes.listitem}
              classes={{ primary: classes.primary }}
            />
          </MenuItem>
        ))}
        <ListSubheader classes={{ root: classes.subHeaderRoot }} disableSticky>
          EXPERTISE/INTERESTS
        </ListSubheader>
        {expertiseInterests.map(interest => (
          <MenuItem
            key={interest}
            value={interest}
            classes={{ root: classes.listRoot }}
          >
            <Checkbox
              checked={selectedInterests.indexOf(interest) > -1}
              size="small"
            />
            <ListItemText
              primary={interest}
              className={classes.listitem}
              classes={{ primary: classes.primary }}
            />
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    hosts: state.exploreHostsReducer.fakeHostData,
    language: state.exploreHostsReducer.language,
    numberOfTravellers: state.exploreHostsReducer.numberOfTravellers,
    experienceLevel: state.exploreHostsReducer.experienceLevel,
    generalConcierge: state.exploreHostsReducer.generalConcierge,
    generalAssistance: state.exploreHostsReducer.generalAssistance,
    activities: state.exploreHostsReducer.activities,
    tours: state.exploreHostsReducer.tours,
    childMindingServices: state.exploreHostsReducer.childMindingServices,
    expertiseInterests: state.exploreHostsReducer.expertiseInterests,
    pricing: state.exploreHostsReducer.pricing
  };
}

export default connect(mapStateToProps)(SpecialisationFilter);
