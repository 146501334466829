import React from "react";
import { connect } from "react-redux";

import Account from "../../../components/Common/MyAccount/Account";

import {
  editName,
  editBio,
  editCountrycode,
  editPhonenumber,
  editEmail,
  editPassword,
  editGender,
  editExperience,
  editAge,
  editExperienceIns,
  editExperienceLocal,
  editLanguages,
  editAbilities,
  editDisabilities,
  editSportSpecial,
  editPreferlanguage,
  editPrefercurrency,
  editTimezone,
  editQualifications,
  editResorts,
  editCountry,
  editSpecialiaties,
  editTargetaudience
} from "../../../redux/actions/myAccountAction";

function mapStateToProps(state) {
  return {
    studentData: state.myAccountReducer.myaccount,
    filterData: state.myAccountReducer.filterValues
  };
}

const mapDispatchToProps = dispatch => {
  return {
    _editName: name => dispatch(editName(name)),
    _editBio: bio => dispatch(editBio(bio)),
    _editCountrycode: countrycode => dispatch(editCountrycode(countrycode)),
    _editPhonenumber: phonenumber => dispatch(editPhonenumber(phonenumber)),
    _editEmail: email => dispatch(editEmail(email)),
    _editPassword: password => dispatch(editPassword(password)),
    _editGender: gender => dispatch(editGender(gender)),
    _editAge: age => dispatch(editAge(age)),
    _editExperience: experience => dispatch(editExperience(experience)),
    _editExperienceIns: experienceIns =>
      dispatch(editExperienceIns(experienceIns)),
    _editExperienceLocal: experienceLocal =>
      dispatch(editExperienceLocal(experienceLocal)),
    _editLanguages: languages => dispatch(editLanguages(languages)),
    _editAbilities: abilities => dispatch(editAbilities(abilities)),
    _editDisabilities: disabilities => dispatch(editDisabilities(disabilities)),
    _editSportSpecial: sportAndSpecialisation =>
      dispatch(editSportSpecial(sportAndSpecialisation)),
    _editPreferlanguage: preferLanguage =>
      dispatch(editPreferlanguage(preferLanguage)),
    _editPrefercurrency: preferCurrency =>
      dispatch(editPrefercurrency(preferCurrency)),
    _editTimezone: timeZone => dispatch(editTimezone(timeZone)),
    _editQualifications: qualifications =>
      dispatch(editQualifications(qualifications)),
    _editResorts: resorts => dispatch(editResorts(resorts)),
    _editCountry: country => dispatch(editCountry(country)),
    _editSpecialiaties: specialiaties =>
      dispatch(editSpecialiaties(specialiaties)),
    _editTargetaudience: targetAudience =>
      dispatch(editTargetaudience(targetAudience))
  };
};

function MyAccount(props) {
  return (
    <div>
      <Account {...props} />
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyAccount);
