import React from "react";
import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import classNames from "classnames";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Typography, Grid, useMediaQuery } from "@material-ui/core";

import Flag from "../../../components/virtualLessons/Flag";
import AvailabilityCard from "../../../components/virtualLessons/Availability/AvailabilityCard.js";
import InstructorCalendar from "../../../components/virtualLessons/instructorProfileCalendar";
import GanttCalendar from "../../../components/virtualLessons/ganttCalendar";
import BookingCard from "../../../components/virtualLessons/Booking/BookingCard";

const useStyles = makeStyles(theme => ({
  smallSectionHeading: {
    margin: "0, 1",
    width: "120%",
    fontSize: 18,
    color: "#1277B8",
    fontWeight: "bold",
    fontFamily: "PT Sans Narrow",
    lineHeight: "14.62%",
    paddingTop: "2rem",
    paddingBottom: "2rem"
  },
  root: {
    width: "100%",
    height: "25.05px",
    fontWeight: "normal",
    fontSize: 15,
    lineHeight: "140.62%",
    alignItems: "left",
    color: "#000000",
    padding: "0px"
  },
  typograhyFormat: {
    fontSize: 16,
    lineHeight: "22.50px",
    color: "#1277B8",
    marginTop: "2rem",
    paddingBottom: "1rem",
    fontWeight: "bolder",
    width: "100%"
  },
  lableFormat: {
    display: "block",
    color: "rgba(18, 119, 184, 0.6)"
  },
  mainDiv: {
    margin: "0 auto",
    width: "80%",
    paddingTop: "4rem"
  },
  flagContainer: {
    display: "inline-block"
  },
  spanTextFormat: {
    fontSize: 16
  },
  calendarFormat: {
    margin: "0, auto",
    width: "100%",
    paddingLeft: "10%"
  },
  commonFormat: {
    fontStyle: "normal",
    fontFamily: "PT Sans Narrow"
  }
}));

const InstructorIntroSection = props => {
  const classes = useStyles();
  const {
    resorters,
    sports,
    specialisations,
    languages,
    experience,
    qulification
  } = props;

  const Languages = languages.map(value => (
    <div className={classes.flagContainer}>
      <Flag language={value} key={value} />
    </div>
  ));
  const groups = [
    { id: 1, title: "Booked Slots" },
    { id: 2, title: "" },
    { id: 3, title: "" },
    { id: 4, title: "" }
  ];

  //temporary dummy data
  const items = [
    {
      id: 1,
      group: 1,
      title: "item 1",
      start_time: moment(),
      end_time: moment().add(1, "hour")
    },
    {
      id: 3,
      group: 1,
      title: "item 3",
      start_time: moment().add(2, "hour"),
      end_time: moment().add(3, "hour")
    }
  ];

  const [startdate, setStartdate] = React.useState(new Date());
  const theme = useTheme();

  const { bookings, unavailability, handleItemMove, handleItemResize } = props;
  const breakpoints = {
    matchesSm: useMediaQuery(theme.breakpoints.down("sm")),
    matchesXs: useMediaQuery(theme.breakpoints.down("xs")),
    matchesMd: useMediaQuery(theme.breakpoints.down("md")),
    matchesLg: useMediaQuery(theme.breakpoints.down("lg"))
  };
  const instructorData = props.instructorData;
  let rootSize = classes.root;
  if (breakpoints.matchesXs) {
    rootSize = classes.rootXs;
  } else if (breakpoints.matchesMd) {
    rootSize = classes.rootMd;
  }

  //fake data
  const priceList = [
    { key: 1, displayText: "Full day (up to 2 people)", amount: "$504.00" },
    { key: 2, displayText: "Full day (up to 3 people)", amount: "$505.00" },
    { key: 3, displayText: "Full day (up to 4 people)", amount: "$506.00" },
    { key: 4, displayText: "Full day (up to 5 people)", amount: "$507.00" }
  ];

  const updateTime = props.updateTime;

  //Will be able to edit availability as instructor
  //Will be able to make bookings as student
  let calendarCard = (
    <BookingCard
      instructorData={props.instructorData}
      updateTime={updateTime}
    />
  );

  if (props.isOwnProfile) {
    calendarCard = (
      <AvailabilityCard
        instructorData={props.instructorData}
        updateTime={updateTime}
      />
    );
  }

  //This allows the items to be colored
  const itemRenderer = ({
    item,
    itemContext,
    getItemProps,
    getResizeProps
  }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    const backgroundColor = item.bgColor;
    const borderColor = item.color;
    return (
      <div
        {...getItemProps({
          style: {
            background: item.color,
            backgroundColor: item.color,
            color: item.color,
            borderColor: item.color,
            borderStyle: "solid",
            borderWidth: 1,
            borderRadius: 4,
            borderLeftWidth: itemContext.selected ? 3 : 1,
            borderRightWidth: itemContext.selected ? 3 : 1
          }
        })}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ""}

        <div
          className="rct-item-content"
          style={{ maxHeight: `${itemContext.dimensions.height}` }}
        >
          {itemContext.title}
        </div>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ""}
      </div>
    );
  };
  return (
    // this is the main Grid Container work as  (DIV)
    <div className={classes.mainDiv}>
      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        spacing={2}
        justify="space-around"
      >
        <Grid container item sm={6} xs={12} lg={6} spacing={12}>
          <Grid container item spacing={4}>
            <Grid container item xs={12} lg={12}>
              {/* this is the 1st row which has "Age"+ "Gender"+ "Phisical Diability" */}

              <Grid item xs={12} lg={4}>
                <Typography
                  className={classNames(
                    classes.typograhyFormat,
                    classes.commonFormat
                  )}
                >
                  RESORTS
                </Typography>
                {resorters.map(resorter => (
                  <span
                    className={classNames(
                      classes.spanTextFormat,
                      classes.commonFormat
                    )}
                  >
                    {resorter}
                    <br />
                  </span>
                ))}
              </Grid>

              <Grid item xs={12} lg={4}>
                <Typography
                  className={classNames(
                    classes.typograhyFormat,
                    classes.commonFormat
                  )}
                >
                  SPORT
                </Typography>
                {sports.map(sport => (
                  <span
                    className={classNames(
                      classes.spanTextFormat,
                      classes.commonFormat
                    )}
                  >
                    {sport}
                    <br />
                  </span>
                ))}
              </Grid>

              <Grid item xs={12} lg={4}>
                <Typography
                  className={classNames(
                    classes.typograhyFormat,
                    classes.commonFormat
                  )}
                >
                  SPECIALISATIONS
                </Typography>
                {specialisations.map(specialisation => (
                  <span
                    className={classNames(
                      classes.spanTextFormat,
                      classes.commonFormat
                    )}
                  >
                    {specialisation}
                    <br />
                  </span>
                ))}
              </Grid>
            </Grid>

            {/* this is the 2nd row which has "Langange"+ "Years of Experince"+ "Ability" */}
            <Grid container item xs={12} lg={12}>
              <Grid item xs={12} lg={4}>
                <Typography
                  className={classNames(
                    classes.typograhyFormat,
                    classes.commonFormat
                  )}
                >
                  LANGUAGES
                </Typography>
                <span
                  className={classNames(
                    classes.spanTextFormat,
                    classes.commonFormat
                  )}
                >
                  {Languages}
                </span>
                {/* <img src={Flag} /> */}
              </Grid>

              <Grid item xs={12} lg={4}>
                <Typography
                  className={classNames(
                    classes.typograhyFormat,
                    classes.commonFormat
                  )}
                >
                  YEARS OF EXPERIENCE
                </Typography>
                <span
                  className={classNames(
                    classes.spanTextFormat,
                    classes.commonFormat
                  )}
                >
                  {experience}
                </span>
              </Grid>

              <Grid item xs={12} lg={4}>
                <Typography
                  className={classNames(
                    classes.typograhyFormat,
                    classes.commonFormat
                  )}
                >
                  QUALIFICATIONS
                </Typography>

                <Grid item xs={12} spacing={2}>
                  <Grid item xs={12} lg={3}>
                    {qulification.map(qulification => (
                      <span
                        className={classNames(
                          classes.spanTextFormat,
                          classes.commonFormat
                        )}
                      >
                        {qulification}
                        <br />
                      </span>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* secondLine of items */}
          <Grid container item>
            <Grid
              container
              item
              xs={12}
              justify="flex-start"
              alignItems="center"
            >
              <div
                className={classNames(
                  classes.typograhyFormat,
                  classes.commonFormat
                )}
              >
                PRICE
              </div>
            </Grid>

            <Grid container item xs={12} spacing={1}>
              {priceList.map((priceItem, i) => {
                return (
                  <Grid container item sm={6} xs={12} key={"g1" + i}>
                    <Grid container item xs={12} key={"g2" + i}>
                      <div
                        className={classNames(
                          classes.root,
                          classes.commonFormat
                        )}
                        key={"d1" + i}
                      >
                        {priceList[i]["displayText"]}
                      </div>
                    </Grid>
                    <Grid container item xs={12} key={"g3" + i}>
                      <div
                        className={classNames(
                          classes.root,
                          classes.commonFormat
                        )}
                        key={"d2" + i}
                      >
                        {priceList[i]["amount"]}
                      </div>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={12} lg={6} spacing={2}>
          <div className={classes.calendarFormat}>{calendarCard}</div>
        </Grid>

        <div className={classes.smallSectionHeading}>
          <div
            style={{ overflowX: "scroll", border: "1px solid red" }}
            className={classes.grantCalendarFormat}
          >
            <GanttCalendar
              items={items}
              itemRenderer={itemRenderer}
              groups={groups}
              startdate={startdate}
              handleItemMove={handleItemMove}
              handleItemResize={handleItemResize}
            />
          </div>
        </div>

        {/* {calendar} */}
      </Grid>
    </div>
  );
};

export default InstructorIntroSection;
