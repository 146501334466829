import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import agent from "../../../agent";
import {
  incrementActiveStep,
  decrementActiveStep,
  incrementActiveStepHost,
  decrementActiveStepHost,
  updateUserProfile
} from "../../../redux/actions/stepperActions.js";

import { Grid, Button } from "@material-ui/core";

import useStyles from "./ClientInfoStyles";

import ProfilePicture from "../../serviceMarket/profile/form/ProfilePictureForm";
import Bio from "../../serviceMarket/profile/form/BioForm";
import Languages from "../../serviceMarket/profile/form/LanguageForm";
import Nationality from "../../serviceMarket/profile/form/NationalityForm";
import AbilityLevel from "../../serviceMarket/profile/form/AbilityForm";
import { getProfileData } from "../../../redux/actions/profileDataAction.js";

function validate(values) {
  let errors = {};

  if (!values.bio) {
    errors.bio = "Required";
  }

  if (values.disability === "yes" && !values.disabilityForm) {
    errors.disability = "Required";
  }

  if (values.allergy === "yes" && !values.allergyForm) {
    errors.allergy = "Required";
  }

  if (!values.gender) {
    errors.gender = "Required";
  }

  if (!values.age) {
    errors.age = "Required";
  }

  if (!values.languages) {
    errors.languages = "Required";
  }

  if (!values.nationality) {
    errors.nationality = "Required";
  }

  return errors;
}

const useClasses = makeStyles(theme => ({
  nextButton: {
    width: "102px",
    height: "2.5em",
    borderRadius: 100,
    textAlign: "center"
  },
  buttonRoot: {
    backgroundColor: "#FF6161"
  },
  buttonLabel: {
    color: "white",
    fontWeight: 700,
    letterSpacing: "1%",
    fontFamily: "PT Sans"
  },
  backButton: {
    width: "60%",
    marginRight: "1em",
    fontFamily: "PT Sans",
    border: "white"
  },
  backButtonLabel: {
    color: "#FF6161",
    fontWeight: 700,
    letterSpacing: "1%",
    fontFamily: "PT Sans"
  }
}));

let ClientInfo = props => {
  const styles = useStyles();
  const classes = useClasses();

  const { user_id } = props.userData.data;

  const { updateProfileSuccess, updateProfileError, activeStep } = props;

  let [isLoading, setIsLoading] = useState(true);

  let [profilePicture, setProfilePicture] = useState();
  let [bio, setBio] = useState("");
  let [nationality, setNationality] = useState();
  let [languages, setLanguages] = useState([]);
  let [skiLevel, setSkiLevel] = useState("");
  let [snowboardLevel, setSnowboardLevel] = useState("");

  useEffect(() => {
    if (updateProfileSuccess) {
      console.log("User Information was successfully update!");
      return props.onSubmit();
    }
  }, [updateProfileSuccess]);

  useEffect(() => {
    if (updateProfileError) {
      alert(updateProfileError);
    }
  }, [updateProfileError]);

  useEffect(() => {
    setIsLoading(true);

    if (props.userProfile != undefined) {
      setProfilePicture(props.userProfile.profile_picture);
      setBio(props.userProfile.bio);
      if (props.userProfile.country != undefined) {
        setNationality(props.userProfile.country);
      }
      setLanguages(props.userProfile.languages);
      if (props.userProfile.skiLevel != undefined) {
        setSkiLevel(props.userProfile.skiLevel);
      }
      if (props.userProfile.snowLevel != undefined) {
        setSnowboardLevel(props.userProfile.snowLevel);
      }
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [props.userProfile]);

  // Upload profile image to AWS and update data in database
  const uploadProfileImage = async () => {
    if (typeof profilePicture === "object") { // just in case that profilePicture is not changed, which is a link string as fetched from database
      const profileImageData = new FormData();
      profileImageData.append("profile_picture", profilePicture);
      try {
        await agent.Users.uploadProfileImage(user_id, profileImageData);
        const res = await agent.Users.getClientProfileImage(user_id);
        const { profile_picture } = res.data;
        setProfilePicture(profile_picture);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleBackButton = event => {
    props.decrementActiveStep(decrementActiveStep());
  };
  const handleSubmit = async event => {
    event.preventDefault();

    if (
      profilePicture == null ||
      bio == null ||
      nationality == null ||
      languages.length == 0 ||
      skiLevel == 0 || snowboardLevel == 0
    ) {
      alert("Please enter required information");
    } else {
      let nationalityId = nationality.id;
      let languagesArray = [];
      languages.forEach(item => {
        languagesArray.push(item.id);
      });

      try{
        const res = await agent.Users.updateUser(
          user_id,
          bio,
          nationalityId,
          languagesArray,
          skiLevel,
          snowboardLevel,
          activeStep + 1);

        props.updateUserProfile(res.status);
      }catch(error){
        props.updateUserProfile(error);
      }

      await uploadProfileImage();

      props.getProfileData(user_id);
    }
  };

  return (
    <div>
      {isLoading ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={0}
          direction="column"
          className={styles.whiteBackgroudColor}
        >
          <Grid
            container
            justify="center"
            alignItems="flex-start"
            xs={12}
            sm={10}
            md={8}
            lg={6}
            spacing={2}
            direction="row"
            style={{ backgroundColor: "white", padding: "2rem 0px" }}
          >
            <div className="loader-container">
              <div className="loader" />
            </div>
          </Grid>
        </Grid>
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid
            container
            justify="center"
            alignItems="center"
            spacing={0}
            direction="column"
            className={styles.whiteBackgroudColor}
          >
            {/* profile picture, bio */}
            <Grid
              container
              justify="center"
              alignItems="flex-start"
              xs={12}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              direction="row"
              style={{ backgroundColor: "white", padding: "2rem 0px" }}
            >
              <ProfilePicture
                profilePicture={profilePicture}
                setProfilePicture={setProfilePicture}
              />
              <Bio bio={bio} setBio={setBio} />
            </Grid>

            {/* nationality, languages, ability level */}
            <Grid
              container
              justify="center"
              alignItems="flex-start"
              xs={12}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              direction="row"
              style={{ padding: "2rem 0px" }}
            >
              {/* Nationality */}
              <Nationality
                nationality={nationality}
                setNationality={setNationality}
              />
              {/* Languages */}
              <Languages setLanguages={setLanguages} languages={languages} />
              {/* Abilities */}
              <AbilityLevel
                skiLevel={skiLevel}
                setSkiLevel={setSkiLevel}
                snowboardLevel={snowboardLevel}
                setSnowboardLevel={setSnowboardLevel}
              />
            </Grid>

            {/* button */}
            <Grid
              container
              item
              xs={12}
              sm={10}
              md={8}
              lg={6}
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              className={classes.buttonContainer}
              style={{ padding: "3rem 1.5rem" }}
            >
              <Grid item>
                {props.activeStep < 1 ? null : (
                  <Button
                    className={classes.backButton}
                    classes={{ label: classes.backButtonLabel }}
                    variant="outlined"
                    // style={{color:"#FF6161"}}
                    onClick={handleBackButton}
                  >
                    Back
                  </Button>
                )}
              </Grid>

              <Grid item>
                <Button
                  type="submit"
                  className={classes.nextButton}
                  classes={{
                    label: classes.buttonLabel,
                    root: classes.buttonRoot
                  }}
                  variant="contained"
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </div>
  );
};

// destroyOnUnmount - saves it in state
ClientInfo = reduxForm({
  form: "signup",
  destroyOnUnmount: false,
  validate
})(ClientInfo);

function mapDispatchToProps(dispatch) {
  return {
    incrementActiveStep: () => dispatch(incrementActiveStep()),
    decrementActiveStep: () => dispatch(decrementActiveStep()),
    incrementActiveStepHost: () => dispatch(incrementActiveStepHost()),
    decrementActiveStepHost: () => dispatch(decrementActiveStepHost()),
    updateUserProfile: user => dispatch(updateUserProfile(user)),
    getProfileData: client_id => dispatch(getProfileData(client_id))
  };
}

function mapStateToProps(state, ownProps) {
  return {
    activeStep: state.stepperReducer.activeStep,
    activeStepHost: state.stepperReducer.activeStepHost,
    loading: state.stepperReducer.loading,
    updateProfileSuccess: state.stepperReducer.updateProfileSuccess,
    updateProfileError: state.stepperReducer.updateProfileError,
    userData: state.loginReducer.userData,
    userProfile: state.stepperReducer.userProfile
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientInfo);
