import React from "react";

export default class LikeCounter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false
    };
  }

  handleVote = () => {
    this.setState({ disabled: true }, () =>
      this.props.onVote(this.props.counter)
    );
  };
  render() {
    return (
      <div>
        <span>Vote for this</span>
        <button
          style={{ marginRight: 8, marginLeft: 8 }}
          disabled={this.state.disabled}
          onClick={this.handleVote}
        >
          <i
            className="fas fa-thumbs-up"
            style={{
              color: this.state.disabled === true && "rgb(32, 120, 244)"
            }}
          />
        </button>
        {this.state.disabled === true && <span>Thank you for voting!</span>}
      </div>
    );
  }
}
