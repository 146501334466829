import {
  CLOSE_SECTION,
  OPEN_SECTION,
  SAVE_SECTION
} from "../actions/action-types";
import {
  RESORT,
  PROFILE,
  WHEN_WHO,
  SLEEP,
  DOING,
  LIFT_PASS,
  EQUIPMENT,
  LESSONS,
  CHECKOUT
} from "../../components/lessonsEnquiry/Book/section/section-types";

const initialState = {
  open: {
    [RESORT]: true,
    [PROFILE]: false,
    [WHEN_WHO]: false,
    [SLEEP]: false,
    [DOING]: false,
    [LIFT_PASS]: false,
    [EQUIPMENT]: false,
    [LESSONS]: false,
    [CHECKOUT]: false
  },
  completed: {
    [RESORT]: false,
    [PROFILE]: false,
    [WHEN_WHO]: false,
    [SLEEP]: false,
    [DOING]: false,
    [LIFT_PASS]: false,
    [EQUIPMENT]: false,
    [LESSONS]: false,
    [CHECKOUT]: false
  }
};

function sectionReducer(state = initialState, action) {
  if (action.type === OPEN_SECTION) {
    return {
      ...state,
      open: {
        ...state.open,
        [action.payload]: true
      }
    };
  } else if (action.type === CLOSE_SECTION) {
    return {
      ...state,
      open: {
        ...state.open,
        [action.payload]: false
      }
    };
  } else if (action.type === SAVE_SECTION) {
    return {
      ...state,
      completed: {
        ...state.completed,
        [action.payload]: true
      }
    };
  }

  return state;
}

export default sectionReducer;
