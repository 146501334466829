import {
  SAVE_IMAGE_URL_TO_STORE,
  SAVE_CERTIFICATE_URL_TO_STORE,
  STORE_IMAGE_UPLOAD_EVENT,
  STORE_QUALIFICATION_UPLOAD_EVENT
} from "./action-types";

export const saveImageUrlToStore = url => {
  console.log("URL in the redux action", url);
  return {
    type: SAVE_IMAGE_URL_TO_STORE,
    payload: url
  };
};

export const saveCertificateUrlToStore = url => {
  return {
    type: SAVE_CERTIFICATE_URL_TO_STORE,
    payload: { url }
  };
};

export const storeImageUploadEvent = event => {
  return {
    type: STORE_IMAGE_UPLOAD_EVENT,
    payload: event
  };
};

export const storeQualificationUploadEvent = event => {
  return {
    type: STORE_QUALIFICATION_UPLOAD_EVENT,
    payload: event
  };
};
