import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

//Simple button in the shape of a cross, may be used for exiting
const ExitButton = props => {
  const { onClick } = props;
  return (
    <IconButton onClick={onClick}>
      <CloseIcon color="disabled" />
    </IconButton>
  );
};
export default ExitButton;
