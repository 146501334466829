import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Gallery from "../../../components/virtualLessons/InstructorGalleryCarousel.js";
import Reviews from "../../../components/virtualLessons/InstructorReviewCarousel.js";
import Grid from "@material-ui/core/Grid";
import HostIntroSection from "../../../components/exploreHosts/profile/HostIntroSection.js";
import HostMyProfile from "../../../components/exploreHosts/profile/HostMyProfile.js";
import Button from "@material-ui/core/Button";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import classNames from "classnames";
import FooterBar from "../../../components/Common/Footer/FooterBar.js";

import {
  UPDATE_AVATAR,
  UPDATE_NAME,
  UPDATE_RATING,
  UPDATE_BIO,
  UPDATE_RESORTS,
  UPDATE_LANGUAGES,
  UPDATE_SUITABILITY,
  UPDATE_PRE_ARRIVAL,
  UPDATE_POST_ARRIVAL,
  UPDATE_BEEN_A_LOCAL_FOR,
  UPDATE_TOURS,
  UPDATE_EXPERTISE_INTERESTS,
  UPDATE_ACTIVITIES,
  UPDATE_CHILD_MINDING_SERVICES,
  UPDATE_PRICES
} from "../../../redux/actions/action-types";
import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
  main: {
    margin: "2em"
  },
  typograhyFormat: {
    fontSize: 20,
    lineHeight: "22.50px",
    textAlign: "left",
    color: "#1277B8",
    marginTop: "2rem",
    marginBottom: "2rem",
    marginLeft: 50,
    fontWeight: "bolder",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18
    }
  },
  arrowIcon: {
    transform: "rotate(180deg)"
  },
  backButton: {
    margin: "3em",
    borderColor: "#FF6161",
    color: "#FF6161"
  },
  textFormat: {
    fontSize: 18,
    fontWeight: "bolder",
    alignContent: "center",
    alignItems: "center",
    color: "#000000",
    marginLeft: 40
  },
  cardFormat: {
    alignContent: "center",
    alignItems: "center",
    backgroundColor: "#D1E3EF",
    height: "16rem",
    margin: "0rem -5%",
    padding: "3rem 0"
  },
  btnFormat: {
    backgroundColor: "#FF6161",
    color: "white",
    width: "13rem",
    borderRadius: 100
  },
  commonFormat: {
    fontStyle: "normal",
    fontFamily: "PT Sans Narrow"
  }
}));

const HostProfileVisitorView = props => {
  const { hostMyProfile, hostIntroSection } = props;

  //styling
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Grid container item sm={12} xs={12}>
        <Grid container item sm={6} xs={12} spacing={2} lg={12}>
          <Button
            className={classes.backButton}
            variant="outlined"
            startIcon={<ArrowRightAltIcon className={classes.arrowIcon} />}
          >
            Back
          </Button>
        </Grid>
      </Grid>
      {/* This is the child component called HostMyProfile  */}
      <HostMyProfile {...hostMyProfile} />

      {/* This is the child component called HostIntroSection  */}

      <HostIntroSection {...hostIntroSection} />

      <Grid container direction="Row" alignContent="center" justify="center">
        <Grid item xs={12} md={12}>
          <Typography
            className={classNames(
              classes.typograhyFormat,
              classes.commonFormat
            )}
          />

          <Reviews instructorId={1} />
        </Grid>

        <Grid item xs={12} md={12}>
          <Typography
            className={classNames(
              classes.typograhyFormat,
              classes.commonFormat
            )}
          >
            GALLERY
          </Typography>
          {/* This is the Gallery component  */}
          <Gallery instructorId={1} />
        </Grid>
      </Grid>
      <div className={classes.cardFormat}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Typography
              className={classNames(classes.textFormat, classes.commonFormat)}
            >
              Are you an instructor or host?
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              className={classNames(classes.textFormat, classes.commonFormat)}
            >
              Create a profile to connect with students and visitors today.
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justify="space-around"
            alignItems="center"
            spacing={2}
            xs={6}
          >
            <Grid item>
              <Button className={classes.btnFormat} href="/signup/hosts">
                I’m an Instructor
              </Button>
            </Grid>
            <Grid item>
              <Button className={classes.btnFormat} href="/signup/hosts">
                I’m a Host
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* This is the Footer component  */}
      <Grid container direction="Row" alignContent="center" justify="center">
        <Grid item xs={12}>
          <FooterBar />
        </Grid>
      </Grid>
    </div>
  );
};

// Reducer part
function mapStateToProps(state) {
  return {
    hostIntroSection: {
      ...state.HostProfileVisitorViewReducer.hostIntroSection
    },
    hostMyProfile: {
      ...state.HostProfileVisitorViewReducer.hostMyProfile
    }
  };
}

// Connect part
export default connect(
  mapStateToProps,
  {
    UPDATE_AVATAR,
    UPDATE_NAME,
    UPDATE_RATING,
    UPDATE_BIO,
    UPDATE_RESORTS,
    UPDATE_LANGUAGES,
    UPDATE_SUITABILITY,
    UPDATE_PRE_ARRIVAL,
    UPDATE_POST_ARRIVAL,
    UPDATE_BEEN_A_LOCAL_FOR,
    UPDATE_TOURS,
    UPDATE_EXPERTISE_INTERESTS,
    UPDATE_ACTIVITIES,
    UPDATE_CHILD_MINDING_SERVICES,
    UPDATE_PRICES
  },
  null,
  { forwardRef: true }
)(HostProfileVisitorView);
