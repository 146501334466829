import React from "react";

//helper function that returns a boolean depending on if two dates are exactly identicle
//including time
export const equalsDatetime = (date1, date2) => {
  if (typeof date1 === "undefined" || typeof date2 === "undefined")
    return false;
  return (
    new Date(
      date1.getFullYear(),
      date1.getMonth(),
      date1.getDate(),
      date1.getHours(),
      date1.getMinutes()
    ).getTime() ===
    new Date(
      date2.getFullYear(),
      date2.getMonth(),
      date2.getDate(),
      date2.getHours(),
      date2.getMinutes()
    ).getTime()
  );
};

//Returns whether two dates are the same (excluding time)
export const equalsDate = (date1, date2) => {
  return (
    new Date(
      date1.getFullYear(),
      date1.getMonth(),
      date1.getDate()
    ).getTime() ===
    new Date(date2.getFullYear(), date2.getMonth(), date2.getDate()).getTime()
  );
};

//Returns whether a date plus one day equals another date
//@date regular date
//@prev_date date you want one day to be added on to
export const equalsDatePlusOne = (date, prev_date) => {
  let plus_one = new Date(prev_date);
  plus_one.setDate(prev_date.getDate() + 1);
  return equalsDate(plus_one, date);
};

//Converts date object to string of format "HH:MM"
export const convertTimeToString = time => {
  let hours = time.getHours().toString();
  hours = hours.length > 1 ? hours + "" : "0" + hours;
  let minutes = time.getMinutes().toString();
  minutes = minutes.length > 1 ? minutes + "" : "0" + minutes;
  return hours + ":" + minutes;
};
