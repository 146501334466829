import React, { useState, useEffect, useImperativeHandle } from "react";
import { connect } from "react-redux";
import {
  selectCountry,
  selectResort,
  showResortErrors,
  updateResortErrors
} from "../../../../redux/actions/tripWhereActions";
import ResortSelector from "./ResortSelector";
import DatePicker from "react-datepicker/es";
import {
  changeTripEndDate,
  changeTripStartDate
} from "../../../../redux/actions/tripWhenActions";
import { setCountriesAndResorts } from "../../../../redux/actions/tripWhereActions";
import {
  NextButton,
  SectionTitle,
  SectionTitleSmaller,
  VerticalSpaceSM
} from "../../../../styling/styled-components/index";
import WhereSectionCustomDateInput from "../where/WhereSectionCustomDateInput";
import "../../../../styling/css/react-datepicker.css";

import { validateResort } from "./ResortSelector";
import { SectionBodyWrapper } from "../../../../styling/styled-components/sectionWrapper";
import {
  getCountriesAndResortsFromDatabase,
  getCountriesAndOpeningStatus
} from "./helper";

const datepickerSettings = {
  dateFormat: "dd/MM/yyyy",
  placeholderText: "dd/mm/yyyy"
};

const today = new Date();

const parseTimeZone = date => {
  const adjustedDate = new Date(
    date.getTime() + date.getTimezoneOffset() * 60000
  );

  return adjustedDate;
};

const ResortSection = React.forwardRef((props, ref) => {
  const endDateCalendarRef = React.createRef();

  const updateCountries = (countryResortInfoDic, startDate, endDate) => {
    if (countryResortInfoDic) {
      props.setCountriesAndResorts(
        getCountriesAndOpeningStatus(countryResortInfoDic, startDate, endDate)
      );
    } else {
      props.setCountriesAndResorts(
        getCountriesAndOpeningStatus(
          props.countryResortInfoDic,
          startDate,
          endDate
        )
      );
    }
  };

  useEffect(() => {
    async function update() {
      const result = await getCountriesAndResortsFromDatabase();
      props.setCountriesAndResorts(result);
      updateCountries(
        result.countryResortInfoDic,
        props.startDate,
        props.endDate
      );
    }
    update();
  }, []);

  // forward child function to parent
  useImperativeHandle(ref, () => ({
    validateResorts() {
      const errors = validateResort(props.resortName);
      if (Object.keys(errors).length !== 0) {
        props.updateResortErrors(errors);
        props.showResortErrors(true);
      }
    }
  }));

  // open end date datepicker when start date is set
  const handleChangeStart = date => {
    props.changeTripStartDate(date);
    if (date != null) {
      updateCountries(null, date, props.endDate);
      props.selectCountry("");
      if (date > props.endDate) {
        props.changeTripEndDate(date);
      }
      endDateCalendarRef.current.setOpen(true);
    }
  };

  const handleChangeEnd = date => {
    props.changeTripEndDate(date);
    updateCountries(null, props.startDate, date);
    props.selectCountry("");
  };

  const handleNext = () => {
    const errors = validateResort(props.resortName);
    if (Object.keys(errors).length === 0) {
      props.onNext();
    } else {
      props.updateResortErrors(errors);
      props.showResortErrors(true);
    }
  };

  const renderDates = () => {
    const startDate = props.startDate;
    const endDate = props.endDate;

    return (
      <div className="row">
        <div className="col-12 col-sm-6">
          {props.homePage ? (
            <h6>From:</h6>
          ) : (
            <SectionTitleSmaller>From:</SectionTitleSmaller>
          )}
          <DatePicker
            customInput={<WhereSectionCustomDateInput />}
            selected={startDate}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            onChange={handleChangeStart}
            minDate={today}
            {...datepickerSettings}
          />
        </div>
        <div className="col-12 col-sm-6">
          {props.homePage ? (
            <h6>To:</h6>
          ) : (
            <SectionTitleSmaller>To:</SectionTitleSmaller>
          )}
          <DatePicker
            customInput={<WhereSectionCustomDateInput />}
            selected={endDate}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            onChange={handleChangeEnd}
            ref={endDateCalendarRef}
            minDate={startDate}
            {...datepickerSettings}
          />
        </div>
      </div>
    );
  };

  return (
    <SectionBodyWrapper>
      <VerticalSpaceSM />
      <div className="container">
        {props.homePage ? (
          <div>
            <h2
              className="text-justify"
              style={{ display: "inline", marginRight: 10 }}
            >
              Select Dates
            </h2>
            <span style={{ fontSize: 11 }}>(48+ hours notice required)</span>
          </div>
        ) : (
          <SectionTitle>Select Dates</SectionTitle>
        )}
        {renderDates()}
      </div>
      <VerticalSpaceSM />
      <ResortSelector homePage={props.homePage} />
      {props.homePage ? (
        <div style={{ marginTop: "20px" }} />
      ) : (
        <div style={{ textAlign: "right" }}>
          <NextButton onClick={handleNext}>Next</NextButton>
        </div>
      )}
    </SectionBodyWrapper>
  );
});

function mapStateToProps(state) {
  return {
    startDate: state.tripWhenReducer.startDate,
    endDate: state.tripWhenReducer.endDate,
    resortName: state.tripWhereReducer.resortName,
    country: state.tripWhereReducer.country,
    countryResortInfoDic: state.tripWhereReducer.countryResortInfoDic
  };
}

export default connect(
  mapStateToProps,
  {
    setCountriesAndResorts,
    selectResort,
    selectCountry,
    changeTripStartDate,
    changeTripEndDate,
    showResortErrors,
    updateResortErrors
  },
  null,
  { forwardRef: true }
)(ResortSection);
