import React from "react";
import useStyles from "./MyAccountStyles";
import { Grid, TextField } from "@material-ui/core";

const GenderAge = ({
  studentData,
  filterData,
  _editGender,
  _editAge,
  disabled,
  select
}) => {
  const classes = useStyles();

  return (
    <Grid item container md={12} direction="row">
      <Grid item lg={2} md={2} sm={2} xs={2}>
        <div align="right" className={classes.textFieldStyle}>
          Gender:{" "}
        </div>
      </Grid>
      <Grid item lg={1} md={1} sm={1} xs={1} />
      <Grid item lg={2} md={2} sm={2} xs={9}>
        <div align="left">
          <TextField
            id="select-gender"
            variant="outlined"
            disabled={disabled}
            select={select}
            value={studentData.gender}
            onChange={event => {
              _editGender(event.target.value);
            }}
            SelectProps={{
              native: true
            }}
            className={classes.textFieldGender}
          >
            {filterData.gender.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </div>
      </Grid>
      <Grid item lg={1} md={1} sm={1} />
      <Grid item lg={1} md={1} sm={1} xs={2}>
        <div className={classes.textFieldStyle} align="right">
          Age:
        </div>
      </Grid>
      <Grid item lg={1} md={1} sm={1} xs={1} />
      <Grid item lg={2} md={2} sm={2} xs={9}>
        <div align="left">
          <input
            fullWidth
            id="cardname"
            variant="filled"
            className={classes.textField}
            size="small"
            onChange={event => _editAge(event.target.value)}
            value={studentData.age}
          />
        </div>
      </Grid>
      <Grid item lg={2} md={2} sm={2} />
    </Grid>
  );
};

export default GenderAge;
