import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import StarRating from "../../Common/StarRating/StarRating.js";
import "react-calendar-timeline/lib/Timeline.css";
import IconButton from "@material-ui/core/IconButton";
import FavoriteIcon from "@material-ui/icons/Favorite";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  root: {
    margin: "0 auto",
    width: "auto",
    marginTop: "2rem",
    alignItems: "center"
  },
  bioFieldFormat: {
    color: "#000000",
    width: "100%",
    height: "9%",
    fontSize: 14,
    resize: "none",
    border: "0"
  },
  backButton: {
    margin: "3em",
    borderColor: "#FF6161",
    color: "#FF6161"
  },
  avatarSize: {
    width: "10rem",
    height: "10rem",
    marginBottom: "15%",
    [theme.breakpoints.down("sm")]: {
      width: "10rem",
      height: "10rem"
    }
  },
  textName: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#000000",
    lineHeight: "22.5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      fontWeight: "bold",
      marginTop: "2rem",
      marginBottom: "1rem",
      alignItems: "center",
      marginLeft: "10rem"
    }
  },
  bioFieldFormat: {
    color: "#000000",
    width: "100%",
    height: "9%",
    fontSize: 14,
    resize: "none",
    border: "0"
  },
  divAvatar: {
    margin: "0 auto"
  },
  ratingFormat: {
    fontWeight: "bold",
    marginLeft: "-60%",
    width: "fit-content",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      marginLeft: "6rem"
    }
  },
  favouriteIcon: {
    width: "3rem",
    height: "3rem",
    zIndex: "10",
    outline: "none",
    color: "#D75A4A",
    fontSize: "large",
    backgroundColor: "#c6dbf1",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1rem",
      marginBottom: "1rem"
    }
  },
  messageBtn: {
    borderRadius: 100,
    background: "#FF6161",
    width: "50%",
    fontSize: 14,
    marginLeft: "10%",
    marginBottom: "1rem",
    lineHeight: "190.62%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "7rem",
      width: "50%"
    }
  },
  editProfileBtn: {
    borderRadius: 100,
    background: "#FF6161",
    width: "60%",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontSize: 14,
    lineHeight: "140.62%",
    backgroundColor: "#FF6161",
    color: "white",
    fontWeight: "normal",
    letterSpacing: "0.01em",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "40%"
    }
  },
  textName: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#000000",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      marginTop: "2rem",
      textAlign: "center"
    }
  },
  viewUpcommingBtn: {
    borderRadius: 100,
    background: "#FF6161",
    width: "60%",
    fontSize: 14,
    marginLeft: "10%",
    marginBottom: "1rem",
    lineHeight: "140.62%",
    backgroundColor: "#FF6161",
    color: "white",
    fontWeight: "normal",
    letterSpacing: "0.01em",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "60%",
      alignItems: "center",
      marginLeft: "20%"
    }
  },
  divAvatar: {
    textAlign: "center",
    margin: "0 auto"
  },
  ratingFormat: {
    textAlign: "center",
    fontWeight: "bold",
    margin: "0 auto",
    width: "fit-content",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  commonFormat: {
    fontStyle: "normal",
    fontFamily: "PT Sans Narrow"
  }
}));

function HostMyProfile(props) {
  const classes = useStyles();
  const { name, bio, rating, avtar, isHost } = props;

  return (
    <Grid container className={classes.root}>
      {/* This Grid Container has picture + edit profile button */}
      <Grid container item spacing={1} md={3} lg={3}>
        {/* This Grid Item will wrap picture  */}
        <Grid container item xs={12} spacing={2} md={12} lg={12}>
          <div className={classes.divAvatar}>
            <Avatar src={avtar} className={classes.avatarSize} />
          </div>
        </Grid>

        {/* This Grid Item will wrap edit profile  */}

        {isHost ? (
          <Grid item xs={12} md={12} lg={12} spacing={2}>
            <div className={classes.divAvatar}>
              <Button
                className={classes.editProfileBtn}
                color="primary"
                variant="contained"
              >
                Edit Profile
              </Button>
            </div>
          </Grid>
        ) : null}
      </Grid>

      {/* This Grid Container will wrap the right section of the page (Name + upcoming vide Button + Rating) */}
      <Grid container item spacing={2} md={9} lg={9}>
        {/* This Grid Item will wrap Name  */}
        <Grid item xs={12} spacing={2} md={4} lg={2} justify="center">
          <Typography
            className={classNames(classes.textName, classes.commonFormat)}
          >
            {`${name.firstName} ${name.lastName}`}
          </Typography>
        </Grid>
        {isHost ? null : (
          <Grid
            item
            container
            xs={12}
            spacing={2}
            md={4}
            lg={1}
            justify="center"
          >
            <IconButton
              onClick={props.onClick}
              color="secondary"
              aria-label="unfavourite"
              className={classes.favouriteIcon}
            >
              <FavoriteIcon fontSize="large" />
            </IconButton>
          </Grid>
        )}
        <Grid item xs={12} spacing={2} md={4} lg={6}>
          <Button
            className={classNames(classes.messageBtn, classes.commonFormat)}
            color="primary"
            variant="contained"
          >
            {isHost ? "Upcoming bookings" : `Message ${name.firstName}`}
          </Button>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <div className={classes.ratingFormat}>
            <StarRating currentRating={rating} />
          </div>
        </Grid>
        <Grid item spacing={2} xs={12}>
          <p
            className={classNames(classes.bioFieldFormat, classes.commonFormat)}
          >
            {bio}
          </p>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HostMyProfile;
