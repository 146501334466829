import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Store from "../../../redux/store";
import "react-calendar-timeline/lib/Timeline.css";
import {translateServiceData_new} from "../../../redux/actions/stepperActions.js"
// Components
import ItemsCarousel from "react-items-carousel";
import Rating from 'material-ui-rating';
//data
import { getProviderProfile } from "../../../redux/actions/stepperActions";
import agent from "../../../agent.js";

//import Constants
import {
  FIRST_TIMER_API_VALUE,
  FIRST_TIMER_VALUE,
  SERVICES_NAMES,
  SKI,
  SNOWBOARD
} from "../../../constants/common.js";

//
import clsx from "clsx";

// Style
import useStyles from "../../Common/SignupForm/ClientInfoStyles";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import { getCountryList } from "../../../redux/actions/homePageAction.js";
import {TextContent, ListContent} from "../../Common/SignupForm/ProviderInfo/Services/InputFields";

import {
  Typography,
  Grid,
  Avatar,
  Box,
  Paper,
  ImageList,
  ImageListItem,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  ListSubheader,
  FormLabel,
  Checkbox,
  ListItemText,
  Hidden
} from "@material-ui/core";
import CountryResortSelection from "../../../pages/serviceMarket/profile/CountryResortSelection";


const useClasses = makeStyles(theme => ({
  arrowIcon: {
    transform: "rotate(180deg)"
  },
  root: {
    margin: "0 auto",
    width: "100%",
    marginTop: "2rem",
    alignItems: "center"
  },
  backButton: {
    margin: "3em",
    borderColor: "#FF6161",
    color: "#FF6161"
  },
  avatarSize: {
    width: "10rem",
    height: "10rem",
    marginBottom: "15%",
    [theme.breakpoints.down("sm")]: {
      width: "10rem",
      height: "10rem"
    }
  },
  editProfileBtn: {
    borderRadius: 100,
    background: "#FF6161",
    width: "70%",
    fontSize: 14,
    lineHeight: "140.62%",
    backgroundColor: "#FF6161",
    color: "white",
    fontWeight: "normal",
    letterSpacing: "0.01em",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "40%"
    }
  },
  textName: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#1277B8",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      marginTop: "2rem",
      textAlign: "center"
    }
  },
  viewUpcommingBtn: {
    background: "#FF6161",
    width: "70%",
    fontSize: 14,
    lineHeight: "140.62%",
    backgroundColor: "#FF6161",
    color: "white",
    fontWeight: "normal",
    letterSpacing: "0.01em",
    borderRadius: 100,
    [theme.breakpoints.down("sm")]: {
      width: "40%",
      marginTop: "1rem",
      marginBottom: "1rem"
    }
  },
  textFieldFormat: {
    color: "#000000",
    width: "100%",
    height: "9%",
    fontSize: 14,
    resize: "none",
    border: "0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "1rem",
      marginBottom: "2rem",
      color: "#000000"
    }
  },
  divAvatar: {
    textAlign: "center",
    margin: "0 auto"
  },
  ratingFormat: {
    textAlign: "center",
    margin: "0 auto",
    width: "fit-content"
  },
  commonFormat: {
    fontStyle: "normal",
    fontFamily: "PT Sans Narrow",
    fontSize: "16px",
    color: "#000000"
  },
  nameLabel: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "30px",
    color: "#000000"
  },
  rating:
  {  
    [theme.breakpoints.up("md")]: {
      paddingLeft: "50px"
    } ,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "100px"
    }
  },

  label: {
    marginLeft: "7.5%",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    marginBottom: "0.5em",
    textTransform: "uppercase",
    color: "#000000"
  },
  galleryLabel: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "26px",
    color: "#000000"
  },
  headlineText: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: '36.56px',
    display: "flex",
    alignItems: "center",
    color: "#000000",
  },
  option:{
    backgroundColor: 'white',
    fontFamily: "PT Sans",
    fontStyle: "regular",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "25.31px",
    color: "black"
  },
  listSubheader:{
    fontFamily: "PT Sans",
    fontStyle: "regular",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "25.31px",
    color: "grey",
  },

  formControl:{
    "&:hover": {
      border: 'none',
      outline:'none'
    },

  },
  selectDropdown:{
    backgroundColor: 'white',
    margin: '0',
    width: '100%',
    outline: 'none',
    border: '1.4px solid black',
    borderRadius: '10px',
    minWidth: '100px',
    "&:before":{
      border: 'none'
    },
    "&:after": {
      border: 'none'
    },
    "&:active": {
      border: 'none',
      outline:'none',
      backgroundColor: 'white'
    },
    "&:focus": {
      backgroundColor: 'white'
    },
    "&:hover": {
      border: 'none',
      outline:'none',
      backgroundColor: 'white'
    },
  },

  inputLabel: {
    color: "#000000",
    padding:'0px 0px 6px 7px',
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "25.5px",
  },

  heightServices:
  {    
    [theme.breakpoints.up("lg")]: {
      height: "200px"
    }  
  },

  activityHeight:
  {   
     
    [theme.breakpoints.down("xs")]: {
      height: "120px"
    },
    [theme.breakpoints.only("sm")]: {
      height: "150px"
    },
    [theme.breakpoints.only("lg")]: {
      height: "200px"
    },
  },

  alignInXS:
  {
    [theme.breakpoints.down("xs")]: {
      marginLeft: "20px"
    }
  }

  

}));

function ProviderView(props) {
  const store = Store.getState();

  const styles = useStyles();
  const classes = useClasses();
  const { user_id } =  useParams();
  const { avatar, profileData, userProfile } = props;

  useEffect(() => {
    props.getCountryList();

    //props.getProviderReviewRating('52');
  }, []);

  const {country_list}=props
  

  const gallery1=[
    {
      index: 0,
      img: "https://test-resorter-images.s3.am",
      title: "Breakfast"
    },

    
  ]
 

  let [activeItem, setActiveItem] = useState(1); //Image Gallery
  let [activeItemRev, setActiveItemRev] = useState(1);
  let [isLoading, setIsLoading] = useState(true);
  let [profilePicture, setProfilePicture] = useState("");
  let [name, setName] = useState("");
  let [bio, setBio] = useState("");
  let [nationality, setNationality] = useState("");
  let [languages, setLanguages] = useState([]);
  let [skiLevel, setSkiLevel] = useState("");
  let [snowboardLevel, setSnowboardLevel] = useState("");
  let [countries, setCountries] = useState([]);
  let [resorts, setResorts] = useState([]);
  let [providerServices, setProviderServices] = useState([]);
  let [gallery, setGallery] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [data,setData] = useState([])
  let [pricing, setPricing] = useState([]);
  let [targetValue, settargetValue] = useState(null);
  let [providerRating, setProviderRating]=useState(0);
  let [providerReviews, setProviderReviews]=useState([]);
  let [allServices, setAllServices]=useState([]);
  let [firstTimer, setFirstTimer]=useState([]);
  let [tours, setTours]=useState([]);
  let [activities, setActivities]=useState([]);
  let [virtualLessons, setVirtualLessons]=useState([]);
  let [childMining, setchildMining]=useState([]);
  let [suitableFor, setSuitableFor]=useState([]);


useEffect(() => {
  setIsLoading(false)
  agent.Users.getProviderProfile(user_id)
  .then((res)=>{
        
       const user=res.data.user
       
       setName(user.first_name + " " + user.last_name);
       setBio(res.data.provider_bio);
       setProfilePicture(user.profile_picture);
       setNationality(user.country.country_name);
       setLanguages(user.languages);

       if (user.skiing_level == 1) {
        setSkiLevel("Ski - Level 1 - First Timer");
      } else if (user.skiing_level == 2) {
        setSkiLevel("Ski - Level 2 - Low Beginner");
      } else if (user.skiing_level == 3) {
        setSkiLevel("Ski - Level 3 - High Beginner");
      } else if (user.skiing_level == 4) {
        setSkiLevel("Ski - Level 4 - Low Intermediate");
      } else if (user.skiing_level == 5) {
        setSkiLevel("Ski - Level 5 - High Intermediate");
      } else if (user.skiing_level == 6) {
        setSkiLevel("Ski - Level 6 - Low Advanced");
      } else if (user.skiing_level == 7) {
        setSkiLevel("Ski - Level 7 - High Advanced");
      }

      
        if (user.snowboarding_level == 1) {
          setSnowboardLevel("Snowboard - Level 1 - First Timer");
        } else if (user.snowboarding_level == 2) {
          setSnowboardLevel("Snowboard - Level 2 - Low Beginner");
        } else if (user.snowboarding_level == 3) {
          setSnowboardLevel("Snowboard - Level 3 - High Beginner");
        } else if (user.snowboarding_level == 4) {
          setSnowboardLevel("Snowboard - Level 4 - Low Intermediate");
        } else if (user.snowboarding_level == 5) {
          setSnowboardLevel("Snowboard - Level 5 - High Intermediate");
        } else if (user.snowboarding_level == 6) {
          setSnowboardLevel("Snowboard - Level 6 - Low Advanced");
        } else if (user.snowboarding_level == 7) {
          setSnowboardLevel("Snowboard - Level 7 - High Advanced");
        }
      
       setSuitableFor(res.data.suitable_fors)
       setResorts(res.data.resorts)
       setServicesData(res.data.services)
       console.log(res.data.services)

        
      //Set Experiences
      const setExperiences=category=>
      {
    
      const serviceList = res.data.services;
      
      let tempServices=[];

        for(let i=0;i<serviceList.length;i++)
            {
              if(serviceList[i].catagory_name==category)
              {
                
                tempServices.push(serviceList[i].service_name);
                
              }
              
            }
            
        category=='FIRST TIMER ASSISTANCE HOSTS'?setFirstTimer(tempServices):
        category=='TOURING HOSTS'?setTours(tempServices):
        category=='ACTIVITY HOSTS'?setActivities(tempServices):
        category=='VIRTUAL LESSONS by Instructors'?setVirtualLessons(tempServices):
        category=='CHILD MINDING HOSTS'?setchildMining(tempServices):console.log("null");

      
      }

      setExperiences("FIRST TIMER ASSISTANCE HOSTS");
      setExperiences("TOURING HOSTS");
      setExperiences("ACTIVITY HOSTS");
      setExperiences("VIRTUAL LESSONS by Instructors");
      setExperiences("CHILD MINDING HOSTS");



     agent.Users.getProviderServices(res.data.id)
     .then((res)=>{
         setPricing(res.data.services_pricing)
     })
  
    //provider rating
    agent.Users.getProviderRating(res.data.id).then(response => {

      if (response.status === 200) {

        setProviderRating(response.data.average_rating_value);

      }

      }).catch(error => {

      console.log("error", error);

  });

}).catch((err)=>{
    console.log(err)
  }) 




  //reviews
  agent.Users.getProviderReviewRating(user_id).then((res)=>
  {
  
    setProviderReviews(res.data)
    console.log(res.data)
  }).catch(error => {
    console.log("error", error);
  });


  
  
  //gallery
  agent.Users.getGallery(user_id).then((res)=>
  {
    setGallery(res.data)
  }).catch(error => {
    console.log("error", error);
  });


  //countries
  agent.Constants.getCountry()
  .then((res)=>{
    let countries_temp=[];
   
    let country_list=res.data
   
  
    agent.Users.getProviderProfile(user_id)
    .then((res)=>{

    const resortss=res.data.resorts
    
    for(let i=0;i<resortss.length;i++)
    {
      country_list.map((item)=>
        item.id==resortss[i].country?
        countries_temp.includes(item.country_name)?countries_temp.push(null):
        countries_temp.push(item.country_name):null
      );
    }

    setCountries(countries_temp);



  }).catch(error => {
    console.log("error", error);
  });


  }).catch((err)=>{
    console.log(err)
  });
}, []);





useEffect(() => {

    agent.Constants.getServices().then(response => {

      if (response.status === 200) {

       
        setAllServices(response.data);

      }

    }).catch(error => {

        console.log("error", error);

    });

  },[]);

  

  
  const handleSetActiveItemRev = value => {
    setActiveItemRev(value);
  };

  const handleSetActiveItem = value => {
    setActiveItem(value);
  };

  const handleServiceName = (string) => {
    const regex =/<[^>]+>/g
    const split_string = string
    const new_str = string.replace(regex,' | ')
    return new_str

  }




useEffect(() => {
    var categoryNameArray = [];
    var objectData = {};
    var objectArray = [];
    var index = 0;
   providerServices &&
   providerServices.forEach(service => {
        if (!categoryNameArray.includes(service.catagory_name)) {
          categoryNameArray.push(service.catagory_name);
          objectData.category_name = service.catagory_name;
          objectData.id = "";
          objectData.header = true;
          objectData.service_name = service.service_name;
          index = objectArray.length;
          objectArray[index] = { ...objectData };
        }
        objectData.category_name = service.catagory_name;
        objectData.id = service.id;
        objectData.service_name = service.service_name;
        objectData.header = false;
        index = objectArray.length;
        objectArray[index] = { ...objectData };
      });

    setServicesData(objectArray);
  }, [providerServices]);


  const handleServiceChange = (e)=> {
    settargetValue(e.target.value);
  }

  const handlePricing = (service) =>{
    console.log(service)
    if(service.service_detail === null){
       return (
          <Grid item className={classes.headlineText} >null</Grid>
        )
    }
    else if( service.service_detail.price_per_hour !== undefined && service.service_detail !== null){
      return ( 
         <Grid item className={classes.headlineText} >${service.service_detail.price_per_hour} per hour</Grid>
        )
    }
    else if(service.service_detail.price_per_head !== undefined && service.service_detail !== null){
        return (
            <div>
              <Grid item className={classes.headlineText}>${service.service_detail.price_per_head} per head (Max {service.service_detail.max_group_size} members)</Grid>
              { service.service_detail.additional_children_price ?
              <Grid item className={classes.headlineText}>- ${service.service_detail.additional_children_price} per additional child</Grid>
              : <Grid item className={classes.headlineText}>- ${service.service_detail.additional_guests_price} per additional member</Grid>}
            </div>
        )
    }
  }
  return (
      <div>
      {isLoading ? (
        <div className="loader-container">
          <div className="loader" />
        </div>
      ) : (
        <div>
          <Grid
            container
            justify="center"
            alignItems="center"
            spacing={0}
            direction="column"
            style={{ padding: "2rem 15px" }}
          >
            {/* Profile Picture, Bio */}
            <Grid
              container
              justify="center"
              alignItems="center"
              xs={12}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              direction="row"
            >
              <Grid
                item
                container
                xs={12}
                sm={4}
                direction="column"
                justify="center"
                alignItems="center"
              >
                {profilePicture == undefined ? (
                  <Avatar className={classes.avatarSize} src={avatar} />
                ) : (
                  <Avatar className={classes.avatarSize}>
                    <img src={profilePicture} />
                  </Avatar>
                )}
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={4}
                lg={8}
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
                
              >
                <Grid
                item
                container   
                xs={12}
                sm={12}
                lg={12}    
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                //flexWrap="wrap"
              >
                  <Typography className={classes.nameLabel}>{name}</Typography>
                 
                  {(providerRating >= 0) ?
                  <Rating name="size-small" value={providerRating} size="small" max={5}
                  readOnly/>:null}
                  
                  <Grid item lg={12} xs={12}>
                  <Typography className={classes.commonFormat}>
                    {bio}
                  </Typography>
                  </Grid>
                  
                </Grid>
              </Grid>
            </Grid>

            {/* Nationality, Languages, Abilities */}
            <Grid
              container
              justify="center"
              alignItems="flex-start"
              xs={12}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              direction="row"
              style={{ padding: "2rem 0px" }}
            >
              {/* Nationality */}
              <Grid
                item
                container
                xs={12}
                sm={4}
                direction="column"
                justify="center"
                alignItems="left"
                style={{ marginBottom: "1rem"}}
                className={classes.alignInXS}
              >
                <Grid item style={{ padding: "0.25rem 0" }}>
                  <Typography className={styles.allergyandotherinformation}>
                    NATIONALITY
                  </Typography>
                </Grid>
                <Grid item container justify="left" alignItems="left">
                  <Typography className={classes.commonFormat}>
                    {nationality}
                  </Typography>
                </Grid>
              </Grid>

              {/* Languages */}
              <Grid
                item
                container
                xs={12}
                sm={4}
                direction="column"
                justify="center"
                alignItems="left"
                style={{ marginBottom: "1rem" }}
                className={classes.alignInXS}
              >
                <Grid item style={{ padding: "0.25rem 0" }}>
                  <Typography className={styles.allergyandotherinformation}>
                    LANGUAGES
                  </Typography>
                </Grid>
                <Grid item container justify="left" alignItems="left">
                  <Typography className={classes.commonFormat}>
                    {languages != undefined
                      ? languages.map(language => (
                          <div>{language.language}</div>
                        ))
                      : null}
                  </Typography>
                </Grid>
              </Grid>

              {/* Abilities */}
              <Grid
                item
                container
                xs={12}
                sm={4}
                direction="column"
                justify="center"
                alignItems="left"
                style={{ marginBottom: "1rem" }}
                className={classes.alignInXS}
              >
                <Grid item style={{ padding: "0.25rem 0" }}>
                  <Typography className={styles.allergyandotherinformation}>
                    ABILITY LEVEL
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  justify="left"
                  alignItems="left"
                >
                  <Typography className={classes.commonFormat} align="left">
                    {skiLevel}
                  </Typography>
                  <Typography className={classes.commonFormat} align="left">
                    {snowboardLevel}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* Countries, resorts, suitable for */}
          

            {/* Countries */}
            <Grid
              container
              justify="center"
              alignItems="flex-start"
              xs={12}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              direction="row"
              style={{ padding: "0rem 0px" }}

              >
                <Grid
                item
                container
                xs={12}
                sm={4}
                direction="column"
                justify="center"
                alignItems="left"
                style={{ marginBottom: "1rem" }}
                className={classes.alignInXS}
              >
                <Grid item style={{ padding: "0.25rem 0" }}>
                  <Typography className={styles.allergyandotherinformation}>
                    COUNTRIES
                  </Typography>
                </Grid>
                
                  
                {countries.map((country)=>{
                  
                  return (
                    
                    <Grid item container justify="left" alignItems="left">
                      {country==null? <Typography align="left" className={classes.commonFormat}><br/></Typography>:
                       <Typography align="left" className={classes.commonFormat}>{country}</Typography>}
                 
                  </Grid>)
                  
                  })}

                
              </Grid>



            {/* Resorts */}
            <Grid 
               item
               container
               xs={12}
               sm={4}
               direction="column"
               justify="center"
               alignItems="left"
               style={{ marginBottom: "1rem" }}
               className={classes.alignInXS}
              >
                <Grid item style={{ padding: "0.25rem 0" }}>
                  <Typography className={styles.allergyandotherinformation}>
                    RESORTS
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  justify="left"
                  alignItems="left"
                >
                  {resorts && resorts.map((resort)=>{
                   return <Typography align="left" className={classes.commonFormat}>{resort.resort_name}</Typography>
                  })}
                </Grid>
            </Grid>

            {/* Suitable for */}
            <Grid
                item
                container
                xs={12}
                sm={4}
                direction="column"
                justify="center"
                alignItems="left"
                style={{ marginBottom: "1rem" }}
                className={classes.alignInXS}
              >
                <Grid item style={{ padding: "0.25rem 0" }}>
                  <Typography className={styles.allergyandotherinformation}>
                    Suitable for
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  justify="left"
                  alignItems="left"
                >
                  <Typography align="left">
                    {suitableFor && suitableFor.map((suitable)=>{
                   return <Typography align="left" className={classes.commonFormat}>{suitable.suitable_for}</Typography>
                  })}</Typography>
                </Grid>
              </Grid>


          </Grid>

           {/* Enquire component */}
         <Grid
             container
             justify="center"
             alignItems="flex-start"
             xs={12}
             sm={10}
             md={8}
             lg={6}
             spacing={1}
             direction="column"
             style={{ padding: "2rem 2rem" ,border: '1px solid #BFBFBF', borderRadius: '10px'}}
             
            >
            {/* headline item */}
            <Grid 
              item xs={12} sm={12}
               >
                <Typography align="left" className={classes.headlineText} >
                  ENQUIRE
                </Typography>
            </Grid>
  
           {/* Form select input item */}

           <Grid 
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ padding: "1rem 0px 1rem" }}
              >

          <FormControl
            className={classes.formControl}
            fullWidth
          >
          <Select
            defaultValue=""
            className={classes.selectDropdown}
            displayEmpty
            onChange= {handleServiceChange}
          >
          <MenuItem value="" className={classes.test} style={{fontFamily: 'PT Sans Narrow', color: "lightslategrey", paddingLeft: "10px"}}>
             Select Experience</MenuItem>
   
         { servicesData && servicesData.map((service)=>
            
           service.header == true ? (
 
              <ListSubheader
               className={classes.listSubheader}
               disableSticky
              >
              {service.category_name}
             </ListSubheader>)
         
        :
            ( <MenuItem
              value={service.service_name}
              className={classes.option}
              style={{padding: '3px 0 0 3.5rem', boxSizing: 'border-box'}}
              >
              {handleServiceName(service.service_name)}
             </MenuItem>
            )
          )
        }
           </Select>   
        </FormControl>
       </Grid>
                     

            {/* Activity sub component */}
             <Grid      
              item xs={12} sm={12}
              >
               {targetValue ? <Typography align="left" className={classes.headlineText} >
                  ACTIVITIES
                </Typography> : null }
              </Grid>
             

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                direction="column"
                justify="left"
                alignItems="left"
                style={{ marginBottom: "1rem" }}
               >
              { pricing && targetValue && pricing.map((service,index)=>{
                  if (targetValue === service.service_info.service_name){
                    return ( 
                        service.service_info.service_name.includes('<li>') ? 
                         <Grid item className={classes.headlineText} >${handleServiceName(service.service_info.service_name)}</Grid> :
                      <Grid item className={classes.headlineText}>{service.service_info.service_name}</Grid>
                    )
                  }
                })
              }
             </Grid>
                  
            {/* pricing sub-component */}

             <Grid      
              item xs={12} sm={12}
              >
               {targetValue ? <Typography align="left" className={classes.headlineText} >
                  PRICING
                </Typography> : null }
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                direction="column"
                justify="left"
                alignItems="left"
                style={{ marginBottom: "1rem" }}
               >
                { pricing  && targetValue && pricing.map((service,index)=>{
                    if ( targetValue === service.service_info.service_name ){
                      return handlePricing(service)
                    }
                })
            }
           </Grid>
          </Grid>
                 
            
             {/* Experiences Offered */}


             <Grid
              container
              justify="flex-start"
              alignItems="flex-start"
              xs={12}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              direction="row"
              style={{ paddingTop: "50px" }}
              className={classes.alignInXS}
            ><Grid item >
            <Typography>
              Experiences offered
            </Typography></Grid>
              </Grid>

            <Grid
              container
              justify="center"
              alignItems="flex-start"
              xs={12}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              direction="row"
              style={{ padding: "2rem 0px" }}
            >
            
            {/* FIRST TIMER HOSTING */}
            <Grid
                item
                container
                xs={12}
                sm={4}
                direction="column"
                justify="center"
                alignItems="left"
                style={{ marginBottom: "1rem" }}
                className={classes.alignInXS}
              >
                
                <Grid className={classes.heightServices}>
                <Grid item style={{ padding: "0.25rem 0" }}>
                  <Typography className={styles.allergyandotherinformation}>
                    FIRST TIMER HOSTING
                  </Typography>
                </Grid>
                
                {

          
                    allServices.map((ft)=>{
                                             
                        return (
                        firstTimer.includes(ft.service_name)?
                        <Grid item container justify="left" alignItems="left">
                        <Typography align="left" style={{color:"black"}}><ListContent /></Typography>
                        </Grid>:ft.catagory_name=="FIRST TIMER ASSISTANCE HOSTS"?
                        <Grid item container justify="left" alignItems="left">
                        <Typography align="left" style={{color:"grey"}}><ListContent /></Typography>
                        </Grid>:null)
                      
                      })
            
                }
              </Grid>

              
              {/* Virtual lessons */}
              <Grid className={classes.heightServices}>
              <Grid item style={{ padding: "0.25rem 0" }}>
                  <Typography className={styles.allergyandotherinformation}>
                    VIRTUAL LESSONS
                  </Typography>
              </Grid>
                
              {//approach 2

                
                    allServices.map((ft)=>{
                                      
                                        
                    return (
                      virtualLessons.includes(ft.service_name)?
                      <Grid item container justify="left" alignItems="left">
                      <Typography align="left" style={{color:"black"}}>{ft.service_name}</Typography>
                      </Grid>:ft.catagory_name=="VIRTUAL LESSONS by Instructors"?
                      <Grid item container justify="left" alignItems="left">
                      <Typography align="left" style={{color:"grey"}}>{ft.service_name}</Typography>
                      </Grid>:null)
                      
                      })
              }


                </Grid>
                </Grid>

                {/* ACTIVITIES */}
                <Grid
                item
                container
                xs={12}
                sm={4}
                direction="column"
                justify="center"
                alignItems="left"
                style={{ marginBottom: "1rem" }}
                className={classes.alignInXS}
              >

              <Grid className={classes.activityHeight}>
                <Grid item style={{ padding: "0.25rem 0" }}>
                  <Typography className={styles.allergyandotherinformation}>
                    ACTIVITIES
                  </Typography>
              </Grid>
                
              {//approach 2

                    allServices.map((ft)=>{
                                                                          
                                                                            
                      return (
                        activities.includes(ft.service_name)?
                        <Grid item container justify="left" alignItems="left">
                      <Typography align="left" style={{color:"black"}}>{ft.service_name}</Typography>
                      </Grid>:ft.catagory_name=="ACTIVITY HOSTS"?
                      <Grid item container justify="left" alignItems="left">
                      <Typography align="left" style={{color:"grey"}}>{ft.service_name}</Typography>
                      </Grid>:null)
                      
                })}

                </Grid>

              {/* Child mining */}

              <Grid className={classes.heightServices} >
              <Grid item style={{ padding: "0.25rem 0" }}>
                  <Typography className={styles.allergyandotherinformation}>
                    CHILD MINING
                  </Typography>
              </Grid>
                
              {//approach 2

                
                allServices.map((ft)=>{
                                                      
                                                        
                  return (
                    childMining.includes(ft.service_name)?
                    <Grid item container justify="left" alignItems="left">
                  <Typography align="left" style={{color:"black"}}>{ft.service_name}</Typography>
                  </Grid>:ft.catagory_name=="CHILD MINDING HOSTS"?
                  <Grid item container justify="left" alignItems="left">
                  <Typography align="left" style={{color:"grey"}}>{ft.service_name}</Typography>
                  </Grid>:null)
                  
                  })}
                                

                </Grid>
                </Grid>
                {/* TOURS */}

                <Grid
                item
                container
                xs={12}
                sm={4}
                direction="column"
                justify="center"
                alignItems="left"
                style={{ marginBottom: "1rem" }}
                className={classes.alignInXS}
              >
                <Grid item style={{ padding: "0.25rem 0" }}>
                  <Typography className={styles.allergyandotherinformation}>
                    TOURS
                  </Typography>
                </Grid>
                {


                    allServices.map((ft)=>{
                  
                    
                      return (
                        tours.includes(ft.service_name)?
                        <Grid item container justify="left" alignItems="left">
                      <Typography align="left" style={{color:"black"}}>{ft.service_name}</Typography>
                      </Grid>:ft.catagory_name=="TOURING HOSTS"?
                      <Grid item container justify="left" alignItems="left">
                      <Typography align="left" style={{color:"grey"}}>{ft.service_name}</Typography>
                      </Grid>:null)
                      
                      })
                    } 
                  
                </Grid>
            </Grid>


          {/* reviews */}

         
          <Grid
              container
              justify="center"
              alignItems="flex-start"
              xs={12}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              direction="row"
              style={{ padding: "2rem 0px" }}
            ><Grid item style={{ padding: "0.25rem 0" }}>
            <Typography align="center">
              <b>Reviews</b>
            </Typography></Grid>
              </Grid>

              {providerReviews.length>0?
              <Grid
              container
              justify="center"
              alignItems="flex-start"
              xs={12}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              direction="row"
            >
              <Grid
                container
                direction="row"
                justify="center"
                justifyContent="center"
                alignItems="center"
                xs={12}
                sm={10}
                md={8}
                lg={6}
                spacing={2}
                style={{ backgroundColor: "white", padding: "2rem 0px" }}
              >
                <Grid
                  container
                  item
                  xs={8}
                  sm={10}
                  direction="column"
                  alignItems="center"
                  justify="center"
                >
                  <Grid item style={{ width: "100%" }}>
                    <ItemsCarousel
                      placeholderItem={
                        <div style={{ height: 200, background: "#EEE" }} />
                      }
                      enablePlaceholder={true}
                      numberOfPlaceholderItems={3}
                      gutter={12}
                      activePosition={"center"}
                      chevronWidth={100}
                      disableSwipe={false}
                      alwaysShowChevrons={false}
                      numberOfCards={1}
                      slidesToScroll={1}
                      outsideChevron={true}
                      showSlither={false}
                      firstAndLastGutter={false}
                      activeItemIndex={activeItemRev}
                      requestToChangeActive={value =>
                        handleSetActiveItemRev(value)
                      }
                      rightChevron={<ArrowForwardIosOutlinedIcon />}
                      leftChevron={<ArrowBackIosOutlinedIcon />}
                    >
                      {providerReviews.map( (item) => <Item item={item} /> )}
                    </ItemsCarousel>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
             : (<div><Grid item style={{ padding: "0.25rem 0" , height: "100px"}}>
             <Typography align="center">
               <i>No Reviews for this provider yet.</i>
            </Typography></Grid> </div>)}


          {/* Image Gallery Header */}
            <Grid
              container
              justify="center"
              alignItems="flex-start"
              xs={12}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              direction="row"
            >
              <Grid item xs={12} sm={12}>
                <Typography align="center" className={classes.galleryLabel}>
                  Gallery
                </Typography>
              </Grid>
            </Grid>


            {/* Image Gallery Carousel */}
            {gallery.length>0?
            <Grid
              container
              justify="center"
              alignItems="flex-start"
              xs={10}
              sm={10}
              md={8}
              lg={6}
              spacing={2}
              direction="row"
            >
              <Grid
                item
                justify="center"
                alignItems="center"
                xs={10}
                sm={10}
                style={{ paddingTop: "1.5rem" }}
              >
                <Hidden xsDown>
                  <ItemsCarousel
                    enablePlaceholder={true}
                    numberOfPlaceholderItems={3}
                    gutter={12}
                    activePosition={"center"}
                    chevronWidth={100}
                    disableSwipe={false}
                    alwaysShowChevrons={false}
                    numberOfCards={3}
                    slidesToScroll={1}
                    outsideChevron={true}
                    showSlither={false}
                    firstAndLastGutter={false}
                    activeItemIndex={activeItem}
                    requestToChangeActive={value => handleSetActiveItem(value)}
                    rightChevron={<ArrowForwardIosOutlinedIcon />}
                    leftChevron={<ArrowBackIosOutlinedIcon />}
                  >
                    {gallery!==undefined&&gallery.map(item =>
                      activeItem == item.id ? (
                        <img
                          src={`${item.image_link}&fit=crop&auto=format`}
                          alt={item.title}
                          loading="lazy"
                          style={{
                            width: "154px",
                            height: "154px",
                            margin: "1.5rem",
                            transform: "scale( 1.3 )",
                            transition: "transform .1s"
                          }}
                        />
                      ) : (
                        <img
                          src={`${item.image_link}&fit=crop&auto=format`}
                          alt={item.title}
                          loading="lazy"
                          style={{
                            width: "154px",
                            height: "154px",
                            margin: "1.5rem",
                            transform: "scale( 0.75 )"
                          }}
                        />
                      )
                    )}
                  </ItemsCarousel>
                </Hidden>
                <Hidden smUp>
                  <ItemsCarousel
                    enablePlaceholder={true}
                    numberOfPlaceholderItems={3}
                    gutter={12}
                    activePosition={"center"}
                    chevronWidth={100}
                    disableSwipe={false}
                    alwaysShowChevrons={false}
                    numberOfCards={1}
                    slidesToScroll={1}
                    outsideChevron={true}
                    showSlither={false}
                    firstAndLastGutter={false}
                    activeItemIndex={activeItem}
                    requestToChangeActive={value => handleSetActiveItem(value)}
                    rightChevron={<ArrowForwardIosOutlinedIcon />}
                    leftChevron={<ArrowBackIosOutlinedIcon />}
                  >
                    
                    {gallery.map(item => (
                      <img
                        src={`${item.image_link}&fit=crop&auto=format`}
                        alt={item.title}
                        loading="lazy"
                        style={{
                          width: "200px",
                          height: "200px"
                        }}
                      />
                    ))}
                  </ItemsCarousel>
                </Hidden>
              </Grid>
            </Grid>:(<div><Grid item style={{ padding: "0.25rem 0", height: "100px" }}>
            <Typography align="center">
              <i>No Photo Gallery for this provider yet.</i>
            </Typography></Grid> <br></br> <br></br> <br></br></div>)}
          </Grid>
        </div>
      )}
    </div>
  );
}

function Item(props)
{
  var date = props.item.creation_date
  
  var d = new Date(date);
  var n = d.getMonth()+1;
  
    return (
      <Grid >
        <p><center>"{props.item.review_content}"</center></p>
          <p><b><center>- {props.item.reviewer.first_name} {props.item.reviewer.last_name},{props.item.order.created_date}</center></b></p>
          
      </Grid>
            
        
    )
}




// Reducer part
function mapStateToProps(state, ownProps) {
  
  return {
    profileData: state.profileDataReducer.profileData,   //client data
    userProfile: state.stepperReducer.userProfile,       //Provider data
    country_list: state.homePageReducer.country_list
  };
}

// Adding Dispatch to update the existing values in Redux
const mapDispatchToProps = dispatch => {
  return {
    //getProviderProfile: userId => dispatch(getProviderProfile(userId)),
    //getCountry:() => dispatch(getCountry()),
    getCountryList: () => dispatch(getCountryList())
  };
};

// Connect part
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderView);