import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: "100%",
    height: 261,
    background: "#d1e3ef",
    alignContent: "center"
  },
  text: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 28,
    lineHeight: "140.62%",
    textAlign: "center",
    color: "#000000"
  },
  button: {
    width: 173,
    height: 50,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 18,
    lineHeight: "140.62%",
    textAlign: "center",
    letterSpacing: "0.01em",
    color: "#ffffff",
    background: "#ff6161",
    borderRadius: "100px",
    border: "none",
    "&:last-child": {
      marginLeft: 25
    }
  },
  [theme.breakpoints.down("sm")]: {
    wrapper: {
      height: 200
    },
    text: {
      fontSize: 20,
      marginBottom: 10
    },
    button: {
      width: 173,
      height: 50,
      fontSize: 18
    }
  },
  [theme.breakpoints.down("xs")]: {
    wrapper: {
      height: 150
    },
    text: {
      fontSize: 13,
      marginBottom: 10
    },
    button: {
      width: 130,
      height: 35,
      fontSize: 12
    }
  }
}));

const CreateProfileBanner = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.wrapper}>
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <p className={classes.text}>Are you an instructor or host?</p>
        </Grid>
        <Grid item xs={12}>
          <p className={classes.text}>
            Create a profile to connect with students and visitors today.
          </p>
        </Grid>
      </Grid>

      <Grid item container justify="center">
        <button
          className={classes.button}
          onClick={() => {
            /* TODO: create instructor profile */
          }}
        >
          I'm an Instructor
        </button>
        <button
          className={classes.button}
          onClick={() => {
            /* TODO: create host profile*/
          }}
        >
          I'm a Host
        </button>
      </Grid>
    </Grid>
  );
};

export default CreateProfileBanner;
