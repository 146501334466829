import React, { Component } from "react";
import axios from "axios";
import { baseURL } from "../../../redux/actions/action-types";
import agent from "../../../agent";

class VerifyEmail extends Component {
  state = {
    virtualLessonID: "",
    body: ""
  };

  onTitleChange = e => {
    this.setState({
      virtualLessonID: e.target.value
    });
  };

  onBodyChange = e => {
    this.setState({
      body: e.target.value
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    const data = {
      virtualLessonID: this.state.virtualLessonID
    };

    const request = data;
    console.log(request);

    return axios
      .post(baseURL + "/booking/RateEmail/verifyEmail", { ...request })
      .then(response => console.log(response))
      .catch(err => console.log(err));

    //   axios
    //     .post(baseURL+"/booking/RateEmail/virtualLesson", request)
    //     .then(res => console.log(res))
    //     .catch(err => console.log(err));
  };

  render() {
    return (
      <div className="post">
        <form className="post" onSubmit={this.handleSubmit}>
          <input
            placeholder="virtual_booking id"
            value={this.state.virtualLessonID}
            onChange={this.onTitleChange}
            required
          />
          {/*<textarea*/}
          {/*  placeholder="Body" value={this.state.body}*/}
          {/*  onChange={this.onBodyChange} required*/}
          {/*/>*/}
          <button type="submit">Create Post</button>
        </form>
      </div>
    );
  }
}

export default VerifyEmail;
