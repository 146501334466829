import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import blankImage from "../profile/blank_photo_icon.jpg";
import ImageWithEditIcon from "./ImageWithEditIcon";

const useStyles = makeStyles(theme => ({
  imgMargin: {
    margin: "5px 0"
  },
  uploadBtn: {
    width: "50%",
    marginTop: "3rem",
    marginBottom: "2rem",
    textAlign: "end",
    [theme.breakpoints.down("xs")]: {
      paddingRight: "10px"
    }
  },
  imageGalleryTitle: {
    width: "50%",
    marginBottom: "2rem",
    marginTop: "3rem",
    fontSize: "x-large",
    fontFamily: "PT Sans Narrow",
    paddingLeft: "1rem"
  },
  input: {
    display: "none"
  },
  divImage: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    paddingRight: "10px"
  },
  editIcon: {
    position: "absolute",
    zIndex: "2",
    color: "white",
    backgroundColor: "royalblue",
    margin: "5px"
  }
}));

function ImageGallery(props) {
  const { gallery, setGallery, isEditable } = props;
  const classes = useStyles();
  let [isDisable, setIsDisable] = useState({});
  let imageData = getImageList(gallery);

  // when the upload button is clicked, check if the number of images reaches to 6
  const handleClick = () => {
    if (gallery.length === 6) {
      console.log("The gallery reaches to its maximum capacity");
      setIsDisable(true);
    }
  };

  // handle upload image event
  // const handleImageUpload = event => {
  //   let uploadedImageUrl = URL.createObjectURL(event.target.files[0]);
  //   console.log(uploadedImageUrl);
  //   setPreviewImage(uploadedImageUrl);
  //   setImage(event.target.files[0]);
  // };

  return (
    <Grid
      container
      item
      direction="row"
      justifyContent="space-evenly"
      alignItems="baseline"
    >
      <div className={classes.imageGalleryTitle}>Image Gallery</div>

      <div className={classes.uploadBtn}>
        <input
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          multiple
          type="file"
          onClick={handleClick}
        />
        {isEditable ? (
          <label htmlFor="contained-button-file">
            <Button color="primary" component="span">
              <span>Upload Photos</span>
            </Button>
          </label>
        ) : (
          <label>
            <Button color="primary" component="span" disabled>
              <span>Upload Photos</span>
            </Button>
          </label>
        )}
      </div>

      {/* Images */}
      <Grid
        container
        item
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        {imageData.map(image => (
          <Grid item xs={4} sm={2} md={2} lg={2} className={classes.imgMargin}>
            <ImageWithEditIcon imageList={image} isEditMode={isEditable} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

function getImageList(providerGalleryData) {
  let imageData = [];
  if (providerGalleryData.length === 0) {
    imageData = Array(6).fill(blankImage);
  } else {
    providerGalleryData.forEach(item => imageData.push(item.image_link));
    if (imageData.length < 6) {
      for (let i = imageData.length - 1; i < 5; i++) {
        imageData.push(blankImage);
      }
    }
  }
  return imageData;
}

export default ImageGallery;
