import React, { Fragment, useEffect } from "react";
import { Field } from "redux-form";
import { getResortList } from "../../../../redux/actions/homePageAction.js";

// Component Input
import { renderTextAreaField } from "../../FormField/FormField.js";

import { Typography, Grid } from "@material-ui/core";

import useStyles from "../ClientInfoStyles";
import { connect } from "react-redux";

const Resorts = props => {
  const { renderSelectField } = props;
  const classes = useStyles();
  // console.log(props);
  const { resortList, resorts, isDisabled, isNative, isEditable, setResorts } = props;
  // props.getResortList(14);

  // useEffect(() => {
  //   props.getResortList(14);
  //   // console.log(resortList);
  // }, []);
  
  let obj = {}
  if (isEditable) {
    obj = {
      disabled: isDisabled,
      native: isNative
    }
  } else {
    obj = {
      disabled: isDisabled,
      native: isNative,
      value: resorts,
      renderValue: (selected) => {
          // console.log("Form Field");
          // console.log(selected)
          if (selected.length > 0) {
            let selectedOptions = selected.join(", ");
            return selectedOptions;
          }
        }
      
    }
  }

  return (
    // <Grid item xs={12} sm={4}>
    <Grid>
      <Field
        name="resorts"
        component={renderSelectField}
        options={resortList}
        // spacing={{ width: "80%" }}
        spacing={{}}
        props = {obj}
        type="select"
        label="Resorts"
        placeholder="Please select an option"
        onChange={event => props.setResorts(event.target.value)}
      />
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    resortList: state.homePageReducer.resortList
  };
}

// const mapDispatchToProps = dispatch => {
//   return {
//     getResortList: id => dispatch(getResortList(id))
//   };
// };

export default connect(
  // mapDispatchToProps,
  mapStateToProps
)(Resorts);
