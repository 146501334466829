import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { COLOR } from "../../../styling/theme/Theme";
import clsx from "clsx";
// Component Input
import {
  MenuItem,
  FormControl,
  Select,
  ListSubheader,
  ListItemText,
  Radio,
  Typography,
  Box,
  Chip
} from "@material-ui/core";

const useClasses = makeStyles(theme => ({
  select2: {
    fontColor: "1277B8",
    marginLeft: "7.5%",
    width: "95%",
    fontFamily: "PT Sans Narrow",
    borderRadius: "10px",
    "& .MuiOutlinedInput-input": {
      padding: "0.5rem 0.5rem",
      height: "auto"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black  ",
      borderWidth: "1px  "
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black  ",
      borderWidth: "1px  "
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black  ",
      borderWidth: "1px  "
    },
    "& .MuiListItemText-root": {
      marginTop: "0px"
    },
    "& .MuiTypography-body1": {
      lineHeight: "inherit"
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "2.5%",
      width: "95%"
    },
  },
  label: {
    marginLeft: "7.5%",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    marginBottom: "0.5em",
    textTransform: "uppercase",
    color: "#000000",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "2.5%",
      width: "95%"
    },
  },
  inputSelect: {
    marginTop: "0.2em",
    textAlign: "center",
    flexWrap: "wrap",
    display: "flex"
  },
  selectMenu: {
    width: "90%"
  },
  selectRootText: {
    textAlign: "center",
    color: COLOR.PRIMARY,
    width: "90%",
    "& div.MuiOutlinedInput-root": {
      height: "2.1rem",
      fontFamily: "Roboto"
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      borderColor: "black !important"
    },
    "& .MuiOutlinedInput-root .MuiSelect-select:focus": {
      backgroundColor: "#EFEFEF"
    }
  },
  selectMenu: {
    width: "90%"
  },
  selectRoot: {
    borderColor: "black",
    width: "90%",
    "& div.MuiOutlinedInput-root": {
      height: "auto",
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      borderColor: "black !important"
    },
    "& .MuiOutlinedInput-root .MuiSelect-select:focus": {
      backgroundColor: "transparent"
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    },
  },
  selectIcon: {
    color: COLOR.PRIMARY
  },
  search: {
    width: "17em"
  },
  checkBoxStyle: {
    "& .MuiCheckbox-root": {
      color: "#1277B8"
    }
  },
  searchRoot: {
    borderRadius: 5,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: COLOR.PRIMARY,
        borderWidth: 1,
        borderRadius: 5
      },
      "&:hover fieldset": {
        borderColor: COLOR.PRIMARY,
        borderWidth: 1,
        borderRadius: 5
      },
      "&.Mui-focused fieldset": {
        borderColor: "#ff0000",
        borderWidth: 1,
        borderRadius: 5
      }
    }
  },
  listRoot: {
    whiteSpace: "normal",
    // width: "18em",
    alignContent: "center"
  },
  subHeaderRoot: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "140.62%",
    display: "flex",
    alignItems: "center",
    color: COLOR.PRIMARY
  },
  primary: {
    fontFamily: "PT Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "140.62%",
    color: COLOR.PRIMARY
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5"
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)"
    }
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3"
    }
  }
}));

const AbilityLevel = props => {
  const classes = useClasses();
  const { skiAbilityLevel, snowBoardAbilityLevel } = props;
  const { skiLevel, snowboardLevel, setSkiLevel, setSnowboardLevel, isDisabled } = props;
  let [selectedAbilityLevel, setSelectedAbilityLevel] = useState([]);

  const handleAbilityLevelChange = event => {
    // console.log(event.target.value);
    let ski = [];
    let snowboard = [];
    event.target.value.map(item => {
      if (item.includes("Skiing")) {
        ski = item;
        setSkiLevel(ski.substring(ski.length - 1));
      } else if (item.includes("Snowboard")) {
        snowboard = item;
        setSnowboardLevel(snowboard.substring(snowboard.length - 1));
      }
      setSelectedAbilityLevel([ski, snowboardLevel]);
    });
  }

  // When this component is used in Profile page, the languages should be preload to the page.
  // This is to load the user selected languages to the page.
  useEffect(() => {
    if (snowboardLevel !== undefined && skiLevel !== undefined) {
      let skiValue = "Skiing Ability Level " + skiLevel;
      let snowboardValue = "Snowboard Ability Level " + snowboardLevel;
      setSelectedAbilityLevel([skiValue, snowboardValue]);
    }
  }, [skiLevel, snowboardLevel]);

  return (
    <FormControl
      variant="outlined"
      color="primary"
      classes={{ root: classes.selectRoot }}
    >
      <Typography align="left" className={classes.label}>
        ABILITY LEVEL
      </Typography>
      <Select
        multiple
        disabled={isDisabled}
        value={selectedAbilityLevel}
        onChange={handleAbilityLevelChange}
        renderValue={selected => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {selectedAbilityLevel.map(value => (
              <Chip key={value} label={value} />
            ))}
          </Box>
      )}
        className={classes.select2}
        classes={{
          selectMenu: classes.selectMenu,
          root: classes.selectRoot,
          icon: classes.selectIcon,
          selected: classes.selected
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
      >
        <ListSubheader
          classes={{ root: classes.subHeaderRoot }}
          disableSticky
          value="value"
        >
          SKIING
        </ListSubheader>
        {skiAbilityLevel.map(skiingAbilityLevel => (
          <MenuItem
            key={skiingAbilityLevel.id}
            value={skiingAbilityLevel.key}
            // disabled={visibilitySkiing}
            classes={{ root: classes.listRoot }}
          >
            <Radio
              checked={selectedAbilityLevel.includes(skiingAbilityLevel.key)}
              value={skiingAbilityLevel.key}
              // inputProps={{ "aria-label": "C" }}
              disableRipple
              color="primary"
              className={classes.root}
            />
            <ListItemText
              primary={skiingAbilityLevel.level}
              className={classes.listitem}
              classes={{ primary: classes.primary }}
            />
          </MenuItem>
        ))}

        <ListSubheader
          classes={{ root: classes.subHeaderRoot }}
          disableSticky
          value="value"
        >
          SNOWBOARDING
        </ListSubheader>
        {snowBoardAbilityLevel.map(snowBoardingAbilityLevel => (
          <MenuItem
            // disabled={visibilitySnowBoarding}
            key={snowBoardingAbilityLevel.id}
            value={snowBoardingAbilityLevel.key}
            classes={{ root: classes.listRoot }}
          >
            <Radio
              checked={selectedAbilityLevel.includes(
                snowBoardingAbilityLevel.key
              )}
              value={snowBoardingAbilityLevel.key}
              // inputProps={{ "aria-label": "C" }}
              disableRipple
              color="primary"
              className={classes.root}
            />
            <ListItemText
              primary={snowBoardingAbilityLevel.level}
              className={classes.listitem}
              classes={{ primary: classes.primary }}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const mapStateToProps = state => {
    return {
      snowBoardAbilityLevel: state.exploreHostsReducer.snowBoardAbilityLevel,
      skiAbilityLevel: state.exploreHostsReducer.skiAbilityLevel
    };
}
  
const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AbilityLevel);