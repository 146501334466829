import React from "react";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import AvailabilityCard from "./Availability/AvailabilityCard";
import BookingCard from "./Booking/BookingCard";
import Flag from "./Flag";

const useStyles = makeStyles({
  smallSectionHeading: {
    fontSize: "16",
    color: "#1277B8",
    fontWeight: "bold",
    fontFamily: "PT Sans Narrow",
    lineHeight: "140.62%",
    // height: "46.07px",
    width: "78px",
    padding: "6px"
  },
  root: {
    width: "100%",
    height: "25.05px",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "140.62%",
    alignItems: "left",
    color: "#000000",
    padding: "0px"
  }
});

const SmallSections = props => {
  const classes = useStyles();
  const { values, header, size } = props;

  return (
    <Grid container item sm={size} xs={12}>
      <Grid container item xs={12} justify="flex-start" alignItems="center">
        <div className={classes.smallSectionHeading}>{header}</div>
      </Grid>
      {Array.isArray(values) ? (
        values.map(value => {
          return <div className={classes.root}> {value} </div>;
        })
      ) : (
        <div className={classes.root}> {values} </div>
      )}
    </Grid>
  );
};

const MoreInfo = props => {
  const classes = useStyles();
  const { ExperienceYears, languages } = props.instructorData;

  //The qualifications in the data base are comma seperated values - this seperates said values
  let qualifications = [""];
  if (props.instructorData.Qualifications)
    qualifications = props.instructorData.Qualifications.split(",").filter(
      x => x !== ""
    );
  //parses array of objects into array of strings
  let resorts = [""];
  if (props.instructorData.resorts)
    resorts = props.instructorData.resorts.map(r => r.fullname);

  let sport = [""];
  if (props.instructorData.sport)
    sport = props.instructorData.sport.map(s => s.desc);

  let specialisations = [""];
  if (props.instructorData.specialisations)
    specialisations = props.instructorData.specialisations.map(s => s.desc);
  //fake data
  const priceList = [
    { key: 1, displayText: "Full day (up to 2 people)", amount: "$504.00" },
    { key: 2, displayText: "Full day (up to 3 people)", amount: "$505.00" },
    { key: 3, displayText: "Full day (up to 4 people)", amount: "$506.00" },
    { key: 4, displayText: "Full day (up to 5 people)", amount: "$507.00" }
  ];

  const updateTime = props.updateTime;

  //Will be able to edit availability as instructor
  //Will be able to make bookings as student
  let calendarCard = (
    <BookingCard
      instructorData={props.instructorData}
      updateTime={updateTime}
    />
  );

  if (props.isOwnProfile) {
    calendarCard = (
      <AvailabilityCard
        instructorData={props.instructorData}
        updateTime={updateTime}
      />
    );
  }
  return (
    <Grid container item xs={12} spacing={2} justify="space-around">
      <Grid container item sm={6} xs={12} spacing={2}>
        <Grid container item spacing={4}>
          <Grid container item xs={12}>
            <SmallSections values={resorts} header={"RESORTS"} size={3} />
            <SmallSections values={sport} header={"SPORTS"} size={4} />
            <SmallSections
              values={specialisations}
              header={"SPECIALISATIONS"}
              size={5}
            />
          </Grid>

          <Grid container item xs={12} spacing={1}>
            <Grid container item sm={3} xs={12}>
              <Grid
                container
                item
                xs={12}
                justify="flex-start"
                alignItems="center"
              >
                <div className={classes.smallSectionHeading}>LANGUAGES</div>
              </Grid>
              {languages.map(value => {
                return <Flag language={value} key={value} />;
              })}
            </Grid>
            <img src={Flag} />

            <SmallSections
              values={ExperienceYears}
              header={"YEARS OF EXPERIENCE"}
              size={4}
            />
            <SmallSections
              values={qualifications}
              header={"QUALIFICATIONS"}
              size={5}
            />
          </Grid>
        </Grid>

        {/* secondLine of items */}
        <Grid container item>
          <Grid container item xs={12} justify="flex-start" alignItems="center">
            <div className={classes.smallSectionHeading}>PRICE</div>
          </Grid>

          <Grid container item xs={12} spacing={1}>
            {priceList.map((priceItem, i) => {
              return (
                <Grid container item sm={6} xs={12} key={"g1" + i}>
                  <Grid container item xs={12} key={"g2" + i}>
                    <div className={classes.root} key={"d1" + i}>
                      {" "}
                      {priceList[i]["displayText"]}{" "}
                    </div>
                  </Grid>
                  <Grid container item xs={12} key={"g3" + i}>
                    <div className={classes.root} key={"d2" + i}>
                      {" "}
                      {priceList[i]["amount"]}{" "}
                    </div>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>

      <Grid item container item lg={5} md={5} sm={6} xs={12}>
        {/* <AvailabilityCard
          instructorData={props.instructorData}
          updateTime={updateTime}
        /> */}
        {calendarCard}
      </Grid>
    </Grid>
  );
};

function mapStateToProps(state) {
  return {
    instructorData: state.virtualInstructorReducer.currentProfile
  };
}

export default connect(mapStateToProps)(MoreInfo);
