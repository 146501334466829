import React, { useState, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Popover from "@material-ui/core/Popover";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import DateFnsUtils from "@date-io/date-fns";
import {
  GROUP,
  PRIVATE,
  FIRST_TIMER_GROUP,
  FIRST_TIMER_PRIVATE,
  PRIVATE_ADAPTIVE
} from "./constant/lessonType";
import _ from "lodash";
import uuid from "uuid";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

import { connect } from "react-redux";
import {
  createTripLessons,
  editTripLesson
} from "../../../../redux/actions/tripLessonActions";
import {
  filterAgeGroup,
  filterNumDays,
  filterSession,
  filterDurationHours,
  filterStartTime,
  filterPrivateParticipants,
  filterGroupParticipants
} from "./reusable/FilterFieldsInDialog";
import { ageGroupMapper } from "./reusable/DataMapper";
import {
  initialize,
  validateForm,
  getDaysAndTime,
  isMemberFitInLesson,
  getEligibleParticipants,
  checkTimeClash,
  membersWithTimeClashes,
  hasErrors,
  hasFieldsRequiredToBeFilledError,
  getParticipantsList,
  doParticipantsHaveSameAbilityLevel,
  instructorOptionsMapper
} from "./reusable/EditPopupHelper";
import {
  calculatePrivateDayRange,
  calculateSession
} from "../lesson/reusable/LessonTimeCalculator";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
    "& legend": {
      color: "rgba(0, 0, 0, 0.54)"
    }
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  checkBox: {
    flexDirection: "row",
    "& span": {
      minWidth: "auto"
    }
  },
  datePicker: {
    margin: 8,
    width: "100%"
  },
  actionBtns: {
    borderRadius: 15
  },
  popover: {
    padding: theme.spacing(2)
  }
}));

const EditPopup = props => {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [snackParams, setSnackParams] = React.useState({
    open: false,
    id: null,
    activity: null
  });
  const {
    familyMembers, // from redux
    tripLessons, // from redux
    main, // from redux
    groupLessons, // from redux
    privateLessons, // from redux
    tripLessonId, // a single lesson object passed from parent
    isAdaptive,
    isFirstTimer,
    /* Note: startDate is the date user pick initialDate is the earliest data user can pick*/
    initialDate, // from redux.
    endDate, // from redux
    open,
    onClose,
    needsHighlight
  } = props;

  let initialState = {
    ageGroup: "",
    activity: "",
    lessonType: "",
    adaptive: "",
    firstTimer: "",
    durationHours: "",
    startTime: "",
    instructor: "",
    durationDays: "",
    selectedInstructorOption: "1",
    instructorDetails: "",
    startDate: new Date(),
    initialParticipants: {},
    startDate: new Date()
  };

  let initialErrors = {
    activityError: false,
    ageGroupError: false,
    lessonTypeError: false,
    durationError: false,
    sessionError: false,
    startTimeError: false,
    instructorDetailsError: false,
    daysError: false,
    membersError: false,
    differentAbilityLevelErrorMsg: false,
    // differentAbilityLevelErrorMsg:"",
    inOldLessonsErrorMsg: ""
    // errorMsg:"",
  };

  initialState = initialize(tripLessonId, initialState, {
    tripLessons,
    familyMembers,
    main,
    calculateSession
  });

  // const [open, setOpen] = useState(false);
  const [isActivityDisabled, setIsActivityDisabled] = useState(false);
  const [isAgeGroupDisabled, setIsAgeGroupDisabled] = useState(false);
  const [isLessonTypeDisabled, setIsLessonTypeDisabled] = useState(false);
  const [ageGroup, setAgeGroup] = useState(initialState.ageGroup);
  const [activity, setActivity] = useState(initialState.activity);
  const [lessonType, setlessonType] = useState(initialState.lessonType);
  const [durationHours, setDuration] = useState(initialState.durationHours);
  const [startTime, setStartTime] = useState(initialState.startTime);
  const [session, setSession] = useState(initialState.session);
  const [instructor, setInstructor] = useState(initialState.instructor);
  const [instructorDetails, setInstructorDetails] = useState(
    initialState.instructorDetails
  );
  const [selectedInstructorOption, setSelectedInstructorOption] = useState(
    initialState.selectedInstructorOption
  );
  const [durationDays, setDurationDays] = useState(
    initialState.groupLessonDurationDays
  );
  const [startDate, setstartDate] = useState(initialState.startDate);
  const [participants, setParticipants] = useState(
    initialState.initialParticipants
  );
  const [sessionMapper, setSessionMapper] = useState({});

  /* states for option array */
  const [filteredAgeGroups, setFilteredAgeGroups] = useState([]);
  const [filteredActivityDays, setFilteredActivityDays] = useState([]);
  const [filteredSession, setFilteredSession] = useState([]);
  const [filteredDurationHours, setFilteredDurationHours] = useState([]);
  const [filteredStartTime, setFilteredStartTime] = useState([]);

  // to warn users required fields
  const [errors, setErrors] = useState(initialErrors);

  // Popover
  const [popoverOpen, setPopoverOpen] = React.useState(null);

  const activities = ["ski", "snowboard"];
  const instructors = ["Helen", "Tommy"];
  const groupsWithInstructors = [PRIVATE];
  const activityMembers = main[activity];

  /******************************** variables *********************************/
  //TODO: move this to helper or somewehre
  let abilityMapper = {
    ski: "SkiAbility",
    snowboard: "SnowboardAbility"
  };
  const id = popoverOpen ? "simple-popover" : undefined;
  let isPrivateLesson =
    isLessonTypeDisabled || groupsWithInstructors.indexOf(lessonType) >= 0;
  let newLesson = {
    activity: activity.toLowerCase(),
    lessonType,
    adaptive: isAdaptive,
    firstTimer: isFirstTimer,
    participants: getParticipantsList(participants),
    selectedInstructorOption,
    instructorDetails,
    startDate,
    newlesson: needsHighlight,
    ageGroup,
    ...getDaysAndTime(
      isPrivateLesson,
      { durationHours, startTime, session, durationDays },
      sessionMapper
    ),
    ...ageGroupMapper[ageGroup]
  };

  let allLessons, lessonCategories, filteredLesson;
  let checkedPtcpnt = [];
  _.map(participants, (p, id) => {
    if (p.isChecked) checkedPtcpnt.push(p);
  });
  if (isAdaptive) {
    lessonCategories = [PRIVATE];
  } else {
    lessonCategories = [GROUP, PRIVATE];
  }

  /**************************** UI related functions **************************/
  const handleChange = id => event => {
    setParticipants({
      ...participants,
      [id]: { ...participants[id], isChecked: event.target.checked }
    });
    setErrors({ ...errors, membersError: false });
    const values = Object.values(participants);
    const checkedParticipants = values.filter(
      participant => participant.isChecked
    );

    if (checkedParticipants.length > 0) {
      let checkAbility = null;
      snackParams.activity === "ski"
        ? (checkAbility = "SkiAbility")
        : (checkAbility = "SnowboardAbility");
      const leveltoCheck = values.filter(
        participant => participant.isChecked
      )[0][checkAbility];
      if (
        participants[id][checkAbility] !== leveltoCheck &&
        !participants[id].isChecked
      ) {
        setSnackParams({ ...snackParams, open: true, id: id });
      }
    }
  };

  const handleDateChange = date => {
    setstartDate(date);
  };

  const handleClickOpen = () => {
    // if click edit popup from edit popup
    if (tripLessonId) {
      setIsActivityDisabled(true);
      setIsAgeGroupDisabled(true);
      if (isAdaptive) setIsLessonTypeDisabled(true);

      if (isPrivateLesson) {
        let filteredDays = calculatePrivateDayRange(initialDate, endDate);
        setFilteredActivityDays(filteredDays);
        setFilteredDurationHours(
          filterDurationHours(activity, privateLessons, isFirstTimer)
        );
        setFilteredStartTime(
          filterStartTime(activity, privateLessons, durationHours, isFirstTimer)
        );
        // filter suitable lessons
        filteredLesson = filterPrivateParticipants(
          privateLessons,
          activity,
          ageGroup,
          durationHours,
          startTime,
          isFirstTimer
        );
      } else {
        // call these functinos to render existing values in edit popup
        setFilteredActivityDays(
          filterNumDays(activity, groupLessons, ageGroup, isFirstTimer)
        );
        const { filteredSession, mapper } = filterSession(
          activity,
          groupLessons,
          ageGroup,
          durationDays,
          isFirstTimer,
          sessionMapper
        );
        setFilteredSession(filteredSession);
        setSessionMapper(mapper);

        if (durationDays) {
          filteredLesson = filterGroupParticipants(
            groupLessons,
            activity,
            ageGroup,
            durationDays,
            session,
            isFirstTimer
          );
        }
      }
      const eligibleParticipants = getEligibleParticipants(
        activityMembers,
        filteredLesson,
        activity,
        { familyMembers, isAdaptive, isFirstTimer, participants, tripLessonId }
      );

      setParticipants({ ...participants, ...eligibleParticipants });
    }
  };

  const handlePopoverClose = () => {
    setPopoverOpen(null);
  };

  const handleChooseAgain = async () => {
    await setParticipants({
      ...participants,
      [snackParams.id]: { ...participants[snackParams.id], isChecked: false }
    });
    setSnackParams({ ...snackParams, open: false, id: null });
  };
  const handleEnroll = () => {
    setSnackParams({ ...snackParams, open: false, id: null });
  };

  const handleClose = () => {
    onClose();

    // clean up fields after closing the window
    if (!tripLessonId) {
      setIsActivityDisabled(false);
      setIsAgeGroupDisabled(false);
      setIsLessonTypeDisabled(false);
      setAgeGroup("");
      setActivity("");
      setlessonType("");
      setDuration("");
      setStartTime("");
      setSession("");
      setInstructor("");
      setDurationDays("");
      setSelectedInstructorOption("1");
      setInstructorDetails("");
      setSessionMapper({});
      setstartDate(new Date());
      setParticipants(initialState.initialParticipants);
      setErrors(initialErrors);
    }
  };

  const handleSave = (event, useConfirm) => {
    let errorsCopy = validateForm(errors, {
      activity,
      ageGroup,
      durationDays,
      lessonType,
      tripLessonId,
      participantsList: getParticipantsList(participants),
      participants,
      isPrivateLesson,
      durationHours,
      startTime,
      selectedInstructorOption,
      instructorDetails,
      session,
      timeClashCheckerObject: {
        newLesson,
        tripLessons,
        tripLessonId,
        familyMembers
      }
    });

    if (hasErrors(errorsCopy, initialErrors)) {
      setErrors(errorsCopy);
      return;
    }

    if (errorsCopy.differentAbilityLevelError && !useConfirm) {
      setPopoverOpen(event.currentTarget);
      return;
    }

    // TODO: async update and edit
    if (tripLessonId) {
      let tripLessonsCopy;
      // delete the lesson without participants
      if (newLesson.participants.length === 0) {
        tripLessonsCopy = tripLessons.filter(
          lesson => lesson.tripLessonId != tripLessonId
        );
      } else {
        tripLessonsCopy = [...tripLessons];
        const lesson = tripLessons.filter(
          lesson => lesson.tripLessonId === tripLessonId
        )[0];
        tripLessonsCopy[tripLessons.indexOf(lesson)] = {
          ...newLesson,
          tripLessonId: lesson.tripLessonId
        };
      }
      props.editTripLesson(tripLessonsCopy);
    } else {
      props.createTripLessons({
        ...newLesson,
        tripLessonId: uuid.v4()
      });
    }
    // clean up fields after closing the window
    handleClose();
  };

  const handleCategoryChange = event => {
    setlessonType(event.target.value);
    setIsAgeGroupDisabled(false);
    setErrors({ ...errors, lessonTypeError: false });
    if (
      event.target.value === PRIVATE ||
      event.target.value === PRIVATE_ADAPTIVE ||
      event.target.value === FIRST_TIMER_PRIVATE
    ) {
      isPrivateLesson = true;
      allLessons = privateLessons;
      // TODO: When add ageGroup in private table in database, this should be updated
      // setFilteredAgeGroups(["3-5 yrs", "6+ yrs"]);
      setFilteredAgeGroups(filterAgeGroup(activity, allLessons, isFirstTimer));
      if (isPrivateLesson) {
        let filteredDays = calculatePrivateDayRange(initialDate, endDate);
        setFilteredActivityDays(filteredDays);
      }
      setFilteredDurationHours(
        filterDurationHours(activity, allLessons, isFirstTimer)
      );
    } else {
      allLessons = groupLessons;
      setFilteredActivityDays(
        filterNumDays(activity, groupLessons, ageGroup, isFirstTimer)
      );
      // console.log("age groups", filterAgeGroup(activity, allLessons, isFirstTimer));
      setFilteredAgeGroups(filterAgeGroup(activity, allLessons, isFirstTimer));
    }
  };

  const handleAgeGroupChange = event => {
    setAgeGroup(event.target.value);
    setErrors({ ...errors, ageGroupError: false });
  };

  const handleDaysChange = event => {
    setDurationDays(event.target.value);
    setErrors({ ...errors, daysError: false });
  };

  const handleActivityChange = event => {
    setActivity(event.target.value);
    setErrors({ ...errors, activityError: false });
    setSnackParams({
      ...snackParams,
      activity: event.target.value,
      open: false
    });
  };

  const handleSessionChange = event => {
    setSession(event.target.value);
    setErrors({ ...errors, sessionError: false });
  };

  const handleStartTimeChange = event => {
    setStartTime(event.target.value);
    setErrors({ ...errors, startTimeError: false });
  };

  const handleDurationChange = event => {
    setDuration(event.target.value);
    setErrors({ ...errors, durationError: false });
  };

  const handleInstructorOptionChange = (event, props) => {
    setSelectedInstructorOption(props.key);
    setInstructorDetails("");
    setErrors({ ...errors, instructorDetailsError: false });
  };

  const handleInstructorDetailsChange = event => {
    setInstructorDetails(event.target.value);
    setErrors({ ...errors, instructorDetailsError: false });
  };

  /**************************** ComponentDidUpate *****************************/
  useEffect(() => {
    setErrors({
      ...errors,
      membersError: false,
      differentAbilityLevelError: false,
      inOldLessonsErrorMsg: ""
    });
    // check if lesson already exists
    let memberIdsWithTimeClash = checkTimeClash(
      newLesson,
      tripLessons,
      tripLessonId
    );
    if (memberIdsWithTimeClash.length > 0) {
      setErrors({
        ...errors,
        inOldLessonsErrorMsg: membersWithTimeClashes(
          memberIdsWithTimeClash,
          familyMembers
        )
      });
    }
    if (!doParticipantsHaveSameAbilityLevel(participants, activity)) {
      setErrors({ ...errors, differentAbilityLevelError: true });
    }
  }, [participants]);

  useEffect(() => {
    setFilteredStartTime(
      filterStartTime(activity, privateLessons, durationHours, isFirstTimer)
    );

    if (durationDays) {
      const { filteredSession, mapper } = filterSession(
        activity,
        groupLessons,
        ageGroup,
        durationDays,
        isFirstTimer
      );
      setFilteredSession(filteredSession);
      setSessionMapper(mapper);
    }

    //group lesson depends on session change
    if (!isPrivateLesson) {
      if (durationDays && session) {
        filteredLesson = filterGroupParticipants(
          groupLessons,
          activity,
          ageGroup,
          durationDays,
          session,
          isFirstTimer
        );
      }
      setFilteredActivityDays(
        filterNumDays(activity, groupLessons, ageGroup, isFirstTimer)
      );
    } else {
      filteredLesson = filterPrivateParticipants(
        privateLessons,
        activity,
        ageGroup,
        durationHours,
        startTime,
        isFirstTimer
      );
    }

    const eligibleParticipants = getEligibleParticipants(
      activityMembers,
      filteredLesson,
      activity,
      { familyMembers, isAdaptive, isFirstTimer, participants, tripLessonId }
    );
    if (!tripLessonId) {
      setParticipants({ ...eligibleParticipants });
    } else {
      //append new participants to the existing participants
      setParticipants({ ...participants, ...eligibleParticipants });
    }
  }, [session, durationHours, startTime, ageGroup, durationDays, activity]);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        onEntered={handleClickOpen}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent className={classes.dialogContent}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs={12} sm={12}>
              {isActivityDisabled && tripLessonId ? (
                <FormControl className={classes.formControl}>
                  <TextField
                    disabled
                    label="Activity"
                    defaultValue={initialState.activity}
                  />
                </FormControl>
              ) : (
                <FormControl
                  className={classes.formControl}
                  error={errors.activityError}
                >
                  <InputLabel>Activity</InputLabel>
                  <Select value={activity} onChange={handleActivityChange}>
                    {activities.map(activity => (
                      <MenuItem value={activity} key={activity}>
                        {activity}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>

            <Grid item xs={12} sm={12}>
              {isLessonTypeDisabled && tripLessonId ? (
                <FormControl className={classes.formControl}>
                  <TextField
                    disabled
                    label="Lesson Category"
                    defaultValue={initialState.lessonType}
                  />
                </FormControl>
              ) : (
                <FormControl
                  className={classes.formControl}
                  error={errors.lessonTypeError}
                >
                  {/* <TextField
                      disabled
                      label="Lesson Category"
                      defaultValue={initialState.lessonType}
                    /> */}
                  <InputLabel>Lesson Category</InputLabel>
                  <Select value={lessonType} onChange={handleCategoryChange}>
                    {lessonCategories.map(category => (
                      <MenuItem value={category} key={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>

            <Grid item xs={12} sm={12}>
              {isAgeGroupDisabled && tripLessonId ? (
                <FormControl className={classes.formControl}>
                  <TextField
                    disabled
                    label="Age Group"
                    defaultValue={initialState.ageGroup}
                  />
                </FormControl>
              ) : (
                <FormControl
                  className={classes.formControl}
                  error={errors.ageGroupError}
                >
                  <InputLabel>Age Group</InputLabel>
                  <Select value={ageGroup} onChange={handleAgeGroupChange}>
                    {filteredAgeGroups.map(age => (
                      <MenuItem value={age} key={age}>
                        {age}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>

            <Grid item xs={12} sm={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Start Date"
                  //Default the initial value as the trip start date + 1; first day is considered as travelling day
                  value={startDate}
                  //However, allow the user to choose the start date of trip as the start date of lessons if they wish to
                  minDate={
                    tripLessonId
                      ? null
                      : new Date().setDate(startDate.getDate() - 1)
                  }
                  maxDate={endDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  className={classes.datePicker}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormControl
                className={classes.formControl}
                error={errors.daysError}
              >
                {isPrivateLesson ? (
                  <InputLabel># of Consecutive Days</InputLabel>
                ) : (
                  <InputLabel># of Days</InputLabel>
                )}
                {/* concat "days" in the map */}
                <Select value={`${durationDays}`} onChange={handleDaysChange}>
                  {filteredActivityDays.map(day => {
                    return (
                      <MenuItem value={`${day}`} key={day}>{`${day}`}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            {!isPrivateLesson ? (
              <Grid item xs={12} sm={12}>
                <FormControl
                  className={classes.formControl}
                  error={errors.sessionError}
                >
                  <InputLabel>Session</InputLabel>
                  <Select
                    value={session ? session : ""}
                    onChange={handleSessionChange}
                  >
                    {filteredSession.sort().map(session => (
                      <MenuItem value={session} key={session}>
                        {session}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}

            {isPrivateLesson ? (
              <Grid item xs={12} sm={12}>
                <FormControl
                  className={classes.formControl}
                  error={errors.durationError}
                >
                  <InputLabel>Duration Hours</InputLabel>
                  <Select value={durationHours} onChange={handleDurationChange}>
                    {filteredDurationHours.sort().map(duration => (
                      <MenuItem value={duration} key={duration}>
                        {duration}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}

            {isPrivateLesson ? (
              <Grid item xs={12} sm={12}>
                <FormControl
                  className={classes.formControl}
                  error={errors.startTimeError}
                >
                  <InputLabel>Start Time</InputLabel>
                  <Select value={startTime} onChange={handleStartTimeChange}>
                    {filteredStartTime.sort().map(startTime => (
                      <MenuItem value={startTime} key={startTime}>
                        {startTime}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}

            {isPrivateLesson ? (
              <Grid item xs={12} sm={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel>Instructor</InputLabel>
                  <Select
                    value={
                      instructorOptionsMapper[selectedInstructorOption].text
                    }
                    onChange={handleInstructorOptionChange}
                  >
                    {_.map(instructorOptionsMapper, (option, key) => (
                      <MenuItem value={option.text} key={key}>
                        {option.text}
                      </MenuItem>
                    ))}
                  </Select>
                  {selectedInstructorOption === "1" ? null : (
                    <TextField
                      error={errors.instructorDetailsError}
                      style={{ marginTop: "0.5em" }}
                      variant="outlined"
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      value={instructorDetails}
                      onChange={handleInstructorDetailsChange}
                      placeholder={
                        instructorOptionsMapper[selectedInstructorOption]
                          .placeholder
                      }
                    />
                  )}
                </FormControl>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <FormControl
                component="fieldset"
                className={classes.formControl}
                error={errors.membersError}
              >
                <FormLabel component="legend">Participants</FormLabel>
                {checkedPtcpnt.length === 0 && tripLessonId ? (
                  <Typography variant="body2" color="error">
                    Warning: remove all participants will delete this lesson
                  </Typography>
                ) : null}
                {Object.keys(participants).length === 0 ? (
                  <Typography>No eligible participants found!</Typography>
                ) : (
                  <FormGroup className={classes.checkBox}>
                    {_.map(participants, (participant, id) => (
                      <FormControlLabel
                        key={id}
                        control={
                          <Checkbox
                            checked={participant.isChecked}
                            disabled={
                              isFirstTimer &&
                              tripLessonId != undefined &&
                              checkedPtcpnt.length === 1 &&
                              participant.isChecked
                            }
                            color="primary"
                            onChange={handleChange(id)}
                          />
                        }
                        label={
                          activity
                            ? participant.FirstName + " " + participant.LastName
                            : null
                        }
                      />
                    ))}
                  </FormGroup>
                )}
              </FormControl>
              {snackParams.open ? (
                <SnackbarContent
                  open={snackParams.open}
                  message={
                    "This participant is not the same ability level as the others chosen…we strongly" +
                    " recommend a separate lesson for this participant"
                  }
                  action={
                    <div>
                      <Button
                        color="secondary"
                        size="small"
                        onClick={handleChooseAgain}
                      >
                        Choose again
                      </Button>
                      <Button
                        color="secondary"
                        size="small"
                        onClick={handleEnroll}
                      >
                        Enroll anyway
                      </Button>
                    </div>
                  }
                />
              ) : null}
            </Grid>
          </Grid>
          {hasFieldsRequiredToBeFilledError(errors, initialErrors) ? (
            <Typography variant="body2" color="error">
              Please fill in required data!
            </Typography>
          ) : null}
          <Typography variant="body2" color="error">
            {errors.inOldLessonsErrorMsg}
          </Typography>
          {/* {errors.differentAbilityLevelError ? (
            <Typography variant="body2" color="error">
              There are at least two selected participants have different
              ability levels!
            </Typography>
          ) : null} */}
        </DialogContent>
        <DialogActions>
          <Button
            aria-describedby={id}
            variant="contained"
            className={classes.actionBtns}
            color="primary"
            disabled={
              errors.inOldLessonsErrorMsg !== "" ||
              hasFieldsRequiredToBeFilledError(errors, initialErrors)
            }
            onClick={handleSave}
          >
            Save
          </Button>
          <Popover
            id={id}
            open={Boolean(popoverOpen)}
            anchorEl={popoverOpen}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
          >
            <Typography className={classes.popover}>
              Do you want to enrol this lesson anyway?
              <Button onClick={event => handleSave(event, true)}> Yes </Button>
              <Button onClick={handlePopoverClose}> No </Button>
            </Typography>
          </Popover>
          <Button
            variant="contained"
            className={classes.actionBtns}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    tripLessons: state.tripLessonReducer.tripLessons,
    familyMembers: state.tripWhoReducer.familyMembers,
    tripMembers: state.tripWhoReducer.tripMembers,
    privateLessons: state.tripLessonReducer.privateLessons,
    groupLessons: state.tripLessonReducer.groupLessons,
    main: state.activityReducer.main,
    initialDate: state.tripWhenReducer.startDate,
    endDate: state.tripWhenReducer.endDate
  };
}

export default connect(
  mapStateToProps,
  { createTripLessons, editTripLesson },
  null,
  { forwardRef: true }
)(EditPopup);
