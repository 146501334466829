import { CHANGE_TRIP_START_DATE, CHANGE_TRIP_END_DATE } from "./action-types";

export const changeTripStartDate = startDate => {
  return {
    type: CHANGE_TRIP_START_DATE,
    payload: { startDate }
  };
};

export const changeTripEndDate = endDate => {
  return {
    type: CHANGE_TRIP_END_DATE,
    payload: { endDate }
  };
};
