import {
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from "@material-ui/core";
import _ from "lodash";
import { connect } from "react-redux";
import React from "react";

const styles = {
  span: {
    float: "right"
  },

  input: {},

  label: {
    display: "block",
    textAlign: "left"
  },
  selectGroupContainer: {
    display: "flex",
    flexWrap: "wrap",
    margin: "auto"
  },
  select: {
    padding: 0,
    textAlign: "left",
    width: "100%"
  },
  selectContainer: {},

  title: { marginLeft: 15 }
};

function ActivityMemberMultiSelect(props) {
  const { familyMembers, activities, tripMembers, activity } = props;

  return (
    <div>
      <InputLabel style={styles.label} htmlFor={activity}>
        {activity}
      </InputLabel>
      <Select
        // remove arrow icon
        onClose={props.onClose}
        open={props.open}
        onClose={props.onClose}
        IconComponent={() => <div />}
        displayEmpty
        style={styles.select}
        name={activity}
        value={activities[activity]}
        multiple
        onChange={event => props.onChange(event, props.activity)}
        input={<Input style={styles.input} id={activity} />}
        onOpen={props.onOpen}
        // input box contents
        renderValue={selected => {
          if (selected.length === 0) {
            return <em>Click to add members</em>;
          } else {
            return (
              <div style={{ margin: "auto" }}>
                {selected.map(uuid => (
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}
                    key={uuid}
                  >
                    {familyMembers[uuid].FirstName}{" "}
                    {familyMembers[uuid].LastName}
                  </div>
                ))}
              </div>
            );
          }
        }}
      >
        <MenuItem value="addAll">
          <ListItemText primary={"[Add all]"} />
        </MenuItem>

        {_.map(tripMembers, uuid => {
          return (
            <MenuItem
              style={{
                background: _.includes(activities[activity], uuid)
                  ? "rgba(0, 166, 255, 1)"
                  : "white"
              }}
              key={uuid}
              value={uuid}
            >
              <ListItemText
                primary={
                  familyMembers[uuid].FirstName +
                  " " +
                  familyMembers[uuid].LastName +
                  " " +
                  familyMembers[uuid].DOB.toLocaleDateString("en-au")
                }
              />
            </MenuItem>
          );
        })}

        <MenuItem value="close" style={styles.span}>
          <span>Done</span>
        </MenuItem>
      </Select>
    </div>
  );
}

function mapStateToPropsSelect(state) {
  return {
    tripMembers: state.tripWhoReducer.tripMembers,
    familyMembers: state.tripWhoReducer.familyMembers
  };
}

export default connect(mapStateToPropsSelect)(ActivityMemberMultiSelect);
