import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Gallery from "../../../components/virtualLessons/InstructorGalleryCarousel.js";
import Reviews from "../../../components/virtualLessons/InstructorReviewCarousel.js";
import Grid from "@material-ui/core/Grid";
import StudentIntroSection from "./StudentIntroSection.js";
import Button from "@material-ui/core/Button";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import StudentMyProfile from "./StudentMyProfile.js";
import FooterBar from "../../../components/Common/Footer/FooterBar.js";
import { useEffect, useState } from "react";
import agent from "../../../agent";

import { updateStuProfile } from "../../../redux/actions/studentProfileActions";
import { connect } from "react-redux";
import { SEND_HOST_NAME } from "../../../redux/actions/action-types.js";
import { first } from "lodash";

const useStyles = makeStyles({
  main: {
    margin: "2em"
  },
  typograhyFormat: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontSize: 16,
    lineHeight: "22.50px",
    textAlign: "left",
    color: "#1277B8",
    marginTop: "2rem",
    marginBottom: "2rem",
    marginLeft: 50,
    fontWeight: "bolder",
    width: "100%"
  },
  arrowIcon: {
    transform: "rotate(180deg)"
  },
  backButton: {
    margin: "3em",
    borderColor: "#FF6161",
    color: "#FF6161"
  }
});
const StudentProfile = props => {
  const {
    studentMyProfile,
    studentIntroSection,
    isStudent,
    _updateStuProfile
  } = props;

  const classes = useStyles();

  useEffect(() => {
    //getting Student profiles from database
    agent.Students.getStudent(1)
      .then(response => {
        const responseData = response.data;
        console.log("responseData", responseData);
        _updateStuProfile(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <div className={classes.main}>
      <Grid container item sm={12} xs={12}>
        <Grid container item sm={6} xs={12} spacing={2} lg={12}>
          <Button
            className={classes.backButton}
            variant="outlined"
            startIcon={<ArrowRightAltIcon className={classes.arrowIcon} />}
          >
            Back
          </Button>
        </Grid>
      </Grid>

      <StudentMyProfile {...studentMyProfile} isStudent={isStudent} />

      <StudentIntroSection {...studentIntroSection} />

      <Grid container direction="Row" alignContent="center" justify="center">
        <Grid item xs={12} md={12}>
          <Typography className={classes.typograhyFormat}>
            {isStudent ? "REVIEWS" : "WHAT INSTRUCTOR SAYS"}
          </Typography>
          <Reviews instructorId={1} />
        </Grid>

        <Grid item xs={12} md={12}>
          <Typography className={classes.typograhyFormat}>GALLERY</Typography>
          <Gallery instructorId={1} />
        </Grid>
      </Grid>

      <Grid container direction="Row" alignContent="center" justify="center">
        <Grid item xs={12}>
          <FooterBar />
        </Grid>
      </Grid>
    </div>
  );
};

// Reducer part
function mapStateToProps(state) {
  return {
    studentIntroSection: {
      ...state.studentProfileReducer.studentIntroSection
    },
    studentMyProfile: {
      ...state.studentProfileReducer.studentMyProfile
    }
  };
}

// Adding Dispatch to update the existing values in Redux
const mapDispatchToProps = dispatch => {
  return {
    _updateStuProfile: data => dispatch(updateStuProfile(data))
  };
};

// Connect part
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentProfile);
