import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import Grid from "@material-ui/core/Grid";
import AccountCard from "./AccountCard";
import Card from "@material-ui/core/Card";
import personalIcon from "../../../materials/Dashboard/personal.png";
import privacyIcon from "../../../materials/Dashboard/privacy.png";
import paymentslIcon from "../../../materials/Dashboard/payments.png";
import preferencesIcon from "../../../materials/Dashboard/preferences.png";
import notificationslIcon from "../../../materials/Dashboard/notifications.png";

const useStyles = makeStyles({
  //button css
  btn: {
    color: "red",
    height: "2em",
    width: "10em",
    borderColor: "red",
    textAlign: "center",
    margin: "2em"
  },
  btnArrow: {
    position: "absolute",
    left: "0",
    rotate: "180deg"
  },
  emptyCard: {
    position: "relative",
    width: "343px",
    height: "140px",
    left: "206px",
    top: "65px",

    background: "#FFFFFF",
    boxShadow: "inset 0px 4px 2px rgba(0, 0, 0, 0.03)"
  },
  btnRoot: {},
  btnLabel: {
    align: "center"
  }
  //card css
});

const AccountPage = props => {
  const classes = useStyles();
  return (
    <div className>
      <Button
        variant="outlined"
        className={classes.btn}
        classes={{ root: classes.btnRoot, label: classes.btnLabel }}
      >
        <ArrowRightAltIcon className={classes.btnArrow} variant="outlined" />
        Back
      </Button>

      <Card className={classes.emptyCard} />

      <Grid container spacing={3} justify="center" align="center">
        <Grid item md={5}>
          <AccountCard title="Personal Information" src={personalIcon} />
        </Grid>
        <Grid item md={5}>
          <AccountCard title="Payments & Payouts" src={paymentslIcon} />
        </Grid>
        <Grid item md={5}>
          <AccountCard title="Privacy & Sharing" src={privacyIcon} />
        </Grid>
        <Grid item md={5}>
          <AccountCard title="Global Preferences" src={preferencesIcon} />
        </Grid>
        <Grid item md={5}>
          <AccountCard title="Notifications" src={notificationslIcon} />
        </Grid>
      </Grid>
    </div>
  );
};
export default AccountPage;
