import React, { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";

// Component Input
import { getLanguageData } from "../../../../redux/actions/exploreHostsActions";

// Style
import {
  Grid,
  MenuItem,
  FormControl,
  Select,
  ListItemText,
  Typography,
  Checkbox,
  Box,
  Chip,
  FormHelperText
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useStyles from "../../../Common/SignupForm/ClientInfoStyles";
import { COLOR } from "../../../../styling/theme/Theme";
import clsx from "clsx";

const useClasses = makeStyles(theme => ({
  errorText:{
    color: "#f44336", marginLeft: "14px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "22px",
    },
  },
  select2: {
    fontColor: "1277B8",
    fontFamily: "PT Sans Narrow",
    borderRadius: "10px",
    "& .MuiOutlinedInput-input": {
      padding: "0.5rem 0.5rem",
      height: "auto"
      // paddingTop: "5px",
      // paddingBottom: "5px"
      // paddingLeft: "5px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      // borderWidth: "0"
      borderColor: "black  ",
      borderWidth: "1px  "
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      // borderWidth: "0"
      borderColor: "black  ",
      borderWidth: "1px  "
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      // borderWidth: "0"
      borderColor: "black  ",
      borderWidth: "1px  "
    },
    "& .MuiListItemText-root": {
      fontFamily: "PT Sans Narrow",
      marginTop: "0px"
    },
    "& .MuiTypography-body1": {
      lineHeight: "inherit"
    }
  },
  label: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    marginBottom: "0.25em",
    textTransform: "uppercase",
    color: "#000000"
  },
  inputSelect: {
    marginTop: "0.2em",
    textAlign: "center",
    flexWrap: "wrap",
    display: "flex"
  },
  selectMenu: {
    width: "90%"
  },
  selectRootText: {
    textAlign: "center",
    color: COLOR.PRIMARY,
    width: "90%",
    "& div.MuiOutlinedInput-root": {
      height: "Auto",
      fontFamily: "PT Sans"
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      borderColor: "black"
    },
    "& .MuiOutlinedInput-root .MuiSelect-select:focus": {
      backgroundColor: "#EFEFEF"
    }
  },
  selectMenu: {
    width: "90%"
    // height: "0.1em"
  },
  selectRoot: {
    textAlign: "center",
    borderColor: "black",
    width: "100%",
    "& div.MuiOutlinedInput-root": {
      height: "Auto",
      fontFamily: "PT Sans"
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      borderColor: "black !important"
    },
    "& .MuiOutlinedInput-root .MuiSelect-select:focus": {
      backgroundColor: "transparent"
    }
  },
  selectIcon: {
    color: COLOR.PRIMARY
  },
  search: {
    width: "17em"
  },
  checkBoxStyle: {
    "& .MuiCheckbox-root": {
      color: "#1277B8"
    }
  },
  searchRoot: {
    borderRadius: 5,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: COLOR.PRIMARY,
        borderWidth: 1,
        borderRadius: 5
      },
      "&:hover fieldset": {
        borderColor: COLOR.PRIMARY,
        borderWidth: 1,
        borderRadius: 5
      },
      "&.Mui-focused fieldset": {
        borderColor: "#ff0000",
        borderWidth: 1,
        borderRadius: 5
      }
    }
  },
  listRoot: {
    whiteSpace: "normal",
    // width: "18em",
    alignContent: "center"
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5"
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)"
    }
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3"
    }
  }
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function LanguageForm(props) {
  const styles = useStyles();
  const classes = useClasses();

  const { language_list } = props;
  const { languages, setLanguages, isDisabled } = props;

  let [error, setError] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState([]);

  const handleLanguage = event => {
    let duplicated = false;
    let selectedItem = event.target.value[event.target.value.length - 1];

    selectedLanguage.forEach(item => {
      if (item == selectedItem.language) {
        duplicated = true;
      }
    });

    if (!duplicated) {
      setSelectedLanguage([...selectedLanguage, selectedItem.language]);
      setLanguages([...languages, selectedItem]);
    } else {
      let filteredArray = [...selectedLanguage].filter(
        item => item !== selectedItem.language
      );
      setSelectedLanguage(filteredArray);
      let filteredArray2 = [...languages].filter(
        item => item.language !== selectedItem.language
      );
      setLanguages(filteredArray2);
    }
  };

  // get ALL language data
  useEffect(() => {
    props.getLanguageData();
  }, []);

  useEffect(() => {
    if (languages != undefined) {
      let langArr = [];
      languages.forEach(item => {
        langArr.push(item.language);
      });
      setSelectedLanguage(langArr);
    }
  }, [languages]);

  const handleClose = event => {
    if (selectedLanguage == "" || selectedLanguage == null) {
      setError(true);
    } else{
      setError(false);
    }
  };

  return (
    <Grid
      item
      container
      xs={12}
      sm={4}
      direction="column"
      justify="center"
      alignItems="left"
      style={{ marginBottom: "1rem" }}
    >
      <Grid item style={{ padding: "0.25rem 0" }}>
        <Typography className={styles.allergyandotherinformation}>
          LANGUAGE
        </Typography>
      </Grid>
      <Grid item container justify="center" alignItems="left">
        <FormControl
          variant="outlined"
          classes={{ root: classes.selectRoot }}
          disabled={isDisabled}
          error={error}
        >
          <Select
            multiple
            onClose={handleClose}
            value={selectedLanguage}
            onChange={handleLanguage}
            renderValue={selected => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {selected.map(value => (
                  <Chip
                    style={{ fontFamily: "PT Sans Narrow" }}
                    key={value}
                    label={value}
                  />
                ))}
              </Box>
            )}
            className={classes.select2}
            classes={{
              selectMenu: classes.selectMenu,
              root: classes.selectRoot,
              icon: classes.selectIcon,
              selected: classes.selected
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
          >
            {language_list.map((lang, index) => {
              return (
                <MenuItem key={index} value={lang}>
                  <Checkbox
                    className={classes.root}
                    disableRipple
                    color="default"
                    checkedIcon={
                      <span
                        className={clsx(classes.icon, classes.checkedIcon)}
                      />
                    }
                    checked={selectedLanguage.indexOf(lang.language) > -1}
                    icon={<span className={classes.icon} />}
                    inputProps={{ "aria-label": "decorative checkbox" }}
                  />
                  <ListItemText primary={lang.language} />
                </MenuItem>
              );
            })}
          </Select>
          {error ? <FormHelperText className={classes.errorText}>*Required</FormHelperText> : (
          <FormHelperText className={classes.errorText}>&nbsp;</FormHelperText>
          )}
        </FormControl>
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    language_list: state.exploreHostsReducer.language
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getLanguageData: () => dispatch(getLanguageData())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageForm);