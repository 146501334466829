import React, { Component } from "react";
import "../../../../styling/css/template/SliderBar.css";

class PureSliderBar extends Component {
  handleChange = e => {
    const { onChange } = this.props;
    onChange(e);
  };

  render() {
    const { min, max, id, value } = this.props;

    return (
      <React.Fragment>
        <input
          type="range"
          className="color-slider-bar"
          name={id}
          min={min}
          max={max}
          step="1"
          id={id}
          onChange={this.handleChange}
          value={value}
        />
      </React.Fragment>
    );
  }
}

export default PureSliderBar;
