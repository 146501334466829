//this is the timeslots for the selected availabilities

import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SelectedTimeslot from "../SelectedTimeslot";
import { equalsDate, equalsDatePlusOne } from "../Helpers/dates";

const useStyles = makeStyles({
  dateText: { fontSize: 11, marginTop: ".25rem" },
  highlight: { background: "#C5C5C5", padding: "0.25rem" },
  dropdown: { marginLeft: ".2rem" },
  arrow: { margin: "0 .5em 0 .5em" }
});

//drop down menu
//"1st December 1pm to 3rd December 2pm" for example
//props:
//take all dates
//format into lists of uninterupted dates
//
const SelectedAvailability = ({
  dates,
  unavailability,
  disabled = false,
  timeslots,
  setTimeslots
}) => {
  const classes = useStyles();

  React.useEffect(() => {
    reloadTimeslots();
  }, [dates]);

  const reloadTimeslots = () => {
    let numberOfTimeslots = 0;
    let newTimeslots = [];
    if (dates && dates.length > 0) {
      let dates_sorted = dates.sort(function(date1, date2) {
        if (date1.start > date2.start) return 1;
        if (date1.start < date2.start) return -1;
        return 0;
      });

      //Takes a series of dates and returns uninterupted beginning and end
      // of string of dates (26, 27, 28, 30) becomes (26 -> 28, 30)
      let datefrom = dates_sorted[0].start;
      let prev_date = dates_sorted[0].start;
      let dateto = dates_sorted[0].end;

      dates_sorted.map(date => {
        if (
          equalsDate(date.start, prev_date) ||
          equalsDatePlusOne(date.start, prev_date)
        ) {
          dateto = date.end;
          prev_date = date.start;
          newTimeslots[numberOfTimeslots] = {
            datefrom,
            dateto,
            id: numberOfTimeslots
          };
          setTimeslots(newTimeslots);
        } else {
          newTimeslots[numberOfTimeslots] = {
            datefrom,
            dateto,
            id: numberOfTimeslots
          };
          setTimeslots(newTimeslots);
          numberOfTimeslots += 1;
          datefrom = date.start;
          dateto = date.end;

          newTimeslots[numberOfTimeslots] = {
            datefrom,
            dateto,
            id: numberOfTimeslots
          };
          setTimeslots(newTimeslots);
        }
        prev_date = date.start;
      });
    } else {
      setTimeslots([]);
    }
  };

  const renderTimeslots = timeslots.map(timeslot => (
    <SelectedTimeslot
      datefrom={timeslot.datefrom}
      dateto={timeslot.dateto}
      id={timeslot.id}
      timeslots={timeslots}
      disabled={disabled}
      setTimeslots={setTimeslots}
    />
  ));

  return <div>{renderTimeslots}</div>;
};

export default SelectedAvailability;
