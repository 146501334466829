import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { getCountryList } from "../../../redux/actions/homePageAction";

function handleSearch() {}

const useStyles = makeStyles(theme => ({
  header: {
    width: 285,
    height: 87,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 30,
    color: "#1277B8",
    marginTop: 18,
    fontFamily: "PT Sans Narrow",
    marginLeft: "5%"
  },
  row: {
    textAlign: "end",
    marginBottom: "0.7em",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  typography: {
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left"
    }
  },
  leftLabel: {
    display: "inline",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    color: "#1277B8",
    paddingTop: "0.4em",
    marginRight: "1em",
    verticalAlign: "sub"
  },
  formControl: {
    width: "100%",
    color: "black",
    /* display: "inline",
    "& .svg.MuiSvgIcon-root .MuiStepIcon-completed, path": {
      color: "#4A443F"
    }, */
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black !important",
      borderWidth: "1px !important"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black !important",
      borderWidth: "1px !important"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black !important",
      borderWidth: "1px !important"
    }
  },
  formControlNew: {
    width: "100%",
    textAlign: "left",
    "& div.MuiOutlinedInput-root": {
      height: "2.1rem",
      fontFamily: "PT Sans Narrow"
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      borderColor: "black !important"
    },
    "& .MuiOutlinedInput-root .MuiSelect-select:focus": {
      backgroundColor: "transparent"
    }
  },
  inputLabel: {
    textAlign: "none",
    color: "#4A443F",
    fontFamily: "PT Sans Narrow",
    "&.MuiInputLabel-outlined.MuiInputLabel-shrink": {
      // transform: "translate(14px, -20px) scale(0.75);",
      color: "#505050"
    }
  },
  classSelect: {
    // width: "100%",
    height: "34px",
    // marginTop: "0.2em",
    textAlign: "center",
    flexWrap: "wrap",
    display: "flex",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline-notchedOutline": {
      borderWidth: 0
    }
  },
  selectRoot: {
    // backgroundColor: "green",
    textAlign: "left",
    "&.MuiOutlinedInput-input": {
      // padding: "0px"
    }
  },
  selectIcon: {
    color: "black"
  },
  inputRoot: {
    width: "100%",
    height: "34px",
    "& .MuiOutlinedInput-root": {
      height: "2.1em",
      width: "100%"
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px"
    },
    marginTop: "0em",
    marginBottom: "0em",
    "& .MuiInputAdornment-positionEnd": {
      marginLeft: "0px"
    },
    "& svg.MuiSvgIcon-root": {
      color: "black",
      height: "15px"
    },
    "& svg.MuiSvgIcon-root .MuiStepIcon-completed, path": {
      color: "#4A443F",
      opacity: 0.5
    }
  },
  selectTitle: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    color: "#1277B8",
    paddingTop: "0.4em",
    marginRight: "1em",
    verticalAlign: "sub"
  },
  searchCard: {
    padding: "0 48px 0 48px",
    borderRadius: 15,
    boxShadow: "7px 7px 20px rgba(0, 0, 0, 0.25)",
    // minWidth: 406,
    // maxWidth: 406,
    // minHeight: 415,
    // maxHeight: 415,
    marginLeft: "10%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10%",
      marginLeft: 0
    }
  },

  button: {
    borderRadius: 100,
    margin: "30px 100px 30px 80px",
    height: "3em",
    width: "10em",
    justify: "center",
    background: "#FF6161"
  },
  buttonLabel: {
    fontWeight: "300",
    textTransform: "none",
    color: "white",
    fontFamily: "Roboto"
  },
  select1: {
    width: "10.7em"
  },
  // select2: {
  //   width: "33.59px"
  // },
  date2: {
    // marginLeft: "0.5em"
  }
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#FFFFFF"
    },
    secondary: {
      main: "#FF6161"
    }
  }
});

const SearchCardForExploreInstructors = props => {
  const classes = useStyles();
  const today = new Date();
  const minStartDate = new Date(new Date().setDate(today.getDate() + 3));
  const minEndDate = new Date(new Date().setDate(today.getDate() + 7));
  const [startDate, setStartDate] = useState(minStartDate);
  const [endDate, setEndDate] = useState(minEndDate);
  const [country, setCountry] = useState("");
  const { country_list } = props;

  useEffect(() => {
    props.getCountryList();
  }, []);

  const handleCountry = event => {
    setCountry(event.target.value);
  };

  useEffect(() => {
    if (startDate >= endDate) {
      setEndDate(new Date(new Date().setDate(startDate.getDate() + 4)));
    }
  }, [startDate, endDate]); // Only re-run the effect if startDate changes

  const { checkList, resortList } = props;

  const checkOptions = country_list.map(data => (
    <MenuItem key={data.id} value={data.id}>
      {data.country_name}
    </MenuItem>
  ));
  const resortOptions = resortList.map(data => (
    <MenuItem key={data.id} value={data.value}>
      {data.name}
    </MenuItem>
  ));

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Grid container justify="center" alignItems="center">
        <Grid container justify="center" alignItems="center" item xs={12}>
          <ThemeProvider theme={theme}>
            <Card className={classes.searchCard}>
              <h2 className={classes.header}>Let's get started.</h2>

              {/* start date */}
              <Grid container className={classes.row}>
                <Grid item sm={5} xs={12} className={classes.typography}>
                  <Typography className={classes.leftLabel}>
                    START DATE:{" "}
                  </Typography>
                </Grid>
                <Grid item sm={7} xs={12}>
                  <FormControl classes={{ root: classes.formControl }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        selected={startDate}
                        value={startDate}
                        minDate={minStartDate}
                        onChange={date => setStartDate(date)}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                        inputVariant="outlined"
                        classes={{
                          root: classes.inputRoot
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
              </Grid>

              {/* end date copied from start date*/}
              <Grid container className={classes.row}>
                <Grid item md={5} sm={5} xs={12} className={classes.typography}>
                  <Typography className={classes.leftLabel}>
                    END DATE:{" "}
                  </Typography>
                </Grid>
                <Grid item md={7} sm={7} xs={12}>
                  <FormControl classes={{ root: classes.formControl }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        selected={endDate}
                        value={endDate}
                        minDate={startDate}
                        onChange={date => setEndDate(date)}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                        inputVariant="outlined"
                        classes={{
                          root: classes.inputRoot
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
              </Grid>

              {/* select resort by country*/}
              <Grid container className={classes.row}>
                <Grid item md={5} sm={5} xs={12} className={classes.typography}>
                  <Typography className={classes.leftLabel}>
                    SELECT COUNTRY:
                  </Typography>
                </Grid>

                <Grid item md={7} sm={7} xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControlNew}
                  >
                    <Select
                      value={country}
                      onChange={handleCountry}
                      displayEmpty
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="" disabled>
                        By Country
                      </MenuItem>
                      {resortOptions}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              {/* select resort by resort*/}
              <Grid container className={classes.row}>
                <Grid item md={5} sm={5} xs={12} className={classes.typography}>
                  <Typography className={classes.leftLabel}>
                    SELECT RESORT
                  </Typography>
                </Grid>

                <Grid item md={7} sm={7} xs={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControlNew}
                  >
                    <Select
                      value={country}
                      onChange={handleCountry}
                      displayEmpty
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="" disabled>
                        By Resort
                      </MenuItem>
                      {checkOptions}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              {/* search button */}
              <Grid
                container
                justify="center"
                alignItems="center"
                item
                sm={12}
                xs={12}
                className={classes.row}
              >
                <Button
                  variant="contained"
                  onClick={handleSearch}
                  className={classes.button}
                  classes={{ label: classes.buttonLabel }}
                >
                  Search
                </Button>
              </Grid>
            </Card>
          </ThemeProvider>
        </Grid>
      </Grid>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    startDateInRedux: state.tripWhenReducer.startDate,
    endDate: state.tripWhenReducer.endDate,
    country_list: state.homePageReducer.country_list,
    resortList: state.homePageReducer.resortList
    // resortName: state.tripWhereReducer.resortName,
    // resortId: state.tripWhereReducer.resortId,
    // country: state.tripWhereReducer.country,
    // countryResortInfoDic: state.tripWhereReducer.countryResortInfoDic,
    // countriesAndOpeningStatus: state.tripWhereReducer.countriesAndOpeningStatus,
    // resortValidated: state.tripWhereReducer.resortValidated,
    // resortsWithContinent: state.tripWhereReducer.resortsWithContinent
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getCountryList: () => dispatch(getCountryList())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchCardForExploreInstructors);
