import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import InstructorPageBody from "../../../components/virtualLessons/Instructors-redundant/InstructorPageBody";

import styles from "../../../styling/css/Homepage/instructorCard.module.css";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const colorCode = "#1976d2";

const useStyles = makeStyles(theme => ({
  colorPrimary: {
    color: colorCode
  },
  bodyTextStyle: {
    marginTop: 10
  },
  root: {
    maxWidth: 423
  },
  textContext: {
    marginTop: 10,
    padding: "16px 25px 16px 25px"
  },
  media: {
    height: 260
  },
  bold: {
    fontWeight: 500
  }
}));

const InstructorPage = () => {
  const classes = useStyles();
  // fontWeight="fontWeightMedium"
  return (
    <div className={styles.wrapper}>
      <Grid container spacing={3} alignItems="center">
        <Grid item md={12} xs={12}>
          <Typography
            component="div"
            color="primary"
            className={classes.bold}
            variant="h5"
            align="center"
            classes={{
              colorPrimary: classes.colorPrimary
            }}
          >
            <Box m={1}>Bookmark the instructors you like for your trip</Box>
          </Typography>
        </Grid>

        <Grid
          container
          item
          md={12}
          xs={12}
          justify="center"
          alignItems="center"
        >
          <Typography align="center">Placeholder for FilterSearch</Typography>
        </Grid>

        <Grid item container xs={12}>
          <InstructorPageBody />
        </Grid>
      </Grid>
    </div>
  );
};

export default InstructorPage;
