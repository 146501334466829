import React from "react";
import useStyles from "./MyAccountStyles";

import { Grid, TextField } from "@material-ui/core";

const PreLanguage = ({
  studentData,
  filterData,
  _editPreferlanguage,
  select,
  disabled
}) => {
  const classes = useStyles();

  return (
    <Grid item container md={12} direction="row">
      <Grid item lg={2} md={2} sm={2} xs={2}>
        <div align="right" className={classes.textFieldStyle}>
          Preferred Language:
        </div>
      </Grid>
      <Grid item lg={1} md={1} sm={1} xs={1} />
      <Grid item lg={5} md={8} sm={8} xs={9}>
        <div align="left">
          <TextField
            id="standard-select-language"
            disabled={disabled}
            select={select}
            value={studentData.preferLanguage}
            onChange={event => _editPreferlanguage(event.target.value)}
            variant="outlined"
            SelectProps={{
              native: true
            }}
            className={classes.textFieldLong}
          >
            {filterData.preferLanguage.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </div>
      </Grid>
      <Grid item lg={4} md={1} sm={4} />
    </Grid>
  );
};

export default PreLanguage;
