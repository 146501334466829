import React, { Component } from "react";
import { SectionBodyWrapper } from "../../../../styling/styled-components/sectionWrapper";
import { VerticalSpaceSM } from "../../../../styling/styled-components/index";
import { Elements, StripeProvider } from "react-stripe-elements";
import PaymentForm from "./PaymentForm";
import "../../../../styling/css/Book/PaymentSection.css";

class PaymentSection extends Component {
  render() {
    return (
      <SectionBodyWrapper>
        <VerticalSpaceSM />
        <div className="container">
          <StripeProvider apiKey={process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY}>
            <div>
              <VerticalSpaceSM />
              <Elements>
                <PaymentForm />
              </Elements>
            </div>
          </StripeProvider>
        </div>
      </SectionBodyWrapper>
    );
  }
}

export default PaymentSection;
