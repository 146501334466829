import React, { useEffect } from "react";
import { connect } from "react-redux";

import useStyles from "../../../styling/material-ui/Common/home/MasterHomePage_Styles";
import Grid from "@material-ui/core/Grid";

import HeaderSection from "../../../components/Common/Home/HeaderSection";
import SearchCard from "../../../components/Common/Home/SearchCard";
import ReusableSection from "../../../components/Common/Home/ReusableSection";
import CreateProfileBanner from "../../../components/Common/Home/CreateProfileBanner";
import GroupLessonSection from "../../../components/Common/Home/GroupLessonSection";

const MasterHomePage = props => {
  const classes = useStyles();

  return (
    <div>
      <HeaderSection searchCard={true} />
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.hideSearchCard}
      >
        <SearchCard />
      </Grid>
      <div className={classes.firstWrapper}>
        <div className={classes.wrapper}>
          <ReusableSection
            heading="Explore and Book Private Instructors"
            data={props.fakeInstructorData}
            more="See all Instructor"
            type="instructor"
          />
        </div>
      </div>
      <div className={classes.wrapper}>
        <GroupLessonSection />
      </div>
      <div className={classes.wrapper}>
        <ReusableSection
          heading="Explore and Book Hosts"
          data={props.fakeHostData}
          more="See all Hosts"
          type="hosts"
        />
      </div>
      <CreateProfileBanner />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    fakeHostData: state.homePageReducer.fakeHostData,
    fakeInstructorData: state.homePageReducer.fakeInstructorData
  };
}

export default connect(
  mapStateToProps,
  null
)(MasterHomePage);
