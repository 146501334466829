import { withStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    background: "#FFFFFF",
    marginLeft: "10%",
    marginRight: "10%"
  },
  paper: {
    padding: theme.spacing(5),
    border: "1px solid",
    borderColor: "red"
  },
  paper2: {
    padding: theme.spacing(5),
    background: "#F6F6F6"
  },
  btn: {
    color: "red",
    height: "3em",
    width: "7em",
    margin: "2em",
    border: "1px solid #FF6161",
    fontStyle: "normal"
  },
  btnArrow: {
    position: "absolute",
    left: "0",
    transform: "rotate(180deg)"
  },
  headingStyle: {
    color: "#1277B8",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 30,
    [theme.breakpoints.down("xs")]: {
      fontSize: 25
    },
    lineHeight: "140.62%",
    margin: 0
  },
  textField: {
    height: "45px",
    width: "100%",
    fontSize: "0.5em",
    fontSize: 15,
    fontFamily: "PT Sans Narrow",
    padding: "1em",
    border: "10px",
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    borderRadius: "0px",
    marginBottom: "20px",
    textAlign: "left"
  },
  textFieldBio: {
    height: "120px",
    width: "100%",
    fontSize: "0.5em",
    size: "small",
    fontSize: 15,
    fontFamily: "PT Sans Narrow",
    padding: "1em",
    border: "10px",
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    borderRadius: "0px",
    marginBottom: "20px"
  },
  textFieldCountry: {
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    width: "100%",
    height: "45px",
    borderStyle: "none",
    "& .MuiOutlinedInput-input": {
      padding: 13,
      fontFamily: "PT Sans Narrow",
      fontWeight: "normal",
      fontSize: 16,
      [theme.breakpoints.down("xs")]: {
        fontSize: 13,
        padding: 16
      }
    },
    "& .PrivateNotchedOutline-root-43": {
      border: "none"
    },
    marginBottom: "20px"
  },
  textFieldGender: {
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    width: "100%",
    height: "45px",
    borderStyle: "none",
    "& .MuiOutlinedInput-input": {
      padding: 13,
      fontFamily: "PT Sans Narrow",
      fontWeight: "normal",
      fontSize: 16,
      [theme.breakpoints.down("xs")]: {
        fontSize: 13,
        padding: 16
      }
    },
    "& .PrivateNotchedOutline-root-43": {
      border: "none"
    },
    marginBottom: "20px"
  },
  textFieldLanguage: {
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    width: "100%",
    height: "45px",
    borderStyle: "none",
    "& .MuiOutlinedInput-input": {
      padding: 13,
      fontFamily: "PT Sans Narrow",
      fontWeight: "normal",
      fontSize: 16,
      [theme.breakpoints.down("xs")]: {
        fontSize: 13,
        padding: 16
      }
    },
    "& .PrivateNotchedOutline-root-43": {
      border: "none"
    },
    marginBottom: "20px"
  },
  textFieldDis: {
    height: "45px",
    width: "90%",
    fontSize: "0.5em",
    size: "small",
    padding: "0em",
    border: "10px",
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    borderRadius: "0px",
    marginBottom: "20px",
    textAlign: "left"
  },
  textFieldLong: {
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    width: "100%",
    height: "45px",
    borderStyle: "none",
    "& .MuiOutlinedInput-input": {
      padding: 13,
      fontFamily: "PT Sans Narrow",
      fontWeight: "normal",
      fontSize: 16,
      [theme.breakpoints.down("xs")]: {
        fontSize: 13,
        padding: 16
      }
    },
    "& .PrivateNotchedOutline-root-43": {
      border: "none"
    },
    marginBottom: "20px"
  },
  textFieldProDetail: {
    height: "45px",
    width: "95%",
    fontSize: "0.5em",
    size: "small",
    padding: "0em",
    border: "10px",
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    borderRadius: "0px",
    marginBottom: "10px",
    textAlign: "left"
  },
  textFieldStyle: {
    fontFamily: "PT Sans Narrow",
    fontWeight: "bold",
    color: "#1277B8",
    fontSize: 16,
    [theme.breakpoints.down("xs")]: {
      fontSize: 13
    },
    lineHeight: "140.62%"
  },
  textSmall: {
    fontFamily: "PT Sans Narrow",
    fontSize: 12,
    [theme.breakpoints.down("xs")]: {
      fontSize: 10
    },
    lineHeight: "140.62%"
  },
  textFieldStyleBlack: {
    fontFamily: "PT Sans Narrow",
    fontSize: 16,
    [theme.breakpoints.down("xs")]: {
      fontSize: 13
    },
    lineHeight: "140.62%"
  },
  emptyCard2: {
    position: "relative",
    width: 100,
    height: "40px",
    background: "#FFFFFF"
  },
  emptyCard3: {
    position: "relative",
    width: 100,
    height: "20px",
    background: "#FFFFFF"
  },
  btnbot: {
    borderRadius: "100px",
    background: "#FF6161",
    width: 87.7,
    height: 30,
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontSize: 14,
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      width: 80
    },
    fontWeight: "normal",
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
    border: "none"
  },
  btnbot1: {
    width: 80,
    height: 40,
    background: "#FF6161",
    borderRadius: "100px",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 20,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16
    },
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
    border: "none"
  },
  btnResortAdd: {
    borderRadius: "100px",
    background: "#FF6161",
    width: 70,
    height: 30,
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontSize: 14,
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      width: 80
    },
    fontWeight: "normal",
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
    border: "none"
  },
  btnResortRemove: {
    borderRadius: "100px",
    background: "#C4C4C4",
    width: 70,
    height: 30,
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontSize: 14,
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      width: 80
    },
    fontWeight: "normal",
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
    border: "none"
  },
  btnEdit: {
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 16,
    marginTop: 8,
    [theme.breakpoints.down("xs")]: {
      fontSize: 13
    },
    color: "#000000",
    border: "none",
    backgroundColor: "transparent"
  },
  btnUpdate: {
    width: 90,
    height: 40,
    marginTop: "10px",
    marginBottom: "10px",
    background: "#FF6161",
    borderRadius: "100px",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 20,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      width: 80,
      height: 30
    },
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
    border: "none"
  },
  btnSave: {
    position: "absolute",
    width: "75px",
    left: "80%",
    right: "2%",
    height: 35,
    marginTop: "5px",
    marginBottom: "15px",
    background: "#FF6161",
    borderRadius: "100px",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 20,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16
    },
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
    border: "none"
  },
  col: {
    paddingRight: "5px",
    paddingLeft: "5px"
  },
  slider: {
    fontFamily: "PT Sans Narrow",
    fontSize: 16,
    lineHeight: 2,
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
      lineHeight: 2.4
    }
  },
  passwordDialog: {
    height: 300,
    margin: 30
  }
}));

export default useStyles;
