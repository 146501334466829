import _ from "lodash";
import {
  GET_LANGUAGE_DATA,
  GET_SERVICES_DATA,
  GET_SUITABLE_FOR_DATA,
  SEND_HOST_NAME,
  TOGGLE_HOST_LIKE
} from "../actions/action-types";

const defaultState = {
  fakeHostData: [
    {
      imagePath:
        "https://images.unsplash.com/photo-1551698618-1dfe5d97d256?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
      alt: "host",
      name: "Aaron Hussian",
      location: "Mount Hotham",
      title: "Host",
      id: 1,
      isLiked: false
    },
    {
      imagePath:
        "https://images.unsplash.com/photo-1551698618-1dfe5d97d256?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
      alt: "host",
      name: "Aaron Hussian",
      location: "Mount Hotham",
      title: "Host",
      id: 2,
      isLiked: false
    },
    {
      imagePath:
        "https://images.unsplash.com/photo-1551698618-1dfe5d97d256?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
      alt: "host",
      name: "Aaron Hussian",
      location: "Mount Hotham",
      title: "Host",
      id: 3,
      isLiked: false
    },
    {
      imagePath:
        "https://images.unsplash.com/photo-1551698618-1dfe5d97d256?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
      alt: "host",
      name: "Aaron Hussian",
      location: "Mount Hotham",
      title: "Host",
      id: 4,
      isLiked: false
    },
    {
      imagePath:
        "https://images.unsplash.com/photo-1551698618-1dfe5d97d256?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
      alt: "host",
      name: "Aaron Hussian",
      location: "Mount Hotham",
      title: "Host",
      id: 5,
      isLiked: false
    },
    {
      imagePath:
        "https://images.unsplash.com/photo-1551698618-1dfe5d97d256?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
      alt: "host",
      name: "Aaron Hussian",
      location: "Mount Hotham",
      title: "Host",
      id: 6,
      isLiked: false
    }
  ],
  language: ["English", "French", "German", "Russian", "Spanish", "Mandarin"],
  numberOfTravellers: ["1", "2", "3", "4", "5", "6"],
  suitableFor: ["Kids", "Adults", "Both kids and adults"],
  generalConcierge: [
    "Accommodation Advisory",
    "Rental Shop Support & getting started on the slopes"
  ],
  generalAssistance: ["Equipment rental"],
  activities: [
    "Ski/snb backcountry touring",
    "Snowshoeing",
    "Snowmobiling",
    "Hiking"
  ],
  tours: ["General tour of the slopes", "General tour of village"],
  childMindingServices: [
    "On slope - skiing",
    "On slope - snowboarding",
    "Off slope",
    "Special needs care experience"
  ],
  expertiseInterests: [
    "Culture",
    "Food",
    "Astronomy",
    "Comedy",
    "History",
    "Art",
    "Music"
  ],
  pricing: ["Sort high to low", "Sort low to high"],
  vistualLessons: ["Skiing", "Snowboarding"],
  skiAbilityLevel: [
    {
      id: 1,
      level: "Level 1 - First Timer",
      key: "Skiing Ability Level 1"
    },
    {
      id: 2,
      level: "Level 2 - Low Beginner",
      key: "Skiing Ability Level 2"
    },
    {
      id: 3,
      level: "Level 3 - High Beginner",
      key: "Skiing Ability Level 3"
    },
    {
      id: 4,
      level: "Level 4 - Low Intermediate",
      key: "Skiing Ability Level 4"
    },
    {
      id: 5,
      level: "Level 5 - High Intermediate",
      key: "Skiing Ability Level 5"
    },
    {
      id: 6,
      level: "Level 6 - Low Advanced",
      key: "Skiing Ability Level 6"
    },
    {
      id: 7,
      level: "Level 7 - High Advanced",
      key: "Skiing Ability Level 7"
    }
  ],
  snowBoardAbilityLevel: [
    {
      id: 1,
      level: "Level 1 - First Timer",
      key: "Snowboard Ability Level 1"
    },
    {
      id: 2,
      level: "Level 2 - Low Beginner",
      key: "Snowboard Ability Level 2"
    },
    {
      id: 3,
      level: "Level 3 - High Beginner",
      key: "Snowboard Ability Level 3"
    },
    {
      id: 4,
      level: "Level 4 - Low Intermediate",
      key: "Snowboard Ability Level 4"
    },
    {
      id: 5,
      level: "Level 5 - High Intermediate",
      key: "Snowboard Ability Level 5"
    },
    {
      id: 6,
      level: "Level 6 - Low Advanced",
      key: "Snowboard Ability Level 6"
    },
    {
      id: 7,
      level: "Level 7 - High Advanced",
      key: "Snowboard Ability Level 7"
    }
  ]
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SEND_HOST_NAME:
      return {
        ...state,
        name: action.payload.name
      };
    case GET_LANGUAGE_DATA:
      return {
        ...state,
        language: action.payload.data
      };
    case GET_SUITABLE_FOR_DATA:
      console.log(JSON.stringify(action.payload.data));
      return {
        ...state,
        suitableFor: action.payload.data
      };
    case GET_SERVICES_DATA:
      return {
        ...state,
        serviceData: action.payload.data
        // serviceData: _.chain(action.payload.data)
        //   // Group the elements of Array based on `color` property
        //   .groupBy("catagory_name")
        //   // `key` is group's name (color), `value` is the array of objects
        //   .map((value, key) => ({ catagory_name: key, services: value }))
        //   .value()
      };
    case TOGGLE_HOST_LIKE:
      const toggledData = state.fakeHostData.map(hostData => {
        console.log(action.payload);

        if (hostData.id === action.payload.hostId) {
          hostData.isLiked = !hostData.isLiked;
          return hostData;
        }
        return hostData;
      });

      console.log(toggledData);

      return {
        ...state,
        fakeHostData: toggledData
      };
    default:
      return state;
  }
};
