import React, { useState, useEffect } from "react";

// Redux
import { connect } from "react-redux";

// Material UI
import useStyles from "../../../Common/SignupForm/ClientInfoStyles";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Avatar,
  styled,
  Badge,
  IconButton,
  FormHelperText
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";

// Style
const useClasses = makeStyles(theme => ({
  uploadButton: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  nextButton: {
    // marginTop: "3em",
    width: "9em",
    height: "2.5em",
    borderRadius: 100,
    textAlign: "center",
    marginRight: "1em"
  },
  avatarSize: {
    width: "10rem",
    height: "10rem",
    [theme.breakpoints.down("sm")]: {
      width: "10rem",
      height: "10rem"
    }
  },
  iconbutton: {
    color: "white",
    backgroundColor: "#BDBDBD",
    "&:hover": {
      backgroundColor: "#BDBDBD",
      cursor: "default"
    }
  },
  iconbuttonEditable: {
    color: "white",
    backgroundColor: "#1277B8",
    "&:hover": {
      backgroundColor: "#1277B8"
    }
  }
}));
const BigAvatar = styled(Avatar)`
  width: 300px;
  height: 300px;

  margin: 0 auto 16px;
  border: 1px solid black;
`;

let ProfilePictureForm = props => {
  const styles = useStyles();
  const classes = useClasses();

  const { profilePicture, setProfilePicture, isDisabled } = props;

  const [error, setError] = useState(false);
  let [previewImage, setPreviewImage] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (profilePicture == null){
      // setError(true);
    } else{
      setProfilePicture(profilePicture);
      setPreviewImage(previewImage);
      setError(false);
    }
  }, [isDisabled, profilePicture]);

  // Edit Icon
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    window.addEventListener('focus', handleFocusBack);
  };
  //Close Pop-up
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFocusBack= () => {
    if (previewImage || profilePicture){
      setError(false);
    }else{
      setError(true);
    }
    window.removeEventListener('focus', handleFocusBack);
  }
  // Browse button
  const handleBrowseImage = event => {
    let uploaded;
    if (event.target.files[0]) {
      uploaded = URL.createObjectURL(event.target.files[0]);
      setPreviewImage(uploaded);
      setProfilePicture(event.target.files[0]);
      if (uploaded == "" || profilePicture == null) {
        setError(true);
      } else {
        setError(false);
      }
    }
    
    handleClose();
    window.removeEventListener('focus', handleFocusBack);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Grid
      item
      container
      xs={12}
      sm={4}
      direction="column"
      justify="center"
      alignItems="center"
    >
      {/* Profile Picture Header */}
      <Grid item style={{ padding: "0.5rem 2rem" }}>
        <Typography className={styles.allergyandotherinformation}>
          PROFILE PICTURE
        </Typography>
      </Grid>

      {/* Profile Picture */}
      <Grid item>
        {isDisabled ? (
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            badgeContent={
              <IconButton size="small" className={classes.iconbutton}>
                <EditIcon />
              </IconButton>
            }
          >
            {profilePicture == undefined ? (
              <Avatar className={classes.avatarSize} />
            ) : (
              <Avatar className={classes.avatarSize}>
                <img src={profilePicture} />
              </Avatar>
            )}
          </Badge>
        ) : (
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            badgeContent={
              <label>
                <input
                  accept="image/*"
                  className={styles.input}
                  id="contained-button-file"
                  multiple
                  hidden
                  type="file"
                  onChange={handleBrowseImage}
                />
                <IconButton
                  aria-label="profile picture"
                  component="span"
                  size="small"
                  className={classes.iconbuttonEditable}
                  onClick={handleClick}
                >
                  <EditIcon />
                </IconButton>
              </label>
            }
          >
            {profilePicture == undefined ? (
              <Avatar className={classes.avatarSize} />
            ) : (
              <Avatar className={classes.avatarSize}>
                <img src={previewImage || profilePicture} />
              </Avatar>
            )}
          </Badge>
        )}
      </Grid>

      <Grid item>
        {error ? (
          <FormHelperText style={{ color: "#f44336" }}>*Required</FormHelperText>
        ) : (
          <FormHelperText>&nbsp;</FormHelperText>
        )}
      </Grid>
    </Grid>
  );
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePictureForm);
