import {
  MEMBER_ID_RETRIEVED,
  BOOKING_EMAIL_SENT,
  ALERT_SUBMIT_FORM
} from "../actions/action-types";

const initialState = {
  bookingEmailSent: false,
  alertSubmitForm: false
};

function tripCoreReducer(state = initialState, action) {
  if (action.type === MEMBER_ID_RETRIEVED) {
    return Object.assign({}, state, {
      masterID: action.payload.id
    });
  } else if (BOOKING_EMAIL_SENT === action.type) {
    return { ...state, bookingEmailSent: action.payload };
  } else if (ALERT_SUBMIT_FORM === action.type) {
    return {
      ...state,
      alertSubmitForm: action.payload
    };
  } else {
    return state;
  }
}

export default tripCoreReducer;
