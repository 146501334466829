import {
  baseURL,
  BOOKING_EMAIL_SENT,
  CLOSE_SECTION,
  OPEN_SECTION,
  SAVE_SECTION,
  ALERT_SUBMIT_FORM
} from "./action-types";

import { submit } from "redux-form";
import agent from "../../agent";

export function openSection(request) {
  return function(dispatch) {
    return dispatch({ type: OPEN_SECTION, payload: request.section });
  };
}

export function closeSection(request) {
  return function(dispatch) {
    return dispatch({ type: CLOSE_SECTION, payload: request.section });
  };
}

export function saveSection(request) {
  return function(dispatch) {
    return dispatch(
      { type: SAVE_SECTION, payload: request.section },
      //TODO reconsider doing this here
      submit(request.section)
    );
  };
}

export function completeForm(request) {
  return function(dispatch) {
    dispatch(alertSubmitForm(true));
    return agent.Trips.putTripByTripId
      .post(baseURL + "/members/2/trips/3/submissionEmail", { ...request })
      .then(response => {
        if (response.status === 204) {
          dispatch(alertSubmitForm(false));
          dispatch({ type: BOOKING_EMAIL_SENT, payload: true });
        }
      })
      .catch(error => {
        console.log("error", error);
        dispatch(alertSubmitForm(false));
      });
  };
}

export function resetSuccess() {
  return { type: BOOKING_EMAIL_SENT, payload: false };
}

export function alertSubmitForm(triggerAlert) {
  return { type: ALERT_SUBMIT_FORM, payload: triggerAlert };
}
