import React from "react";
import FooterBar from "../../../components/Common/Footer/FooterBar";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Rating from "@material-ui/lab/Rating";
import Avatar from "@material-ui/core/Avatar";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

import Mastercard from "../../../materials/payment/Mastercard.jpg";
import VISA from "../../../materials/payment/visa.svg";
import Paypal from "../../../materials/payment/Paypal.png";

const useStyles = makeStyles(theme => ({
  root: {
    background: "#FFFFFF"
  },
  paper: {
    padding: theme.spacing(5),
    border: "1px solid",
    borderColor: "red"
  },
  paper2: {
    padding: theme.spacing(5),
    background: "#F6F6F6"
  },
  btn: {
    color: "red",
    height: "3em",
    width: "7em",
    margin: "2em",
    border: "1px solid #FF6161"
  },
  btnArrow: {
    position: "absolute",
    left: "0",
    transform: "rotate(180deg)"
  },

  bluebtn: {
    borderRadius: "20px",
    width: "311px",
    height: "49px",
    bottom: "-25px",
    background: "#1277B8",
    right: "30%"
  },
  btmbtn: {
    borderRadius: "25px",
    width: "220px",
    height: "35px",
    right: "10%",
    background: "#FF6161"
  },
  emptyCard: {
    position: "relative",
    width: "1600px",
    height: "100px",
    background: "#F6F6F6"
  },

  textField: {
    height: "45px",
    width: "90%",
    fontSize: "5",
    size: "small",
    padding: "0em",
    border: "10px",
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    borderRadius: "0px",
    marginBottom: "10px"
  },
  textField1: {
    height: "45px",
    width: "83.5%",
    fontSize: "5",
    size: "small",
    padding: "0em",
    border: "10px",
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    borderRadius: "0px",
    marginBottom: "10px"
  },
  mainGrid: {
    background: "#F6F6F6",
    position: "center"
  },
  preMainGrid: {
    background: "#F6F6F6",
    position: "center"
  },
  cardpaygrid: {},
  imgsizevisa: {
    //position: "absolute",
    width: "61px",
    height: "32px"
    //left: "20px",
    //top: "35px"
  },

  imgsizemaster: {
    //position: "absolute",
    width: "61px",
    height: "32px"
    //left: "60px",
    //top: "35px"
  },

  imgsizepaypal: {
    //position: "absolute",
    width: "70px",
    height: "52px"
    //left: "60px",
    //top: "35px"
  },
  headingStyle: {
    color: "#1277B8",
    fontFamily: "PT Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "30px",
    lineHeight: "140.62%",
    alignItems: "center",
    textAlign: "center"
  },
  textFieldStyle: {
    fontFamily: "PT Sans",
    fontWeight: "bold",
    color: "#000000",
    fontSize: "18px",
    lineHeight: "140.62%"
  },
  roundimg: {
    width: "150px",
    height: "150px",
    top: "10%"
  },
  text: {
    fontFamily: "PT Sans"
  },
  btnpay: {
    borderRadius: "100px",
    background: "#FF6161",
    height: "35px"
  },

  emptyCard: {
    position: "relative",
    width: "1600px",
    height: "40px",
    background: "#FFFFFF"
  },
  textFieldStyle: {
    fontFamily: "PT Sans",
    fontWeight: "bold",
    color: "#1277B8",
    fontSize: "18px",
    lineHeight: "140.62%"
  }
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function AutoGrid() {
  const classes = useStyles();
  const [value, setValue] = React.useState(5);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.root} align="center">
      <div align="left">
        <Button
          variant="outlined"
          className={classes.btn}
          classes={{ root: classes.btnRoot, label: classes.btnLabel }}
        >
          <ArrowRightAltIcon className={classes.btnArrow} variant="outlined" />
          Back
        </Button>
      </div>
      <div className={classes.emptyCard2} />

      <Grid>
        <Grid
          md={7}
          xs={7}
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item md={6} xs={3}>
            <div className={classes.textFieldStyle}>
              Your Credit/Debit cards:
            </div>
          </Grid>
          <Grid item md={6} xs={9}>
            <div>
              <input
                id="cardname"
                variant="filled"
                className={classes.textField}
                size="small"
              />
            </div>
          </Grid>
        </Grid>

        <Grid
          md={7}
          xs={7}
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item md={6} xs={3}>
            <div className={classes.textFieldStyle}>
              Your Credit/Debit cards:
            </div>
          </Grid>
          <Grid item md={6} xs={9}>
            <div>
              <input
                id="cardname"
                variant="filled"
                className={classes.textField}
                size="small"
              />
            </div>
          </Grid>
        </Grid>

        <Grid
          md={7}
          xs={7}
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item md={6} xs={3}>
            <div className={classes.textFieldStyle}>
              Your Credit/Debit cards:
            </div>
          </Grid>
          <Grid item md={6} xs={9}>
            <div>
              <input
                id="cardname"
                variant="filled"
                className={classes.textField}
                size="small"
              />
            </div>
          </Grid>
        </Grid>
        <div className={classes.emptyCard} />

        <Grid
          md={7}
          xs={7}
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item md={6} xs={3}>
            <Button
              color="primary"
              variant="contained"
              className={classes.btnpay}
            >
              Add New Payment Method
            </Button>
          </Grid>
          <Grid item md={6} xs={9}>
            <div />
          </Grid>
        </Grid>
        <div className={classes.emptyCard} />
        <Grid
          md={7}
          xs={7}
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item md={6} xs={3}>
            <div align="center" className={classes.textFieldStyle}>
              ACCEPTED PAYMENTS
            </div>
          </Grid>
          <Grid item md={6} xs={9}>
            <div />
          </Grid>
        </Grid>
        <div className={classes.emptyCard} />

        <Grid
          md={7}
          xs={7}
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item md={6} xs={3}>
            <div align="center" className={classes.textFieldStyle}>
              First Name
            </div>
          </Grid>
          <Grid item md={6} xs={9}>
            <div align="center" className={classes.textFieldStyle}>
              Surname
            </div>
          </Grid>
        </Grid>

        <Grid
          md={7}
          xs={7}
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item md={6} xs={3}>
            <div align="center" className={classes.textFieldStyle}>
              <input
                fullWidth
                id="cardname"
                variant="filled"
                className={classes.textField}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={6} xs={9}>
            <div align="center" className={classes.textFieldStyle}>
              <input
                fullWidth
                id="cardname"
                variant="filled"
                className={classes.textField}
                size="small"
              />
            </div>
          </Grid>
        </Grid>

        <Grid
          md={6}
          xs={7}
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item md={2} xs={3}>
            <div align="left" className={classes.textFieldStyle}>
              Card Info:
            </div>
          </Grid>
          <Grid item md={10} xs={9}>
            <div />
          </Grid>
        </Grid>

        <Grid
          md={8}
          xs={7}
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item md={12} xs={9}>
            <div>
              <input
                id="cardname"
                variant="filled"
                className={classes.textField1}
                size="small"
              />
            </div>
          </Grid>
        </Grid>
        <div className={classes.emptyCard} />
        <Grid
          md={7}
          xs={7}
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item md={6} xs={3}>
            <div align="center" className={classes.textFieldStyle}>
              <input
                fullWidth
                id="cardname"
                variant="filled"
                className={classes.textField}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={6} xs={9}>
            <div align="center" className={classes.textFieldStyle}>
              <input
                fullWidth
                id="cardname"
                variant="filled"
                className={classes.textField}
                size="small"
              />
            </div>
          </Grid>
        </Grid>
        <div className={classes.emptyCard} />

        <Grid
          md={7}
          xs={7}
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item md={6} xs={3}>
            <div align="center" className={classes.textFieldStyle}>
              BILLING INFO:
            </div>
          </Grid>
          <Grid item md={6} xs={9}>
            <div align="center" className={classes.textFieldStyle}>
              COUNTRY/REGION
            </div>
          </Grid>
        </Grid>

        <Grid
          md={7}
          xs={7}
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item md={6} xs={3}>
            <div align="center" className={classes.textFieldStyle}>
              <input
                fullWidth
                id="cardname"
                variant="filled"
                className={classes.textField}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={6} xs={9}>
            <div align="center" className={classes.textFieldStyle}>
              <input
                fullWidth
                id="cardname"
                variant="filled"
                className={classes.textField}
                size="small"
              />
            </div>
          </Grid>
        </Grid>
        <div className={classes.emptyCard} />
        <Grid
          md={7}
          xs={7}
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item md={3} xs={3}>
            <div align="center" className={classes.textFieldStyle}>
              <Button
                color="primary"
                variant="contained"
                className={classes.btnpay}
              >
                Add
              </Button>
            </div>
          </Grid>
          <Grid item md={3} xs={3}>
            <div align="center" className={classes.textFieldStyle}>
              <Button
                color="primary"
                variant="contained"
                className={classes.btnpay}
              >
                Cancel
              </Button>
            </div>
          </Grid>
          <Grid item md={3} xs={9}>
            <div align="center" className={classes.textFieldStyle} />
          </Grid>
          <Grid item md={3} xs={9}>
            <div align="center" className={classes.textFieldStyle} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default function Payment() {
  return (
    <div>
      <AutoGrid />
    </div>
  );
}
