import React from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import useStyles from "./MyAccountStyles";

const LangCountry = ({
  studentData,
  filterData,
  _editLanguages,
  _editCountry,
  select,
  disabled
}) => {
  const classes = useStyles();

  return (
    <Grid item container md={12} direction="row">
      <Grid item lg={2} md={2} sm={2} xs={2}>
        <div align="right" className={classes.textFieldStyle}>
          Languages:
        </div>
      </Grid>
      <Grid item lg={1} md={1} sm={1} xs={1} />
      <Grid item lg={2} md={2} sm={2} xs={9}>
        <div align="left">
          <TextField
            id="standard-select-currency"
            variant="outlined"
            disabled={disabled}
            select={select}
            onChange={event => _editLanguages(event.target.value)}
            value={studentData.languages}
            SelectProps={{
              native: true
            }}
            className={classes.textFieldLanguage}
          >
            {filterData.languages.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </div>
      </Grid>
      <Grid item lg={1} md={1} sm={1} />
      <Grid item lg={1} md={1} sm={1} xs={2}>
        <div className={classes.textFieldStyle} align="right">
          Country:
        </div>
      </Grid>
      <Grid item lg={1} md={1} sm={1} xs={1} />
      <Grid item lg={3} md={3} sm={3} xs={9}>
        <div align="left">
          <TextField
            id="standard-select-currency"
            variant="outlined"
            disabled={disabled}
            select={select}
            value={studentData.country}
            onChange={event => _editCountry(event.target.value)}
            SelectProps={{
              native: true
            }}
            className={classes.textFieldLanguage}
          >
            {filterData.country.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </div>
      </Grid>
      <Grid item lg={1} md={1} sm={1} />
    </Grid>
  );
};

export default LangCountry;
