import React from "react";

// CSS
import styles from "./HostCard.module.css";
// Component
import StarRating from "../../Common/StarRating/StarRating.js";

// Redux
import Store from "../../../redux/store";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";

import hostImg from "../../../materials/HomePage/host.jpg";
import zIndex from "@material-ui/core/styles/zIndex";

const useStyles = makeStyles({
  root: {
    position: "relative",
    maxWidth: 345
  },
  typography: {
    useNextVariants: true
  },
  buttonRoot: {
    marginBottom: "2em",
    textTransform: "none",
    fontWeight: "normal",
    fontFamily: "PT Sans Narrow",
    fontSize: 18,
    borderRadius: 20,
    backgroundColor: "#1277b8",
    "&:focus": {
      outline: "transparent"
    }
  },
  name: {
    color: "#1277b8",
    fontFamily: "PT Sans Narrow",
    fontWeight: "bold",
    fontSize: 17,
    textAlign: "left",
    paddingLeft: "0.3em"
  },
  rating: {
    "& .StarRating_ratingStarContainer__3OtaX": {
      // display: 'flex',
      fontSize: "x-small",
      fontFamily: "PT Sans Narrow"
    }
  },
  typeAndSpecialization: {
    color: "#676868",
    fontFamily: "PT Sans Narrow",
    fontWeight: "bold",
    fontSize: 12,
    textAlign: "left"
  },
  perHour: {
    fontFamily: "PT Sans Narrow",
    fontSize: 20,
    fontWeight: "500",
    color: "#1277b8"
  },
  priceFrom: {
    fontFamily: "PT Sans Narrow",
    fontSize: 12,
    fontWeight: "500",
    color: "#1277b8"
  },
  price: {
    textAlign: "right",
    marginRight: "1em"
  },
  description: {
    fontFamily: "PT Sans Narrow",
    textAlign: "left",
    paddingLeft: "0.5em",
    paddingRight: "0.5em",
    fontSize: 15,
    color: "#505050"
  },
  media: {
    position: "relative",
    height: 260
  },
  favourite: {
    position: "absolute",
    zIndex: "10",
    outline: "none",
    "&.MuiIconButton-root": {
      outline: "none"
    },

    "& > *": {
      margin: "1"
    }
  }
});

const HeartButton = props => {
  let btn;
  const classes = useStyles();
  if (props.filled === true)
    btn = (
      <IconButton
        onClick={props.onClick}
        color="secondary"
        aria-label="unfavourite"
        className={classes.favourite}
      >
        <FavoriteIcon />
      </IconButton>
    );
  else
    btn = (
      <IconButton
        onClick={props.onClick}
        aria-label="unfavourite"
        className={classes.favourite}
      >
        <FavoriteBorderIcon />
      </IconButton>
    );

  return btn;
};

const ButtonImageOverlay = props => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <HeartButton
        filled={props.isFilled}
        onClick={() => {
          props.onClick(props.id);
        }}
      />
      <img src={props.url} className={classes.media} />{" "}
    </React.Fragment>
  );
};

function InstructorCard(props) {
  const classes = useStyles();

  const {
    imagePath,
    instructorName,
    location,
    title,
    id,
    instructorType,
    sepcialisation,
    pricing,
    desc,
    isLiked
  } = props;
  // To be moved to props once set up.

  const rating = 5;

  return (
    <Card className={classes.root}>
      <CardMedia
        name={instructorName}
        component={ButtonImageOverlay}
        url={imagePath}
        onClick={props.onClick}
        isFilled={isLiked}
        id={id}
      />

      <CardContent>
        <div className={classes.containerInstuctorCard}>
          <Grid container className={classes.firstLine}>
            <Grid container>
              <Typography
                gutterBottom
                variant="h4"
                className={classes.name}
                component="h1"
              >
                {instructorName}
              </Typography>
            </Grid>
            <Grid container className={classes.rating}>
              <StarRating currentRating={rating} />
            </Grid>
          </Grid>

          <Grid container className={classes.secondLine}>
            <Grid item md={6} sm={6} xs={6}>
              <Typography className={classes.typeAndSpecialization}>
                {title}
              </Typography>
              <Typography className={classes.typeAndSpecialization}>
                {"Specialisation: " + sepcialisation}
              </Typography>
            </Grid>
            <Grid item md={6} sm={6} xs={6} className={classes.rating}>
              <div>
                <p className={classes.price}>
                  <span className={classes.priceFrom}>Price From</span>
                  <br />
                  <span className={classes.perHour}>{`$${pricing}/hr`}</span>
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
        <Typography
          className={classes.description}
          variant="body2"
          color="textSecondary"
          component="p"
        >
          {desc}
        </Typography>
      </CardContent>
      <CardActions className={styles.cardAction}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          className="button"
          classes={{ root: classes.buttonRoot }}
          href={"/instructor-profile/" + id}
        >
          View Full Profile
        </Button>
      </CardActions>
    </Card>
  );
}

export default InstructorCard;
