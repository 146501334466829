import React from "react";
import { connect } from "react-redux";
import {
  TextCenterDiv,
  SuccessText,
  ResortText
} from "../../styling/styled-components/bookingSuccess";
import {
  VerticalSpaceMD,
  VerticalSpaceSM
} from "../../styling/styled-components/index";
import { OrangeButton } from "../../styling/styled-components";
import { Link } from "react-router-dom";
import { resetSuccess } from "../../redux/actions/formActions";

function UnconnectedBookSuccessPage(props) {
  const { resortName } = props;
  props.resetSuccess();

  return (
    <React.Fragment>
      <VerticalSpaceMD />
      {/*<TextCenterDiv>*/}
      {/*  <img*/}
      {/*    id="comp-jehyob76imgimage"*/}
      {/*    alt="success"*/}
      {/*    src="https://static.wixstatic.com/media/25b4a3_1322af67d33249569e09547f161280ff~mv2.png/v1/fill/w_288,h_182,al_c,q_80,usm_0.66_1.00_0.01/25b4a3_1322af67d33249569e09547f161280ff~mv2.webp"*/}
      {/*    style={{ width: "144px", height: "91px" }}*/}
      {/*  />*/}
      {/*</TextCenterDiv>*/}

      <TextCenterDiv>
        <SuccessText>Success</SuccessText>
      </TextCenterDiv>

      <VerticalSpaceSM />

      {!localStorage.getItem("userID") && (
        <TextCenterDiv>
          <Link to="/register">
            <OrangeButton>Sign up</OrangeButton>
          </Link>
          <div>...to save your details for next time</div>
        </TextCenterDiv>
      )}

      {/* <VerticalSpaceMD /> */}

      <TextCenterDiv>
        <div>
          We have sent your enquiry to <ResortText>{resortName}</ResortText>.
        </div>
        <VerticalSpaceSM />

        <div>
          They should be in touch shortly to confirm availability and take
          payment.
        </div>
        <VerticalSpaceSM />
        <div>Display of prices and credit card payments coming soon.</div>
      </TextCenterDiv>

      <VerticalSpaceMD />

      <TextCenterDiv>
        <div>
          <a href="/">Back to home page</a>
        </div>
      </TextCenterDiv>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    resortName: state.tripWhereReducer.resortName
  };
}

const BookSuccessPage = connect(
  mapStateToProps,
  { resetSuccess: resetSuccess }
)(UnconnectedBookSuccessPage);

export default BookSuccessPage;
