import React, { Component } from "react";
import agent from "../../../../agent";
import {
  SectionTitleSmaller,
  VerticalSpaceSM,
  NextButton
} from "../../../../styling/styled-components/index";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe
} from "react-stripe-elements";

class PaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      complete: false,
      name: ""
    };
  }

  /**
   * Handles change in the input fields that are not provided by Stripe
   * Note: Always use an identical component name prop and state property
   * for this method to work
   */
  handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  /**
   * Creates a token and requests the backend API to create a charge.
   * If the response is 'OK'(200), sets the sets the complete flag in the state
   * to indicate payment completion.
   *
   * Note: Use Stripe API's testing cards to test this
   * https://stripe.com/docs/testing#cards
   */
  submit = async () => {
    let { token, error } = await this.props.stripe.createToken({
      name: this.state.name
    });

    const requestData = {
      token: token,
      amount: 100,
      currency: "usd",
      description: "Daniel"
    };

    // Call backend with created token's id passed in the request body
    if (token) {
      agent.Instructor.charge(requestData)
        .then(response => {
          if (response.status === 200) this.setState({ complete: true });
        })
        .catch(error => {
          this.setState({ error: error.response.data });
        });
    } else {
      // If token isn't created
      const error = { message: "Invalid card details" };
      this.setState({ error: error });
    }
  };

  render() {
    if (this.state.complete) return <h5>Purchase Complete</h5>;

    return (
      <div className="row">
        <div className="col-12 col-sm-6">
          <SectionTitleSmaller>Name</SectionTitleSmaller>
          <br />
          <input
            className="StripeInput"
            name="name"
            type="text"
            placeholder="John Doe"
            value={this.state.name}
            onChange={this.handleInputChange}
            required
          />
        </div>
        <div className="col-12 col-sm-6">
          <SectionTitleSmaller>Card number</SectionTitleSmaller>
          <CardNumberElement />
        </div>
        <VerticalSpaceSM />
        <div className="col-12 col-sm-6">
          <SectionTitleSmaller>Expiration date</SectionTitleSmaller>
          <CardExpiryElement />
        </div>
        <VerticalSpaceSM />
        <div className="col-12 col-sm-6">
          <SectionTitleSmaller>CVC</SectionTitleSmaller>
          <CardCVCElement />
        </div>
        <VerticalSpaceSM />
        <div className="col-12 col-sm-12 Alignment">
          <NextButton onClick={this.submit}>Purchase Now</NextButton>
        </div>

        {this.state.error ? (
          <div>
            <h5>{this.state.error.message}</h5>
          </div>
        ) : null}
      </div>
    );
  }
}

export default injectStripe(PaymentForm);
