import React from "react";

export default function CaptionToolTip(props) {
  return (
    <div>
      <button
        onClick={() => {
          props.onClick(props.message);
        }}
        className="btn btn-link"
      >
        {props.caption || "Why do we need this?"}
      </button>
    </div>
  );
}
