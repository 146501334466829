import React from "react";
import useStyles from "./MyAccountStyles";
import { Button, Grid } from "@material-ui/core";

import SportSpecialFilter from "./SportSpecialFilter";

const SportSpecial = ({ journey }) => {
  const classes = useStyles();

  return journey === "vl-ins" ? (
    <Grid item container md={12} direction="row">
      <Grid item lg={2} md={2} sm={2} xs={2}>
        <div align="right" className={classes.textFieldStyle}>
          Sport and Specialisation:
        </div>
      </Grid>
      <Grid item lg={1} md={1} sm={1} xs={1} />
      <Grid item lg={5} md={8} sm={8} xs={9}>
        <div align="left">
          <SportSpecialFilter />
        </div>
      </Grid>
    </Grid>
  ) : null;
};

export default SportSpecial;
