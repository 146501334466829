import React from "react";
import useStyles from "./MyAccountStyles";
import Grid from "@material-ui/core/Grid";

const Phone = ({ studentData, _editPhonenumber }) => {
  const classes = useStyles();
  return (
    <Grid item container md={12} direction="row">
      <Grid item lg={2} md={2} sm={2} xs={2} />
      <Grid item lg={1} md={1} sm={1} xs={1} />
      <Grid item lg={1} md={2} sm={2} xs={2}>
        <div align="left" className={classes.textFieldStyleBlack}>
          Phone No:
        </div>
      </Grid>
      <Grid item lg={5} md={5} sm={5} xs={6}>
        <div align="left">
          <input
            fullWidth
            id="cardname"
            variant="filled"
            className={classes.textField}
            size="small"
            onChange={event => _editPhonenumber(event.target.value)}
            value={studentData.phonenumber}
          />
        </div>
      </Grid>
      <Grid item lg={3} md={2} sm={2} xs={1} />
    </Grid>
  );
};

export default Phone;
