import styled from "styled-components";
import {
  GrayColor0,
  GrayColor1,
  GrayColor3,
  //GrayColor4,
  BlueColor1
  //BlueColor2
} from "./color-themes_old";

import {
  media_sm_max
  //media_md_max
} from "./dimension-themes";

export const SectionBodyWrapper = styled.div`
  margin: auto;
  width: 95%;

  @media (max-width: ${media_sm_max}) {
    width: 98%;
  }
`;

export const TitleButton = styled.button`
  position: relative;
  background-color: ${GrayColor0};
  width: 100%;
  border: 1px solid ${GrayColor1};
  color: ${GrayColor3};
  font-size: 1.25rem;
  padding: 6px 0;
  font-weight: 700;
  font-family: "Open Sans Condensed", sans-serif;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${BlueColor1};
  }

  &:focus {
    outline: none;
  }
`;
//
// export const Arrow = styled.span`
//   position: absolute;
//   right: 30%;
//   top: 0px;
//
//   @media (max-width: ${media_sm_max}) {
//     right: 10%;
//   }
// `;
