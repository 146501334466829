import React, { forwardRef } from "react";
import { connect } from "react-redux";
import { addNewPaymentMethod } from "../../../redux/actions/paymentActions.js";

import FooterBar from "../../../components/Common/Footer/FooterBar";

//Materials
import Mastercard from "../../../materials/payment/Mastercard.jpg";
import VISA from "../../../materials/payment/visa.svg";
import Paypal from "../../../materials/payment/Paypal.png";
import PaymentPopup from "../../../materials/payment/PaymentPopup";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import {
  ArrowRightAlt as ArrowRightAltIcon,
  Close as CloseIcon,
  AccountBalanceOutlined as AccountBalanceIcon,
  MoreHorizOutlined as MoreHorizOutlinedIcon
} from "@material-ui/icons/ArrowRightAlt";

import {
  AppBar,
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Dialog,
  Typography,
  IconButton,
  Link,
  Popover,
  Chip,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions
} from "@material-ui/core/";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const useStyles = makeStyles(theme => ({
  textField: {
    height: "10",
    fontSize: "5",
    size: "small",
    padding: "0em",
    paddingLeft: "4.5rem",
    border: "10px",
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    borderRadius: "0px",
    marginBottom: "10px"
  },
  textFieldStyle: {
    fontFamily: "PT Sans Narrow",
    fontWeight: "bold",
    color: "#1277B8",
    fontSize: "18px",
    lineHeight: "140.62%",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14
    }
  },
  textFieldStyleTitle: {
    fontFamily: "PT Sans Narrow",
    fontWeight: "bold",
    color: "#1277B8",
    fontSize: 24,
    lineHeight: "140.62%",
    marginTop: 20
  },
  tab: {
    fontFamily: "PT Sans Narrow",
    fontWeight: "bolder",
    fontSize: 18,
    textTransform: "none",
    color: "#1277B8",
    "&:focus": {
      outline: "none"
    },
    "& span": {
      alignItems: "normal"
    },
    textAlign: "left"
  },
  root: {
    background: "#FFFFFF"
  },
  btn: {
    color: "red",
    height: "3em",
    width: "7em",
    margin: "2em",
    border: "1px solid #FF6161"
  },
  btnArrow: {
    position: "absolute",
    left: "0",
    transform: "rotate(180deg)"
  },
  textField: {
    height: "45px",
    fontSize: "5",
    size: "small",
    width: "100%",
    padding: "0em",
    paddingLeft: "4.5rem",
    border: "10px",
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    borderRadius: "0px",
    marginBottom: "10px"
  },
  textFieldPopup: {
    height: "45px",
    width: "100%",
    fontSize: "5",
    size: "small",
    padding: "0em",
    paddingLeft: "0.5rem",
    border: "10px",
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    borderRadius: "0px",
    "&::placeholder": {
      color: "#1277B8"
    }
  },
  textField1: {
    height: "45px",
    width: "100%",
    fontSize: "5",
    size: "small",
    padding: "0em",
    border: "10px",
    backgroundColor: "rgba(18, 119, 184, 0.15)",
    borderRadius: "0px",
    marginBottom: "10px"
  },
  imgsizevisa: {
    //position: "absolute",
    width: "61px",
    height: "32px"
    //left: "20px",
    //top: "35px"
  },
  imgsizemaster: {
    //position: "absolute",
    width: "61px",
    height: "32px"
    //left: "60px",
    //top: "35px"
  },
  imgsizepaypal: {
    //position: "absolute",
    width: "70px",
    height: "52px"
    //left: "60px",
    //top: "35px"
  },
  headingStyle: {
    color: "#1277B8",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "30px",
    lineHeight: "140.62%",
    alignItems: "center",
    textAlign: "center"
  },
  btnpay: {
    borderRadius: 100,
    background: "#FF6161",
    height: 35,
    width: 70,
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontSize: 17,
    fontWeight: "normal",
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
    border: "none",
    marginTop: 20
  },
  btnpayLarge: {
    borderRadius: 100,
    background: "#FF6161",
    height: 35,
    width: 200,
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontSize: 18,
    fontWeight: "normal",
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
    border: "none",
    marginTop: 20,
    marginBottom: 80
  },
  marginstyle: {
    marginTop: 10
  },
  marginstyle2: {
    marginLeft: 2
  },
  btnmethod: {
    borderColor: "black",
    backgroundColor: "transparent",
    borderRadius: "1px"
  },
  btnrd: {
    width: "100%",
    border: "none",
    backgroundColor: "transparent",
    fontFamily: "PT Sans Narrow"
  },
  chip: {
    fontFamily: "PT Sans Narrow",
    "& div.MuiChip-root": {
      borderRadius: "0px"
    }
  },
  chip1: {
    fontFamily: "PT Sans Narrow",
    height: "15px",
    fontSize: "10px"
  },
  emptyCard: {
    height: 10
  },
  paymentInputLabel: {
    color: "black",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "140.62%"
  },
  paymentBtnContainer: {
    textAlign: "left",
    width: "55%"
  },
  payoutContainer: {
    textAlign: "left"
  },
  payoutSection: {
    margin: "2em 0",
    textAlign: "left",
    width: "75%"
  },
  paymentMethodImgContainer: {
    marginLeft: "1em"
  },
  paymentDialog: {
    margin: "0 auto",
    width: "90%"
  },
  cardInfoContainer: {
    width: "75%",
    margin: "0 auto"
  },
  cardInfo: {
    height: "44px",
    backgroundColor: "rgba(18, 119, 184, 0.15)"
  },
  cardInfoGrid: {
    margin: "1rem 0"
  },
  cardInfoText: {
    lineHeight: 2.5
  },
  bankaccountstyle: {
    fontFamily: "PT Sans Narrow",
    fontSize: "0.8rem"
  },
  contentstyle: {
    fontFamily: "PT Sans Narrow",
    textAlign: "left"
  },
  fontfamily: {
    fontFamily: "PT Sans Narrow"
  }
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function AutoGrid(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenAccount = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAccount = () => {
    setAnchorEl(null);
  };

  const openaccount = Boolean(anchorEl);
  const idaccount = openaccount ? "simple-popover" : undefined;

  const { cardInfo } = props;

  const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles(theme => ({
    root: {
      padding: theme.spacing(2)
    }
  }))(MuiDialogContent);

  const DialogActions = withStyles(theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(1)
    }
  }))(MuiDialogActions);

  return (
    <div className={classes.root} align="center">
      <Grid
        container
        lg={8}
        md={9}
        sm={10}
        xs={12}
        direction="column"
        justify="center"
      >
        <div align="left">
          <Button
            variant="outlined"
            className={classes.btn}
            classes={{ root: classes.btnRoot, label: classes.btnLabel }}
          >
            <ArrowRightAltIcon
              className={classes.btnArrow}
              variant="outlined"
            />
            Back
          </Button>
        </div>

        <div>
          <p className={classes.headingStyle}>Payment and Payouts</p>
        </div>

        <div className={classes.cardInfoContainer}>
          <AppBar
            elevation={0} //clear the shadow
            className={classes.AppBar}
            position="static"
            color={"transparent"}
          >
            <Tabs
              className={classes.Tabs}
              // change the color of indicator to grey
              TabIndicatorProps={{
                style: { backgroundColor: "#1277B8" }
              }}
              variant="fullWidth"
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="Payments" className={classes.tab} />
              <Tab label="Payouts" className={classes.tab} {...a11yProps(1)} />
              {/*<Tab label="Item Three" {...a11yProps(2)} />*/}
            </Tabs>
          </AppBar>

          <TabPanel value={value} index={0}>
            <div className={classes.root} align="center">
              <div className={classes.emptyCard} />
              <Grid>
                {cardInfo.map(element => {
                  return (
                    <Grid
                      md={9}
                      sm={10}
                      xs={12}
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      className={classes.cardInfoGrid}
                    >
                      <Grid item md={5} sm={6} xs={12}>
                        <div className={classes.paymentInputLabel}>
                          Your Credit/Debit cards
                        </div>
                      </Grid>
                      <Grid item md={7} sm={6} xs={12}>
                        <div className={classes.cardInfo}>
                          <p className={classes.cardInfoText}>
                            {"xxxxxxxxxx" +
                              element.substring(element.length - 4)}
                          </p>
                        </div>
                      </Grid>
                    </Grid>
                  );
                })}
                <div className={classes.emptyCard} />
              </Grid>
              <Grid container item xs={12}>
                <Grid item md={3} sm={1} />
                <Grid item md={6} sm={10} xs={12} align="center">
                  <button
                    onClick={handleClickOpen}
                    color="primary"
                    variant="contained"
                    className={classes.btnpayLarge}
                  >
                    Add New Payment Method
                  </button>
                </Grid>
                <Grid item md={3} sm={1} />
              </Grid>

              <Dialog
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
                open={open}
                fullWidth="true"
              >
                <DialogContent dividers>
                  <PaymentPopup handleClose={handleClose} />
                </DialogContent>
              </Dialog>
            </div>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <div className={classes.root} align="center">
              <Grid
                md={9}
                sm={10}
                xs={12}
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                {/* title 1 */}
                <Grid item xs={12}>
                  <div align="left" className={classes.textFieldStyleTitle}>
                    Payout Method
                  </div>
                </Grid>
                {/* content 1 */}
                <Grid item xs={12}>
                  <div className={classes.contentstyle}>
                    When you receive a payment for a reservation, we call that
                    payment to you a 'payout'. Our secure payment system
                    supports several payment methods , which can be set up
                    below.
                  </div>
                </Grid>
                {/* banck account */}
                <Grid
                  xs={12}
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  className={classes.marginstyle}
                >
                  <Grid item md={1} sm={1} xs={1}>
                    <AccountBalanceIcon fontSize="large"> </AccountBalanceIcon>
                  </Grid>
                  <Grid item md={1} sm={1} xs={1} />
                  <Grid item container md={9} sm={9} xs={9}>
                    <Grid item container xs={9} className={classes.chip}>
                      <div align="left" className={classes.fontfamily}>
                        Bank Account
                      </div>
                      <div className={classes.marginstyle2}>
                        <Chip label="DEFAULT" className={classes.chip1} />
                      </div>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <div align="left" className={classes.bankaccountstyle}>
                        Name, Accout Number
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item md={1} sm={1} xs={1}>
                    <div>
                      <button
                        aria-describedby={idaccount}
                        variant="contained"
                        color="primary"
                        onClick={handleOpenAccount}
                        className={classes.btnmethod}
                      >
                        <MoreHorizOutlinedIcon />
                      </button>
                      <Popover
                        id={idaccount}
                        open={openaccount}
                        anchorEl={anchorEl}
                        onClose={handleCloseAccount}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center"
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center"
                        }}
                        style={{ left: "1%" }}
                      >
                        <div>
                          <button className={classes.btnrd}>Remove</button>
                        </div>
                        <div>
                          <button className={classes.btnrd}>Default</button>
                        </div>
                      </Popover>
                    </div>
                  </Grid>
                </Grid>
                {/* title 2 */}
                <Grid item xs={12}>
                  <div align="left" className={classes.textFieldStyleTitle}>
                    To get paid you need to set up a Payout method
                  </div>
                </Grid>
                {/* content 2 */}
                <Grid item xs={12}>
                  <div className={classes.contentstyle}>
                    Resorter releases payouts about 3-5 days, the time it takes
                    for the funds to appear in your account depends on your
                    payout method.
                  </div>
                </Grid>
                {/* button */}
                <Grid container item xs={12}>
                  <Grid item md={3} sm={1} />
                  <Grid item md={6} sm={10} xs={12} align="center">
                    <Link href="/payoutTab">
                      <button
                        color="primary"
                        variant="contained"
                        className={classes.btnpayLarge}
                      >
                        Add New Payout Method
                      </button>
                    </Link>
                  </Grid>
                  <Grid item md={3} sm={1} />
                </Grid>
              </Grid>
            </div>
          </TabPanel>
        </div>
      </Grid>
    </div>
  );
}

function Payment(props) {
  const { cardInfo } = props;
  return (
    <div>
      <AutoGrid cardInfo={cardInfo} />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    cardInfo: state.paymentReducer.cardInfo
  };
}

export default connect(
  mapStateToProps,
  { addNewPaymentMethod },
  null,
  { forwardRef: true }
)(Payment);
