import React, { Component } from "react";
import "../../styling/css/HowItWorkPage/HowItWorksPage.css";
import device from "../../materials/HowItWork/device.png";
import service from "../../materials/HowItWork/service.png";
import ski from "../../materials/HowItWork/ski.png";
import world from "../../materials/HowItWork/world.png";
import downward from "../../materials/HowItWork/downward.png";
import $ from "jquery";
import BackTopBtn from "../../components/lessonsEnquiry/BackTopBtn";
import { LightFilterColor } from "../../styling/styled-components/color-themes_old";
import {
  StyledMedText,
  StyledSmallText
} from "../../components/Common/Home/Slider";
import { VerticalSpaceSM } from "../../styling/styled-components";
import classNames from "classnames";

const styles = {
  logos: {
    width: "100%",
    maxWidth: 128
  },
  text: {
    color: "white",
    textAlign: "left"
  },
  flowchartContent: {}
};

function ArrowIcon() {
  let arrow =
    window.innerWidth < 576 ? "fa-arrow-circle-down" : "fa-arrow-circle-right";
  return <div className={classNames("col-12 col-md-1 fa", arrow)} />;
}

class SkiTripsMadeEasy extends Component {
  handleClick = divID => {
    $("html,body").animate({ scrollTop: $("#" + divID).offset().top }, "slow");
  };

  render() {
    return (
      <div
        id="skiTripsMadeEasy"
        className="container-fluid"
        style={{ color: "white" }}
      >
        <div className="row justify-content-center">
          <div className="col-10 col-sm-8 col-md-6">
            <StyledMedText>
              One Portal | One Profile | Resorts Worldwide
            </StyledMedText>
            <VerticalSpaceSM />
            <StyledSmallText style={styles.text}>
              We are passionate about connecting communities, and group lessons
              at ski resorts are an obvious way to connect with other
              snowsporters at the same ability level. We offer a lessons booking
              enquiry service (including privates and request privates) which
              sends your enquiry to the resort for them to confirm availability
              and take your payment. In time, we are aiming to display prices
              and include a payment facility to close the transaction here.
              <VerticalSpaceSM />
              We also want to offer more components to book in the future so you
              need only create one profile to book your entire trip at any
              resort in the world.
            </StyledSmallText>
          </div>
        </div>
        <br />

        <div
          className="row col-6 col-md-10 col-lg-7 justify-content-center align-items-center"
          style={{
            backgroundColor: LightFilterColor,
            borderRadius: 40,
            margin: "auto",
            textAlign: "center"
          }}
        >
          <div className="col-12 col-md-2">
            <img src={device} style={styles.logos} alt="" />
            <p>Plan your lessons</p>
          </div>
          <ArrowIcon />

          <div className="col-12 col-md-2">
            <img src={world} style={styles.logos} alt="" />
            <p>Resort confirms availability</p>
          </div>
          <ArrowIcon />

          <div className="col-12 col-md-2">
            <img src={service} style={styles.logos} alt="" />
            <p>...processes it</p>
          </div>
          <ArrowIcon />

          <div className="col-12 col-md-2">
            <img src={ski} style={styles.logos} alt="" />
            <p>...and you rest easy</p>
          </div>
        </div>

        {/*<p className="plaintext">*/}
        {/*  Choose in advance your Resort, dates, activities and experiences - eg.*/}
        {/*  skiing, snowboarding, snowbiking, snowshoeing, snowmobiling; or even*/}
        {/*  heliskiing or backcountry tours - or plan a private lesson with your*/}
        {/*  favourite instructor or join a group lesson - then choose the # of*/}
        {/*  days you need a lift pass and equipment rental. The Resort will*/}
        {/*  contact you direct to confirm availability and take payment.*/}
        {/*  RESORTer's planning engine is uniquely sequenced by snowsports experts*/}
        {/*  who have your convenience and safety in mind.*/}
        {/*</p>*/}

        {/*<h2>Plan now. Pay later...</h2>*/}

        {/*<p className="plaintext">*/}
        {/*  The best part is you can sleep on your plans...and make changes when*/}
        {/*  the Resort contacts you to confirm availability, offer the best deals,*/}
        {/*  and take payment.{" "}*/}
        {/*</p>*/}

        <div className="row justify-content-center">
          <img
            className="goDownDiv"
            src={downward}
            onClick={() => this.handleClick("behindTheDesks")}
            alt=""
          />
        </div>
      </div>
    );
  }
}

class BehindTheDesks extends Component {
  // handleClick = divID => {
  //   $("html,body").animate({ scrollTop: $("#" + divID).offset().top }, "slow");
  // };

  render() {
    return (
      <div id="behindTheDesks" className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-10 col-sm-8 col-md-6">
            <StyledMedText>BEHIND THE DESKS...</StyledMedText>
            <VerticalSpaceSM />
            <StyledSmallText>
              We are a team of techies and seasoned resorters who simply want a
              no fuss way to book our trips all over the world and only ever
              have to navigate one booking engine.
            </StyledSmallText>
            <VerticalSpaceSM />
            <VerticalSpaceSM />

            <StyledMedText>About the Founder</StyledMedText>
            <VerticalSpaceSM />

            <StyledSmallText>
              At the age of three, I squished up with my four older siblings in
              the back seat of the family car - all squealing with excitement -
              and endured a seven hour journey to Thredbo, Australia. It was the
              ultimate family experience, the type that's passed down through
              generations. My father's passion for skiing meant we returned for
              a week every year until he discovered somewhere a little closer,
              Falls Creek - my winter home ever since. More recently, I have
              spent 18 magical winters ski instructing across the world,
              listening to guests about what makes for a truly outstanding
              experience at the snow. What rated high amongst them? A need for
              an easy, efficient way to plan and book lessons, rental, and lift
              pass. RESORTer is for them, and for anyone who has been too
              intimidated to try a wintersport for the first time. I want my
              family experience to be accessible to more families by making
              things easier for them and the whole experience ultimately more
              affordable.
            </StyledSmallText>
          </div>
        </div>

        {/*<img*/}
        {/*  className="goDownDiv"*/}
        {/*  src={downward}*/}
        {/*  onClick={() => this.handleClick("resortIMG")}*/}
        {/*  alt=""*/}
        {/*/>*/}
      </div>
    );
  }
}

class ReosrtIMG extends Component {
  // handleClick = divID => {
  //   $("html,body").animate({ scrollTop: $("#" + divID).offset().top }, "slow");
  // };

  render() {
    return (
      <div id="resortIMG" className="text-center container-fluid">
        {/*<img*/}
        {/*  className="goDownDiv"*/}
        {/*  src={downward}*/}
        {/*  style={{ position: "relative", top: "80%" }}*/}
        {/*  onClick={() => this.handleClick("workWithUs")}*/}
        {/*  alt=""*/}
        {/*/>*/}
      </div>
    );
  }
}

// class WorkWithUs extends Component {
//   handleClick = divID => {
//     $("html,body").animate({ scrollTop: $("#" + divID).offset().top }, "slow");
//   };
//
//   render() {
//     return (
//       <div id="workWithUs" className="text-center container-fluid">
//         <h1>WORK WITH US</h1>
//         <p className="plaintext">
//           At RESORTer, our people are at the heart of what we do. If you see
//           yourself as someone who thrives working collaboratively and doesn’t
//           shy away from hard work and the seemingly impossible.{" "}
//         </p>
//
//         <p className="plaintext">
//           <Link className="privacy" to={`/contact`}>
//             drop us a line
//           </Link>
//         </p>
//
//         <img
//           className="goDownDiv"
//           src={downward}
//           onClick={() => this.handleClick("highFiveIMG")}
//           alt=""
//         />
//       </div>
//     );
//   }
// }

// class HighFive extends Component {
//   render() {
//     return <div id="highFiveIMG" className="text-center container-fluid" />;
//   }
// }

class HowItWorks extends Component {
  state = {
    currentScrollHeight: 0
  };

  componentDidMount() {
    if (this.props.location.pathname === "/about-us") {
      $("html,body").animate(
        { scrollTop: $("#behindTheDesks").offset().top },
        "slow"
      );
    }
  }

  render() {
    const opacity = Math.min(100 / this.state.currentScrollHeight, 1);

    return (
      <div className="HowItWorksCSS">
        <SkiTripsMadeEasy />
        <BehindTheDesks />
        <ReosrtIMG />
        {/*<WorkWithUs />*/}
        {/*<HighFive />*/}
        {/*<FeedBackBtn />*/}
        {/*<SideIcon/>*/}
        {opacity !== 1 ? (
          <BackTopBtn scrollStepInPx="50" delayInMs="16.66" />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default HowItWorks;
