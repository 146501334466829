import React, { Component } from "react";
import { Collapse } from "react-bootstrap";
import { connect } from "react-redux";
import {
  openSection,
  closeSection,
  saveSection,
  completeForm
} from "../../../../redux/actions/formActions";

import { VerticalSpaceMD } from "../../../../styling/styled-components/index";
import { TitleButton } from "../../../../styling/styled-components/sectionWrapper";

import { Element, scroller } from "react-scroll";
import { PROFILE, DOING, LESSONS } from "./section-types";
import formValueSelector from "redux-form/es/formValueSelector";

import getFormSyncErrors from "redux-form/es/getFormSyncErrors";
import { scrollerOptions } from "../../../../constants/common";
import { BlueColor3 } from "../../../../styling/styled-components/color-themes_old";

// contains the contents of each section, unaware of its contents

// TODO: implement better method of doing this
export function wait(boolean) {
  return new Promise(resolve => {
    while (!boolean) {
      setTimeout(function() {}, 1000);
    }
    resolve();
  });
}

class FormContents extends Component {
  childSection = React.createRef();

  handleToggle = () => {
    const props = this.props;
    const thisSection = { section: props.thisSection };
    if (props.open) {
      props.closeSection(thisSection);
    } else {
      //open this section
      props.openSection(thisSection);
      scroller.scrollTo(props.thisSection, scrollerOptions);
    }
  };

  handleSetActive = () => {
    const nextSection = { section: this.props.nextSection };
    this.props.openSection(nextSection);
  };

  handleNext = async () => {
    const props = this.props;
    const thisSection = { section: props.thisSection };
    const nextSection = { section: props.nextSection };
    props.saveSection(thisSection);
    props.closeSection(thisSection);

    props.openSection(nextSection);
    // TODO: implement better method
    setTimeout(() => {
      scroller.scrollTo(props.thisSection, scrollerOptions);
    }, 500);
  };

  handleSave = () => {
    const props = this.props;
    const thisSection = { section: props.thisSection };
    const nextSection = { section: props.nextSection };

    // TODO: update for all sections

    if (props.thisSection === PROFILE) {
      if (this.childSection.current.submit() == null) {
        props.saveSection(thisSection);
        props.closeSection(thisSection);
        scroller.scrollTo(props.thisSection, {
          activeClass: "active",
          onSetActive: this.handleSetActive,
          duration: 0,
          delay: 0,
          smooth: true,
          isDynamic: true
        });
        this.props.openSection(nextSection);
      }
    } else if (props.thisSection === DOING) {
      this.childSection.current.handleValidate();
    }
    // else if (props.thisSection === CHECKOUT) {
    //   const { Email, FirstName, ResortName } = this.props;
    //   // validate all sections
    //   // resort not selected
    //   let errored = false;
    //   if (Object.keys(validateResort(this.props.resortName)).length !== 0) {
    //     // set show validateResort true
    //     this.props.showResortValidation(true);
    //     scroller.scrollTo(RESORT, {
    //       activeClass: "active",
    //       onSetActive: this.handleSetActive,
    //       duration: 0,
    //       delay: 0,
    //       smooth: true,
    //       isDynamic: true
    //     });
    //     this.props.openSection({ section: RESORT });
    //   }
    //   //profile not complete
    //   else {
    //     this.submitProfile().then(response => {
    //       if (response === true) {
    //         const {
    //           Email,
    //           FirstName,
    //           resortName,
    //           country,
    //           tripMembers,
    //           familyMembers,
    //           startDate,
    //           endDate,
    //           manLesChosen,
    //           groLesChosen,
    //           priLesChosen,
    //           adaLesChosen
    //         } = this.props;
    //
    //         // members
    //         let tripMemberNames = []; // an array of trip members' names
    //         for (let memberID of tripMembers) {
    //           const firstName = familyMembers[memberID].FirstName;
    //           const lastName = familyMembers[memberID].LastName;
    //           tripMemberNames.push(firstName + " " + lastName);
    //         }
    //         const tripMemberCount = tripMemberNames.length;
    //
    //         // dates
    //         const tripStartDate = moment(startDate).format("DD MMM YYYY");
    //         const tripEndDate = moment(endDate).format("DD MMM YYYY");
    //
    //         // lessons
    //         function id2nameConverter(lesChosen) {
    //           for (let entry of Object.entries(lesChosen)) {
    //             const entry_key = entry[0];
    //             const entry_value = entry[1];
    //
    //             const lesson_ppl_ids = entry_value.ppl; // an array of memberIDs
    //             const lesson_ppl_names = []; // will replace ids
    //             for (let memberID of lesson_ppl_ids) {
    //               const firstName = familyMembers[memberID].FirstName;
    //               const lastName = familyMembers[memberID].LastName;
    //               lesson_ppl_names.push(firstName + " " + lastName);
    //             }
    //             lesChosen[entry_key].ppl = lesson_ppl_names;
    //             lesChosen[entry_key].date = moment(entry_value.date).format(
    //               "DD MMM YYYY"
    //             );
    //           }
    //         }
    //
    //         id2nameConverter(manLesChosen);
    //         id2nameConverter(groLesChosen);
    //         id2nameConverter(priLesChosen);
    //         id2nameConverter(adaLesChosen);
    //
    //         // const manLesChosen_array = Object.values(manLesChosen);
    //         // const groLesChosen_array = Object.values(groLesChosen);
    //         // const priLesChosen_array = Object.values(priLesChosen);
    //         // const adaLesChosen_array = Object.values(adaLesChosen);
    //
    //         this.props.completeForm({
    //           Email,
    //           FirstName,
    //           resortName,
    //           country,
    //           tripMemberNames,
    //           tripMemberCount,
    //           tripStartDate,
    //           tripEndDate,
    //           manLesChosen,
    //           priLesChosen,
    //           groLesChosen,
    //           adaLesChosen
    //         });
    //       }
    //     });
    //   }
    //}
    else {
      props.saveSection(thisSection);
      props.closeSection(thisSection);
      scroller.scrollTo(props.thisSection, {
        activeClass: "active",
        onSetActive: this.handleSetActive,
        duration: 0,
        delay: 0,
        smooth: true,
        isDynamic: true
      });
      this.props.openSection(nextSection);
    }
  };

  render() {
    const childElement = React.Children.only(this.props.children);

    // set true to show all sections
    const prevCompleted =
      process.env.REACT_APP_NODE_ENV !== "production" ||
      this.props.completed[this.props.prevSection] ||
      this.props.completed[this.props.thisSection];
    return (
      <div
        className="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8"
        style={{ margin: "auto", padding: 0 }}
      >
        <div>
          <Element name={this.props.thisSection}>
            <TitleButton
              style={
                this.props.completed[this.props.thisSection] ||
                this.props.open === true
                  ? { color: "white", background: BlueColor3 }
                  : {}
              }
              onClick={this.handleToggle}
              disabled={!prevCompleted}
            >
              {this.props.label}

              {/*<Arrow>{this.renderArrow()}</Arrow>*/}
            </TitleButton>
            <Collapse in={this.props.open} style={{ paddingBottom: 10 }}>
              <div>
                {/* make reference to child*/}
                {React.cloneElement(childElement, {
                  onNext: this.handleNext
                })}

                <VerticalSpaceMD />

                {/*<div style={{ textAlign: "right" }}>*/}
                {/*  <OrangeButton onClick={this.handleSave}>Next</OrangeButton>*/}
                {/*</div>*/}
              </div>
            </Collapse>
          </Element>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const selector = formValueSelector(PROFILE);
  return {
    open: state.sectionReducer.open[props.thisSection],
    completed: state.sectionReducer.completed,
    profileSyncErrors: Object.keys(getFormSyncErrors(PROFILE)(state)),
    Email: selector(state, "Email"),
    FirstName: selector(state, "FirstName"),
    // ResortName: state.tripWhereReducer.resortName,

    tripMembers: state.tripWhoReducer.tripMembers,
    familyMembers: state.tripWhoReducer.familyMembers,
    startDate: state.tripWhenReducer.startDate,
    endDate: state.tripWhenReducer.endDate,
    resortName: state.tripWhereReducer.resortName,
    country: state.tripWhereReducer.country,
    manLesChosen: state.tripLessonReducer.manLesChosen,
    groLesChosen: state.tripLessonReducer.groLesChosen,
    priLesChosen: state.tripLessonReducer.priLesChosen,
    adaLesChosen: state.tripLessonReducer.adaLesChosen,

    // post trip related data
    when: state.tripWhenReducer,
    where: state.tripWhereReducer,
    profile: state.profileReducer,
    who: state.tripWhoReducer,
    activity: state.activityReducer,
    lessons: state.tripLessonReducer.tripLessons
  };
}

export default connect(
  mapStateToProps,
  {
    openSection,
    closeSection,
    saveSection,
    completeForm
  }
)(FormContents);
