import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import agent from "../../../agent";
import * as moment from "moment";
import {
  getCountryList,
  getResortList,
  getProviderList
} from "../../../redux/actions/homePageAction";
import { COLOR } from "../../../styling/theme/Theme";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: "0rem 1rem"
  },
  backgroundShadow: {
    background: "rgba(0, 0, 0, 0.25)",
    borderRadius: 10,
    [theme.breakpoints.down("xs")]: {
      background: "rgba(0, 0, 0, 0.45)"
    }
  },
  header: {
    //     font-family: PT Sans Narrow;
    // font-size: 26px;
    // font-style: normal;
    // font-weight: 700;
    // letter-spacing: 0.02em;
    // text-align: center;

    // display: "flex",
    // alignItems: "center",
    // justify: "center",

    color: "#FFFFFF",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "26px",
    margin: "18px 0 9px 0",
    fontFamily: "PT Sans Narrow",
    justify: "center",
    textAlign: "center",
    paddingRight: "1rem",
    paddingLeft: "1rem"
  },
  test: {
    marginTop: "0.3em"
  },
  selectRoot: {
    color: "black",
    paddingRight: "30px",
    // display: "inline",
    "& .svg.MuiSvgIcon-root .MuiStepIcon-completed, path": {
      color: "#4A443F"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      borderWidth: "1px"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      borderWidth: "1px"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      borderWidth: "1px"
    },
    // "& fieldset.MuiOutlinedInput-notchedOutline": {
    //   borderColor: "black !important"
    // },
    // [theme.breakpoints.down("xs")]: {
    //   paddingRight: "0px"
    // },
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0px",
      padding: "0.5rem"
    }
  },
  selectRoot2: {
    display: "inline",
    paddingBottom: "0em",
    paddingTop: "0em",
    marginBottom: "0em",
    height: "2.3em",
    lineHeight: 2.5
  },
  labels: {
    display: "inline",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    color: COLOR.PRIMARY,
    lineHeight: "23px",
    letterSpacing: "0em",
    textAlign: "left",
    paddingBottom: "5px"
  },
  labels2: {
    display: "inline",
    fontFamily: "PT Sans Narrow",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    color: COLOR.PRIMARY,
    lineHeight: "23px",
    letterSpacing: "0em",
    textAlign: "left",
    paddingBottom: "5px"
  },
  dateRoot: {
    "& .MuiOutlinedInput-root": {
      height: "2.1em",
      width: "9em"
    },
    marginTop: "0em",
    marginBottom: "0em",
    "& .MuiIconButton-root": {
      padding: "7px",
      marginTop: "1px"
    },
    "& svg.MuiSvgIcon-root": {
      color: "black",
      height: "15px"
    },
    "& svg.MuiSvgIcon-root .MuiStepIcon-completed, path": {
      color: "#4A443F",
      opacity: 0.5
    },
    "& .MuiOutlinedInput-adornedEnd": {
      padding: "0px"
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiOutlinedInput-root": {
        width: "16em"
      }
    }
  },
  searchCard: {
    padding: "20px 30px 20px 30px",
    borderRadius: 10,
    boxShadow: "7px 7px 20px rgba(0, 0, 0, 0.25)",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "grid",
      justifyContent: "center"
    },
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      justifyContent: "center"
    }

    // minWidth: 406,
    // maxWidth: 406,
    // minHeight: 415,
    // maxHeight: 415,
    // marginLeft: "4em"
  },
  row: {
    // marginBottom: "0.7em"
    padding: "0em 0.5em"
  },
  button: {
    borderRadius: 100,
    height: "3em",
    width: "10em",

    background: "#FF6161",
    alignSelf: "flex-end",
    justifySelf: "center",
    "&:hover.MuiButton-contained": {
      background: "#FF6161"
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0px",
      padding: "0.5rem",
      margin: "0.5rem 0rem"
    }
  },

  buttonLabel: {
    fontWeight: "300",
    textTransform: "none",
    color: "#ffffff",
    fontWeight: 700,
    fontSize: 16,
    fontFamily: "PT Sans"
  },
  select1: {
    width: "9em",
    height: "2.1em",
    [theme.breakpoints.down("sm")]: {
      width: "16em"
    }
  },
  select2: {
    width: "9em",
    height: "2.1em"
  },
  date2: {
    // width: "9em"
    // marginRight: '2rem'
  },
  backgroundTest: {
    // width: "9em"
  }
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF"
    },
    secondary: {
      main: "#FF6161"
    }
  }
});

const SearchCardHorizontal = props => {
  const classes = useStyles();
  const today = new Date();
  const minStartDate = new Date(new Date().setDate(today.getDate() + 4));
  const minEndDate = new Date(new Date().setDate(today.getDate() + 11));
  const [startDate, setStartDate] = useState(minStartDate);
  const [endDate, setEndDate] = useState(minEndDate);
  const [country, setCountry] = useState();
  const [resort, setResort] = useState();
  const [resortLabel, setResortLabel] = useState();
  const [disabled, setDisable] = useState(true);
  const [countryList, setCountryList] = useState([]);
  const { country_list, resortList } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isToOpen, setIsToOpen] = useState(false);

  useEffect(() => {
    props.getCountryList();
  }, []);

  const handleSearch = () => {
    if (country && resortLabel) {
      props.getProviderList(resort);
      props.onSearchClick(true);
      scrollDown();
    }
  };

  const scrollDown = () => {
    props.onSelectCountry(country);
    props.onSelectResort(resortLabel);
    props.onSelectStartDate(moment(startDate).format("DD-MM-YYYY"));
    props.onSelectEndDate(moment(endDate).format("DD-MM-YYYY"));
    console.log("checked", props.checked);
    setTimeout(function() {
      window.scrollTo({
        top: window.screen.height,
        behavior: "smooth"
      });
    }, 400);
  };

  useEffect(() => {
    if (startDate >= endDate) {
      setEndDate(startDate);
    }
  }, [startDate, endDate]); // Only re-run the effect if startDate changes

  const checkOptions = country_list.map(data => (
    <MenuItem key={data.id} value={data}>
      {data.country_name}
    </MenuItem>
  ));
  const onCountryHandleChange = event => {
    setCountry(event.target.value.country_name);
    props.getResortList(event.target.value.id);
    props.onSearchClick(false);
    resetFields();
    setDisable(false);
  };

  const resetFields = () => {
    props.onSelectResort("");
  };

  const resortOptions = resortList.map(data => (
    <MenuItem key={data.id} value={data}>
      {data.resort_name}
    </MenuItem>
  ));

  const onResortHandleChange = event => {
    if ( event.target.value.id !=null ) {
      props.setSelectedResortID(event.target.value.id);
    }
    setResort(event.target.value.id);
    setResortLabel(event.target.value.resort_name);
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.backgroundTest} xs container justify="center">
        <ThemeProvider theme={theme}>
          <div className={classes.backgroundShadow}>
            <h2 className={classes.header}>
              Browse and book <i>Experience Hosts</i> local to the resort
            </h2>
            <Card className={classes.searchCard}>
              <div>
                {props.lookingFor == null ? (
                  <FormControl
                    variant="outlined"
                    color="primary"
                    classes={{ root: classes.selectRoot }}
                  >
                    <Typography className={classes.labels2}>
                      SELECT COUNTRY{" "}
                    </Typography>
                    <Select
                      className={classes.select1}
                      classes={{
                        root: classes.selectRoot2
                      }}
                      onChange={onCountryHandleChange}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                    >
                      {checkOptions}
                    </Select>
                  </FormControl>
                ) : (
                  <p />
                )}
              </div>
              <FormControl
                variant="outlined"
                color="primary"
                classes={{ root: classes.selectRoot }}
              >
                <Typography className={classes.labels2}>
                  SELECT RESORT{" "}
                </Typography>
                <Select
                  disabled={disabled}
                  className={classes.select1}
                  classes={{
                    root: classes.selectRoot2
                  }}
                  onChange={onResortHandleChange}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                >
                  {resortOptions}
                </Select>
              </FormControl>

              <FormControl classes={{ root: classes.selectRoot }}>
                <Typography className={classes.labels}>FROM </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    open={isOpen}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    autoOk
                    selected={startDate}
                    value={startDate}
                    minDate={minStartDate}
                    KeyboardButtonProps={{
                      onFocus: e => {
                        setIsOpen(true);
                      }
                    }}
                    onChange={date => {
                      setStartDate(date);
                      setIsOpen(false);
                    }}
                    PopoverProps={{
                      disableRestoreFocus: true,
                      onClose: () => {
                        setIsOpen(false);
                      }
                    }}
                    InputProps={{
                      onFocus: () => {
                        setIsOpen(true);
                      }
                    }}
                    InputAdornmentProps={{
                      onFocus: e => {
                        setIsOpen(true);
                      }
                    }}
                    className={classes.date2}
                    inputVariant="outlined"
                    classes={{
                      root: classes.dateRoot
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>

              <FormControl classes={{ root: classes.selectRoot }}>
                <Typography className={classes.labels}>TO </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    open={isToOpen}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    autoOk
                    selected={endDate}
                    value={endDate}
                    minDate={startDate}
                    onChange={date => {
                      setEndDate(date);
                      setIsToOpen(false);
                    }}
                    KeyboardButtonProps={{
                      onFocus: e => {
                        setIsToOpen(true);
                      }
                    }}
                    InputAdornmentProps={{
                      onFocus: e => {
                        setIsToOpen(true);
                      }
                    }}
                    PopoverProps={{
                      disableRestoreFocus: true,
                      onClose: () => {
                        setIsToOpen(false);
                      }
                    }}
                    InputProps={{
                      onFocus: () => {
                        setIsToOpen(true);
                      }
                    }}
                    inputVariant="outlined"
                    className={classes.date2}
                    classes={{
                      root: classes.dateRoot
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>

              <Button
                variant="contained"
                onClick={handleSearch}
                className={classes.button}
                classes={{ label: classes.buttonLabel }}
              >
                Search
              </Button>
            </Card>
          </div>
        </ThemeProvider>
      </Grid>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    country_list: state.homePageReducer.country_list,
    resortList: state.homePageReducer.resortList
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getCountryList: () => dispatch(getCountryList()),
    getResortList: id => dispatch(getResortList(id)),
    getProviderList: id => dispatch(getProviderList(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchCardHorizontal);
