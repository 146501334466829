import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  main: {
    height: "18em",
    background: "#d1e3ef",
    alignContent: "center"
  },
  text: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    width: "100%",
    fontWeight: "bold",
    fontSize: "28px",
    lineHeight: "140.62%",
    textAlign: "center",
    color: "#000000"
  },
  button: {
    marginTop: "1em",
    width: "173px",
    height: "50px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "140.62%",
    textAlign: "center",
    letterSpacing: "0.01em",
    color: "#ffffff",
    background: "#ff6161",
    borderRadius: "100px",
    border: "none",
    "&:hover": {
      color: "#ffffff",
      backgroundColor: "#e05555"
    }
  }
});

const SignUpSection = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.main}>
      <Grid item container justify="center">
        <Typography className={classes.text}>
          Are you an instructor or host?
        </Typography>
        <Typography className={classes.text}>
          Create a profile to connect with students and visitors today.
        </Typography>
      </Grid>
      <Grid item container justify="center">
        <Button
          variant="contained"
          color="secondary"
          href="/signup"
          className={classes.button}
          onClick={() => {}}
        >
          Sign up
        </Button>
      </Grid>
    </Grid>
  );
};

export default SignUpSection;
