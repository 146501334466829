import React, { useState } from "react";
import { connect } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import StyledButton from "../StyledButton";
import { toggleVirtInstructorLike } from "../../../redux/actions/virtInstructorDataActions";
import { makeStyles } from "@material-ui/core/styles";
import BookingPopup from "../BookingPopup";
import IconButton from "@material-ui/core/IconButton";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";

//import SavedInstructors from "../ExploreInstructors/SavedInstructors";
import axios from "axios";
import { useHistory } from "react-router-dom";
import agent from "../../../agent";

const useStyles = makeStyles(theme => ({
  large: {
    width: theme.spacing(26),
    height: theme.spacing(26)
  },
  medium: {
    width: theme.spacing(22),
    height: theme.spacing(22)
  },
  small: {
    width: theme.spacing(17),
    height: theme.spacing(17),
    padding: "4px"
  },
  textBody: {
    fontSize: 16,
    textAlign: "left"
  },
  textName: {
    fontSize: 30,
    fontWeight: "bold"
  },
  textNameSm: {
    fontSize: 30,
    fontWeight: "bold"
  },
  iconstyle: {
    [theme.breakpoints.up("sm")]: {
      fontSize: 22
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12
    },
    fill: isLiked => (isLiked ? "red" : "grey")
  },
  iconRoot: {
    margin: 10,
    [theme.breakpoints.down("sm")]: {
      margin: 0
    },
    backgroundColor: "rgba(128, 128, 128, 0.3)"
  },
  favourite: {
    position: "absolute",
    zIndex: "10",
    outline: "none",

    "& > *": {
      margin: "1"
    }
  }
}));

function IntroSection(props) {
  const { FirstName, LastName, Bio, MinPrice, id } = props.instructorData;

  //fake data
  const isLiked = false;
  const avatarSrc = "";

  //instructor favourite
  const [favInstructors, setFavInstructors] = React.useState(false);

  const handleFavourites = name => {
    let newArray = [...favInstructors];
    if (favInstructors.findIndex(x => x === name) === -1) {
      newArray.push(name);
    } else {
      newArray = newArray.filter(x => {
        return x !== name;
      });
    }
    setFavInstructors(newArray);
  };
  const removeFromFavourites = name => {
    let newArray = [...favInstructors];
    newArray = newArray.filter(x => {
      return x !== name;
    });
    setFavInstructors(newArray);
  };

  const classes = useStyles(isLiked);
  const { matchesXs, matchesSm, matchesMd, matchesLg } = props.breakpoints;
  //const toggleVirtInstructorLike = props.toggleVirtInstructorLike;

  let avatarSize = classes.large;
  if (matchesXs) {
    avatarSize = classes.small;
  } else if (matchesLg) {
    avatarSize = classes.medium;
  }
  //heart
  const [isFilled, setIsFilled] = useState();

  const handleChangeState = () => {
    setIsFilled(!isFilled);
  };

  const HeartButton = () => {
    let btn;
    const classes = useStyles();
    if (isFilled === false)
      btn = (
        <IconButton
          onClick={handleChangeState}
          color="secondary"
          aria-label="unfavourite"
          className={classes.favourite}
        >
          <FavoriteIcon />
        </IconButton>
      );
    else
      btn = (
        <IconButton
          onClick={handleChangeState}
          aria-label="unfavourite"
          className={classes.favourite}
        >
          <FavoriteBorderIcon />
        </IconButton>
      );

    return btn;
  };

  const API_PATH = "../api/";
  let history = useHistory();
  // This is different from the id in url?
  // not sure it should be instructor_id or ins_user_id
  const instructorID = props.instructorId;

  const handleMessageButton = () => {
    // axios
    //   .post(API_PATH + "messagingRoom/getID", {
    //     student_user_id: props.currentUser.id,
    //     instructor_user_id: instructorID
    //   })
    agent.Message.getOrCreateMessageRoom(props.currentUser.id, instructorID)
      .then(res => {
        if (res.data == "the current login user is a ins") {
          alert("you are an instructor, shouldn't talk to another instructor");
        } else {
          const roomID = res.data.room_id;
          history.push("/chatpage/" + roomID);
        }
      })
      .catch(e => console.log(e));
  };

  return (
    <Grid container spacing={3}>
      <Grid
        item
        container
        lg={2}
        md={3}
        sm={6}
        xs={12}
        alignItems="center"
        justify="center"
      >
        <Avatar className={avatarSize} src={avatarSrc} />
      </Grid>

      <Grid item lg={8} md={7} sm={6} xs={12}>
        <Grid container spacing={5}>
          <Grid item container md={6} sm={12} xs={12}>
            <Grid
              item
              container
              md={8}
              sm={9}
              xs={12}
              alignItems="center"
              justify={matchesXs ? "center" : "flex-start"}
            >
              <Typography
                align={matchesSm ? "center" : "left"}
                className={matchesSm ? classes.textNameSm : classes.textName}
              >
                {FirstName + " " + LastName}
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={2}
              sm={3}
              xs={12}
              justify={matchesXs ? "center" : "left"}
            >
              <HeartButton />
              {/* <IconButton
                aria-label="add to favorites"
                onClick={handleFavourites}
                classes={{
                  label: classes.iconLabel,
                  root: classes.iconRoot
                }}
              >
                <FavoriteIcon className={classes.iconstyle} />
              </IconButton> */}
            </Grid>
          </Grid>

          <Grid
            item
            container
            md={6}
            justify={matchesXs ? "center" : "space-around"}
          >
            <StyledButton
              displayText={"Message " + FirstName}
              onClick={handleMessageButton}
            />
          </Grid>
        </Grid>

        {/* <Grid item container xs={12} justify={matchesXs ? "center" : "flex-start"} style={{marginTop: "0.5em"}}>
          <StyledButton displayText={"Book a virtual lesson"} onClick={handleClickOpen}/>
        </Grid> */}

        {/* <BookingPopup open={open} handleClose={handleClose} avatarSrc={avatarSrc}/> */}

        <Grid item xs={12} style={matchesSm ? { margin: 40 } : { margin: 20 }}>
          <Typography className={classes.textBody}>{Bio}</Typography>
        </Grid>
      </Grid>

      <Grid item container xs={12} sm={2} direction="column">
        <Grid item container>
          <Typography
            style={{
              fontSize: 12,
              color: "#1277B8",
              fontWeight: "500",
              fontFamily: "PT Sans Narrow"
            }}
            justify={matchesXs ? "center" : "left"}
          >
            Starting Price
          </Typography>
        </Grid>

        <Grid item container>
          <Typography
            style={{
              fontSize: 20,
              color: "#1277B8",
              fontWeight: "500",
              fontFamily: "PT Sans Narrow"
            }}
            align={matchesXs ? "left" : "center"}
          >
            {"$" + MinPrice + "/hr"}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    instructorData: state.virtualInstructorReducer.currentProfile
  };
}

const mapDispatchToProps = dispatch => {
  return {
    toggleVirtInstructorLike: virtInstructorId =>
      dispatch(toggleVirtInstructorLike(virtInstructorId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntroSection);
