import React from "react";
import LikeCounter from "../../Common/Home/LikeCounter";
import agent from "../../../agent";

const styles = {
  root: {
    textAlign: "left"
  }
};

export default class ConnectOthers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connectSkiersCount: 0,
      connectSkiersVoted: false,
      connectHostCount: 0,
      connectHostVoted: false,
      becomeHostCount: 0,
      becomeHostVoted: false
    };
  }

  componentDidMount() {
    agent.Votes.getVotes()
      .then(response => {
        this.setState({ ...response.data });
      })
      .catch(error => {});
  }

  handleVote = counter => {
    let id = 0;
    if (counter === "connectSkiersCount") {
      id = 1;
    } else if (counter === "connectHostCount") {
      id = 2;
    } else {
      id = 3;
    }
    this.setState({ [counter]: this.state[counter] + 1 }, () => {
      agent.Votes.putVotes(id, { votes: this.state[counter] });
    });
  };

  render() {
    const state = this.state;
    return (
      <div className="row justify-content-center ">
        <div className="col-11 col-sm-8 col-md-6" style={styles.root}>
          <h1 style={{ textAlign: "center" }}>
            Coming Soon...
            <br />
          </h1>
          <h5>
            In a society consumed with connecting with each other online, we
            want to provide an online experience to connect like-minded people
            with each other in person.
          </h5>
          <h5>
            Are you interested in connecting with others at your ability level
            who are visiting the same resort as you, so you can enjoy the slopes
            at your pace?
          </h5>
          <LikeCounter
            onVote={this.handleVote}
            count={state.connectSkiersCount}
            counter="connectSkiersCount"
            voted={state.connectSkiersVoted}
          />
          <br />
          <h5>
            Are you interested in connecting with a local resort host/guide to
            show you the best slopes, the best aprés bars, restaurants,
            nightlife?
          </h5>
          <LikeCounter
            onVote={this.handleVote}
            count={state.connectHostCount}
            counter="connectHostCount"
            voted={state.connectHostVoted}
          />
          <br />
          <h5>
            Are you interested in becoming a resort host/guide to show off your
            mountain?
          </h5>
          <LikeCounter
            onVote={this.handleVote}
            count={state.becomeHostCount}
            counter="becomeHostCount"
            voted={state.becomeHostVoted}
          />
          <br />
          <h5>
            <a href="/contact">Add comments</a>
          </h5>
        </div>
      </div>
    );
  }
}
