//this is the card for the instructor to set their availability
import React from "react";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import StyledButton from "../StyledButton";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import SelectedAvailability from "./SelectedAvailability";
import axios from "axios";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import ExitButton from "../ExitButton";
import { equalsDate } from "../Helpers/dates";
import {
  toggleVirtInstructorLike,
  createBooking,
  adjustUnavailability
} from "../../../redux/actions/virtInstructorDataActions";

const useStyles = makeStyles({
  smallSectionHeading: {
    fontSize: "16"
  },
  root: {
    width: "100%",
    padding: "20px 10% 20px 10%"
  },
  textHeading: {
    fontSize: "16",
    color: "#1277B8",
    fontWeight: "bold",
    fontFamily: "PT Sans Narrow",
    lineHeight: "140.62%",
    padding: "6px"
  },
  subHeading: {
    textAlign: "left",
    marginRight: "auto",
    marginLeft: "0px",
    fontSize: "16",
    color: "#000000",
    fontWeight: "bold",
    fontFamily: "PT Sans Narrow",
    lineHeight: "140.62%",
    padding: "6px"
  },
  subHeadingContainer: {
    width: "80%",
    textAlign: "left"
  },
  centererDiv: {
    textAlign: "center"
  }
});

//TO BE CLEAR
//Unavailability refers to specfic days that the instructor is unavailable- perhaps on a holiday
//Availability refers to REPEATED SCHEDULED days that the instructor is available - every monday-friday 9-5 for example
//This card deals with both
const UnavailabilitySettings = props => {
  const classes = useStyles();
  //on which screen the status is editable
  //User is not able to make a booking until they click on "Book" basically
  const editableScreen = 2;
  const [resort_id, setResortID] = React.useState("");
  const [selection, setSelection] = React.useState([]);
  const [pastDays, setPastDays] = React.useState([]);
  const [timeslots, setTimeslots] = React.useState([]);
  const [resorts, setResorts] = React.useState([]);
  const isLiked = false;
  const id = 1;
  const updateTime = props.updateTime;
  const API_PATH = "../api/";

  //-----Use Effects-----\\

  //Activates whenever the current instructors booking, resorts or availability changes
  //Updates the available resorts to select from and the
  React.useEffect(() => {
    setResorts(props.instructorData.resorts);
    loadUnavailability();
  }, [
    props.instructorData.resort_bookings,
    props.instructorData.resorts,
    props.instructorData.unavailability,
    resort_id
  ]);

  //Activates when the available resorts change
  //Sets the current resort id
  //TODO is this done?
  React.useEffect(() => {
    if (typeof resorts[0] !== "undefined") setResortID(resorts[0].id);
  }, [resorts]);

  React.useEffect(() => {
    modifiers = {
      disabled: extractDates(selection)
    };
  }, [selection]);

  //-----Update functions-----\\

  //Takes the current selected availability and applys it to the redux state and to the backend database
  const updateUnavailability = () => {
    let newUnavailability = timeslots.map(date => {
      return {
        resort_info_id: resort_id,
        start: date.datefrom,
        end: date.dateto
      };
    });
    newUnavailability = [...newUnavailability];
    props.adjustUnavailabilityReduxState(resort_id, newUnavailability);
    newUnavailability = timeslots.map(date => {
      return {
        resort_info_id: resort_id,
        start: date.datefrom.toString(),
        end: date.dateto.toString()
      };
    });
    axios
      .put(API_PATH + "instructor/unavailability/" + props.instructorData.id, {
        newUnavailability: newUnavailability,
        resort_id
      })
      .then(response => {})
      .catch(e => {
        console.log(e);
      });
    setSelection([]);
    loadUnavailability();
  };

  //"Loads" the availability from the current instructor data
  const loadUnavailability = () => {
    if (props.instructorData.unavailability != null) {
      let resortUnavailability = props.instructorData.unavailability.filter(
        dateObject => dateObject.resort_info_id === resort_id
      );
      resortUnavailability = unwrapDates(resortUnavailability);
      if (resortUnavailability != null) {
        setSelection(resortUnavailability);
      }
    } else {
      setSelection([]);
    }
  };

  const isPastDate = day => {
    const today = new Date();
    console.log(day);
    console.log(today);
    console.log(day.getDate() < today.getDate());
    console.log(day < today);
    if (day.getDate() < today.getDate() && day < today) return true;
    return false;
  };

  //updateSelection:
  //triggered by selecting new date on calendar
  //updates selection state
  //selection state: list of days selected as unavailable
  const updateSelection = day => {
    const pastDate = isPastDate(day);
    if (props.screenNumber == editableScreen && !pastDate) {
      let selectedDates = extractDates(selection);
      const containsSelection = containsDate(selectedDates, day);
      if (containsSelection) {
        selectedDates = unselectDate(selection, day);
        setSelection(selectedDates);
      } else {
        let newSelection = [
          ...selection,
          { start: startOfCurrentDay(day), end: endOfCurrentDay(day) }
        ];
        setSelection(newSelection);
      }
      updateTime(day);
    }
  };

  //Unselects date in form [{start: datetime, end: datetime}]
  const unselectDate = (dates, day) => {
    let datesCopy = [];
    dates.map(date => {
      console.log(date.start, day);
      if (!equalsDate(date.start, day)) {
        datesCopy[datesCopy.length] = date;
        console.log(datesCopy);
      }
    });
    return datesCopy;
  };
  //Renders the buttons below the calendar
  //Update, Save, Cancel
  const renderUnavailabilityButtons = () => {
    if (props.screenNumber == editableScreen) {
      return (
        <Grid
          xs={12}
          item
          container
          alignItems="center"
          justify="center"
          spacing={2}
          className={classes.centererDiv}
        >
          <Grid item xs={6}>
            <StyledButton
              displayText={"Save"}
              onClick={() => {
                updateUnavailability();
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <StyledButton
              outlined={true}
              displayText={"Cancel"}
              onClick={() => {
                //removes ability to update and reloads data (overwriting any changes)
                props.setScreenNumber(1);
                loadUnavailability();
              }}
            />
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid item>
        <StyledButton
          displayText={"Update Availability"}
          //remove this onclick, fire an action that would add a booking
          onClick={() => {
            props.setScreenNumber(props.screenNumber + 1);
          }}
        />
      </Grid>
    );
  };

  const closeButton = (
    <ExitButton
      onClick={() => {
        updateUnavailability();
        props.setScreenNumber(1);
      }}
    />
  );

  //HELPER functions
  //takes inputs of format {Date: start, Date: end} and
  // unwraps to [{start:start,end:endOfDay}, {start:startOfDay+1, end:endOfDay+1}, ..., {start:startOfDay+n, end:end}]
  const unwrapDates = dates => {
    let unwrapped = [];
    dates.map(date => {
      unwrapped = unwrapped.concat(unwrapDate(date));
    });
    return unwrapped;
  };
  const unwrapDate = date => {
    let curr = {
      start: new Date(date.start),
      end: endOfCurrentDay(date.start)
    };
    let end = { start: startOfCurrentDay(date.end), end: new Date(date.end) };
    if (equalsDate(curr.start, end.start) || curr.start >= end.start) {
      return [{ start: curr.start, end: end.end }];
    }
    let dates = [];
    while (!equalsDate(curr.start, end.start)) {
      dates.push(curr);
      curr = {
        start: startOfCurrentDay(curr.start),
        end: endOfCurrentDay(curr.start)
      };
      curr.start.setDate(curr.start.getDate() + 1);
      curr.end.setDate(curr.end.getDate() + 1);
    }
    dates.push(end);
    return dates;
  };

  //takes a date and returns that same day at 11:59pm
  const startOfCurrentDay = day => {
    const year = day.getFullYear();
    const month = day.getMonth();
    const dayOfMonth = day.getDate();
    const hour = 0;
    const minute = 0;
    return new Date(year, month, dayOfMonth, hour, minute);
  };

  //takes a date and returns that same day at 11:59pm
  const endOfCurrentDay = day => {
    const year = day.getFullYear();
    const month = day.getMonth();
    const dayOfMonth = day.getDate();
    const hour = 23;
    const minute = 59;
    return new Date(year, month, dayOfMonth, hour, minute);
  };

  //Takes format of {start, end} and returns {datefrom, dateto}
  const getTimeslots = dates => {
    let new_timeslots = dates.map(date => {
      return { datefrom: date.start, dateto: date.end };
    });
    return new_timeslots;
  };

  //takes input of form [{datetime: start1, datetime: end1}, {datetime: start2,datetime: end2}] and returns [date: date1, date: date2]
  const extractDates = datetimes => {
    let extracted = [];
    datetimes.map(datetime => {
      const year = datetime.start.getFullYear();
      const month = datetime.start.getMonth();
      const day = datetime.start.getDate();
      const new_day = new Date(year, month, day);
      extracted[extracted.length] = new_day;
    });
    return extracted;
  };

  //returns a boolean for if an array of dates contains a date
  const containsDate = (dates, day) => {
    if (typeof dates == "undefined" || dates.length == 0) {
      return false;
    }
    let new_dates = dates.map(date => {
      return (
        new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate()
        ).getTime() ===
        new Date(day.getFullYear(), day.getMonth(), day.getDate()).getTime()
      );
    });
    return new_dates.indexOf(true) !== -1;
  };

  //-----Calendar styling-----\\
  let modifiers = {
    disabled: extractDates(selection)
  };
  const modifiersStyles = {
    disabled: {
      color: "#686868",
      backgroundColor: "#D3D3D3"
    }
  };

  return (
    <div>
      <Grid xs={12} item container justify="center" spacing={2}>
        <Grid xs={12} item container justify="center">
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Typography
              className={classes.textHeading}
              align="center"
              gutterBottom
            >
              AVAILABILITY
            </Typography>
          </Grid>
          <Grid item xs={1}>
            {closeButton}
          </Grid>
        </Grid>
        <Grid xs={12} item container justify="center">
          <select
            id="resorts"
            key="resorts"
            onChange={event => setResortID(event.target.value)}
            value={resort_id}
          >
            <option value="" disabled>
              Select Resort
            </option>
            {resorts.map(resort => {
              return (
                resort &&
                resort.fullname && (
                  <option key={resort.id} value={resort.id}>
                    {resort.fullname}
                  </option>
                )
              );
            })}
          </select>
        </Grid>

        {/* datepicker */}
        <Grid xs={12} item container justify="center">
          <DayPicker
            modifiers={modifiers}
            modifiersStyles={modifiersStyles}
            onDayClick={day => updateSelection(day)}
          />
        </Grid>

        {/* Legend for datepicker */}
        <Grid xs={12} item container justify="flex-start" spacing={1}>
          <Grid xs={12} item container justify="center">
            <span
              style={{
                color: "#686868",
                backgroundColor: "#D3D3D3",
                borderRadius: "50%",
                height: "25px",
                width: "25px"
              }}
            >
              {" "}
            </span>
            <span> Unavailable </span>
          </Grid>
        </Grid>

        <Grid xs={12} item container justify="center">
          <div className={classes.subHeadingContainer}>
            <Typography
              className={classes.subHeading}
              align="left"
              gutterBottom
            >
              Selected Times to Block
            </Typography>
          </div>
        </Grid>
        <SelectedAvailability
          disabled={props.screenNumber == 1}
          dates={selection}
          timeslots={timeslots}
          setTimeslots={setTimeslots}
        />

        {renderUnavailabilityButtons()}
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    instructorData: state.virtualInstructorReducer.currentProfile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleVirtInstructorLike: virtInstructorId =>
      dispatch(toggleVirtInstructorLike(virtInstructorId)),
    createBooking: (id, resort, chosenday, timeslot) =>
      dispatch(createBooking(id, resort, chosenday, timeslot)),
    adjustUnavailabilityReduxState: (resort_info_id, dates) =>
      dispatch(adjustUnavailability(resort_info_id, dates))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnavailabilitySettings);
